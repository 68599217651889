import { useEffect } from "react";
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../themes';
import AppRouter from './AppRouter';
import { useDispatch, useSelector } from "react-redux";
import { isUserLoggedIn } from '../redux/actions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ToasterArea } from 'Components/Reusable';
import { AppMode } from "urlConfig";

const App = () => {
  const stripePromise = loadStripe(AppMode.stripe);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  //componentDidmount with useEffect
  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
  }, [dispatch, auth.authenticate]);

  return (
    <Elements stripe={stripePromise}>
      < ThemeProvider theme={theme}>
        <ToasterArea />
        <CssBaseline />
        <AppRouter />
      </ThemeProvider>
    </Elements>
  );
}

export default App;
