import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { DeleteOutline as DeleteOutlineIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
// import { RIconButton } from "..";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Fragment } from "react";
import { updateUserDataToTheTable } from "redux/actions";
import { RIconButton } from "Components/Reusable";
const MatDelete = () => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.root}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      control={
        <RIconButton title="Delete this user" className={classes.addButton}>
          <DeleteOutlineIcon />
        </RIconButton>
      }
    />
  );
};
const UsersAssignedProjects = ({ params }) => {
  const { id, field } = params;
  // // console.log({ id, value, api, field, params });
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    setUnsetTheTextField: {
      "& .MuiInput-underline::before": {
        content: "unset",
      },
    },
  }));
  const classes = useStyles();
  const { project } = useSelector((state) => state.projects);
  const icon = (
    <CheckBoxOutlineBlankIcon
      style={{ height: "16px", width: "16px" }}
      fontSize="small"
    />
  );
  const checkedIcon = (
    <CheckBoxIcon
      fontSize="small"
      style={{ color: "#7478E8", height: "16px", width: "16px" }}
    />
  );
  const toProjectUserAssignee = project
    ?.filter((value) => value.role === "ADMIN" || value.role === null)
    ?.map((item) => ({
      label: item.name,
      value: item.projectId,
    }));

  const defaultValues = params?.row?.projectList.filter(
    (e) => !toProjectUserAssignee.includes(e.id)
  );
  const defaultValuess = defaultValues?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleEditItem = (projects) => {
    const name = projects;
    const data = {
      id,
      name,
      value: field,
    };
    dispatch(updateUserDataToTheTable(data));
  };
  return (
    <Autocomplete
      options={toProjectUserAssignee}
      multiple
      getOptionLabel={(option) => option?.label}
      getOptionSelected={(option, value) => {
        return option.value === value.value;
      }}
      style={{ width: "100%", padding: "0" }}
      id="blur-on-select"
      blurOnSelect
      onChange={(e, f) => {
        handleEditItem(f);
      }}
      defaultValue={defaultValuess}
      renderTags={(value) => (
        <p style={{ margin: "0" }}>{value?.length && value?.length + "+"}</p>
      )}
      renderOption={(option, { selected }) => (
        <Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <p
            style={{
              wordBreak: "break-all",
              whiteSpace: " normal",
              textAlign: "center",
              fontSize: "15px",
              margin: 0,
              fontWeight: "500",
            }}
          >
            {option.label}
          </p>
        </Fragment>
      )}
      autoHighlight={true}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.setUnsetTheTextField}
          style={{
            content: "unset",
          }}
          fullWidth
          label="Select project"
        />
      )}
    />
  );
};

const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 180,
    sortable: false,
    description: "Name",
    editable: true,
  },
  {
    field: "username",
    headerName: "Email",
    minWidth: 300,
    editable: true,
    description: "Enter your email",
    sortable: false,
  },
  {
    field: "projects",
    headerName: "Projects",
    sortable: false,
    editable: true,
    minWidth: 198,
    align: "center",
    renderCell: (params) => {
      return <UsersAssignedProjects params={params} index={params?.row?.id} />;
    },
  },
  {
    field: "Delete",
    headerName: "Delete",
    sortable: false,
    minWidth: 90,
    align: "center",
    renderCell: (params) => {
      return <MatDelete index={params.row.id} />;
    },
  },
];

export default columns;

const useStyles = makeStyles((theme) => ({
  addButton: {
    cursor: "pointer",
    marginLeft: "10px",
    "& .MuiSvgIcon-root": {
      color: "#999999",
    },
  },
}));
