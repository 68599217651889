import React from 'react';
import { getBezierPath } from 'reactflow';

export default function ConnectionLine({
  fromX,
  fromY,
  fromPosition,
  toX,
  toY,
  connectionLineStyle,
}) {
  const [edgePath] = getBezierPath({
    sourceX: fromX,
    sourceY: fromY,
    sourcePosition: fromPosition,
    targetX: toX,
    targetY: toY,
    borderRadius: 2,
  });

  return (
    <g>
      <path stroke="gray" style={connectionLineStyle} fill="white" d={edgePath} />
      <circle cx={toX} cy={toY} fill="#fff" r={3} stroke="gray" strokeWidth={1.5} />
    </g>
  );
};
