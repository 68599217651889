import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Chip, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { ExportsData } from "./ExportsData";
import { useState } from "react";
import Squares from "./Squares";
import CardConfig from "./CardConfig";
import { useHistory } from "react-router-dom";
import ProAddUsers from "./ProTab/programme/ProAddUsers";
import Projects from "./ProTab/programme/Projects";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingTop={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabProfile({
  generateCSV,
  user,
  selfproject,
  ProjectItem,
  deleteSingleProject,
  handledeleteSingleProject,
  setdeleteSingleProject,
}) {
  const project = useSelector((state) => state.projects);
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const [filterproject, setFilterProject] = useState();
  const [selectedProjects, setSelectedProjects] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const SelectProject = () => {
    const handleChange = (event, values) => {
      const uniqueValues = values.filter(
        (option, index, self) =>
          index === self.findIndex((opt) => opt.value === option.value)
      );
      setSelectedProjects(uniqueValues);
    };

    const projectOptions = project?.project.map((item) => ({
      name: item.name,
      value: item.projectId,
    }));

    return (
      <div>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "-10px",
          }}
        >
          Select project
        </h2>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box marginTop={3} marginBottom={2}>
            <Autocomplete
              multiple
              id="multi-select-autocomplete"
              options={projectOptions}
              value={selectedProjects}
              onChange={handleChange}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Options"
                  placeholder="Options"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option.name} {...getTagProps({ index })} />
                ))
              }
            />
          </Box>
        </Box>
      </div>
    );
  };
  return (
    <div>
      <Paper
        elevation={1}
        style={{
          marginTop: "8px",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
        >
          <Tab label="Project settings​" {...a11yProps(0)} />
          {user.roleType === "ADMIN" && selfproject?.role !== "USER" ?
            <Tab label="Programme settings​" {...a11yProps(1)} /> : null}
          {/* <Tab label="Company settings​​" {...a11yProps(2)} /> */}
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0} >
        <SelectProject />
        <Grid container spacing={2}>
          <Grid item xs={6} lg={6}>
            {/* <Box marginBottom={1}>
              {selectedProjects.length ? (
                <CardConfig selectedProjects={selectedProjects} />
              ) : (
                <Paper elevation={1}>
                  <Box padding={3}>
                    <Typography>No Projects Selected</Typography>
                  </Box>
                </Paper>
              )}
            </Box> */}
            <ExportsData user={user} generateCSV={generateCSV} />
          </Grid>
          <Grid item xs={6} lg={6} justifyContent="center" display="flex">
            {selectedProjects.length ? (
              <Squares selectedProjects={selectedProjects} />
            ) : (
              <Paper elevation={1}>
                <Box padding={3}>
                  <Typography>No Projects Selected</Typography>
                </Box>
              </Paper>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box display={`flex`} justifyContent={`space-between`}>
          <Grid spacing={2} container>
            <Grid item md={6} xs={12}>
              <Projects
                {...{
                  user,
                  selfproject,
                  history,
                  project,
                  setFilterProject,
                  filterproject,
                  projectData: project,
                  ProjectItem,
                  deleteSingleProject,
                  handledeleteSingleProject,
                  setdeleteSingleProject,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProAddUsers />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </div>
  );
}
