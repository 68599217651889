import { Modal } from "Components/Reusable";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { ErrorMessages } from "Components/Reusable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { backgroundArray } from "assets/Images/background";
export const AddBoardModal = ({
  openModalForBoard,
  setOpenModalForBoard,
  boardReset,
  handleBoardSubmit,
  onBoardSubmit,
  boardRegister,
  boardError,
  classes,
  setSelectedBackground,
  selectedBackground,
}) => {
  return (
    <Modal
      open={openModalForBoard}
      handleClose={() => {
        setOpenModalForBoard(false);
        boardReset();
      }}
    >
      <Box maxHeight={800} style={{ overflowY: "auto" }}>
        <h1 style={{ textAlign: "left", marginLeft: "32px" }}>Add new Board</h1>
        <form
          style={{ padding: "20px", maxWidth: "780px" }}
          noValidate
          autoComplete="off"
          onSubmit={handleBoardSubmit(onBoardSubmit)}
        >
          <TextField
            style={{ margin: "10px" }}
            variant="outlined"
            fullWidth
            label="Board name*"
            {...boardRegister("name", {
              required: {
                value: true,
                message: "This is field required",
              },
              minLength: {
                value: 3,
                message: "min character 3",
              },
            })}
          />
          <ErrorMessages errors={boardError} name="name" />
          <TextField
            style={{ margin: "10px", height: "42px" }}
            variant="outlined"
            fullWidth
            className={classes.boardColor}
            placeholder="Click to change board color"
            label="Board Color*"
            defaultValue={"#93d275"}
            type="color"
            {...boardRegister("boardColor", {
              required: {
                value: true,
                message: "This is field required",
              },
            })}
          />
          <Box ml={2} mt={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Background Images
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid spacing={2} container>
                  {backgroundArray.map((key, index) => (
                    //avatar ? 1 :
                    <Grid
                      style={{
                        border:
                          selectedBackground &&
                          selectedBackground.id === key.id &&
                          "3px solid #7478E8",
                      }}
                      key={index}
                      item
                      sm={3}
                    >
                      <Paper>
                        <img
                          onClick={() => {
                            setSelectedBackground(key);
                          }}
                          style={{
                            cursor: "pointer",
                            width: "150px",
                          }}
                          src={key.thumbnail}
                          alt={"background"}
                        />
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <br />
          <Button
            style={{ margin: "10px" }}
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
          >
            Add Board
          </Button>
        </form>
      </Box>
    </Modal>
  );
};
