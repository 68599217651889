import { RButton } from "Components/Reusable";
import {
  Box,
  Typography,
  Tooltip,
  Grid,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { getSingleAvatar } from "Utils";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { downloadjourneymap, getproblemStatement } from "redux/actions";
import Spinner from "Components/Reusable/Spinner";
import { useEffect } from "react";
import { ProblemStatement } from "Components/HomeComponents";

const PersonasLayout = ({
  menuOpen,
  pushWord,
  setMenuOpen,
  personas,
  personasId,
  setOpenWorkflowModal,
  user,
  children,
  journeyMap,
  projectId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { statement } = useSelector((state) => state.problemstatement);
  const classes = useStyles();
  // for problem statement
  useEffect(() => {
    if (personasId) {
      dispatch(getproblemStatement(projectId, personasId));
    }
  }, [personasId, dispatch, projectId]);
  return (
    <>
      {personas?.length > 0 ? (
        <Grid
          container={journeyMap ? true : false}
          style={{
            padding: "0",
            marginLeft: "0",
            marginTop: "22px",
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box
              className={menuOpen ? classes.sidebarOpen : classes.sidebarClose}
            >
              <div onClick={() => setMenuOpen(!menuOpen)}>
                {menuOpen ? (
                  <ChevronLeft className={classes.menuButton} />
                ) : (
                  <ChevronRight className={classes.menuButton} />
                )}
              </div>
              {menuOpen ? (
                <Box className={classes.internalItems}>
                  <Typography style={{ paddingLeft: 16 }}>Personas</Typography>
                  <Box
                    style={{
                      maxHeight: "350px",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    {personas.map((item, index) => {
                      return (
                        <Tooltip
                          key={index}
                          title={item.name}
                          placement="right"
                          arrow
                        >
                          <Box
                            display={"flex"}
                            style={{
                              cursor: "pointer",
                              background: personasId === item.id && "#002984",
                              alignItems: "center",
                              justifyContent: "start",
                              marginTop: "9px",
                              marginBottom: "1px",
                              padding: "5px 5px 5px 16px",
                            }}
                            onClick={() => {
                              history.push(`/${pushWord}/${item.id}`);
                            }}
                          >
                            {item?.avatar ? (
                              <Avatar
                                src={getSingleAvatar(item?.avatar)}
                                onClick={() =>
                                  history.push(`/${pushWord}/${item.id}`)
                                }
                              ></Avatar>
                            ) : (
                              <Avatar>{item?.name.slice(0, 1)}</Avatar>
                            )}
                            <span
                              style={{
                                paddingLeft: "1rem",
                                fontWeight: "bold",
                              }}
                            >
                              {item?.name.length > 7
                                ? item?.name.slice(0, 7) + "..."
                                : item?.name}
                            </span>
                          </Box>
                        </Tooltip>
                      );
                    })}
                  </Box>
                  {pushWord === "journeyMap" && (
                    <>
                      <RButton
                        type="submit"
                        variant={"outlined"}
                        className={classes.addpersona}
                        wording={"Create features"}
                        onClick={() => setOpenWorkflowModal(true)}
                      />
                      <RButton
                        type="submit"
                        variant={"outlined"}
                        className={classes.addpersona}
                        wording={"Download"}
                        onClick={() =>
                          dispatch(
                            downloadjourneymap(user.projectId, personasId)
                          )
                        }
                      />
                    </>
                  )}
                  <Tooltip placement="right" title="Problem Statement" arrow>
                    <Box maxWidth={160} width={"100%"} paddingLeft={`20px`}>
                      {statement[0] && (
                        <ProblemStatement statement={statement} />
                      )}
                    </Box>
                  </Tooltip>
                </Box>
              ) : (
                <Box textAlign={"center"}>
                  <Typography style={{ color: "white", paddingTop: "3rem" }}>
                    ({personas?.length})
                  </Typography>
                  {personas.map((item, index) => {
                    return (
                      <Tooltip
                        key={index}
                        title={item?.name}
                        placement="right"
                        arrow
                      >
                        <Box
                          paddingTop=".3rem"
                          display={"flex"}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            history.push(`/${pushWord}/${item.id}`);
                          }}
                          key={index}
                        >
                          {item?.avatar ? (
                            <Avatar
                              src={getSingleAvatar(item?.avatar)}
                              onClick={() =>
                                history.push(`/${pushWord}/${item.id}`)
                              }
                              style={{ padding: ".4rem" }}
                            ></Avatar>
                          ) : (
                            <Avatar>{item?.name.slice(0, 1)}</Avatar>
                          )}
                        </Box>
                      </Tooltip>
                    );
                  })}
                </Box>
              )}
            </Box>
            {children}
          </Box>
        </Grid>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default PersonasLayout;

const useStyles = makeStyles(() => ({
  sidebarOpen: {
    backgroundColor: "#6748A4",
    width: "21vh !important",
    textAlign: "right",
    transition: "0.5s",
    top: "0",
    overflow: "hidden",
    minHeight: "89vh",
  },
  sidebarClose: {
    backgroundColor: "#6748A4",
    width: "40px",
    textAlign: "right",
    minHeight: "89vh",
    height: "100%",
    transition: "0.5s",
    position: "sticky",
    top: "0",
  },
  menuButton: {
    color: "white",
    border: "1px solid white",
    fontSize: "24px",
    fontWeight: "bold",
    cursor: "pointer",
    width: "45px",
  },
  internalItems: {
    textAlign: "left",
    width: "21vh",
    marginTop: "2rem",
    color: "white",
  },
  addpersona: {
    border: "2px solid white",
    color: "white",
    borderRadius: "26px",
    width: "94%",
    marginTop: "1rem",
    fontWeight: "bold",
    marginLeft: "6px",
  },
}));
