import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";

/**
 *@function NotFound.jsx
 *@author Azim
 *
 **/

const NotFound = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="center"
      style={{
        color: "red",
      }}
    >
      <h1>
        This. NotFound Component go back <Link to="/">Home</Link>
      </h1>
    </Box>
  );
};

export default NotFound;
