import { useState, useEffect } from "react";
import Layout from "Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Tooltip,
} from "@material-ui/core";
import {
  AddCircle,
  ChevronLeft,
  ChevronRight,
  Person,
} from "@material-ui/icons";
import { RButton, Modal, CustomSnacbar } from "Components/Reusable";
import { useToggle } from "hooks/useToggle";
import { personaData } from "assets/Data/personas";
import colors from "assets/Colors";
import { RPersona } from "Components/Reusable/Persona";
import Avatars from "assets/Avatars";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessages } from "Components/Reusable";
import {
  createPersonas,
  getPersonas,
  getPersonasById,
  updatePersonas,
  deletePersona,
  updatePersona,
} from "redux/actions";
import { useHistory } from "react-router-dom";
import { nanoid } from "nanoid";
import tinycolor from "tinycolor2";
import { useParams } from "react-router-dom";
import { ImageOrAvatarSelector } from "Components/BoardComponents";
import { usePersonasStyles } from "assets/Styles/personas";

/**
 *@function Personas.jsx
 *@author Shahed
 *
 **/

const Personas = () => {
  const classes = usePersonasStyles();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isTextChanged, setIsTextChanged] = useToggle();
  const { singlePersona } = useSelector((state) => state.personas);
  const [avatarNew, setAvatarNew] = useState();
  const { user } = useSelector((state) => state.auth);
  const { personas } = useSelector((state) => state.personas);
  const history = useHistory();
  const [mainData, setMainData] = useState([]);
  const [personasData, setPersonasData] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const { id } = useParams();
  const { persona } = useSelector((state) => state.diagram);
  const [justSave, setJustSave] = useState(false);

  // for initial loading page
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // getsingle persona
  useEffect(() => {
    dispatch(getPersonasById(user?.projectId, id));
  }, [id]);

  // setpersonasdata
  useEffect(() => {
    if (id) {
      if (singlePersona && singlePersona.personaItemList) {
        setPersonasData(singlePersona?.personaItemList);
        setAvatarNew(singlePersona?.avatar);
      }
    } else {
      const newpersonasData = personaData.map((item) => {
        const newitem = {
          name: item.name,
          description: item?.description,
          sideColor: item.sideColor,
          backColor: item.backColor,
        };
        return newitem;
      });
      setPersonasData(newpersonasData);
    }
  }, [id, singlePersona]);

  useEffect(() => {
    const indexId = personasData.indexOf((value) => value.id === mainData.id);
    if (indexId > -1) {
      personasData.splice(indexId, 1);
      personasData.push(mainData);
    }
  }, [mainData]);

  // getting personas
  useEffect(() => {
    dispatch(getPersonas(user?.projectId));
  }, []);

  // using hook form for getting data
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleChangeAvatar = (avatar) => {
    setAvatarNew(avatar);
  };

  const getAvatar = () => {
    let newData;
    Object.keys(Avatars).find((key) => {
      if (key === avatarNew) {
        newData = `${Avatars[avatarNew]}`;
        return newData;
      }
      return newData;
    });
    return newData;
  };

  const getSingleAvatar = (data) => {
    let newData;
    Object.keys(Avatars).find((key) => {
      if (key === data) {
        newData = `${Avatars[data]}`;
        return newData;
      }
      return newData;
    });
    return newData;
  };

  const handleAvatarChange = () => {
    return (
      <Modal
        open={isTextChanged}
        handleClose={() => setIsTextChanged(false)}
        settings
      >
        <ImageOrAvatarSelector
          handleChangeBackground={handleChangeAvatar}
          wording={"Select Your Persona Avatar"}
          setOpenSetting={setIsTextChanged}
          avatar
        />
      </Modal>
    );
  };

  // create persona
  const onSubmit = (data) => {
    const newData = {
      ...data,
      avatar: avatarNew,
      projectId: user?.projectId,
    };
    if (id && user?.projectId && justSave) {
      dispatch(updatePersonas(user?.projectId, newData, id, personasData));
    } else {
      if (id && user?.projectId) {
        const newpersonasData = personasData.map((item) => {
          return {
            name: item.name,
            description: item.description,
            sideColor: item.sideColor,
            backColor: item.backColor,
          };
        });
        dispatch(createPersonas(user?.projectId, newData, newpersonasData));
      } else {
        dispatch(createPersonas(user?.projectId, newData, personasData));
      }
    }

    if (personas?.length < 1) {
      const forpersona = {
        name: data.name,
        projectId: user?.projectId,
        id: persona?.id,
      };
      dispatch(updatePersona(forpersona, user?.projectId, persona?.id));
    }
    if (!justSave) {
      history.push("/personas");
      setJustSave(false);
    } else {
      history.push("/");
      setJustSave(false);
    }
  };

  // adding new item
  const addNewItem = () => {
    const findColour = colors.filter(
      (val) => !personasData.map((item) => item?.backColor).includes(val)
    );
    const color = findColour[Math.floor(Math.random() * findColour.length)];
    const backcolor = tinycolor(color).lighten();
    const sidecolor = tinycolor(color).darken();
    const newitem = {
      id: nanoid(),
      name: "New Comment",
      description: "",
      backColor: backcolor.toHexString(),
      sideColor: sidecolor.toHexString(),
    };
    const allitems = [...personasData, newitem];
    setPersonasData(allitems);
  };

  const handleDelete = (id) => {
    const index = personasData.findIndex((value) => value.id === id);
    if (index > -1) {
      personasData.splice(index, 1);
      setPersonasData(personasData);
    }
    setDeleteLoading(true);
    setTimeout(() => {
      setDeleteLoading(false);
    }, 10);
  };
  const handleDeletePersona = (itemid) => {
    dispatch(deletePersona(user?.projectId, itemid));
    setDeleteItem(false);
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  return (
    <Layout pageName={"Personas"}>
      <Grid
        container
        style={{
          padding: "0",
          marginLeft: "0",
          marginTop: "22px",
        }}
      >
        {isTextChanged === true ? handleAvatarChange() : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box
              className={menuOpen ? classes.sidebarOpen : classes.sidebarClose}
            >
              <div
                // className="menuButton"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                {menuOpen ? (
                  <ChevronLeft className={classes.menuButton} />
                ) : (
                  <ChevronRight className={classes.menuButton} />
                )}
              </div>
              {menuOpen ? (
                <Box className={classes.internalItems}>
                  {/*Total number of personas*/}
                  <Typography>Personas</Typography>

                  {/* this section will be looped while api connected */}
                  {personas.map((item, index) => {
                    return (
                      <Tooltip
                        key={index}
                        title={item.name}
                        placement="right"
                        arrow
                      >
                        <Box
                          paddingTop="1rem"
                          display={"flex"}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            history.push(`/personas/${item.id}`);
                          }}
                          key={index}
                        >
                          {item?.avatar ? (
                            <Avatar
                              src={getSingleAvatar(item?.avatar)}
                              onClick={() =>
                                history.push(`/personas/${item.id}`)
                              }
                            ></Avatar>
                          ) : (
                            <Avatar>{item?.name.slice(0, 1)}</Avatar>
                          )}
                          <span
                            style={{
                              paddingTop: "0.8rem",
                              paddingLeft: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            {item?.name.length > 7
                              ? item?.name.slice(0, 7) + "..."
                              : item?.name}
                          </span>
                        </Box>
                      </Tooltip>
                    );
                  })}

                  <RButton
                    type="submit"
                    variant={"outlined"}
                    className={classes.addpersona}
                    wording={id ? "Clone" : "Save"}
                  />
                  {id && (
                    <Button
                      type="submit"
                      variant={"outlined"}
                      className={classes.deletepersona}
                      wording={"Update"}
                      onClick={() => setJustSave(true)}

                    >Update</Button>
                  )}

                  {id && (
                    <Button
                      variant="outlined"
                      className={classes.deletepersona}
                      onClick={() => setDeleteItem({ id: id, alert: true })}
                    >
                      Delete
                    </Button>
                  )}

                  {deleteItem.alert && (
                    <CustomSnacbar
                      opened={deleteItem.alert}
                      alertToDelete={deleteItem.id}
                      handleDeleteFromBackend={handleDeletePersona}
                      setOpenAlert={setDeleteItem}
                      delid={deleteItem.id}
                      DeleteText={"Are you sure you want to delete persona?"}
                      type="adminUser"
                    />
                  )}
                </Box>
              ) : (
                <Box textAlign={"center"}>
                  <Typography style={{ color: "white", paddingTop: "3rem" }}>
                    ({personas?.length})
                  </Typography>
                  {personas.map((item, index) => {
                    return (
                      <Tooltip
                        key={index}
                        title={item?.name}
                        placement="right"
                        arrow
                      >
                        <Box
                          paddingTop=".3rem"
                          display={"flex"}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            history.push(`/personas/${item.id}`);
                          }}
                        >
                          {item?.avatar ? (
                            <Avatar
                              src={getSingleAvatar(item?.avatar)}
                              onClick={() =>
                                history.push(`/personas/${item.id}`)
                              }
                              style={{ padding: ".4rem" }}
                            ></Avatar>
                          ) : (
                            <Avatar>{item?.name.slice(0, 1)}</Avatar>
                          )}
                        </Box>
                      </Tooltip>
                    );
                  })}
                </Box>
              )}
            </Box>
            <Box marginLeft={"1rem"}>
              <Box mb="15px" style={{ position: "fixed", top: "0.7rem", marginLeft: "1rem", zIndex: "9999999999999" }}>
                <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                  Persona
                </div>
                <i style={{ color: "gray" }}>
                  Who
                </i>
              </Box>

              <Paper
                style={{
                  height: "30vh",
                  width: "350px",
                  cursor: "pointer",
                }}
                elevation={2}
              >
                <Avatar
                  className={classes.firstpaper}
                  src={
                    id && singlePersona && !avatarNew
                      ? getSingleAvatar(singlePersona.avatar)
                      : getAvatar()
                  }
                  onClick={() => setIsTextChanged(true)}
                >
                  <Person style={{ fontSize: "46px" }} />
                </Avatar>

              </Paper>
              {id ? singlePersona && Object.keys(singlePersona).length > 0 && !loading && (
                <Paper
                  style={{
                    width: "350px",
                    cursor: "pointer",
                    marginTop: "0.7rem"
                  }}
                  elevation={0}
                >
                  <Controller
                    name="thinking"
                    control={control}
                    defaultValue={singlePersona?.thinking}
                    render={({ field }) => (

                      <TextField
                        {...field}
                        id="outlined-multiline-static"
                        placeholder='Eg. Quotes: "I think therefore I am"'
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                      />)}
                  />
                  <Controller
                    name="description"
                    control={control}
                    defaultValue={singlePersona?.description}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-multiline-static"
                        placeholder='Eg. I am a working dad with 3 children ages 3,6,10. I am also in a band which means I want to spend as much time as possible with my kids and my band. I need my package delivered on time so that I can maximise time with my family'
                        multiline
                        rows={8}
                        style={{ marginTop: "0.5rem" }}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Paper>
              ) : (
                <Paper
                  style={{
                    width: "350px",
                    cursor: "pointer",
                    marginTop: "0.7rem"
                  }}
                  elevation={0}
                >
                  <Controller
                    name="thinking"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-multiline-static"
                        placeholder='Eg. Quotes: "I think therefore I am"'
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                      />)}
                  />
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-multiline-static"
                        placeholder='Eg. I am a working dad with 3 children ages 3,6,10. I am also in a band which means I want to spend as much time as possible with my kids and my band. I need my package delivered on time so that I can maximise time with my family'
                        multiline
                        rows={8}
                        style={{ marginTop: "0.5rem" }}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Paper>
              )}
              {id ? (
                singlePersona &&
                  Object.keys(singlePersona).length > 0 &&
                  !loading ? (
                  <Paper
                    style={{
                      height: "64vh",
                      width: "350px",
                      marginTop: "1rem",
                      border:"1px solid #cbcbcb"
                    }}
                    elevation={0}
                  >
                    <div className={classes.userrcontents} style={{marginTop:"2rem"}}>
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Name is required",
                          },
                          maxLength: {
                            value: 60,
                            message: "Can't be more then 60 characters",
                          },
                          minLength: {
                            value: 3,
                            message: "min character is 3",
                          },
                          pattern: {
                            value: /^[^\s].*$/,
                            message:
                              "Entered value can't start/end or contain only white spacing",
                          },
                        }}
                        error
                        defaultValue={singlePersona?.name}
                        render={({ field }) => (
                          <>
                            <TextField
                              {...field}
                              size="small"
                              id="standard-basic"
                              label="Persona Name"
                              style={{ marginRight: ".5rem" }}
                              fullWidth
                            />
                          </>
                        )}
                      />
                      <ErrorMessages errors={errors} name="name" />
                      <Box display={"flex"} justifyContent="space-between">
                        <Box style={{ width: "100%" }}>
                          <Controller
                            name="gender"
                            control={control}
                            defaultValue={singlePersona?.gender}
                            render={({ field }) => (
                              <>
                                <FormControl
                                  size="small"
                                  variant="standard"
                                  fullWidth
                                >
                                  <InputLabel>Gender </InputLabel>
                                  <Select {...field} label="Gender" fullWidth>
                                    <MenuItem value={"male"}>Male</MenuItem>
                                    <MenuItem value={"female"}>Female</MenuItem>
                                    <MenuItem value={"nonbinary"}>
                                      Non Binary
                                    </MenuItem>
                                    <MenuItem value={"nonapplicable"}>
                                      Non Applicable
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </>
                            )}
                          />
                        </Box>
                        <Box style={{ width: "100%" }}>
                          <Controller
                            name="age"
                            control={control}
                            defaultValue={singlePersona?.age || ""}
                            rules={{
                              pattern: /^[0-9]+$/,
                            }}
                            render={({ field }) => (
                              <>
                                <TextField
                                  {...field}
                                  size="small"
                                  id="standard-basic"
                                  label="Age"
                                  type="number"
                                />
                              </>
                            )}
                          />
                        </Box>
                      </Box>
                      <Controller
                        name="maritalStatus"
                        control={control}
                        defaultValue={singlePersona?.maritalStatus}
                        render={({ field }) => (
                          <>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel>Marital Status</InputLabel>
                              <Select
                                {...field}
                                size="small"
                                label="Marital Status"
                              >
                                <MenuItem value={"unmarried"}>
                                  Unmarried
                                </MenuItem>
                                <MenuItem value={"married"}>Married</MenuItem>
                                <MenuItem value={"divorced"}>Divorced</MenuItem>
                              </Select>
                            </FormControl>
                          </>
                        )}
                      />
                      <ErrorMessages errors={errors} name="maritalStatus" />
                      <Controller
                        name="occupation"
                        control={control}
                        defaultValue={singlePersona?.occupation}
                        render={({ field }) => (
                          <>
                            <TextField
                              {...field}
                              size="small"
                              id="standard-basic"
                              label="Occupation"
                              fullWidth
                            />
                          </>
                        )}
                      />
                      <ErrorMessages errors={errors} name="occupation" />
                      <Controller
                        name="income"
                        control={control}
                        defaultValue={singlePersona?.income}
                        render={({ field }) => (
                          <>
                            <TextField
                              {...field}
                              size="small"
                              id="standard-basic"
                              label="Income"
                              fullWidth
                            />
                          </>
                        )}
                      />
                      <ErrorMessages errors={errors} name="income" />
                      <Controller
                        name="education"
                        control={control}
                        defaultValue={singlePersona?.education}
                        render={({ field }) => (
                          <>
                            <TextField
                              {...field}
                              style={{ marginTop: "1rem" }}
                              size="small"
                              id="standard-basic"
                              placeholder="Eg: Education: Masters Degree"
                              fullWidth
                            />
                          </>
                        )}
                      />
                      <ErrorMessages errors={errors} name="education" />
                      <Controller
                        name="experience"
                        control={control}
                        defaultValue={singlePersona?.experience}
                        render={({ field }) => (
                          <>
                            <TextField
                              {...field}
                              style={{ marginTop: "1rem" }}
                              size="small"
                              id="standard-basic"
                              placeholder="Eg: Tech literacy: High"
                              fullWidth
                            />
                          </>
                        )}
                      />
                      <ErrorMessages errors={errors} name="experience" />
                      <Controller
                        name="personality"
                        control={control}
                        defaultValue={singlePersona?.personality}
                        render={({ field }) => (
                          <>
                            <TextField
                              {...field}
                              style={{ marginTop: "1rem" }}
                              size="small"
                              id="standard-basic"
                              placeholder="Eg: Personality: Introvert"
                              fullWidth
                            />
                          </>
                        )}
                      />
                      <ErrorMessages errors={errors} name="personality" />
                      <Controller
                        name="paymenttype"
                        control={control}
                        defaultValue={singlePersona?.paymenttype}
                        render={({ field }) => (
                          <>
                            <TextField
                              {...field}
                              style={{ marginTop: "1rem" }}
                              size="small"
                              id="standard-basic"
                              placeholder="Eg: Preferred payment type: Cash"
                              fullWidth
                            />
                          </>
                        )}
                      />
                      <ErrorMessages errors={errors} name="paymenttype" />
                      {/* <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ marginTop: "2rem" }}
                        fullWidth
                        onClick={() => setJustSave(true)}
                      >
                        Save
                      </Button> */}
                    </div>
                  </Paper>
                ) : null
              ) : (
                <Paper
                  style={{
                    height: "57vh",
                    width: "350px",
                    marginTop: "1rem",
                    border: "1px solid #cbcbcb"
                  }}
                  elevation={0}
                >
                  <div className={classes.userrcontents} style={{marginTop:"2rem"}}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Name is required",
                        },
                        minLength: {
                          value: 3,
                          message: "min character is 3",
                        },
                        maxLength: {
                          value: 60,
                          message: "Can't be more then 60 characters",
                        },
                        pattern: {
                          value: /^[^\s].*$/,
                          message:
                            "Entered value can't start/end or contain only white spacing",
                        },
                      }}
                      error
                      defaultValue={"Persona1"}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          id="standard-basic"
                          label="Persona Name"
                          style={{ marginRight: ".5rem" }}
                          fullWidth
                        />
                      )}
                    />
                    <ErrorMessages errors={errors} name="name" />
                    <Box display={"flex"} justifyContent="space-between">
                      <Box style={{ width: "100%" }}>
                        <Controller
                          name="gender"
                          control={control}
                          render={({ field }) => (
                            <>
                              <FormControl
                                size="small"
                                variant="standard"
                                fullWidth
                              >
                                <InputLabel>Gender </InputLabel>
                                <Select {...field} label="Gender" fullWidth>
                                  <MenuItem value={"male"}>Male</MenuItem>
                                  <MenuItem value={"female"}>Female</MenuItem>
                                  <MenuItem value={"nonbinary"}>
                                    Non Binary
                                  </MenuItem>
                                  <MenuItem value={"nonapplicable"}>
                                    Non Applicable
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </>
                          )}
                        />
                      </Box>
                      <Box style={{ width: "100%" }}>
                        <Controller
                          name="age"
                          control={control}
                          render={({ field }) => (
                            <>
                              <TextField
                                {...field}
                                size="small"
                                id="standard-basic"
                                label="Age"
                                fullWidth
                              />
                            </>
                          )}
                        />
                      </Box>
                    </Box>
                    <Controller
                      name="maritalStatus"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel>Marrital Status</InputLabel>
                            <Select
                              {...field}
                              size="small"
                              label="Marital Status"
                            >
                              <MenuItem value={"unmarried"}>Unmarried</MenuItem>
                              <MenuItem value={"married"}>Married</MenuItem>
                              <MenuItem value={"divorced"}>Divorced</MenuItem>
                            </Select>
                          </FormControl>
                        </>
                      )}
                    />
                    <Controller
                      name="occupation"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            {...field}
                            size="small"
                            id="standard-basic"
                            label="Occupation"
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <Controller
                      name="income"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            {...field}
                            size="small"
                            id="standard-basic"
                            label="Income"
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <Controller
                      name="education"
                      control={control}
                      defaultValue={singlePersona?.education}
                      render={({ field }) => (
                        <>
                          <TextField
                            {...field}
                            style={{ marginTop: "1rem" }}
                            size="small"
                            id="standard-basic"
                            placeholder="Eg: Education: Masters Degree"
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <ErrorMessages errors={errors} name="education" />
                    <Controller
                      name="experience"
                      control={control}
                      defaultValue={singlePersona?.experience}
                      render={({ field }) => (
                        <>
                          <TextField
                            {...field}
                            style={{ marginTop: "1rem" }}
                            size="small"
                            id="standard-basic"
                            placeholder="Eg: Tech: Literacy: High"
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <ErrorMessages errors={errors} name="experience" />
                    <Controller
                      name="personality"
                      control={control}
                      defaultValue={singlePersona?.personality}
                      render={({ field }) => (
                        <>
                          <TextField
                            {...field}
                            style={{ marginTop: "1rem" }}
                            size="small"
                            id="standard-basic"
                            placeholder="Eg: Personality: Introvert"
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <ErrorMessages errors={errors} name="personality" />
                    <Controller
                      name="paymenttype"
                      control={control}
                      defaultValue={singlePersona?.paymenttype}
                      render={({ field }) => (
                        <>
                          <TextField
                            {...field}
                            style={{ marginTop: "1rem" }}
                            size="small"
                            id="standard-basic"
                            placeholder="Eg: Preferred payment type: Cash"
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <ErrorMessages errors={errors} name="paymenttype" />
                    {/* <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ marginTop: "2rem" }}
                      fullWidth
                      onClick={() => setJustSave(true)}
                    >
                      Save
                    </Button> */}
                  </div>
                </Paper>
              )}
            </Box>
            {!loading ? (
              <Box
                className={classes.commentscontiner}
                style={menuOpen ? { width: "68%" } : { width: "80%" }}
              >
                <Box mb="15px" style={{ position: "fixed", top: "0.7rem", left: "60%", zIndex: "9999999999999" }}>
                  <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                    Empathy Mapping
                  </div>
                  <i style={{ color: "gray" }}>
                    What
                  </i>
                </Box>
                <Grid container className={classes.comments}>
                  {personasData.map((item, index) => {
                    return (
                      <RPersona
                        key={index}
                        menuOpen={menuOpen}
                        id={id}
                        item={item}
                        getItemData={setMainData}
                        handleDelete={handleDelete}
                      />
                    );
                  })}
                  <Box
                    className={classes.secondMain}
                    onClick={() => addNewItem()}
                  >
                    <Box
                      className={classes.addItem}
                      style={
                        menuOpen
                          ? { width: "39.5vh", transition: ".5s" }
                          : { width: "45.2vh", transition: ".5s" }
                      }
                    >
                      <div className={classes.insideitem}>
                        <AddCircle className={classes.circleIcon} />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            ) : null}
          </Box>
        </form>
      </Grid>
    </Layout>
  );
};

export default Personas;
