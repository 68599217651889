import React from "react";
import { useState } from "react";

import Box from "@material-ui/core/Box";
import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { images } from "assets";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Modal } from "Components/Reusable";
import {
  downloadJasperReports,
  downloadTimeSheet,
  downloadjourneymap,
} from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";

export const ExportsData = (props) => {
  const dispatch = useDispatch();
  const handleTimeSheet = () => {
    dispatch(downloadTimeSheet(props?.user?.projectId, props?.user, startDate, endDate));
  };
  const {personas} = useSelector(state => state.personas);
  // const handledownloadJasperReport = () => {
  //   dispatch(downloadJasperReports(props?.user?.projectId));
  // };
  const handleJourneyMapDownload = () => {
    if (personasId){
      dispatch(downloadjourneymap(props.user?.projectId, personasId));
    }
    
  };
  const [downloadModal, setDownloadModal] = useState(false);
  const [personasModal, setPersonasModal] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [personasId, setPersonasId] = useState(null);


  const PersonasModal = () => {
    return (
      <Modal
        settings
        open={personasModal}
        handleClose={() => setPersonasModal(false)}
      >
        <Box>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box  margin="4rem" marginBottom="0rem">
              <Box>
                <Autocomplete
                  options={personas}
                  id="blur-on-select"
                  blurOnSelect
                  getOptionSelected={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, f) => setPersonasId(f.id)}
                  renderOption={(option, { selected }) => (
                    <div>
                      <p
                        style={{
                          color: "black",
                          display: "block",
                          marginLeft: "10px",
                        }}
                      >
                        {option.name}
                      </p>
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      width="100%"
                      label="Personas"
                      variant="outlined"
                    />
                  )}
                />
              </Box>
            </Box>
          </MuiPickersUtilsProvider>
        </Box>
        <Button
          style={{ marginTop: "20px" }}
          variant="outlined"
          color="primary"
          onClick={() => handleJourneyMapDownload()}
        >
          Download Journey Map
        </Button>
      </Modal>
    )
  }

  const TimesheetModal = () => {
    return (
      <Modal
        settings
        open={downloadModal}
        handleClose={() => setDownloadModal(false)}
      >
        <Box>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box display={"flex"} justifyContent="center" paddingTop="4rem">
              <Box>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="From"
                  format="yyyy-MM-dd"
                  value={startDate}
                  onChange={setStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Box>
              <Box paddingLeft="1rem">
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="To"
                  format="yyyy-MM-dd"
                  value={endDate}
                  onChange={setEndDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Box>
            </Box>
          </MuiPickersUtilsProvider>
        </Box>
        <Button
          style={{ marginTop: "20px" }}
          variant="outlined"
          color="primary"
          onClick={() => handleTimeSheet()}
        >
          Download TimeSheet
        </Button>
      </Modal>
    );
  };

  return (
    <Paper>
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          padding={"3rem"}
          paddingBottom={"0"}
        >
          {TimesheetModal()}
          {PersonasModal()}
          <Typography variant="h6">Updates & Stories</Typography>
        </Box>
        <Box padding={"2rem"} paddingTop={"1rem"}>
          <Tooltip title={"Download User Stories"} placement="right" arrow>
            <ListItem key={"users"}>
              <ListItemText primary={"Download User Stories"} />
              <ListItemIcon
                onClick={() => props?.generateCSV()}
                // button={true}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={images.download}
                  style={{
                    height: "32px",
                    width: "32px",
                  }}
                  alt=""
                />
              </ListItemIcon>
            </ListItem>
          </Tooltip>
          <Tooltip title={"Download Time Sheet"} placement="right" arrow>
            <ListItem key={"users"}>
              <ListItemText primary={"Download Time Sheet"} />
              <ListItemIcon
                onClick={() => setDownloadModal(true)}
                // button
                style={{ cursor: "pointer" }}
              >
                <img
                  src={images.download}
                  style={{
                    height: "32px",
                    width: "32px",
                  }}
                  alt=""
                />
              </ListItemIcon>
            </ListItem>
          </Tooltip>
          <Tooltip title={"Download Journey Map"} placement="right" arrow>
            <ListItem key={"users"}>
              <ListItemText primary={"Download Journey Map"} />
              <ListItemIcon
                onClick={() => setPersonasModal(true)}
                // button
                style={{ cursor: "pointer" }}
              >
                <img
                  src={images.download}
                  style={{
                    height: "32px",
                    width: "32px",
                  }}
                  alt=""
                />
              </ListItemIcon>
            </ListItem>
          </Tooltip>
          {/* <Tooltip title={"Download Reports"} placement="right" arrow>
            <ListItem key={"users"}>
              <ListItemText primary={"Download Reports"} />
              <ListItemIcon
                onClick={() => handledownloadJasperReport()}
                // button
                style={{ cursor: "pointer" }}
              >
                <img
                  src={images.download}
                  style={{
                    height: "32px",
                    width: "32px",
                  }}
                  alt=""
                />
              </ListItemIcon>
            </ListItem>
          </Tooltip> */}
        </Box>
      </Box>
    </Paper>
  );
};
