import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-quill/dist/quill.snow.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import {
  ReactFlowProvider,
} from 'reactflow';
import App from './app/App';
import './index.css';
import { Provider } from "react-redux";
import store from './redux/store';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <Router>
      <ReactFlowProvider>
        <App />
      </ReactFlowProvider>
    </Router>
  </Provider>,
);
