// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-multi-carousel-item {
    margin-left: 6px;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/Components/HomeComponents/Workmanagement.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,4BAA4B;IAC5B,2BAA2B;AAC/B","sourcesContent":[".react-multi-carousel-item {\n    margin-left: 6px;\n    transform-style: preserve-3d;\n    backface-visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
