import { Box, Button, Tooltip } from "@material-ui/core";
import TaskDataGrid from "./tasksGrid";
/**
 *@function ManyCards.jsx
 *@author Azim
 *
 **/

const ManyCards = ({
  tasks,
  setTasks,
  classes,
  openManyTasks,
  handleCreateModalClose,
  handleSubmit,
}) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent={`start`}
        marginBottom={3}
        marginTop={2}
      ></Box>
      <TaskDataGrid
        rows={tasks}
        setTasks={setTasks}
        name={openManyTasks.label}
        type={openManyTasks.type}
      />
      <Box
        display="flex"
        justifyContent="right"
        marginTop="30px"
        paddingBottom="20px"
      >
        <Tooltip title="Press (Esc)">
          <Button
            className={classes.submitAndCloseButton}
            style={{
              marginRight: "10px",
              background: "#F5F5F5",
            }}
            onClick={() => handleCreateModalClose(false)}
            variant="outlined"
            type="button"
            color="secondary"
          >
            Close
          </Button>
        </Tooltip>

        <Tooltip title="Press (Ctrl + s)">
          <Button
            className={classes.submitAndCloseButton}
            color="primary"
            variant="contained"
            type="submit"
            id="submit-button"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Tooltip>
      </Box>
    </>
  );
};

export default ManyCards;
