


const laneStyle = {
    borderRadius: "6px",
    background: "#F3F3F3",
    boxShadow: "1px 1px 4px gray",
    paddingTop: "15px",
};

const style = {
    height: "100%",
    backgroundColor: "transparent",
    padding: "10px",
};
const renderStyle = { height: "30px", width: "30px" };

export const globalStyle = {
    style,
    laneStyle,
    renderStyle
}