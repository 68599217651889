import { Grid, Typography, Box } from "@material-ui/core";
import { images } from "assets";
const SignBanner = (props) => {
  return (
    <Grid
      item
      md={6}
      lg={6}
      sm={12}
      xs={12}
      style={{
        background: "#e9e574",
        minHeight: "100vh",
        color: "#373A4D",
        display: "flex",
        justifyContent: "center",
        padding: "3.6rem 0rem",
      }}
    >
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <img
          style={{
            textAlign: "center",
            marginTop: "30px",
            width: "260px",
          }}
          src={images.login}
          alt="login"
        />
        <Box mt="10px">
          <Typography
            style={{ fontWeight: "600", fontSize: "26px", marginLeft: "67px"}}
            align="center"
            variant="h5"

          >
            The home of product
          </Typography>
          <Typography
            style={{ fontWeight: "300", fontSize: "18px", marginLeft: "60px" }}
            align="center"
          >
            Powered by Dynamic User Stories <sup style={{fontSize:"8px"}}>TM</sup>
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default SignBanner;
