import { Grid } from "@material-ui/core";
import React from "react";
import { FirstCard } from "..";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactECharts from "echarts-for-react";
import { getRiskAndIssueItems } from "Utils/chartconfig";

const RiskAndIssueCard = ({ laneCount, classes, layoutSec }) => {
  const history = useHistory();
  return (
    <Grid
      item
      className={classes.cardSection}
      sm={layoutSec ? 6 : 12}
      onClick={() => history.push("/riskandissues")}
    >
      <FirstCard
        titleVariant="h5"
        titleText="Risk and Issues"
        style={{ cursor: "pointer" }}
        subtitle="Delivery: Risk and Issue Management"
      >
        <div
          style={{
            columns: `${parseInt(laneCount.length / 5) + 1} auto`,
          }}
        >
          <ReactECharts
            style={{ height: "250px", width: "200%" }}
            option={getRiskAndIssueItems(laneCount)}
          />
        </div>
      </FirstCard>
    </Grid>
  );
};

export default RiskAndIssueCard;
