import { Buffer } from "buffer"

class Auth {
    #token = null
    constructor() {
        return
    }
    getToken() {
        const token = localStorage.getItem("token") !== null ? atob(localStorage.getItem("token")) : null
        return token
    }
    setToken(token) {
        this.#token = token
        localStorage.setItem("token", new Buffer(token).toString("base64"))
    }
    removeToken() {
        localStorage.removeItem("token")
        this.#token = null
    }
}


const auth = new Auth()
export { auth }