import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

/**
 *@function ShowPassword.jsx
 *@author Azim
 *
 **/

const ShowPassword = ({ onClick, showPassword }) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={() => onClick()}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};

export default ShowPassword;
