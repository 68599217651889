import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { HookFormTextField, Modal } from "Components/Reusable";
import { useDispatch, useSelector } from "react-redux";
import Switch from "@material-ui/core/Switch";
import {
  handleChangeRequest,
  stripeSubscriptionCharge,
  stripeUpdateSubscriptionCharge,
  handleStripeSuccess,
} from "redux/actions";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useCallback, useEffect, useState } from "react";
import { CardInput } from ".";
import { useHistory } from "react-router-dom";
import { images } from "assets";
import axios from "api/axios";
import { uniqBy } from "lodash";
/**
 *@function PricingCount.jsx
 *@author Azim
 *
 **/

const PricingCount = ({ items, existingSeat, update, users }) => {
  const dispatch = useDispatch();
  const [openCardForPayment, setOpenCardForPayment] = useState(false);
  const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);
  const { successStripeRequest, successStripe } = useSelector(
    (state) => state.payment
  );
  const { user } = useSelector((state) => state.auth);
  const [submitting, setsubmitting] = useState(undefined);
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();
  //Hook form state
  const {
    formState: { errors },
    // handleSubmit,
    // reset,
    control,
    watch,
    getValues,
  } = useForm({
    mode: "all",
  });
  const handleClose = () => {
    setOpenCardForPayment(false);
    dispatch(handleChangeRequest(false));
  };
  useEffect(() => {
    if (successStripe) {
      handleClose();
      const timeOut = setTimeout(() => setPaymentSuccessModal(true), 500);
      return () => clearTimeout(timeOut);
    }
  }, [successStripe]);

  // const onSubmit = (data) => {
  //   const usersData = {};
  //   usersData.amount = totalPaid;
  //   usersData.name = user?.name;
  //   usersData.noOfUser = nowAddingUsers;
  //   // console.log({ usersData });
  //   dispatch(saveSubscription(usersData));
  //   reset();
  // };
  const classes = useStyles();

  const handleSubmitSub = async () => {
    if (!stripe || !elements) {
      return;
    }

    const result =
      !update &&
      (await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: user?.username,
        },
      }));

    if (result.error) {
      // console.log(result.error.message);
    } else {
      const requestBody = {
        customerId: user?.stripeCustomerId,
        couponId: getValues("voucherCode") ? getValues("voucherCode") : null,
        paymentMethodId: result?.paymentMethod?.id,
        // priceId: items?.priceId,
        items: items.map((item) => {
          return {
            priceId: item.priceId,
            quantity: item.quantity,
            subItemId: item.id,
          };
        }),
        email: user?.username,
        // subscriptionPlanId: items?.id,
      };
      const updateRequestBody = {
        // customerId: user?.stripeCustomerId,
        // paymentMethodId: result?.paymentMethod.id,
        // couponId: getValues("voucherCode") ? getValues("voucherCode") : null,
        items: items.map((item) => {
          return {
            priceId: item.priceId,
            quantity: item.quantity,
            subItemId: item.id,
          };
        }),
        projectId: user?.projectId,
        stripeSubscriptionId: user?.stripeSubscriptionId,
      };

      if (user.roleType === "ADMIN") {
        if (update) {
          dispatch(stripeUpdateSubscriptionCharge(user?.id, updateRequestBody));
        } else {
          dispatch(stripeSubscriptionCharge(user?.id, requestBody));
        }
      }
    }
  };
  const [state, setState] = useState({
    checkedA: true,
  });

  const handleStepChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const PaymentModal = () => {
    return (
      <Modal open={openCardForPayment} handleClose={handleClose}>
        <Box mt="30px">
          <Card style={{ padding: "20px" }} className={classes.root}>
            <Box mb={1}>
              <img src={images.newHomeLogo} alt="" />
            </Box>
            <CardContent className={classes.content}>
              <form autoComplete="off">
                {update && (
                  <Box
                    display={`flex`}
                    justifyContent={"start"}
                    alignItems={"center"}
                    border="1px dotted gray"
                    padding={"4px"}
                    borderRadius={"4px"}
                  >
                    <Switch
                      checked={state.checkedA}
                      onChange={handleStepChange}
                      color="primary"
                      name="checkedA"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    {!state.checkedA ? (
                      <p>Use new card...</p>
                    ) : (
                      <p>Keep using current card...</p>
                    )}
                  </Box>
                )}
                {update ? (
                  !state.checkedA && (
                    <Box
                      color="primary"
                      my="12px"
                      border="1px dotted gray"
                      padding={"16px"}
                      borderRadius={"4px"}
                    >
                      <CardInput />
                    </Box>
                  )
                ) : (
                  <Box
                    color="primary"
                    my="0px"
                    border="1px dotted gray"
                    padding={"16px"}
                    borderRadius={"4px"}
                  >
                    <CardInput />
                  </Box>
                )}

                <Box mt="30px">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={successStripeRequest ? true : false}
                    className={classes.button}
                    onClick={handleSubmitSub}
                  >
                    {update ? "Update subscription" : "Subscribe"}
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    );
  };
  const PaymentSuccessModal = () => {
    return (
      <Modal
        modalStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        open={paymentSuccessModal}
      >
        <Box mt="30px" justifyContent={`center`} alignItems="center">
          Stripe Subscription {update ? "updated" : "created"} successfully.
        </Box>
        <Box mt="30px" justifyContent={`center`} alignItems="center">
          <Button
            variant="contained"
            color="primary"
            disabled={successStripeRequest ? true : false}
            className={classes.button}
            onClick={() => {
              setPaymentSuccessModal(false);
              dispatch(handleChangeRequest(false));
              dispatch(handleStripeSuccess(false));
              history.push("/addUsers");
            }}
          >
            Click to add user(s)
          </Button>
        </Box>
      </Modal>
    );
  };
  watch(["voucherCode"]);

  const voucherCode = getValues("voucherCode");
  // const voucherData = (value) => {
  //   const Voucher = VoucherCode.find((data) => data.name === value);
  //   if (Voucher) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const fetchDiscount = useCallback(async (code) => {
    const response = await axios.get("/v1/coupons");
    if (response && response.status) {
      setsubmitting(
        response.data.data.find(
          (data) => data.promotionalCode === code || data.name === code
        )
      );
    }
  }, []);

  useEffect(() => {
    if (voucherCode) {
      fetchDiscount(voucherCode);
    }
    // return () => setsubmitting(undefined);
  }, [voucherCode, fetchDiscount]);

  // styles
  const styles = {
    heads: {
      backgroundColor: "#DFEBFA",
      padding: "0.5rem 4rem 0.5rem 4rem",
      borderRadius: "15px",
      fontWeight: "bolder",
      fontSize: "15px",
    },
  };

  const purchaseAmount = () =>
    items.reduce((item, value) => item + value.amount * value.quantity, 0);

  return (
    <Box maxWidth={`320px`} marginLeft={3}>
      <Box
        minWidth={`320px`}
        boxShadow={2}
        padding={2}
        sx={{ borderRadius: "15px" }}
      >
        {openCardForPayment && PaymentModal()}
        {paymentSuccessModal && PaymentSuccessModal()}
        <div style={{ textAlign: "center" }}>
          <span style={styles.heads}>User Seat Summary</span>
        </div>
        <div style={styles.body}>
          <Box
            display={"flex"}
            justifyContent={`space-between`}
            paddingLeft={3}
            paddingRight={3}
            paddingTop={3}
          >
            <div style={{ fontWeight: "800" }}>Existing seats</div>
            <div style={{ fontWeight: "800" }}>
              {existingSeat() > 0 ? existingSeat() : 0}
            </div>
          </Box>
          <Box
            display={"flex"}
            justifyContent={`space-between`}
            paddingLeft={3}
            paddingRight={3}
          >
            <div style={{ fontWeight: "800" }}>Used Seats</div>
            <div style={{ fontWeight: "800" }}>
              {uniqBy(users, "id").length > 0 ? uniqBy(users, "id").length : 0}
            </div>
          </Box>
          <Box
            display={"flex"}
            justifyContent={`space-between`}
            paddingLeft={3}
            paddingRight={3}
          >
            <div style={{ fontWeight: "800" }}>
              Number of seats after purchase
            </div>
            <div style={{ fontWeight: "800" }}>
              {items && items.length > 0
                ? items.reduce(
                    (item, value) => item + value.noOfUser * value.quantity,
                    0
                  )
                : 0}
            </div>
          </Box>
        </div>
      </Box>

      <Box
        minWidth={`320px`}
        boxShadow={2}
        sx={{ borderRadius: "15px" }}
        marginTop={2}
        paddingTop={3}
      >
        <div style={{ textAlign: "center" }}>
          <span style={styles.heads}>Bill Summary</span>
        </div>
        <Box className={classes.boxStyle} padding={2}>
          <Box
            display={"flex"}
            justifyContent={`space-between`}
            paddingLeft={3}
            paddingRight={3}
          >
            <div style={{ fontWeight: "800" }}>
              {items && items.length > 0 ? items.length : "No"} Plan :
            </div>
            <div style={{ fontWeight: "800" }}>
              {items && items.length ? `$${purchaseAmount()}` : 0}
            </div>
          </Box>
          <Box
            paddingTop={"1rem"}
            marginTop={"1rem"}
            style={items?.length > 0 ? { borderTop: "2px solid #EEEEEE" } : {}}
          >
            {items &&
              items.map((item, index) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent={`space-between`}
                    paddingLeft={3}
                    paddingRight={3}
                    key={index}
                  >
                    <div style={{ fontWeight: "800" }}>
                      {item.quantity} {item.name} User License
                    </div>
                    <div style={{ fontWeight: "800" }}>
                      {item.quantity * item.amount}
                    </div>
                  </Box>
                );
              })}
          </Box>
          <div style={{ border: "1px solid #eeeeee", marginTop: "1rem" }}></div>

          <Box paddingLeft={3} paddingTop={1}>
            <div style={{ fontWeight: "800", paddingBottom: "10px" }}>
              Total Per Month :
            </div>
            <div style={{ fontWeight: "800", fontSize: "26px" }}>
              {items && items.length > 0
                ? `$${
                    submitting
                      ? submitting?.discountType === "PERCENTAGE"
                        ? purchaseAmount() -
                          (submitting.discountAmount / 100) * purchaseAmount()
                        : purchaseAmount() - submitting.discountAmount / 100
                      : purchaseAmount()
                  }`
                : 0}
            </div>
          </Box>
        </Box>

        <Box
          justifyContent="center"
          flexDirection="column"
          display="flex"
          alignItems="center"
          paddingLeft={3}
          paddingRight={3}
          paddingBottom={3}
        >
          {!update ? (
            <HookFormTextField
              name="voucherCode"
              control={control}
              errors={errors}
              rules={{
                maxLength: {
                  value: 2,
                  // message: "Oops! Voucher is not available.",
                },
                // validate: voucherData,
              }}
              label="Add discount code"
            />
          ) : null}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={
              items?.length > 0
                ? items.reduce(
                    (item, value) => item + value.noOfUser * value.quantity,
                    0
                  ) >= uniqBy(users, "id")?.length
                  ? false
                  : true
                : true
            }
            type="submit"
            style={!update ? { marginTop: "10px" } : {}}
            // onClick={() =>
            //   update ? handleSubmitSub() : setOpenCardForPayment(true)
            // }
            onClick={() => setOpenCardForPayment(true)}
          >
            {!update ? `Pay now to add user(s)` : "Update Subscription"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PricingCount;

const useStyles = makeStyles(() => ({
  textStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "180px",
    marginTop: "10px",
  },
  boxStyle: {
    fontSize: "14px",
    color: "#000000",
    lineHeight: "21px",
    marginBottom: "20px",
  },
}));
