import { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Layout from "Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "Components/Reusable";
import {
  getProjectByUserId,
  getUserSubscriptionDetails,
  getAllSubscription,
  getUserAndProjectByAdminId,
} from "redux/actions";
import { PricingCount, SingleBox } from "Components/PricingComponents";
import { uniqBy } from "lodash";
import { Redirect } from "react-router-dom";

/**
 *@function UpdatePricing.jsx
 *@author Azim
 *
 **/

const UpdatePricing = () => {
  const [active, setactive] = useState();
  const [items, setItems] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.orgprofile);
  const { users, plans, getSinglePayData } = useSelector(
    (state) => state.payment
  );
  useEffect(() => {
    if (user?.id) {
      dispatch(getUserSubscriptionDetails(user?.id));
      dispatch(getProjectByUserId(user?.id));
    }
  }, [user?.id, dispatch, user?.subscriptionPlanId]);


  useEffect(() => {
    if (user?.id) {
      dispatch(getAllSubscription(user?.id));
      dispatch(getUserAndProjectByAdminId(user?.id));
    }
  }, [user?.id, dispatch]);

  
  const getExistingUser = useCallback(() => {
    const newplans = getSinglePayData?.items?.map(item => {
      const newItem = plans.find(value => value.priceId === item.priceId)
      return {
        quantity: item?.quantity,
        users: newItem?.noOfUser
      }
    })
    const numberOfSeats = newplans?.reduce((item, value) => item + (value.quantity * value.users), 0)
    const alluser = uniqBy(users, "id");
    const existingUser = numberOfSeats - alluser.length
    return existingUser
  }, [getSinglePayData, users,plans]);


  const getAllItems = useCallback(() => {
    const allplans = getSinglePayData?.items?.map(item => {
      // console.log({item})
      const newItem = plans.find(value => value.priceId === item.priceId)
      // console.log({newItem})
      const anotherNewItem = plans.find(value => value.name === newItem.name && value.visible === true)
      // console.log({anotherNewItem})
      return {
        ...anotherNewItem,
        quantity: item.quantity
      }
    })
    setItems(allplans)
  },[plans, getSinglePayData])

  useEffect(() => {
    getAllItems()
  }, [getAllItems])
  

  // handle Change Element
  const handleChange = (data) => {
    setactive(data);
    // const activeItem = plans.find((value) => value.name === data.name);
    const findIndexofNewItem = items.findIndex(value => value.id === data.id)
    if (findIndexofNewItem !== -1) {
      items.splice(findIndexofNewItem, 1)
    }
    const itemsnew = [...items, data]
    setItems(itemsnew);
  };


  if (user.roleType === "USER") {
    return <Redirect to="/" />
  }

  return (
    <Layout pageName={"Add Seats"}>
      <h3
        style={{
          padding: "0px 0px 0px 10px",
          marginTop: "10px",
          marginLeft: "8px",
        }}
      >
        {getSinglePayData?.noOfUser && "Update your seat bundle."}
      </h3>
      <Grid
        container
        style={{
          padding: "0px 10px 10px 10px",
          marginTop: "10px",
          marginLeft: "8px",
        }}
      >
        <Grid item xs={12} md={6}>
          {loading ? (
            <Loader />
          ) : (
            <Box>
              {plans.filter(value => value?.visible === true).map((item, index) => (
                <SingleBox
                  key={index}
                  item={item}
                  active={active}
                  allplans={items}
                  deactivateItem={getSinglePayData}
                  click={handleChange}
                  users={users}
                />
              ))}
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <PricingCount
            user={user}
            update
            existingSeat={getExistingUser}
            items={items}
            users={users}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default UpdatePricing;
