import { Modal } from "Components/Reusable";
import { Box, } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { ErrorMessages } from "Components/Reusable";
import { Controller } from "react-hook-form";


export const UpdateBoardModal = ({
    updateBoardModal,
    setUpdateBoardModal,
    resetUpdate,
    setModalData,
    handleUpdateSubmit,
    onUpdateBoardSubmit,
    control,
    modalData,
    classes,
    formData,
    alertToDelete,
    attemptToDeleteBoard
}) => {
    return (
        <Modal
            open={updateBoardModal}
            handleClose={() => {
                setUpdateBoardModal(false);
                resetUpdate();
                setModalData({});
            }}
        >
            <h1 style={{ textAlign: "left" }}>Update Board</h1>
            <div>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleUpdateSubmit(onUpdateBoardSubmit)}
                >
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={modalData?.name}
                        rules={{
                            required: {
                                value: true,
                                message: "This is required",
                            },
                            minLength: {
                                value: 3,
                                message: "min character 3",
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Board name*"
                                variant="outlined"
                                fullWidth
                                type="text"
                                style={{ marginTop: "12px" }}
                            />
                        )}
                    />
                    <Controller
                        name="boardColor"
                        control={control}
                        defaultValue={modalData?.boardColor}
                        rules={{
                            required: {
                                value: true,
                                message: "This is required",
                            },
                            minLength: {
                                value: 3,
                                message: "min character 3",
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className={classes.boardColor}
                                label="Board color*"
                                variant="outlined"
                                fullWidth
                                placeholder="Click to change board color"
                                type="color"
                                style={{ marginTop: "22px" }}
                            />
                        )}
                    />
                    <ErrorMessages errors={formData} name="name" />
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-right"
                        mt="10px"
                    >
                        <Button
                            style={{ marginRight: "13px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Update
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            alertToDelete={alertToDelete}
                            onClick={() => attemptToDeleteBoard(modalData)}
                            type="button"
                        >
                            delete
                        </Button>
                    </Box>
                </form>
            </div>
        </Modal>
    );
};