import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
  TextareaAutosize,
  Tooltip,
  Avatar,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import {
  Check as CheckIcon,
  Warning as WarningIcon,
  Clear as ClearIcon,
  Add as AddIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Layout from "Components/Layout";
import Board from "react-trello";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLaneForRisksAndIssue,
  issuecardUpdate,
  getPersona,
  getOrganizationProfile,
  addNewCardToRiskAndIssueKanban,
  updateLanePositionForRiskKanban,
  deleteCardFromRiskAndIssue,
  cardPositionFromRiskKanban,
  addNewLaneForDefaultKanban,
  getAllFilesFromBackendBasedOnCard,
  removeAllAttachmentsFromSingleCardRisk,
  getTreatmentCards,
} from "redux/actions";
import DateFnsUtils from "@date-io/date-fns";
import { Controller, useForm } from "react-hook-form";
import {
  AddButton,
  Attachments,
  LaneForRisk,
  CustomCardForRiskAndJourney,
  ShowNotes,
  DropzoneSection,
  AddNewCard,
  PopoverForSeverity,
  PopoverForLikelihood,
  PopoverForNewAssignee,
} from "Components/BoardComponents";
import { images } from "assets";
import Avatars from "assets/Avatars";
import { nanoid } from "nanoid";
import {
  CustomSnacbar,
  ErrorMessages,
  SVGRender,
  Modal,
  Loader,
  UserAvatar,
  RIconButton,
  DraftEditor,
  QuillText,
} from "Components/Reusable";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { changeArrayPositionByIndexNumber, convertArrayToObject } from "Utils";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
import { format } from "date-fns";
import { riskAndIssuesData } from "assets/Data/RiskAndIssue";
import { useStyles } from "assets/Styles/RiskAndIssue";
import { writingComment } from "helpers/Board";
import ModalCloseSnackBar from "Components/Reusable/CustomSnacbar/modalclose";
import { riskFormData, riskMultipleFormData } from "helpers/RiskAndIssue";
/**
 *@function RiskIssues.jsx
 *@author Azim
 *
 **/

const RiskIssues = () => {
  //state of this file
  const domLocation = window?.location?.href;
  /* Modal related states */
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForAddCard, setModalOpenForAddCard] = useState(false);
  //popover
  // const [anchorEl, setAnchorEl] = useState(null);
  const [anchorForAssignee, setAnchorForAssignee] = useState(null);
  const [anchorSeverityChange, setAnchorSeverityChange] = useState(null);
  const [anchorLikelihoodChange, setAnchorLikelihoodChange] = useState(null);
  //create evidence link
  const [evidenceLinks, setEvidenceLinks] = useState([]);
  const [updateEvidenceLinks, setUpdateEvidenceLinks] = useState([]);
  const [updateEvidences, setUpdateEvidences] = useState([]);
  const [defaultValuesForCard, setDefaultValuesForCard] = useState({});
  const [singleSeverity, setSingleSeverity] = useState({});
  const [singleLikelyHood, setSingleLikelyHood] = useState({});

  const [singleAssigneeForBoard, setSingleAssigneeForBoard] = useState({});
  const [assesments, setAssesments] = useState([]);
  const [assesmemntsDefaultValue, setAssesmemntsDefaultValue] = useState([]);
  const [currentLikeliHood, setCurrentLikelihood] = useState([]);
  const [addCardsToIceBoxInTheBoard, setAddCardsToIceBoxInTheBoard] = useState(
    []
  );

  //alert to delete
  const [openAlert, setOpenAlert] = useState(false);
  const [alertToDelete, setAlertToDelete] = useState({});
  const [openToChangeTheTitle, setOpenToChangeTheTitle] = useState(false);
  //file related states
  const [filesToSave, setFilesToSave] = useState([]);
  const [updateFilesToSave, setUpdateFilesToSave] = useState([]);

  const [cardData, setCardData] = useState({});
  const [cardId, setCardId] = useState("");
  // comment related states
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [openToComment, setOpenToComment] = useState(false);
  const [savedDescription, SetSavedDescription] = useState([]);
  const [updateDescription, setUpdateDescription] = useState(false);
  const [updateDescriptionState, setUpdateDescriptionState] = useState("");
  const [updatenotes, setUpdatenotes] = useState(false);
  const [updateToNotes, setUpdateToNotes] = useState(null);

  const [editorUpdateState, setEditorUpdateState] = useState(
    EditorState.createWithContent(convertFromRaw(riskAndIssuesData?.rawContent))
  );
  const { search } = useLocation();
  useEffect(() => {
    if (updateToNotes) {
      setEditorUpdateState(
        EditorState.createWithContent(convertFromRaw(updateToNotes.comment))
      );
    }
  }, [updateToNotes]);
  useEffect(() => {
    const parse = querystring.parse(search);
    if (parse.card !== undefined) {
      setCardId(parse.card);
    }
  }, []);
  /* icons related states */
  const [sizeOfProblem, setSizeOfProblem] = useState(0);
  const [updateSizeOfProblem, setUpdateSizeOfProblem] = useState(0);
  // const [cardType, setCardType] = useState(0);
  /* date related states */
  const [updateSelectedDate, setUpdateSelectedDate] = useState(null);

  /* Estimate related states */

  /* global(redux) related states */
  const dispatch = useDispatch();
  const { riskKanbanData } = useSelector((state) => state.riskandissue);
  const { attachments, treatments } = useSelector(
    (state) => state.riskandissue
  );
  const { kanbanData } = useSelector((state) => state.kanban);
  const { user } = useSelector((state) => state.auth);
  const { valueStream, persona } = useSelector((state) => state.diagram);
  const { organizationProfile } = useSelector((state) => state.orgprofile);
  // update card close
  const [openalertnew, setOpenAlertnew] = useState(false);
  useEffect(() => {
    if (user?.projectId) {
      dispatch(getAllLaneForRisksAndIssue(user?.projectId));
      // dispatch(getAllCardsForRisksAndIssue(user?.projectId));
    }
  }, [dispatch, user?.projectId]);
  useEffect(() => {
    dispatch(getPersona(user?.projectId));
  }, [dispatch, user?.projectId]);

  useEffect(() => {
    if (user?.projectId) {
      dispatch(getOrganizationProfile(user?.projectId));
    }
  }, [user?.projectId, dispatch]);

  const {
    formState: { errors: createError },
    handleSubmit: handleCreateSubmit,
    reset: resetForCreate,
    control: controlForCreate,
    setValue,
  } = useForm({
    mode: "all",
  });
  const {
    formState: { errors, isDirty },
    handleSubmit: handleUpdateSubmit,
    reset,
    control,
    getValues,
    // setValue,
  } = useForm({
    mode: "all",
    defaultValues: defaultValuesForCard,
  });
  useEffect(() => {
    if (cardData) {
      setDefaultValuesForCard({ ...cardData, ...assesmemntsDefaultValue });
      reset(cardData);
    }
  }, [cardData, reset]);

  const updateTitle = getValues("title");
  //Kanban Data for Board
  // const kanbanDataForCreate = [...riskKanbanData];
  const newKanbanData = riskKanbanData.map((item) => {
    const newItem = { ...item };
    if (item.notDeletable) {
      return newItem;
    } else {
      newItem.disallowAddingCard = true;
      return newItem;
    }
  });
  const data = {
    lanes: newKanbanData ? newKanbanData : [],
  };
  const handleEditCard = (e) => {
    setModalOpen(true);
    const particularCard = riskKanbanData.map((item) => item?.cards);
    const releaseCard = particularCard.flat(2);
    if (releaseCard) {
      const newCardModalUser = releaseCard.find((item) => item?.id === e);
      setCardData(newCardModalUser);
      setUpdateSizeOfProblem(newCardModalUser?.type === "risk" ? 0 : 1);
      if (newCardModalUser?.assessments) {
        setAssesments(JSON.parse(newCardModalUser?.assessments));
        setAssesmemntsDefaultValue(
          convertArrayToObject(
            JSON.parse(newCardModalUser?.assessments),
            "name"
          )
        );
      }
      if (newCardModalUser?.evidenceLinks) {
        setUpdateEvidences(JSON.parse(newCardModalUser?.evidenceLinks));
      }
      setUpdateSelectedDate(
        newCardModalUser?.dueDate ? newCardModalUser?.dueDate : null
      );
      SetSavedDescription(
        newCardModalUser?.notes ? JSON.parse(newCardModalUser?.notes) : []
      );
    }
  };

  useEffect(() => {
    if (cardId !== "") {
      window.history.pushState(
        null,
        "",
        window.location.origin + window.location.pathname + `?card=${cardId}`
      );
      dispatch(getAllFilesFromBackendBasedOnCard(user?.projectId, cardId));
      dispatch(getTreatmentCards(cardId));

      // dispatch(getSingleCardForRiskAndIssues(user?.projectId, cardId));
      handleEditCard(cardId);
    }
  }, [cardId, riskKanbanData, dispatch]);

  const handleDeleteFromBackend = (id) => {
    dispatch(deleteCardFromRiskAndIssue(user?.projectId, id));
    setOpenAlert(false);
  };

  const handleCardToAnotherLane = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    const targetedIndexData = riskKanbanData.find(
      (item) => item.id === targetLaneId
    );
    const makedFunCards = [...targetedIndexData.cards];
    const fes = makedFunCards.findIndex((item) => item.id === cardId);
    if (fes === position) {
      return;
    }

    const cardMovedDetailsForLane = { ...cardDetails };
    cardMovedDetailsForLane.riskLaneId = targetLaneId;
    cardMovedDetailsForLane.projectId = user?.projectId;

    if (sourceLaneId === targetLaneId) {
      const respondedArray = changeArrayPositionByIndexNumber(
        fes,
        position,
        cardMovedDetailsForLane,
        makedFunCards
      );
      const INdexedResponse = respondedArray.map((item, index) => {
        const singleItem = { ...item };
        singleItem.indexNumber = index;
        return singleItem;
      });
      dispatch(cardPositionFromRiskKanban(user?.projectId, INdexedResponse));
    } else {
      makedFunCards.splice(position, 0, cardMovedDetailsForLane);
      const uniqueCards = Array.from(
        new Set(makedFunCards.map((a) => a.id))
      ).map((id) => {
        return makedFunCards.find((a) => a.id === id);
      });
      const INdexedResponse = uniqueCards.map((item, index) => {
        const singleItem = { ...item };
        singleItem.indexNumber = index;
        return singleItem;
      });
      dispatch(cardPositionFromRiskKanban(user?.projectId, INdexedResponse));
    }
  };
  const handleLaneDragEnd = (removedIndex, addedIndex, payload) => {
    const newUpdatedLanes = [...riskKanbanData];
    const updatedAe = changeArrayPositionByIndexNumber(
      removedIndex,
      addedIndex,
      payload,
      newUpdatedLanes
    );
    const INdexedResponse = updatedAe.map((item, index) => {
      const singleItem = { ...item };
      singleItem.serial = index;
      return singleItem;
    });
    dispatch(updateLanePositionForRiskKanban(user?.projectId, INdexedResponse));
  };

  const renderStyle = { height: "24px", width: "24px" };
  const openAssignee = Boolean(anchorForAssignee);
  const openSeverity = Boolean(anchorSeverityChange);
  const openLikeLihood = Boolean(anchorLikelihoodChange);
  const openAssigneeid = openAssignee ? "simple-popover" : undefined;
  const handleAddEvidenceLink = () => {
    setEvidenceLinks([
      ...evidenceLinks,
      {
        id: nanoid(15),
        link: "",
      },
    ]);
  };
  const handleAddUpdateEvidenceLink = () => {
    setUpdateEvidenceLinks([
      ...updateEvidenceLinks,
      {
        id: nanoid(15),
        link: "",
      },
    ]);
  };
  const handleAddSelectedEvidenceLink = (e, item) => {
    const getEvidenceValue = e.target.value;
    const getData = evidenceLinks.find((items) => items.id === item.id);
    getData.link = getEvidenceValue;
    const indexedEvidence = evidenceLinks.findIndex(
      (info) => info.id === getData.id
    );
    evidenceLinks[indexedEvidence] = getData;
    setEvidenceLinks(evidenceLinks);
  };
  const handleUpdateEvidenceLink = (e, item) => {
    const getEvidenceValue = e.target.value;
    const getData = updateEvidenceLinks.find((items) => items.id === item.id);
    getData.link = getEvidenceValue;
    const indexedEvidence = updateEvidenceLinks.findIndex(
      (info) => info.id === getData.id
    );
    updateEvidenceLinks[indexedEvidence] = getData;
    setUpdateEvidenceLinks(updateEvidenceLinks);
  };
  const handleAddTaskforIcebox = (e, items) => {
    const getTaskTitle = e.target.value;
    const getData = addCardsToIceBoxInTheBoard.find(
      (item) => item.id === items.id
    );
    getData.title = getTaskTitle;
    const indexedEvidence = addCardsToIceBoxInTheBoard.findIndex(
      (info) => info.id === getData.id
    );
    addCardsToIceBoxInTheBoard[indexedEvidence] = getData;
    setAddCardsToIceBoxInTheBoard(addCardsToIceBoxInTheBoard);
  };
  const handleAddDueDateForIceBoxCard = (date, items) => {
    const getData = addCardsToIceBoxInTheBoard.find(
      (item) => item.id === items.id
    );
    getData.dueDate = date;
    const indexedDueDate = addCardsToIceBoxInTheBoard.findIndex(
      (info) => info.id === getData.id
    );
    addCardsToIceBoxInTheBoard[indexedDueDate] = getData;
    setAddCardsToIceBoxInTheBoard(addCardsToIceBoxInTheBoard);
  };
  const handleRemoveCreatedEvidence = (item) => {
    setEvidenceLinks(evidenceLinks.filter((val) => val.id !== item.id));
  };
  const handleRemoveCreatedUpdateEvidence = (item) => {
    setUpdateEvidenceLinks(
      updateEvidenceLinks.filter((val) => val.id !== item.id)
    );
  };

  const renderUpdateNotes = () => {
    return (
      <Box>
        <Grid
          style={{
            maxWidth: "1040px",
            borderRadius: "8px",
          }}
        >
          <DraftEditor
            editorState={editorUpdateState}
            onEditorStateChange={onCommentDataUpdateChange}
            suggestions={suggestions}
          />
        </Grid>
        <Box mt={1} align="left">
          <Button variant="contained" onClick={updateNotesData}>
            Update
          </Button>
          <Button style={{ marginLeft: "8px" }} onClick={cancelupdateNotesData}>
            cancel
          </Button>
        </Box>
      </Box>
    );
  };

  const handleUpdateCardClose = (cancelBtn) => {
    if (cancelBtn === false) {
      const newclickbtn = window.document.getElementById("custom-risk-button");
      // console.log(newclickbtn)
      newclickbtn?.click();
    }
    if (updateFilesToSave.length > 0 || isDirty === true) {
      setOpenAlertnew(true);
    } else {
      handleUpdateCardCloseConfirm();
    }
  };

  const handleUpdateCardCloseConfirm = () => {
    window.history.pushState(
      null,
      "",
      window.location.origin + window.location.pathname
    );
    setModalOpen(false);
    setUpdateDescription(false);
    setUpdateFilesToSave([]);
    SetSavedDescription([]);
    setAddCardsToIceBoxInTheBoard([]);
    setOpenToComment(false);
    setAssesments([]);
    setOpenToChangeTheTitle(false);
    setCardId("");
    reset(cardData);
    setUpdateEvidences([]);
    setUpdateEvidenceLinks([]);
    setUpdateDescription(false);
    setOpenAlertnew(false);
    setUpdateDescriptionState("");
    dispatch(removeAllAttachmentsFromSingleCardRisk());
  };
  const handleCardSection = () => {
    return (
      <Modal
        open={modalOpen}
        // riskAndIssue
        board
        overflowAuto
        handleClose={() => handleUpdateCardClose(false)}
      >
        {newRenderUpdateModal()}
      </Modal>
    );
  };
  // handleAddCardToTheTrelloBoard
  const handleCreateModalClose = () => {
    setModalOpenForAddCard(false);
    setEvidenceLinks([]);
    resetForCreate();
  };
  const handleAddCardToTheTrelloBoard = () => {
    const getPropsForAddCard = {
      handleCreateSubmit,
      onCreateSubmit,
      classes,
      handleChangeTypeOfSize,
      sizeOfProblem,
      controlForCreate,
      createError,
      handleAddEvidenceLink,
      handleCreateModalClose,
      evidenceLinks,
      handleAddSelectedEvidenceLink,
      setFilesToSave,
      handleRemoveCreatedEvidence,
      setValue,
    };
    return (
      <Modal
        open={modalOpenForAddCard}
        board
        handleClose={handleCreateModalClose}
      >
        <Box p="10px">
          <AddNewCard {...getPropsForAddCard} />
        </Box>
      </Modal>
    );
  };
  //handleDeleteCard
  const handleDeleteCard = (erase) => {
    setOpenAlert(true);
    setAlertToDelete(erase);
  };

  const onUpdateSubmit = (data) => {
    const UpdateformData = new FormData();
    const getMapedData = assesments.map((item) => {
      const getItem = { ...item };
      if (item.name === "impactOnTime") {
        getItem.value = data?.impactOnTime;
      }
      if (item.name === "impactOnCost") {
        getItem.value = data?.impactOnCost;
      }
      if (item.name === "impactOnSatisfaction") {
        getItem.value = data?.impactOnSatisfaction;
      }
      if (item.name === "impactOnReputation") {
        getItem.value = data?.impactOnReputation;
      }
      return getItem;
    });
    UpdateformData.append("assessments", JSON.stringify(getMapedData));
    UpdateformData.append("createDate", new Date());
    UpdateformData.append(
      "description",
      updateDescriptionState ? updateDescriptionState : cardData?.description
    );
    UpdateformData.append(
      "evidenceLinks",
      updateEvidenceLinks
        ? JSON.stringify([...updateEvidences, ...updateEvidenceLinks])
        : JSON.stringify(updateEvidences)
    );
    if (updateSizeOfProblem === 0) {
      UpdateformData.append("type", "risk");
    }
    if (updateSizeOfProblem === 1) {
      UpdateformData.append("type", "issue");
    }
    UpdateformData.append("id", cardData?.id);
    UpdateformData.append("notes", JSON.stringify(savedDescription));
    UpdateformData.append("riskLaneId", cardData?.riskLaneId);
    UpdateformData.append("title", data.title ? data.title : cardData?.title);
    UpdateformData.append("projectId", cardData?.projectId);
    UpdateformData.append("attachmentList", cardData?.attachmentList);
    for (let pic of updateFilesToSave) {
      UpdateformData.append("attachments", pic);
    }
    if (addCardsToIceBoxInTheBoard.length > 0) {
      const nowStage = addCardsToIceBoxInTheBoard.filter(
        (item) => item.title !== ""
      );
      const updatedToTheIceBox = nowStage.map((item) => {
        const itemsForIcebox = {};
        itemsForIcebox.choresLabel = item?.title;
        itemsForIcebox.dueDate = item.dueDate;
        itemsForIcebox.id = item.id;
        itemsForIcebox.laneId = kanbanData[0].id;
        itemsForIcebox.riskCardId = cardData?.id;
        itemsForIcebox.metadata = JSON.stringify([
          writingComment(
            user,
            data,
            riskAndIssuesData.rawContentForIceBox(domLocation)
          ),
        ]);
        itemsForIcebox.projectId = user?.projectId;
        itemsForIcebox.userId = item.userId;
        return itemsForIcebox;
      });
      dispatch(
        addNewLaneForDefaultKanban(
          user?.projectId,
          user?.kanbanId,
          updatedToTheIceBox,
          cardId,
          cardData?.id
        )
      );
    }
    dispatch(issuecardUpdate(user?.projectId, cardData?.id, UpdateformData));
    handleUpdateCardCloseConfirm();
  };

  const onCreateSubmit = (data) => {
    const submittedData = riskFormData(
      user,
      data,
      evidenceLinks,
      riskKanbanData,
      sizeOfProblem,
      filesToSave
    );
    if (data.tasks.length > 0) {
      data.tasks.forEach((item) => {
        const formDataTask = riskMultipleFormData(
          user,
          item,
          evidenceLinks,
          riskKanbanData,
          sizeOfProblem,
          filesToSave
        );
        dispatch(addNewCardToRiskAndIssueKanban(user?.projectId, formDataTask));
      });
    } else {
      dispatch(addNewCardToRiskAndIssueKanban(user?.projectId, submittedData));
    }
    handleCreateModalClose();
  };

  //raw wyswying Editor
  const onEditorStateChange = (editorState) => setEditorState(editorState);
  const onCommentDataUpdateChange = (data) => {
    setEditorUpdateState(data);
  };

  const saveComment = () => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    SetSavedDescription((prev) => [
      ...prev,
      writingComment(user, data, rawContent),
    ]);
    setEditorState(EditorState.createEmpty());
    setOpenToComment(false);
  };

  const removeComment = () => {
    setEditorState(EditorState.createEmpty());
    setOpenToComment(false);
  };
  const updateNotesData = () => {
    const updatedrawContent = convertToRaw(
      editorUpdateState.getCurrentContent()
    );
    const itemIndex = savedDescription.findIndex(
      (item) => item.id === updateToNotes.id
    );
    if (itemIndex !== -1) {
      savedDescription[itemIndex].comment = updatedrawContent;
      savedDescription[itemIndex].cretedDate = new Date();
    }
    setEditorState(EditorState.createEmpty());
    setUpdatenotes(false);
  };
  const cancelupdateNotesData = () => {
    setEditorState(EditorState.createEmpty());
    setUpdatenotes(false);
  };
  const handleAddNewTaskForTheRiskAndIssue = () => {
    const cardId = `node_process_${nanoid(15)}`;
    const datasas = {
      createDate: new Date(),
      dueDate: null,
      title: "",
      id: cardId,
    };
    setAddCardsToIceBoxInTheBoard([...addCardsToIceBoxInTheBoard, datasas]);
  };
  const handleSeverityChange = (event, item) => {
    setAnchorSeverityChange(event.currentTarget);
    setSingleSeverity(item);
  };
  const handleLikelihoodChange = (event, item) => {
    setAnchorLikelihoodChange(event.currentTarget);
    setSingleLikelyHood(item);
    const getCurrent = riskAndIssuesData.severityTable.find(
      (v) => v.title === item.severity
    ).likelihood;
    setCurrentLikelihood(getCurrent);
  };
  const handleChoresTreatmentForBoardAndParticularCard = (event, item) => {
    setAnchorForAssignee(event.currentTarget);
    setSingleAssigneeForBoard(item);
  };
  const handleChangeTheDueDateForCard = (event, item) => {
    setUpdateSelectedDate(event);
    setSingleAssigneeForBoard(item);
  };
  const handleUpdateAssesmentSeverity = (item) => {
    singleSeverity["severity"] = item.title;
    singleSeverity["color"] = item.color;
    setAssesments([...assesments]);
    setAnchorSeverityChange(null);
  };
  const handleUpdateAssesmentLikelyHood = (item) => {
    singleLikelyHood["likelihood"] = item.title;
    singleLikelyHood["Asscolor"] = item.color;
    singleLikelyHood["impact"] = item.impactKey;
    setAssesments([...assesments]);
    setAnchorLikelihoodChange(null);
  };
  const newRenderUpdateModal = () => {
    return (
      <Box minWidth="1040px" maxWidth="1040px" mt="21px">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleUpdateSubmit(onUpdateSubmit)}
        >
          <PopoverForSeverity
            openSeverity={openSeverity}
            anchorSeverityChange={anchorSeverityChange}
            setAnchorSeverityChange={setAnchorSeverityChange}
            className={classes.assesmentPopOverStyle}
            handleUpdateAssesmentSeverity={handleUpdateAssesmentSeverity}
          />
          <PopoverForLikelihood
            openLikeLihood={openLikeLihood}
            anchorLikelihoodChange={anchorLikelihoodChange}
            setAnchorLikelihoodChange={setAnchorLikelihoodChange}
            className={classes.assesmentPopOverStyle}
            currentLikeliHood={currentLikeliHood}
            handleUpdateAssesmentLikelyHood={handleUpdateAssesmentLikelyHood}
          />
          <PopoverForNewAssignee
            openAssigneeid={openAssigneeid}
            openAssignee={openAssignee}
            anchorForAssignee={anchorForAssignee}
            setAnchorForAssignee={setAnchorForAssignee}
            classes={classes}
            toUsers={toUsers}
            addCardsToIceBoxInTheBoard={addCardsToIceBoxInTheBoard}
            singleAssigneeForBoard={singleAssigneeForBoard}
            setAddCardsToIceBoxInTheBoard={setAddCardsToIceBoxInTheBoard}
            getAvatar={getAvatar}
            getName={getName}
          />
          <Box className={classes.flexBasisBetweenForHeaderCard}>
            <Box className={classes.flexBasisBetween}>
              <Tooltip
                placement="top"
                title={`${
                  updateSizeOfProblem === 0
                    ? "risk created date"
                    : "issue created date"
                }`}
                arrow
              >
                <p>
                  {cardData?.createDate &&
                    format(new Date(cardData?.createDate), "dd-MM-yyyy")}
                </p>
              </Tooltip>
            </Box>
            <Box className={classes.flexBetweenCenter}>
              <Tooltip
                placement="top"
                title={`${
                  riskAndIssuesData.getColors(assesments)?.color
                    ? riskAndIssuesData.getColors(assesments)?.title
                    : "severity or likelihood"
                }`}
                arrow
              >
                <Box
                  width={`25px`}
                  height={`25px`}
                  borderRadius="50%"
                  style={{
                    background: riskAndIssuesData.getColors(assesments)?.color,
                  }}
                ></Box>
              </Tooltip>
              <Tooltip
                placement="top"
                title={`${updateSizeOfProblem === 0 ? "risk" : "issue"}`}
                arrow
              >
                <IconButton onClick={handleUpdateTypeOfCard}>
                  {updateSizeOfProblem === 0 && (
                    <WarningIcon
                      style={{
                        color: " #FFDD15",
                        stroke: "black",
                        height: "16px",
                        ...renderStyle,
                      }}
                    />
                  )}
                  {updateSizeOfProblem === 1 && (
                    <SVGRender
                      style={{ ...renderStyle }}
                      img={images.risk_}
                      alt={"small Icon"}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          {cardData?.title && (
            <Box style={{ textAlign: "left" }}>
              {!openToChangeTheTitle ? (
                <Tooltip
                  arrow
                  title="click to change the title"
                  placement="top"
                >
                  <Typography
                    align="left"
                    className={classes.updateTitle}
                    onClick={() => setOpenToChangeTheTitle(true)}
                  >
                    {updateTitle ? updateTitle : cardData?.title}
                  </Typography>
                </Tooltip>
              ) : (
                <Box className={classes.flexStartCenter}>
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="medium"
                        fullWidth
                        label="Title *"
                        variant="outlined"
                        type="text"
                      />
                    )}
                  />
                  <ErrorMessages errors={errors} name="title" />
                  {/* <Box
                    marginLeft="10px"
                    className={classes.shortIconForAcceptance}
                  >
                    <Button
                      variant="contained"
                      onClick={() => setOpenToChangeTheTitle(false)}
                    >
                      <CheckIcon />
                    </Button>
                  </Box> */}
                </Box>
              )}
            </Box>
          )}
          <Box maxWidth="1040px" textAlign="left" marginTop="30px">
            <Typography className={classes.textForBoldEveryUpdateCardSection}>
              Description
            </Typography>
            {updateDescription || !cardData?.description ? (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                marginTop={"1rem"}
                marginBottom={"3rem"}
                mt="20px"
              >
                <QuillText
                  value={updateDescriptionState}
                  onChange={setUpdateDescriptionState}
                />
              </Box>
            ) : (
              <Tooltip
                arrow
                title="click to change description"
                placement="top"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: cardData?.description,
                  }}
                  onClick={() => {
                    setUpdateDescription(true);
                    setUpdateDescriptionState(cardData?.description);
                  }}
                ></div>
              </Tooltip>
            )}
          </Box>
          <Box maxWidth="1040px" textAlign="left" marginTop="70px">
            <Box
              display={`flex`}
              alignItems="center"
              justifyContent={`space-between`}
              style={{ marginTop: "8px", maxWidth: "1040px" }}
            >
              <p style={{ fontWeight: "600" }}>Evidence (Links)</p>
              <Tooltip
                placement="top"
                arrow
                title="click to add new evidence link"
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleAddUpdateEvidenceLink}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {/* onClick={handleAddUpdateEvidenceLink} */}
            {updateEvidenceLinks.map((item, index) => (
              <FormControl
                style={{ marginTop: "16px", maxWidth: "1040px" }}
                key={index}
                fullWidth
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Evidence (Links)
                </InputLabel>
                <OutlinedInput
                  onChange={(e) => handleUpdateEvidenceLink(e, item)}
                  id="outlined-adornment-password"
                  type={"text"}
                  defaultValue={item.link}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleRemoveCreatedUpdateEvidence(item)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={113}
                />
              </FormControl>
            ))}
            {updateEvidences.length > 0 &&
              updateEvidences.map((item, index) => (
                <Box
                  display={`flex`}
                  justifyContent="space-between"
                  alignItems={`center`}
                  mb="8px"
                >
                  <p key={index}>
                    <Link target="_blank" rel="noreferrer" href={item?.link}>
                      {item?.link}
                    </Link>
                  </p>
                  <RIconButton
                    onClick={() => {
                      setUpdateEvidences(
                        updateEvidences.filter((items) => items.id !== item.id)
                      );
                    }}
                    title="delete this evidence link"
                  >
                    <DeleteOutlineIcon style={{ color: "#FF0000" }} />
                  </RIconButton>
                </Box>
              ))}
          </Box>
          <Box mt="30px" className={classes.flexBetweenCenter}>
            <Box>
              <Typography className={classes.textForBoldEveryUpdateCardSection}>
                Evidence
                {attachments.length === 0 ? (
                  ""
                ) : (
                  <span>{`(${attachments.length})`}</span>
                )}
              </Typography>
            </Box>
            <Box className={classes.updateDropzone}>
              <DropzoneSection
                showFileNames={true}
                showPreviewsInDropzone={false}
                setUpdateFilesToSave={setUpdateFilesToSave}
                updateFilesToSave={updateFilesToSave}
              />
            </Box>
          </Box>
          {(attachments.length > 0 || updateFilesToSave.length > 0) && (
            <Attachments
              updateFilesToSave={updateFilesToSave}
              setUpdateFilesToSave={setUpdateFilesToSave}
              projectId={user?.projectId}
              cardId={cardData?.id}
              organizationId={user?.projectId}
              AttachmentList={attachments}
            />
          )}
          <Box
            maxWidth="1040px"
            display={`flex`}
            justifyContent="center"
            alignItems={`center`}
            mt="30px"
          >
            <Typography className={classes.textForBoldEveryUpdateCardSection}>
              Assessment
            </Typography>
          </Box>
          <Box display={`flex`} justifyContent="right" gridGap={`80px`}>
            <p>Severity</p>
            <p>Likelihood</p>
          </Box>
          {assesments &&
            assesments.map((item, index) => (
              <Grid
                key={index}
                container
                style={{ maxWidth: "1040px" }}
                spacing={2}
              >
                <Grid item sm={8}>
                  <Controller
                    name={item.name}
                    control={control}
                    defaultValue={item.value}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        minRows={2}
                        className={classes.textareaStyleForAssesment}
                        placeholder={item.placeholder}
                        style={{ width: "100%", borderColor: "#c6c0c0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={2}>
                  <Button
                    onClick={(event) => handleSeverityChange(event, item)}
                    style={{
                      textDecoration: "underline",
                      color: item?.color,
                    }}
                  >
                    {item?.severity}
                  </Button>
                </Grid>
                <Grid item sm={2}>
                  <Button
                    onClick={(event) => handleLikelihoodChange(event, item)}
                    style={{
                      textDecoration: "underline",
                      color: item?.Asscolor,
                    }}
                  >
                    {item?.likelihood}
                  </Button>
                </Grid>
              </Grid>
            ))}

          <Box
            maxWidth="1040px"
            display={`flex`}
            justifyContent="center"
            alignItems={`center`}
            mt="30px"
          >
            <Typography className={classes.textForBoldEveryUpdateCardSection}>
              Treatment
            </Typography>
          </Box>
          <Box display={`flex`} justifyContent="right" gridGap={`80px`}>
            <Tooltip placement="top" arrow title="click to add new task">
              <Button
                onClick={handleAddNewTaskForTheRiskAndIssue}
                style={{ color: "#1F69D7", textDecoration: "underline" }}
              >
                Add more
              </Button>
            </Tooltip>
          </Box>
          <Box maxWidth={`1040px`}>
            {addCardsToIceBoxInTheBoard &&
              addCardsToIceBoxInTheBoard.map((items, index) => (
                <Box
                  key={index}
                  mt="8px"
                  display={`flex`}
                  justifyContent="space-between"
                  alignItems={`center`}
                >
                  <TextareaAutosize
                    onChange={(e) => handleAddTaskforIcebox(e, items)}
                    minRows={3}
                    className={classes.textareaStyleForAssesment}
                    placeholder="Task"
                    style={{
                      width: "400px",
                      marginLeft: "6px",
                      marginRight: "40px",
                    }}
                    defaultValue={items?.title}
                  />
                  <Box style={{ marginLeft: "-20px" }} className="dateStyle">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        title={`Click to change date`}
                        disableToolbar
                        className={classes.muiDatePicker}
                        format="dd-MM-yyyy"
                        placeholder={`Due date`}
                        value={items.dueDate}
                        onChange={(date) => {
                          setUpdateSelectedDate(date);
                          handleAddDueDateForIceBoxCard(date, items);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                  <Box ml="10px">
                    <IconButton
                      onClick={(event) =>
                        handleChoresTreatmentForBoardAndParticularCard(
                          event,
                          items
                        )
                      }
                    >
                      {items?.userId ? (
                        <UserAvatar
                          className={classes.updateCardAvatar}
                          getAvatar={getAvatar}
                          getName={getName ?? "no"}
                          userId={items?.userId}
                        />
                      ) : (
                        <Avatar className={classes.updateCardAvatar} />
                      )}
                    </IconButton>
                  </Box>
                  <Box ml="10px">
                    <RIconButton
                      placement="top"
                      title="click to delete the task"
                      onClick={() => {
                        setAddCardsToIceBoxInTheBoard(
                          addCardsToIceBoxInTheBoard.filter(
                            (item) => item.id !== items.id
                          )
                        );
                      }}
                    >
                      <ClearIcon />
                    </RIconButton>
                  </Box>
                </Box>
              ))}


            {treatments.length > 0 &&
              treatments.map((items, index) => (
                <Box
                  key={index}
                  mt="8px"
                  display={`flex`}
                  justifyContent="space-between"
                  alignItems={`center`}
                >
                  <TextareaAutosize
                    disabled
                    onChange={(e) => handleAddTaskforIcebox(e, items)}
                    minRows={3}
                    className={classes.textareaStyleForAssesment}
                    placeholder="Task"
                    style={{ width: "100%", marginRight: "20px" }}
                    defaultValue={items?.choresLabel}
                  />

                  {items?.dueDate && (
                    <Tooltip
                      title="this card created date"
                      arrow
                      placement="top"
                    >
                      <Box width={150}>
                        {format(new Date(items?.dueDate), "dd-MM-yyyy")}
                      </Box>
                    </Tooltip>
                  )}

                  <Box ml="10px">
                    {items?.userId ? (
                      <UserAvatar
                        className={classes.updateCardAvatar}
                        getAvatar={getAvatar}
                        getName={getName ?? "no"}
                        userId={items?.userId}
                      />
                    ) : (
                      <Tooltip title={"Not Assigned"} arrow>
                        <Avatar className={classes.updateCardAvatar} />
                      </Tooltip>
                    )}
                  </Box>
                  <Tooltip
                    title={
                      items?.columnName ? items?.columnName : "current lane"
                    }
                    arrow
                    placement="top"
                  >
                    <Box className={classes.ColumnName} ml="10px">
                      {items?.laneName ? items?.laneName : "ICE BOX"}
                    </Box>
                  </Tooltip>
                </Box>
              ))}
          </Box>
          <Box>
            <Box mb="15px" mt="30px">
              <Typography className={classes.textForBoldEveryUpdateCardSection}>
                Notes
              </Typography>
            </Box>

            <Box display="flex" justifyContent="flex-start" mb="10px">
              <Box>
                <UserAvatar
                  getAvatar={getAvatar}
                  style={{ marginRight: "8px" }}
                  getName={getName}
                  userId={user?.id}
                />
              </Box>
              {openToComment ? (
                <Box>
                  <Grid
                    style={{
                      maxWidth: "672px",
                      borderRadius: "8px",
                    }}
                  >
                    <DraftEditor
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                      suggestions={suggestions}
                    />
                  </Grid>
                  <Box style={{ textAlign: "left" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={saveComment}
                    >
                      Save
                    </Button>

                    <Button
                      size="small"
                      style={{ textAlign: "left", marginLeft: "10px" }}
                      variant="contained"
                      onClick={removeComment}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              ) : (
                <TextField
                  onClick={() => setOpenToComment(true)}
                  style={{
                    maxWidth: "1040px",
                    cursor: "pointer",
                  }}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Add a comment..."
                />
              )}
            </Box>
          </Box>
          {savedDescription && (
            <ShowNotes
              savedDescription={savedDescription}
              SetSavedDescription={SetSavedDescription}
              getName={getName}
              setUpdatenotes={setUpdatenotes}
              setUpdateToNotes={setUpdateToNotes}
              getAvatar={getAvatar}
            />
          )}
          {updatenotes && renderUpdateNotes()}
          <Box
            display="flex"
            justifyContent="right"
            marginTop="30px"
            paddingBottom="20px"
          >
            <Button
              className={classes.submitAndCloseButton}
              style={{
                marginRight: "10px",
                background: "#F5F5F5",
              }}
              onClick={() => handleUpdateCardClose(true)}
              variant="outlined"
              type="button"
              color="secondary"
              id=""
            >
              Close
            </Button>
            <Button
              className={classes.submitAndCloseButton}
              color="primary"
              variant="contained"
              type="submit"
              id="custom-risk-button"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    );
  };
  const handleChangeTypeOfSize = useCallback(() => {
    setSizeOfProblem((prev) => {
      if (sizeOfProblem >= 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }, [sizeOfProblem]);
  const handleUpdateTypeOfCard = useCallback(() => {
    setUpdateSizeOfProblem((prev) => {
      if (updateSizeOfProblem >= 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }, [updateSizeOfProblem]);
  const classes = useStyles(filesToSave);
  const toUsers = organizationProfile?.map((user) => ({
    label: user.name,
    value: user.id,
  }));
  const suggestions = organizationProfile?.map((user) => ({
    text: user.name,
    url: user.id,
    value: user.name,
  }));
  //helpers--- getAvatar
  const getAvatar = (value) => {
    const newAvatar = organizationProfile.find(
      (item) => item.id === value && item
    );
    let newData;
    Object.keys(Avatars).find((key, index) => {
      if (key === newAvatar?.avatar) {
        newData = `${Avatars[newAvatar?.avatar]}`;
        return newData;
      }
      return newData;
    });
    return newData;
  };
  //helpers--- getName
  const getName = (value) => {
    const showingName = organizationProfile.find(
      (item) => item.id === value && item
    );
    return showingName?.name;
  };
  //helpers--- getEpicName
  const getEpicName = (value) => {
    const showingName = valueStream.find((item) => item.id === value && item);
    return showingName?.valueStreamName;
  };
  //helpers--- getEpicColor
  const getEpicColor = (value) => {
    const showingName = valueStream.find((item) => item.id === value && item);
    return showingName?.color;
  };
  const CreatedCard = (props) => {
    return (
      <CustomCardForRiskAndJourney
        handleDeleteCard={handleDeleteCard}
        handleEditCard={handleEditCard}
        getAvatar={getAvatar}
        getName={getName}
        getEpicColor={getEpicColor}
        getEpicName={getEpicName}
        setCardId={setCardId}
        partData={props}
        persona={persona}
        riskAndIssue
      />
    );
  };
  const CreatedHeader = (props) => {
    return (
      <LaneForRisk
        riskAndIssue
        kanbanId={user?.kanbanId}
        props={props}
        riskKanbanData={riskKanbanData}
      />
    );
  };
  const NewButtonArise = () => {
    return <AddButton onClick={() => setModalOpenForAddCard(true)} />;
  };
  const components = {
    AddCardLink: NewButtonArise,
    LaneHeader: CreatedHeader,
    Card: CreatedCard,
  };

  // DD-MM-YYYY
  return (
    <Layout pageName={"Risk And Issues"} type={"board"}>
      {openAlert && (
        <CustomSnacbar
          opened={openAlert}
          alertToDelete={alertToDelete}
          handleDeleteFromBackend={handleDeleteFromBackend}
          setOpenAlert={setOpenAlert}
          type="card"
        />
      )}
      <Box className="BoardClass">
        {riskKanbanData ? (
          <Board
            editable
            style={riskAndIssuesData.style}
            laneStyle={riskAndIssuesData.laneStyle}
            collapsibleLanes={true}
            hideCardDeleteIcon={true}
            cardDraggable={true}
            draggable
            data={data}
            handleLaneDragEnd={(removedIndex, addedIndex, payload) => {
              handleLaneDragEnd(removedIndex, addedIndex, payload);
            }}
            components={components}
            handleDragEnd={(
              cardId,
              sourceLaneId,
              targetLaneId,
              position,
              cardDetails
            ) =>
              handleCardToAnotherLane(
                cardId,
                sourceLaneId,
                targetLaneId,
                position,
                cardDetails
              )
            }
          />
        ) : (
          <Loader />
        )}
        {modalOpen && handleCardSection()}
        {modalOpenForAddCard && handleAddCardToTheTrelloBoard()}
        {openalertnew && (
          <ModalCloseSnackBar
            opened={openalertnew}
            setOpenAlert={setOpenAlertnew}
            handleCloseCardModal={handleUpdateCardCloseConfirm}
          />
        )}
      </Box>
    </Layout>
  );
};
export default RiskIssues;
