import {
  Avatar,
  Box,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "@material-ui/core";
import NavigationIcon from "@material-ui/icons/Navigation";
import {
  UserAvatar,
  MuiDatePicker,
  QuillText,
  MPopover,
  ErrorMessages,
  HookFormTextField,
  MUIAutoComplete,
  Loader,
} from "Components/Reusable";
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Add as AddIcon,
  Adb as AdbIcon,
  Star as StarIcon,
  AssignmentTurnedInOutlined as AssignmentTurnedInOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  AccessTime,
} from "@material-ui/icons";
import { Controller } from "react-hook-form";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { nanoid } from "nanoid";
import {
  Attachments,
  BlockersTable,
  DropzoneSection,
  PopoverForSeverity,
  ShowNotes,
} from ".";
import { Editor } from "react-draft-wysiwyg";
import { globalStyle } from "assets/Styles/GlobalStyle";
import store from "../../redux/store";
import ReleaseCandidate from "./ReleaseCandidate";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import {
  filteredItemForBlockers,
  getPersona,
  getPersonaName,
} from "helpers/Board";
import { useSelector } from "react-redux";
import RelatedTable from "./RelatedTable";
import { useHistory } from "react-router-dom";
import { htmlToText, withHttps } from "Utils";
import { useRef } from "react";
import { ChangeSize } from "./UpdateCard";

/**
 *@function UpdateBoardCard.jsx
 *@author Azim
 *
 **/

const UpdateBoardCard = ({
  handleUpdateSubmit,
  onUpdateSubmit,
  toEpics,
  classes,
  getEpicName,
  updateEpicId,
  setUpdateEpicId,
  getEpicColor,
  toUsers,
  setUpdateAssignee,
  updateAssignee,
  getAvatar,
  getName,
  singleAcceptanceCriteria,
  updateSelectedDate,
  setUpdateSelectedDate,
  logEnable,
  cardData,
  handleUpdateTypeOfSize,
  updateSizeOfProblem,
  particularCard,
  persona,
  user,
  handleTimeSheet,
  PopOverForTimeSheet,
  setOpenToChangeTheUserStories,
  sum,
  openToChangeTheUserStories,
  updateTitle,
  updateLabel,
  control,
  errors,
  setOpenForUpdate,
  openForUpdate,
  setSingleAcceptanceCriteria,
  handleCheckAcceptanceCriteria,
  openAcceptanceForUpdate,
  updateSingleAcceptanceCriteria,
  setUpdateSingleAcceptanceCriteria,
  handleUpdateSingleAcceptanceForCard,
  setOpenAcceptanceForUpdate,
  setSingleAcceptanceToUpdateCard,
  aceptanceCriteria,
  singleAcceptanceToUpdateCard,
  handleUpdateCardClose,
  updatenotes,
  updateChoresLabel,
  opendescription,
  setOpenDescription,
  setOpenToChangeTheChoresLabel,
  openToChangeTheChoresLabel,
  savedDescription,
  SetSavedDescription,
  setUpdatenotes,
  setUpdateToNotes,
  setOpenToComment,
  removeComment,
  saveComment,
  setUpdateFilesToSave,
  setOpenAddAttachment,
  openAddAttachment,
  updateFilesToSave,
  suggestions,
  toBlockers,
  setOpenBlockersSection,
  openBlockersSection,
  updateBlockers,
  boarsdData,
  setUpdateBlockers,
  setDescription,
  description,
  openToComment,
  onEditLinkToDesign,
  setOnEditLinkToDesign,
  setOpenToChangeTheBugsLabel,
  updateBugsLabel,
  openToChangeTheBugsLabel,
  setAceptanceCriteria,
  deleteSingleAcceptanceCriteria,
  handleUpdateSingleForUpdateCard,
  getSingleAcceptanceCriteria,
  updateReleaseName,
  setOpenReleaseNameUpdate,
  openChangeToReleaseName,
  setOpenChangeToGoal,
  openChangeToGoal,
  updateReleaseGoal,
  setAnchorElTimeSheet,
  anchorElTimeSheet,
  setEstimateHour,
  estimateHour,
  comments,
  setComments,
  updateToNotes,
  updateNotesData,
  cancelupdateNotesData,
  updatPersonaId,
  setUpdatPersonaId,
  assesments,
  setAssesments,
  updateReleate,
  setAllUpdateRelated,
  clearErrors,
  setValue,
  currentGPT,
  setCurrentGPT,
  setFeature,
  feature,
}) => {
  const history = useHistory();
  const { allCard } = useSelector((state) => state.kanban);
  const allCardsofProject = allCard?.map((block) => ({
    label: block?.title
      ? htmlToText(block?.title) + " " + block.label
      : block?.bugsLabel
      ? block?.bugsLabel
      : block?.choresLabel,
    value: block.id,
  }));
  const popoverRef = useRef();

  const { personas } = useSelector((state) => state.personas);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPersona, setAnchorElPersona] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorForAssignee, setAnchorForAssignee] = useState(null);
  const [anchorElEstimate, setAnchorElEstimate] = useState(null);

  const [estimateShow, setEstimateShow] = useState(false);

  //assesment Update
  const [anchorSeverityChange, setAnchorSeverityChange] = useState(null);
  const [singleSeverity, setSingleSeverity] = useState({});
  const [loadingText, setLoadingText] = useState(false);
  const [aitext, setAiText] = useState(null);

  const handleSeverityChange = (event, item) => {
    setAnchorSeverityChange(event.currentTarget);
    setSingleSeverity(item);
  };
  //functions for assesment
  const openSeverity = Boolean(anchorSeverityChange);
  const handleUpdateAssesmentSeverity = (item) => {
    singleSeverity["buttonText"] = item.title;
    singleSeverity["color"] = item.color;
    singleSeverity["impact"] = item.impactKey;
    setAssesments([...assesments]);
    setAnchorSeverityChange(null);
  };
  const id = open ? "simple-popover" : undefined;
  const openAssignee = Boolean(anchorForAssignee);
  const openPersona = Boolean(anchorElPersona);
  const openAssigneeid = openAssignee ? "simple-popover" : undefined;
  const openPersonaId = openPersona ? "simple-popover" : undefined;
  const estimateShowid = estimateShow ? "simple-popover" : undefined;
  const filter = createFilterOptions();
  const [wait, setWait] = useState(true);

  const [editornewState, setEditorNewState] = useState(
    EditorState.createEmpty()
  );
  const [editorupdateNewState, setEditorUpdateNewState] = useState(
    EditorState.createEmpty()
  );

  const [openRelatedSection, setOpenRelatedSection] = useState(false);

  const [isCtrlPressed, setIsCtrlPressed] = useState(false);

  // for submitting modal both create and update
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "s") {
        event.preventDefault();
        document.getElementById("submit-button").click();
      }
    };

    const handleKeyUp = (event) => {
      if (!event.ctrlKey) {
        setIsCtrlPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleMouseDown = (event) => {
    if (event.ctrlKey) {
      setIsCtrlPressed(true);
    }
  };

  const handleMouseUp = () => {
    setIsCtrlPressed(false);
  };

  useEffect(() => {
    if (cardData?.estimateHour) {
      setEstimateHour(cardData?.estimateHour);
    }
  }, [cardData]);

  useEffect(() => {
    const abort = new AbortController();
    if (updateToNotes) {
      setEditorUpdateNewState(
        EditorState.createWithContent(convertFromRaw(updateToNotes.comment))
      );
    }
    return () => {
      abort.abort();
    };
  }, [updateToNotes]);

  // for cancel update
  const handleCancelUpdate = () => {
    setEditorUpdateNewState(EditorState.createEmpty());
    cancelupdateNotesData();
  };

  const PopoverForNewEpicName = () => {
    return (
      <MPopover
        id={id}
        open={open}
        ref={popoverRef}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        className={classes.popoverStyle}
      >
        <Autocomplete
          options={toEpics}
          getOptionLabel={(option) => {
            if (!option) {
              return "";
            }
            return option.label;
          }}
          getOptionSelected={(option, value) => {
            return option.value === value.value;
          }}
          style={{ width: "100%", padding: "20px" }}
          id="blur-on-select"
          blurOnSelect
          onChange={(e, f) => {
            setUpdateEpicId(f.value);
            setAnchorEl(null);
          }}
          defaultValue={
            toEpics.find((item) => item?.value === updateEpicId)
              ? toEpics.find((item) => item?.value === updateEpicId)
              : ""
          }
          value={
            toEpics.find((item) => item?.value === updateEpicId)
              ? toEpics.find((item) => item?.value === updateEpicId)
              : ""
          }
          renderOption={(option, { selected }) => (
            <div
              style={{
                width: "100%",
                background: option.color ? option.color : "#67c6c0",
              }}
            >
              <p
                style={{
                  color: "black",
                  marginLeft: "10px",
                }}
              >
                {option.label}
              </p>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Epic link"
              variant="outlined"
            />
          )}
        />
      </MPopover>
    );
  };
  // console.log({ cardData });
  const PopoverForNewAssignee = () => {
    return (
      <div>
        <MPopover
          id={openAssigneeid}
          ref={popoverRef}
          open={openAssignee}
          anchorEl={anchorForAssignee}
          onClose={() => setAnchorForAssignee(null)}
          className={classes.popoverStyle}
        >
          <Autocomplete
            options={toUsers}
            getOptionLabel={(option) => {
              if (!option) {
                return "";
              }
              return option.label;
            }}
            getOptionSelected={(option, value) => {
              return option.value === value.value;
            }}
            style={{ width: "100%", padding: "20px" }}
            id="blur-on-select"
            blurOnSelect
            onChange={(e, f) => {
              setUpdateAssignee(f?.value);
              setAnchorForAssignee(null);
            }}
            defaultValue={
              toUsers.find((item) => item?.value === updateAssignee)
                ? toUsers.find((item) => item?.value === updateAssignee)
                : ""
            }
            value={
              toUsers.find((item) => item?.value === updateAssignee)
                ? toUsers.find((item) => item?.value === updateAssignee)
                : ""
            }
            renderOption={(option, { selected }) => (
              <div className={classes.flexBetweenCenter}>
                <UserAvatar
                  className={classes.typesIconStyle}
                  getAvatar={getAvatar}
                  getName={getName}
                  userId={option?.value}
                />

                <p
                  style={{
                    color: "black",
                    display: "block",
                    marginLeft: "10px",
                  }}
                >
                  {option?.label}
                </p>
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Assignee"
                variant="outlined"
              />
            )}
          />
        </MPopover>
      </div>
    );
  };
  const toPersonas = personas?.map((item) => ({
    label: item.name,
    // color: item.color,
    value: item.id,
  }));
  const PopoverForNewPersona = () => {
    return (
      <div>
        <MPopover
          id={openPersonaId}
          ref={popoverRef}
          open={openPersona}
          anchorEl={anchorElPersona}
          onClose={() => setAnchorElPersona(null)}
          className={classes.popoverStyle}
        >
          <MUIAutoComplete
            close={() => setAnchorElPersona(null)}
            control={control}
            name="personas"
            options={toPersonas}
            style={{ width: "100%", padding: "20px" }}
            value={
              updatPersonaId != null
                ? getPersona(updatPersonaId, toPersonas)
                : localStorage.getItem("currentPersona") !== undefined &&
                  localStorage.getItem("currentPersona") !== "undefined"
                ? JSON.parse(localStorage.getItem("currentPersona"))
                : toPersonas[0]
                ? toPersonas[0]
                : null
            }
            renderOption={(option, { selected }) => (
              <div
                style={{
                  width: "100%",
                }}
              >
                <p
                  style={{
                    color: "black",
                    marginLeft: "10px",
                  }}
                >
                  {option.label}
                </p>
              </div>
            )}
            getOptionLabel={(option) => {
              if (option && option.label) {
                setUpdatPersonaId(option.value);
              }
              return option.label;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Change Persona"
                variant="outlined"
              />
            )}
          />
        </MPopover>
      </div>
    );
  };
  // pop over for estimate
  const PopOverForEstimate = () => {
    return (
      <MPopover
        id={estimateShowid}
        ref={popoverRef}
        open={estimateShow}
        anchorEl={anchorElEstimate}
        onClose={() => {
          setAnchorElEstimate(null);
          setEstimateShow(false);
        }}
        className={classes.estimateStyle}
      >
        <Box padding={2}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="0h"
            value={estimateHour}
            onChange={(event) => setEstimateHour(event.target.value)}
          />
        </Box>
      </MPopover>
    );
  };
  useEffect(() => {
    const abort = new AbortController();
    setTimeout(() => {
      setWait(true);
    }, 1000);
    setWait(false);
    return () => {
      abort.abort();
    };
  }, []);
  //before save comment
  const handleSaveComment = () => {
    const rawContent = convertToRaw(editornewState.getCurrentContent());
    saveComment(rawContent);
    setEditorNewState(EditorState.createEmpty());
  };

  // before remove comment
  const handleRemoveComment = () => {
    setEditorNewState(EditorState.createEmpty());
    removeComment();
  };

  // get generated text
  // const getText = async (serverText, label) => {
  //   setLoadingText(true);
  //   const newText =
  //     '"' +
  //     serverText +
  //     '"' +
  //     "using the given, when and then format and after every new line use <br/>";
  //   const body = {
  //     aibody: newText.replaceAll("\\", "").replaceAll('"', ""),
  //   };
  //   const res = await getgeneratedText(body);
  //   if (res.status === 200) {
  //     setLoadingText(false);
  //     setAiText(res.data);
  //     setValue(label, res.data);
  //   }
  // };

  return (
    <>
      {!loadingText ? (
        <Box minWidth="1040px" mt="21px">
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleUpdateSubmit(onUpdateSubmit)}
          >
            <Tooltip title="Card number" arrow placement="top">
              <Typography
                style={{
                  fontWeight: 600,
                  textDecoration: "underline",
                  marginTop: "-10px",
                }}
                color="#1e1616cc"
                align="left"
              >
                #{cardData?.issueCode}
              </Typography>
            </Tooltip>
            {PopoverForNewEpicName()}
            {PopoverForNewAssignee()}
            {/* AZIM TODO QSA*/}
            {cardData?.type !== "feature" && PopoverForNewPersona()}
            <div className={classes.flexBasisBetweenForHeaderCard}>
              <div className={classes.flexBasisBetween}>
                <Tooltip title="Change avatar" arrow placement="top">
                  <IconButton
                    onClick={(event) =>
                      setAnchorForAssignee(event.currentTarget)
                    }
                  >
                    {updateAssignee ? (
                      <UserAvatar
                        className={classes.updateCardAvatar}
                        getAvatar={getAvatar}
                        getName={getName ?? "no"}
                        userId={updateAssignee}
                      />
                    ) : (
                      <Avatar className={classes.updateCardAvatar} />
                    )}
                  </IconButton>
                </Tooltip>

                {cardData?.type !== "release" ? (
                  <Tooltip
                    title={
                      getEpicName(updateEpicId)
                        ? getEpicName(updateEpicId)
                        : "Epic link"
                    }
                    arrow
                    placement="top"
                  >
                    <Button
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                      className={classes.updateCardEpicLink}
                      style={{
                        background: updateEpicId
                          ? getEpicColor(updateEpicId) || "#DEDEDE"
                          : "#DEDEDE",
                      }}
                    >
                      {updateEpicId ? getEpicName(updateEpicId) : "Epic link"}
                    </Button>
                  </Tooltip>
                ) : null}

                <div className="dateStyle">
                  <MuiDatePicker
                    className={classes.muiDatePicker}
                    value={updateSelectedDate}
                    onChange={(date) => setUpdateSelectedDate(date)}
                  />
                </div>
                {logEnable ? (
                  <div>
                    <Box
                      sx={{ backgroundColor: "#dedede", cursor: "pointer" }}
                      paddingLeft={1.2}
                      paddingRight={1.2}
                      paddingTop={0.3}
                      paddingBottom={0.3}
                      borderRadius={"5px"}
                      fontSize="15px"
                      fontWeight={estimateHour > 0 ? "bold" : "500"}
                      onClick={(event) => {
                        setEstimateShow(!estimateShow);
                        setAnchorElEstimate(event.currentTarget);
                      }}
                    >
                      {estimateHour > 0 && !estimateShow
                        ? `Estimate: ${estimateHour}h`
                        : `Estimate`}
                    </Box>
                    {PopOverForEstimate()}
                  </div>
                ) : null}
              </div>
              {/* #TODO */}
              {cardData?.type !== "release" ? (
                <div className={classes.flexBetweenCenter}>
                  {cardData?.workflowId && (
                    <Tooltip
                      title="navigate to that node"
                      arrow
                      placement="top"
                    >
                      <IconButton
                        onClick={() =>
                          history.push(
                            `/finalDiagram?streamId=${updateEpicId}&workflowId=${cardData?.workflowId}`
                          )
                        }
                      >
                        <NavigationIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {cardData?.type !== "release" &&
                    cardData?.type === "feature" && (
                      <Tooltip
                        title={
                          getPersonaName(cardData?.personaId, personas)
                            ? getPersonaName(cardData?.personaId, personas)
                            : "Change persona"
                        }
                        arrow
                        placement="top"
                      >
                        <Button
                          onClick={(event) =>
                            setAnchorElPersona(event.currentTarget)
                          }
                          className={classes.updateCardEpicLink}
                          style={{
                            background: "#cecece",
                            border: "1px solid gray",
                          }}
                        >
                          {cardData?.personaId &&
                          cardData.personaId !== "undefined"
                            ? getPersonaName(cardData?.personaId, personas)
                            : "persona"}
                          {/* {console.log(cardData?.personaId)} */}
                        </Button>
                      </Tooltip>
                    )}
                  <ChangeSize
                    updateSizeOfProblem={updateSizeOfProblem}
                    handleUpdateTypeOfSize={handleUpdateTypeOfSize}
                  />
                  {/* for access time */}
                  {logEnable ? (
                    <>
                      <IconButton
                        onClick={(event) => {
                          handleTimeSheet();
                          setAnchorElTimeSheet(event.currentTarget);
                        }}
                      >
                        <AccessTime />
                      </IconButton>
                      {PopOverForTimeSheet()}
                    </>
                  ) : null}

                  {cardData?.type === "bug" && (
                    <Tooltip title="bug" arrow placement="top">
                      <AdbIcon fontSize="large" style={{ color: "red" }} />
                    </Tooltip>
                  )}
                  {cardData?.type === "feature" && (
                    <Tooltip title="feature" arrow placement="top">
                      <StarIcon
                        fontSize="large"
                        style={{ color: " #F0DD2E" }}
                      />
                    </Tooltip>
                  )}
                  {cardData?.type === "chore" && (
                    <Tooltip title="task" arrow placement="top">
                      <AssignmentTurnedInOutlinedIcon fontSize="large" />
                    </Tooltip>
                  )}
                  {cardData?.laneName && (
                    <Button
                      style={{ height: "24px", marginLeft: "12px" }}
                      color="primary"
                      variant="contained"
                    >
                      {cardData?.laneName}
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
            {/* User Stories #TODO */}
            {cardData?.estimateHour > 0 ? (
              <Typography
                align="left"
                title="click to change"
                style={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  lineHeight: " 27px",
                  color: " #000000",
                  maxWidth: "700px",
                  marginBottom: "1rem",
                }}
                onClick={() => setOpenToChangeTheUserStories(true)}
              >
                Reported Time
                <Box display={"flex"}>
                  <UserAvatar
                    getAvatar={getAvatar}
                    className={classes.updateCardAvatar}
                    getName={getName}
                    userId={user?.id}
                  />
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontWeight: "normal",
                      marginLeft: "10px",
                    }}
                  >
                    <div>{getName(user?.id)}</div>
                    <div>
                      {sum} of {cardData?.estimateHour}h
                    </div>
                  </Typography>
                </Box>
              </Typography>
            ) : null}

            <div>
              {!openToChangeTheUserStories ? (
                <div
                  onClick={() => setOpenToChangeTheUserStories(true)}
                  style={{ textAlign: "left" }}
                  dangerouslySetInnerHTML={{
                    __html: cardData?.title,
                  }}
                ></div>
              ) : (
                <QuillText value={feature} onChange={setFeature} />
              )}
            </div>

            {cardData?.title && (
              <>
                {/* <div>
                  {!openToChangeTheUserStories ? (
                    <Typography
                      align="left"
                      title="click to change"
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        lineHeight: " 27px",
                        color: " #000000",
                      }}
                      onClick={() => setOpenToChangeTheUserStories(true)}
                    >
                      <span>{`As ${
                        cardData?.personaId != undefined &&
                        cardData?.personaId != null
                          ? getPersonaName(cardData?.personaId, personas)
                              ?.length > 10
                            ? getPersonaName(
                                cardData?.personaId,
                                personas
                              ).substring(0, 5) +
                              "..." +
                              getPersonaName(
                                cardData?.personaId,
                                personas
                              ).substring(
                                getPersonaName(cardData?.personaId, personas)
                                  ?.length - 5,
                                getPersonaName(cardData?.personaId, personas)
                                  ?.length
                              )
                            : getPersonaName(cardData?.personaId, personas)
                          : "User"
                      }`}</span>
                      <br />
                      <span>{` I want to do the ${
                        updateTitle ? updateTitle : cardData?.title
                      } process `}</span>
                      <br />
                      <span>{` so that I can ${
                        updateLabel ? updateLabel : cardData?.label
                      }`}</span>
                      <br />
                    </Typography>
                  ) : (
                    <div
                      className={classes.flexBetweenCenter}
                      style={{
                        textAlign: "left",
                        margin: "43px 4px",
                      }}
                    >
                      {`As ${
                        cardData?.personaId != undefined &&
                        cardData?.personaId != null
                          ? getPersonaName(cardData?.personaId, personas)
                              ?.length > 10
                            ? getPersonaName(
                                cardData?.personaId,
                                personas
                              ).substring(0, 5) +
                              "..." +
                              getPersonaName(
                                cardData?.personaId,
                                personas
                              ).substring(
                                getPersonaName(cardData?.personaId, personas)
                                  ?.length - 5,
                                getPersonaName(cardData?.personaId, personas)
                                  ?.length
                              )
                            : getPersonaName(cardData?.personaId, personas)
                          : persona?.name
                      } I want to do the `}
                      <div style={{ display: "inline" }}>
                        <Controller
                          name="title"
                          control={control}
                          defaultValue={cardData?.title}
                          rules={{
                            required: {
                              value: true,
                              message: "This is required",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              style={{ maxWidth: "146px" }}
                              variant="outlined"
                              type="text"
                            />
                          )}
                        />

                        <ErrorMessages errors={errors} name="title" />
                      </div>
                      {` process so that I can `}
                      <div style={{ display: "inline" }}>
                        <Controller
                          name="label"
                          control={control}
                          defaultValue={cardData?.label}
                          rules={{
                            required: {
                              value: true,
                              message: "This is required",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              style={{ maxWidth: "146px" }}
                              // label="processWhy*"
                              variant="outlined"
                              type="text"
                            />
                          )}
                        />
                        <ErrorMessages errors={errors} name="label" />
                      </div>
                    </div>
                  )}
                </div> */}
                {/* Acceptance Criteria */}
                <div className={classes.flexBetweenCenter}>
                  <div>
                    <Typography
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      Acceptance Criteria
                    </Typography>
                  </div>
                  <IconButton onClick={() => setOpenForUpdate(true)}>
                    <AddIcon />
                  </IconButton>
                </div>
                <div>
                  {openForUpdate && (
                    <div style={{ display: "inline", maxWidth: "1114px" }}>
                      {/* TODO */}
                      <QuillText
                        currentGPT={currentGPT}
                        setCurrentGPT={setCurrentGPT}
                        value={singleAcceptanceCriteria}
                        onChange={setSingleAcceptanceCriteria}
                        custom={true}
                        cardData={cardData}
                      />
                      <ErrorMessages
                        style={{
                          display: "inline-block",
                        }}
                        errors={errors}
                        name="title"
                      />
                      {/* acceptance criteria buttons */}
                      <div className={classes.shortIconForAcceptance}>
                        <Button
                          variant="contained"
                          onClick={handleCheckAcceptanceCriteria}
                        >
                          <CheckIcon />
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setSingleAcceptanceCriteria(
                              boarsdData.initialState
                            );
                            setOpenForUpdate(false);
                          }}
                        >
                          <ClearIcon />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                {openAcceptanceForUpdate && (
                  <div style={{ display: "inline" }}>
                    <QuillText
                      value={updateSingleAcceptanceCriteria}
                      onChange={setUpdateSingleAcceptanceCriteria}
                      currentGPT={currentGPT}
                      setCurrentGPT={setCurrentGPT}
                      custom={true}
                      cardData={cardData}
                    />

                    <ErrorMessages
                      style={{
                        display: "inline-block",
                      }}
                      errors={errors}
                      name="title"
                    />

                    {/* acceptance criteria buttons */}
                    <div className={classes.shortIconForAcceptance}>
                      <Button
                        variant="contained"
                        onClick={handleUpdateSingleAcceptanceForCard}
                      >
                        <CheckIcon />
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpenAcceptanceForUpdate(false);
                          setSingleAcceptanceToUpdateCard({});
                        }}
                      >
                        <ClearIcon />
                      </Button>
                    </div>
                  </div>
                )}
                <div>
                  {aceptanceCriteria &&
                    aceptanceCriteria.map((item, index) => (
                      <div
                        style={{
                          display:
                            singleAcceptanceToUpdateCard.id === item.id
                              ? "none"
                              : "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          maxWidth: "1000px",
                          margin: "5px 0px",
                        }}
                        key={index}
                      >
                        <Tooltip
                          placement="top"
                          title={`Click to Update`}
                          arrow
                        >
                          <div
                            className={classes.acceptanceCriteriaStyle}
                            dangerouslySetInnerHTML={{
                              __html: getSingleAcceptanceCriteria(
                                item?.description
                              ),
                            }}
                            onClick={() =>
                              handleUpdateSingleForUpdateCard(item)
                            }
                          ></div>
                        </Tooltip>
                        <IconButton
                          onClick={() => {
                            if (item.id.length !== 9) {
                              store.dispatch(
                                deleteSingleAcceptanceCriteria(
                                  user.projectId,
                                  item.id
                                )
                              );
                            }
                            setAceptanceCriteria(
                              aceptanceCriteria.filter(
                                (items) => items.id !== item.id
                              )
                            );
                          }}
                        >
                          <DeleteOutlineIcon style={{ color: "#FF0000" }} />
                        </IconButton>
                      </div>
                    ))}
                </div>
              </>
            )}
            {/* bugsLabel */}

            {cardData?.bugsLabel && (
              <div>
                {!openToChangeTheBugsLabel ? (
                  <Typography
                    align="left"
                    title="click to change"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      lineHeight: " 27px",
                      color: " #000000",
                      maxWidth: "1040px",
                    }}
                    onClick={() => setOpenToChangeTheBugsLabel(true)}
                  >
                    {updateBugsLabel ? updateBugsLabel : cardData?.bugsLabel}
                  </Typography>
                ) : (
                  <div className={classes.flexStartCenter}>
                    <Controller
                      name="bugsLabel"
                      control={control}
                      defaultValue={
                        aitext !== null ? aitext : cardData?.bugsLabel
                      }
                      rules={{
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="medium"
                          fullWidth
                          label="Bugs Label*"
                          variant="outlined"
                          type="text"
                          defaultValue={
                            aitext !== null ? aitext : cardData?.bugsLabel
                          }
                        />
                      )}
                    />
                    <ErrorMessages errors={errors} name="bugsLabel" />
                  </div>
                )}
              </div>
            )}

            {/* chores label */}
            {cardData?.choresLabel && (
              <div>
                {!openToChangeTheChoresLabel ? (
                  <Typography
                    align="left"
                    title="click to change"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      lineHeight: " 27px",
                      color: " #000000",
                      maxWidth: "1040px",
                    }}
                    onClick={() => setOpenToChangeTheChoresLabel(true)}
                  >
                    {updateChoresLabel
                      ? updateChoresLabel
                      : cardData?.choresLabel}
                  </Typography>
                ) : (
                  <>
                    <div className={classes.flexStartCenter}>
                      <Controller
                        name="choresLabel"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "This is required",
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="medium"
                            fullWidth
                            label="Task Label*"
                            variant="outlined"
                            type="text"
                            defaultValue={
                              aitext !== null ? aitext : cardData?.choresLabel
                            }
                          />
                        )}
                      />
                      <ErrorMessages errors={errors} name="choresLabel" />
                    </div>
                  </>
                )}
              </div>
            )}

            {/* for description */}
            {cardData?.type === "chore" || cardData?.type === "bug" ? (
              <div>
                <Box
                  display={"flex"}
                  justifyContent="space-between"
                  textAlign={`left`}
                >
                  <Typography
                    align="left"
                    title="click to change"
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      lineHeight: " 27px",
                      color: " #000000",
                      maxWidth: "1040px",
                    }}
                    onClick={() => {
                      setOpenDescription(true);
                    }}
                  >
                    Description
                  </Typography>
                  {opendescription ? (
                    <div style={{ cursor: "pointer" }}>
                      <ClearIcon onClick={() => setOpenDescription(false)} />
                    </div>
                  ) : null}
                </Box>

                <Box className={classes.flexStartCenter}>
                  {!opendescription ? (
                    <div
                      style={{ textAlign: "left", maxWidth: "1040px" }}
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                      onClick={() => setOpenDescription(true)}
                    ></div>
                  ) : null}
                  {opendescription ? (
                    <>
                      <QuillText
                        value={description}
                        onChange={setDescription}
                        custom={true}
                      />
                      ;
                      <ErrorMessages errors={errors} name="description" />
                    </>
                  ) : null}
                </Box>
              </div>
            ) : null}
            {cardData?.type !== "release" ? (
              <div className={classes.flexBetweenCenter}>
                <Typography
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Add link
                </Typography>
                {cardData?.linkToDesign ? (
                  <IconButton onClick={() => setOnEditLinkToDesign(true)}>
                    <EditIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setOnEditLinkToDesign(true)}>
                    <AddIcon />
                  </IconButton>
                )}
              </div>
            ) : null}

            <PopoverForSeverity
              openSeverity={openSeverity}
              fromBoard
              anchorSeverityChange={anchorSeverityChange}
              setAnchorSeverityChange={setAnchorSeverityChange}
              className={classes.assesmentPopOverStyle}
              handleUpdateAssesmentSeverity={handleUpdateAssesmentSeverity}
            />
            {onEditLinkToDesign ? (
              <div style={{ display: "inline" }}>
                <Controller
                  name="linkToDesign"
                  control={control}
                  defaultValue={cardData?.linkToDesign}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="medium"
                      fullWidth
                      variant="outlined"
                      label="Add link"
                      type="text"
                    />
                  )}
                />

                <ErrorMessages
                  style={{
                    display: "inline-block",
                  }}
                  errors={errors}
                  name="linkToDesign"
                />
              </div>
            ) : (
              <Typography
                title="click to change the url"
                style={{
                  color: "blue",
                  fontWeight: "300",
                  fontSize: "14px",
                  textDecoration: "underline",
                  wordBreak: "break-all",
                  maxWidth: "770px",
                  textAlign: "left",
                }}
              >
                <Link
                  target="_blank"
                  rel="referrer"
                  href={withHttps(cardData?.linkToDesign)}
                >
                  {cardData?.linkToDesign}
                </Link>
              </Typography>
            )}
            {/* Blockers */}
            {cardData?.type !== "release" ? (
              <div className={classes.flexBetweenCenter}>
                <div>
                  <Typography
                    style={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    Blockers
                  </Typography>
                </div>

                <IconButton onClick={() => setOpenBlockersSection(true)}>
                  <AddIcon />
                </IconButton>
              </div>
            ) : null}

            <div className={classes.toBlockersUpdateStyle}>
              {openBlockersSection && (
                <Autocomplete
                  multiple
                  limitTags={5}
                  defaultValue={updateBlockers.filter(
                    (o1) =>
                      !boarsdData.appendedBlockers.some(
                        (o2) => o1.value === o2.value
                      )
                  )}
                  value={updateBlockers.filter(
                    (o1) =>
                      !boarsdData.appendedBlockers.some(
                        (o2) => o1.value === o2.value
                      )
                  )}
                  getOptionSelected={(option, value) => {
                    const optionTitle =
                      typeof option === "string" ? option : option.value;
                    const valueTitle =
                      typeof value === "string" ? value : value.value;
                    return optionTitle === valueTitle;
                  }}
                  onChange={(event, newValue) => {
                    if (Array.isArray(newValue)) {
                      const updatedArrayValue = newValue.filter((e) =>
                        typeof e === "string" ? e.trim() : e
                      );
                      const newArrayValue = [...updatedArrayValue];
                      const updatedArray = newArrayValue.map((item) => {
                        if (typeof item === "string") {
                          const newItem = {};
                          newItem.label = item;
                          newItem.value = nanoid(12);
                          newItem.type = "created";
                          return newItem;
                        }
                        return item;
                      });
                      setUpdateBlockers(updatedArray);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== "") {
                      filteredItemForBlockers(params, filtered);
                    }
                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={toBlockers}
                  filterSelectedOptions
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.label;
                  }}
                  renderOption={(option) => option.label}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Blockers"
                      variant="outlined"
                    />
                  )}
                />
              )}
            </div>
            <div style={{ marginBottom: "4px" }}>
              {cardData?.blockers.length > 0 && (
                <BlockersTable
                  style={globalStyle.renderStyle}
                  getName={getName}
                  getAvatar={getAvatar}
                  particularCard={particularCard}
                  persona={persona?.name}
                  blockers={cardData?.blockers}
                />
              )}
            </div>

            {/* related field */}
            {cardData?.type !== "release" ? (
              <div className={classes.flexBetweenCenter}>
                <div>
                  <Typography
                    style={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    Related Fields
                  </Typography>
                </div>

                <IconButton onClick={() => setOpenRelatedSection(true)}>
                  <AddIcon />
                </IconButton>
              </div>
            ) : null}

            <div className={classes.toBlockersUpdateStyle}>
              {openRelatedSection && (
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={allCardsofProject}
                  defaultValue={allCardsofProject?.filter((el) => {
                    return cardData.related?.find((element) => {
                      return element && JSON.parse(element).value === el.value;
                    });
                  })}
                  onChange={(event, newValue) => {
                    if (Array.isArray(newValue)) {
                      const updatedArrayValue = newValue.filter((e) =>
                        typeof e === "string" ? e.trim() : e
                      );
                      // #TODO
                      const newArrayValue = [...updatedArrayValue];
                      const updatedArray = newArrayValue.map((item) => {
                        if (typeof item === "string") {
                          const newItem = {};
                          newItem.label = item;
                          newItem.value = nanoid(12);
                          newItem.type = "created";
                          return newItem;
                        }
                        return item;
                      });
                      setAllUpdateRelated(updatedArray);
                    }
                  }}
                  getOptionLabel={(option) => {
                    if (!option) {
                      return "";
                    }
                    return option.label;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Related Fields"
                      placeholder="Favorites"
                    />
                  )}
                />
              )}
            </div>
            <div style={{ marginBottom: "4px" }}>
              {cardData?.related.length > 0 && (
                <RelatedTable
                  style={globalStyle.renderStyle}
                  getName={getName}
                  getAvatar={getAvatar}
                  particularCard={particularCard}
                  persona={persona?.name}
                  related={cardData?.related}
                />
              )}
            </div>

            {/* Attachments */}
            {cardData?.type !== "release" ? (
              <div className={classes.flexBetweenCenter}>
                <div>
                  <Typography
                    style={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    Attachments
                  </Typography>
                </div>
                <div className={classes.updateDropzone}>
                  <IconButton
                    onClick={() => setOpenAddAttachment(!openAddAttachment)}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </div>
            ) : null}
            <Box>
              {wait ? (
                <Attachments
                  updateFilesToSave={updateFilesToSave}
                  setUpdateFilesToSave={setUpdateFilesToSave}
                  projectId={user?.projectId}
                  cardId={cardData?.id}
                  AttachmentList={
                    cardData?.attachmentList ? cardData?.attachmentList : []
                  }
                />
              ) : null}
            </Box>

            <Box
              className={classes.updateDropZone}
              marginBottom={"2rem"}
              marginTop={"1rem"}
            >
              {openAddAttachment && (
                <DropzoneSection
                  showFileNames={false}
                  showPreviewsInDropzone={true}
                  setUpdateFilesToSave={setUpdateFilesToSave}
                  dropzoneText
                />
              )}
            </Box>

            {/* here we will add our release card */}
            {cardData?.type === "release" ? (
              <div style={{ marginBottom: "1rem" }}>
                {/* for release label */}
                <div>
                  {!openChangeToReleaseName ? (
                    <Typography
                      align="left"
                      title="click to change"
                      style={{
                        fontSize: "18px",
                        lineHeight: " 27px",
                        color: " #000000",
                        maxWidth: "875px",
                        marginTop: "-2rem",
                      }}
                      onClick={() => setOpenReleaseNameUpdate(true)}
                    >
                      {updateReleaseName
                        ? updateReleaseName
                        : cardData?.releaseName}
                    </Typography>
                  ) : (
                    <div className={classes.flexStartCenter}>
                      <HookFormTextField
                        name="releaseName"
                        control={control}
                        defaultValue={cardData?.releaseName}
                        rules={{
                          required: {
                            value: true,
                            message: "This is required",
                          },
                          maxLength: {
                            value: 25,
                            message: "Not More then 25",
                          },
                        }}
                        size="medium"
                        label="Release Name*"
                        errors={errors}
                      />

                      <div
                        style={{ marginLeft: "10px" }}
                        className={classes.shortIconForAcceptance}
                      >
                        <Button
                          variant="contained"
                          onClick={() => setOpenReleaseNameUpdate(false)}
                        >
                          <CheckIcon />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {!openChangeToGoal ? (
                    <Typography
                      align="left"
                      title="click to change"
                      style={{
                        fontSize: "18px",
                        lineHeight: " 27px",
                        color: " #000000",
                        maxWidth: "875px",
                        marginBottom: "2rem",
                        marginTop: "12px",
                      }}
                      onClick={() => setOpenChangeToGoal(true)}
                    >
                      <span style={{ marginRight: "1rem" }}>Goal:</span>
                      {updateReleaseGoal ? updateReleaseGoal : cardData?.goal}
                    </Typography>
                  ) : (
                    <Box mt="12px" className={classes.flexStartCenter}>
                      <HookFormTextField
                        name="goal"
                        control={control}
                        defaultValue={cardData?.goal}
                        size="medium"
                        label="Release Goal"
                        errors={errors}
                      />
                      <div
                        style={{ marginLeft: "10px" }}
                        className={classes.shortIconForAcceptance}
                      >
                        <Button
                          variant="contained"
                          onClick={() => setOpenChangeToGoal(false)}
                        >
                          <CheckIcon />
                        </Button>
                      </div>
                    </Box>
                  )}
                </div>
                {/* for release candidate */}
                <Box marginTop={"2rem"}>
                  <Typography
                    align="left"
                    title="click to change"
                    style={{
                      fontSize: "18px",
                      lineHeight: " 27px",
                      color: " #000000",
                      maxWidth: "875px",
                    }}
                    onClick={() => setOpenChangeToGoal(true)}
                  >
                    Release Candidate
                  </Typography>
                  <ReleaseCandidate cardId={cardData?.id} classes={classes} />
                </Box>
              </div>
            ) : null}

            <div>
              <Typography
                style={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "14px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Notes
              </Typography>

              <Box display="flex" justifyContent="flex-start" mb="10px">
                <div>
                  <UserAvatar
                    getAvatar={getAvatar}
                    style={{ marginRight: "8px" }}
                    getName={getName}
                    userId={user?.id}
                  />
                </div>
                {openToComment ? (
                  <div>
                    <Grid
                      style={{
                        maxWidth:
                          cardData.type === "release" ? "775px" : "1014px",
                        borderRadius: "8px",
                      }}
                    >
                      <Editor
                        editorState={editornewState}
                        onEditorStateChange={setEditorNewState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        toolbar={{
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "fontFamily",
                            "list",
                            "textAlign",
                            "link",
                            "history",
                          ],
                        }}
                        mention={{
                          separator: " ",
                          trigger: "@",
                          suggestions: suggestions,
                        }}
                      />
                    </Grid>
                    <div style={{ textAlign: "left" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={handleSaveComment}
                      >
                        Save
                      </Button>
                      <Button
                        size="small"
                        style={{ textAlign: "left", marginLeft: "10px" }}
                        variant="contained"
                        onClick={handleRemoveComment}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <TextField
                    onClick={() => setOpenToComment(true)}
                    style={{
                      maxWidth: cardData.type === "release" ? "875px" : "",
                      cursor: "pointer",
                    }}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Add a comment..."
                  />
                )}
              </Box>
            </div>
            {savedDescription && (
              <ShowNotes
                savedDescription={savedDescription}
                SetSavedDescription={SetSavedDescription}
                getName={getName}
                setUpdatenotes={setUpdatenotes}
                setUpdateToNotes={setUpdateToNotes}
                getAvatar={getAvatar}
              />
            )}

            {updatenotes && (
              <div>
                <Grid
                  style={{
                    maxWidth: "770px",
                    borderRadius: "8px",
                  }}
                >
                  <Editor
                    editorState={editorupdateNewState}
                    onEditorStateChange={setEditorUpdateNewState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "link",
                        "image",
                        "history",
                      ],
                    }}
                    mention={{
                      separator: " ",
                      trigger: "@",
                      suggestions: suggestions,
                    }}
                  />
                </Grid>
                <Box mt={1} align="left">
                  <Button
                    variant="contained"
                    onClick={() => updateNotesData(editorupdateNewState)}
                  >
                    Update
                  </Button>
                  <Button
                    style={{ marginLeft: "8px" }}
                    onClick={() => handleCancelUpdate()}
                  >
                    cancel
                  </Button>
                </Box>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                aligntItems: "center",
                marginTop: "20px",
              }}
            >
              <Tooltip title="Press (Ese)">
                <Button
                  style={{
                    marginRight: "6px",
                  }}
                  onClick={() => handleUpdateCardClose(true)}
                  variant="contained"
                  color="primary"
                  type="button"
                >
                  Close
                </Button>
              </Tooltip>
              <Tooltip title="Press (Ctrl + s)">
                <Button
                  className={classes.submitAndCloseButton}
                  color="primary"
                  variant="contained"
                  type="submit"
                  id="submit-button"
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                >
                  Submit
                </Button>
              </Tooltip>
            </div>
          </form>
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UpdateBoardCard;
