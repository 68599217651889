import { CustomSnacbar } from "Components/Reusable";
import { Avatar, Box, Grid, Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { AddCircle, PersonOutline } from "@material-ui/icons";
import StreamCard from "Components/Reusable/Card/StreamCard";
import { Fragment } from "react";

export const PersonaSection = ({
  classes,
  personas,
  getAvatar,
  history,
  deleteItem,
  alertToDelete,
  setDeleteItem,
  handleDeletePersona,
  persona,
}) => {
  return (
    <Box>
      <StreamCard titleVariant="h5" titleText="Personas" subtitle="Discovery">
        <Box className={classes.streamCardBox}>
          <Grid className={classes.scrollUpStyle} container>
            {personas &&
              personas.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <Tooltip title={item?.name} placement="bottom" arrow>
                      <Box
                        style={{
                          background: "#E5E5E5",
                          cursor: "pointer",
                          marginRight: "40px",
                        }}
                        className={classes.AddboxCircle2}
                      >
                        {item?.avatar ? (
                          <div>
                            <Avatar
                              className={classes.firstpaper}
                              src={getAvatar(item?.avatar)}
                              onClick={() =>
                                history.push(`/personas/${item.id}`)
                              }
                            ></Avatar>
                          </div>
                        ) : (
                          <p
                            style={{
                              position: "relative",
                              lineHeight: 1,
                              color: "black",
                              top: "0",
                              fontSize: ".5em",
                              textAlign: "center",
                            }}
                            onClick={() => history.push(`/personas/${item.id}`)}
                          >
                            <PersonOutline
                              style={{
                                fontSize: "15.5em",
                                color: "#BDBDBD",
                                position: "relative",
                              }}
                            />
                            <span
                              style={{
                                position: "relative",
                                top: "-60px",
                                fontSize: "2.2em",
                              }}
                            >
                              {item?.name.length > 7
                                ? item?.name.slice(0, 7) + "..."
                                : item?.name}
                            </span>
                          </p>
                        )}
                      </Box>
                    </Tooltip>
                  </Fragment>
                );
              })}
            {deleteItem.alert ? (
              <CustomSnacbar
                opened={deleteItem.alert}
                alertToDelete={alertToDelete}
                handleDeleteFromBackend={handleDeletePersona}
                setOpenAlert={setDeleteItem}
                delid={deleteItem.id}
                type="user"
              />
            ) : null}

            <Box
              style={{ background: "transparent" }}
              onClick={() => history.push("/personas")}
              className={classes.AddboxCircle2}
            >
              <Button
                className={classes.buttonStyle2}
                disableRipple
                title={
                  persona?.name && persona?.projectId
                    ? "Click to add value stream"
                    : ""
                }
                endIcon={<AddCircle className={classes.buttonInnerStyle2} />}
              />
            </Box>
          </Grid>
        </Box>
      </StreamCard>
    </Box>
  );
};
