import { authConstants, diagramConstant, paymentConstants, ProjectConstants } from './constant';
import axios from "../../api/axios";
import store from '../store';
import { errorAlert, handleErrorMessage, redirectUrl, successAlert } from 'Utils';
import { getOrganizationProfile } from './profile.actions';
import { addUserToTable } from './payment.actions';
import { auth } from 'api/Auth';
import { notifyUserAfterAdding } from './auth.actions';
import { saveCofiguration } from './profileconfig.action';

const domHostname = window?.location?.host;

export const addNewProject = (project, singleCog,cardconfig ,applyToNew) => {
    return async (dispatch) => {
        dispatch({
            type: ProjectConstants.PROJECT_CREATE_REQUEST,
        })
        try {
            if (project) {
                const res = await axios.post("/v1/projects", project);

                // for making new configuration to squares
                const newitem = {
                    "customBoard": singleCog.customBoard,
                    "journeyMap": singleCog.journeyMap,
                    "kanban": singleCog.kanban,
                    "latestOne": true,
                    "persona": singleCog.persona,
                    "problemStatement": singleCog.problemStatement,
                    "projectId": res?.data?.data?.id,
                    "riskIssues": singleCog.riskIssues,
                    "userId": project.userId,
                    "workflow": singleCog.workflow
                }
                if (applyToNew) {
                    dispatch(saveCofiguration([newitem]))
                }

                // for making new configuration to card configuration section
                const newitem2 = {
                    "feature": cardconfig.feature,
                    "bug": cardconfig.bug,
                    "risk": cardconfig.risk,
                    "issue": cardconfig.issue,
                    "latestOne": true,
                    "task": cardconfig.task,
                    "projectId": res?.data?.data?.id,
                    "userId": project.userId,
                }
                if (applyToNew) {
                    dispatch(saveCofiguration([newitem]))
                }

                const PrevUser = JSON.parse(window.localStorage.getItem("squser"))
                const token = auth.getToken();
                PrevUser.projectLists = [res?.data?.data?.id];
                PrevUser.kanbanId = res?.data?.data?.kanbanId;
                PrevUser.projectId = PrevUser.projectId !== null || PrevUser.projectId !== "" ? res?.data?.data?.id : PrevUser.projectId
                const { persona } = res?.data?.data
                window.localStorage.setItem("squser", JSON.stringify(PrevUser));
                const user = JSON.parse(window.localStorage.getItem("squser"))
                if (user) {
                    store.dispatch({
                        type: authConstants.LOGIN_SUCCESS,
                        payload: {
                            token,
                            user,
                        },
                    });
                }
                dispatch({
                    type: ProjectConstants.PROJECT_CREATE_SUCCESS,
                    payload: res.data
                })
                dispatch({
                    type: diagramConstant.ADD_NEW_PERSONA,
                    payload: persona
                });
            }
        } catch (error) {
            dispatch({
                type: ProjectConstants.PROJECT_CREATE_FAILURE,
            })
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const updateProject = (project) => {
    return async (dispatch) => {
        try {
            if (project) {
                const res = await axios.patch("/v1/projects", project);
                successAlert(res.data.message)
                dispatch({
                    type: ProjectConstants.PROJECT_UPDATE,
                    payload: res?.data
                })
            }
            dispatch(getOrganizationProfile(project?.id))
        } catch (error) {
            dispatch({
                type: ProjectConstants.PROJECT_CREATE_FAILURE,
            })
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getProjectById = (id) => {
    return async (dispatch) => {
        try {
            if (id) {
                const res = await axios.get("/v1/projects/" + id);
                dispatch({
                    type: ProjectConstants.PROJECT_GET,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getProjectByUserId = (id) => {
    return async (dispatch) => {
        try {
            if (id) {
                const res = await axios.get("/v1/projectUsers/users/" + id);
                dispatch({
                    type: ProjectConstants.ALL_PROJECTS_GET,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const postUserByAssignedProjectByAdminId = (postUsers, adminId, projectId, ifNewUserExists) => {
    return async (dispatch) => {
        dispatch({
            type: ProjectConstants.ASSIGNE_PROJECT_USERS_REQUEST,
        })
        try {
            if (adminId && postUsers) {
                const res = await axios.post(`/v1/users/${adminId}`, postUsers);
                // console.log({res})
                if (res.status == 200) {
                    successAlert("User created successfully! Please Notify the member to check the email")
                    dispatch({
                        type: ProjectConstants.ASSIGNE_PROJECT_USERS_SUCCESS,
                    })
                    dispatch(getUserAndProjectByAdminId(adminId))
                    ifNewUserExists.map(item => {
                        dispatch(
                            notifyUserAfterAdding(item.username, redirectUrl(domHostname, "user-activation"))
                        );
                    })
                }

                dispatch(getProjectByUserId(adminId))

            }
        } catch (error) {
            // console.log(error)
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const patchUserByAssignedProjectByAdminId = (postUsers, adminId, projectId) => {
    return async (dispatch) => {
        dispatch({
            type: ProjectConstants.ASSIGNE_PROJECT_USERS_REQUEST,
        })
        try {
            if (adminId && postUsers) {
                await axios.put(`/v1/users/${adminId}`, postUsers);
                successAlert("User Updated successfully!")
                dispatch({
                    type: ProjectConstants.ASSIGNE_PROJECT_USERS_SUCCESS,
                })
                dispatch(getUserAndProjectByAdminId(adminId))
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const getUserAndProjectByAdminId = (adminId) => {
    return async (dispatch, getState) => {
        dispatch({
            type: ProjectConstants.GET_PROJECT_USER_BY_ADMINID_REQUEST,
        })
        try {
            if (adminId) {
                const { data } = await axios.get(`/v1/admin/${adminId}`);
                dispatch({
                    type: ProjectConstants.GET_PROJECT_USER_BY_ADMINID_SUCCESS,
                    payload: data.data
                })
                const someVal = getState()?.projects?.usersUnderAdmin;
                dispatch({
                    type: paymentConstants.USER_EMPTY
                })
                dispatch(addUserToTable(someVal))
            }
        } catch (error) {
            dispatch({
                type: ProjectConstants.GET_PROJECT_USER_BY_ADMINID_FAILURE
            })
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const handleChangeUserProjectId = (item) => {
    return async (dispatch, getState) => {
        try {
            const res = await axios.get(`/v1/projects/${item?.projectId}`)
            if (item && res.data?.data?.kanbanId) {
                const user = JSON.parse(window.localStorage.getItem("squser"));
                const token = auth.getToken()
                window.localStorage.removeItem("squser");
                user.projectId = item?.projectId;
                if (item.role === "USER") {
                    user.roleType = "USER"
                } else if (item.role === "ADMIN" || item.role === "") {
                    user.roleType = "ADMIN"
                }
                user.kanbanId = res.data?.data?.kanbanId
                window.localStorage.setItem("squser", JSON.stringify(user));
                if (user) {
                    store.dispatch({
                        type: authConstants.LOGIN_SUCCESS,
                        payload: {
                            token,
                            user,
                        },
                    });
                }
            }

        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const handleProjectClone = (id, userid) => {
    return async (dispatch) => {
        dispatch({
            type: ProjectConstants.CLONE_PROJECT_REQUEST,
            loading: true
        })
        try {
            // console.log("idgetting", id)
            if (id) {
                await axios.get(`/v1/projects/${id}/clone`)
                dispatch({
                    type: ProjectConstants.CLONE_PROJECT_SUCCESS,
                    loading: false,
                })
                dispatch(getProjectByUserId(userid))
            }
        } catch (error) {
            dispatch({
                type: ProjectConstants.CLONE_RPOJECT_FAILED
            })
        }
    }
}
export const assginedTheProjectAdminFromUser = (assignedData, id) => {
    return async (dispatch) => {
        dispatch({ type: ProjectConstants.PROJECT_OWNERSHIP_REQUEST });
        try {
            const res = await axios.post(`/v1/projects/ownerships`, assignedData);
            // console.log({ res })
            if (res.status === 200) {
                successAlert(`project assigned successfully to , please tell him to check email to get access the website`)
                dispatch({
                    type: ProjectConstants.PROJECT_OWNERSHIP_SUCCESS,
                });
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
            dispatch({
                type: ProjectConstants.PROJECT_OWNERSHIP_FAILURE,
                payload: error.message
            });
        }
    };
};

export const getProjectUserByProjectId = (id) => {
    return async (dispatch) => {
        dispatch({
            type: ProjectConstants.GET_PROJECT_USER_BY_PROJECTID_REQUEST
        })
        try {
            if (id) {

                // /api/v1 / projectUsers / projects / { projectId }
                const projectuser = await axios.get(`/v1/projectUsers/projects/${id}/`);
                // console.log({ projectuser })
                dispatch({
                    type: ProjectConstants.GET_PROJECT_USER_BY_PROJECTID_SUCCESS,
                    payload: projectuser.data
                })
            }
        } catch (error) {
            dispatch({
                type: ProjectConstants.GET_PROJECT_USER_BY_PROJECTID_FAILURE,
            })
        }
    }
}



export const deleteProject = (id) => {
    return async (dispatch) => {
        dispatch({
            type: ProjectConstants.DELETE_PROJECT_REQUEST
        })
        try {
            if (id) {
                const response = await axios.delete(`v1/projects/${id}`)
                if (response && response.status) {
                    dispatch({
                        type: ProjectConstants.DELETE_PROJECT_SUCCESS
                    })
                    successAlert("Project Has Deleted Successfully")
                }
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: ProjectConstants.DELETE_PROJECT_FAILED
                })
            }
        }
    }
}


export const downloadTimeSheet = (projectId, user, startDate, endDate) => {
    return async (dispatch) => {
        try {
            if (projectId && user?.id) {
                const response = await axios.get(`v1/projects/download-timesheet`, {
                    params: {
                        projectId: projectId,
                        userId: user?.id,
                        startDate: startDate,
                        endDate: endDate
                    },
                    responseType: 'blob'
                })
                if (response && response.status) {

                    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `${user?.roleType === "ADMIN" ? "allusertimesheet" : user?.username}.xls`)
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    dispatch({
                        type: ProjectConstants.DOWNLOAD_TIMESHEET
                    })
                    successAlert("Time Sheet Downloaded Successfully")
                }
            }
        } catch (error) {
            // console.log(error)
            if (error) {
                dispatch({
                    type: ProjectConstants.DOWNLOAD_TIMESHEET_FAILED
                })
            }
        }
    }
}