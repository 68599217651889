import { makeStyles, Tooltip } from "@material-ui/core";
import { Handle } from "reactflow";

const CustomNode = (props) => {
  const classes = useStyles();
  return (
    <div className={`${classes.customNodeWrapper} wrapper_node`}>
      <Handle
        type="source"
        position="left"
        className={classes.leftHandleStyle}
        connectable="true"
        id="b"
      />
      <Handle
        type="source"
        position="top"
        className={classes.leftHandleStyle}
        connectable="true"
        id="c"
      />
      <Handle
        type="source"
        position="bottom"
        className={classes.leftHandleStyle}
        connectable="true"
        id="d"
      />
      <Tooltip
        placement={"top"}
        arrow
        title={props.data?.name ? props.data?.name : "Process node"}
      >
        <div>
          {props.data.name}
          <p
            style={{ background: props?.data?.color }}
            className={classes.valueStreamStyle}
          >
            {props.data.valueStreamName}
          </p>
        </div>
      </Tooltip>

      <Handle
        type="source"
        position="right"
        id="a"
        className={classes.rightHandleStyle}
      />
    </div>
  );
};

export default CustomNode;

const useStyles = makeStyles((theme) => ({
  customNodeWrapper: {
    background: "rgba(223, 224, 255, 1)",
    boxShadow: "0px 4px 4px 0px #00000040",
    color: "black",
    padding: "20px 40px",
    borderRadius: "5px",
    minHeight: "70px",
    position: "relative",
    "&:hover .react-flow__handle": {
      visibility: "visible",
    },
  },
  leftHandleStyle: {
    background: "#555",
    padding: "3px",
    visibility: "hidden",
  },
  valueStreamStyle: {
    position: "absolute",
    bottom: -10,
    fontSize: "10px",
    right: "0",
    textAlign: "center",
    borderRadius: "15px 0px 0px 0px",
    padding: "1px 0px 0px 6px",
    whiteSpace: "nowrap",
    width: "55px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  rightHandleStyle: {
    top: 30,
    background: "#555",
    padding: "3px",
    visibility: "hidden",
  },
}));
