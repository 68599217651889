import { diagramConstant } from './constant';
import axios from "api/axios";
import newAxios from 'axios';
import { downloadFile, errorAlert, handleErrorMessage, successAlert } from 'Utils';
import { auth } from 'api/Auth';
import { AppMode } from 'urlConfig';

export const addEdge = (data) => {
    return async (dispatch) => {
        dispatch({
            type: diagramConstant.ADDEDGE,
            payload: data
        })
    }
}

export const loadDiagramFromBackend = (projectId, valueStreamId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get("/v1/projects/" + projectId + "/contents/workflow/" + valueStreamId);
                dispatch({
                    type: diagramConstant.LOADDATA,
                    payload: res.data
                })
            }
        } catch (error) {
            // console.log({ message: error })
        }
    }
}


export const drapValueStreams = (valueStreamList, projectId) => {
    return async (dispatch) => {
        try {
            if (valueStreamList?.length > 0) {
                await axios.post("/v1/projects/" + projectId + "/valueStreams/dragValueStream", valueStreamList);
                dispatch({
                    type: diagramConstant.VALUE_STREAM_DRAG,
                })
            }
        } catch (error) {
            // console.log({ message: error })
        }
    }
}

export const loadAllDiagramFromBackend = (projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get("/v1/projects/" + projectId + "/contents");
                dispatch({
                    type: diagramConstant.LOADALLDATA,
                    payload: res.data
                })
            }
        } catch (error) {
            // console.log({ message: error })
        }
    }
}
export const uploadDocfile = (projectId, files) => {
    return async (dispatch) => {
        dispatch({
            type: diagramConstant.UPLOAD_FILE_REQUEST,
        })
        try {
            if (projectId && files) {
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${auth.getToken()}`
                    },
                };
                const res = await newAxios.post(`${AppMode.backend}/v1/projects/${projectId}/documents/uploadDoc`, files, config);
                // console.log("from me ", res.data.data)
                dispatch({
                    payload: res?.data?.data,
                    type: diagramConstant.UPLOAD_FILE_SUCCESS,
                })
                // console.log("from me 2", res.data.data)
                    (res.data.data) && successAlert("Card Changed successfully")


            }
        } catch (error) {
            // // console.log({ error })
        }
    }
}
export const removeFilesFromState = (files) => {
    return (dispatch) => {
        if (files) {
            dispatch({
                type: diagramConstant.EMPTY_FILES,
            })
        }
    }
}

export const gettingEmptyDiagram = (projectId) => {
    return (dispatch) => {
        if (projectId) {
            dispatch({
                type: diagramConstant.EMPTY_DIAGRAM,
            })
        }
    }
}

export const addNewValueStream = (valueStream, projectId) => {
    return async (dispatch, getState) => {
        try {
            if (valueStream) {
                const res = await axios.post("/v1/projects/" + projectId + "/valueStreams", valueStream);
                const filteredItem = getState()?.workflow?.workflows;
                successAlert("New valueStream Added")
                dispatch({
                    type: diagramConstant.ADD_VALUE_STREAM,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return error.response
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const addNewValueStreamFlow = (valueStream, projectId) => {
    return async (dispatch, getState) => {
        try {
            if (valueStream) {
                const res = await axios.post("/v1/projects/" + projectId + "/valueStreams", valueStream);

                const workflowItems = getState()?.workflow?.workflows;
                const workflowItem = workflowItems.find(item => item.id === valueStream.workflowId);
                const prevValueStream = workflowItem.valueStreamList;
                const updatedFlow = [...prevValueStream, res.data];
                workflowItem.valueStreamList = updatedFlow;
                const indexed = workflowItems.findIndex((info) => (info.id === res.data.id));
                workflowItems[indexed] = workflowItem;
                successAlert("New valueStream Added")
                dispatch({
                    type: diagramConstant.ADD_VALUE_STREAM_FLOW,
                    payload: workflowItems
                })
                dispatch({
                    type: diagramConstant.ADD_VALUE_STREAM,
                    payload: updatedFlow
                })
            }
        } catch (error) {
            if (error.response?.status === 401) {
                return error.response
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getNewValueStream = (projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get("/v1/projects/" + projectId + "/valueStreams");
                dispatch({
                    type: diagramConstant.GET_VALUE_STREAM,
                    payload: res.data
                })
            }
        } catch (error) {

            if (error && error.response && error && error.response && error.response.status === 401) {
                return error.response
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getAllValueStreamsByWorkflow = (projectId, workflowId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get("/v1/projects/" + projectId + "/valueStreams/workflow/" + workflowId);
                dispatch({
                    type: diagramConstant.GET_ALL_VALUE_STREAM,
                    payload: res.data
                })
            }
        } catch (error) {

            if (error && error.response && error.response.status === 401) {
                return error.response
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const addNewPersona = (data, projectId) => {
    return async (dispatch) => {
        try {
            if (data && projectId) {
                const res = await axios.post("/v1/projects/" + projectId + "/persona", data);
                successAlert(res.data.message)
                dispatch({
                    type: diagramConstant.ADD_NEW_PERSONA,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getPersona = (projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get("/v1/projects/" + projectId + "/persona");
                dispatch({
                    type: diagramConstant.GET_PERSONA_DATA,
                    payload: res.data.data[0]
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const updatePersona = (data, projectId, id) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.patch("/v1/projects/" + projectId + "/persona/" + id, data);
                successAlert(res.data.message)
                dispatch({
                    type: diagramConstant.UPDATE_PERSONA_DATA,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const deleteValueStream = (id) => {
    return async (dispatch) => {
        try {
            if (id) {
                const res = await axios.delete("/v1/projects/{projectId}/valueStreams/" + id);
                // console.log({ resdata: res })
                successAlert("Value Stream Deleted Successsfully")
                dispatch({
                    type: diagramConstant.DELETE_VALUE_STREAM,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const updateValueStream = (id, updatedValueStream) => {
    return async (dispatch) => {
        try {
            if (id) {
                const res = await axios.patch("/v1/projects/{projectId}/valueStreams/" + id, updatedValueStream);
                successAlert(res.data && "Value stream updated successfully");
                dispatch({
                    type: diagramConstant.UPDATE_VALUE_STREAM,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}



export const sendValueStreamAndDiagramForCSV = (projectId, valueStreamDtoList, name) => {
    return async (dispatch) => {
        try {
            if (valueStreamDtoList) {
                const res = await axios.post("/v1/projects/" + projectId + "/valueStreams/generate-csv", valueStreamDtoList);
                downloadFile(
                    `${name}.csv`,
                    "data:text/csv;charset=UTF-8," + encodeURIComponent(res?.data)
                )
                dispatch({
                    type: diagramConstant.FOR_CSV_WITH_VALUE_STREAM,
                    payload: res.data
                })
                dispatch(nullifiedCSV())
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const nullifiedCSV = () => {
    return async (dispatch) => {
        dispatch({ type: diagramConstant.DELETE_CSV })
    }
}
//for the decision or process Node {next 3 actions}
export const addNodeDataToTheTable = (data) => {
    return async (dispatch) => {
        try {
            if (data) {
                dispatch({
                    type: diagramConstant.ADD_NEW_FORM_NODE_DATA,
                    payload: data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const loadNodeDataToTheTableFromServer = (data) => {
    return async (dispatch) => {
        try {
            if (data) {
                dispatch({
                    type: diagramConstant.LOAD_TABLE_DATA_FROM_BACKEND,
                    payload: data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const updateNodeDataToTheTable = (data) => {
    return async (dispatch) => {
        try {
            if (data) {
                dispatch({
                    type: diagramConstant.UPDATE_NEW_FORM_NODE_DATA,
                    payload: data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const cloaseEditableNodeDataToTheTable = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: diagramConstant.CLOSE_FORM_NODE_DATA,
                payload: data
            })
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const deleteNodeDataToTheTable = (id) => {

    return async (dispatch) => {
        try {
            if (id) {
                dispatch({
                    type: diagramConstant.DELETE_NEW_FORM_NODE_DATA,
                    payload: id
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}


