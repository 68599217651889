import { Box, MenuItem } from "@material-ui/core";
import { boarsdData } from "assets/Data/BoardsData";
import { riskAndIssuesData } from "assets/Data/RiskAndIssue";
import { MPopover } from "..";
import { useRef } from 'react';

/**
 *@function PopoverForSeverity.jsx
 *@author Azim
 *
 **/

const PopoverForSeverity = ({
  openSeverity,
  anchorSeverityChange,
  setAnchorSeverityChange,
  className,
  handleUpdateAssesmentSeverity,
  fromBoard,
}) => {
  const popoverRef = useRef();
  return (
    <Box>
      <MPopover
      ref={popoverRef}
        id={"simple-popover"}
        open={openSeverity}
        anchorEl={anchorSeverityChange}
        onClose={() => setAnchorSeverityChange(null)}
        className={className}
      >
        {fromBoard
          ? boarsdData?.impactBasedOnColor.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => handleUpdateAssesmentSeverity(item)}
                style={{ color: item?.color, textDecoration: "underline" }}
              >
                {item.title}
              </MenuItem>
            ))
          : riskAndIssuesData?.severityTable.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => handleUpdateAssesmentSeverity(item)}
                style={{ color: item?.color, textDecoration: "underline" }}
              >
                {item.title}
              </MenuItem>
            ))}
      </MPopover>
    </Box>
  );
};

export default PopoverForSeverity;
