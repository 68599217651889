import hotChilies from "./Hot.png";
import Freedom from "./Freedom.png";
import bridge1 from "./bridge1.png";
import wallofcollocium from "./wallofcollocium.png";
import meerkat from "./meerkat.png";
import Flowerspink from "./Flowerspink.png";
import Plant1 from "./Plant1.png";
import Plant2 from "./Plant2.png";
import Plant3 from "./Plant3.png";
import palmTree from "./palmTree.png";
import Operahouse2 from "./Operahouse2.png";
import Operahouse1 from "./Operahouse1.png";
import infrontOfDoor from "./infrontOfDoor.png";
import News from "./News.png";
import CircularQuay from "./CircularQuay.png";
import Face from "./Face.png";
import Bike from "./Bike.png";
import RedIs from "./RedIs.png";
import Bath from "./Bath.png";
import Bonsai from "./Bonsai.png";
import Coast from "./Coast.png";
import Star from "./Star.png";
import Crabpots1 from "./Crabpots1.png";
import redflowers from "./redflowers.png";

const backimageurl = "https://sqbearstorage.blob.core.windows.net/bgimage"

export const backgroundArray = [
    {
        id: 1,
        thumbnail: hotChilies,
        actualImage: `${backimageurl}/Hot.png`,
    },
    {
        id: 2,
        thumbnail: Freedom,
        actualImage: `${backimageurl}/Freedom.png`,

    },
    {
        id: 3,
        thumbnail: Operahouse2,
        actualImage: `${backimageurl}/Operahouse2.png`,

    },
    {
        id: 4,
        thumbnail: bridge1,
        actualImage: `${backimageurl}/bridge1.png`,

    },
    {
        id: 5,
        thumbnail: palmTree,
        actualImage: `${backimageurl}/palmTree.png`,
    },
    {
        id: 6,
        thumbnail: Coast,
        actualImage: `${backimageurl}/Coast.png`,

    },
    {
        id: 7,
        thumbnail: News,
        actualImage: `${backimageurl}/News.png`,

    },
    {
        id: 8,
        thumbnail: Bike,
        actualImage: `${backimageurl}/Bike.png`,
    },
    {
        id: 9,
        thumbnail: Crabpots1,
        actualImage: `${backimageurl}/Crabpots1.png`,

    },
    {
        id: 10,
        thumbnail: redflowers,
        actualImage: `${backimageurl}/redflowers.png`,

    },
    {
        id: 11,
        thumbnail: Bonsai,
        actualImage: `${backimageurl}/Bonsai.png`,

    },
    {
        id: 12,
        thumbnail: Bath,
        actualImage: `${backimageurl}/Bath.png`,

    },
    {
        id: 13,
        thumbnail: wallofcollocium,
        actualImage: `${backimageurl}/wallofcollocium.png`,

    },
    {
        id: 14,
        thumbnail: meerkat,
        actualImage: `${backimageurl}/meerkat.png`,

    },
    {
        id: 15,
        thumbnail: Operahouse1,
        actualImage: `${backimageurl}/Operahouse1.png`,

    },
    {
        id: 16,
        thumbnail: Plant2,
        actualImage: `${backimageurl}/Plant2.png`,

    },
    {
        id: 17,
        thumbnail: Star,

        actualImage: `${backimageurl}/Star.png`,
    },
    // {
    //     id: 18,
    //     thumbnail: CircularQuay,
    //     actualImage: `${backimageurl}/IMG_2671.png`,
    // },
    {
        id: 19,
        thumbnail: RedIs,
        actualImage: `${backimageurl}/RedIs.png`,
    },
    // {
        //     id: 20,
        //     thumbnail: Flowerspink,
        //     actualImage: `${backimageurl}/Flowerspink.png`,

        // },
        {
            id: 21,
            thumbnail: Face,
            actualImage: `${backimageurl}/Face.png`,

        },
        {
            id: 22,
            thumbnail: Plant1,
            actualImage: `${backimageurl}/Plant1.png`,

        },
        {
            id: 23,
            thumbnail: Plant3,
            actualImage: `${backimageurl}/Plant3.png`,
    },
]
export const background = {
    hotChilies,
    Freedom,
    Operahouse2,
    bridge1,
    infrontOfDoor,
    palmTree,
    Coast,
    News,
    Bike,
    Crabpots1,
    redflowers,
    Bonsai,
    Bath,
    wallofcollocium,
    meerkat,
    Operahouse1,
    Plant2,
    Star,
    CircularQuay,
    RedIs,
    Flowerspink,
    Face,
    Plant1,
    Plant3
}