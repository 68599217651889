import { getSingleCheck } from "redux/actions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const useCheckApply = (userId) => {
  const dispatch = useDispatch();
  //SHAHED_NEW


  const handleChange = (userId, status) => {
    dispatch(getSingleCheck(userId, status));
  };
  return { handleChange };
};

export default useCheckApply;
