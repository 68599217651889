import { makeStyles } from "@material-ui/core";

const isResponsiveLayout = typeof window !== "undefined" && window.innerWidth;


export const useHomeStyles = makeStyles((theme) => {
    return {
        cardSection: {
            "&>div": {
                marginLeft: "-4px",
                cursor: "pointer",
            },
        },
        boardColor: {
            "& .MuiOutlinedInput-input": {
                padding: "5.5px",
                height: "42px",
            },
        },
        beforeSectionCard: {
            // marginRight: "32px",
            position: "relative",
            minWidth: "149px",
            flexBasis: 0,
        },
        homeBeforeSectionCard: {
            // marginRight: "32px",
            position: "relative",
            minWidth: "40px",
            flexBasis: 0,
        },
        iconStyle: {
            width: "17px",
            position: "absolute",
            top: "25px",
            zIndex: "1",
            right: "50px",
            cursor: "pointer",
            color: "red",
        },
        particularBox: {
            cursor: "pointer",
            display: "inline-block",
        },
        boxCircle: {
            borderRadius: "50%",
            background: (props) => (props.background ? props?.background : "#005ae5"),
            height: "128px",
            width: "128px",
            display: "flex",
            padding: "1.5rem",
            justifyContent: "center",
            alignItems: "center",
        },
        AddboxCircle: {
            borderRadius: "50%",
            height: "140px",
            width: "140px",
            display: "flex",
            padding: "1.5rem",
            justifyContent: "center",
            alignItems: "center",
            "& .MuiButton-root:hover": {
                background: "none",
            },
            "& .MuiButton-endIcon": {
                margin: 0,
            },
            cursor: "pointer",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
        AddboxCircle2: {
            borderRadius: "50%",
            height: "110px",
            width: "110px",
            display: "flex",
            padding: "0.8rem",
            justifyContent: "center",
            alignItems: "center",
            "& .MuiButton-root:hover": {
                background: "none",
            },
            "& .MuiButton-endIcon": {
                margin: 0,
            },
            cursor: "pointer",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
        buttonStyle2: {
            color: "white",
            fontSize: "15px",
            padding: 0
        },
        buttonInnerStyle: {
            height: "175px",
            color: "#7478E8",
            width: "168px",
        },
        buttonInnerStyle2: {
            height: "140px",
            color: "#7478E8",
            width: "133px",
        },
        permIdentityInnerStyle: {
            height: "38px",
            width: "38px",
            color: "#9A9A9A",
        },
        flowWarningColor: {
            color: "#E07539",
            textAlign: "center",
            marginLeft: "50px",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
        },
        scrollUpStyle: {
            display: "flex",
            alignItems: "center",
            // "&:hover": {
            //     background: "red"
            // }
        },
        streamCardBox: {
            display: "flex",
            overflowY: "hidden",
            marginTop: "10px",
            paddingLeft: "10px",
            alignItems: "center",
            maxWidth: `calc(${isResponsiveLayout} - 620px)`,
            padding: "20px 0px",
            "& .MuiGrid-container": {
                flexWrap: "nowrap",
            },
            "&": {
                "&::-webkit-scrollbar": {
                    width: "0.5em",
                    height: ".8em",
                },
            },
        },


        // for problem statement
        streamCardBoxforproblemstatement: {
            display: "flex",
            overflowY: "hidden",
            paddingLeft: "10px",
            alignItems: "center",
            paddingBottom: "10px",
            maxWidth: `calc(${isResponsiveLayout} - 120px)`,
            position: "relative",

            "& .MuiGrid-container": {
                flexWrap: "nowrap",
            },
            "&": {
                "&::-webkit-scrollbar": {
                    width: "0.2em",
                    height: ".3em",
                },
            },
        },

        AddboxCircleForProblemStatement: {
            borderRadius: "50%",
            height: "50px",
            width: "50px",
            display: "flex",
            padding: "0.3rem",
            justifyContent: "center",
            alignItems: "center",
            "& .MuiButton-root:hover": {
                background: "none",
            },
            "& .MuiButton-endIcon": {
                margin: 0,
            },
            cursor: "pointer",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },

        firstpaperproblemstatement: {
            // position: "relative",
            backgroundColor: "#e5e5e5",
            color: "#9a9a9a",
            // top: "70px",
            // left: "30%",
            height: "55px",
            width: "55px",
        },
        buttonInnerStyle2problem: {
            height: "60px",
            color: "#7478E8",
            width: "60px",
        },



        // forjourneymap
        streamCardBoxforjourney: {
            display: "flex",
            overflowY: "hidden",
            paddingLeft: "10px",
            alignItems: "center",
            paddingBottom: "10px",
            maxWidth: `calc(${isResponsiveLayout} - 120px)`,
            position: "relative",
            "& .MuiGrid-container": {
                flexWrap: "nowrap",
            },
            "&": {
                "&::-webkit-scrollbar": {
                    width: "0.2em",
                    height: ".3em",
                },
            },
        },
        // forjourneymap
        streamCardHoverBoxforjourney: {
            "&:hover": {
                background: "#e5e5e5"
            },
        },

        AddboxCircleForjourney: {
            borderRadius: "50%",
            height: "50px",
            width: "50px",
            display: "flex",
            padding: "0.3rem",
            justifyContent: "center",
            alignItems: "center",
            "& .MuiButton-root:hover": {
                background: "none",
            },
            "& .MuiButton-endIcon": {
                margin: 0,
            },
            cursor: "pointer",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },

        firstpaperjourney: {
            // position: "relative",
            backgroundColor: "#e5e5e5",
            color: "#9a9a9a",
            // top: "70px",
            // left: "30%",
            height: "55px",
            width: "55px",
        },
        buttonInnerStyle2journey: {
            height: "60px",
            color: "#7478E8",
            width: "133px",
        },

        circleBox:{
            borderRadius: "26px", height: "30px", width: "30px", background: "#bdbdbd", margin: "0", marginLeft: "45px", fontSize: "14px", paddingTop: "5px"
        },

        // end for journey
        firstpaper: {
            // position: "relative",
            backgroundColor: "#e5e5e5",
            color: "#9a9a9a",
            // top: "70px",
            // left: "30%",
            height: "120px",
            width: "120px",
        },
        problemstatementsec: {
            // height: "90%",
            overflow: "hidden",
            overflowY: "scroll"
        }
    };
});