import axios from '../../api/axios';
import newAxios from 'axios';
import { errorAlert, handleErrorMessage, successAlert } from 'Utils';
import { kanbanConstant } from './constant';
import { AppMode } from '../../urlConfig';
import fileDownload from 'js-file-download';
import { auth } from 'api/Auth';

export const sendValueStreamToGenerateKanban = (projectId, valueStreamDtoList, kanbanId, personaId) => {
    return async (dispatch) => {
        try {
            if (valueStreamDtoList) {
                successAlert("Kanban is generating. This may take some time")
                const res = await axios.post(`/v1/projects/${projectId}/${kanbanId}/lanes/generate-kanban/${personaId}`, valueStreamDtoList);
                if (res.status === 200) {
                    successAlert("Kanban generation successfully")
                }
                dispatch(getAllLaneListForKanban(projectId, kanbanId))
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                return
            } else {
                // successAlert("Kanban generation successfully")
            }

        }
        successAlert("Kanban generation successfully")
        dispatch(getAllLaneListForKanban(projectId, kanbanId))
    }
}
export const downloadFileFromBackend = (projectId, documentId, filename) => {
    return async (dispatch) => {
        try {
            if (documentId) {
                await axios.get(`v1/projects/${projectId}/documents/download/file/${documentId}`, {
                    responseType: 'blob',
                }).then(res => {
                    fileDownload(res.data, filename);
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 404) {
                errorAlert("File has beed deleted")
            }
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                // errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const previewfilefrombackend = (projectId, documentId, filename) => {
    return async (dispatch) => {
        try {
            if (documentId && filename) {
                await axios.get(`v1/projects/${projectId}/documents/download/file/${documentId}`, { responseType: 'blob' })
                    .then(res => {
                        var reader = new FileReader();
                        reader.readAsDataURL(res.data);
                        reader.onloadend = function () {
                            var base64data = reader.result;
                            dispatch({
                                type: kanbanConstant.IMAGE_PREVIEW,
                                payload: base64data
                            })
                        }
                    })

            } else {
                dispatch({
                    type: kanbanConstant.IMAGE_PREVIEW,
                    payload: null
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 404) {
                errorAlert("File has beed deleted")
            }
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                // errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const deleteFileFromBackend = (projectId, documentId) => {
    return async (dispatch) => {
        dispatch({
            type: kanbanConstant.DOCUMENT_DELETE_REQUEST,
        })
        try {
            if (documentId) {
                const res = await axios.delete(`v1/projects/${projectId}/documents/delete/file/${documentId}`)
                res && res.status === 204 && successAlert("document deleted successfully")
                dispatch({
                    type: kanbanConstant.DOCUMENT_DELETE_SUCCESS,
                    payload: { deleted: true, documentId }
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 404) {
                errorAlert("File has beed deleted")
            }
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                // errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const makingEmptyTheKanban = (projectId) => {
    return (dispatch) => {
        if (projectId)
            dispatch({
                type: kanbanConstant.MAKING_EMPTY,
            })
    }
}
export const getAllLaneListForKanban = (projectId, kanbanId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get(`/v1/projects/${projectId}/${kanbanId}/lanes/all`);
                dispatch({
                    type: kanbanConstant.GET_ALL_LANE_SUCCESS,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

// for specific users
export const getUsersLaneListForKanban = (projectId, kanbanId, users) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get(`/v1/projects/${projectId}/${kanbanId}/lanes/users/${users}`);
                dispatch({
                    type: kanbanConstant.GET_ALL_LANE_SUCCESS,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

// search lane list for kanban
export const searchUsersLaneListForKanban = (projectId, kanbanId, searchtext) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get(`/v1/projects/${projectId}/${kanbanId}/lanes/search/${searchtext}`)
                // console.log(res.data.data)
                dispatch({
                    type: kanbanConstant.GET_ALL_LANE_SUCCESS,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const filterCardByCardType = (projectId, kanbanId, type) => {
    return async(dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get(`/v1/projects/${projectId}/${kanbanId}/lanes/card-type/${type}`)
                // console.log(res.data.data)
                dispatch({
                    type: kanbanConstant.GET_ALL_LANE_SUCCESS,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const getAllForKanban = (projectId) => {
    return async (dispatch) => {
        dispatch({
            type: kanbanConstant.GET_ALL_LANE_REQUEST
        })
        try {
            if (projectId) {
                const res = await axios.get(`/v1/projects/${projectId}/kanbans`);
                dispatch({
                    type: kanbanConstant.GET_ALL_KANBAN_SUCCESS,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getSingleBoardKanban = (projectId, id) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get(`/v1/projects/${projectId}/kanbans/${id}`);
                dispatch({
                    type: kanbanConstant.GET_SINGLE_KANBAN_SUCCESS,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const deleteSingleBoardKanban = (projectId, id) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.delete(`/v1/projects/${projectId}/kanbans/${id}`);
                if (res.status === 204) {
                    dispatch({
                        type: kanbanConstant.DELETE_SINGLE_KANBAN_SUCCESS,
                        payload: id
                    })
                    successAlert("board deleted successfully")
                }
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const updateSingleBoardKanban = (projectId, id, updatedData) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.patch(`/v1/projects/${projectId}/kanbans/${id}`, updatedData);
                if (res.status === 200) {
                    dispatch({
                        type: kanbanConstant.UPDATE_SINGLE_KANBAN_SUCCESS,
                        payload: updatedData
                    })

                }
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const updateSingleBoardBckground = (projectId, id, updatedData) => {
    return async (dispatch) => {
        try {
            if (projectId && updatedData) {
                const res = await axios.post(`/v1/projects/${projectId}/kanbans/bgimage/${id}`, updatedData);
                if (res.status === 200 || res.status === 201) {
                    dispatch(getAllForKanban(projectId))
                    successAlert("board background updated")
                }
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const createNewBoardKanban = (projectId, newKanbanData) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.post(`/v1/projects/${projectId}/kanbans`, newKanbanData);
                dispatch({
                    type: kanbanConstant.ADD_NEW_KANBAN_EMPTY,
                    payload: res.data
                })
                res.data && successAlert("Board created successfully")
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const sendToKanban = (projectId, valueStreamDtoList) => {
    return async (dispatch) => {
        try {
            if (valueStreamDtoList) {
                const res = await axios.post(`/v1/projects/${projectId}/lanes/generate-kanban`, valueStreamDtoList);
                dispatch({
                    type: kanbanConstant.FOR_CSV_WITH_VALUE_STREAM,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const addNewLaneForKanban = (projectId, laneData, kanbanId) => {
    return async (dispatch) => {
        try {
            if (projectId && laneData) {
                const res = await axios.post(`/v1/projects/${projectId}/${kanbanId}/lanes`, laneData);
                res.data && successAlert("Lane added successfully")
                dispatch({
                    type: kanbanConstant.ADD_KANBAN_LANE,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const updateLaneForKanban = (projectId, updateLaneData, laneId, kanbanId) => {
    return async (dispatch) => {
        try {
            if (projectId && updateLaneData) {
                const res = await axios.put(`/v1/projects/${projectId}/${kanbanId}/lanes/${laneId}`, updateLaneData);
                res.data && successAlert("Lane updated successfully")
                // dispatch({
                //     type: kanbanConstant.UPDATE_KANBAN_LANE,
                //     payload: res.data
                // })
                const users = JSON.parse(sessionStorage.getItem("selectedusers"))
                // // console.log(users, "searched")
                if (users?.length > 0) {
                    dispatch(getUsersLaneListForKanban(projectId, kanbanId, users.map(item => item.id)))
                } else {
                    dispatch(getAllLaneListForKanban(projectId, kanbanId))
                }
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const deleteCustomLaneForKanban = (projectId, laneId, kanbanId) => {
    return async (dispatch) => {
        try {
            if (projectId && laneId) {
                const res = await axios.delete(`/v1/projects/${projectId}/${kanbanId}/lanes/${laneId}`);
                res.status === 204 && successAlert("Lane deleted successfully")
                dispatch({
                    type: kanbanConstant.DELETE_KANBAN_LANE,
                    payload: laneId
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const deleteCardFromKanban = (projectId, cardId, kanbanId) => {
    return async (dispatch) => {
        try {
            if (projectId && cardId) {
                const res = await axios.delete(`/v1/projects/${projectId}/${kanbanId}/lanes/card/${cardId}`);
                res.status === 204 && successAlert("Card deleted successfully")
                const users = JSON.parse(sessionStorage.getItem("selectedusers"))
                if (users?.length > 0) {
                    dispatch(getUsersLaneListForKanban(projectId, kanbanId, users.map(item => item.id)))
                } else {
                    dispatch(getAllLaneListForKanban(projectId, kanbanId))
                }
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const updateCustomLaneForKanban = (projectId, laneData, kanbanId) => {
    return async (dispatch) => {
        try {
            if (projectId && laneData) {
                const res = await axios.post(`/v1/projects/${projectId}/${kanbanId}/lanes/list`, laneData);
                res.data && successAlert("Lane dragged successfully")
                dispatch({
                    type: kanbanConstant.UPDATE_LANE_DRAG,
                    payload: res.data
                })
                const users = JSON.parse(sessionStorage.getItem("selectedusers"))
                // // console.log(users, "searched")
                if (users?.length > 0) {
                    dispatch(getUsersLaneListForKanban(projectId, kanbanId, users.map(item => item.id)))
                } else {
                    dispatch(getAllLaneListForKanban(projectId, kanbanId))
                }
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const findByLaneId = (projectId, valueStreamDtoList, name) => {
    return async (dispatch) => {
        try {
            if (valueStreamDtoList) {
                const res = await axios.post(`/v1/projects/${projectId}/lanes/generate-kanban`, valueStreamDtoList);
                dispatch({
                    type: kanbanConstant.FOR_CSV_WITH_VALUE_STREAM,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const cardUpdateByFromKanban = (projectId, cardId, cardData, acceptanceCriteria, kanbanId) => {
    return async (dispatch) => {
        try {
            if (projectId && cardId) {
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${auth.getToken()}`
                    },
                };
                const res = await newAxios.post(`${AppMode.backend}/v1/projects/${projectId}/${kanbanId}/lanes/card/${cardId}`, cardData, config);
                const responseTwo = await axios.post(`/v1/projects/${projectId}/${kanbanId}/lanes/card/${cardId}/acceptanceCriteria`, acceptanceCriteria);
                (res.data || responseTwo.data) && successAlert("Card Changed successfully")
                dispatch({
                    type: kanbanConstant.UPDATE_KANBAN_CARD,
                    payload: res.data
                })
                const users = JSON.parse(sessionStorage.getItem("selectedusers"))
                // // console.log(users, "searched")
                if (users?.length > 0) {
                    dispatch(getUsersLaneListForKanban(projectId, kanbanId, users.map(item => item.id)))
                } else {
                    dispatch(getAllLaneListForKanban(projectId, kanbanId))
                }
            }
        }
        catch (error) {
            if (error?.response?.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const cardUpdateWithCardsFromKanban = (projectId, cardData, kanbanId) => {
    return async (dispatch) => {
        try {
            if (projectId && cardData) {
                const res = await axios.patch(`/v1/projects/${projectId}/${kanbanId}/lanes/cards`, cardData);
                (res.data) && successAlert("Card Changed successfully")
                dispatch({
                    type: kanbanConstant.UPDATE_KANBAN_CARD,
                    payload: res.data
                })
                const users = JSON.parse(sessionStorage.getItem("selectedusers"))
                // // console.log(users, "searched")
                if (users?.length > 0) {
                    dispatch(getUsersLaneListForKanban(projectId, kanbanId, users.map(item => item.id)))
                } else {
                    dispatch(getAllLaneListForKanban(projectId, kanbanId))
                }
            }
        }
        catch (error) {
            if (error?.response?.status === 401) {
                // console.log(error.response)
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}


// #TODO no need
export const forFeatureLane = (projectId, featureName) => {
    return async (dispatch) => {
        try {
            if (projectId && featureName) {
                const res = await axios.put(`/v1/projects/${projectId}/types`, featureName);
                res.data && successAlert("Card Changed Successfully")
                dispatch({
                    type: kanbanConstant.UPDATE_KANBAN_CARD,
                    payload: res.data
                })
                dispatch(getAllLaneListForKanban(projectId))
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const addNewCardToKanban = (projectId, formDataOfKard, acceptanceCriteria, cardId, kanbanId) => {

    return async (dispatch) => {
        try {
            if (projectId) {
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${auth.getToken()}`
                    },
                };
                const res = await newAxios.post(`${AppMode.backend}/v1/projects/${projectId}/${kanbanId}/lanes/card`, formDataOfKard, config);
                const responseTwo = await axios.post(`/v1/projects/${projectId}/${kanbanId}/lanes/card/${cardId}/acceptanceCriteria`, acceptanceCriteria);
                (res.data || responseTwo.data) && successAlert("Card added successfully")
                // dispatch({
                //     type: kanbanConstant.UPDATE_KANBAN_CARD,
                //     payload: res.data
                // })
                const users = JSON.parse(sessionStorage.getItem("selectedusers"))
                // // console.log(users, "searched")
                if (users?.length > 0) {
                    dispatch(getUsersLaneListForKanban(projectId, kanbanId, users.map(item => item.id)))
                } else {
                    dispatch(getAllLaneListForKanban(projectId, kanbanId))
                }
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const deleteSingleAcceptanceCriteria = (projectId, acceptanceCriteriaId) => {

    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.delete(`/v1/projects/${projectId}/{kanbanId}/lanes/card/acceptanceCriteria/${acceptanceCriteriaId}`);
                (res.status === 204) && successAlert("AcceptanceCriteria deleted successfully")
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const notificationToTheNewUser = (cardId, redirectUrl, userId, message) => {
    return async (dispatch) => {
        try {
            if (cardId) {

                const newurlvar = `v1/projects/{projectId}/users/send/notification/email/${cardId}/${userId}?message=${message}&redirectUrl=${redirectUrl}`
                const res = await axios.patch(`v1/projects/{projectId}/users/send/notification/email/${cardId}/${userId}?message=${message}&redirectUrl=${redirectUrl}`);
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

// adding(patching data) estimate hour for each card
export const addEstimateHour = (projectId, kanbanId, cardId, hour) => {
    return async (dispatch) => {
        try {
            if (cardId) {
                const response = await axios.patch(`v1/projects/${projectId}/${kanbanId}/lanes/card/${cardId}/${hour}`)
                response && response.status === 200 && successAlert("Estimate hour added")
            }
        } catch (error) {
            if (error) {
                if (error && error.response && error.response.status === 401) {
                    return
                } else {
                    errorAlert(handleErrorMessage(error));
                }
            }
        }
    }
}

export const getVelocityReport = (projectId, data) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const response = await axios.post(`v1/projects/${projectId}/velocity-report/getvelocity`, data)
                dispatch({
                    type: kanbanConstant.GET_VELOCITY_REPORT,
                    payload: response.data
                })

            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const downloadJasperReports = (projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const response = await axios.post(`v1/projects/${projectId}/velocity-report/getvelocityreport`);
                fileDownload(response.data, "velocity_report.pdf")
                dispatch({
                    type: kanbanConstant.DOWNLOAD_JASPER_REPORTS,
                    payload: response.data
                })

            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const getAllCardsByProjectId = (projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const response = await axios.get(`v1/projects/{projectId}/cards/${projectId}`)
                dispatch({
                    type: kanbanConstant.GET_ALL_CARDS_BY_PROJECT_ID,
                    payload: response.data
                })

            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}


export const getAllKanbanCategory = (projectId) => {
    return async (dispatch) => {
        try {

            if (projectId) {
                const response = await axios.get("splitkanban/getkanbanlist/" + projectId)
                dispatch({
                    type: kanbanConstant.GET_KANBAN_CATEGORY,
                    payload: response.data
                })
            }
        } catch (error) {
            // console.log(error)
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error))
                dispatch({
                    type: kanbanConstant.GET_KANBAN_CATEGORY_ERROR
                })
            }
        }
    }
}


export const createKanbanCategory = (data) => {
    return async (dispatch) => {
        try {
            if (data.projectId) {
                const response = await axios.post(`splitkanban/saveSplitKanban`, data)
                if (response.status) {
                    // console.log(response)
                    dispatch({
                        type: kanbanConstant.CREATE_KANBAN_CATEGORY
                    })
                    getAllKanbanCategory(data.projectId)
                    successAlert("Category Saved Successfully")
                }
            }
        } catch (error) {
            dispatch({
                type: kanbanConstant.CREATE_KANBAN_CATEGORY
            })
        }
    }
}

export const deleteKanbanCategory = (kanbanId, projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const response = await axios.delete(`splitkanban/deletesplitkanban/?kanbanId=` + kanbanId)
                if (response.status) {
                    dispatch({
                        type: kanbanConstant.DELETE_KANBAN_CATEGORY
                    })
                    successAlert("Category Deleted Successfully");
                    getAllKanbanCategory(projectId);
                    // dispatch(getAllLaneListForKanban(projectId))
                }
            }
        } catch (error) {
            // console.log(error)
        }
    }
}

export const ChangeKanbanCategory = (data, projectId, kanbanId) => {
    return async (dispatch) => {
        try {
            if (data) {
                const res = await axios.get(`/v1/projects/${projectId}/${kanbanId}/lanes/all`);
                // console.log(data)
                if (data.id !== null) {

                    const uniqueKanbans = res.data.filter(kanban => data?.laneIds?.includes(kanban.id));
                    // console.log({ uniqueKanbans })
                    dispatch({
                        type: kanbanConstant.CHANGE_KANBAN_CATEGORY,
                        payload: uniqueKanbans
                    })
                } else {
                    // console.log(res.data)
                    dispatch({
                        type: kanbanConstant.CHANGE_KANBAN_CATEGORY,
                        payload: res.data
                    })
                }
            }
        } catch (error) {
            // console.log(error)
        }
    }
}


export const getReleaseCardCounting = (projectId) => {
    return async (dispatch) => {
        try {
            if(projectId){
                const res = await axios.get(`/v1/projects/{projectId}/{kanbanId}/lanes/kanban/releasecount/${projectId}`)

                if (res.status === 200) {
                    dispatch({
                        type: kanbanConstant.GET_RELEASE_CARD_COUNTINGS,
                        payload: res.data
                    })
                }
            }

        } catch (error) {
            // console.log(error)
        }
    }
}