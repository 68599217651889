import { makeStyles } from '@material-ui/core';

export const usePersonasStyles = makeStyles(() => ({
    root: {
      width: "100%",
      padding: "20px",
      fontFamily:"Asap"
    },
    removeUnderScoreMenuItem: {
      "&.MuiInput-underline:before": {
        content: "unset",
      },
    },
    sidebarOpen: {
      backgroundColor: "#6748A4",
      width: "21vh !important",
      minWidth: "fit-content",
      textAlign: "right",
      // height: "89vh",
      transition: "0.5s",
      top: "0",
      overflow: "hidden",
    },
    menuButton: {
      color: "white",
      border: "1px solid white",
      fontSize: "24px",
      fontWeight: "bold",
      cursor: "pointer",
      width: "45px",
    },
    sidebarClose: {
      backgroundColor: "#6748A4",
      width: "40px",
      textAlign: "right",
      minHeight: "89vh",
      height:"100%",
      transition: "0.5s",
      position: "sticky",
      top: "0",
    },
    internalItems: {
      textAlign: "left",
      width: "21vh",
      paddingLeft: "1rem",
      marginTop: "2rem",
      color: "white",
    },
    addpersona: {
      border: "2px solid white",
      color: "white",
      borderRadius: "26px",
      width: "94%",
      marginTop: "3rem",
      fontWeight: "bold",
    },
    deletepersona: {
      border: "2px solid white",
      color: "white",
      borderRadius: "26px",
      width: "94%",
      marginTop: ".5rem",
      fontWeight: "bold",
    },
    commentscontiner: {
      height: "130vh !important",
      overflow: "hidden",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "0px",
      },
    },
    comments: {
      marginLeft: "1rem",
      gap: "1rem",
    },
    secondMain: {
      backgroundColor: "#F0F0F0",
      height: "248px",
      cursor: "pointer",
    },
    addItem: {
      borderLeft: "5px solid gray",
      margin: ".5rem",
      height: "235px",
    },
    insideitem: {
      fontWeight: "bold",
      marginLeft: "1rem",
      paddingTop: "0.7rem",
    },
    circleIcon: {
      fontSize: "172px",
      color: "#c4c4c4",
      alignContent: "middle",
      position: "relative",
      top: "20px",
      left: "28%",
    },
    firstpaper: {
      position: "relative",
      backgroundColor: "#e5e5e5",
      color: "#9a9a9a",
      top: "26%",
      left: "30%",
      height: "120px",
      width: "120px",
    },
    userrcontents: {
      padding: "2rem",
      paddingTop: ".5rem",
    },
  }));