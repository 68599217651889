import { heartBeat } from './constant';
import axios from '../../api/axios';
import { getAllLaneListForKanban } from './kanban.actions';
import { successAlert, handleErrorMessage, errorAlert } from 'Utils';


// for fetching all log hours
export const fetchHeartBeat = (projectId, kanbanId) => {
    return async (dispatch) => {
        dispatch({
            type: heartBeat.GET_HEART_BEAT_API_REQUEST
        })
        try {
            const response = await axios.get(`/v1/projects/${projectId}/${kanbanId}/heartbeats`)
            dispatch({
                type: heartBeat.GET_HEART_BEAT_API_SUCCESS,
                payload: response.data
            })
            if (response.data.success === true) {
                dispatch(getAllLaneListForKanban(projectId, kanbanId))
                successAlert("Board Updated By " + response.data.updatedBy)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                // console.log(error.response)
            } else {
                errorAlert(handleErrorMessage(error));
            }
            dispatch({
                type: heartBeat.GET_HEART_BEAT_API_FAILED,
                payload: error.response
            })
        }
    }
}