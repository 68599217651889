import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    table_header: {
        border: "1px solid white",
        padding: "0px 15px 0px 0px",
        maxWidth: "1000px",
        wordBreak: "break-all",
        textAlign: "left"
    },
    deleteElement: {
        color: "black",
        height: "40px",
        width: "50px",
        margin: "10px 0px 20px 0px",
        cursor: "pointer",
    },
    submitAndCloseButton: {
        width: "137px",
        fontWeight: "700",
        height: "42px"
    },
    sideBar: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
    },
    wrapperFlow: {
        flexDirection: "column",
        display: " flex",
        height: `calc(100vh - ${80}px)`,
        flexBasis: "100px",
        overflow: "hidden",
        "& .MuiDropzoneSnackbar-successAlert": {
            background: "green",
            color: "white",
        },
    },
    dragstyle: {
        height: `calc(100vh - ${80}px)`,
        overflow: "hidden",
        width: "100%",
        zIndex: "1000",
        background: "#c1bcbc24",
    },
    reactflowWrapper: {
        height: `100vh`,
    },
    removewattermark: {
        "& .react-flow__attribution": {
            display: "none !important",
        },
    },
    form_Style: {
        minWidth: "800px",
    },
}));
export { useStyles }