import CircleBox from "Components/FlowComponents/CircleBox";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AddCircle as AddCircleIcon,
  PermIdentityTwoTone as PermIdentityTwoToneIcon,
  PersonOutline,
} from "@material-ui/icons";
import StreamCard from "Components/Reusable/Card/StreamCard";
import React, { useEffect, useRef, useState } from "react";
import { images } from "assets";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { AddValueStreamModal } from "./AddValueStreamModal";
import colors from "assets/Colors";
import {
  addNewValueStreamFlow,
  deleteValueStream,
  drapValueStreams,
  updateValueStream,
} from "redux/actions";
import { useDispatch } from "react-redux";
import { useToggle } from "hooks/useToggle";
import { UpdateValueStreamModal } from "./UpdateValueStreamModal";
import { CustomSnacbar } from "..";
/**
 *@function WorkFlow.jsx
 *@author Azim
 *
 **/
export const WorkFlowControl = ({
  classes,
  persona,
  setChangePersona,
  valueStream,
  history,
  workflow,
  journeyMap,
  getAvatar,
  titleText,
  workFlowStream,
  setOpenModal,
  workflowitem,
  singlePersona,
  setOpenTitle,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [newValueStream, setNewValueStream] = useState(false);
  const [updateModal, setUpdateModal] = useToggle();
  const [openValueStream, setOpenValueStream] = useToggle();
  const [modalData, setModalData] = useState({});

  // for dragging
  const [sortableStreams, setSortableStreams] = useState([]);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  useEffect(() => {
    const valueStreamNew =
      valueStream.length > 0 &&
      valueStream.map((item, index) => {
        if (item.indexNumber === null) {
          const itemNew = {
            ...item,
            indexNumber: index,
          };
          return itemNew;
        } else {
          return item;
        }
      });
    setSortableStreams(
      valueStreamNew &&
        valueStreamNew?.sort((a, b) => a.indexNumber - b.indexNumber)
    );
  }, [valueStream]);

  const {
    formState: { errors: errorsState },
    handleSubmit: handleCreateSubmit,
    reset,
    register,
    control: flowControl,
  } = useForm({
    mode: "all",
  });
  const {
    formState: { errors: formData },
    handleSubmit: handleUpdateSubmit,
    reset: resetUpdate,
    watch,
    control,
  } = useForm({
    mode: "all",
    defaultValues: {
      valueStreamName: modalData?.valueStreamName,
      valueStreamWhy: modalData?.valueStreamWhy,
      persona: modalData?.persona,
    },
  });
  const newvalueStreamName = watch("valueStreamName");
  const newValueStreamWhy = watch("valueStreamWhy");
  const newPersona = watch("persona");
  const handlePushState = (item) => {
    !workflow && history.push(`/journeyMap/${item.id}`);
    workflow && history.push(`/workflow/${item.id}`);
  };
  const handleUpdateModal = () => {
    if (newPersona || newValueStreamWhy || newvalueStreamName) {
      const updatedValueStream = {
        valueStreamName: newvalueStreamName,
        valueStreamWhy: newValueStreamWhy,
      };
      dispatch(updateValueStream(modalData?.id, updatedValueStream));
      setUpdateModal(false);
      resetUpdate();
    } else {
      setUpdateModal(false);
      resetUpdate();
    }
  };

  const handleDeleteEpic = () => {
    dispatch(deleteValueStream(modalData));
    setOpenValueStream(false);
    setUpdateModal(false);
    resetUpdate();
    setModalData({});
  };
  const handleOpenModalForUpdateValueStream = (item) => {
    setModalData(item);
    setUpdateModal(true);
  };
  const attempToDeleteValueStream = (modalData) => {
    setOpenValueStream(true);
    setModalData(modalData);
  };
  const onCreateSubmit = (data) => {
    const findColour = colors.filter(
      (val) => !valueStream.map((item) => item?.color).includes(val)
    );
    const color = findColour[Math.floor(Math.random() * findColour.length)];
    const updatedColor = color ? color : "#93d275";
    const newvaluesStream = {
      valueStreamName: data.valueStreamName,
      valueStreamWhy: data.valueStreamWhy,
      persona: data.persona,
      projectId: user.projectId,
      userId: user.id,
      color: updatedColor,
      workflowId: workflowitem.id,
    };
    dispatch(addNewValueStreamFlow(newvaluesStream, user.projectId));
    setNewValueStream(false);
    reset();
  };
  const onUpdateSubmit = () => {
    resetUpdate();
    setModalData({});
  };

  const handleSort = () => {
    // dupplicate items
    let _valueItems = [...sortableStreams];

    // remove and save the dragged item content
    const draggedItemContent = _valueItems.splice(dragItem.current, 1)[0];

    // switch the position
    _valueItems.splice(dragOverItem.current, 0, draggedItemContent);

    // reset the position
    dragItem.current = null;
    dragOverItem.current = null;

    // update the actual array
    setSortableStreams(_valueItems);

    const finalList = _valueItems.map((item, index) => {
      return {
        ...item,
        indexNumber: index,
      };
    });
    // console.log({ finalList });
    dispatch(drapValueStreams(finalList, user?.projectId));
  };

  return (
    <StreamCard
      titleVariant="h5"
      setOpenModal={setOpenModal}
      setOpenTitle={setOpenTitle}
      titleText={titleText}
      workFlowStream={workFlowStream}
      workflowitem={workflowitem}
      spaceflow={workflow}
      createflow={true}
      // journeymap={"% build"}
    >
      {newValueStream && (
        <AddValueStreamModal
          openValueStremModal={newValueStream}
          setOpenValueStremModal={setNewValueStream}
          reset={reset}
          handleSubmit={handleCreateSubmit}
          onSubmit={onCreateSubmit}
          register={register}
          errors={errorsState}
          realControl={flowControl}
          persona={singlePersona}
        />
      )}
      {updateModal && (
        <UpdateValueStreamModal
          updateModal={updateModal}
          handleUpdateModal={handleUpdateModal}
          handleUpdateSubmit={handleUpdateSubmit}
          onUpdateSubmit={onUpdateSubmit}
          control={control}
          modalData={modalData}
          formData={formData}
          persona={persona}
          attempToDeleteValueStream={attempToDeleteValueStream}
        />
      )}
      {openValueStream && (
        <CustomSnacbar
          opened={openValueStream}
          handleDeleteFromBackend={handleDeleteEpic}
          setOpenAlert={setOpenValueStream}
          type="Epic"
        />
      )}
      <Box style={{ maxWidth: "1400px" }} className={classes.streamCardBox}>
        <Grid className={classes.scrollUpStyle} container>
          {persona?.name &&
            !workFlowStream &&
            persona?.projectId &&
            !journeyMap && (
              <Tooltip title={persona?.name} placement="bottom" arrow>
                <Box
                  style={{
                    background: "#E5E5E5",
                    cursor: "pointer",
                    marginRight: "40px",
                  }}
                  onClick={() => setChangePersona(true)}
                  className={classes.AddboxCircle}
                >
                  <p
                    style={{
                      position: "relative",
                      lineHeight: 1,
                      color: "black",
                      top: "0",
                      fontSize: "1em",
                      textAlign: "center",
                    }}
                  >
                    <PersonOutline
                      style={{
                        fontSize: "9.5em",
                        color: "#BDBDBD",
                        position: "relative",
                      }}
                    />
                    <span
                      style={{
                        position: "relative",
                        top: "-60px",
                        fontSize: "1.2em",
                      }}
                    >
                      {persona?.name}
                    </span>
                  </p>
                </Box>
              </Tooltip>
            )}
          {sortableStreams.length > 0 &&
            sortableStreams.map((item, index) => (
              <div
                key={index}
                draggable
                style={{ backgroundColor: "#ffffff00", cursor: "move" }}
                onDragStart={(event) => (dragItem.current = index)}
                onDragEnter={(event) => (dragOverItem.current = index)}
                onDragEnd={handleSort}
                onDragOver={(e) => e.preventDefault()}
              >
                {journeyMap ? (
                  <Tooltip title={item?.name} placement="bottom" arrow>
                    <Box
                      style={{
                        background: "#E5E5E5",
                        cursor: "pointer",
                        marginRight: "40px",
                      }}
                      className={classes.AddboxCircle2}
                    >
                      {item?.avatar ? (
                        <div>
                          <Avatar
                            className={classes.firstpaper}
                            src={getAvatar(item?.avatar)}
                            onClick={() => handlePushState(item)}
                          ></Avatar>
                        </div>
                      ) : (
                        <p
                          style={{
                            position: "relative",
                            lineHeight: 1,
                            color: "black",
                            top: "0",
                            fontSize: ".5em",
                            textAlign: "center",
                          }}
                          onClick={() => handlePushState(item)}
                        >
                          <PersonOutline
                            style={{
                              fontSize: "15.5em",
                              color: "#BDBDBD",
                              position: "relative",
                            }}
                          />
                          <span
                            style={{
                              position: "relative",
                              top: "-60px",
                              fontSize: "2.2em",
                            }}
                          >
                            {item?.name.length > 7
                              ? item?.name.slice(0, 7) + "..."
                              : item?.name}
                          </span>
                        </p>
                      )}
                    </Box>
                  </Tooltip>
                ) : (
                  <Grid
                    className={classes.beforeSectionCard}
                    key={index}
                    item
                    md={2}
                  >
                    <img
                      onClick={() => handleOpenModalForUpdateValueStream(item)}
                      src={images.edit}
                      className={classes.iconStyle}
                      alt="Edit Button"
                    />
                    <Box
                      className={classes.particularBox}
                      onClick={() => {
                        history.push(
                          `/finalDiagram?streamId=${item.id}&workflowId=${workflowitem.id}`
                        );
                      }}
                    >
                      <CircleBox background={item?.color} item={item} />
                    </Box>
                  </Grid>
                )}
              </div>
            ))}

          {!journeyMap && (
            <Box
              style={
                persona?.name && persona?.projectId
                  ? { background: "transparent" }
                  : { background: "#E5E5E5" }
              }
              title={
                !(persona?.name && persona?.projectId)
                  ? "Click to add persona"
                  : ""
              }
              onClick={() => setNewValueStream(true)}
              className={classes.AddboxCircle}
            >
              <Tooltip
                placement="top"
                arrow
                title={
                  singlePersona?.name && persona?.projectId
                    ? "Click to add value stream"
                    : ""
                }
              >
                <Button
                  className={classes.buttonStyle}
                  disableRipple
                  endIcon={
                    persona?.name && persona?.projectId ? (
                      <AddCircleIcon className={classes.buttonInnerStyle} />
                    ) : (
                      <PermIdentityTwoToneIcon
                        className={classes.permIdentityInnerStyle}
                      />
                    )
                  }
                />
              </Tooltip>
            </Box>
          )}

          {!(persona?.name && persona?.projectId) && (
            <Typography className={classes.flowWarningColor}>
              You need to add persona before you create value <br /> stream.
              Click the icon to add persona.
            </Typography>
          )}
        </Grid>
      </Box>
    </StreamCard>
  );
};
