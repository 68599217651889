import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Box, IconButton } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { SVGRender, UserAvatar } from "Components/Reusable";
import { globalStyle } from "assets/Styles/GlobalStyle";
import { images } from "assets";
import Avatars from "assets/Avatars";

/**
 *@function Board.jsx
 *@author Shahed
 *
 **/

const ReleaseCandidate = ({ cardId, classes }) => {
  const { kanbanData } = useSelector((state) => state.kanban);
  const { organizationProfile } = useSelector((state) => state.orgprofile);
  const [toDoLane, setToDoLane] = useState();

  useEffect(() => {
    const abort = new AbortController();
    if (kanbanData) {
      let allcards = [];
      kanbanData.map((item) => {
        allcards.push(...item.cards);
        return null;
      });
      const cards = allcards.filter((value) => value.releaseRef === cardId);

      const newrows = cards
        ?.filter((value) => value.type !== "release")
        ?.map((item) => {
          return {
            id: item?.id,
            ticketname:
              item?.choresLabel !== ""
                ? item?.choresLabel
                : item?.bugsLabel !== ""
                ? item?.bugsLabel
                : item?.description !== ""
                ? item?.description
                : `As Antomina
I want to do the ${item.title} process
so that I can ${item.label}`,

            lanename: kanbanData.find((value) => value.id == item.laneId).title,
            workstarted:
              kanbanData.find((value) => value.id == item.laneId).title ===
              "To Do"
                ? null
                : item?.doingDate,
            workfinished: item?.acceptedDate,
            size: item?.size,
            owner: item?.userId,
            estimate: item?.dueDate,
          };
        });
      setToDoLane(newrows);
    }
    return () => {
      abort.abort();
    };
  }, [kanbanData]);

  const getAvatar = (value) => {
    const newAvatar = organizationProfile.find(
      (item) => item.id === value && item
    );
    let newData;
    Object.keys(Avatars).find((key, index) => {
      if (key === newAvatar?.avatar) {
        newData = `${Avatars[newAvatar?.avatar]}`;
        return newData;
      }
      return newData;
    });
    return newData;
  };

  const getName = (value) => {
    const showingName = organizationProfile.find(
      (item) => item.id === value && item
    );
    return showingName?.name;
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: "ticketname",
      headerName: "Ticket Name",
      width: 170,
      editable: false,
    },
    {
      field: "lanename",
      headerName: "Lane Name",
      width: 150,
      editable: false,
    },
    {
      field: "workstarted",
      headerName: "Work Started",
      width: 158,
      editable: false,
      renderCell: (params) => {
        const date =
          params.value !== null && params.value !== "null"
            ? new Date(params.value)
            : null;
        return (
          <span>
            {date !== null
              ? date.getDate() +
                "/" +
                parseInt(date.getMonth() + 1) +
                "/" +
                date.getFullYear()
              : "--/--/--"}
          </span>
        );
      },
    },
    {
      field: "size",
      headerName: "Size",
      width: 105,
      editable: false,
      renderCell: (params) => {
        return (
          <IconButton>
            {params && params.value ? (
              <SVGRender
                style={globalStyle.renderStyle}
                img={
                  params?.value === "0"
                    ? images.tShirt
                    : params?.value === "1"
                    ? images.smallIconForSize
                    : params.value === "2"
                    ? images.mediumIconForSize
                    : images.largeIconForSize
                }
                alt={"small Icon"}
              />
            ) : (
              <SVGRender
                style={globalStyle.renderStyle}
                img={images.tshirt}
                alt={"small Icon"}
              />
            )}
          </IconButton>
        );
      },
    },
    {
      field: "owner",
      headerName: "Owner",
      width: 120,
      editable: false,
      renderCell: (params) => {
        return (
          <IconButton>
            {params.value ? (
              <UserAvatar
                className={classes.updateCardAvatar}
                getAvatar={getAvatar}
                getName={getName ?? "no"}
                userId={params?.value}
              />
            ) : (
              <Avatar className={classes.updateCardAvatar} />
            )}
          </IconButton>
        );
      },
    },
    {
      field: "workfinished",
      headerName: "Work Finished",
      width: 170,
      editable: false,
      renderCell: (params) => {
        const newdate =
          params.value !== null && params.value !== "null"
            ? new Date(params.value)
            : null;
        return (
          <span>
            {newdate !== null
              ? newdate.getDate() +
                "/" +
                parseInt(newdate.getMonth() + 1) +
                "/" +
                newdate.getFullYear()
              : "--/--/--"}
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <Box sx={{ width: "100%", minWidth: "875px" }}>
        {toDoLane && toDoLane ? (
          <DataGrid
            rows={toDoLane}
            columns={columns}
            pageSize={5}
            autoHeight={true}
            rowsPerPageOptions={[5]}
            density="compact"
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        ) : null}
      </Box>
    </div>
  );
};

export default ReleaseCandidate;
