import { Tooltip } from "@material-ui/core";
import React from "react";
import { Handle } from "reactflow";
import "./index.css";
import { isImage } from "Utils/flowUtils";
import LinkIcon from '@material-ui/icons/Link';
import { useState } from "react";
import { ReactTinyLink } from 'react-tiny-link'
const LinkNode = ({ data }) => {
  const [validUrl, setValidUrl] = useState(true);
  const handleImageError = () => {
    setValidUrl(false);
  };
  return (
    <div className="filesNode">
        <ReactTinyLink
          cardSize="large"
          showGraphic={true}
          maxLine={2}
          minLine={1}
          url="https://figma.com/file/kYrQg9a6XGcPB5WVDBmyDo/SquareBear-Kanban?type=design&mode=design&t=pnT5gpTdpnGFkLtc-0"
        />
      <Tooltip placement={"top"} arrow title={"file node"}>
        {isImage(data?.url) ? (
          <>
            {validUrl && (
              <img
                style={{
                  background: "white",
                  width: "120px",
                  height: "120px",
                  padding: "5px",
                  objectFit: "cover", //contain
                }}
                src={data?.url}
                alt="files"
                onError={handleImageError}
              />
            )}
            {!validUrl && (
              <LinkIcon
                style={{
                  color: "#7478E8",
                  height: "120px",
                  width: "120px",
                  padding: "5px",
                }}
              />
            )}
          </>
        ) : (
          <LinkIcon
            style={{
              color: "#7478E8",
              height: "120px",
              width: "120px",
              padding: "5px",
            }}
          />
        )}
      </Tooltip>

      <Handle
        type="source"
        position="bottom"
        id="file_source"
        style={{
          zIndex: "2",
          bottom: 0,
          right: 0,
          background: "#101921",
          padding: "3px",
          transform: "rotate(-45deg)",
          content: "data",
        }}
      />
    </div>
  );
};
export default LinkNode;
