import { getBezierPath } from 'reactflow'

export default function BendyEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
}) {
    const midPoint = (targetY - sourceY) / 2 + sourceY
    const [edgePath] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX: targetX + 200,
        targetY: midPoint,
        targetPosition,
    })
    const [edgePath2] = getBezierPath({
        sourceX: targetX + 200,
        sourceY: midPoint,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })

    return (
        <>
            <path id={id} className="react-flow__edge-path" d={edgePath + edgePath2} />
        </>
    )
}