import LeftSideComp from "Components/ProjectScreenComps/LeftSideComp";
import RightSideComp from "Components/ProjectScreenComps/RightSideComp";
import { Container, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 *@function SplashScreen.jsx
 *@author Shahed
 *
 **/

const SplashScreen = () => {
  const classes = useStyles();
  return (
    <div style={{ backgroundColor: "#e9e574", height:"100vh" }}>
      <Container maxWidth="md" >
        <Paper className={classes.innerDiv} >
          <Grid container className={classes.containerbox}>
            <Grid item xs={8}>
              <LeftSideComp />
            </Grid>
            <Grid item xs={4}>
              <RightSideComp />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
    
  );
};

export default SplashScreen;

const useStyles = makeStyles((theme) => ({
  innerDiv: {
    position: "relative",
    top: "17vh",
  },
  containerbox: {
    height: "550px",
  },
}));
