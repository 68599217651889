import React, { useCallback, useEffect, useState } from "react";

import { Box, CircularProgress, TextField } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Layout from "Components/Layout";
import Board from "react-trello";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLaneListForKanban,
  cardUpdateByFromKanban,
  getPersona,
  getNewValueStream,
  loadAllDiagramFromBackend,
  getOrganizationProfile,
  addNewCardToKanban,
  updateCustomLaneForKanban,
  deleteCardFromKanban,
  sendValueStreamAndDiagramForCSV,
  nullifiedCSV,
  cardUpdateWithCardsFromKanban,
  deleteSingleAcceptanceCriteria,
  notificationToTheNewUser,
  storeLogHour,
  fetchLogbyCard,
  fetchEnableLoghour,
  fetchHeartBeat,
  updateSingleBoardBckground,
  getRiskLaneNotDeletableBoard,
  getPersonas,
  getAllCardsByProjectId,
  getVelocityReport,
} from "redux/actions";
import { parse } from "zipson";
import DateFnsUtils from "@date-io/date-fns";
import { useForm } from "react-hook-form";
import { multidates } from "Utils";
import {
  AddButton,
  ReleaseButton,
  AddNewForBoard,
  CustomLaneHeader,
  NewCustomCard,
  UpdateBoardCard,
} from "Components/BoardComponents";
import Avatars from "assets/Avatars";
import { nanoid } from "nanoid";
import { CustomSnacbar, Modal, Loader, MPopover } from "Components/Reusable";
import { convertToRaw, EditorState } from "draft-js";
import {
  getSingleAcceptanceCriteria,
  changeArrayPositionByIndexNumber,
} from "Utils";
import { boarsdData } from "assets/Data/BoardsData";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
import { useStyles } from "assets/Styles/Board";
import { globalStyle } from "assets/Styles/GlobalStyle";
import { Fragment } from "react";
import { images } from "assets";
import { createRiskSubmit } from "helpers/RiskAndIssue";
import {
  allTypeFormData,
  blockers,
  taskFormData,
  writingComment,
} from "helpers/Board";
import { format } from "date-fns";
import { useRef } from "react";
import useRenderCardConfig from "hooks/useRenderCardConfig";

/**
 *@function Board.jsx
 *@author Azim
 *
 **/

const Boards = (props) => {
  // for card config
  const { cardRenderer, loading: homeLoading } = useRenderCardConfig();
  //state of this file
  const domLocation = window?.location?.href;
  /* Modal related states */
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForAddCard, setModalOpenForAddCard] = useState(false);
  const [onEditLinkToDesign, setOnEditLinkToDesign] = useState(false);
  const [openBlockersSection, setOpenBlockersSection] = useState(false);
  const [openForUpdate, setOpenForUpdate] = useState(false);
  // const [pendingRequest, setPendingRequest] = useState(false);
  const [openToChangeTheUserStories, setOpenToChangeTheUserStories] =
    useState(false);
  const { imagepreview } = useSelector((state) => state.kanban);
  const { notDeletableItem } = useSelector((state) => state.riskandissue);

  //Dummy Data to prevent errors for Blockers and notes

  //popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTimeSheet, setAnchorElTimeSheet] = useState(null);

  //create aceptance criteria
  const [openTextFieldToAcceptance, setOpenTextFieldToAcceptance] =
    useState(true);
  const [openAcceptanceCriteria, setOpenAcceptanceCriteria] = useState(false);
  const [
    singleToUpdateAcceptanceCriteria,
    setSingleToUpdateAcceptanceCriteria,
  ] = useState({});
  const [createAceptanceCriteria, setcreateAceptanceCriteria] = useState([]);
  const [updateSingleAcceptanceCriteria, setUpdateSingleAcceptanceCriteria] =
    useState("");
  //update aceptance criteria
  const [aceptanceCriteria, setAceptanceCriteria] = useState([]);
  const [singleAcceptanceCriteria, setSingleAcceptanceCriteria] = useState(
    boarsdData.initialState
  );
  const [openAcceptanceForUpdate, setOpenAcceptanceForUpdate] = useState(false);
  const [currentGPT, setCurrentGPT] = useState(false);
  const [singleAcceptanceToUpdateCard, setSingleAcceptanceToUpdateCard] =
    useState({});
  //alert to delete
  const [openAlert, setOpenAlert] = useState(false);
  const [alertToDelete, setAlertToDelete] = useState({});
  const [openToChangeTheBugsLabel, setOpenToChangeTheBugsLabel] =
    useState(false);
  const [openChangeToReleaseName, setOpenReleaseNameUpdate] = useState(false);
  const [openChangeToGoal, setOpenChangeToGoal] = useState(false);
  const [openToChangeTheChoresLabel, setOpenToChangeTheChoresLabel] =
    useState(false);
  /* data related states */
  const [filesToSave, setFilesToSave] = useState([]);
  const [updateFilesToSave, setUpdateFilesToSave] = useState([]);
  //assesment
  const [assesments, setAssesments] = useState([]);
  const [feature, setFeature] = useState("");
  const [cardData, setCardData] = useState("");
  const [cardId, setCardId] = useState("");
  //AutoComplete filter to show the onChange function
  const [toEmails, setToEmails] = useState([]);
  // for related field
  const [related, setAllRelated] = useState([]);
  const [updateReleate, setAllUpdateRelated] = useState([]);

  const [epicId, setEpicId] = useState("");
  const [assignee, setAssignee] = useState("");
  //update related States
  const [updateEpicId, setUpdateEpicId] = useState("");
  const [updatPersonaId, setUpdatPersonaId] = useState("");
  const [updateAssignee, setUpdateAssignee] = useState("");
  // comment related states
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [openToComment, setOpenToComment] = useState(false);
  const [savedDescription, SetSavedDescription] = useState([]);
  const [prevcomments, setPrevSavedComment] = useState([]);
  const [updatenotes, setUpdatenotes] = useState(false);
  const [updateToNotes, setUpdateToNotes] = useState(null);
  const [openAddAttachment, setOpenAddAttachment] = useState(false);

  // for description section
  const [description, setDescription] = useState(null);

  const [prevdescription, setPredescription] = useState(null);
  const [opendescription, setOpenDescription] = useState(false);
  // const [isCtrlPressed, setIsCtrlPressed] = useState(false);

  // for getting velocity report
  const fetchDateChange = useCallback((value) => {
    const data = {
      startDate: multidates[3].date,
      endDate: format(new Date(), "dd-MM-yyyy"),
    };
    dispatch(getVelocityReport(user?.projectId, data));
  }, []);

  useEffect(() => {
    fetchDateChange(1);
  }, [fetchDateChange]);

  // for image preview
  useEffect(() => {
    if (imagepreview) {
      // // console.log(imagepreview);
    }
  }, [imagepreview]);

  useEffect(() => {
    const parse = querystring.parse(search);
    if (parse.card !== undefined) {
      setCardId(parse.card);
    }
  }, []);
  //update blockers
  const [updateBlockers, setUpdateBlockers] = useState([]);
  const [transientBlockers, setTransientBlockers] = useState([]);
  /* icons related states */
  const [sizeOfProblem, setSizeOfProblem] = useState(0);
  const [updateSizeOfProblem, setUpdateSizeOfProblem] = useState(0);
  const [cardType, setCardType] = useState(
    cardRenderer.feature ? 0 : cardRenderer.task ? 1 : cardRenderer.bug ? 2 : 0
  );
  /* date related states */
  const [selectedDate, setSelectedDate] = useState(null);
  const [anotherselectedDate, setAnotherSelectedDate] = useState(new Date());
  const [updateSelectedDate, setUpdateSelectedDate] = useState(null);

  /* Estimate related states */
  const [timesheet, setTimeSheet] = useState(false);
  const loghours = useSelector((state) => state.loghour.data);
  const [dateinclude, setDateInclude] = useState([]);
  const [loading, setLoading] = useState(false);
  const logEnable = useSelector((state) => state.logenable.enable);
  const [estimateHour, setEstimateHour] = useState(0);
  const [editloading, setEditLoading] = useState(true);

  /* global(redux) related states */
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { kanbanData, kanban } = useSelector((state) => state.kanban);
  const { user } = useSelector((state) => state.auth);
  const { allinitials, valueStream, persona } = useSelector(
    (state) => state.diagram
  );
  const { organizationProfile } = useSelector((state) => state.orgprofile);

  // for card modal close
  const [openalertnew, setOpenAlertnew] = useState(false);

  // for getting heartbeat api
  const [background, setBackground] = useState("");



  useEffect(() => {
    if (kanban && kanban.length) {
      setBackground(kanban.find((item) => item.id === user?.kanbanId));
    }
  }, [kanban]);
  /* Lifecycle method of this file*/
  useEffect(() => {
    if (user?.projectId) {
      dispatch(getAllLaneListForKanban(user?.projectId, user?.kanbanId));
      dispatch(getRiskLaneNotDeletableBoard(user?.projectId));
      dispatch(getPersonas(user?.projectId));
      dispatch(getAllCardsByProjectId(user?.projectId));
      sessionStorage.removeItem("selectedusers");
    }
  }, [dispatch, user?.projectId]);

  useEffect(() => {
    dispatch(getPersona(user?.projectId));
  }, [dispatch, user?.projectId]);

  useEffect(() => {
    if (user?.projectId) {
      dispatch(getOrganizationProfile(user?.projectId));
      dispatch(getNewValueStream(user?.projectId));
      dispatch(loadAllDiagramFromBackend(user?.projectId));
    }
  }, [user?.projectId, dispatch]);
  const {
    formState: { errors, isDirty },
    handleSubmit: handleUpdateSubmit,
    reset,
    control,
    setValue: setUpdateValue,
    getValues: getUpdateValues,
  } = useForm({
    mode: "all",
    defaultValues: cardData,
  });

  const {
    formState: { errors: createError },
    handleSubmit: handleCreateSubmit,
    reset: resetForCreate,
    control: controlForCreate,
    getValues,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    mode: "all",
  });
  const label = getValues("label");
  const title = getValues("title");
  // const singlePersonas = getValues("personas");
  const bugsLabel = getValues("bugsLabel");
  const choresLabel = getValues("choresLabel");
  const updateLabel = getUpdateValues("label");
  const updateTitle = getUpdateValues("title");
  const updateBugsLabel = getUpdateValues("bugsLabel");
  const updateChoresLabel = getUpdateValues("choresLabel");
  const updateReleaseName = getUpdateValues("releaseName");
  const updateReleaseGoal = getUpdateValues("goal");

  //the componentDidmount and componentWillUpdate for create Card
  useEffect(() => {
    // watch
    if (cardType === 0) {
      setValue("label", label);
      setValue("title", title);
      // setValue("bugsLabel", "");
      // setValue("choresLabel", "");
      clearErrors(["choresLabel", "bugsLabel"]);
    }
    if (cardType === 1) {
      setValue("label", "");
      setValue("title", "");
      setValue("bugsLabel", bugsLabel);
      // setValue("choresLabel", "");
      clearErrors(["label", "bugsLabel", "title"]);
    }
    if (cardType === 2) {
      setValue("label", "");
      setValue("title", "");
      // setValue("bugsLabel", "");
      setValue("choresLabel", choresLabel);
      clearErrors(["label", "choresLabel", "title"]);
    }
  }, [bugsLabel, label, title, setValue, cardType, choresLabel, clearErrors]);

  // for getting heartbeat
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchHeartBeat(user?.projectId, user?.kanbanId));
    }, 3000);
    return () => clearInterval(interval);
  });

  //Kanban Data for Board
  const kanbanDataForCreate = [...kanbanData];
  const newKanban = kanbanDataForCreate.map((item) => {
    const newItem = { ...item };
    if (item.title === "Ice Box") {
      // newItem.id = newItem.label;
      return newItem;
    }
    if (item.title === "To Do") {
      // newItem.id = newItem.label;
      return newItem;
    } else {
      newItem.disallowAddingCard = true;
      return newItem;
    }
  });
  const data = {
    lanes: newKanban ? newKanban : [],
  };
  const handleChangeBackground = (key) => {
    dispatch(
      updateSingleBoardBckground(user?.projectId, user?.kanbanId, {
        backgroundImage: key.actualImage,
      })
    );
  };
  const handleEditCard = (e) => {
    setModalOpen(true);
    const particularCard = kanbanData.map((item) => item?.cards);

    const releaseCard = particularCard.flat(2);
    if (releaseCard) {
      const newCardModalUser = releaseCard.find((item) => item?.issueCode === e);
      setCardData(newCardModalUser);
      setUpdateEpicId(newCardModalUser?.epicId);
      setUpdatPersonaId(newCardModalUser?.personaId);
      setUpdateAssignee(newCardModalUser?.userId);
      setAceptanceCriteria(newCardModalUser?.acceptanceCriteria);
      setUpdateBlockers(
        newCardModalUser?.blockers.map((item) => item && JSON.parse(item))
      );
      setTransientBlockers(
        newCardModalUser?.blockers.map((item) => item && JSON.parse(item))
      );
      setPredescription(newCardModalUser?.description);
      setDescription(newCardModalUser?.description);
      setCurrentGPT(newCardModalUser?.aiGenerate);
      setUpdateSelectedDate(
        newCardModalUser?.dueDate ? newCardModalUser?.dueDate : null
      );
      SetSavedDescription(
        newCardModalUser?.metadata ? JSON.parse(newCardModalUser?.metadata) : []
      );
      setFeature(newCardModalUser?.title);
      setPrevSavedComment(
        newCardModalUser?.metadata ? JSON.parse(newCardModalUser?.metadata) : []
      );
      setAssesments(
        newCardModalUser?.assessments.length > 2
          ? newCardModalUser?.assessments.map(
            (item) => item && JSON.parse(item)
          )
          : boarsdData.initialBoardAssesment
      );
      setUpdateSizeOfProblem(Number(newCardModalUser?.size));
      setOpenAcceptanceForUpdate(false);
      setEditLoading(false);
    }
  };

  useEffect(() => {
    // console.log(cardId)
    const abort = new AbortController();
    let newcards = [];
    if (kanbanData) {
      kanbanData?.map((item) => {
        newcards.push(...item.cards);
        return item;
      });
    }
    if (newcards.find((value) => value.issueCode === cardId) !== undefined) {
      setEditLoading(true);
      if (cardId !== "") {
        window.history.pushState(
          null,
          "",
          window.location.origin + window.location.pathname + `?card=${cardId}`
        );
        handleEditCard(cardId);
      }
    } else {
      window.history.pushState(
        null,
        "",
        window.location.origin + window.location.pathname
      );
    }
    return () => {
      abort.abort();
    };
  }, [cardId, kanbanData]);

  const handleDeleteFromBackend = (id) => {
    dispatch(deleteCardFromKanban(user?.projectId, id, user?.kanbanId));
    setOpenAlert(false);
  };

  const handleCardToAnotherLane = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    let prevSourceCard = ""
    let prevSource = ""
    const targetedIndexData = particularCards.find(
      (item) => item.id === targetLaneId
    );
    const makedFunCards = [...targetedIndexData.cards];
    const fes = makedFunCards.findIndex((item) => item.id === cardId);
    if (fes === position) {
      return;
    }
    const cardMovedDetailsForLane = { ...cardDetails };
    // // console.log(cardMovedDetailsForLane.laneId)
    cardMovedDetailsForLane.laneId = targetLaneId;
    cardMovedDetailsForLane.projectId = user?.projectId;
    if (sourceLaneId === targetLaneId) {
      const respondedArray = changeArrayPositionByIndexNumber(
        fes,
        position,
        cardMovedDetailsForLane,
        makedFunCards
      );

      const INdexedResponse = respondedArray.map((item, index) => {
        item.laneId =
          item?.laneId === "Ice Box"
            ? kanbanData &&
            kanbanData.find(
              (value) =>
                value.title === "Ice Box" || value.label === "Ice Box"
            ).id
            : item?.laneId === "To Do"
              ? kanbanData &&
              kanbanData.find(
                (value) => value.title === "To Do" || value.label === "To Do"
              ).id
              : item?.laneId;
        const singleItem = { ...item };
        singleItem.indexNumber = index;
        return singleItem;
      });

      const mainCard = INdexedResponse.findIndex(
        (value) => value.id === cardMovedDetailsForLane.id
      );
      const newcards = INdexedResponse.slice(mainCard, INdexedResponse.length);
      let releaseRef = null;

      for (const card of newcards) {
        if (card.type === "release") {
          releaseRef = card.id;
          break;
        }
      }

      const newIndexedResposne = INdexedResponse.map((item) => {
        if (item.id === cardMovedDetailsForLane.id) {
          const data = {
            ...item,
            releaseRef: releaseRef,
          };
          return data;
        }
        return item;
      });

      dispatch(
        cardUpdateWithCardsFromKanban(
          user?.projectId,
          cardMovedDetailsForLane.type === "release"
            ? respondedArray
            : newIndexedResposne,
          user?.kanbanId
        )
      );
    } else if (prevSourceCard !== cardId && prevSource !== sourceLaneId) {
      try {
        makedFunCards.splice(position, 0, cardMovedDetailsForLane);
        let indexNumber = cardMovedDetailsForLane.indexNumber;
        const uniqueCards = Array.from(
          new Set(makedFunCards.map((a) => a.id))
        ).map((id) => {
          return makedFunCards.find((a) => a.id === id);
        });
        const INdexedResponse = uniqueCards.map((item, index) => {
          const laneDetail =
            kanbanData &&
            kanbanData.find(
              (value) => value.id === cardMovedDetailsForLane?.laneId
            );

          if (laneDetail?.title === "Ice Box") {
            item.doingDate = null;
          } else if (laneDetail?.title === "To Do") {
            item.doingDate = new Date();
          } else if (laneDetail?.title === "Doing") {
            item.doingDate = new Date();
          } else {
            item.doingDate = item.doingDate;
          }

          item.acceptedDate =
            laneDetail?.title === "Accepted"
              ? item.acceptedDate
                ? item.acceptedDate
                : new Date()
              : null;
          const singleItem = { ...item };
          singleItem.indexNumber = index;
          return singleItem;
        });
        const mainCard = INdexedResponse.findIndex(
          (value) => value.id === cardMovedDetailsForLane.id
        );
        const newcards = INdexedResponse.slice(
          mainCard,
          INdexedResponse.length
        );
        let releaseRef = "";

        for (const card of newcards) {
          if (card.type === "release") {
            releaseRef = card.id;
            card.laneId = kanbanData.find(
              (value) => value.title === "To Do" || value.label === "To Do"
            ).id;
            break;
          }
        }

        const newIndexedResposne = INdexedResponse.map((item) => {
          if (item.id === cardMovedDetailsForLane.id) {
            const status =
              kanbanData.find((value) => value.id === targetLaneId).title ===
              "Ice Box";
            const data = {
              ...item,
              releaseRef:
                status === true
                  ? null
                  : releaseRef
                    ? releaseRef
                    : item?.releaseRef,
              indexNumber:
                item?.type === "release" ? indexNumber : item?.indexNumber,
            };
            return data;
          }
          return item;
        });

        dispatch(
          cardUpdateWithCardsFromKanban(
            user?.projectId,
            newIndexedResposne,
            user?.kanbanId
          )
        );
      } catch (error) {
        // console.log(error);
      }
    }
    prevSource = sourceLaneId
    prevSourceCard = cardId
    
  };

  const handleLaneDragEnd = (removedIndex, addedIndex, payload) => {
    const newUpdatedLanes = [...particularCards];
    const updatedAe = changeArrayPositionByIndexNumber(
      removedIndex,
      addedIndex,
      payload,
      newUpdatedLanes
    );
    const INdexedResponse = updatedAe.map((item, index) => {
      item.id =
        item.id === "Ice Box"
          ? kanbanData &&
          kanbanData.find(
            (value) => value.title === "Ice Box" || value.label === "Ice Box"
          ).id
          : item.id === "To Do"
            ? kanbanData &&
            kanbanData.find(
              (value) => value.title === "To Do" || value.label === "To Do"
            ).id
            : item.id;
      const singleItem = { ...item };
      singleItem.serial = index;
      return singleItem;
    });
    dispatch(
      updateCustomLaneForKanban(
        user?.projectId,
        INdexedResponse,
        user?.kanbanId
      )
    );
  };

  const renderStyle = { height: "24px", width: "24px" };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // pop over for timesheet
  const popoverRef = useRef();
  const PopOverForTimeSheet = () => {
    return (
      <MPopover
        id={id}
        ref={popoverRef}
        open={timesheet}
        anchorEl={anchorElTimeSheet}
        onClose={() => {
          handleTimeSheetClose();
          setAnchorElTimeSheet(null);
          setTimeSheet(false);
        }}
        className={classes.timeSheetStyle}
      >
        <Box padding={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.muiDateWrapper}
              disableToolbar
              format="dd-MM-yyyy"
              margin="normal"
              variant="outlined"
              id="date-picker-inline"
              label="This week"
              value={anotherselectedDate}
              size="small"
              onChange={(date) => handleChangeDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          {!loading ? (
            <Box display={"flex"} paddingTop={2}>
              {new Array(7).fill().map((_, index) => {
                const date = new Date(anotherselectedDate);
                date.setDate(date.getDate() + (index - 3));

                const matched =
                  loghours &&
                  loghours.find(
                    (item) =>
                      new Date(item.date).getDate() === date.getDate() &&
                      cardData.id === item.cardId &&
                      new Date(item.date).getFullYear() ===
                      date.getFullYear() &&
                      new Date(item.date).getMonth() === date.getMonth()
                  );
                let weekday = date.toLocaleString("en-us", {
                  weekday: "long",
                });
                return (
                  <div style={index !== 0 ? { marginLeft: "15px" } : null}>
                    <div>
                      {weekday.substring(0, 3)}
                      {`(${date.getDate()})`}
                    </div>
                    <TextField
                      defaultValue={
                        matched && matched.hours ? matched.hours : 0
                      }
                      variant="outlined"
                      size="small"
                      type="number"
                      style={{ width: "80px" }}
                      onBlur={(event) =>
                        event.target.value !== ""
                          ? setDateInclude([
                            ...dateinclude,
                            {
                              date: date,
                              hours: event.target.value,
                              id:
                                matched && matched.id
                                  ? matched.id
                                  : nanoid(15),
                            },
                          ])
                          : null
                      }
                      placeholder="0h"
                      disabled={date.getTime() > new Date().getTime()}
                    />
                  </div>
                );
              })}
            </Box>
          ) : null}
        </Box>
      </MPopover>
    );
  };

  const handleUpdateCardCloseConfirm = (cancelbtn) => {
    if (cancelbtn === false) {
      const newclickbtn = window.document.getElementById("submit-button");
      newclickbtn?.click();
    }

    window.history.pushState(
      null,
      "",
      window.location.origin + window.location.pathname
    );
    setModalOpen(false);
    setOnEditLinkToDesign(false);
    setOpenBlockersSection(false);
    setOpenToChangeTheBugsLabel(false);
    setOpenChangeToGoal(false);
    setOpenReleaseNameUpdate(false);
    setOpenToChangeTheChoresLabel(false);
    setOpenToChangeTheUserStories(false);
    setOpenForUpdate(false);
    setOpenAcceptanceForUpdate(false);
    setCurrentGPT(false);
    setUpdateFilesToSave([]);
    setAceptanceCriteria([]);
    SetSavedDescription([]);
    setSingleAcceptanceToUpdateCard({});
    setOpenToComment(false);
    setEstimateHour(0);
    setCardId("");
    setDescription(null);
    setOpenDescription(false);
    setOpenAlertnew(false);
    reset();
  };
  const handleCardSection = () => {
    return (
      <>
        {editloading ? (
          <div
            style={{
              minHeight: "calc(100vh - 90px)",
              display: "flex",
              position: "absolute",
              top: "0px",
              left: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        ) : (
          <Modal
            open={modalOpen}
            board
            handleClose={() => handleUpdateCardCloseConfirm(false)}
          >
            {newRenderUpdateModal()}
          </Modal>
        )}
      </>
    );
  };

  // handleAddCardToTheTrelloBoard
  const handleCreateModalClose = (cancelbtn) => {
    // console.log({ cancelbtn });
    if (cancelbtn === false) {
      const newclickbtn = window.document.getElementById("submit-button");
      newclickbtn?.click();
    }
    setModalOpenForAddCard(false);
    setOpenTextFieldToAcceptance(true);
    setOpenAcceptanceCriteria(false);
    setSingleToUpdateAcceptanceCriteria({});
    setcreateAceptanceCriteria([]);
    setSelectedDate(null);
    setSingleAcceptanceCriteria("");
    setToEmails([]);
    setEpicId("");
    setAssignee("");
    resetForCreate();
  };

  //handleDeleteCard
  const handleDeleteCard = (erase) => {
    setOpenAlert(true);
    setAlertToDelete(erase);
  };

  const onUpdateSubmit = (data) => {

    console.log(feature)
    console.log(data)
    const updatedCardData = { ...cardData };
    const formUpdateData = new FormData();
    const getExist =
      organizationProfile &&
      organizationProfile.findIndex((value) => value.id === updateAssignee);

    const filterByReference = (arr1, arr2) => {
      let res = [];
      res = arr1.filter((el) => {
        return !arr2.find((element) => {
          return element.value === el.value;
        });
      });
      return res;
    };
    const assesmentData = assesments.length
      ? assesments
      : boarsdData?.initialBoardAssesment;
    const getUpdateMapedData = assesmentData.map((item) => {
      const getItem = { ...item };
      if (item.name === "reach") {
        getItem.value = data?.reach;
      }
      if (item.name === "impact") {
        getItem.value = data?.impact;
      }
      if (item.name === "confidence") {
        getItem.value = data?.confidence;
      }
      return getItem;
    });
    const result1 = filterByReference(updateBlockers, transientBlockers);
    const filteredIssueItems = result1.filter(
      (item) => item.type === "riskAndIssue"
    );
    if (filteredIssueItems && filteredIssueItems.length) {
      const idAddedIssue = filteredIssueItems.map((item) => ({
        ...item,
        id: `sqbear_risk_and_issue_${nanoid(15)}`,
      }));
      const getAllComments = idAddedIssue.map((item) =>
        blockers(user, updatedCardData, item)
      );
      idAddedIssue.forEach((item) =>
        createRiskSubmit(item, user, notDeletableItem)
      );
      const metadataWithBlockers = [...getAllComments, ...savedDescription];
      formUpdateData.append("metadata", JSON.stringify(metadataWithBlockers));
    } else {
      formUpdateData.append("metadata", JSON.stringify(savedDescription));
    }
    formUpdateData.append("id", updatedCardData?.id);
    formUpdateData.append("aiGenerate", currentGPT);
    formUpdateData.append("releaseRef", updatedCardData?.releaseRef);
    formUpdateData.append("attachmentList", updatedCardData?.attachmentList);
    formUpdateData.append(
      "doingDate",
      updatedCardData?.doingDate !== null ? updatedCardData?.doingDate : null
    );
    formUpdateData.append(
      "acceptedDate",
      updatedCardData?.acceptedDate !== null
        ? updatedCardData?.acceptedDate
        : null
    );
    let finalUpdatedBlockers;
    if (updateBlockers.length === 0 || updateBlockers.length <= 1) {
      finalUpdatedBlockers = [
        ...boarsdData.appendedBlockers,
        ...updateBlockers,
      ];
      for (let value of finalUpdatedBlockers) {
        formUpdateData.append("blockers", JSON.stringify(value));
      }
    } else {
      for (let value of updateBlockers) {
        formUpdateData.append("blockers", JSON.stringify(value));
      }
    }
    let finalUpdateRelated;
    if (updateReleate.length === 0 || updateReleate.length <= 1) {
      finalUpdateRelated = [...boarsdData.appendedBlockers, ...updateReleate];
      for (let value of finalUpdateRelated) {
        formUpdateData.append("related", JSON.stringify(value));
      }
    } else {
      for (let value of updateReleate) {
        formUpdateData.append("related", JSON.stringify(value));
      }
    }
    formUpdateData.append("epicId", updateEpicId ? updateEpicId : "");
    formUpdateData.append("issueCode", updatedCardData?.issueCode);
    formUpdateData.append(
      "dueDate",
      updateSelectedDate ? updateSelectedDate : updatedCardData?.dueDate
    );
    formUpdateData.append(
      "bugsLabel",
      data.bugsLabel ? data.bugsLabel : updatedCardData.bugsLabel
    );
    formUpdateData.append(
      "linkToDesign",
      data.linkToDesign ? data.linkToDesign : updatedCardData.linkToDesign
    );
    if (getUpdateMapedData) {
      for (const assesment of getUpdateMapedData) {
        formUpdateData.append("assessments", JSON.stringify(assesment));
      }
    }
    formUpdateData.append("personaId", updatPersonaId);
    formUpdateData.append(
      "choresLabel",
      data.choresLabel ? data.choresLabel : updatedCardData.choresLabel
    );
    formUpdateData.append("description", description);

    formUpdateData.append("draggable", true);
    formUpdateData.append(
      "label",
      data.label ? data.label : updatedCardData.label
    );
    formUpdateData.append(
      "laneId",
      updatedCardData?.laneId === "Ice Box"
        ? kanbanData &&
        kanbanData.find(
          (value) => value.title === "Ice Box" || value.label === "Ice Box"
        ).id
        : updatedCardData?.laneId === "To Do"
          ? kanbanData &&
          kanbanData.find(
            (value) => value.title === "To Do" || value.label === "To Do"
          ).id
          : updatedCardData?.laneId
    );
    formUpdateData.append(
      "title", feature
    );
    if (getExist !== -1) {
      formUpdateData.append(
        "userId",
        updateAssignee ? updateAssignee : updatedCardData?.userId
      );
    } else {
      formUpdateData.append("userId", "");
    }
    formUpdateData.append("type", updatedCardData?.type);
    if (updatedCardData?.riskCardId) {
      formUpdateData.append("riskCardId", updatedCardData?.riskCardId);
    }
    if (updateSizeOfProblem === 0) {
      formUpdateData.append("size", 0);
    }
    if (updateSizeOfProblem === 1) {
      formUpdateData.append("size", 1);
    }
    if (updateSizeOfProblem === 2) {
      formUpdateData.append("size", 2);
    }
    if (updateSizeOfProblem === 3) {
      formUpdateData.append("size", 3);
    }
    for (let pic of updateFilesToSave) {
      formUpdateData.append("attachments", pic);
    }
    formUpdateData.append("createDate", updatedCardData?.createDate);
    // for release cardd
    formUpdateData.append(
      "releaseName",
      updateReleaseName ? updateReleaseName : updatedCardData?.releaseName
    );
    formUpdateData.append(
      "goal",
      updateReleaseGoal ? updateReleaseGoal : updatedCardData?.goal
    );
    // for estimate hour
    formUpdateData.append(
      "estimateHour",
      estimateHour ? estimateHour : updatedCardData?.estimateHour
    );
    formUpdateData.append(
      "draggable",
      updatedCardData.type === "release" ? false : true
    );
    formUpdateData.append("indexNumber", updatedCardData?.indexNumber);
    const newShallowArray = [...aceptanceCriteria];
    newShallowArray.map((item) => {
      if (item.id.length === 9) {
        delete item.id;
        return item;
      }
      return item;
    });
    dispatch(
      cardUpdateByFromKanban(
        user?.projectId,
        updatedCardData?.id,
        formUpdateData,
        aceptanceCriteria,
        user?.kanbanId
      )
    );
    if (updateAssignee) {
      const message = `You have assigned a card, please check the assigned card`;
      if (getExist !== -1 && updatedCardData.userId !== updateAssignee) {
        dispatch(
          notificationToTheNewUser(
            updatedCardData?.id,
            domLocation,
            updateAssignee,
            message
          )
        );
      }
    }
    handleUpdateCardCloseConfirm(true);
  };

  const onCreateSubmit = (data) => {
    const allTypeData = allTypeFormData(
      selectedDate,
      data,
      toEmails,
      related,
      cardType,
      epicId,
      assignee,
      user,
      sizeOfProblem,
      filesToSave,
      kanbanData,
      notDeletableItem
    );
    if (data?.tasks?.length > 0) {
      const firstObj = data?.tasks[0];
      const checkBugsLabel = Object.keys(firstObj).includes("bugsLabel");
      const checkChoresLabel = Object.keys(firstObj).includes("choresLabel");
      if (checkBugsLabel) {
        const nowStage = data.tasks.filter((item) => item.bugsLabel !== "");
        nowStage.forEach((item) => {
          const formDataTask = taskFormData(item, kanbanData, user);
          dispatch(
            addNewCardToKanban(
              user?.projectId,
              formDataTask,
              JSON.stringify(data?.updateCreateAceptanceCriteria),
              item.id,
              user?.kanbanId
            )
          );
        });
      }
      if (checkChoresLabel) {
        const nowStage = data.tasks.filter((item) => item.choresLabel !== "");
        nowStage.forEach((item) => {
          const formDataTask = taskFormData(item, kanbanData, user);
          dispatch(
            addNewCardToKanban(
              user?.projectId,
              formDataTask,
              JSON.stringify(data?.updateCreateAceptanceCriteria),
              item.id,
              user?.kanbanId
            )
          );
        });
      }
      handleCreateModalClose();
    } else {
      dispatch(
        addNewCardToKanban(
          user?.projectId,
          allTypeData,
          JSON.stringify(data?.updateCreateAceptanceCriteria),
          data?.cardId,
          user?.kanbanId
        )
      );
      handleCreateModalClose();
    }
    if (assignee) {
      const anotherurl = `${domLocation}?card=${data.cardId}`;
      // const redirectUrl = domLocation;
      const message = `You have assigned a card, please check the assigned card`;
      dispatch(
        notificationToTheNewUser(data?.cardId, anotherurl, assignee, message)
      );
    }
  };

  //description, id, projectId, cardId, update
  const handleCheckAcceptanceCriteria = () => {
    if (
      singleAcceptanceCriteria !== null &&
      singleAcceptanceCriteria !== "" &&
      singleAcceptanceCriteria !== boarsdData.initialState
    ) {
      const writingDescription = {
        description: singleAcceptanceCriteria,
        id: nanoid(9),
        projectId: user?.projectId,
        cardId: cardData?.id,
      };
      setAceptanceCriteria([...aceptanceCriteria, writingDescription]);
      setOpenForUpdate(false);
      setSingleAcceptanceCriteria(boarsdData.initialState);
    }
  };

  const handleUpdateSingleAcceptanceForCard = useCallback(() => {
    if (
      updateSingleAcceptanceCriteria !== null &&
      updateSingleAcceptanceCriteria !== ""
    ) {
      const itemIndex = aceptanceCriteria.findIndex(
        (item) => item.id === singleAcceptanceToUpdateCard.id
      );
      if (itemIndex !== -1) {
        aceptanceCriteria[itemIndex].description =
          updateSingleAcceptanceCriteria;
      }
      setOpenAcceptanceForUpdate(false);
      setUpdateSingleAcceptanceCriteria("");
      setSingleAcceptanceToUpdateCard({});
    } else {
      setOpenAcceptanceForUpdate(false);
      setUpdateSingleAcceptanceCriteria("");
      setSingleAcceptanceToUpdateCard({});
    }
  }, [updateSingleAcceptanceCriteria]);

  //create----update
  const handleCreateUpdateAcceptanceCriteria = () => {
    if (
      updateSingleAcceptanceCriteria !== null &&
      updateSingleAcceptanceCriteria !== ""
    ) {
      const itemIndex = createAceptanceCriteria.findIndex(
        (item) => item.id === singleToUpdateAcceptanceCriteria.id
      );
      if (itemIndex !== -1) {
        createAceptanceCriteria[itemIndex].description =
          updateSingleAcceptanceCriteria;
      }
      setOpenAcceptanceCriteria(false);
      setOpenTextFieldToAcceptance(false);
      setSingleAcceptanceCriteria("");
      setUpdateSingleAcceptanceCriteria("");
      setSingleToUpdateAcceptanceCriteria({});
    } else {
      setOpenAcceptanceCriteria(false);
      setOpenTextFieldToAcceptance(false);
      setSingleAcceptanceCriteria("");
      setUpdateSingleAcceptanceCriteria("");
      setSingleToUpdateAcceptanceCriteria({});
    }
  };

  //raw wyswying Editor
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  //roe
  const saveComment = (newdata) => {
    const rawContent = newdata;
    SetSavedDescription((prev) => [
      writingComment(user, data, rawContent),
      ...prev,
    ]);
    setEditorState(EditorState.createEmpty());
    setOpenToComment(false);
  };
  const removeComment = () => {
    setEditorState(EditorState.createEmpty());
    setOpenToComment(false);
  };
  const updateNotesData = (updatedata) => {
    const updatedrawContent = convertToRaw(updatedata.getCurrentContent());
    const itemIndex = savedDescription.findIndex(
      (item) => item.id === updateToNotes.id
    );
    if (itemIndex !== -1) {
      savedDescription[itemIndex].comment = updatedrawContent;
      savedDescription[itemIndex].cretedDate = new Date();
    }
    setUpdatenotes(false);
  };
  const cancelupdateNotesData = () => {
    setUpdatenotes(false);
  };

  // for time sheet handling
  const handleTimeSheet = () => {
    setTimeSheet(!timesheet);
  };

  // close data send handle
  const handleTimeSheetClose = () => {
    if (dateinclude.length > 0) {
      const newData = dateinclude.map((item) => {
        return {
          cardId: cardData?.id,
          date: item.date,
          hours: item.hours,
          laneId: cardData?.laneId,
          projectId: cardData?.projectId,
          userId: cardData?.userId,
          id: item.id,
        };
      });

      dispatch(storeLogHour(cardData?.projectId, newData));
      setDateInclude([]);
    }
  };

  // fetch loghour data
  useEffect(() => {
    cardData &&
      cardData?.projectId &&
      dispatch(fetchLogbyCard(cardData?.projectId, cardData?.id));
  }, [cardData]);

  // handle change date
  const handleChangeDate = (date) => {
    setLoading(true);
    setTimeout(() => {
      setAnotherSelectedDate(date);
      setLoading(false);
    }, 100);
  };

  // dispatch logenable

  const project = useSelector((state) => state?.auth?.user?.projectId);

  useEffect(() => {
    dispatch(fetchEnableLoghour(project));
  }, [project, fetchEnableLoghour]);

  // get enable data

  const newRenderUpdateModal = () => {
    const allget =
      loghours &&
      loghours.map((item) =>
        cardData?.id === item.cardId ? item.hours : null
      );
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const sum = allget && allget.length && allget.reduce(reducer);

    return (
      <Fragment>
        <UpdateBoardCard
          feature={feature}
          setFeature={setFeature}
          handleUpdateSubmit={handleUpdateSubmit}
          onUpdateSubmit={onUpdateSubmit}
          currentGPT={currentGPT}
          setCurrentGPT={setCurrentGPT}
          assesments={assesments}
          setAssesments={setAssesments}
          singleAcceptanceCriteria={singleAcceptanceCriteria}
          toEpics={toEpics}
          classes={classes}
          getEpicName={getEpicName}
          updateEpicId={updateEpicId}
          setUpdateEpicId={setUpdateEpicId}
          updatPersonaId={updatPersonaId}
          setUpdatPersonaId={setUpdatPersonaId}
          getEpicColor={getEpicColor}
          toUsers={toUsers}
          setUpdateAssignee={setUpdateAssignee}
          updateAssignee={updateAssignee}
          getAvatar={getAvatar}
          getName={getName}
          updateSelectedDate={updateSelectedDate}
          setUpdateSelectedDate={setUpdateSelectedDate}
          logEnable={logEnable}
          cardData={cardData}
          handleUpdateTypeOfSize={handleUpdateTypeOfSize}
          updateSizeOfProblem={updateSizeOfProblem}
          particularCard={particularCard}
          persona={persona}
          user={user}
          handleTimeSheet={handleTimeSheet}
          setAnchorElTimeSheet={setAnchorElTimeSheet}
          anchorElTimeSheet={anchorElTimeSheet}
          setEstimateHour={setEstimateHour}
          estimateHour={estimateHour}
          PopOverForTimeSheet={PopOverForTimeSheet}
          setOpenToChangeTheUserStories={setOpenToChangeTheUserStories}
          sum={sum}
          openToChangeTheUserStories={openToChangeTheUserStories}
          updateTitle={updateTitle}
          updateLabel={updateLabel}
          control={control}
          errors={errors}
          setOpenForUpdate={setOpenForUpdate}
          openForUpdate={openForUpdate}
          setSingleAcceptanceCriteria={setSingleAcceptanceCriteria}
          handleCheckAcceptanceCriteria={handleCheckAcceptanceCriteria}
          openAcceptanceForUpdate={openAcceptanceForUpdate}
          updateSingleAcceptanceCriteria={updateSingleAcceptanceCriteria}
          setUpdateSingleAcceptanceCriteria={setUpdateSingleAcceptanceCriteria}
          handleUpdateSingleAcceptanceForCard={
            handleUpdateSingleAcceptanceForCard
          }
          setOpenAcceptanceForUpdate={setOpenAcceptanceForUpdate}
          setSingleAcceptanceToUpdateCard={setSingleAcceptanceToUpdateCard}
          aceptanceCriteria={aceptanceCriteria}
          singleAcceptanceToUpdateCard={singleAcceptanceToUpdateCard}
          handleUpdateCardClose={handleUpdateCardCloseConfirm}
          // for update notes data
          updatenotes={updatenotes}
          updateNotesData={updateNotesData}
          cancelupdateNotesData={cancelupdateNotesData}
          updateToNotes={updateToNotes}
          updateChoresLabel={updateChoresLabel}
          opendescription={opendescription}
          setOpenDescription={setOpenDescription}
          setOpenToChangeTheChoresLabel={setOpenToChangeTheChoresLabel}
          openToChangeTheChoresLabel={openToChangeTheChoresLabel}
          savedDescription={savedDescription}
          SetSavedDescription={SetSavedDescription}
          setUpdatenotes={setUpdatenotes}
          setUpdateToNotes={setUpdateToNotes}
          setOpenToComment={setOpenToComment}
          removeComment={removeComment}
          saveComment={saveComment}
          setUpdateFilesToSave={setUpdateFilesToSave}
          setOpenAddAttachment={setOpenAddAttachment}
          openAddAttachment={openAddAttachment}
          updateFilesToSave={updateFilesToSave}
          suggestions={suggestions}
          toBlockers={toBlockers}
          setOpenBlockersSection={setOpenBlockersSection}
          openBlockersSection={openBlockersSection}
          updateBlockers={updateBlockers}
          boarsdData={boarsdData}
          setUpdateBlockers={setUpdateBlockers}
          setDescription={setDescription}
          description={description}
          openToComment={openToComment}
          onEditLinkToDesign={onEditLinkToDesign}
          setOnEditLinkToDesign={setOnEditLinkToDesign}
          setOpenToChangeTheBugsLabel={setOpenToChangeTheBugsLabel}
          setOpenReleaseNameUpdate={setOpenReleaseNameUpdate}
          setOpenChangeToGoal={setOpenChangeToGoal}
          openChangeToGoal={openChangeToGoal}
          updateBugsLabel={updateBugsLabel}
          updateReleaseName={updateReleaseName}
          updateReleaseGoal={updateReleaseGoal}
          openToChangeTheBugsLabel={openToChangeTheBugsLabel}
          openChangeToReleaseName={openChangeToReleaseName}
          setAceptanceCriteria={setAceptanceCriteria}
          deleteSingleAcceptanceCriteria={deleteSingleAcceptanceCriteria}
          handleUpdateSingleForUpdateCard={handleUpdateSingleForUpdateCard}
          getSingleAcceptanceCriteria={getSingleAcceptanceCriteria}
          updateReleate={updateReleate}
          setAllUpdateRelated={setAllUpdateRelated}
          clearErrors={clearErrors}
          setValue={setUpdateValue}
        />
      </Fragment>
    );
  };

  const handleChangeTypeOfSize = useCallback(() => {
    setSizeOfProblem((prev) => {
      if (sizeOfProblem >= 3) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }, [sizeOfProblem]);
  const handleUpdateTypeOfSize = useCallback(() => {
    setUpdateSizeOfProblem((prev) => {
      if (updateSizeOfProblem >= 3) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }, [updateSizeOfProblem]);

  // will work in next release
  // const handleCardType = useCallback(() => {
  //   setCardType((prev) => {
  //     if (cardType >= 2) {
  //       return 0;
  //     } else {
  //       console.log({ prev });
  //       // AZIM BHAI WORK
  //       if (prev == 0 && cardRenderer.feature == true) {
  //         return prev;
  //       } else if (prev == 1 && cardRenderer.task == true) {
  //         return prev;
  //       } else if (prev == 2 && cardRenderer.bug == true) {
  //         return prev;
  //       }
  //       return prev + 1;
  //     }
  //   });
  // }, [cardType]);
  const handleCardType = useCallback(() => {
    setCardType((prev) => {
      if (cardType >= 2) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }, [cardType]);

  const classes = useStyles(filesToSave);
  const toUsers = organizationProfile?.map((user) => ({
    label: user.name,
    value: user.id,
  }));
  const suggestions = organizationProfile?.map((user) => ({
    text: user.name,
    url: user.id,
    value: user.name,
  }));
  const particularCard = kanbanData.map((item) => item.cards).flat(2);
  const particularCards = kanbanData;
  //css-2613qy-menu
  const toBlockers = particularCard?.map((block) => ({
    label: block?.title
      ? block?.title + " " + block.label
      : block?.bugsLabel
        ? block?.bugsLabel
        : block?.choresLabel,
    value: block.id,
  }));
  const toEpics = valueStream?.map((item) => ({
    label: item.valueStreamName,
    color: item.color,
    value: item.id,
  }));
  //  #TODO
  const handleUpdateSingleForUpdateCard = (singleAccept) => {
    setOpenAcceptanceForUpdate(true);
    setOpenForUpdate(false);
    // setUpdateSingleAcceptanceCriteria(singleAccept.description);
    setUpdateSingleAcceptanceCriteria(
      getSingleAcceptanceCriteria(singleAccept.description)
    );
    setSingleAcceptanceToUpdateCard(singleAccept);
  };

  //helpers--- getAvatar
  const getAvatar = (value) => {
    const newAvatar = organizationProfile.find(
      (item) => item.id === value && item
    );
    let newData;
    Object.keys(Avatars).find((key, index) => {
      if (key === newAvatar?.avatar) {
        newData = `${Avatars[newAvatar?.avatar]}`;
        return newData;
      }
      return newData;
    });
    return newData;
  };
  //helpers--- getName
  const getName = (value) => {
    const showingName = organizationProfile.find(
      (item) => item.id === value && item
    );
    return showingName?.name;
  };
  //helpers---
  const getEpicName = (value) => {
    const showingName = valueStream.find((item) => item.id === value && item);
    return showingName?.valueStreamName;
  };

  //helpers--- getEpicColor
  const getEpicColor = (value) => {
    const showingName = valueStream.find((item) => item.id === value && item);
    return showingName?.color;
  };
  const CreatedCard = (props) => {
    return (
      <NewCustomCard
        handleDeleteCard={handleDeleteCard}
        handleEditCard={handleEditCard}
        getAvatar={getAvatar}
        getName={getName}
        setCardId={setCardId}
        partData={props}
        persona={persona}
        logenable={logEnable}
      />
    );
  };
  const CreatedHeader = (props) => {
    return (
      <CustomLaneHeader
        kanbanId={user?.kanbanId}
        props={props}
        kanbanData={kanbanData}
      />
    );
  };

  const NewButtonArise = (props) => {
    // console.log(props)
    const kanbanFind = kanbanData.find((value) => value.id === props.laneId);
    // console.log({kanbanFind})
    return kanbanFind?.title === "Ice Box" ? (
      <AddButton
        onClick={() => {
          if (cardType === 1 || cardType === 2) {
            setCardType(cardType);
          } else {
            setCardType(0);
          }
          setModalOpenForAddCard(true);
        }}
      />
    ) : (
      <ReleaseButton
        onClick={() => {
          setCardType(3);
          setModalOpenForAddCard(true);
        }}
      />
    );
  };

  const components = {
    AddCardLink: NewButtonArise,
    LaneHeader: CreatedHeader,
    Card: CreatedCard,
  };

  // console.log(allinitials[0] && JSON.parse(allinitials[0]?.messageContent))

  const generateCSV = () => {
    let parsedCanvasData = [];

    allinitials.map((item) => {
      const alldata = JSON.parse(item?.messageContent);
      console.log(alldata)
      alldata &&
        alldata.length &&
        alldata.map((item2) => {
          parsedCanvasData.push(item2);
        });
    });
    if (parsedCanvasData?.length > 0) {
      const newValueStream = valueStream.map((item) => {
        return parsedCanvasData.filter(
          (item1) => item1.data.valueStreamId === item.id && item1
        );
      });
      const newValueStreamFilter = valueStream.map((item, index) => {
        const newObj = {
          valueStreamId: item.id,
          elementsDtoList: newValueStream[index],
          valueStreamName: item.valueStreamName,
          valueStreamWhy: item.valueStreamWhy,
          persona: persona?.name,
        };
        return newObj;
      });
      dispatch(
        sendValueStreamAndDiagramForCSV(
          user?.projectId,
          newValueStreamFilter,
          user?.name + nanoid(2)
        )
      );
      dispatch(nullifiedCSV());
    }
  };
  // DD-MM-YYYY
  return (
    <Layout
      pageName={"Work Board"}
      type={"board"}
      generateCSV={generateCSV}
      handleChangeBackground={handleChangeBackground}
      board
    >
      {openAlert && (
        <CustomSnacbar
          opened={openAlert}
          alertToDelete={alertToDelete}
          handleDeleteFromBackend={handleDeleteFromBackend}
          setOpenAlert={setOpenAlert}
          type="card"
        />
      )}

      <Box className="BoardClass">
        {kanbanData && (
          <div style={{ position: "relative" }}>
            {/* Board component */}
            <Board
              editable
              style={{
                ...globalStyle.style,
                backgroundImage: `linear-gradient(
          -220deg,
          rgba(0, 36, 51, 1) 0%,
          rgba(98, 218, 255, 0.164) 100%
          ),url(${background?.backgroundImage
                    ? background?.backgroundImage
                    : images.updatedHomeLogo
                  })`,
                padding: "10px",
                backgroundPosition: "center",
                backgroundSize: background?.backgroundImage ? "cover" : "auto",
                backgroundRepeat: "no-repeat",
              }}
              laneStyle={globalStyle.laneStyle}
              collapsibleLanes={true}
              hideCardDeleteIcon={true}
              cardDraggable={!loading ? true : false}
              draggable={!loading ? true : false}
              data={data}
              handleLaneDragEnd={(removedIndex, addedIndex, payload) => {
                handleLaneDragEnd(removedIndex, addedIndex, payload);
              }}
              components={components}
              handleDragEnd={(
                cardId,
                sourceLaneId,
                targetLaneId,
                position,
                cardDetails
              ) =>
                handleCardToAnotherLane(
                  cardId,
                  sourceLaneId,
                  targetLaneId,
                  position,
                  cardDetails
                )
              }
            />
            {/* Overlay while loading */}
            {loading &&
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.7)", // Adjust opacity as needed
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </div>}
          </div>
        )}

        {/* {kanbanData && !loading ? (
          <Board
            editable
            style={{
              ...globalStyle.style,
              backgroundImage: `linear-gradient(
              -220deg,
              rgba(0, 36, 51, 1) 0%,
              rgba(98, 218, 255, 0.164) 100%
              ),url(${
                background?.backgroundImage
                  ? background?.backgroundImage
                  : images.updatedHomeLogo
              })`,
              padding: "10px",
              backgroundPosition: "center",
              backgroundSize: background?.backgroundImage ? "cover" : "auto",
              backgroundRepeat: "no-repeat",
            }}
            laneStyle={globalStyle.laneStyle}
            collapsibleLanes={true}
            hideCardDeleteIcon={true}
            cardDraggable={!loading ? true : false}
            draggable={!loading ? true : false}
            data={data}
            handleLaneDragEnd={(removedIndex, addedIndex, payload) => {
              handleLaneDragEnd(removedIndex, addedIndex, payload);
            }}
            components={components}
            handleDragEnd={(
              cardId,
              sourceLaneId,
              targetLaneId,
              position,
              cardDetails
            ) =>
              handleCardToAnotherLane(
                cardId,
                sourceLaneId,
                targetLaneId,
                position,
                cardDetails
              )
            }
          />
        ) : (
          <Loader />
        )} */}
        {modalOpen && handleCardSection()}
        {modalOpenForAddCard && (
          <AddNewForBoard
            user={user}
            watch={watch}
            getValues={getValues}
            modalOpenForAddCard={modalOpenForAddCard}
            handleCreateSubmit={handleCreateSubmit}
            onCreateSubmit={onCreateSubmit}
            setValue={setValue}
            classes={classes}
            persona={persona}
            handleChangeTypeOfSize={handleChangeTypeOfSize}
            sizeOfProblem={sizeOfProblem}
            controlForCreate={controlForCreate}
            createError={createError}
            handleCreateModalClose={handleCreateModalClose}
            handleCardType={handleCardType}
            setFilesToSave={setFilesToSave}
            logEnable={logEnable}
            renderStyle={renderStyle}
            cardType={cardType}
            setOpenTextFieldToAcceptance={setOpenTextFieldToAcceptance}
            openTextFieldToAcceptance={openTextFieldToAcceptance}
            singleAcceptanceCriteria={singleAcceptanceCriteria}
            setSingleAcceptanceCriteria={setSingleAcceptanceCriteria}
            openAcceptanceCriteria={openAcceptanceCriteria}
            singleToUpdateAcceptanceCriteria={singleToUpdateAcceptanceCriteria}
            setUpdateSingleAcceptanceCriteria={
              setUpdateSingleAcceptanceCriteria
            }
            handleCreateUpdateAcceptanceCriteria={
              handleCreateUpdateAcceptanceCriteria
            }
            setOpenAcceptanceCriteria={setOpenAcceptanceCriteria}
            setSingleToUpdateAcceptanceCriteria={
              setSingleToUpdateAcceptanceCriteria
            }
            createAceptanceCriteria={createAceptanceCriteria}
            getSingleAcceptanceCriteria={getSingleAcceptanceCriteria}
            setcreateAceptanceCriteria={setcreateAceptanceCriteria}
            toUsers={toUsers}
            setAssignee={setAssignee}
            getAvatar={getAvatar}
            getName={getName}
            toEpics={toEpics}
            setEpicId={setEpicId}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setToEmails={setToEmails}
            toBlockers={toBlockers}
            related={related}
            setAllRelated={setAllRelated}
            cardRenderer={cardRenderer}
          />
        )}
      </Box>
    </Layout>
  );
};

export default Boards;
