import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { lazy, Suspense } from "react";
import {
  Login,
  Signup,
  ForgotPassword,
  Activation,
  Pricing,
  UpdatePricing,
  Personas,
  RiskIssues,
} from "Pages";
import {
  SplashScreen,
  NotFound,
  CustomBoard,
  ResetPassword,
  AddUsers,
  ProjectUsers,
  ProblemStatement,
  JourneyMap,
  Callback
} from "Pages";
import PrivateRoute from "./PrivateRoute";
import { useSelector } from "react-redux";
import Spinner from "Components/Reusable/Spinner";
import { useEffect } from "react";
const Profile = lazy(() => import("../Pages/Profile"));
const Board = lazy(() => import("../Pages/Board"));
const Home = lazy(() => import("../Pages/Home"));
const WorkFlow = lazy(() => import("../Pages/Workflow"));
const FinalDiagram = lazy(() => import("../Pages/FinalDiagram"));

const AppRouter = () => {
  const user = JSON.parse(window.localStorage.getItem("squser"));
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/board" ||
      location.pathname === "/unhookboard" ||
      location.pathname === "/riskandissues" ||
      location.pathname === "/journeyMap"
    ) {
      localStorage.setItem("afterlogin", location.pathname + location.search);
    }
  }, [location]);

  return (
    <Switch>
      <Route  path="/login">
        <Login />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      <Route exact path="/callback">
        <Callback />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route exact path="/reset-password/:secretKey?">
        <ForgotPassword />
      </Route>
      <Route exact path="/user-activation/:secretKey?">
        <ResetPassword />
      </Route>
      <Route exact path="/activation-link/:secretKey?">
        <Activation />
      </Route>
      <PrivateRoute path="/pricing" exact component={Pricing} />
      {user && user?.noofusernow > 0 && user?.stripeSubscriptionId === null ? (
        <Redirect from={location.pathname} exact to={`/pricing`} />
      ) : null}
      <PrivateRoute path="/project" exact component={SplashScreen} />
      {user && user?.projectId === "" && auth?.authenticate ? (
        <Redirect from={location.pathname} exact to={`/project`} />
      ) : (
        <>
          <Suspense fallback={<Spinner />}>
            <PrivateRoute path="/settings" exact component={Profile} />
          </Suspense>
          <Suspense fallback={<Spinner />}>
            <PrivateRoute path="/" exact component={Home} />
          </Suspense>
          <Suspense fallback={<Spinner />}>
            <PrivateRoute path="/board/:modalId?" exact component={Board} />
          </Suspense>
          <Suspense fallback={<Spinner />}>
            <PrivateRoute
              path="/workflow/:workflowId"
              exact
              component={WorkFlow}
            />
          </Suspense>
          <Suspense fallback={<Spinner />}>
            <PrivateRoute
              path="/finalDiagram/:streamId?"
              component={FinalDiagram}
            />
          </Suspense>

          <PrivateRoute path="/riskandissues" exact component={RiskIssues} />
          <PrivateRoute
            path="/journeyMap/:personasId"
            exact
            component={JourneyMap}
          />
          <PrivateRoute path="/unhookboard/:boardId?" component={CustomBoard} />
          <PrivateRoute
            path="/projectuser/:projectid"
            exact
            component={ProjectUsers}
          />
          <PrivateRoute
            path="/update-pricing"
            exact
            component={UpdatePricing}
          />
          <PrivateRoute path="/addUsers" exact component={AddUsers} />
          <PrivateRoute
            path="/problemstatement/:personasid"
            exact
            component={ProblemStatement}
          />
          <PrivateRoute path="/personas" exact component={Personas} />
          <PrivateRoute path="/personas/:id" exact component={Personas} />
        </>
      )}
      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

export default AppRouter;
