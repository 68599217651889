import { profileConfig } from "../actions/constant";

const initialState = {
  loading: false,
  configuration: [],
  singleCog: {},
  error: "",
  homeRenderer: {},
};

const profileConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case profileConfig.GET_ALL_PROFILE_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        configuration: [],
        error: "",
      };
    case profileConfig.GET_ALL_PROFILE_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        configuration: action.payload,
        error: "",
      };
    case profileConfig.GET_ALL_PROFILE_CONFIG_ERROR:
      return {
        ...state,
        loading: false,
        configuration: [],
        error: action.payload,
      };

    case profileConfig.GET_SINGLE_PROFILE_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        singleCog: {},
        error: "",
      };
    case profileConfig.GET_SINGLE_PROFILE_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        singleCog: action.payload,
        error: "",
      };
    case profileConfig.GET_SINGLE_PROFILE_CONFIG_FAILED:
      return {
        ...state,
        loading: false,
        singleCog: {},
        error: action.payload,
      };
    case profileConfig.SAVE_CONFIG:
      return {
        ...state,
        loading: false,
      };
    case profileConfig.UPDATE_CONFIG:
      return {
        ...state,
        loading: false,
      };
    case profileConfig.GET_CHECKED_SUCCESS:
      return {
        ...state,
      };
    case profileConfig.GET_ALL_HOME_REQUEST:
      return {
        ...state,
        homeRenderer: "",
        loading: true,
      };
    case profileConfig.GET_ALL_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        homeRenderer: action.payload,
      };
    default:
      return state;
  }
};

export default profileConfigReducer;
