import { Box, Tooltip } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";

/**
 *@function Bipolar.jsx
 *@author Azim
 *
 **/

const Bipolar = (props) => {
  const useStyles = makeStyles(() => ({
    root: {
      width: "100%",
      maxWidth: "282px",
      marginLeft: "10px",
    },
    breadCumStyle: {
      marginTop: "10px",
      fontSize: "20px",
      fontWeight: "600",
      width: "350px",
    },
    lowersec: {
      width: "370px",
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Tooltip
        title={props?.pageName === "Home" ? "Workspace" : props?.pageName}
        placement="right"
        arrow
      >
        <Typography
          className={classes.breadCumStyle}
          style={{ whiteSpace: "nowrap" }}
        >
          {props?.pageName === "Home"
            ? "Workspace"
            : props?.pageName?.length > 35
            ? props?.pageName.slice(0, 35) + "..."
            : props?.pageName}
        </Typography>
      </Tooltip>

      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className={classes.lowersec}
      >
        <Link
          // onClick={() => props?.onDiagram && props?.generateKanban()}
          style={{
            textDecoration: "none",
            color: "black",
            textTransform: "capitalize",
            display: "block",
          }}
          to="/"
        >
          {props?.projectName?.length > 8
            ? props?.projectName.slice(0, 8) + "..."
            : props?.projectName}
        </Link>
        {props.pageName === "Project Users" ? (
          <Link
            style={{
              textDecoration: "none",
              color: "black",
              textTransform: "capitalize",
            }}
            to="/settings"
          >
            Profile
          </Link>
        ) : null}
        <Link
          style={{ textDecoration: "none", color: "blue" }}
          to={
            props.returnPersonasId
              ? `/workflow/${props.returnPersonasId}`
              : props.custom
              ? `unhookboard${props?.location}`
              : `/#`
          }
        >
          {props?.pageName === "Home"
            ? "Workspace"
            : props?.pageName?.length > 35
            ? props?.pageName.slice(0, 35) + "..."
            : props?.pageName}
        </Link>
        {props.thirdName && (
          <Tooltip
            title={props?.thirdName?.length > 15 ? props?.thirdName : ""}
            placement="top"
            arrow
          >
            <Link
              style={{ textDecoration: "none", color: "blue" }}
              to={window.location}
            >
              {props?.thirdName === "Home"
                ? "Workspace"
                : props?.thirdName?.length > 15
                ? props?.thirdName.slice(0, 15) + "..."
                : props?.thirdName}
            </Link>
          </Tooltip>
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default Bipolar;
