import { images } from "assets";
import { SVGRender } from "Components/Reusable";
import { IconButton, Tooltip } from "@material-ui/core";
import { globalStyle } from "assets/Styles/GlobalStyle";
const ChangeSize = ({ updateSizeOfProblem, handleUpdateTypeOfSize }) => {
  return (
    <Tooltip title="Change size" arrow placement="top">
      <IconButton onClick={handleUpdateTypeOfSize}>
        {updateSizeOfProblem === 0 && (
          <SVGRender
            style={globalStyle.renderStyle}
            img={images.tShirt}
            alt={"small Icon"}
          />
        )}
        {updateSizeOfProblem === 1 && (
          <SVGRender
            style={globalStyle.renderStyle}
            img={images.smallIconForSize}
            alt={"small Icon"}
          />
        )}
        {updateSizeOfProblem === 2 && (
          <SVGRender
            style={globalStyle.renderStyle}
            img={images.mediumIconForSize}
            alt={"medium Icon"}
          />
        )}
        {updateSizeOfProblem === 3 && (
          <SVGRender
            style={globalStyle.renderStyle}
            img={images.largeIconForSize}
            alt={"large Icon"}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ChangeSize;
