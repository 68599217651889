import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import columns from 'assets/Data/DataGrid'
import {
    addNodeDataToTheTable,
    deleteNodeDataToTheTable,
    updateNodeDataToTheTable,
} from 'redux/actions';
import { makeStyles } from '@material-ui/core';
import { nanoid } from 'nanoid';
import AddIconForGrid from './AddIconForGrid';

export default function DataGridOfProcessNode({ rows }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    //add method to create the work items
    const renderOpenClickWayListener = React.useCallback(() => {
        let id = nanoid(5);
        const data = {
            id,
            source: "",
            input: "",
            destination: "",
            name: "",
            purpose: "",
            output: "",
        };
        dispatch(addNodeDataToTheTable(data));
    }, [dispatch]);

    //delete method to delete the work items
    const handleDeleteItem = (id) => {
        dispatch(deleteNodeDataToTheTable(id))
    }
    //edit method to edit the work items
    const handleEditItem = (id, value, name) => {
        const data = {
            id, name, value
        }
        dispatch(updateNodeDataToTheTable(data))
    }
    const newSetRow = [...new Set(rows)];
    const addIconParse = { classes, renderOpenClickWayListener, title: "add a new workflow" }
    return (
        <div className={classes.rootOfProcessNode}>
            <DataGrid
                autoHeight={true}
                rows={newSetRow}
                columns={columns}
                isCellEditable={() => true}
                onCellEditCommit={(row) => {
                    if (row.id && row.field) {
                        handleEditItem(row.id, row.field, row.value)
                    }
                }}
                disableColumnMenu
                pageSize={5}

                onCellClick={(row) => {
                    if (row.id && row.field === 'Delete') {
                        handleDeleteItem(row.id)
                    }
                    if (row.id && row.field === 'Add') {
                        renderOpenClickWayListener()
                    }
                }
                }
            />
            {
                rows.length === 0 &&
                <AddIconForGrid {...addIconParse} />
            }
        </div>
    );
}
const useStyles = makeStyles({
    rootOfProcessNode: {
        width: 1000,
        '& .MuiDataGrid-cell': {
            border: '1px solid #d4cfcf',
            borderRadius: '8px'
        },
    },
    buttonInDataGrid: {
        position: "relative"
    },
    addButton: {
        position: "absolute",
        color: "red",
        zIndex: 93,
        bottom: 5,
        left: 5

    }
})