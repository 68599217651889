import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 *@function Terms.jsx
 *@author Azim
 *
 **/

const Terms = (props) => {
  const classes = useStyles();
  const renderTermsandConditions = () => {
    return (
      <Box>
        <Typography className={classes.boldStyle} align="center">
          SquareBear Software End-User License Agreement
        </Typography>
        <Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              IMPORTANT:
              <span className={classes.spanText}>
                THIS SOFTWARE END USER LICENSE AGREEMENT ("EULA") IS A LEGAL
                AGREEMENT BETWEEN YOU AND COMPANY ("SQUAREBEAR TECHNOLOGY PTY
                LTD"). USE OF SQUAREBEAR SOFTWARE PROVIDED WITH THIS EULA (THE
                "SOFTWARE") CONSTITUTES YOUR ACCEPTANCE OF THESE TERMS. READ IT
                CAREFULLY BEFORE USING SQUAREBEAR SOFTWARE. IF YOU DO NOT AGREE
                TO THE TERMS OF THIS EULA, DO NOT USE THIS SOFTWARE. BY USING
                SQUAREBEAR SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS OF THIS
                EULA.
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              1.LICENSE GRANT.
              <span className={classes.spanText}>
                SquareBear Software is licensed on a per user basis. "User"
                means the company, entity or individual whose funds are used to
                pay the fee. "Use" means storing, loading, installing, executing
                or displaying SquareBear Software. You may not modify SquareBear
                Software or disable any licensing or control features of
                SquareBear Software except as an intended part of SquareBear
                Software programming features. This license is not transferable
                to any other system, or to another organisation or individual.
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              2.OWNERSHIP.
              <span className={classes.spanText}>
                SquareBear Software is owned and copyrighted by SquareBear
                Technology Pty Ltd. Your license confers no title or ownership
                in SquareBear Software and should not be construed as a sale of
                any right in SquareBear Software.
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              3.SOFTWARE COPYRIGHT.
              <span className={classes.spanText}>
                SquareBear Software and all rights, without limitation including
                proprietary rights therein, are owned by SquareBear Technology
                Pty Ltd or its suppliers and are protected by copyright laws and
                international copyright treaties, and other intellectual
                property laws and treaties. SquareBear Software is licensed, not
                sold. You acknowledge that no title to the intellectual property
                in SquareBear Software is transferred to you. You further
                acknowledge that title and full ownership rights to SquareBear
                Software will remain the exclusive property of SquareBear
                Technology Pty Ltd and you will not acquire any rights to
                SquareBear Software except as expressly set forth in this
                license. You agree that any copies of SquareBear Software will
                contain the same proprietary notices which appear on and in
                SquareBear Software.
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              4.CONTENT COPYRIGHT AND OWNERSHIP.
              <span className={classes.spanText}>
                You agree that the owners, employees and volunteers of the
                Company are not responsible for any loss or damages which may
                result from your unauthorised or illegal use of digital content.
                You warrant that you have the right to use the digital content
                that you transmit to SquareBear Software or cause SquareBear
                Software to process. You grant SquareBear Technology Pty Ltd a
                non-exclusive royalty-free world-wide perpetual irrevocable
                license in respect of all digital content you have provided to
                SquareBear Software and any output generated by SquareBear
                Software, for any purpose (including to provide SquareBear
                Software to you). For any purpose related to product development
                by SquareBear Technology Pty Ltd, the digital content you have
                provided to SquareBear Software and any output generated by
                SquareBear Software shall be de-identified. For any purpose
                related to marketing by SquareBear Technology Pty Ltd,
                SquareBear Technology Pty Ltd shall obtain your prior written
                consent to use where such consent may only be provided if
                SquareBear Technology Pty Ltd de-identifies or removes your
                confidential information to your satisfaction.
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              5.RESTRICTIONS ON USE.
              <span className={classes.spanText}>
                You agree that you will not attempt to or procure any third
                party to: <br />
                5.1 extract, disassemble, decompile, reverse engineer,
                translate, creative derivative works or otherwise seek to reveal
                the operating logic of SquareBear Software in whole or in part;
                5.2 make any part of SquareBear Software available to any third
                party, unless explicitly authorised by this EULA or otherwise by
                SquareBear Technology Pty Ltd in writing; 5.3 resell or resupply
                SquareBear Software or the output (or any part of the output) of
                SquareBear Software to any person or entity; 5.4 reveal, modify,
                adapt, reproduce or otherwise use SquareBear Software (including
                any software, documentation or data related to SquareBear
                Software) for any purpose not explicitly authorised by this
                EULA; 5.5 use SquareBear Software to derive the source code,
                underlying ideas, algorithms, structure or organisation, or
                store data/output of SquareBear Software, or any similar action,
                in a manner that facilitates the creation or development of an
                Application Programming Interface (API) or any functionality of
                SquareBear Software; use or permit (whether directly or
                indirectly) the use of SquareBear Software for any illegal or
                unethical purpose; or 5.6 defeat, avoid, by-pass, remove,
                deactivate or otherwise circumvent any software protection
                mechanisms in SquareBear Software (or any part of them),
                including, without limitation, any such mechanism (including any
                website(s) operated by SquareBear Technology Pty Ltd) used to
                restrict or control the functionality of SquareBear Software (or
                any part of them).
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              6.NO OTHER WARRANTIES.
              <span className={classes.spanText}>
                SQUAREBEAR SOFTWARE IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY
                KIND. SQUAREBEAR TECHNOLOGY PTY LTD DISCLAIMS ALL WARRANTIES
                WITH RESPECT TO SQUAREBEAR SOFTWARE, EITHER EXPRESS OR IMPLIED,
                INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                NONINFRINGEMENT OF THIRD PARTY RIGHTS. SOME JURISDICTIONS DO NOT
                ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW
                LONG AN IMPLIED WARRANTY MAY LAST, OR THE EXCLUSION OR
                LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. YOU MAY ALSO
                HAVE OTHER RIGHTS WHICH VARY FROM JURISDICTION TO JURISDICTION.
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              7.YOUR INFORMATION AND SQUAREBEAR TECHNOLOGY PTY LTD'S PRIVACY
              POLICY
              <span className={classes.spanText}>
                7.1 Privacy Policy. The personal information you provide to
                SquareBear Technology Pty Ltd during the sign up and
                registration process is used for SquareBear Technology Pty Ltd's
                internal purposes only. SquareBear Technology Pty Ltd uses the
                information it collects to learn your preferences, usage
                patterns and to improve SquareBear Software for the benefit of
                all its Users. Except as otherwise expressly permitted by this
                EULA or as otherwise authorised by you, SquareBear Technology
                Pty Ltd will not provide any of your personal information it
                collects to any third party without your express approval except
                as reasonably required by law, as authorised by this provision
                or as necessary to protect SquareBear Technology Pty Ltd, or its
                agents and employees. SquareBear Technology Pty Ltd can (and you
                authorise SquareBear Technology Pty Ltd to) disclose any
                information about you to private entities, law enforcement
                agencies or government officials, as SquareBear Technology Pty
                Ltd, in its sole discretion, believe is necessary or appropriate
                to investigate or resolve possible problems or inquiries, or as
                otherwise required by law. 7.2 Email Communication. You agree
                that SquareBear Technology Pty Ltd may communicate with you via
                email and any similar technology for any purpose relating to
                SquareBear Software, other SquareBear Technology Pty Ltd
                products and any services or software which may in the future be
                provided by SquareBear Technology Pty Ltd or on SquareBear
                Technology Pty Ltd's behalf. If you do not want to receive
                communication from SquareBear Technology Pty Ltd, you can
                unsubscribe at any time following the instructions contained in
                any email received from SquareBear Technology Pty Ltd or by
                writing an opt-out request to SquareBear Technology Pty Ltd at
                support@squarebear.com.au. 7.3 Statistics & Analytics. In order
                to innovate and continuously improve its products, SquareBear
                Technology Pty Ltd may collect some anonymous usage statistics
                from its Software including, without limitation, the collection
                of information on how software is used by Users. STATISTICS
                COLLECTED ARE ANONYMOUS AND CANNOT IN ANY WAY IDENTIFY
                INDIVIDUALS.
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              8.TERM, TERMINATION AND SUSPENSION
              <span className={classes.spanText}>
                8.1 Term. This EULA shall be in effect from the first time you
                access SquareBear Software, and shall remain in effect until
                terminated in accordance with this EULA. 8.2 Termination and
                suspension. Without limiting any other right or remedy available
                to SquareBear Technology Pty Ltd under this EULA, if you fail to
                make payment of any amount due under this EULA by the due date
                for payment, or you are otherwise in breach of any term of this
                EULA, SquareBear Technology Pty Ltd may suspend your (and your
                Users’) access to SquareBear Software until payment (including
                any interest payable thereon or any subsequent payments missed)
                is received or the relevant breach has been remedied. 8.3
                SquareBear Technology Pty Ltd may also suspend your access to
                website(s) operated by SquareBear Technology Pty Ltd and
                SquareBear Software at any time: (a) for such time as is
                necessary to carry out maintenance determined by SquareBear
                Technology Pty Ltd to be necessary or desirable; (b) to reduce
                or prevent interference with website(s) operated by SquareBear
                Technology Pty Ltd or SquareBear Software; (c) if required to do
                so as a result of a direction by any government, law enforcement
                or other authority. 8.4 Either party may terminate this EULA by
                giving the other at least 30 days’ written notice. 8.5
                SquareBear Technology Pty Ltd may immediately by notice in
                writing to you, terminate this EULA where: (a) you fail to
                comply with any term of this EULA, including failure to pay any
                applicable fees or charges; (b) required by law; or (c)
                SquareBear Technology Pty Ltd considers it necessary or
                desirable to do so to protect SquareBear Technology Pty Ltd’s
                interests or the interests of anyone else. 8.6 Upon termination
                of this EULA: (a) you must immediately cease (and ensure all
                your Users immediately cease) to access or use SquareBear
                Software and destroy all access codes or passwords related to
                SquareBear Software and confidential information in your
                possession or under your control; and (b) all of your data,
                other than anonymous data, shall be deleted from SquareBear
                Software; (c) any termination will be without prejudice to any
                prior breaches by you (or any of your Users) of this EULA; and
                (d) any provision of this EULA intended to survive termination
                shall survive.
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              9.UPDATES AND NEW VERSIONS
              <span className={classes.spanText}>
                SquareBear Technology Pty Ltd may provide updates to or new
                versions of SquareBear Software (or any parts of it) and reserve
                the right to take down applicable servers hosting website(s)
                operated by SquareBear Technology Pty Ltd and SquareBear
                Software to undertake such updates or install such new versions.
                You agree to comply with SquareBear Technology Pty Ltd’s
                instructions in relation to any update, new version or
                maintenance and SquareBear Technology Pty Ltd will not be held
                liable for any upgrade, update, maintenance or new version of
                SquareBear Software (or any part of them).
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              10.SEVERABILITY.
              <span className={classes.spanText}>
                In the event of invalidity of any provision of this license, the
                parties agree that such invalidity shall not affect the validity
                of the remaining portions of this license.
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              11.NO LIABILITY.
              <span className={classes.spanText}>
                NO LIABILITY FOR CONSEQUENTIAL DAMAGES. IN NO EVENT SHALL
                SQUAREBEAR TECHNOLOGY PTY LTD OR ITS SUPPLIERS BE LIABLE TO YOU
                FOR ANY CONSEQUENTIAL, SPECIAL, INCIDENTAL OR INDIRECT DAMAGES
                OF ANY KIND ARISING OUT OF THE DELIVERY, PERFORMANCE OR USE OF
                SQUAREBEAR SOFTWARE, EVEN IF SQUAREBEAR TECHNOLOGY PTY LTD HAS
                BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT
                WILL SQUAREBEAR TECHNOLOGY PTY LTD'S LIABILITY FOR ANY CLAIM,
                WHETHER IN CONTRACT, TORT OR ANY OTHER THEORY OF LIABILITY,
                EXCEED THE FEE(S) PAID BY YOU, IF ANY.
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.boldStyle} align="center">
              12.GENERAL PROVISIONS.
              <span className={classes.spanText}>
                This is the entire agreement between you and SquareBear
                Technology Pty Ltd, which supersedes any prior agreement or
                understanding, whether written, or oral, relating to the subject
                matter of this EULA. If any part of this EULA is found void and
                unenforceable, it will not affect the validity of the balance of
                the agreement, which shall remain valid and enforceable
                according to its terms. This EULA shall automatically terminate
                upon failure by you to comply with its terms. SquareBear
                Technology Pty Ltd, on its sole discretion, may modify this EULA
                in writing at any time.
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  return renderTermsandConditions();
};

export default Terms;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "90%",
      ".MuiTextField-root": {
        color: "gray",
      },
      "& .MuiOutlinedInput-input": {
        color: "gray",
      },
    },
  },
  boldStyle: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "27px",
    textAlign: "start",
    margin: "12px 0px",
  },
  spanText: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
  },
}));
