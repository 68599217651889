import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";
import rootReducer from "../reducers";
const middleware = [thunk];
const devTools =
    process.env.NODE_ENV === "production"
        ? applyMiddleware(...middleware)
        : composeWithDevTools(applyMiddleware(...middleware));
const store = createStore(rootReducer, {}, devTools)
export default store;
