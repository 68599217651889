import {
  Box,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import {
  Warning as WarningIcon,
  Clear as ClearIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import { images } from "assets";
import { SVGRender, HookFormTextField, QuillText } from "Components/Reusable";
import { useState } from "react";
import { DropzoneSection } from ".";
import ManyCards from "./ManyCards";
/**
 *@function AddNewCard.jsx
 *@author Azim
 *
 **/
const AddNewCard = ({
  handleCreateSubmit,
  onCreateSubmit,
  classes,
  handleChangeTypeOfSize,
  sizeOfProblem,
  controlForCreate,
  createError,
  handleAddEvidenceLink,
  handleCreateModalClose,
  evidenceLinks,
  handleAddSelectedEvidenceLink,
  setFilesToSave,
  handleRemoveCreatedEvidence,
  setValue,
}) => {
  const renderStyle = { height: "24px", width: "24px" };
  const [description, setDescription] = useState("");
  const [openManyTasks, setOpenManyTasks] = useState({
    open: false,
    label: "",
    type: 0,
  });
  const [tasks, setTasks] = useState([]);
  const handleSubmit = (createdData) => {
    const data = {
      ...createdData,
      description,
      tasks,
    };
    onCreateSubmit(data);
  };
  return (
    <Box minWidth="1040px" maxWidth="1040px" maxHeight="650px">
      {openManyTasks.open ? (
        <ManyCards
          tasks={tasks}
          setTasks={setTasks}
          classes={classes}
          setOpenManyTasks={setOpenManyTasks}
          openManyTasks={openManyTasks}
          handleCreateModalClose={handleCreateModalClose}
          handleSubmit={handleSubmit}
        />
      ) : (
        <form
          onSubmit={handleCreateSubmit(handleSubmit)}
          className={classes.rootOfForm}
        >
          <Box mb="20px" className={classes.flexBetweenCenter}>
            <h1>Add Card</h1>
            <Box className={classes.flexBetweenCenter}>
              {sizeOfProblem === 0 && (
                <Tooltip title="Click to get Many cards">
                  <Button
                    className={classes.submitAndCloseButton}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setOpenManyTasks((prev) => ({
                        ...prev,
                        open: true,
                        label: "title",
                        type: 0,
                      }));
                      setValue("title", "For many");
                    }}
                  >
                    Many
                  </Button>
                </Tooltip>
              )}
              {sizeOfProblem === 1 && (
                <Tooltip title="Click to get Many cards">
                  <Button
                    className={classes.submitAndCloseButton}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setOpenManyTasks((prev) => ({
                        ...prev,
                        open: true,
                        label: "title",
                        type: 1,
                      }));
                      setValue("title", "For many");
                    }}
                  >
                    Many
                  </Button>
                </Tooltip>
              )}

              <Box>
                <Tooltip
                  placement="top"
                  title={`${sizeOfProblem === 0 ? "risk" : "issue"}`}
                  arrow
                >
                  <IconButton onClick={handleChangeTypeOfSize}>
                    {sizeOfProblem === 0 && (
                      <WarningIcon
                        style={{ color: " #FFDD15", stroke: "black" }}
                      />
                    )}
                    {sizeOfProblem === 1 && (
                      <SVGRender
                        style={{ ...renderStyle }}
                        img={images.risk_}
                        alt={"small Icon"}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box display="inline">
              <HookFormTextField
                name="title"
                control={controlForCreate}
                label="Title *"
                errors={createError}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            marginTop={"1rem"}
            marginBottom={"3rem"}
            mt="20px"
          >
            <QuillText value={description} onChange={setDescription} />
          </Box>
          <Box
            display={`flex`}
            alignItems="center"
            justifyContent={`space-between`}
            style={{ marginTop: "8px", maxWidth: "1040px" }}
          >
            <p style={{ fontWeight: "600" }}>Evidence (Links)</p>
            <Tooltip
              placement="top"
              arrow
              title="click to add new evidence link"
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleAddEvidenceLink}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {evidenceLinks.map((item, index) => (
            <FormControl
              style={{ marginTop: "16px", maxWidth: "1040px" }}
              key={index}
              fullWidth
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Evidence (Links)
              </InputLabel>
              <OutlinedInput
                onChange={(e) => handleAddSelectedEvidenceLink(e, item)}
                id="outlined-adornment-password"
                type={"text"}
                defaultValue={item.link}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleRemoveCreatedEvidence(item)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={113}
              />
            </FormControl>
          ))}

          <Box>
            <DropzoneSection
              showFileNames={true}
              showPreviewsInDropzone={true}
              setUpdateFilesToSave={setFilesToSave}
              dropzoneText
            />
          </Box>
          <Box
            display="flex"
            justifyContent="right"
            marginTop="30px"
            paddingBottom="20px"
          >
            <Button
              className={classes.submitAndCloseButton}
              style={{
                marginRight: "10px",
                background: "#F5F5F5",
              }}
              onClick={handleCreateModalClose}
              variant="outlined"
              type="button"
              color="secondary"
            >
              Close
            </Button>
            <Button
              className={classes.submitAndCloseButton}
              color="primary"
              variant="contained"
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};

export default AddNewCard;
