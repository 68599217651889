import { useCallback, useEffect, useState } from "react";
import { Box, Grid, Divider, Button } from "@material-ui/core";
import Layout from "Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "Components/Reusable";
import {
  getProjectByUserId,
  addUserToTable,
  getUserSubscriptionDetails,
  getAllSubscription,
  getOrganizationProfile,
  getUserAndProjectByAdminId,
} from "redux/actions";
import { Users } from "Components/PricingComponents";
import { nanoid } from "nanoid";
import { useHistory, Redirect } from "react-router-dom";
import { uniqBy } from "lodash";
/**
 *@function AddUsers.jsx
 *@author Azim
 *
 **/

const AddUsers = ({ offSeat }) => {
  const [error, setError] = useState("");
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { getSinglePayData, users, plans } = useSelector(
    (state) => state.payment
  );
  const { usersUnderAdmin, project } = useSelector((state) => state.projects);
  const history = useHistory();
  const [selfProject, setSelfProject] = useState({});

  useEffect(() => {
    const proj = project?.find((value) => value.projectId === user?.projectId);
    setSelfProject(proj);
  }, [project, user]);
  const styles = {
    heads: {
      backgroundColor: "#DFEBFA",
      padding: "0.5rem 4rem 0.5rem 4rem",
      borderRadius: "15px",
      fontWeight: "bolder",
      fontSize: "15px",
    },
  };
  useEffect(() => {
    if (user?.subscriptionPlanId) {
      dispatch(getUserSubscriptionDetails(user?.id));
      dispatch(getProjectByUserId(user?.id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user?.id) {
      dispatch(getAllSubscription(user?.id));
      dispatch(getOrganizationProfile(user?.projectId));
      dispatch(getUserAndProjectByAdminId(user?.id));
    }
  }, [user, dispatch]);

  // getting existing users
  const getExistingUser = useCallback(() => {
    const newplans = getSinglePayData?.items?.map((item) => {
      const newItem = plans.find((value) => value.priceId === item.priceId);
      return {
        quantity: item?.quantity,
        users: newItem?.noOfUser,
      };
    });
    const numberOfSeats = newplans?.reduce(
      (item, value) => item + value.quantity * value.users,
      0
    );
    const alluser = uniqBy(users, "id");
    const existingUser = numberOfSeats - alluser.length;
    return existingUser;
  }, [getSinglePayData, users]);

  useEffect(() => {
    if (getExistingUser() >= 0) {
      setError("");
    }

    if (getExistingUser() <= 0) {
      setError("No more seats are available");
    }
  }, [getExistingUser]);
  const addNewUserToTheColumn = () => {
    let id = nanoid(15);
    const data = {
      id,
      name: "",
      username: "",
      projectList: [],
    };
    if (getExistingUser() <= 0) {
      return;
    }
    dispatch(addUserToTable([data]));
  };

  if (user.roleType === "USER") {
    return <Redirect to="/" />;
  }

  return (
    <Layout pageName={"Add Seats"}>
      <h3
        style={{
          padding: "0px 0px 0px 10px",
          marginTop: "10px",
          marginLeft: "8px",
        }}
      >
        {getSinglePayData?.noOfUser
          ? "Add your team member"
          : "Choose your seat bundle."}
      </h3>
      <Grid container spacing={5}>
        {getSinglePayData?.items?.length > 0 ? (
          <>
            <Grid item xl={6}>
              <Users
                addNewUserToTheColumn={addNewUserToTheColumn}
                rows={users.filter((value) => value.id !== selfProject?.userId)}
              />
            </Grid>
            <Grid
              style={{ display: offSeat ? "none" : "", overflow: "none" }}
              item
              xl={6}
            >
              <Box display={`flex`} justifyContent="left">
                <Box
                  minWidth={`320px`}
                  boxShadow={2}
                  padding={2}
                  // marginLeft="20px"
                  // marginTop="15px"
                  sx={{ borderRadius: "15px" }}
                >
                  <div style={{ textAlign: "center" }}>
                    <span style={styles.heads}>User Seat Summary</span>
                  </div>
                  <div style={styles.body}>
                    <Box
                      display={"flex"}
                      justifyContent={`space-between`}
                      paddingLeft={3}
                      paddingRight={3}
                      paddingTop={3}
                      mt={2}
                      mb={2}
                    >
                      <div style={{ fontWeight: "800" }}>Existing seats</div>
                      <div style={{ fontWeight: "800" }}>
                        {getExistingUser()}
                      </div>
                    </Box>

                    <Box
                      paddingLeft={3}
                      paddingRight={3}
                      paddingTop={3}
                      mt={2}
                      mb={2}
                    >
                      <Divider />
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={`center`}
                      paddingLeft={3}
                      paddingRight={3}
                      mb={2}
                    >
                      <div style={{ fontWeight: "800", color: "#BE88C7" }}>
                        <span>{error}</span>
                      </div>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={`center`}
                      paddingLeft={3}
                      paddingRight={3}
                      mb={2}
                    >
                      <Button
                        type="submit"
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => history.push("/update-pricing")}
                      >
                        Update your plan
                      </Button>
                    </Box>
                    {/* )} */}
                  </div>
                </Box>
              </Box>
            </Grid>
          </>
        ) : (
          <Loader />
        )}
      </Grid>
    </Layout>
  );
};

export default AddUsers;
