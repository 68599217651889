/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Grid, Card, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { images } from "assets";
import { Link, Redirect, useLocation, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  resendActivationUser,
  signup,
  handleRemoveSuccessFromSignup,
  microsoftLogin,
  googleLogin,
} from "redux/actions";
import {
  Loader,
  SignBanner,
  Modal,
  FormsGrouped as SignUpForm,
  ShowPassword,
} from "Components/Reusable";
import Terms from "Components/UtilComponents/Terms";
import { redirectUrl } from "Utils";
import MicrosoftLogin from "react-microsoft-login";
import { AppMode } from "urlConfig";

const Signup = () => {
  const classes = useStyles();

  const auth = useSelector((state) => state.auth);
  const { success, loading } = useSelector((state) => state.user);
  const [clock, setClock] = useState(null);
  const [useraname, setUserName] = useState({});
  const location = useLocation();
  const history = useHistory();
  const domHostname = window?.location?.host;
  const dispatch = useDispatch();
  //RHF
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm();
  //async
  const onSubmit = (data) => {
    const { name, username, password, cpassword } = data;
    let newData = { name, password };
    newData.username = username.toLowerCase();
    newData.roleType = "ADMIN";
    newData.isAdmin = true;
    newData.avatar = "male1";
    newData.redirectUrl = redirectUrl(domHostname, "activation-link");
    if (password !== cpassword) {
      setError("cpassword", {
        type: "custom",
        message: `Confirm Password doesn't match`,
      });
    } else {
      dispatch(signup(newData));
      setClock(30);
      setUserName(newData);
      success && reset();
    }
  };

  //show password section
  const [values, setValues] = useState({
    showPassword: false,
    showCpassword: false,
  });

  const [openDialogueModal, setOpenDialogueModal] = useState(false);

  const handleClickShowPassword = () => {
    setValues((prev) => ({ ...prev, showPassword: !values.showPassword }));
  };
  const handleClickShowCPassword = () => {
    setValues((prev) => ({ ...prev, showCpassword: !values.showCpassword }));
  };

  const renderTermsAndServicesModal = () => {
    return (
      <Modal
        open={openDialogueModal}
        handleClose={() => {
          setOpenDialogueModal(false);
        }}
        board
        maxWidth={"1157px"}
        style={{ overflow: "scroll" }}
      >
        <Terms />
      </Modal>
    );
  };
  
  useEffect(() => {
    if (clock === 0) {
      setClock(null);
    }
    if (!clock) return;
    const intervalId = setInterval(() => {
      setClock(clock - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [clock]);

  // handling sending verification code to get it again
  const handleSendVerificationCode = () => {
    dispatch(resendActivationUser(useraname.username, useraname?.redirectUrl));
    setClock(60);
  };
  const handleRemoveSuccessFromSignupPage = () => {
    dispatch(handleRemoveSuccessFromSignup(false));
  };
  const mapedForm = [
    {
      label: "Name",
      name: "name",
      rules: {
        required: {
          value: true,
          message: "Name is required",
        },
        maxLength: {
          value: 40,
          message: "You can't put more than 40 characters",
        },
      },
      style: { width: "428px", marginBottom: "8px", maxWidth: "457px" },
    },
    {
      label: "Email",
      name: "username",
      rules: {
        required: {
          value: true,
          message: "This is required",
        },
        pattern: {
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          message: "Entered value does not match email format",
        },
      },
      defaultValue: localStorage.getItem("checkedemail"),
      style: { width: "428px", marginBottom: "8px" },
      type: "email",
    },
    {
      label: "Password",
      name: "password",
      style: { marginBottom: "8px" },
      InputProps: {
        endAdornment: (
          <ShowPassword
            onClick={handleClickShowPassword}
            showPassword={values.showPassword}
          />
        ),
      },
      type: values.showPassword ? "text" : "password",
    },
    {
      label: "Confirm Password",
      name: "cpassword",
      style: { marginBottom: "14px" },
      InputProps: {
        endAdornment: (
          <ShowPassword
            onClick={handleClickShowCPassword}
            showPassword={values.showCpassword}
          />
        ),
      },
      type: values.showCpassword ? "text" : "password",
    },
  ];

  const handleSigninGoogle = (response) => {
    dispatch(googleLogin(response.credential));
  };

  const handleSigninMicrosoft = (err, data, msal) => {
    if (!err && data) {
      if (data && data.idToken && data.idToken) {
        dispatch(microsoftLogin(data.idToken));
      }
      //
    }
    window.sessionStorage.clear();
  };

  const initGoogleSignIn = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = reject;
      document.head.appendChild(script);
    });
  };

  useEffect(() => {
    initGoogleSignIn()
      .then((loaded) => {
        google.accounts.id.initialize({
          client_id: AppMode.googleSignInClientId,
          callback: handleSigninGoogle,
          auto_select: true,
        });

        const target = document.getElementById("signInDev");
        google?.accounts?.id?.renderButton(target, {
          theme: "outline",
          size: "large",
          width: "212px",
        });
        target.style.border = ".1px solid black";
      })
      .catch((googleAuthError) => console.log({ googleAuthError }));
  }, []);

  

  useEffect(() => {
    // navigate login
    if (success) {
      setTimeout(() => history.push("/login"), 2000);
    }
    return () => {
      clearTimeout();
    };
  }, [success]);

  if (auth.authenticate) {
    return <Redirect from={location.pathname} exact to={`/project`} />;
  }

  return (
    <section className={classes.loginContainer}>
      <Grid container>
        <SignBanner />
        <Grid className={classes.loginFormStyle} item md={6}>
          {loading ? (
            <Loader />
          ) : success ? (
            <Card>
              <Box style={{ padding: "6rem", textAlign: "center" }}>
                <Box>
                  <Typography style={{ fontWeight: "600", fontSize: "2rem" }}>
                    Account <br /> created successfully
                  </Typography>
                  <img src={images.userCreated} alt="" />
                </Box>
                <p>
                  Your account already active , now you can login.
                  <Link
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      textDecoration: "underline",
                    }}
                    onClick={handleRemoveSuccessFromSignupPage}
                    to="/login"
                  >
                    Go Back to Login
                  </Link>
                </p>
                <Button
                  style={{
                    marginTop: "3rem",
                    padding: ".5rem 3rem .5rem 3rem",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => handleSendVerificationCode()}
                  disabled={clock ? true : false}
                >
                  {`Resend Link ${clock ? clock : ""}`}
                </Button>
              </Box>
            </Card>
          ) : (
            <Box maxWidth="428px" margin={"1rem"}>
              <Typography
                className={classes.welcomeMessage}
                variant="h4"
                component="h3"
              >
                Create an account
              </Typography>
              <Typography className={classes.signUpWriting}>
                Already have an account?
                <Link to="/login">
                  <span className={classes.signUpSpanWriting}> Login now.</span>
                </Link>
              </Typography>

              {/* hoook form section */}
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <SignUpForm
                  mapedForm={mapedForm}
                  control={control}
                  errors={errors}
                />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.loginButton}
                  >
                    Signup
                  </Button>
                </Box>
                <Box display="flex" justifyContent={"center"} marginTop="1rem">
                  <Box
                    style={{
                      width: "30%",
                      marginTop: ".6rem",
                      borderTop: "1px solid #c0c0c0",
                    }}
                  ></Box>
                  <Box style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                    Or
                  </Box>
                  <Box
                    style={{
                      width: "30%",
                      marginTop: ".6rem",
                      borderTop: "1px solid #c0c0c0",
                    }}
                  ></Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px 0px",
                  }}
                >
                  <div
                    id="signInDev"
                    style={{ border: ".1px solid black" }}
                  ></div>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px 0px",
                  }}
                >
                      <MicrosoftLogin
                        clientId="ffaaaf53-59b8-4487-8976-254e8a462453"
                        redirectUri={AppMode.callback}
                        authCallback={handleSigninMicrosoft}
                        buttonTheme="light"
                        prompt="select_account"
                      />
                </Box>
                <Typography className={classes.signUpBottomText}>
                  By continuing you indicate that you've read and agree to our
                  &nbsp;
                  <br />
                  <span
                    onClick={() => setOpenDialogueModal(true)}
                    className={classes.linkStyle}
                  >
                    Terms of Service
                  </span>
                </Typography>
                {openDialogueModal && renderTermsAndServicesModal()}
              </form>
            </Box>
          )}
        </Grid>
      </Grid>
    </section>
  );
};

export default Signup;

//material Ui styles

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
      ".MuiTextField-root": {
        color: "gray",
      },
      "& .MuiOutlinedInput-input": {
        color: "gray",
      },
    },
  },
  loginContainer: {
    maxWidth: "100%",
    overflow: "hidden",
    position: "relative",
    "& .MuiGrid-container": {
      justifyContent: "center !important",
    },
  },
  helloSign: {
    color: "#373A4D",
    fontSize: "50px",
    fontWeight: 400,
    margin: "20px 0px",
  },
  loginFormStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  welcomeMessage: {
    color: "black",
    fontSize: "1.2rem",
    fontWeight: 300,
    textAlign: "center",
    lineHeight: "51px",
    letter: "-2%",

  },
  signUpSpanWriting: {
    color: "#373A4D",
    fontSize: "16px",
    fontWeight: 700,
    margin: "20px 0px",
    textDecoration: "underline",
  },
  signUpBottomText: {
    marginTop: "10px",
    fontSize: "12px",
    fontWeight: "normal",
    color: " #6D787E",
    textAlign: "center",
  },
  loginButton: {
    marginTop: "10px",
  },
  linkStyle: {
    textDecoration: "underline",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: " #6D787E",
    cursor: "pointer",
  },
  signUpWriting: {
    color: "#373A4D",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center"
  },
  background: theme.palette.primary,
  fontSize: "13px",
  fontWeight: "700",
  textTransform: "none !important",
  marginTop: "10px",
}));
