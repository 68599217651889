import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { images } from "assets";
import { useRef } from "react";
import {
  SVGRender,
  Modal,
  HookFormTextField,
  UserAvatar,
  MuiDatePicker,
  QuillText,
} from "Components/Reusable";
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Add as AddIcon,
  Adb as AdbIcon,
  Star as StarIcon,
  AssignmentTurnedInOutlined as AssignmentTurnedInOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  AllInbox as ReleaseIcon,
} from "@material-ui/icons";
import { Controller } from "react-hook-form";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { nanoid } from "nanoid";
import { DropzoneSection, PopoverForNewAssignee, PopoverForSeverity } from ".";
import { useCallback } from "react";
import { boarsdData } from "assets/Data/BoardsData";
import { filteredItemForBlockers } from "helpers/Board";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ManyCards from "./ManyCards";
import { getSingleAvatar, htmlToText } from "Utils";
import PopoverForNewPersona from "./PopoverForNewPersona";
import useLocalStorage from "hooks/useLocalStorage";

/**
 *@function AddNewForBoard.jsx
 *@author Azim
 *
 **/

const AddNewForBoard = ({
  modalOpenForAddCard,
  handleCreateSubmit,
  onCreateSubmit,
  classes,
  persona,
  setValue,
  handleChangeTypeOfSize,
  sizeOfProblem,
  controlForCreate,
  createError,
  handleCreateModalClose,
  handleCardType,
  setFilesToSave,
  logEnable,
  renderStyle,
  cardType,
  getSingleAcceptanceCriteria,
  toUsers,
  setAssignee,
  getAvatar,
  getName,
  setEpicId,
  selectedDate,
  setSelectedDate,
  setToEmails,
  toBlockers,
  user,
  watch,
  getValues,
  isCreateDirty,
  related,
  setAllRelated,
  // feature,
  // setFeature,
  cardRenderer,
}) => {
  const ref = useRef();
  const filter = createFilterOptions();
  const { personas } = useSelector((state) => state.personas);
  const { treatments } = useSelector((state) => state.riskandissue);

  const singlePersonas = getValues("personas");
  const [description, setDescription] = useState("");
  const [openTextFieldToAcceptance, setOpenTextFieldToAcceptance] =
    useState(true);
  const [openManyTasks, setOpenManyTasks] = useState({
    open: false,
    label: "",
  });
  const [openAcceptanceCriteria, setOpenAcceptanceCriteria] = useState(false);
  const [addCardsToIceBoxInTheBoard, setAddCardsToIceBoxInTheBoard] = useState(
    []
  );
  const [tasks, setTasks] = useState([]);
  const [
    singleToUpdateAcceptanceCriteria,
    setSingleToUpdateAcceptanceCriteria,
  ] = useState({});

  const [createAceptanceCriteria, setcreateAceptanceCriteria] = useState([]);
  const [updateSingleAcceptanceCriteria, setUpdateSingleAcceptanceCriteria] =
    useState("");

  const [singleAcceptanceCriteria, setSingleAcceptanceCriteria] = useState(
    boarsdData.initialState
  );

  const [selectedPersona, setSelectedpersona] = useState(false);
  //assesment
  const [anchorSeverityChange, setAnchorSeverityChange] = useState(null);
  const [singleSeverity, setSingleSeverity] = useState({});
  const [assesments, setAssesments] = useState([]);
  //tasks
  const [anchorForAssignee, setAnchorForAssignee] = useState(null);
  const openAssignee = Boolean(anchorForAssignee);
  const openAssigneeid = openAssignee ? "simple-popover" : undefined;
  const [singleAssigneeForBoard, setSingleAssigneeForBoard] = useState({});
  /* date related states */

  const [isCtrlPressed, setIsCtrlPressed] = useState(false);

  // for submitting modal both create and update
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "s") {
        event.preventDefault();
        document.getElementById("submit-button").click();
      }
    };

    const handleKeyUp = (event) => {
      if (!event.ctrlKey) {
        setIsCtrlPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleMouseDown = (event) => {
    if (event.ctrlKey) {
      setIsCtrlPressed(true);
    }
  };

  const handleMouseUp = () => {
    setIsCtrlPressed(false);
  };

  //epic related
  let toEpics = singlePersonas?.workflows
    .map((item) => item.valueStreamList)
    .flat()
    .map((item) => ({
      label: item.valueStreamName,
      color: item.color,
      value: item.id,
    }));

  useEffect(() => {
    if (selectedPersona) {
      toEpics = selectedPersona
        ?.map((item) => item.valueStreamList)
        .flat()
        .map((item) => ({
          label: item.valueStreamName,
          color: item.color,
          value: item.id,
        }));
    }
  }, [selectedPersona]);
  const handleSeverityChange = (event, item) => {
    setAnchorSeverityChange(event.currentTarget);
    setSingleSeverity(item);
  };

  const handleCreateAcceptanceCriteria = useCallback(() => {
    if (
      singleAcceptanceCriteria !== null &&
      singleAcceptanceCriteria !== "" &&
      singleAcceptanceCriteria !== boarsdData.initialState
    ) {
      const writingDescription = {
        description: singleAcceptanceCriteria,
        id: nanoid(9),
        projectId: user?.projectId,
      };
      setcreateAceptanceCriteria((t) => [...t, writingDescription]);
      setOpenTextFieldToAcceptance(false);
      setSingleAcceptanceCriteria(boarsdData.initialState);
    }
  }, [singleAcceptanceCriteria]);

  const toPersonas = personas?.map((item) => ({
    label: item.name,
    workflows: item.workflows,
    value: item.id,
    avatar: item.avatar,
  }));

  // #TODO_SHAHED BHAI
  const [storedValue, setLocalValue] = useLocalStorage(
    "currentPersona",
    toPersonas[0]
  );

  // #TODO_SHAHED BHAI
  const [feature, setFeature] = useState(
    boarsdData.initialFeatureState(storedValue?.label)
  );

  // for re-render local value
  useEffect(() => {
    if (storedValue?.label) {
      const newfeaturelable = boarsdData.initialFeatureState(
        storedValue?.label
      );
      setFeature(newfeaturelable);
    }
  }, [storedValue]);

  const handleUpdateSingleAcceptanceCriteria = (singleAccept) => {
    setSingleToUpdateAcceptanceCriteria(singleAccept);
    setUpdateSingleAcceptanceCriteria(singleAccept.description);
    setOpenAcceptanceCriteria(true);
  };

  const handleCreateUpdateAcceptanceCriteria = () => {
    if (
      updateSingleAcceptanceCriteria !== null &&
      updateSingleAcceptanceCriteria !== ""
    ) {
      const itemIndex = createAceptanceCriteria.findIndex(
        (item) => item.id === singleToUpdateAcceptanceCriteria.id
      );
      if (itemIndex !== -1) {
        createAceptanceCriteria[itemIndex].description =
          updateSingleAcceptanceCriteria;
      }
      setOpenAcceptanceCriteria(false);
      setOpenTextFieldToAcceptance(false);
      setSingleAcceptanceCriteria("");
      setUpdateSingleAcceptanceCriteria("");
      setSingleToUpdateAcceptanceCriteria({});
    } else {
      setOpenAcceptanceCriteria(false);
      setOpenTextFieldToAcceptance(false);
      setSingleAcceptanceCriteria("");
      setUpdateSingleAcceptanceCriteria("");
      setSingleToUpdateAcceptanceCriteria({});
    }
  };


  const handleSubmit = (modaldata) => {

    if(cardType === 0){
      delete modaldata?.bugsLabel
      delete modaldata?.choresLabel
    }
    else if(cardType === 1){
      delete modaldata?.choresLabel
    }else if(cardType === 2){
      delete modaldata?.bugsLabel
    }

    let newState;
    if (
      singleAcceptanceCriteria !== null &&
      singleAcceptanceCriteria !== "" &&
      singleAcceptanceCriteria !== boarsdData.initialState
    ) {
      const writingDescription = {
        description: singleAcceptanceCriteria,
        id: nanoid(9),
        projectId: user?.projectId,
      };
      newState = [writingDescription];
    }

    const cardId = `node_process_${nanoid(15)}`;
    const newCreateShallowArray = newState
      ? newState
      : [...createAceptanceCriteria];
    const updateCreateAceptanceCriteria = newCreateShallowArray.map((item) => {
      const newItem = { ...item };
      newItem.cardId = cardId;
      if (newItem.id.length === 9) {
        delete newItem.id;
        return newItem;
      }
      return newItem;
    });
    const assesmentData = assesments.length
      ? assesments
      : boarsdData?.initialBoardAssesment;

    const data = {
      ...modaldata,
      title: feature,
      description,
      cardId,
      updateCreateAceptanceCriteria,
      assesmentData,
      tasks: tasks.filter(
        (value) => value.choresLabel !== "" && value.bugsLabel !== ""
      ),
    };
    onCreateSubmit(data);
  };


  const openSeverity = Boolean(anchorSeverityChange);
  const handleUpdateAssesmentSeverity = (item) => {
    singleSeverity["buttonText"] = item.title;
    singleSeverity["color"] = item.color;
    singleSeverity["impact"] = item.impactKey;
    setAssesments([...assesments]);
    setAnchorSeverityChange(null);
  };
  //task related
  // const handleAddNewTaskForTheRiskAndIssue = () => {
  //   const cardId = `node_process_${nanoid(15)}`;
  //   const datasas = {
  //     createDate: new Date(),
  //     dueDate: null,
  //     title: "",
  //     id: cardId,
  //   };
  //   setAddCardsToIceBoxInTheBoard([...addCardsToIceBoxInTheBoard, datasas]);
  // };
  // const handleAddTaskforIcebox = (e, items) => {
  //   const getTaskTitle = e.target.value;
  //   const getData = addCardsToIceBoxInTheBoard.find(
  //     (item) => item.id === items.id
  //   );
  //   getData.title = getTaskTitle;
  //   const indexedEvidence = addCardsToIceBoxInTheBoard.findIndex(
  //     (info) => info.id === getData.id
  //   );
  //   addCardsToIceBoxInTheBoard[indexedEvidence] = getData;
  //   setAddCardsToIceBoxInTheBoard(addCardsToIceBoxInTheBoard);
  // };
  // const handleAddDueDateForIceBoxCard = (date, items) => {
  //   const getData = addCardsToIceBoxInTheBoard.find(
  //     (item) => item.id === items.id
  //   );
  //   getData.dueDate = date;
  //   const indexedDueDate = addCardsToIceBoxInTheBoard.findIndex(
  //     (info) => info.id === getData.id
  //   );
  //   addCardsToIceBoxInTheBoard[indexedDueDate] = getData;
  //   setAddCardsToIceBoxInTheBoard(addCardsToIceBoxInTheBoard);
  // };

  // for related field
  const { allCard } = useSelector((state) => state.kanban);

  const allCardsofProject = allCard?.map((block) => ({
    label: block?.title
      ? htmlToText(block?.title) + " " + block.label
      : block?.bugsLabel
      ? block?.bugsLabel
      : block?.choresLabel,
    value: block.id,
  }));
  return (
    <Modal
      open={modalOpenForAddCard}
      board
      handleClose={() => handleCreateModalClose(false)}
    >
      {openManyTasks.open ? (
        <>
          <ManyCards
            tasks={tasks}
            setTasks={setTasks}
            classes={classes}
            setOpenManyTasks={setOpenManyTasks}
            openManyTasks={openManyTasks}
            handleCreateModalClose={handleCreateModalClose}
            handleSubmit={handleSubmit}
          />
        </>
      ) : (
        <div style={{ padding: "10px" }}>
          <div
            style={{
              minWidth: "700px",
              maxHeight: "650px",
            }}
          >
            <PopoverForSeverity
              openSeverity={openSeverity}
              fromBoard
              anchorSeverityChange={anchorSeverityChange}
              setAnchorSeverityChange={setAnchorSeverityChange}
              className={classes.assesmentPopOverStyle}
              handleUpdateAssesmentSeverity={handleUpdateAssesmentSeverity}
            />
            <PopoverForNewPersona
              openAssigneeid={openAssigneeid}
              openAssignee={openAssignee}
              anchorForAssignee={anchorForAssignee}
              setAnchorForAssignee={setAnchorForAssignee}
              classes={classes}
              toUsers={toPersonas}
              addCardsToIceBoxInTheBoard={addCardsToIceBoxInTheBoard}
              singleAssigneeForBoard={singleAssigneeForBoard}
              storedValue={storedValue}
              setLocalValue={setLocalValue}
              getAvatar={getAvatar}
              getName={getName}
              de
            />
            <form
              onSubmit={handleCreateSubmit(handleSubmit)}
              className={classes.rootOfForm}
            >
              <div className={classes.flexBetweenCenter}>
                {cardType === 0 || cardType === 1 || cardType === 2 ? (
                  <h1>Add Card</h1>
                ) : null}
                {cardType === 0 || cardType === 1 || cardType === 2 ? (
                  <div className={classes.flexBetweenCenter}>
                    <div>
                      <Tooltip
                        title={"Click to change persona"}
                        placement="top"
                        arrow
                      >
                        <IconButton
                          onClick={(event) =>
                            setAnchorForAssignee(event.currentTarget)
                          }
                        >
                          <Avatar
                            style={{
                              height: "30px",
                              width: "30px",
                            }}
                            src={
                              storedValue?.avatar
                                ? getSingleAvatar(storedValue.avatar)
                                : "Azim"
                            }
                          ></Avatar>
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div>
                      {cardType === 1 && (
                        <Tooltip title="Click to get Many cards">
                          <Button
                            className={classes.submitAndCloseButton}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setOpenManyTasks((prev) => ({
                                ...prev,
                                open: true,
                                label: "bugsLabel",
                              }));
                              setValue("bugsLabel", "For many");
                            }}
                          >
                            Many
                          </Button>
                        </Tooltip>
                      )}
                      {cardType === 2 && (
                        <Tooltip title="Click to get Many cards">
                          <Button
                            className={classes.submitAndCloseButton}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setOpenManyTasks((prev) => ({
                                ...prev,
                                open: true,
                                label: "choresLabel",
                              }));
                              setValue("choresLabel", "For many");
                            }}
                          >
                            Many
                          </Button>
                        </Tooltip>
                      )}
                    </div>
                    {!logEnable ? (
                      <div>
                        <IconButton onClick={handleChangeTypeOfSize}>
                          {sizeOfProblem === 0 && (
                            <SVGRender
                              style={renderStyle}
                              img={images.tShirt}
                              alt={"noSize Icon"}
                            />
                          )}
                          {sizeOfProblem === 1 && (
                            <SVGRender
                              style={renderStyle}
                              img={images.smallIconForSize}
                              alt={"small Icon"}
                            />
                          )}
                          {sizeOfProblem === 2 && (
                            <SVGRender
                              style={renderStyle}
                              img={images.mediumIconForSize}
                              alt={"medium Icon"}
                            />
                          )}
                          {sizeOfProblem === 3 && (
                            <SVGRender
                              style={renderStyle}
                              img={images.largeIconForSize}
                              alt={"large Icon"}
                            />
                          )}
                        </IconButton>
                      </div>
                    ) : null}

                    <div>
                      {/* here we have to add the configuration  */}
                      <IconButton onClick={handleCardType}>
                        {cardType === 0 && (
                          <StarIcon style={{ color: " #F0DD2E" }} />
                        )}
                        {cardType === 1 && (
                          <AdbIcon style={{ color: " red" }} />
                        )}
                        {cardType === 2 && <AssignmentTurnedInOutlinedIcon />}
                        {cardType === 3 && (
                          <ReleaseIcon style={{ color: "#2eb82e" }} />
                        )}
                      </IconButton>
                    </div>
                  </div>
                ) : null}
              </div>

              {cardType === 0 && (
                <div>
                  <QuillText value={feature} onChange={setFeature} />
                  <div className={classes.flexBetweenCenter}>
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#444444",
                      }}
                    >
                      Acceptance Criteria
                    </p>
                    {!openTextFieldToAcceptance && (
                      <IconButton
                        onClick={() => setOpenTextFieldToAcceptance(true)}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                  </div>
                  <div>
                    {openTextFieldToAcceptance && (
                      <Box style={{ display: "inline" }}>
                        {/* TODO */}
                        <QuillText
                          value={singleAcceptanceCriteria}
                          onChange={setSingleAcceptanceCriteria}
                        />

                        {/* acceptance criteria buttons */}
                        <div className={classes.shortIconForAcceptance}>
                          <Button
                            variant="contained"
                            onClick={handleCreateAcceptanceCriteria}
                          >
                            <CheckIcon />
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setSingleAcceptanceCriteria(
                                boarsdData.initialState
                              );
                              setOpenTextFieldToAcceptance(false);
                            }}
                          >
                            <ClearIcon />
                          </Button>
                        </div>
                      </Box>
                    )}
                    {/* Created Update Acceptancce Criteria */}
                    {openAcceptanceCriteria && (
                      <Box style={{ display: "inline" }}>
                        {/* TODO For Update*/}
                        <QuillText
                          value={updateSingleAcceptanceCriteria}
                          onChange={setUpdateSingleAcceptanceCriteria}
                        />

                        {/* acceptance criteria buttons */}
                        <div className={classes.shortIconForAcceptance}>
                          <Button
                            variant="contained"
                            onClick={handleCreateUpdateAcceptanceCriteria}
                          >
                            <CheckIcon />
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setOpenAcceptanceCriteria(false);
                              setSingleToUpdateAcceptanceCriteria({});
                            }}
                          >
                            <ClearIcon />
                          </Button>
                        </div>
                      </Box>
                    )}
                  </div>
                  <div>
                    {createAceptanceCriteria &&
                      createAceptanceCriteria.map((item, index) => (
                        <div
                          style={{
                            display:
                              singleToUpdateAcceptanceCriteria.id === item.id
                                ? "none"
                                : "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            maxWidth: "700px",
                            margin: "5px 0px",
                          }}
                          title={`Click to Update`}
                          key={index}
                        >
                          <div
                            className={classes.acceptanceCriteriaStyle}
                            dangerouslySetInnerHTML={{
                              __html: getSingleAcceptanceCriteria(
                                item?.description
                              ),
                            }}
                            onClick={() =>
                              handleUpdateSingleAcceptanceCriteria(item)
                            }
                          ></div>
                          <IconButton
                            onClick={() => {
                              setcreateAceptanceCriteria(
                                createAceptanceCriteria.filter(
                                  (items) => items.id !== item.id
                                )
                              );
                            }}
                          >
                            <DeleteOutlineIcon style={{ color: "#FF0000" }} />
                          </IconButton>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {(cardType === 1 || cardType === 2) && (
                <div>
                  <Box display="inline">
                    <HookFormTextField
                      name={cardType === 1 ? "bugsLabel" : "choresLabel"}
                      control={controlForCreate}
                      label={
                        cardType === 1 ? "Bug's Label *" : "Task's Label *"
                      }
                      errors={createError}
                    />
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      marginTop={"1rem"}
                      marginBottom={"3.5rem"}
                      maxWidth={1014}
                    >
                      <QuillText
                        value={description}
                        onChange={setDescription}
                      />
                    </Box>
                  </Box>
                </div>
              )}

              {cardType === 3 && (
                <>
                  <div>
                    <Box>
                      <h1 style={{ textAlign: "left" }}>Release Card</h1>
                    </Box>
                    <Box>
                      <HookFormTextField
                        name="releaseName"
                        rules={{
                          maxLength: {
                            value: 25,
                            message: "Not more than 25",
                          },
                          required: {
                            value: true,
                            message: "This is required",
                          },
                        }}
                        control={controlForCreate}
                        label="Release Name *"
                        errors={createError}
                        style={{ width: "100%" }}
                      />
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        marginTop={"1rem"}
                      >
                        <Controller
                          name="goal"
                          control={controlForCreate}
                          render={({ field }) => (
                            <TextareaAutosize
                              {...field}
                              minRows={6}
                              className={classes.textareaStyle}
                              placeholder="Release Goal"
                              style={{ width: "100%" }}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                  </div>

                  <div className="dateStyle">
                    <MuiDatePicker
                      className={classes.muiDateWrapper}
                      value={selectedDate}
                      keyboard
                      onChange={(date) => setSelectedDate(date)}
                    />
                  </div>

                  <Box
                    display="flex"
                    justifyContent="right"
                    marginTop="30px"
                    paddingBottom="20px"
                  >
                    <Button
                      className={classes.submitAndCloseButton}
                      style={{
                        marginRight: "10px",
                        background: "#F5F5F5",
                      }}
                      onClick={handleCreateModalClose}
                      variant="outlined"
                      type="button"
                      color="secondary"
                    >
                      Close
                    </Button>
                    <Button
                      className={classes.submitAndCloseButton}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              )}

              {cardType === 0 || cardType === 1 || cardType === 2 ? (
                <>
                  <Box
                    display="flex"
                    justifyContent={`space-between`}
                    style={{ columnGap: "10px" }}
                  >
                    <Autocomplete
                      options={toUsers}
                      id="blur-on-select"
                      blurOnSelect
                      getOptionSelected={(option, value) => {
                        return option.value === value.value;
                      }}
                      getOptionLabel={(option) => option.label}
                      style={{ width: "100%", flex: 1, marginTop: "16px" }}
                      onChange={(e, f) => setAssignee(f.value)}
                      renderOption={(option, { selected }) => (
                        <div className={classes.flexBetweenCenter}>
                          <UserAvatar
                            className={classes.typesIconStyle}
                            getAvatar={getAvatar}
                            getName={getName}
                            userId={option.value}
                          />
                          <p
                            style={{
                              color: "black",
                              display: "block",
                              marginLeft: "10px",
                            }}
                          >
                            {option.label}
                          </p>
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Assignee"
                          variant="outlined"
                        />
                      )}
                    />
                    <Autocomplete
                      options={toEpics ?? []}
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) => {
                        return option.value === value.value;
                      }}
                      style={{ width: "100%", flex: 1, marginTop: "16px" }}
                      id="blur-on-select"
                      blurOnSelect
                      onChange={(e, f) => setEpicId(f.value)}
                      renderOption={(option, { selected }) => (
                        <div
                          style={{
                            width: "100%",
                            background: option.color ? option.color : "#67c6c0",
                          }}
                        >
                          <p
                            style={{
                              color: "black",
                              marginLeft: "10px",
                            }}
                          >
                            {option.label}
                          </p>
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Epic link"
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={`space-between`}
                    style={{ columnGap: "10px" }}
                  >
                    <div className="dateStyle" style={{ flex: 1 }}>
                      <MuiDatePicker
                        className={classes.muiDateWrapper}
                        keyboard
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                      />
                    </div>
                    <div
                      style={{ flex: 1, paddingTop: "8px" }}
                      className={classes.linkToDesignStyle}
                    >
                      <HookFormTextField
                        name="linkToDesign"
                        control={controlForCreate}
                        label="Add link"
                        errors={createError}
                      />
                    </div>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent={`space-between`}
                    style={{ columnGap: "10px", maxWidth: "1014px" }}
                  >
                    <Autocomplete
                      multiple
                      limitTags={5}
                      style={{
                        flex: 1,
                        paddingTop: "12px",
                      }}
                      getOptionSelected={(option, value) => {
                        const optionTitle =
                          typeof option === "string" ? option : option.value;
                        const valueTitle =
                          typeof value === "string" ? value : value.value;
                        return optionTitle === valueTitle;
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            style={{
                              maxWidth: "90px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                            label={option.label}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={(event, newValue) => {
                        if (Array.isArray(newValue)) {
                          const updatedArrayValue = newValue.filter((e) =>
                            typeof e === "string" ? e.trim() : e
                          );
                          // #TODO
                          const newArrayValue = [...updatedArrayValue];
                          const updatedArray = newArrayValue.map((item) => {
                            if (typeof item === "string") {
                              const newItem = {};
                              newItem.label = item;
                              newItem.value = nanoid(12);
                              newItem.type = "created";
                              return newItem;
                            }
                            return item;
                          });
                          setToEmails(updatedArray);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== "") {
                          filteredItemForBlockers(params, filtered);
                        }
                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={toBlockers}
                      filterSelectedOptions
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.label;
                      }}
                      renderOption={(option) => option.label}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Blockers"
                          variant="outlined"
                        />
                      )}
                    />
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      style={{
                        flex: 1,
                        paddingTop: "12px",
                      }}
                      options={allCardsofProject}
                      onChange={(event, newValue) => {
                        if (Array.isArray(newValue)) {
                          const updatedArrayValue = newValue.filter((e) =>
                            typeof e === "string" ? e.trim() : e
                          );
                          // #TODO
                          const newArrayValue = [...updatedArrayValue];
                          const updatedArray = newArrayValue.map((item) => {
                            if (typeof item === "string") {
                              const newItem = {};
                              newItem.label = item;
                              newItem.value = nanoid(12);
                              newItem.type = "created";
                              return newItem;
                            }
                            return item;
                          });
                          setAllRelated(updatedArray);
                        }
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            style={{
                              maxWidth: "90px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                            label={option.label}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Related Fields"
                          placeholder="Favorites"
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <DropzoneSection
                      showFileNames={true}
                      showPreviewsInDropzone={true}
                      setUpdateFilesToSave={setFilesToSave}
                      dropzoneText
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="right"
                    marginTop="30px"
                    paddingBottom="20px"
                  >
                    <Tooltip title="Press (Esc)">
                      <Button
                        className={classes.submitAndCloseButton}
                        style={{
                          marginRight: "10px",
                          background: "#F5F5F5",
                        }}
                        onClick={() => handleCreateModalClose(true)}
                        variant="outlined"
                        type="button"
                        color="secondary"
                      >
                        Close
                      </Button>
                    </Tooltip>

                    <Tooltip title="Press (Ctrl + s)">
                      <Button
                        className={classes.submitAndCloseButton}
                        color="primary"
                        variant="contained"
                        type="submit"
                        id="submit-button"
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                      >
                        Submit
                      </Button>
                    </Tooltip>
                  </Box>
                </>
              ) : null}
            </form>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddNewForBoard;
