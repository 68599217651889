import { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Layout from "Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "Components/Reusable";
import {
  getProjectByUserId,
  getUserSubscriptionDetails,
  getAllSubscription,
  getUserAndProjectByAdminId,
} from "redux/actions";
import { PricingCount, SingleBox } from "Components/PricingComponents";
import { useHistory } from "react-router-dom";
import { uniqBy } from "lodash";
import { Redirect } from "react-router-dom";
/**
 *@function Pricing.jsx
 *@author Azim
 *
 **/

const Pricing = () => {
  const [active, setactive] = useState();
  const [items, setItems] = useState([]);
  const [error, setError] = useState("");
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.orgprofile);
  const history = useHistory();
  const { users, plans, getSinglePayData } = useSelector(
    (state) => state.payment
  );
  useEffect(() => {
    if (user?.id) {
      dispatch(getUserSubscriptionDetails(user?.id));
      dispatch(getProjectByUserId(user?.id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user?.id) {
      dispatch(getAllSubscription(user?.id));
      dispatch(getUserAndProjectByAdminId(user?.id));
    }
  }, [user,dispatch]);

  // getting existing users
  const getExistingUser = useCallback(() => {
    const newplans = getSinglePayData && getSinglePayData?.items?.map(item => {
      const newItem = plans && plans?.find(value => value.priceId === item.priceId)
      return {
        quantity: item?.quantity,
        users: newItem?.noOfUser
      }
    })
    const numberOfSeats = newplans?.reduce((item, value) => item + (value.quantity * value.users), 0)
    const alluser = uniqBy(users, "id");
    const existingUser = numberOfSeats - alluser?.length
    return existingUser

  }, [getSinglePayData, users, plans]);

  useEffect(() => {
    if (getExistingUser() >= 0) {
      setError("");
    }
    if (getExistingUser() <= 0) {
      setError("No more seats are available");
    }
  }, [getExistingUser]);
  // handle Change Element
  const handleChange = (data) => {
    setactive(data);
    // const activeItem = plans.find((value) => value.name === data.name);
    const findIndexofNewItem = items.findIndex(value => value.id === data.id)
    if(findIndexofNewItem !== -1){
      items.splice(findIndexofNewItem, 1)
    }
    const itemsnew = [...items, data]
    setItems(itemsnew.filter(value => value.quantity > 0));
  };

  if (user.roleType === "USER") {
    return <Redirect to="/" />;
  }

  return (
    <Layout pageName={"Add Seats"}>
      <h3
        style={{
          padding: "0px 0px 0px 10px",
          marginTop: "10px",
          marginLeft: "8px",
        }}
      >
        {getSinglePayData?.noOfUser
          ? "Add your team member"
          : "Choose your seat bundle."}
      </h3>
      <Grid
        container
        style={{
          padding: "0px 10px 10px 10px",
          marginTop: "10px",
          marginLeft: "8px",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <Grid item xs={12} md={6}>
              <Box>
                {plans.filter(value => value?.visible === true).map((item, index) => (
                  <SingleBox
                    key={index}
                    item={item}
                    active={active}
                    existing={user?.noofusernow}
                    click={handleChange}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <PricingCount existingSeat={getExistingUser} items={items} users={users} />
            </Grid>
          </>
        )}
      </Grid>
    </Layout>
  );
};

export default Pricing;
