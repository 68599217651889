import { Tooltip, makeStyles } from "@material-ui/core";
import React from "react";
import { Handle } from "reactflow";
import "./index.css";

const DecisionNode = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.decisionNode} decisionNode`}>
      <Handle
        type="target"
        position="left"
        id="decision_a"
        className={classes.leftHandleStyle}
      />
      <Handle
        type="target"
        position="top"
        id="decision_c"
        className={classes.topHandleStyle}
      />
      <Handle
        type="target"
        className={classes.rightHandleStyle}
        position="top"
        id="decision_d"
      />
      <Tooltip
        placement={"top"}
        arrow
        title={data?.name ? data?.name : "decision node"}
      >
        <p
          style={{
            position: "absolute",
            color: "black",
            zIndex: "2",
            fontSize: "16px",
            wordWrap: "normal",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transform: "rotate(-45deg)",
            textAlign: "center",
            top: 0,
            left: 0,
            right: 0,
            wordBreak: "break-word",
            bottom: 0,
          }}
        >
          {data?.name.length > 15
            ? data?.name.slice(0, 15) + "..."
            : data?.name}
        </p>
      </Tooltip>

      <Handle
        type="source"
        className={classes.bottomHandleStyle}
        position="right"
        id="decision_b"
      />
    </div>
  );
};
export default DecisionNode;
const commonStyle = {
  background: "#295b88",
  position: "relative",
  zIndex: "2",
  fontSize: "10px",
  padding: "3px",
  visibility: "hidden",
};
const useStyles = makeStyles((theme) => ({
  decisionNode: {
    background: "#d5f692",
    minWidth: "82.81px",
    minHeight: "82.81px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    transform: "rotate(45deg)",
    "&:hover .react-flow__handle": {
      visibility: "visible",
    },
  },
  bottomHandleStyle: {
    visibility: "hidden",
    zIndex: "2",
    top: 0,
    right: 0,
    padding: "3px",
    background: "#295b88",
    transform: "rotate(-45deg)",
    content: "data",
    top: 0,
    right: 0,
  },
  leftHandleStyle: {
    ...commonStyle,
    top: "80px",
    left: "-3px",
  },
  topHandleStyle: {
    ...commonStyle,
    top: "-10px",
    left: "0px",
  },
  rightHandleStyle: {
    ...commonStyle,
    top: "62px",
    left: "81px",
  },
}));
