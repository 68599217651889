import { logEnable } from "../actions/constant";

const initState = {
    error: "",
    success: false,
    enable: false,
}


const logEnableReducer = (state = initState, action) => {
    switch(action.type){
        case logEnable.UPDATE_LOGHOUR_ENABLE_SUCCESS:
            return {
                ...state,
                success: true,
                enable: action.payload
            }
        case logEnable.UPDATE_LOGHOUR_ENABLE_FAILED:
            return {
                ...state,
                success: false,
                enable: false
            }
        case logEnable.FETCH_ENABLE_LOGHOUR_REQUEST:
            return{
                ...state,
                success: false,
                enable: false
            }
        case logEnable.FETCH_ENABLE_LOGHOUR_SUCCESS:
            return {
                ...state,
                success: true,
                enable: action.payload
            }
        case logEnable.FETCH_ENABLE_LOGHOUR_FAILED:
            return{
                ...state,
                success: false,
                enable: false,
            }
        default:
            return state;
    }
}

export default logEnableReducer