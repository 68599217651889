/* eslint-disable no-undef */
import {
    Button,
    Grid,
    Typography,
    Box,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { googleLogin, login, microsoftLogin, pageMove } from "redux/actions";
import {
    FormsGrouped as SignInForm,
    SignBanner,
    ShowPassword,
} from "Components/Reusable";
import { useHistory } from "react-router-dom";
import MicrosoftLogin from "react-microsoft-login";
import { AppMode } from "urlConfig";

/**
 *@function Login.jsx
 *@author Azim
 *
 **/
const Login = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const history = useHistory();
    const [afterlogin, _] = useState(localStorage.getItem("afterlogin"));

    //RHF
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({ mode: "all" });
    const [state, setState] = useState({
        checkedA: true,
        checkedB: localStorage.getItem("checked"),
        checkedF: true,
        checkedG: true,
    });

    const onSubmit = (data) => {
        const { username, password } = data;
        if (state.checkedB) {
            localStorage.setItem("checkedemail", username);
        }
        let newData = { password };
        newData.username = username.toLowerCase();
        if (newData) {
            dispatch(login(newData));
            localStorage.removeItem("afterlogin");
        }
    };
    //states of login Component
    const [values, setValues] = useState({
        showPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues((prev) => ({ ...prev, showPassword: !values.showPassword }));
    };
    const handleChange = (event) => {
        if (event.target.checked) {
            localStorage.setItem("checked", true);
        } else {
            localStorage.removeItem("checked");
            localStorage.removeItem("checkedemail");
        }

        setState({ ...state, [event.target.name]: event.target.checked });
    };
    //modal states

    if (auth.authenticate) {
        if (afterlogin) {
            history.push(afterlogin);
        } else {
            history.push("/project");
        }
    }
    const mapedForm = [
        {
            label: "Email",
            name: "username",
            value: "zaqaa@gmail.com",
            rules: {
                required: {
                    value: true,
                    message: "This is required",
                },
                pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: "Entered value does not match email format",
                },
            },
            defaultValue: localStorage.getItem("checkedemail"),
            style: { width: "428px", marginBottom: "8px" },
            type: "email",
        },
        {
            label: "Password",
            name: "password",
            style: { marginBottom: "8px" },
            InputProps: {
                endAdornment: (
                    <ShowPassword
                        onClick={handleClickShowPassword}
                        showPassword={values.showPassword}
                    />
                ),
            },
            type: values.showPassword ? "text" : "password",
        },
    ];

    const handleSigninGoogle = (response) => {
        dispatch(googleLogin(response.credential));
    };

    const handleSigninMicrosoft = (err, data, msal) => {
        if (!err && data) {
            if (data && data.idToken && data.idToken) {

                dispatch(microsoftLogin(data.idToken));
            }
        }
        window.sessionStorage.clear();
    };

    useEffect(() => {
        initGoogleSignIn()
            .then((loaded) => {
                google.accounts.id.initialize({
                    client_id: AppMode.googleSignInClientId,
                    callback: handleSigninGoogle,
                    auto_select: true,
                });

                const target = document.getElementById("signInDev");
                google?.accounts?.id?.renderButton(target, {
                    theme: "outline",
                    size: "large",
                    width: "212px",
                });
                target.style.border = ".1px solid black"; 
            })
            .catch((googleAuthError) => console.log({ googleAuthError }));
    }, []);

    const initGoogleSignIn = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = "https://accounts.google.com/gsi/client";
            script.onload = () => {
                resolve(true);
            };
            script.onerror = reject;
            document.head.appendChild(script);
        });
    };

    return (
        <section className={classes.loginContainer}>
            <Grid container>
                <SignBanner />
                <Grid className={classes.loginFormStyle} item md={6}>
                    <Box style={{ maxWidth: "428px", margin: "1rem" }}>
                        <Typography className={classes.welcomeMessage}>
                            Sign into your account
                        </Typography>
                        <Typography className={classes.signUpWriting}>
                            Don’t have one?
                            <Link onClick={() => dispatch(pageMove())} to="/signup">
                                <span className={classes.signUpSpanWriting}> Sign up now.</span>
                            </Link>
                        </Typography>
                        <Typography
                            style={{ color: "red", padding: "0px 0px", fontWeight: "600" }}
                        >
                            {auth?.state?.error}
                        </Typography>
                        <form
                            className={classes.root}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <SignInForm
                                mapedForm={mapedForm}
                                control={control}
                                errors={errors}
                            />
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "20px 0px",
                                }}
                            >
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.checkedB ? true : false}
                                                onChange={handleChange}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Remember me"
                                    />
                                </Box>
                                <Box>
                                    <Link
                                        style={{
                                            color: "#373A4D",
                                            textDecoration: "underline",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            fontWeight: 400,
                                        }}
                                        to="/forgot-password"
                                    >
                                        Forgot Password
                                    </Link>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "20px 0px",
                                }}
                            >
                                <Button
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    className={classes.loginButton}
                                >
                                    Login
                                </Button>
                            </Box>
                        </form>
                        <Box display="flex" justifyContent={"center"}>
                            <Box
                                style={{
                                    width: "30%",
                                    marginTop: ".6rem",
                                    borderTop: "1px solid #c0c0c0",
                                }}
                            ></Box>
                            <Box style={{ marginLeft: "1rem", marginRight: "1rem" }}>Or</Box>
                            <Box
                                style={{
                                    width: "30%",
                                    marginTop: ".6rem",
                                    borderTop: "1px solid #c0c0c0",
                                }}
                            ></Box>
                        </Box>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "20px 0px",
                            }}
                        >
                            <div id="signInDev">
                                <CircularProgress />
                            </div>
                        </Box>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "20px 0px",
                            }}
                        >
                            <MicrosoftLogin
                                clientId="ffaaaf53-59b8-4487-8976-254e8a462453"
                                redirectUri={AppMode.callback}
                                authCallback={handleSigninMicrosoft}
                                buttonTheme="light"
                                prompt="select_account"
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </section>
    );
};

export default Login;

//Material Ui css

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            maxWidth: "100%",
            ".MuiTextField-root": {
                color: "white",
            },
            "& .MuiSvgIcon-root": {
                color: "rgba(0, 0, 0, 0.6)",
            },
            "& .MuiOutlinedInput-input": {
                color: "black",
            },
        },
    },
    loginContainer: {
        maxWidth: "100%",
        overflow: "hidden",
        position: "relative",
        "& .MuiGrid-container": {
            justifyContent: "center !important",
        },
    },

    loginFormStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    welcomeMessage: {
        color: "#373A4D",
        fontSize: "1.2rem",
        fontWeight: 200,
        margin: "10px 0px",
        textAlign: "center",
    },
    helloSign: {
        color: "#373A4D",
        fontSize: "50px",
        fontWeight: 400,
        margin: "10px 0px",
    },
    signUpWriting: {
        color: "#373A4D",
        fontSize: "16px",
        fontWeight: 400,
        textAlign: "center"
    },
    signUpSpanWriting: {
        color: "#373A4D",
        fontSize: "16px",
        fontWeight: 700,
        margin: "20px 0px",
        textDecoration: "underline",
    },
    loginButton: {
        background: theme.palette.primary,
        fontSize: "13px",
        fontWeight: "700",
        textTransform: "none !important",
    },
}));
