import { personas } from "../actions/constant";

const initState = {
    error: "",
    success: false,
    loading: false,
    personas: [],
    singlePersona: {},
    personaNameAndId: {}
}


const personasReducer = (state = initState, action) => {
    switch (action.type) {
        case personas.GET_PERSONAS_REQUEST:
            return {
                ...state,
                success: false,
                loading: true,
                personas: []
            }
        case personas.GET_PERSONAS_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                personas: action.payload
            }
        case personas.EMPTY_ALL_PERSONA_DETAILS:
            return {
                ...state,
                personas: []
            }
        case personas.GET_ONLY_ID_AND_NAME:
            return {
                ...state,
                success: true,
                loading: false,
                personaNameAndId: action.payload
            }
        case personas.GET_PERSONAS_FAILED:
            return {
                ...state,
                success: false,
                loading: false,
                error: action.payload,
                personas: []
            }
        case personas.CREATE_PERSONAS_REQUEST:
            return {
                ...state,
                success: false,
                loading: true,
                personas: []
            }
        case personas.CREATE_PERSONAS_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                personas: []
            }
        case personas.CREATE_PESONAS_FAILED:
            return {
                ...state,
                success: false,
                loading: false,
                error: action.payload,
                personas: []
            }
        case personas.UPDATE_PERSONAS_REQUEST:
            return {
                ...state,
                success: false,
                loading: true,
                personas: []
            }
        case personas.UPDATE_PERSONAS_SUCCESS:
            return {
                ...state,
                success: false,
                loading: false,
                personas: []
            }
        case personas.UPDATE_PERSONAS_FAILED:
            return {
                ...state,
                success: false,
                loading: false,
                error: action.payload,
            }
        case personas.GET_SINGLEP_PERSONAS_REQUEST:
            return {
                ...state,
                success: false,
                loading: true,
                singlePersona: {}
            }
        case personas.GET_SINGLEP_PERSONAS_SUCCESS:
            return {
                ...state,
                success: false,
                loading: false,
                singlePersona: action.payload
            }
        case personas.GET_SINGLEP_PERSONAS_FAILED:
            return {
                ...state,
                success: false,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}

export default personasReducer