import login from './Images/login.png'
import signup from './Images/signup.png'
import userCreated from './logo/user_created.svg'
import homeLogo from './logo/logo__sq.png'
import arrow from './logo/right-arrow.svg'
import successLogged from './Images/success_logged.jpg'
import diamond from './Images/diamond.png'
import process1 from './Images/rectangle.png'
import process2 from './Images/process2.png'
import menu from './Images/menu.png'
import processDesign from './Images/process_.png'
import decision from './Images/decision.png'
import Brandlogo from './Images/Brandlogo.png'
import edit from './Images/edit.svg'
import newLogo from './logo/new__logo.svg'
import upload from './logo/newLogo.svg'
import largeIconForSize from './logo/L-24.svg'
import smallIconForSize from './logo/S-24.svg'
import sideLogo from './logo/side__logo.png';
import issue_ from './logo/issue_.svg';
import risk_ from './logo/risk_.svg';
import mediumIconForSize from './logo/M-24.svg'
import newHomeLogo from './logo/home__logo.png'
import updatedHomeLogo from './logo/logo.svg'
import tShirt from './logo/only__t__shirt.svg'
import smallLogo from './sidebar/logo.png'
import addUser from './sidebar/person-add.png'
import help from './sidebar/help.png'
import download from './sidebar/ci_download.png'
import kanban from './sidebar/view-kanban.png';
import Image from "./Images/landscape.jpeg"
import DownArrow from "./Images/downarrow.png"
import Access from "./Images/access.svg"
import uparrow from "./Images/up.ico"
import downarrow from "./Images/down.png"
import googlelogo from "./logo/logogoogle.png"
import files from "./Images/files.png"
import magic from "./logo/magic.png"
import linkImage from "./Images/link.svg";
import emptyImage from "./logo/empty.svg"

export const images = {
    edit,
    risk_,
    Image,
    issue_,
    upload,
    processDesign,
    updatedHomeLogo,
    decision,
    tShirt,
    Brandlogo,
    login,
    sideLogo,
    signup,
    newHomeLogo,
    userCreated,
    homeLogo,
    newLogo,
    successLogged,
    diamond,
    process2,
    process1,
    menu,
    arrow,
    files,
    largeIconForSize,
    mediumIconForSize,
    smallIconForSize,
    smallLogo,
    addUser,
    help,
    download,
    kanban,
    DownArrow,
    Access,
    uparrow,
    downarrow,
    googlelogo,
    magic,
    linkImage,
    emptyImage
}