import { ProfileConstants } from "../actions/constant";

const initState = {
    organizationProfile: [],
    loading: false,
    error: '',
    profileItem: {},
};

const profileReducer = (state = initState, action) => {
    switch (action.type) {
        case ProfileConstants.ORGANIZATION_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                organizationProfile: [],
                error: ''
            };
        case ProfileConstants.ORGANIZATION_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                organizationProfile: action.payload,
                error: ''
            };
        case ProfileConstants.ORGANIZATION_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                organizationProfile: [],
                error: action.payload
            };
        case ProfileConstants.GET_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                profileItem: action.payload
            };
        case ProfileConstants.GET_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profileItem: action.payload,
            };
        case ProfileConstants.DELETE_USER_SUCCESS:
            const filteredData = state.organizationProfile.filter(item => item.id !== action.payload);
            return {
                ...state,
                loading: false,
                organizationProfile: filteredData,
            };
        case ProfileConstants.PROFILE_NAME:
            return {
                ...state,
                profileItem: action.payload,
            };
        case ProfileConstants.PROFILE_AVATAR_REQUEST:
            return {
                ...state,
                profileItem: action.payload,
            };
        default:
            return state;
    }
};
export default profileReducer;
