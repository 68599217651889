import {
    Box,
    Button,
    Checkbox,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
    getAllProfileConfiguration,
    saveCofiguration,
} from "redux/actions/profileconfig.action";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SingleSquareBoxs } from "Utils/squares";
import { useEffect } from "react";
import { useCallback } from "react";
import { areObjectsMatching } from "Utils/squares";
import useCheckApply from "hooks/useCheckApply";

const Squares = ({ selectedProjects }) => {
    // redux states and dispatches
    const { user } = useSelector((state) => state.auth);

    const { configuration } = useSelector((state) => state.profileConfig);
    const dispatch = useDispatch();

    const { handleChange } = useCheckApply(user.id);
    const [valueChecked, setValueChecked] = useState(user?.applyToNew)
    // react states
    // for single and multi items
    const [visible, setVisible] = useState();

    // for getting project data
    const fetchProjectDetails = useCallback(
        async (projects) => {
            // console.log({ selectedProjects });
            const projectIds = projects
                .map(function (item) {
                    return item.value;
                })
                .join(",");
            dispatch(getAllProfileConfiguration(user?.id, projectIds));
        },
        [configuration]
    );

    // upper callback function
    useEffect(() => {
        if (selectedProjects.length > 0) {
            fetchProjectDetails(selectedProjects);
        }
    }, [selectedProjects]);

    // for getting status of projects
    const getProjectConfigurationStatus = useCallback((config) => {
        const matchOrNot = areObjectsMatching(config);
        if (matchOrNot === null) {
            setVisible(SingleSquareBoxs());
        } else if (matchOrNot === true) {
            setVisible(SingleSquareBoxs(config));
        } else if (matchOrNot === false) {
            setVisible(SingleSquareBoxs());
        }
    }, []);

    // upper callback function
    useEffect(() => {
        if (configuration.length == selectedProjects.length) {
            // console.log({ selectedProjects });
            getProjectConfigurationStatus(configuration);
        }
    }, [configuration]);

    const handleSetVisible = (data, id) => {
        setVisible((visi) =>
            visi.map((item) => {
                if (id === item.id) {
                    item.visible = data;
                }
                return item;
            })
        );
    };

    const handleApplyConfig = () => {
        const newconfig = {};
        visible.forEach((item) => {
            newconfig[item.name] = item.visible;
        });

        const projects = selectedProjects?.map((item, index) => {
            const newitem = {
                customBoard: newconfig.customBoard,
                journeyMap: newconfig.journeyMap,
                kanban: newconfig.kanban,
                latestOne: true,
                persona: newconfig.persona,
                problemStatement: newconfig.problemStatement,
                projectId: item.value,
                riskIssues: newconfig.riskIssues,
                userId: user.id,
                workflow: newconfig.workflow,
            };
            return newitem;
        });

        dispatch(saveCofiguration(projects));

        handleChange(user?.id, valueChecked)
    };

    return (
        <Paper elevation={1}>
            <Box padding={3}>
                <Typography align="center" variant="h5">
                    Squares
                </Typography>
                <Grid container>
                    {visible?.map((item) => (
                        <Grid
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                            xs={6}
                            key={item.id}
                            item
                        >
                            <Button
                                variant="contained"
                                color={item.visible ? "primary" : "default"}
                                size="large"
                                onClick={() => handleSetVisible(!item.visible, item.id)}
                                style={{ margin: "8px 0px", minWidth: "200px" }}
                                endIcon={item.visible ? <Visibility /> : <VisibilityOff />}
                            >
                                {item.title}
                            </Button>
                        </Grid>
                    ))}
                </Grid>

                <Box
                    display={`flex`}
                    justifyContent={`center`}
                    alignItems={`center`}
                    marginTop={2}
                    marginBottom={2}
                >
                    <Typography align="center">Apply to new projects​</Typography>
                    <Checkbox
                        // defaultChecked
                        color="primary"
                        // value={singleCheck}
                        checked={valueChecked}
                        onChange={(e) => setValueChecked(e.target.checked)}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                </Box>
                <Box display={"flex"} justifyContent={`center`}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ margin: "8px 0px", minWidth: "200px" }}
                        onClick={() => handleApplyConfig()}
                    >
                        Apply
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default Squares;
