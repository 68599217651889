import toast from "react-hot-toast";
import { subDays, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { nanoid } from "nanoid";
import Avatars from "assets/Avatars";

export function edgeArrowId(source, target) {
  return `${source}>${target}`;
}
export function saveFlow(elements, nanoid, setOpenMenuClick) {
  const downloadLink = document.createElement("a");
  const fileBlob = new Blob([JSON.stringify(elements, null, 2)], {
    type: "application/json",
  });
  downloadLink.href = URL.createObjectURL(fileBlob);
  downloadLink.download = "square-bear-flow" + nanoid(3) + ".json";
  downloadLink.click();
  setOpenMenuClick(false);
}
export function removeHtmlTags(input) {
  return input.replace(/<\/?[^>]+(>|$)/g, "");
}
export function reduceMultipleSpaces(str) {
  // Use a regular expression to replace multiple spaces with a single space
  // and also handle multiple spaces at the start of the string
  return str.replace(/^\s+|\s+$/g, "").replace(/\s+/g, " ");
}
export const getLog = (loggedData, warning) => {
  // console.log({ loggedData })
  if (warning) {
    // console.log(
    //     `%cWARNING!${warning}`,
    //     `color:red; font-size:40px`
    // );
  }
};
export const getSingleAvatar = (data) => {
  let newData;
  Object.keys(Avatars).find((key) => {
    if (key === data) {
      newData = `${Avatars[data]}`;
      return newData;
    }
    return newData;
  });
  return newData;
};

export function toJSON(elements) {
  const downloadLink = document.createElement("a");
  const fileBlob = new Blob([JSON.stringify(elements, null, 2)], {
    type: "application/json",
  });
  downloadLink.href = URL.createObjectURL(fileBlob);
  downloadLink.download = `square-bear-flow${nanoid(4)}.json`;
  downloadLink.click();
}
export function WordCount(str) {
  return str.split(" ");
}
//WordCountLength
export function WordCountLength(str) {
  return str.split(" ").length;
}
//SUCCESS ALERT
export const successAlert = (message) => message && toast.success(message);
// Error Alert
export const errorAlert = (error) => error && toast.error(error);
//SHOWING ERROR MESSAGE
export const handleErrorMessage = (err) =>
  err.response && (err.response.data.message || err.response.data.error)
    ? err.response.data.message || err.response.data.error
    : err.message || err.error;
//DOWNLOAD__CSV
export function downloadFile(fileName, urlData) {
  var aLink = document.createElement("a");
  aLink.download = fileName;
  aLink.href = urlData;
  var event = new MouseEvent("click");
  aLink.dispatchEvent(event);
}
export function handleDownloadTheCSVFromBackend(fileName, urlData) {
  var aLink = document.createElement("a");
  aLink.download = fileName;
  aLink.href = urlData;
  var event = new MouseEvent("click");
  aLink.dispatchEvent(event);
}
export function generateCSVState(
  elements,
  valueStream,
  persona,
  dispatch,
  sendValueStreamAndDiagramForCSV,
  projectId,
  project,
  nanoid,
  nullifiedCSV
) {
  if (elements) {
    const newValueStream = valueStream.map((item) => {
      return elements.filter(
        (item1) => item1.data.valueStreamId === item.id && item1
      );
    });
    const newValueStreamFilter = valueStream.map((item, index) => {
      const newObj = {
        valueStreamId: item.id,
        elementsDtoList: newValueStream[index],
        valueStreamName: item.valueStreamName,
        valueStreamWhy: item.valueStreamWhy,
        persona: persona?.name,
      };
      return newObj;
    });
    dispatch(
      sendValueStreamAndDiagramForCSV(
        projectId,
        newValueStreamFilter,
        project?.project?.name + nanoid(2)
      )
    );
    dispatch(nullifiedCSV());
  }
}

export function isoToUtcDate(date) {
  if (!date) {
    return "";
  }
  return date.utc().format("MM/DD/YYYY");
}

export const getSingleAcceptanceCriteria = (text) => {
  if (text) {
    const firstSlpit = text
      .split("*Given*")
      .join("<strong><br/>Given</strong>");
    const secondSlpit = firstSlpit
      .split("*When*")
      .join("<strong><br/>When</strong>");
    const preBeforeFinalText = secondSlpit
      .split("*And given*")
      .join("<strong><br/>And given</strong>");
    const beforeFinalText = preBeforeFinalText
      .split("*Then*")
      .join("<strong><br/>Then</strong>");
    const finalText = beforeFinalText
      .split("*And*")
      .join("<strong><br/>And</strong>");
    return finalText;
  }
};
export const redirectUrl = (domHostname, url) => {
  // console.log(domHostname)
  let hostEnv;
  if (domHostname === "localhost:3000") {
    hostEnv = `http://${domHostname}/${url}?secretKey=`;
  } else {
    hostEnv = `https://${domHostname}/${url}?secretKey=`;
  }
  return hostEnv;
};
export const changeArrayPositionByIndexNumber = (
  removedIndex,
  newIndex,
  value,
  array
) => {
  let updatedArray = array.filter((v, i) => i !== removedIndex);
  updatedArray.splice(newIndex, 0, value);
  return updatedArray;
};
// let array = [
//     { id: 1, title: 'box-1', values: ['law', 'sosa', 'sim', 'begun'] },
//     { id: 2, title: 'box-2', values: ['tomato'] },
//     { id: 3, title: 'box-3', values: [] },
//     { id: 4, title: 'box-4', values: [] },
// ];

export const withHttps = (url) => {
    const checkedUrl = url && url.includes("http" || "https");
    const updatedUrl = checkedUrl ? url : `https://${url}`
    return updatedUrl;
}

export function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}
export function addDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}
export const checkduplicity = (arrayData) => {
  const itemsData = arrayData.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      arrayData.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  return itemsData;
};
export const getItemLabel = (arr, newData) => {
  let newD;
  newD = arr.map((item) => {
    if (item.laneId === newData.id) {
      item.laneName = newData.label;
    }
    return item;
  });
  return newD;
};

export function check(
  currentObjectID,
  targectObjectID,
  currentArrayValue,
  targetArrayIndex,
  array
) {
  let currentArrayIndex = array
    .find((v) => v.id === currentObjectID)
    .values.findIndex((v) => v === currentArrayValue);

  if (
    currentObjectID !== targectObjectID ||
    currentArrayIndex !== targetArrayIndex
  ) {
    if (currentObjectID !== targectObjectID) {
      let removedArray = array
        .find((v) => v.id === currentObjectID)
        .values.filter((v) => v !== currentArrayValue);

      let addedArray = array.find((v) => v.id === targectObjectID).values;
      addedArray.splice(targetArrayIndex, 0, currentArrayValue);

      let modifiedArray = array.map((v) =>
        v.id === currentObjectID ? { ...v, values: [...removedArray] } : v
      );
      return { changed: true, data: modifiedArray };
    } else {
      let modified = array
        .find((v) => v.id === currentObjectID)
        .values.filter((v) => v !== currentArrayValue);
      modified.splice(targetArrayIndex, 0, currentArrayValue);
      let modifiedArray = array.map((v) =>
        v.id === currentObjectID ? { ...v, values: [...modified] } : v
      );
      return { changed: true, data: modifiedArray };
    }
  } else {
    return { changed: false, data: array };
  }
}
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
// let { changed, data } = check(2, 2, 'tomato', 0, array);
// // console.log(data);
// // console.log(changed);

export const pricingCountData = (users) => {
  const getAllData = {};
  let currentUserMoney;
  const lengthOfUsers = users.length;
  if (lengthOfUsers) {
    if (lengthOfUsers === 1) {
      currentUserMoney = 10;
    } else if (lengthOfUsers === 2) {
      currentUserMoney = 20;
    } else if (lengthOfUsers >= 3) {
      const restMoney = lengthOfUsers * 20;
      currentUserMoney = restMoney - 20;
    }
  } else {
    currentUserMoney = 0;
  }
  getAllData.nowAddingUsers = lengthOfUsers;
  getAllData.amountToBePaid = currentUserMoney;
  getAllData.totalPaid = currentUserMoney;
  return getAllData;
};

export const _capitalName = (input) => {
  let words = input.split(" ");
  let CapitalizedWords = [];
  if (input === "Pending") {
    return "Take Action";
  } else {
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element?.length)
      );
    });
    return CapitalizedWords.join(" ");
  }
};
export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item.value,
    };
  }, initialValue);
};

export const getFileType = (file) => {
  // "image/*,application/pdf,.doc,.docx,.ppt,.xls,.xlsx,.zip,.csv,.tsv,.txt,.ppt,.pptx,.pages,.odt,.rtf",
  // "video/*,.mp4,.mkv,.avi,.webm",
};

export const multidates = [
  {
    number: 1,
    date: formatInTimeZone(new Date(), "Europe/Paris", "dd-MM-yyyy"),
  },
  {
    number: 7,
    date: formatInTimeZone(
      subDays(new Date(), 7),
      "Europe/Paris",
      "dd-MM-yyyy"
    ),
  },
  {
    number: 14,
    date: formatInTimeZone(
      subDays(new Date(), 14),
      "Europe/Paris",
      "dd-MM-yyyy"
    ),
  },
  {
    number: 30,
    date: formatInTimeZone(
      subDays(new Date(), 30),
      "Europe/Paris",
      "dd-MM-yyyy"
    ),
  },
];

export function htmlToText(html, extensions) {
  var text = html;

  if (extensions && extensions["preprocessing"])
    text = extensions["preprocessing"](text);

  text = text
    // Remove line breaks
    .replace(/(?:\n|\r\n|\r)/gi, " ")
    // Remove content in script tags.
    .replace(/<\s*script[^>]*>[\s\S]*?<\/script>/gim, "")
    // Remove content in style tags.
    .replace(/<\s*style[^>]*>[\s\S]*?<\/style>/gim, "")
    // Remove content in comments.
    .replace(/<!--.*?-->/gim, "")
    // Remove !DOCTYPE
    .replace(/<!DOCTYPE.*?>/gi, "");

  /* I scanned http://en.wikipedia.org/wiki/HTML_element for all html tags.
    I put those tags that should affect plain text formatting in two categories:
    those that should be replaced with two newlines and those that should be
    replaced with one newline.

    I process <DEL> and <INS> as inline elements.
    http://www.w3.org/TR/1999/REC-html401-19991224/struct/text.html#h-9.4
    "These two elements are unusual for HTML in that they may serve as either
    block-level or inline elements (but not both). They may contain one or more
    words within a paragraph or contain one or more block-level elements such
    as paragraphs, lists and tables." */

  if (extensions && extensions["tagreplacement"])
    text = extensions["tagreplacement"](text);

  var doubleNewlineTags = [
    "p",
    "h[1-6]",
    "dl",
    "dt",
    "dd",
    "ol",
    "ul",
    "dir",
    "address",
    "blockquote",
    "center",
    "div",
    "hr",
    "pre",
    "form",
    "textarea",
    "table",
  ];

  var singleNewlineTags = [
    "li",
    "fieldset",
    "legend",
    "tr",
    "th",
    "caption",
    "thead",
    "tbody",
    "tfoot",
  ];

  for (let i = 0; i < doubleNewlineTags.length; i++) {
    var r = RegExp("</?\\s*" + doubleNewlineTags[i] + "[^>]*>", "ig");
    text = text.replace(r, "\n\n");
  }

  for (let i = 0; i < singleNewlineTags.length; i++) {
    var r = RegExp("<\\s*" + singleNewlineTags[i] + "[^>]*>", "ig");
    text = text.replace(r, "\n");
  }

  // Replace <br> and <br/> with a single newline
  text = text.replace(/<\s*br[^>]*\/?\s*>/gi, "\n");

  text = text
    // Remove all remaining tags.
    .replace(/(<([^>]+)>)/gi, "")
    // Trim rightmost whitespaces for all lines
    .replace(/([^\n\S]+)\n/g, "\n")
    .replace(/([^\n\S]+)$/, "")
    // Make sure there are never more than two
    // consecutive linebreaks.
    .replace(/\n{2,}/g, "\n\n")
    // Remove newlines at the beginning of the text.
    .replace(/^\n+/, "")
    // Remove newlines at the end of the text.
    .replace(/\n+$/, "")
    // Decode HTML entities.
    .replace(/&([^;]+);/g, decodeHtmlEntity);

  if (extensions && extensions["postprocessing"])
    text = extensions["postprocessing"](text);

  return text;
}

function decodeHtmlEntity(m, n) {
  // Determine the character code of the entity. Range is 0 to 65535
  // (characters in JavaScript are Unicode, and entities can represent
  // Unicode characters).
  var code;

  // Try to parse as numeric entity. This is done before named entities for
  // speed because associative array lookup in many JavaScript implementations
  // is a linear search.
  if (n.substr(0, 1) == "#") {
    // Try to parse as numeric entity
    if (n.substr(1, 1) == "x") {
      // Try to parse as hexadecimal
      code = parseInt(n.substr(2), 16);
    } else {
      // Try to parse as decimal
      code = parseInt(n.substr(1), 10);
    }
  } else {
    // Try to parse as named entity
    code = ENTITIES_MAP[n];
  }

  // If still nothing, pass entity through
  return code === undefined || code === NaN
    ? "&" + n + ";"
    : String.fromCharCode(code);
}

var ENTITIES_MAP = {
  nbsp: 160,
  iexcl: 161,
  cent: 162,
  pound: 163,
  curren: 164,
  yen: 165,
  brvbar: 166,
  sect: 167,
  uml: 168,
  copy: 169,
  ordf: 170,
  laquo: 171,
  not: 172,
  shy: 173,
  reg: 174,
  macr: 175,
  deg: 176,
  plusmn: 177,
  sup2: 178,
  sup3: 179,
  acute: 180,
  micro: 181,
  para: 182,
  middot: 183,
  cedil: 184,
  sup1: 185,
  ordm: 186,
  raquo: 187,
  frac14: 188,
  frac12: 189,
  frac34: 190,
  iquest: 191,
  Agrave: 192,
  Aacute: 193,
  Acirc: 194,
  Atilde: 195,
  Auml: 196,
  Aring: 197,
  AElig: 198,
  Ccedil: 199,
  Egrave: 200,
  Eacute: 201,
  Ecirc: 202,
  Euml: 203,
  Igrave: 204,
  Iacute: 205,
  Icirc: 206,
  Iuml: 207,
  ETH: 208,
  Ntilde: 209,
  Ograve: 210,
  Oacute: 211,
  Ocirc: 212,
  Otilde: 213,
  Ouml: 214,
  times: 215,
  Oslash: 216,
  Ugrave: 217,
  Uacute: 218,
  Ucirc: 219,
  Uuml: 220,
  Yacute: 221,
  THORN: 222,
  szlig: 223,
  agrave: 224,
  aacute: 225,
  acirc: 226,
  atilde: 227,
  auml: 228,
  aring: 229,
  aelig: 230,
  ccedil: 231,
  egrave: 232,
  eacute: 233,
  ecirc: 234,
  euml: 235,
  igrave: 236,
  iacute: 237,
  icirc: 238,
  iuml: 239,
  eth: 240,
  ntilde: 241,
  ograve: 242,
  oacute: 243,
  ocirc: 244,
  otilde: 245,
  ouml: 246,
  divide: 247,
  oslash: 248,
  ugrave: 249,
  uacute: 250,
  ucirc: 251,
  uuml: 252,
  yacute: 253,
  thorn: 254,
  yuml: 255,
  quot: 34,
  amp: 38,
  lt: 60,
  gt: 62,
  OElig: 338,
  oelig: 339,
  Scaron: 352,
  scaron: 353,
  Yuml: 376,
  circ: 710,
  tilde: 732,
  ensp: 8194,
  emsp: 8195,
  thinsp: 8201,
  zwnj: 8204,
  zwj: 8205,
  lrm: 8206,
  rlm: 8207,
  ndash: 8211,
  mdash: 8212,
  lsquo: 8216,
  rsquo: 8217,
  sbquo: 8218,
  ldquo: 8220,
  rdquo: 8221,
  bdquo: 8222,
  dagger: 8224,
  Dagger: 8225,
  permil: 8240,
  lsaquo: 8249,
  rsaquo: 8250,
  euro: 8364,
};
