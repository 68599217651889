export const ProblemStatement = ({ statement }) => {
  return (
    <div>
      <p style={{ fontSize: "18px", wordBreak: "break-word" }}>
        I am <strong>{statement[0]?.userName}</strong>. I am trying to
        <strong> {statement[0]?.userNeeds}</strong>. But
        <strong> {statement[0]?.userProblem}</strong>. Because
        <strong> {statement[0]?.rootCause}</strong> which makes me feel
        <strong> {statement[0]?.userEmotion}</strong>
      </p>
    </div>
  );
};
