import { Box, Button, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { Container } from "react-trello";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { images } from "assets";

import { useSelector } from "react-redux";
import {
    handleChangeUserProjectId,
    getProjectByUserId,
    freePrevPersona,
    freePrevData
} from "redux/actions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import simpleaudio from "../../assets/audio/simple.wav"
import { useState } from "react";
import { Accordion } from "@material-ui/core";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/**
 *@function RightSideComp.jsx
 *@author Shahed
 *
 **/

const RightSideComp = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const projectData = useSelector(state => state.projects)
    const auth = useSelector((state) => state.auth);
    const history = useHistory()
    const [play, setPlay] = useState(JSON.parse(localStorage.getItem("song")))
    const [createdby, setCreatedBy] = useState([])
    const [expanded, setExpanded] = useState(true)

    const handleChangeAndGoIntoHome = (item) => {
        dispatch(freePrevPersona());
        dispatch(freePrevData());
        dispatch(handleChangeUserProjectId(item));
        history.push("/");
    };

    useEffect(() => {
        const abort = new AbortController()
        if (auth?.user?.id && auth?.authenticate) {
            dispatch(getProjectByUserId(auth.user.id))
        }
        return () => {
            abort.abort()
        }
    }, [auth])

    useEffect(() => {
        const audioEl = document.getElementById("audiosample");
        if(audioEl != null){

            audioEl.play().catch((error) => {
                document.addEventListener("click", () => {
                    audioEl.play()
                })
            })
        }
    }, [])

    const handlePlay = () => {
        setPlay(!play)
        window.localStorage.setItem("song", !play)
    }

    const fetchProjectList = useCallback(() => {
        if (projectData && projectData.project && projectData.project.length > 0) {
            const createbyuser = projectData.project.map(value => value?.created_by)
            setCreatedBy(createbyuser.filter((v, i, a) => a.findIndex(v2 => (v2?.id === v?.id)) === i))
        }
    }, [projectData])


    useEffect(() => {
        fetchProjectList()
    }, [fetchProjectList])


    return (
        <Container>
            {projectData?.project?.length > 9 ?
                <Box>
                    <Typography
                        variant="subtitle1"
                        className={classes.textverticle}
                    >
                        Scroll Down
                        <img
                            src={images.DownArrow}
                            alt="downarrow"
                        />
                    </Typography>
                </Box>
                : null}
            <Box className={classes.rightside}>

                {createdby?.length > 0 ?
                    <Box className={classes.insiderClass}>

                        {createdby?.map((item, index) => {
                            return (
                                <Accordion key={index} className={classes.customaccordio} expanded={expanded === true} onChange={() => setExpanded(!expanded)} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography style={{ textTransform: "capitalize" }}>{item?.name}</Typography>
                                    </AccordionSummary>
                                    {projectData?.project.filter(value => value?.created_by?.id === item?.id).map((item, index) =>
                                        <AccordionDetails key={index} className={classes.customAccordioDetail}>
                                            <Button
                                                key={index}
                                                variant="contained"
                                                color="primary"
                                                className={classes.customButton}
                                                onClick={() => handleChangeAndGoIntoHome(item)}>
                                                {item?.name.slice(0, 15) + `${item?.name.length > 15 ? "..." : ''}`}
                                            </Button>
                                        </AccordionDetails>
                                    )}
                                </Accordion>
                            )
                        })}

                    </Box>
                    :
                    <Box className={classes.noproject}>
                        <Typography variant="h6" className={classes.head}>No Project Found</Typography>
                        <Typography variant="h6" className={classes.description}>Let's get started by creating a new project</Typography>
                    </Box>
                }

                <Box className={classes.lastClass}>
                    {/* <audio src={simpleaudio} autoPlay controls={false} loop muted={play} id="audiosample"></audio> */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={() => handlePlay()}
                                defaultChecked={play}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="checkedI"
                            />
                        }
                        label="Mute Intro Song"
                    />
                </Box>

            </Box>
        </Container>
    );
};

export default RightSideComp;


const useStyles = makeStyles((theme) => ({
    rightside: {
        backgroundColor: "#E8E9F5",
        height: "550px"
    },
    insiderClass: {
        position: "relative",
        top: "53px",
        left: "25px",
        // transform: "translateX(-48px)",
        height: "450px",
        overflow: "hidden",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
    },
    ".MuiAccordion-root .Mui-expanded": {
        margin: "0px !important"
    },
    customaccordio: {
        width: "85%",
        borderRadius: "4px !important",
        margin: "0px !important",
        marginBottom: "7px !important"
    },
    customAccordioDetail: {
        paddingTop: ".2rem",
        paddingBottom: ".7rem",
    },
    customButton: {
        width: "100%",
        // marginBottom: ".7rem"
    },
    lastClass: {
        position: "absolute",
        bottom: "0px",
        left: "148px"
    },
    noproject: {
        position: "relative",
        top: "40%",
    },
    head: {
        fontSize: "18px",
        textAlign: "center"
    },
    description: {
        fontSize: "13px",
        textAlign: "center"
    },
    textverticle: {
        writingMode: "vertical-rl",
        position: 'absolute',
        top: "53px",
        right: "0px",
        fontSize: "12px",
        fontWeight: "normal"
    },
}))