import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";
import { nanoid } from "nanoid";
import {
  bugsColumns,
  issueColumns,
  riskColumns,
  tasksColumns,
} from "assets/Data/BoardsData/taskDataGrid";
import AddIconForGrid from "Components/FlowComponents/AddIconForGrid";

export default function TaskDataGrid({ rows, setTasks, name, type }) {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(0);

  const renderOpenClickWayListener = React.useCallback(() => {
    const id = type
      ? `sqbear_risk_and_issue_${nanoid(15)}`
      : `node_process_${nanoid(15)}`;
    const data = { id };
    data[name] = "";
    setTasks((prev) => {
      const newItems = [...prev, data];
      // If more than 10 items, navigate to the next page
      if (newItems.length > (currentPage + 1) * 10) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
      return newItems;
    });
  }, [currentPage, type, name, setTasks]);

  //delete method to delete the work items
  const handleDeleteItem = (id) => {
    const filteredRows = rows.filter((item) => item.id !== id);
    setTasks(filteredRows);

    if (filteredRows.length <= currentPage * 10 && currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // for going back to previous or next items
  React.useEffect(() => {
    if (rows.length <= currentPage * 10 && currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [rows.length, currentPage]);

  //edit method to edit the work items
  const handleEditItem = (id, value, name) => {
    let allTableDataa = [...rows];
    const index = allTableDataa.findIndex((info) => info.id === id);
    allTableDataa[index][value] = name;
  };
  const newSetRow = [...new Set(rows)];
  const switchLabel = (type) => {
    switch (type) {
      case "choresLabel":
        return tasksColumns;
      case "bugsLabel":
        return bugsColumns;
      default:
        return tasksColumns;
    }
  };
  const switchTitle = (type) => {
    switch (type) {
      case 0:
        return riskColumns;
      case 1:
        return issueColumns;
      default:
        return riskColumns;
    }
  };
  const addIconParse = {
    classes,
    renderOpenClickWayListener,
    title: "add a new task",
  };
  const onCellEdit = (row, e) => {
    if (row.id && row.field) {
      handleEditItem(row.id, row.field, row.props.value);
      if (e.code === "Enter") {
        renderOpenClickWayListener();
      } else {
        return;
      }
    }
  };
  const onCellClick = (row) => {
    if (row.id && row.field === "Delete") {
      handleDeleteItem(row.id);
    }
    if (row.id && row.field === "Add") {
      renderOpenClickWayListener();
    }
  };
  return (
    <div className={classes.rootOfProcessNode}>
      <DataGrid
        autoHeight={true}
        rows={newSetRow}
        columns={name === "title" ? switchTitle(type) : switchLabel(name)}
        isCellEditable={() => true}
        // onCellEditCommit={(row, e) => onCellEdit(row, e)}
        onEditCellPropsChange={(props, e) => onCellEdit(props, e)}
        page={currentPage}
        onPageChange={(params) => setCurrentPage(params.page)}
        disableColumnMenu
        pageSize={10}
        onCellClick={(row) => onCellClick(row)}
      />
      {rows.length === 0 && <AddIconForGrid {...addIconParse} />}
    </div>
  );
}
const useStyles = makeStyles({
  rootOfProcessNode: {
    width: 1040,
    "& .MuiDataGrid-cell": {
      border: "1px solid #d4cfcf",
      borderRadius: "8px",
    },
  },
  buttonInDataGrid: {
    position: "relative",
  },
  addButton: {
    position: "absolute",
    color: "red",
    zIndex: 93,
    bottom: 5,
    left: 5,
  },
});
