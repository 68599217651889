

export const personaData = [
    {
        name: "What do they need to DO?",
        description: JSON.stringify("<p><strong>Some questions to consider here are:</strong></p><ol><li>What do they need to do differently?</li><li>What job(s) do they want or need to get done?</li><li>What decision(s) do they need to make?</li><li>How will we know they were successful?</li></ol>"),
        backColor: "#FAFAFF",
        sideColor: "#7981FF"
    },
    {
        name: "What do they SEE?",
        description: JSON.stringify("<p><strong>Some questions to consider here are:</strong></p><ol><li>What do they see in the marketplace?</li><li>What do they see in their immediate environment?</li><li>What do they see others saying and doing?</li><li>What are they watching and reading?</li></ol>"),
        backColor: "#fffcf1",
        sideColor: "#d3a501"
    },
    {
        name: "What do they say?",
        description: JSON.stringify("<p> <strong>Some questions to consider here are:</strong></p> <ol><li>What have we heard them say?</li><li>What can we imagine them saying?</li></ol>"),
        backColor: "#f1fcff",
        sideColor: "#01d3d3"

    },
    {
        name: "What do they DO?",
        description: JSON.stringify("<p><strong>Some questions to consider here are:</strong></p><ol><li>What do they do today?</li><li>What behaviour have we observed?</li><li>What can we imagine them doing?</li></ol>"),
        backColor: "#dbfff3",
        sideColor: "#1f9e73"
    },

    {
        name: "What do they HEAR?",
        description: JSON.stringify("<p><strong>Some questions to consider here are?</strong></p><ol><li>What are they hearing others say?</li><li>What are they hearing from friends?</li><li>What are they hearing from colleagues?</li><li>What are they hearing second-hand?</li></ol>"),
        
        backColor: "#fff5f5",
        sideColor: "#9e7b1f"

    },
    {
        name: "What do they thing and feel? :PAINS",
        description: JSON.stringify("<p><strong>Some questions to consider here are:</strong></p><ol><li>What are their fears, frustrations, and anxieties?</li><li>What other thoughts and feelings might motivate their behaviour?</li></ol>"),
        backColor: "#fff4f7",
        sideColor: "#ec1e4d"

    },
    {
        name: "What do they think and feel? :GAINS",
        description: JSON.stringify("<p><strong>Some questions to consider here are:</strong></p><ol><li>What are their wants, needs, hopes and dreams?</li><li>What other thoughts and feelings might motivate their behaviour?</li></ol>"),
        backColor: "#d7e6f9",
        sideColor: "#1e287c"

    },
    
    
]