const appendedBlockers = [
  { label: "creative", value: "node_process_Lh1L8RsafJbUas" },
  { label: "Azim__bro", value: "node_process_LhL1s8RaafJbUas" },
];
const rawContent = {
  blocks: [
    {
      key: "5giiu",
      text: "m",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
};
const initialBoardAssesment = [
  {
    id: 1,
    placeholder: "Reach",
    name: "reach",
    impact: 1,
    value: "",
    buttonText: "low",
  },
  {
    id: 2,
    placeholder: "Impact",
    name: "impact",
    impact: 1,
    value: "",
    buttonText: "low",
  },
  {
    id: 3,
    placeholder: "Confidence",
    name: "confidence",
    impact: 1,
    value: "",
    buttonText: "low",
  },
];
const impactBasedOnColor = [
  {
    id: 1,
    title: "Low",
    color: "green",
    impactKey: 1,
  },
  {
    id: 2,
    title: "Medium",
    color: "#FFAB40",
    impactKey: 2,
  },
  {
    id: 3,
    title: "High",
    impactKey: 3,
    color: "red",
  },
];
const initialState = "<h4>Given:</h4><h4>When:</h4><h4>Then:</h4>";
const initialFeatureState = (personas) =>
  `<h4>As ${
    personas ?? "user"
  }:</h4><h4>I want to do the: </h4><h4>so that I can:</h4>`;
export const boarsdData = {
  appendedBlockers,
  rawContent,
  initialState,
  initialBoardAssesment,
  impactBasedOnColor,
  initialFeatureState,
};
