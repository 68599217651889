import { Fragment } from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import { HookFormTextField, MUIAutoComplete } from "..";

/**
 *@function FormsGrouped.jsx
 *@author Azim
 *
 **/

const FormsGrouped = (props) => {
  const { mapedForm, control, errors } = props;
  return (
    <Box>
      {mapedForm.map((item, index) => (
        <Fragment key={index}>
          <br />
          {item.formLabel && <label>{item.formLabel}</label>}
          {item.type !== "autocomplete" && (
            <HookFormTextField control={control} {...item} errors={errors} />
          )}
        </Fragment>
      ))}
      <Grid container spacing={2}>
        {mapedForm.map(
          (item, index) =>
            item.type === "autocomplete" && (
              <Grid key={index} item xs={12} md={6} lg={6}>
                <MUIAutoComplete
                  control={control}
                  errors={errors}
                  filterSelectedOptions
                  limitTags={item.limitTags}
                  name={item.name}
                  options={item.options}
                  value={item.value}
                  renderOption={(option, { selected }) => (
                    <div
                      style={{
                        width: "100%",
                        background: option.color ? option.color : "#67c6c0",
                      }}
                    >
                      <p
                        style={{
                          color: "black",
                          marginLeft: "10px",
                        }}
                      >
                        {option.label}
                      </p>
                    </div>
                  )}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label={item.label}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )
        )}
      </Grid>
    </Box>
  );
};

export default FormsGrouped;
