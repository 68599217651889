import { diagramConstant } from "../actions/constant";

const initState = {
  workflows: [],
  decisionNodeInitialData: [],
  csvData: null,
  persona: {},
  flowOverview: []
};

const workflowReducer = (state = initState, action) => {
  switch (action.type) {

    case diagramConstant.ADDEDGE:
      return {
        ...state,
      };
    case diagramConstant.CREATE_NEW_WORKFLOW:
      return {
        ...state,
        workflows: [...state.workflows, action.payload]
      };
    case diagramConstant.UPDATE_NEW_WORKFLOW:
      return {
        ...state,
        workflows: [action.payload]
      };
    case diagramConstant.GET_ALL_WORKFLOW_DETAILS:
      return {
        ...state,
        flowOverview: action.payload
      };
    case diagramConstant.EMPTY_ALL_WORKFLOW_DETAILS:
      return {
        ...state,
        flowOverview: []
      };
    case diagramConstant.GET_ALL_WORKFLOW:
      return {
        ...state,
        workflows: action.payload
      };
    case diagramConstant.DELETE_A_WORKFLOW:
      return {
        ...state,
        workflows: action.payload
      };
    case diagramConstant.ADD_VALUE_STREAM_FLOW:
      return {
        ...state,
        workflows: action.payload
      };
    case diagramConstant.CLONE_A_WORKFLOW:
      return {
        ...state,
        workflows: [...state.workflows, action.payload]
      };
    case diagramConstant.UPDATE_VALUE_STREAM:
      return {
        ...state,
        workflows: action.payload
      };
    case diagramConstant.DELETE_VALUE_STREAM:
      return {
        ...state,
        workflows: action.payload
      };
    case diagramConstant.EMPTY_DIAGRAM:
      return {
        ...state,
        initialElements: {}
      };
    case diagramConstant.GET_VALUE_STREAM:
      return {
        ...state,
        valueStream: [...action.payload]
      };
    case diagramConstant.FOR_CSV_WITH_VALUE_STREAM:
      return {
        ...state,
        csvData: action.payload
      };
    case diagramConstant.DELETE_CSV:
      return {
        ...state,
        csvData: null
      };
    case diagramConstant.GET_PERSONA_DATA:
      return {
        ...state,
        persona: action.payload
      };
    case diagramConstant.ADD_NEW_PERSONA:
      return {
        ...state,
        persona: { ...action.payload },

      };
    case diagramConstant.UPDATE_PERSONA_DATA:
      return {
        ...state,
        persona: action.payload
      };

    case diagramConstant.ADD_NEW_FORM_NODE_DATA:
      return {
        ...state,
        decisionNodeInitialData: [...state.decisionNodeInitialData, action.payload]
      };
    case diagramConstant.LOAD_TABLE_DATA_FROM_BACKEND:
      return {
        ...state,
        decisionNodeInitialData: action.payload
      };
    case diagramConstant.UPDATE_NEW_FORM_NODE_DATA:
      let allTableDataa = [...state.decisionNodeInitialData];
      const index = allTableDataa.findIndex((info) => (info.id === action.payload.id));
      allTableDataa[index][action.payload.value] = action.payload.name;
      return {
        ...state,
        decisionNodeInitialData: allTableDataa
      };
    case diagramConstant.CLOSE_FORM_NODE_DATA:
      return {
        ...state,
        decisionNodeInitialData: []
      };
    case diagramConstant.DELETE_NEW_FORM_NODE_DATA:
      let allTableData = [...state.decisionNodeInitialData];
      return {
        ...state,
        decisionNodeInitialData: allTableData.filter((info) => info.id !== action.payload)
      };

    case diagramConstant.GET_WORKFLOW_REQUEST:
      return {
        ...state,
        flowOverview: []
      }

    default:
      return state;
  }
};
export default workflowReducer;
