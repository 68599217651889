import axios from 'api/axios';
import { errorAlert, handleErrorMessage } from 'Utils';
import { diagramConstant } from './constant';

export const getAllWorkFlowByProjectId = (projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get("/v1/projects/" + projectId + "/workflows");
                dispatch({
                    type: diagramConstant.GET_ALL_WORKFLOW,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return error.response
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const createWorkFlowByProjectId = (data, projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.post("/v1/projects/" + projectId + "/workflows", data);
                dispatch({
                    type: diagramConstant.CREATE_NEW_WORKFLOW,
                    payload: res.data
                })
            }
        } catch (error) {

            if (error?.response?.status === 401) {
                return error.response
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const updateWorkFlow = (data, projectId, workflowId) => {
    return async (dispatch) => {
        try {
            if (workflowId) {
                const res = await axios.put("/v1/projects/" + projectId + "/workflows/" + workflowId, data);
                // console.log({ res })
                dispatch({
                    type: diagramConstant.UPDATE_NEW_WORKFLOW,
                    payload: res.data
                })
            }
        } catch (error) {

            if (error?.response?.status === 401) {
                return error.response
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const deleteWorkFlowById = (workflowId, projectId) => {
    return async (dispatch, getState) => {
        try {
            if (projectId) {
                await axios.delete("/v1/projects/" + projectId + "/workflows/" + workflowId);
                const filteredItem = getState()?.workflow?.workflows.filter(item => item.id !== workflowId);
                dispatch({
                    type: diagramConstant.DELETE_A_WORKFLOW,
                    payload: filteredItem
                })
            }
        } catch (error) {

            if (error?.response?.status === 401) {
                return error.response
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const copyWorkFlowById = (workflowId, projectId, personaId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.post("/v1/projects/" + projectId + "/workflows/" + workflowId + "/" + personaId);
                dispatch({
                    type: diagramConstant.CLONE_A_WORKFLOW,
                    payload: res.data
                })
            }
        } catch (error) {

            if (error?.response?.status === 401) {
                return error.response
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getContentByProjectId = (projectId, workflowId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get("/v1/projects/" + projectId + "/contents/workflow/" + workflowId);
                dispatch({
                    type: diagramConstant.GET_CONTENTS_BY_PROJECTID,
                    payload: res.data
                })
            }
        } catch (error) {

            if (error && error.response && error.response.status === 401) {
                return error.response
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getAllworkflowDetails = (id) => {
    return async (dispatch) => {
        dispatch({
            type: diagramConstant.GET_WORKFLOW_REQUEST
        })
        try {
            if (id) {
                const res = await axios.get(`/v1/projects/{projectId}/workflows/personasDetails/${id}`);
                dispatch({
                    type: diagramConstant.GET_ALL_WORKFLOW_DETAILS,
                    payload: res.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const freePrevData = () => {
    return (dispatch) => dispatch({ type: diagramConstant.EMPTY_ALL_WORKFLOW_DETAILS })
}
