import { Box, Button, Card, TextField, Typography } from "@material-ui/core";
import { Container } from "react-trello";
import { images } from "assets";
import { makeStyles } from "@material-ui/core/styles";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import AddToPhotosOutlinedIcon from "@material-ui/icons/AddToPhotosOutlined";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { addNewProject, handleProjectClone, signout } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessages } from "..";
import { Autocomplete } from "@material-ui/lab";
import MeetingRoomOutlinedIcon from "@material-ui/icons/MeetingRoomOutlined";
import { useHistory } from "react-router-dom";
import { reduceMultipleSpaces } from "Utils";
import useLeftSideBar from "hooks/useLeftSideBar";

/**
 *@function LeftSideComp.jsx
 *@author Shahed
 *
 **/

const LeftSideComp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState(0);
  const auth = useSelector((state) => state.auth);
  const { singleCog, cardconfig } = useLeftSideBar(
    auth?.user?.applyToNew,
    auth?.user?.id
  );
  const projectData = useSelector((state) => state.projects);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [projectclone, setProjectClone] = useState(null);
  // for creating a new project

  // console.log({ singleCog, cardconfig });
  const createProject = (data) => {
    const newProjectData = {
      name: reduceMultipleSpaces(data.name),
      role: "ADMIN",
      userId: auth?.user?.id,
    };
    dispatch(
      addNewProject(
        newProjectData,
        singleCog,
        cardconfig,
        auth?.user?.applyToNew
      )
    );
    reset();
    setState(0);
  };

  // for clonning project where function will take project id and user id to clone an entire project
  const cloneProject = () => {
    dispatch(handleProjectClone(projectclone, auth?.user?.id));
    reset();
    setState(0);
  };

  // for logginout from the session
  const handleLogout = () => {
    dispatch(signout());
    localStorage.removeItem("afterlogin");
    history.push("/login");
  };

  return (
    <Container>
      <Box className={classes.logout_button}>
        <Box display={"flex"} onClick={() => handleLogout()}>
          <MeetingRoomOutlinedIcon />
          <Typography
            variant="subtitle1"
            style={{ marginTop: "-3px", marginLeft: "5px" }}
          >
            Log Out
          </Typography>
        </Box>
      </Box>
      <Box className={classes.head}>
        <img src={images.sideLogo} alt="__logo__" />
        <Typography variant="h4" className={classes.welcome}>
          Welcome To SquareBear
        </Typography>
        <Typography variant="h6" className={classes.version}>
          Version 1.0.1.4(5.18.2023)
        </Typography>
      </Box>
      {auth?.user?.roleType ? (
        state === 0 ? (
          <Box className={classes.createbody}>
            <Card className={classes.controlcard} onClick={() => setState(1)}>
              <Box display="flex">
                <Box className={classes.sideicon}>
                  <AddBoxOutlinedIcon className={classes.iconcolor} />
                </Box>
                <Box className={classes.textsection}>
                  <Typography variant="h6">
                    Create a new SquareBear Project
                  </Typography>
                  <Typography variant="body2" className={classes.description}>
                    Create a blank canvas to design, prototype and build <br />
                    your next awesome product
                  </Typography>
                </Box>
              </Box>
            </Card>
            <Card className={classes.controlcard} onClick={() => setState(2)}>
              <Box display="flex">
                <Box className={classes.sideicon}>
                  <AddToPhotosOutlinedIcon className={classes.iconcolor} />
                </Box>
                <Box className={classes.textsection}>
                  <Typography variant="h6">
                    Clone an existing project
                  </Typography>
                  <Typography variant="body2" className={classes.description}>
                    Don’t start from scratch, choose a project that is similar
                    to what you want <br /> to do and jump-start your product
                    journey.
                  </Typography>
                </Box>
              </Box>
            </Card>
            <Card className={classes.controlcard} onClick={() => setState(3)}>
              <Box display="flex">
                <Box className={classes.sideicon}>
                  <FolderOpenOutlinedIcon className={classes.iconcolor} />
                </Box>
                <Box className={classes.textsection}>
                  <Typography variant="h6">Open an archived project</Typography>
                  <Typography variant="body2" className={classes.description}>
                    Did you archive a project to de-clutter? Do you want to have
                    <br /> another look at it? Just click here to open your
                    archived projects.
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
        ) : (
          <Box className={classes.createbody}>
            {/* for creating project */}
            {state === 1 ? (
              <Box>
                <Typography variant="body2">Create Project</Typography>
                <form onSubmit={handleSubmit(createProject)}>
                  <Box>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "This is required",
                        },
                        pattern: {
                          value: /^\s*([a-zA-Z0-9]+\s*){3}$/,
                          message: "Three Words Format Only",
                        },

                        maxLength: {
                          value: 30,
                          message: "Not more than 30 characters",
                        },
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Project name*"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                    <ErrorMessages errors={errors} name="name" />
                    <Box marginTop={"1rem"} display="flex" justifyContent="end">
                      <Box marginRight="1rem">
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => setState(0)}
                        >
                          Back
                        </Button>
                      </Box>

                      <Button color="primary" variant="contained" type="submit">
                        Create
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
            ) : null}
            {/* for clonning project */}
            {state === 2 ? (
              <Box>
                <Typography variant="body2">Clone a Project</Typography>
                <Box>
                  <Autocomplete
                    options={projectData?.project}
                    id="blur-on-select"
                    blurOnSelect
                    getOptionSelected={(option, value) => {
                      return option.projectId === value.projectId;
                    }}
                    size="small"
                    fullWidth
                    getOptionLabel={(option) => option.name}
                    onChange={(e, f) => setProjectClone(f?.projectId)}
                    renderOption={(option, { selected }) => (
                      <div>
                        <p
                          style={{
                            color: "black",
                            display: "block",
                            margin: 0,
                          }}
                        >
                          {option.name}
                        </p>
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Projects"
                        variant="standard"
                      />
                    )}
                  />
                  {projectclone === null || projectclone === undefined ? (
                    <Box style={{ color: "red" }}>
                      * No Poject Selected For Clonning
                    </Box>
                  ) : null}
                </Box>
                <Box marginTop={"1rem"} display="flex" justifyContent="end">
                  <Box marginRight="1rem">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setState(0)}
                    >
                      Back
                    </Button>
                  </Box>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={
                      projectclone === null || projectclone === undefined
                        ? true
                        : false
                    }
                    onClick={() => cloneProject()}
                  >
                    Clone
                  </Button>
                </Box>
              </Box>
            ) : null}
            {/* for getting archived project */}
            {state === 3 ? (
              <Box>
                <Typography variant="h6">
                  * Feature is not available yet
                </Typography>
                <Box marginTop={"1rem"} display="flex" justifyContent="end">
                  <Box marginRight="1rem">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setState(0)}
                    >
                      Back
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : null}
          </Box>
        )
      ) : (
        <Box marginTop={"3rem"}>
          <Box display="flex" justifyContent="center">
            <img src={images.Access} alt="" height={"265px"} />
          </Box>
          <Box display="flex" justifyContent="center" marginTop=".5rem">
            <Typography variant="body2" style={{ fontSize: "10px" }}>
              Don't Have Acces To Create/Clone Project
            </Typography>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default LeftSideComp;

const useStyles = makeStyles((theme) => ({
  // for head section
  head: {
    marginTop: "2rem",
    textAlign: "center",
  },
  welcome: {
    fontSize: "30px",
  },
  version: {
    fontSize: "12px",
  },

  // for button options
  createbody: {
    margin: "3.5rem 2rem 1rem 2rem",
  },
  controlcard: {
    marginTop: ".7rem",
    cursor: "pointer",
  },
  sideicon: {
    width: "87px",
    textAlign: "center",
    paddingTop: "29px",
    backgroundColor: "#f5f5f5",
  },
  iconcolor: {
    color: "#7478e8",
    fontSize: "28px",
  },
  textsection: {
    padding: ".5rem 0 .5em 1rem",
  },
  description: {
    fontSize: "13px",
    color: "#A09F9F",
  },
  logout_button: {
    position: "relative",
    top: "1rem",
    left: "2rem",
    cursor: "pointer",
  },
}));
