import { getSingleHomeConfig } from 'redux/actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useRenderhome = () => {
    const dispatch = useDispatch();
    const { homeRenderer, loading } = useSelector(state => state.profileConfig);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (user) {
            dispatch(getSingleHomeConfig(user?.projectId))
        }
    }, [user?.projectId])
    return { homeRenderer, loading }
}

export default useRenderhome;