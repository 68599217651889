import { Avatar, Box, Grid, Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { AddCircle, PersonOutline } from "@material-ui/icons";
import { Fragment } from "react";

export const PersonasForProblemStatement = ({
    classes,
    personas,
    getAvatar,
    changePersonas,
    persona,
    personaproblemstatement,
    setOpenModalPersona
}) => {
    return (
        <Box>
            <Box className={classes.streamCardBoxforproblemstatement}>
                <Grid className={classes.scrollUpStyle} container>
                    {personas &&
                        personas.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <Tooltip title={item?.name} placement="bottom" arrow>
                                        <Box
                                            style={{
                                                background: "#E5E5E5",
                                                cursor: "pointer",
                                                marginRight: "20px",
                                                border: personaproblemstatement === item.id && "2px solid #7478E8",
                                            }}
                                            className={classes.AddboxCircleForProblemStatement}
                                        >
                                            {item?.avatar ? (
                                                <div>
                                                    <Avatar
                                                      style={{
                                                       border: personaproblemstatement === item.id && "2px solid #7478E8",
                                                      }}
                                                        className={classes.firstpaperproblemstatement}
                                                        src={getAvatar(item?.avatar)}
                                                        onClick={() =>
                                                            changePersonas(item?.id)
                                                        }
                                                    ></Avatar>
                                                </div>
                                            ) : (
                                                <p
                                                    style={{
                                                        position: "relative",
                                                        lineHeight: 1,
                                                        color: "black",
                                                        top: "0",
                                                        fontSize: ".5em",
                                                        textAlign: "center",
                                                        margin: "0px",
                                                    }}
                                                    onClick={() => changePersonas(item?.id)}
                                                >
                                                    <PersonOutline
                                                        style={{
                                                            height: "25px",
                                                            fontSize: "15.5em",
                                                            color: "#BDBDBD",
                                                            position: "relative",
                                                        }}
                                                    />
                                                    <span
                                                        style={{
                                                            position: "relative",
                                                            top: "-10px",
                                                            fontSize: "1.7em",
                                                        }}
                                                    >
                                                        {item?.name.length > 7
                                                            ? item?.name.slice(0, 7) + "..."
                                                            : item?.name}
                                                    </span>
                                                </p>
                                            )}
                                        </Box>
                                    </Tooltip>
                                </Fragment>
                            );
                        })}

                    <Box
                        style={{ background: "transparent" }}
                        onClick={() =>setOpenModalPersona(true)}
                        className={classes.AddboxCircleForProblemStatement}
                    >
                        <Button
                            className={classes.buttonStyle2}
                            disableRipple
                            title={
                                persona?.name && persona?.projectId
                                    ? "Click to add personas"
                                    : ""
                            }
                            endIcon={<AddCircle className={classes.buttonInnerStyle2problem} />}
                        />
                    </Box>
                </Grid>
            </Box>

        </Box>
    );
};
