import { Box, CircularProgress } from '@material-ui/core';

/**
 *@function Spinner.jsx
 *@author Azim
 *
 **/

const Spinner = () => {
  return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={"calc(100vh - 90px)"}
      >
          <CircularProgress disableShrink />
    </Box>
  );
};

export default Spinner;
