import { combineReducers } from "redux";
import { authConstants } from "../actions/constant";
import authReducer from "./auth.reducers";
import userReducer from "./user.reducer";
import diagramReducer from "./diagram.reducer";
import socketReducer from "./websocket.reducer";
import Orgprofile from "./profile.reducer";
import projectReducer from "./project.reducer";
import kanbanReducer from './kanban.reducer';
import paymentReducer from './payment.reducer';
import logHourReducer from "./loghour.reducer";
import logEnableReducer from "./logenable.reducer";
import problemStatementReducer from "./problemstatement.reducers";
import personasReducer from "./personas.reducer";
import riskAndIssueReducers from "./riskAndIssue.reducer";
import journeyReducers from "./journeyMap.reducer";
import heartBearReducer from "./heartbit.reducers";
import workflowReducer from "./newWorkflow.reducer";
import profileConfigReducer from "./profileconfig.reducers";
import cardConfigReducer from "./cardconfig.reducers";

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  diagram: diagramReducer,
  socket: socketReducer,
  payment: paymentReducer,
  orgprofile: Orgprofile,
  projects: projectReducer,
  riskandissue: riskAndIssueReducers,
  journey: journeyReducers,
  kanban: kanbanReducer,
  loghour: logHourReducer,
  logenable: logEnableReducer,
  problemstatement: problemStatementReducer,
  personas: personasReducer,
  heartBeat: heartBearReducer,
  workflow: workflowReducer,
  profileConfig: profileConfigReducer,
  cardConfig: cardConfigReducer
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === authConstants.LOGOUT_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};
// const rootReducer = combineReducers({
//   auth: authReducer,
//   user: userReducer,
//   diagram: diagramReducer,
//   socket: websocketConstant,
//   orgprofile: Orgprofile,
//   projects: projectReducer,
// });
export default rootReducer;
