import { personas } from './constant';
import axios from '../../api/axios';
import { errorAlert, successAlert, handleErrorMessage } from 'Utils';


// getting personas
export const getPersonas = (projectId) => {
    return async (dispatch) => {
        dispatch({
            type: personas.GET_PERSONAS_REQUEST
        })
        try {
            if (projectId) {
                const response = await axios.get(`/v1/projects/${projectId}/personas`)
                if (response && response.status === 200) {
                    dispatch({
                        type: personas.GET_PERSONAS_SUCCESS,
                        payload: response.data.data
                    })
                }
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: personas.GET_PERSONAS_FAILED,
                    payload: error.response
                })
                if (error && error.response && error.response.status === 401) {
                    return
                } else {
                    errorAlert(handleErrorMessage(error));
                }
            }
        }
    }
}
export const getSinglePersona = (projectId, workflowId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get(`/v1/projects/${projectId}/personas/getperonasbyworkflowid/${workflowId}`);
                dispatch({
                    type: personas.GET_ONLY_ID_AND_NAME,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const freePrevPersona = () => {
    return (dispatch) => dispatch({ type: personas.EMPTY_ALL_PERSONA_DETAILS })
}

// creating problem statement for individual entity
export const createPersonas = (projectId, data, personasData) => {
    return async (dispatch) => {
        dispatch({
            type: personas.CREATE_PERSONAS_REQUEST
        })
        try {
            if (projectId) {
                const response = await axios.post(`/v1/projects/${projectId}/personas`, data)
                // console.log({ response })
                if (response && response.status === 201) {
                    if (response.data.data.id) {
                        const newData = personasData.map((item) => {
                            return {
                                id: item.id,
                                backColor: item.backColor,
                                description: item.description,
                                name: item.name,
                                personaId: response.data.data.id,
                                sideColor: item.sideColor,
                            }
                        })
                        await axios.post(`/v1/projects/${projectId}/${response.data.data.id}/personasItems`, newData)
                    }
                    dispatch({
                        type: personas.CREATE_PERSONAS_SUCCESS,
                    })
                    dispatch(getPersonas(projectId))
                    successAlert(`Persona created successfully`)
                }
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: personas.CREATE_PESONAS_FAILED,
                    payload: error.response
                })
                errorAlert("Error occured")
            }
        }
    }
}


// updateing persona for individual entity
export const updatePersonas = (projectId, data, id, listdata) => {
    return async (dispatch) => {
        dispatch({
            type: personas.UPDATE_PERSONAS_REQUEST
        })
        try {
            if (projectId) {
                const response = await axios.put(`/v1/projects/${projectId}/personas/${id}`, data)
                if (response && response.status === 202) {
                    if (response.data.data.id) {
                        const newData = listdata.map((item) => {
                            return {
                                id: item.id,
                                backColor: item.backColor,
                                description: item.description,
                                name: item.name,
                                personaId: id,
                                sideColor: item.sideColor,
                            }
                        })
                        await axios.put(`/v1/projects/${projectId}/${id}/personasItems`, newData)
                    }
                    dispatch({
                        type: personas.UPDATE_PERSONAS_SUCCESS,
                    })
                    dispatch(getPersonas(projectId))
                    successAlert(`Personas Updated Successfully`)
                }
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: personas.UPDATE_PERSONAS_FAILED,
                    payload: error.response
                })
                errorAlert("Error occured")
            }
        }
    }
}

// get personas by id
export const getPersonasById = (projectId, id) => {
    return async (dispatch) => {
        dispatch({
            type: personas.GET_SINGLEP_PERSONAS_REQUEST
        })
        try {
            if (projectId && id) {
                const response = await axios.get(`v1/projects/${projectId}/personas/${id}`)
                // console.log({ response })
                if (response && response.status === 200) {
                    dispatch({
                        type: personas.GET_SINGLEP_PERSONAS_SUCCESS,
                        payload: response.data.data
                    })
                }
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: personas.GET_SINGLEP_PERSONAS_FAILED
                })
                errorAlert("Error occured")
            }
        }
    }
}

export const deletePersona = (projectId, id) => {
    return async (dispatch) => {
        dispatch({
            type: personas.DELETE_SINGLE_PERSONA_REQUEST
        })
        try {
            if (projectId && id) {
                const response = await axios.delete(`v1/projects/${projectId}/personas/${id}`)
                if (response && response.status) {
                    dispatch({
                        type: personas.DELETE_SINGLE_PERSONA_SUCCESS
                    })
                    successAlert("Persona Deleted Successfully")
                    dispatch(getPersonas(projectId))
                }
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: personas.DELETE_SINGLE_PERSONA_FAILED
                })
            }
        }
    }
}