const { makeStyles } = require("@material-ui/core");

const useProfileStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  AvatarHeadLine: {
    border: "1px solid gray",
    padding: "7px",
    borderRadius: "5px",
    marginTop: "10px",
  },
  purple: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    color: "black",
    background: "lightgray",
    cursor: "pointer",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  chooseStyle: {
    padding: "20px",
    paddingTop: "5px",
    paddingBottom: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#d3ced2",
    },
  },
  projectdiv: {
    marginTop: "1rem",
  },
  innderword: {
    fontWeight: "bolder",
    color: "white",
  },
  projectIcon: {
    borderRadius: "25px",
    backgroundColor: "#7478e8",
    padding: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  agelabelstyle: {
    height: "300px",
    overflow: "hidden",
    overflowY: "scroll",
    zIndex: 1,
  },
}));
export { useProfileStyles };
