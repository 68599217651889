import {  Modal } from "Components/Reusable";
import {  Box} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { ErrorMessages } from "Components/Reusable";
import { Controller } from "react-hook-form";

export const AddNewPersona = ({
    openPersonaModal,
    setOpenPersonaModal,
    resetPersona,
    onCreateSubmit,
    handlePersonaSubmit,
    controlled,
    errorState

}) => {
    return (
        <Modal
            open={openPersonaModal}
            handleClose={() => {
                setOpenPersonaModal(false);
                resetPersona();
            }}
        >
            <form
                noValidate
                autoComplete="off"
                onSubmit={handlePersonaSubmit(onCreateSubmit)}
            >
                <h1
                    style={{
                        fontSize: "24px",
                        lineHeight: "36px",
                        textAlign: "left",
                        marginLeft: "30px",
                    }}
                >
                    Add Persona
                </h1>
                <Box
                    style={{
                        padding: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Controller
                        name="name"
                        control={controlled}
                        defaultValue=""
                        rules={{
                            required: {
                                value: true,
                                message: "This is required",
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Persona*"
                                variant="outlined"
                                type="text"
                            />
                        )}
                    />

                    <ErrorMessages errors={errorState} name="name" />
                    <br />
                    <Button color="primary" fullWidth variant="contained" type="submit">
                        Add
                    </Button>
                </Box>
            </form>
        </Modal>
    );
};
