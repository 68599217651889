import React, { useEffect, useState } from "react";
import { images } from "assets";
import SVGRender from "../Reusable/SVGRender";
import { format } from "date-fns";
import {
  Adb as AdbIcon,
  Star as StarIcon,
  DeleteOutline as DeleteOutlineIcon,
  AccountCircleSharp as AccountCircleSharpIcon,
  AssignmentTurnedInOutlined as AssignmentTurnedInOutlinedIcon,
  AttachFile as AttachFileIcon,
} from "@material-ui/icons";
import { Box, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { UserAvatar } from "Components/Reusable";
import { useSelector } from "react-redux";
import { differenceInDays } from "date-fns";
import { getEpicColor, getEpicName, getPersonaName } from "helpers/Board";
import { htmlToText } from "Utils";
/**
 *@function CustomCard.jsx
 *@author Azim
 *
 **/
const NewCustomCard = (props) => {
  const { partData, handleDeleteCard, getAvatar, getName, setCardId } = props;

  const classes = useStyles();
  const { kanbanData, velocity } = useSelector((state) => state.kanban);
  const { personas } = useSelector((state) => state.personas);
  const { valueStream } = useSelector((state) => state.diagram);
  const [finalcal, setFinalCal] = useState(null);
  const [releaseDatedif, setReleaseDatedif] = useState(null);

  const differDate = () => {
    const date1 = new Date();
    const date2 = new Date(partData.createDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  useEffect(() => {
    // getting all cards
    let newcards = [];
    if (kanbanData) {
      kanbanData?.map((item) => {
        newcards.push(...item.cards);
        return item;
      });
    }
    // console.log({partData})

    // getting all relaseRefcards
    let releaseRefCards = null;
    let doingCards = null;
    if (partData.id !== undefined && partData.id !== null) {
      releaseRefCards = newcards.filter(
        (value) =>
          value.releaseRef !== "" &&
          value.releaseRef !== null &&
          value.releaseRef === partData?.id &&
          value.acceptedDate === null &&
          value?.type !== "release"
      );

      doingCards = getAllDoingDetails(releaseRefCards);
    }
    let velocitynew = velocity?.find(
      (value) => value?.date === format(new Date(), "dd-MM-yyyy")
    );

    let finalcalculation = 0;
    if (velocitynew !== undefined) {
      // // console.log({ velocitynew })
      finalcalculation =
        doingCards.size_zero *
          (velocitynew.sizeZero == 0 ? 1 : velocitynew.sizeZero) +
        doingCards.size_one *
          (velocitynew.sizeOne == 0 ? 1 : velocitynew.sizeOne) +
        doingCards.size_two *
          (velocitynew.sizeTwo == 0 ? 1 : velocitynew.sizeTwo) +
        doingCards.size_three *
          (velocitynew.sizeThree == 0 ? 1 : velocitynew.sizeThree);
    } else {
      finalcalculation =
        doingCards.size_zero +
        doingCards.size_one +
        doingCards.size_two +
        doingCards.size_three;
    }

    if (partData.type === "release") {
      setReleaseDatedif(
        differenceInDays(new Date(partData?.dueDate), new Date())
      );
      setFinalCal(finalcalculation);
    }
  }, [kanbanData]);

  // get all length of existing doing cards
  const getAllDoingDetails = (doingCards) => {
    const size_zero = doingCards.filter((value) => value?.size === "0")?.length;
    const size_one = doingCards.filter((value) => value?.size === "1")?.length;
    const size_two = doingCards.filter((value) => value?.size === "2")?.length;
    const size_three = doingCards.filter(
      (value) => value?.size === "3"
    )?.length;
    return { size_zero, size_one, size_two, size_three };
  };

  return (
    <>
      {partData?.type === "release" ? (
        <Box
          className={classes.releasecardstyle}
          style={
            finalcal > releaseDatedif
              ? { backgroundColor: "red" }
              : { backgroundColor: "#00cc7a" }
          }
        >
          <IconButton
            className={classes.deleteReleaseIconStyle}
            onClick={() => handleDeleteCard(partData)}
          >
            <DeleteOutlineIcon />
          </IconButton>

          <div onClick={() => setCardId(partData?.issueCode)}>
            <span
              style={{
                color: "white",
                fontSize: "12px",
                textDecoration: "underline",
              }}
            >
              #{partData?.issueCode}
            </span>
            <h3 className={classes.releasename}>{partData?.releaseName}</h3>
          </div>
        </Box>
      ) : (
        <div className={classes.buttonContainer}>
          <IconButton
            className={classes.deleteIconStyle}
            onClick={() => handleDeleteCard(partData)}
          >
            <DeleteOutlineIcon />
          </IconButton>
          <div
            onClick={() => {console.log(partData);setCardId(partData?.issueCode)}}
            className={classes.customCardStyle}
          >
            <div>
              <h1 className={classes.serialIdStyle}>#{partData?.issueCode}</h1>

              {partData?.bugsLabel ? (
                <div
                  className={classes.labelbox}
                  style={
                    differDate() < 7
                      ? { backgroundColor: "#00cc7a" }
                      : differDate() < 15
                      ? { backgroundColor: "#ffff00" }
                      : { backgroundColor: "#ff3333" }
                  }
                ></div>
              ) : null}
            </div>
            <div
              className={
                (partData?.bugsLabel || partData?.choresLabel) !==
                classes.flexBetween
              }
            >
              {/* {partData?.title &&
              partData?.label !== "undefined" &&
              partData?.label !== "" ? (
                <h1 className={classes.cardHeader}>
                  {`As ${
                    partData?.personaId !== undefined &&
                    partData?.personaId != null
                      ? getPersonaName(partData?.personaId, personas)?.length >
                        10
                        ? getPersonaName(
                            partData?.personaId,
                            personas
                          ).substring(0, 5) +
                          "..." +
                          getPersonaName(
                            partData?.personaId,
                            personas
                          ).substring(
                            getPersonaName(partData?.personaId, personas)
                              ?.length - 5,
                            getPersonaName(partData?.personaId, personas)
                              ?.length
                          )
                        : getPersonaName(partData?.personaId, personas)
                      : "User"
                  }
                I want to do the ${partData?.title}
                process So that I can ${partData?.label}`}
                </h1>
              ) : ( */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: htmlToText(partData?.title),
                  }}
                  className={classes.cardHeader}
                ></div>
              {/* // )} */}

              {partData?.bugsLabel && (
                <h1 className={classes.cardHeader}>{partData?.bugsLabel}</h1>
              )}
              {partData?.choresLabel && (
                <h1 className={classes.cardHeader}>{partData?.choresLabel}</h1>
              )}
            </div>
            <div className={classes.flexBetween}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {partData?.size === "0" && (
                  <SVGRender img={images.tShirt} alt={"small Icon"} />
                )}
                {partData?.size === "1" && (
                  <SVGRender img={images.smallIconForSize} alt={"small Icon"} />
                )}
                {partData?.size === "2" && (
                  <SVGRender
                    img={images.mediumIconForSize}
                    alt={"medium Icon"}
                  />
                )}
                {partData?.size === "3" && (
                  <SVGRender img={images.largeIconForSize} alt={"large Icon"} />
                )}
                {!partData?.size && (
                  <SVGRender img={images.tShirt} alt={"noSize Icon"} />
                )}

                {partData?.type === "bug" && (
                  <AdbIcon
                    className={classes.typesIconStyle}
                    style={{ color: "red" }}
                  />
                )}
                {partData?.type === "feature" && (
                  <StarIcon
                    className={classes.typesIconStyle}
                    style={{ color: " #F0DD2E" }}
                  />
                )}

                {partData.type === "chore" && (
                  <AssignmentTurnedInOutlinedIcon
                    className={classes.typesIconStyle}
                  />
                )}

                {partData?.attachmentList &&
                  partData?.attachmentList?.length > 0 && (
                    <AttachFileIcon className={classes.typesIconStyle} />
                  )}
                {partData?.dueDate && partData?.dueDate !== "null" && (
                  <p className={classes.dueDateStyle}>
                    {format(new Date(partData?.dueDate), "dd-MM-yyyy")}
                  </p>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <div>
                  {partData?.userId ? (
                    <UserAvatar
                      className={classes.typesIconStyle}
                      getAvatar={getAvatar}
                      getName={getName}
                      userId={partData?.userId}
                    />
                  ) : (
                    <Tooltip title={"Not Assigned"} arrow>
                      <AccountCircleSharpIcon
                        style={{ color: "#9A9A9A", height: "16px" }}
                      />
                    </Tooltip>
                  )}
                </div>
                {partData?.epicId && (
                  <p
                    style={{
                      background: getEpicColor(partData?.epicId, valueStream),
                    }}
                    className={classes.epicIdforCard}
                  >
                    {getEpicName(partData?.epicId, valueStream)}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(NewCustomCard);

const useStyles = makeStyles((theme) => ({
  deleteIconStyle: {
    display: "none",
    position: "absolute",
    zIndex: "10",
    right: 0,
    top: -10,
    color: " #7478E8",
    height: "16px",
    width: "16px",
  },
  buttonContainer: {
    position: "relative",
    "&:hover .MuiButtonBase-root": {
      display: "block",
      background: "none",
    },
    "&:hover div": {
      background: "#F5F5F5",
    },
  },
  customCardStyle: {
    borderRadius: "3px",
    borderBottom: "1px solid #ccc",
    backgroundColor: "#fff",
    position: "relative",
    padding: "4px",
    paddingBottom: "0",
    paddingRight: "0",
    cursor: "pointer",
    maxWidth: "250px",
    marginBottom: "7px",
    minWidth: "238px",
  },
  serialIdStyle: {
    color: "blue",
    fontWeight: "300",
    fontSize: "12px",
    textDecoration: "underline",
    marginTop: "0",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  typesIconStyle: {
    height: "16px",
    width: "16px",
    fontSize: "9px",
    marginRight: "3px",
    marginBottom: "3px",
  },
  epicIdforCard: {
    borderRadius: "10px 0px 0px 0px",
    padding: "2px",
    margin: "0",
    textAlign: "center",
    whiteSpace: "nowrap",
    width: "80px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardHeader: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#000000",
    marginTop: "0",
  },
  dueDateStyle: {
    margin: 0,
    fontSize: "10px",
    weight: 400,
    color: " #999999",
  },
  labelbox: {
    borderRadius: "50px",
    height: "8px",
    width: "45px",
    marginBottom: ".2rem",
    // backgroundColor: "#00cc7a"
  },
  releasecardstyle: {
    padding: "0.2rem",
    backgroundColor: "#00cc7a",
    paddingBottom: ".5rem",
    margin: "0 0 .5rem 0",
    cursor: "pointer",
    position: "relative",
    "&:hover .MuiButtonBase-root": {
      display: "block",
    },
  },
  releasename: {
    paddingLeft: "2px",
    color: "white",
    fontWeight: "normal",
    fontSize: "16px",
    minHeight: "20px",
    width: "155px",
    wordBreak: "break-all",
    // maxWidth: "770px",
  },

  deleteReleaseIconStyle: {
    position: "absolute",
    zIndex: "10",
    display: "none",
    right: 0,
    top: -10,
    color: "white",
    height: "16px",
    width: "16px",
  },
}));
