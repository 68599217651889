import { problemStatement } from './constant';
import axios from '../../api/axios';
import { errorAlert, successAlert, handleErrorMessage } from 'Utils';


// getting problem statement
export const getproblemStatement = (projectId, personasId) => {
    return async (dispatch) => {
        dispatch({
            type: problemStatement.GET_PROBLEM_STATEMENT_REQUEST
        })
        try {
            if (projectId) {
                const response = await axios.get(`/v1/projects/${projectId}/problemStatements/personas/${personasId}`)
                if (response && response.status === 200) {
                    dispatch({
                        type: problemStatement.GET_PROBLEM_STATEMENT_SUCCESS,
                        payload: response.data.data
                    })
                }
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: problemStatement.CREATE_PROBLEM_STATEMENT_FAILED,
                    payload: error.response
                })
                if (error && error.response && error.response.status === 401) {
                    return
                } else {
                    errorAlert(handleErrorMessage(error));
                }
            }
        }
    }
}

// creating problem statement for individual entity
export const createStatement = (projectId, data) => {
    return async (dispatch) => {
        dispatch({
            type: problemStatement.CREATE_PROBLEM_STATEMENT_REQUEST
        })
        try {
            if (projectId) {
                const response = await axios.post(`/v1/projects/${projectId}/problemStatements`, data)
                if (response && response.status === 201) {
                    dispatch({
                        type: problemStatement.CREATE_PROBLEM_STATEMENT_SUCCESS,
                        // payload: response.data.data.logEnabled
                    })
                    dispatch(getproblemStatement(projectId))
                    successAlert(`Problem Statement Get success`)
                }
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: problemStatement.CREATE_PROBLEM_STATEMENT_FAILED,
                    payload: error.response
                })
                if (error && error.response && error.response.status === 401) {
                    return
                } else {
                    errorAlert(handleErrorMessage(error));
                }
            }
        }
    }
}


// creating problem statement for individual entity
export const updateStatement = (statement, projectId, data) => {
    return async (dispatch) => {
        dispatch({
            type: problemStatement.UPDATE_PROBLEM_STATEMENT_REQUEST
        })
        try {
            if (projectId) {
                const response = await axios.put(`/v1/projects/${projectId}/problemStatements/${statement[0]?.id}`, data)
                if (response && response.status === 202) {
                    dispatch({
                        type: problemStatement.UPDATE_PROBLEM_STATEMENT_SUCCESS,
                        // payload: response.data.data.logEnabled
                    })
                    dispatch(getproblemStatement(projectId))
                    successAlert(`Problem Statement Get success`)
                }
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: problemStatement.UPDATE_PROBLEM_STATEMENT_FAILED,
                    payload: error.response
                })
                if (error && error.response && error.response.status === 401) {
                    return
                } else {
                    errorAlert(handleErrorMessage(error));
                }
            }
        }
    }
}