import { journeyMapConstant } from "../actions/constant";

const initState = {
    riskKanbanData: [],
    deleted: {},
    kanban: [],
    singleCard: {},
    cards: [],
    attachments: [],
    treatments: [],
    journeyDetails: []
};

const journeyReducers = (state = initState, action) => {
    switch (action.type) {
        case journeyMapConstant.GET_ALL__RISK_AND_ISSUE_CARD_SUCCESS:
            return {
                ...state,
                cards: action.payload,
            };
        case journeyMapConstant.GET_SINGLE_CARD_SUCCESS:
            return {
                ...state,
                singleCard: action.payload,
            };
        case journeyMapConstant.GET_SINGLE_ATTACHMENTS_DATA_BY_CARD:
            return {
                ...state,
                attachments: action.payload,
            };
        case journeyMapConstant.REMOVE_SINGLE_ATTACHMENTS_DATA_BY_CARD:
            return {
                ...state,
                attachments: [],
            };
        case journeyMapConstant.GET_SINGLE_TREATMENTS_CARD:
            return {
                ...state,
                treatments: action.payload,
            };
        case journeyMapConstant.REMOVE_SINGLE_TREATMENTS_CARD:
            return {
                ...state,
                treatments: [],
            };
        case journeyMapConstant.GET_ALL_RISK_KANBAN_SUCCESS:
            return {
                ...state,
                kanban: action.payload
            };
        case journeyMapConstant.UPDATE_LANE_DRAG:
            return {
                ...state,
                riskKanbanData: action.payload
            };

        case journeyMapConstant.ADD_KANBAN_LANE:
            return {
                ...state,
                riskKanbanData: [...state.riskKanbanData, action.payload]
            };

        case journeyMapConstant.UPDATE_KANBAN_LANE:
            let updateSingleKanban = [...state.riskKanbanData];
            const indexedA = updateSingleKanban.findIndex((info) => (info.id === action.payload.id));
            updateSingleKanban[indexedA] = action.payload;
            return {
                ...state,
                riskKanbanData: updateSingleKanban
            };
        case journeyMapConstant.DELETE_KANBAN_LANE:
            return {
                ...state,
                riskKanbanData: state.riskKanbanData.filter((info) => info.id !== action.payload)
            };
        case journeyMapConstant.UPDATE_SINGLE_KANBAN_SUCCESS:
            let UpdateSingleBoardName = [...state.kanban];
            const indexed = UpdateSingleBoardName.findIndex((info) => (info.id === action.payload.id));
            UpdateSingleBoardName[indexed] = action.payload;
            return {
                ...state,
                kanban: UpdateSingleBoardName
            };
        case journeyMapConstant.ADD_NEW_KANBAN_EMPTY:
            return {
                ...state,
                kanban: [...state.kanban, action.payload]
            };
        case journeyMapConstant.DOCUMENT_DELETE_REQUEST:
            return {
                ...state,
                deleted: action.payload
            };
        case journeyMapConstant.DOCUMENT_DELETE_SUCCESS:
            return {
                ...state,
                deleted: action.payload
            };
        case journeyMapConstant.GET_ALL_RISK_AND_LANE_SUCCESS:
            return {
                ...state,
                riskKanbanData: action.payload,
                loading: false
            };

        case journeyMapConstant.JOURNEYMAP_DOWNLOAD:
            return{
                ...state
            }
        case journeyMapConstant.JOURNEYMAP_DOWNLOAD_FAILED:
            return {
                ...state
            }
        case journeyMapConstant.GET_ALL_JOURNEY_DETAILS:
            return {
                ...state,
                journeyDetails: action.payload
            }

        default:
            return state;
    }
};
export default journeyReducers;
