import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import newAxios from "axios";
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  updateEdge,
  useStoreApi,
  useReactFlow,
  getConnectedEdges,
  isNode,
  isEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import NavigationIcon from "@material-ui/icons/Navigation";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import {
  CustomNode,
  ConnectionLine,
  CustomEdge,
  DecisionNode,
  DeleteElement,
  SideBar,
  NewModalForEdge,
  UpdateTableModal,
  ContextMenu,
  AttachmentNode,
  LinkNode,
} from "Components/FlowComponents";
import { auth as Azim } from "api/Auth";
import Layout from "Components/Layout";
import WebSocks from "../InitialSock/WebSocks";
import { useDispatch, useSelector } from "react-redux";
import {
  loadDiagramFromBackend,
  loadNodeDataToTheTableFromServer,
  cloaseEditableNodeDataToTheTable,
  getProjectByUserId,
  sendValueStreamToGenerateKanban,
  getAllValueStreamsByWorkflow,
  removeFilesFromState,
  getSinglePersona,
} from "redux/actions";
import { Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { parse } from "zipson";
import { useStyles } from "assets/Styles/FlowCanvas";
import {
  getValueStreamForCSV,
  edgeArrowId,
  newNode,
  addEdged,
  isImage,
  isLocal,
  mapedWithId,
} from "Utils/flowUtils";
import { AppMode } from "urlConfig";
import { checkduplicity } from "Utils";
import { nanoid } from "nanoid";
/**
 *@function StreamCard.jsx
 *@author Azim
 *
 **/
const FinalDiagram = () => {
  const nodeTypes = useMemo(
    () => ({
      process: CustomNode,
      decision: DecisionNode,
      file: AttachmentNode,
      link: LinkNode,
    }),
    []
  );
  const edgeTypes = useMemo(
    () => ({
      custom: CustomEdge,
    }),
    []
  );
  const history = useHistory();
  const { search } = useLocation();
  const searchId = new URLSearchParams(search).get("streamId");
  const workflowId = new URLSearchParams(search).get("workflowId");
  //redux start
  const { decisionNodeInitialData, initialElements, valueStream, loading } =
    useSelector((state) => state.diagram);
  const { personaNameAndId } = useSelector((state) => state.personas);
  const auth = useSelector((state) => state.auth);
  const { projectId, kanbanId } = auth?.user;
  const dispatch = useDispatch();
  //main Elements State
  const [open, setOpen] = useState(false);
  const [personaDataToTheNode, setPersonaDataToTheNode] = useState({});
  const [elements, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const store = useStoreApi();
  const { setCenter } = useReactFlow();
  //useRef for DOM node
  const reactFlowWrapper = useRef(null);
  //instance
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  //files related state
  const [filesToSave, setFilesToSave] = useState([]);
  const [links, setLinks] = useState([
    {
      id: nanoid(10),
      link: "",
    },
  ]);
  // console.log({ links });
  //opening the Modal of form
  const [openDoubleClick, setOpenDoubleClick] = useState(false);
  const [openWorkflowModal, setOpenWorkflowModal] = useState(false);
  //open Editor menu
  const [edgeEditModal, setEdgeEditModal] = useState(false);
  const [edgeLabel, setEdgeLabel] = useState("");
  //context menu
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  //opening the Modal of form
  //modal Data
  const [updatedData, setUpdatedData] = useState({});
  const [deletedData, setdeletedData] = useState({});
  //state changes on websocket
  const [pendingRequest, setPendingRequest] = useState(false);
  //component Did mount or unmount
  useEffect(() => {
    if (workflowId) {
      dispatch(getAllValueStreamsByWorkflow(projectId, workflowId));
      dispatch(getSinglePersona(projectId, workflowId));
      dispatch(loadDiagramFromBackend(projectId, workflowId));
    }
  }, [workflowId, dispatch]);

  // epics for the value Stream
  const toEpics = valueStream?.map((item) => ({
    label: item.valueStreamName,
    color: item.color,
    value: item.id,
  }));
  //initial Data or from backend uder the organization
  useEffect(() => {
    if (initialElements?.messageContent) {
      const parsedContent = JSON.parse(initialElements?.messageContent);
      if(Array.isArray(parsedContent)){
        const edgesForCanvas = parsedContent.filter(
          (item) => item?.type === "custom" && item
        );
        const nodesForCanvas = parsedContent.filter(
          (item) => !(item?.type === "custom")
        );
        const renderedItems = valueStream.map((items) =>
          nodesForCanvas.filter((item) => item.data.valueStreamId === items.id)
        );
        setNodes(renderedItems.flat());
        setEdges(edgesForCanvas);
      }
      
    }
  }, [initialElements.messageContent]);

  useEffect(() => {
    if (auth.user?.id) {
      dispatch(getProjectByUserId(auth?.user?.id));
    }
  }, [auth?.user?.id, dispatch]);
  //stream Id

  useEffect(() => {
    const particularData = valueStream.find((stream) => stream.id === searchId);
    setPersonaDataToTheNode(particularData);
  }, [searchId, valueStream]);

  useEffect(() => {
    dispatch(loadNodeDataToTheTableFromServer(updatedData.data?.nodeDataList));
  }, [updatedData, dispatch]);

  const focusNode = useCallback(
    (id) => {
      setTimeout(() => {
        const { nodeInternals } = store.getState();
        const nodes = Array.from(nodeInternals).map(([, node]) => node);

        if (nodes.length > 0) {
          const newStepId = nodes.filter(
            (item) => item?.data?.valueStreamId === id
          );
          const noFilteredData = nodes.filter(
            (item) => item?.data?.valueStreamId === id
          );
          const noFilteredLastData =
            noFilteredData.length === 0 &&
            nodes.filter((item) => item?.type === "process")[nodes.length - 1];
          const firstNode = newStepId[0];

          if (firstNode) {
            const x = firstNode?.position?.x + firstNode?.width / 2;
            const y = firstNode?.position?.y + firstNode?.height / 2;
            const zoom = 1;
            setCenter(x + 400, y, { zoom, duration: 1000 });
          }
          if (noFilteredLastData) {
            const x =
              noFilteredLastData?.position?.x + noFilteredLastData?.width / 2;
            const y =
              noFilteredLastData?.position?.y + noFilteredLastData?.height / 2;
            const zoom = 1;
            setCenter(x + 500, y, { zoom, duration: 1000 });
          }
        }
      }, 20);
    },
    [setCenter, store]
  );
  useEffect(() => {
    if (searchId) {
      focusNode(searchId);
    }
  }, [searchId, focusNode]);
  const pushingFileNodes = (connection) => {
    if (
      connection.source.match(/node_file/g) &&
      connection.target.match(/node_process/g)
    ) {
      const connectedNodes = elements.find(
        (item) => item.id === connection.source
      );
      const els = [...elements];
      const maped = els.map((item) => {
        if (item.id === connection.target) {
          const filesSet = connectedNodes.data.files;
          item.data = {
            ...item.data,
            files: item?.data?.files?.length
              ? checkduplicity([...item.data.files, ...filesSet])
              : [...filesSet],
          };
        }
        return item;
      });
      setNodes(maped);
    } else if (
      connection.target.match(/node_file/g) &&
      connection.source.match(/node_process/g)
    ) {
      const connectedNodes = elements.find(
        (item) => item.id === connection.target
      );
      const els = [...elements];
      const maped = els.map((item) => {
        if (item.id === connection.source) {
          const filesSet = connectedNodes.data.files;
          item.data = {
            ...item.data,
            files: item?.data?.files.length
              ? checkduplicity([...item.data.files, ...filesSet])
              : [...filesSet],
          };
        }
        return item;
      });
      setNodes(maped);
    }
  };

  const onConnect = useCallback(
    (connection) => {
      if (connection.source === connection.target) return;
      const source = connection?.source;
      const target = connection.target;
      const newDecisionLineEdge = source.match(/decision/g);
      const fileNodeExist =
        connection.source.match(/node_file/g) ||
        connection.target.match(/node_file/g);
      const newEdgeId = edgeArrowId(source, target);
      const newSource = new Array(source);
      const newTarget = new Array(target);
      handleAddingSource(target, source);
      if (fileNodeExist) {
        pushingFileNodes(connection);
      }
      setEdges((els) =>
        addEdge(
          addEdged(
            connection,
            newEdgeId,
            newSource,
            newTarget,
            newDecisionLineEdge,
            personaDataToTheNode
          ),
          els
        )
      );
      setPendingRequest(true);
    },
    [setEdges, pendingRequest, edges]
  );
  const handleSubmitted = () => {
    if (!edgeLabel) return;
    const els = [...edges];
    const maped = els.map((el) => {
      if (el.id === updatedData?.id) {
        el.data.text = edgeLabel;
      }
      return el;
    });
    const nodeEls = [...elements];

    const mapedNodes = nodeEls.map((el) => {
      if (el.id === updatedData?.target) {
        el.data.decisionLineName = edgeLabel;
      }
      return el;
    });
    setEdges(maped);
    setNodes(mapedNodes);
    setPendingRequest(true);
    setEdgeEditModal(false);
    setEdgeLabel("");
  };
  const handleCloseModalData = () => {
    setEdgeEditModal(false);
    handleSubmitted();
  };
  const handleAddingSource = (target, source) => {
    const newSource = new Array(source);
    const newTarget = new Array(target);
    if (target && source) {
      const els = [...elements, ...edges];
      const maped = els.map((el) => {
        if (el.id === target) {
          if (el.data.source) {
            const newUpdateSource = [...el.data.source];
            const lastSourceArray = [...newUpdateSource, ...newSource];
            el.data.source = [...new Set(lastSourceArray)];
          } else {
            el.data.source = [...newSource];
          }
        }
        if (el.id === source) {
          if (el.data.target) {
            const newUpdateTarget = [...el.data.target];
            const lastTargetArray = [...newUpdateTarget, ...newTarget];
            el.data.target = [...new Set(lastTargetArray)];
          } else {
            el.data.target = [...newTarget];
          }
        }
        return el;
      });
      // setEdges(maped);
    }
  };
  const onNodeClick = (event, node) => {
    event.preventDefault();
    if (
      node.type === "process" ||
      node.type === "decision" ||
      node.type === "file"
    ) {
      setOpen(false);
      setUpdatedData(node);
      setOpen(true);
      setdeletedData(node);
    }
  };
  const onEdgeClick = (event, element) => {
    if (element.type === "custom" && element?.data?.line) {
      const data = { ...element };
      setOpen(false);
      setUpdatedData(data);
      setEdgeEditModal(true);
      setdeletedData(data);
    }
  };

  const onEdgeUpdate = (oldEdge, newConnection) => {
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
    setPendingRequest(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleDeleteElement = (node) => {
    const newDara = new Array(node);
    if (isNode(node)) {
      const edgess = getConnectedEdges(newDara, edges);
      setNodes(elements.filter((info) => info.id !== node.id));
      setEdges(edges.filter((val) => !edgess.includes(val)));
    }
    if (isEdge(node)) {
      setEdges(edges.filter((val) => val.id !== node.id));
    }
    setPendingRequest(true);
    setOpen(false);
    setEdgeEditModal(false);
  };

  // const onInit = (_reactFlowInstance) => {
  //   setReactFlowInstance(_reactFlowInstance);
  // };
  const onNodeDragStop = (event, node) => {
    setUpdatedData(node);
    if (node) {
      const els = [...elements];
      const maped = els.map((el) => {
        if (el.id === node.id) {
          el.position = node.position;
        }
        return el;
      });
      setNodes(maped);
      setPendingRequest(true);
    }
  };
  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      // console.log({ event, type });
      if (typeof type === "undefined" || !type) {
        return;
      }
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const dataNodes = newNode(type, position, personaDataToTheNode);
      setNodes((es) => es.concat(dataNodes));
      setOpenDoubleClick(true);
      const data = { ...dataNodes };
      setUpdatedData(data);
    },
    [reactFlowInstance, setNodes, personaDataToTheNode]
  );
  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: "all",
    defaultValues: {
      name: updatedData?.data?.name,
      processWhy: updatedData?.data?.processWhy,
      valuestreamItem: toEpics.find(
        (item) => item?.value === updatedData?.data?.valueStreamId
      ),
    },
  });
  //renderTableModalForUpdateNode
  useEffect(() => {
    if (
      updatedData.type === "file" &&
      (!updatedData?.data?.url || isLocal(updatedData?.data?.url))
    ) {
      if (filesToSave.length) {
        const els = [...elements];
        const maped = els.map((el) => {
          if (el.id === updatedData.id) {
            el.data.name = "files node";
            el.data.url = isImage(filesToSave[0].name)
              ? URL.createObjectURL(filesToSave[0])
              : "";
          }
          return el;
        });
        setNodes(maped);
      }
    }
  }, [filesToSave]);

  const submitFile = async (nodeData) => {
    if (filesToSave.length) {
      const formData = new FormData();
      for (let pic of filesToSave) {
        formData.append("file", pic);
      }
      setOpenDoubleClick(false);
      if (projectId && formData) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Azim.getToken()}`,
          },
        };
        const res = await newAxios.post(
          `${AppMode.backend}/v1/projects/${projectId}/documents/uploadDoc`,
          formData,
          config
        );
        if (res?.data?.data && !loading) {
          const newUpdateRows = mapedWithId(res.data.data);
          if (nodeData?.valuestreamItem) {
            const els = [...elements];
            const maped = els.map((el) => {
              if (el.id === updatedData.id) {
                el.data.name = "files node";
                el.data.files = [...el.data.files, ...newUpdateRows];
                el.data.url = el.data.files.length
                  ? el.data.files[0].thumnail
                  : newUpdateRows[0].url;
                el.data.processWhy = "files node";
                el.data.valueStreamId = nodeData.valuestreamItem.value;
                el.data.color = toEpics.find(
                  (item) => item?.value === nodeData?.valuestreamItem.value
                ).color;
                el.data.valueStreamName = toEpics.find(
                  (item) => item?.value === nodeData?.valuestreamItem.value
                ).label;
              }
              return el;
            });
            setNodes(maped);
            setUpdatedData({});
            setFilesToSave([]);
            setPendingRequest(true);
            reset();
            dispatch(removeFilesFromState());
          }
        }
      }
    }
  };

  const onUpdateSubmit = async (nodeData) => {
    if (filesToSave.length) {
      submitFile(nodeData);
    } else {
      clearErrors(["name", "processWhy", "dataExist"]);
      const dataExist =
        decisionNodeInitialData &&
        decisionNodeInitialData.some((item) => item.name === "");
      if (
        Object.values(errors).length > 0 ||
        !nodeData.name ||
        !nodeData.processWhy ||
        dataExist
      ) {
        if (!nodeData.name) {
          setError("name", {
            type: "validate",
            message: "This is required",
          });
        }
        if (!nodeData?.valuestreamItem.value) {
          setError("valuestreamItem", {
            type: "validate",
            message: "This is required",
          });
        }
        if (!nodeData.processWhy) {
          setError("processWhy", {
            type: "validate",
            message: "This is required",
          });
        }
        if (dataExist) {
          setError("dataExist", {
            type: "custom",
            message: `"We are doing *" field is mandatory`,
          });
        }
        return;
      }
      if (
        nodeData.name &&
        nodeData.processWhy &&
        updatedData?.id &&
        nodeData?.valuestreamItem
      ) {
        const els = [...elements];
        const maped = els.map((el) => {
          if (el.id === updatedData.id) {
            el.data.name = nodeData.name;
            el.data.processWhy = nodeData.processWhy;
            el.data.valueStreamId = nodeData.valuestreamItem.value;
            el.data.links = links;
            el.data.color = toEpics.find(
              (item) => item?.value === nodeData?.valuestreamItem.value
            ).color;
            el.data.valueStreamName = toEpics.find(
              (item) => item?.value === nodeData?.valuestreamItem.value
            ).label;
          }
          return el;
        });
        setNodes(maped);
        clearErrors(["name", "processWhy", "dataExist"]);
        //calling Websocket
        setPendingRequest(true);
        renderTableOnWorkItem();
        reset();
        setOpenDoubleClick(false);
      }

      dispatch(cloaseEditableNodeDataToTheTable(false));
    }
  };

  useEffect(() => {
    if (updatedData?.data?.name) {
      setValue("name", updatedData.data?.name);
    }
    if (updatedData?.data?.processWhy) {
      setValue("processWhy", updatedData.data?.processWhy);
    }
    return () => reset();
  }, [updatedData.data, setValue, reset]);

  const handleCloseModal = () => {
    if (updatedData.data.name === "" && updatedData.data.processWhy === "") {
      const filtered = elements.filter((item) => item.id !== updatedData.id);
      setNodes(filtered);
      reset();
      setPendingRequest(true);
      setOpenDoubleClick(false);
      clearErrors(["name", "processWhy", "dataExist"]);
      setFilesToSave([]);
    } else {
      setFilesToSave([]);
      setOpenDoubleClick(false);
      clearErrors(["name", "processWhy", "dataExist"]);
    }
  };
  //if condition matched
  const renderTableOnWorkItem = () => {
    const els = [...elements];
    const maped = els.map((el) => {
      if (el.id === updatedData.id) {
        el.data.nodeDataList = decisionNodeInitialData;
      }
      return el;
    });
    setNodes(maped);
    reset();
    setPendingRequest(true);
  };
  //onNodeDoubleClick
  const onNodeDoubleClick = (event, element) => {
    setOpenDoubleClick(true);
    const data = { ...element };
    setUpdatedData(data);
  };
  function generateKANBAN(newStream) {
    // console.log({ newStream });
    if (elements) {
      dispatch(
        sendValueStreamToGenerateKanban(
          projectId,
          getValueStreamForCSV(newStream, elements, personaNameAndId),
          kanbanId,
          personaNameAndId?.personasId
        )
      );
    }
  }
  const proOptions = { hideAttribution: true };
  const classes = useStyles();
  //context menu
  const onNodeContextMenu = (e, element) => {
    e.preventDefault();
    setPosition({ x: e.clientX, y: e.clientY });
    setIsOpen(true);
  };
  const deleteNode = () => {
    const newDara = new Array(updatedData);
    if (isNode(updatedData)) {
      const edgess = getConnectedEdges(newDara, edges);
      setNodes(elements.filter((info) => info.id !== updatedData.id));
      setEdges(edges.filter((val) => !edgess.includes(val)));
    }
    if (isEdge(updatedData)) {
      setEdges(edges.filter((val) => val.id !== updatedData.id));
    }
    setPendingRequest(true);
    setIsOpen(false);
    setEdgeEditModal(false);
  };
  const editNode = () => {
    setOpenDoubleClick(true);
  };
  const toWorkBoard = () => {
    history.push(`/board?card=${updatedData.id}`);
  };
  const handleMouseEnter = (e, node) => {
    setUpdatedData(node);
  };
  return (
    <Layout
      pageName={"Work Flow"}
      generateKanban={generateKANBAN}
      workflowId={workflowId}
      onDiagram
      openWorkflowModal={openWorkflowModal}
      setOpenWorkflowModal={setOpenWorkflowModal}
    >
      <Box className={classes.root}>
        <Box
          onChange={(e) => e.preventDefault()}
          className={`${classes.wrapperFlow} `}
        >
          <Box
            onContextMenu={(e) => e.preventDefault()}
            className={classes.reactflowWrapper}
            ref={reactFlowWrapper}
          >
            <ReactFlow
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              proOptions={proOptions}
              connectionLineComponent={ConnectionLine}
              connectionLineType="step"
              nodes={elements}
              connectionLineStyle={{ fill: "transparent" }}
              edges={edges}
              deleteKeyCode="/"
              snapToGrid={false}
              multiSelectionKeyCode="Control"
              connectionMode="loose"
              onNodeClick={onNodeClick}
              onEdgeClick={onEdgeClick}
              onNodeMouseEnter={handleMouseEnter}
              onConnect={onConnect}
              onEdgesChange={onEdgesChange}
              onNodesChange={onNodesChange}
              elevateEdgesOnSelect="true"
              onInit={setReactFlowInstance}
              onNodeDragStop={onNodeDragStop}
              onDrop={onDrop}
              maxZoom={5}
              minZoom={0.3}
              fitview="true"
              onDragOver={onDragOver}
              onNodeContextMenu={onNodeContextMenu}
              onNodeDoubleClick={onNodeDoubleClick}
              onEdgeUpdate={onEdgeUpdate}
              arrowheadcolor="#595A66"
            >
              <Background variant="lines" gap={150} />
              <Controls />
            </ReactFlow>
          </Box>
          <Box className={classes.sideBar}>
            <SideBar
              personaDataToTheNode={personaDataToTheNode}
              classes={classes}
            >
              {open && (
                <Box className={classes.deleteElement}>
                  <DeleteElement
                    handleClickAway={handleClickAway}
                    deletedData={deletedData}
                    open={open}
                    handleDeleteElement={handleDeleteElement}
                  />
                </Box>
              )}
            </SideBar>
          </Box>
          {edgeEditModal && (
            <NewModalForEdge
              edgeEditModal={edgeEditModal}
              handleCloseModalData={handleCloseModalData}
              handleSubmitted={handleSubmitted}
              defaultValue={updatedData?.data?.text}
              setEdgeLabel={setEdgeLabel}
              handleDeleteElement={handleDeleteElement}
              deletedData={deletedData}
            />
          )}
          <ContextMenu
            isOpen={isOpen}
            position={position}
            onMouseLeave={() => setIsOpen(false)}
            actions={[
              { label: "delete", effect: deleteNode, icon: DeleteOutlineIcon },
              { label: "Edit", effect: editNode, icon: EditIcon },
              {
                label: "to kanban",
                effect: toWorkBoard,
                icon: NavigationIcon,
              },
            ]}
          />

          {openDoubleClick && (
            <UpdateTableModal
              setFilesToSave={setFilesToSave}
              setLinks={setLinks}
              links={links}
              clearErrors={clearErrors}
              openDoubleClick={openDoubleClick}
              handleCloseModal={handleCloseModal}
              updatedData={updatedData}
              classes={classes}
              handleSubmit={handleSubmit}
              onUpdateSubmit={onUpdateSubmit}
              control={control}
              loading={loading}
              errors={errors}
              toEpics={toEpics}
              persona={personaNameAndId}
              decisionNodeInitialData={decisionNodeInitialData}
            />
          )}
        </Box>
      </Box>
      <WebSocks
        initialElements={initialElements}
        setNodes={setNodes}
        setEdges={setEdges}
        elements={elements}
        workflowId={workflowId}
        edges={edges}
        pendingRequest={pendingRequest}
        setPendingRequest={setPendingRequest}
      />
    </Layout>
  );
};
export default FinalDiagram;
