import { kanbanConstant } from "../actions/constant";

const initState = {
    kanbanData: [],
    deleted: {},
    kanban: [],
    loading: false,
    singleKanban: {},
    imagepreview: null,
    velocity: [],
    allCard:[],
    categories: [],
    releasecardcounts : []
};

const kanbanReducer = (state = initState, action) => {
    switch (action.type) {
        case kanbanConstant.GENERATE_KANBAN:
            return {
                ...state,
                kanbanData: action.payload
            };
        case kanbanConstant.GET_ALL_LANE_REQUEST:
            return {
                ...state,
                loading: true,
                kanban: []
            };
        case kanbanConstant.MAKING_EMPTY:
            return {
                ...state,
                loading: true,
                kanbanData: []
            };
        case kanbanConstant.GET_ALL_KANBAN_SUCCESS:
            return {
                ...state,
                kanban: action.payload,
                loading: false
            };
        case kanbanConstant.UPDATE_LANE_DRAG:
            return {
                ...state,
                kanbanData: action.payload
            };
        case kanbanConstant.GET_SINGLE_KANBAN_SUCCESS:
            return {
                ...state,
                singleKanban: action.payload
            };
        case kanbanConstant.ADD_KANBAN_LANE:
            return {
                ...state,
                kanbanData: [...state.kanbanData, action.payload]
            };

        case kanbanConstant.UPDATE_KANBAN_LANE:
            let updateSingleKanban = [...state.kanbanData];
            const indexedA = updateSingleKanban.findIndex((info) => (info.id === action.payload.id));
            updateSingleKanban[indexedA] = action.payload;
            return {
                ...state,
                kanbanData: updateSingleKanban
            };
        case kanbanConstant.DELETE_SINGLE_KANBAN_SUCCESS:
            return {
                ...state,
                kanban: state.kanban.filter((info) => info.id !== action.payload)
            };
        case kanbanConstant.DELETE_KANBAN_LANE:
            return {
                ...state,
                kanbanData: state.kanbanData.filter((info) => info.id !== action.payload)
            };
        case kanbanConstant.UPDATE_SINGLE_KANBAN_SUCCESS:
            let UpdateSingleBoardName = [...state.kanban];
            const indexed = UpdateSingleBoardName.findIndex((info) => (info.id === action.payload.id));
            UpdateSingleBoardName[indexed] = action.payload;
            return {
                ...state,
                kanban: UpdateSingleBoardName
            };
        case kanbanConstant.ADD_NEW_KANBAN_EMPTY:
            return {
                ...state,
                kanban: [...state.kanban, action.payload]
            };
        case kanbanConstant.DOCUMENT_DELETE_REQUEST:
            return {
                ...state,
                deleted: action.payload
            };
        case kanbanConstant.DOCUMENT_DELETE_SUCCESS:
            return {
                ...state,
                deleted: action.payload
            };
        case kanbanConstant.GET_ALL_LANE_SUCCESS:
            return {
                ...state,
                kanbanData: action.payload,
                loading: false
            };
        case kanbanConstant.IMAGE_PREVIEW:
            return {
                ...state,
                imagepreview: action.payload
            }
        case kanbanConstant.GET_VELOCITY_REPORT:
            return {
                ...state,
                velocity: action.payload
            }
        case kanbanConstant.GET_ALL_CARDS_BY_PROJECT_ID:
            return {
                ...state,
                allCard: action.payload
            }

        case kanbanConstant.GET_KANBAN_CATEGORY:
            return {
                ...state,
                categories: action.payload
            }
        case kanbanConstant.GET_KANBAN_CATEGORY_ERROR:
            return {
                ...state,
                categories: []
            }
        case kanbanConstant.CREATE_KANBAN_CATEGORY:
            return {
                ...state,
            }
        case kanbanConstant.CREATE_KANBAN_CATEGORY_ERROR:
            return {
                ...state,
            }
        case kanbanConstant.DELETE_KANBAN_CATEGORY:
            return {
                ...state,
            }
        case kanbanConstant.CHANGE_KANBAN_CATEGORY:
            return {
                ...state,
                kanbanData: action.payload,
            };

        case kanbanConstant.GET_RELEASE_CARD_COUNTINGS:
            return {
                ...state,
                releasecardcounts: action.payload
            }
        default:
            return state;
    }
};
export default kanbanReducer;
