import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import Avatars from "assets/Avatars";
import { backgroundArray } from "assets/Images/background";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  createKanbanCategory,
  deleteKanbanCategory,
  getAllKanbanCategory,
} from "redux/actions";
import { Delete } from "@material-ui/icons";
import { CustomSnacbar, FormsGrouped } from "..";
import { useForm } from "react-hook-form";

/**
 *@function Settings.jsx
 *@author Azim
 *
 **/

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const ImageOrAvatarSelector = ({
  handleChangeBackground,
  setOpenSetting,
  wording,
  avatar,
}) => {
  const classes = useStyles();
  const { kanbanData, categories } = useSelector((state) => state.kanban);
  const { user } = useSelector((state) => state.auth);
  const [kanbans, setKanbans] = useState([]);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const dispatch = useDispatch();
  const [deleteid, setDeleteId] = useState(null);

  useEffect(() => {
    if (kanbanData.length > 0) {
      const newkanbans = kanbanData.map((v) => {
        return {
          value: v?.id,
          label: v?.title,
        };
      });
      setKanbans(newkanbans);
    }
  }, [kanbanData, categories]);

  useEffect(() => {
    if (user?.projectId) {
      dispatch(getAllKanbanCategory(user?.projectId));
    }
  }, [deleteid]);

  const handleDeleteCategory = () => {
    dispatch(deleteKanbanCategory(deleteid, user?.projectId));
    setOpenDeleteCategory(false);
    setOpenSetting(false);
  };

  const {
    formState: { errors: errorsState },
    handleSubmit,
    reset,
    setError,
    control: realControl,
  } = useForm({
    mode: "all",
  });
  const mapedForm = [
    {
      label: "Category Name",
      name: "title",
      rules: {
        required: {
          value: true,
          message: "This is required",
        },
      },
    },
    {
      label: "Start Select Lane List",
      name: "startlaneIds",
      type: "autocomplete",
      options: kanbans,
    },
    {
      label: "End Select Lane List",
      name: "endlaneIds",
      type: "autocomplete",
      options: kanbans,
    },
  ];
  //limit tags should be at least 2, most 2 otherwise error
  const onSubmit = (data) => {
    const startIndex = kanbans.findIndex(
      (option) => option.value === data.startlaneIds?.value
    );
    const endIndex = kanbans.findIndex(
      (option) => option.value === data.endlaneIds?.value
    );
    if (startIndex !== endIndex && startIndex < endIndex) {
      const result = kanbans.filter(
        (_, index) => index >= startIndex && index <= endIndex
      );
      const uploadedData = {
        projectId: user?.projectId,
        title: data.title,
        laneIds: result.map((item) => item.value),
      };
      dispatch(createKanbanCategory(uploadedData));
      setOpenSetting(false);
      reset();
    } else {
      if (startIndex === endIndex) {
        setError("startlaneIds", {
          type: "custom",
          message: "Selected lanes cannot be same",
        });
        setError("endlaneIds", {
          type: "custom",
          message: "Selected lanes cannot be same",
        });
      }
      if (startIndex > endIndex) {
        setError("endlaneIds", {
          type: "custom",
          message: "Selected lanes cannot be smaller then previous one",
        });
      }
    }
  };
  return (
    <Box>
      <Typography
        style={{
          padding: "10px 48px",
          marginTop: "0px",
          fontWeight: "500",
          background: "#7478E8",
          fontSize: "32px",
          color: "#F8F8F8",
          borderBottom: "2px solid black",
        }}
        align="left"
        variant="h5"
        component="h6"
        gutterBottom
      >
        {wording ? wording : "Settings"}
      </Typography>
      <Box
        maxWidth="950px"
        maxHeight="600px"
        padding="45px"
        sx={{
          overflowY: " auto",
          overflowX: "hidden",
        }}
      >
        <Grid spacing={2} container>
          {avatar ? (
            Object.keys(Avatars).map((key, index) => (
              <Grid key={index} item sm={avatar ? 1 : 3}>
                <Paper>
                  <img
                    onClick={() => {
                      handleChangeBackground(key);
                      setOpenSetting(false);
                    }}
                    style={{
                      cursor: "pointer",
                      width: "55px",
                    }}
                    src={avatar && Avatars[key]}
                    alt={"Avatar of this organization"}
                  />
                </Paper>
              </Grid>
            ))
          ) : (
            <>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Background Images
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid spacing={2} container>
                    {backgroundArray.map((key, index) => (
                      <Grid key={index} item sm={avatar ? 1 : 3}>
                        <Paper>
                          <img
                            onClick={() => {
                              handleChangeBackground(key);
                              setOpenSetting(false);
                            }}
                            style={{
                              cursor: "pointer",
                              width: "150px",
                            }}
                            src={key.thumbnail}
                            alt={"background"}
                          />
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ marginTop: ".2rem", width: "100%" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Kanban Category
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box style={{ width: "100%" }}>
                    <Box>
                      <form
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <FormsGrouped
                          mapedForm={mapedForm}
                          control={realControl}
                          errors={errorsState}
                          flex
                        />
                        <Box
                          marginTop="1rem"
                          display={"flex"}
                          justifyContent="end"
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Submit
                          </Button>
                        </Box>
                      </form>
                    </Box>

                    <Box marginTop="2rem">
                      {categories.length < 1 ? (
                        <Typography
                          variant="subtitle2"
                          style={{ color: "GrayText" }}
                        >
                          No Kanban Category exists
                        </Typography>
                      ) : (
                        <Box>
                          <Box display={"flex"} justifyContent="start">
                            <Typography
                              variant="h6"
                              style={{ color: "GrayText" }}
                            >
                              Kanban Categories
                            </Typography>
                          </Box>
                          {categories.map((item, index) => {
                            return (
                              <Box
                                style={{ width: "100%" }}
                                marginTop=".5rem"
                                key={index}
                              >
                                <Paper
                                  variant="outlined"
                                  style={{
                                    padding: "1rem",
                                    textAlign: "start",
                                  }}
                                >
                                  <Box
                                    display={"flex"}
                                    justifyContent="space-between"
                                  >
                                    <Box>{item.title}</Box>
                                    <Box
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setOpenDeleteCategory(true);
                                        setDeleteId(item.id);
                                      }}
                                    >
                                      <Delete style={{ color: "red" }} />
                                    </Box>
                                  </Box>
                                </Paper>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Grid>
      </Box>
      <CustomSnacbar
        generalText=""
        opened={openDeleteCategory}
        setOpenAlert={setOpenDeleteCategory}
        type="user"
        DeleteText={"Are you sure you want to delete categories?"}
        handleDeleteFromBackend={handleDeleteCategory}
      />
    </Box>
  );
};

export default ImageOrAvatarSelector;
