import { ProjectConstants } from "../actions/constant";

const initState = {
    project: [],
    loading: false,
    message: "",
    singleProject: {},
    projectuser: [],
    usersUnderAdmin: [],
};

const projectReducer = (state = initState, action) => {
    switch (action.type) {
        case ProjectConstants.PROJECT_CREATE_REQUEST:
            return {
                ...state,
                loading: true,
                message: ''
            };
        case ProjectConstants.PROJECT_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                project: [action.payload.data],
                message: action.payload.message,
            };
        case ProjectConstants.ASSIGNE_PROJECT_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ProjectConstants.ASSIGNE_PROJECT_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                // project: [action.payload.data],
                // message: action.payload.message,
            };
        case ProjectConstants.PROJECT_GET:
            return {
                ...state,
                loading: false,
                singleProject: action.payload,
                message: action.payload.message,
            };
        case ProjectConstants.ALL_PROJECTS_GET:
            return {
                ...state,
                loading: false,
                project: action.payload,
                message: action.payload.message,
            };
        case ProjectConstants.PROJECT_UPDATE:
            return {
                ...state,
                loading: false,
                singleProject: action?.payload.data,
                message: action.payload.message,
            };
        case ProjectConstants.PROJECT_CREATE_FAILURE:
            return {
                ...state,
                message: "Server Error"
            };
        case ProjectConstants.CLONE_PROJECT_REQUEST:
            return {
                ...state,
            }
        case ProjectConstants.CLONE_PROJECT_SUCCESS:
            return {
                ...state,
            }
        case ProjectConstants.CLONE_RPOJECT_FAILED:
            return {
                ...state,
            }
        case ProjectConstants.GET_PROJECT_USER_BY_ADMINID_REQUEST:
            return {
                ...state,
                loading: true,
                usersUnderAdmin: []
            }
        case ProjectConstants.GET_PROJECT_USER_BY_ADMINID_SUCCESS:
            return {
                ...state,
                loading: false,
                usersUnderAdmin: action.payload,
            }
        case ProjectConstants.GET_PROJECT_USER_BY_ADMINID_FAILURE:
            return {
                ...state,
                loading: false,
                usersUnderAdmin: [],
                message: "Something is wrong",
            }
        case ProjectConstants.GET_PROJECT_USER_BY_PROJECTID_REQUEST:
            return {
                ...state,
                loading: true,
                projectuser: []
            }
        case ProjectConstants.GET_PROJECT_USER_BY_PROJECTID_SUCCESS:
            return {
                ...state,
                loading: false,
                projectuser: action.payload.data,
            }
        case ProjectConstants.GET_PROJECT_USER_BY_PROJECTID_FAILURE:
            return {
                ...state,
                loading: false,
                projectuser: [],
                message: action.payload.message,
            }
        case ProjectConstants.DOWNLOAD_TIMESHEET:
            return{
                ...state,
            }
        case ProjectConstants.DOWNLOAD_TIMESHEET_FAILED:
            return {
                ...state,
            }

        default:
            return state;
    }
};
export default projectReducer;
