import { Button, Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SignBanner from "Components/Reusable/SignBanner";
import { HookFormTextField, Loader } from "Components/Reusable";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPassword,
  handleRemoveSuccessFromForgetPass,
} from "redux/actions";
import { redirectUrl } from "Utils";
/**
 *@function Login.jsx
 *@author Azim
 *
 **/

const ForgotPassword = (props) => {
  const classes = useStyles();
  const { sendEmail, loading } = useSelector((state) => state.auth);
  const domHostname = window?.location?.host;
  const dispatch = useDispatch();
  const [clock, setClock] = useState(null);
  const [useraname, setUserName] = useState({});
  //Hook form state
  //  handle sending reset link
  const handleSendResetLink = () => {
    dispatch(
      resetPassword(useraname, redirectUrl(domHostname, "user-activation"))
    );
    setClock(60);
  };
  const handleRemoveSuccessAfterSendEmail = () => {
    dispatch(handleRemoveSuccessFromForgetPass(false));
  };
  useEffect(() => {
    if (clock === 0) {
      setClock(null);
    }
    if (!clock) return;
    const intervalId = setInterval(() => {
      setClock(clock - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [clock]);
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    mode: "all",
  });
  const onSubmit = (data) => {
    const { username } = data;
    dispatch(
      resetPassword(username, redirectUrl(domHostname, "user-activation"))
    );
    setUserName(username);
    setClock(30);
    reset();
  };

  return (
    <section className={classes.loginContainer}>
      <Grid container>
        <SignBanner />
        <Grid className={classes.loginFormStyle} item md={6}>
          {loading ? (
            <Loader />
          ) : sendEmail ? (
            <Box
              style={{
                padding: "4rem 0.2rem 4rem 0.2rem",
                textAlign: "center",
              }}
            >
              <Box>
                <Typography style={{ fontWeight: "600", fontSize: "2rem" }}>
                  Password Recovery
                </Typography>
              </Box>
              <p>
                A link will be sent to your email address to reset your
                password. if you
                <br /> don't get an email within a few minutes click on the
                resend link <br /> button or
                <Link
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    textDecoration: "underline",
                  }}
                  to="/login"
                  onClick={handleRemoveSuccessAfterSendEmail}
                >
                  Go Back to Login
                </Link>
              </p>
              <Button
                style={{ marginTop: "3rem", padding: ".5rem 3rem .5rem 3rem" }}
                color="primary"
                variant="contained"
                onClick={() => handleSendResetLink()}
                disabled={clock ? true : false}
              >
                {`Resend Link ${clock ? clock : ""}`}
              </Button>
            </Box>
          ) : (
            <Box margin={"1rem"}>
              <Typography
                className={classes.welcomeMessage}
                variant="h3"
                component="p"
              >
                Password Recovery
              </Typography>
              <Box>
                <Typography
                  className={classes.secondaryText}
                  variant="h6"
                  component="p"
                >
                  Tell us your email so we can send you a reset link
                </Typography>
              </Box>
              <form
                className={classes.root}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
              >
                <HookFormTextField
                  name="username"
                  rules={{
                    pattern: {
                      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: "Entered value does not match email format",
                    },
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  }}
                  control={control}
                  errors={errors}
                  label="Email"
                  className={classes.textStyle}
                />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.recoveryButton}
                  >
                    Recover Password
                  </Button>
                </Box>
              </form>
            </Box>
          )}
        </Grid>
      </Grid>
    </section>
  );
};

export default ForgotPassword;

//Material UI
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      width: "100%",
    },
  },
  loginContainer: {
    maxWidth: "100%",
    overflow: "hidden",
    position: "relative",
    "& .MuiGrid-container": {
      justifyContent: "center !important",
    },
  },
  loginFormStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  welcomeMessage: {
    color: theme.palette.common.black,
    fontWeight: 700,
    fontSize: "34px",
    margin: "3rem 0rem 2rem 0rem",
    textAlign: "center",
  },
  secondaryText: {
    color: "#373A4D",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    fontWeight: 400,
    marginBottom: "15px",
  },
  recoveryButton: {
    background: theme.palette.primary,
    fontSize: "13px",
    fontWeight: "700",
    textTransform: "none !important",
  },
  textStyle: {
    marginBottom: "15px",
    // minWidth: 457,
  },
}));
