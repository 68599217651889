const mapedFormForPersona = (persona) => [
    {
        label: "Value stream name*",
        name: "valueStreamName",
        rules: {
            required: {
                value: true,
                message: "This is required",
            },
            maxLength: {
                value: 50,
                message: "oops! characters limitation 50.",
            },
        },
        style: { margin: "10px" },
    },
    {
        label: "Value stream why*",
        name: "valueStreamWhy",
        rules: {
            required: {
                value: true,
                message: "This is required",
            },
            maxLength: {
                value: 60,
                message: "oops! characters limitation 60.",
            },
        },
        style: { margin: "10px" },
    },
    {
        label: "Persona*",
        name: "persona",
        rules: {
            required: {
                value: true,
                message: "This is required",
            },
        },
        InputProps: {
            readOnly: true,
        },
        defaultValue: persona?.name,
        style: { margin: "10px" },
    },
];
export { mapedFormForPersona }