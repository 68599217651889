import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  Add as AddIcon,
  Adb as AdbIcon,
  Star as StarIcon,
  AssignmentTurnedInOutlined as AssignmentTurnedInOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  AccessTime,
} from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Layout from "Components/Layout";
import Board from "react-trello";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLaneListForKanban,
  cardUpdateByFromKanban,
  getPersona,
  getNewValueStream,
  getOrganizationProfile,
  addNewCardToKanban,
  updateCustomLaneForKanban,
  deleteCardFromKanban,
  cardUpdateWithCardsFromKanban,
  deleteSingleAcceptanceCriteria,
  storeLogHour,
  fetchLogbyCard,
  fetchEnableLoghour,
  getAllForKanban,
  notificationToTheNewUser,
  updateSingleBoardBckground,
} from "redux/actions";
import DateFnsUtils from "@date-io/date-fns";
import { Controller, useForm } from "react-hook-form";
import {
  AddButton,
  AddCardForCustom,
  Attachments,
  BlockersTable,
  CustomLaneHeader,
  NewCustomCard,
  ShowNotes,
} from "Components/BoardComponents";
import { DropzoneArea } from "material-ui-dropzone";
import { images } from "assets";
import Avatars from "assets/Avatars";
import { nanoid } from "nanoid";
import {
  CustomSnacbar,
  ErrorMessages,
  SVGRender,
  Modal,
  Loader,
  UserAvatar,
  MPopover,
  QuillText,
} from "Components/Reusable";
import { useLocation } from "react-router-dom";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import {
  getSingleAcceptanceCriteria,
  changeArrayPositionByIndexNumber,
} from "Utils";
import { withHttps } from "Utils";
import { boarsdData } from "assets/Data/BoardsData";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import querystring from "query-string";
import { useCustomStyles } from "assets/Styles/CustomBoard";
import { globalStyle } from "assets/Styles/GlobalStyle";
import ModalCloseSnackBar from "Components/Reusable/CustomSnacbar/modalclose";
import { isMatch } from "lodash";
import { useRef } from "react";
/**
 *@function Board.jsx
 *@author Azim
 *
 **/

const CustomBoard = (props) => {
  const { search } = useLocation();
  const domLocation = window?.location?.href;
  const searchId = new URLSearchParams(search).get("boardId");
  //state of this file
  /* Modal related states */
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForAddCard, setModalOpenForAddCard] = useState(false);
  const [onEditLinkToDesign, setOnEditLinkToDesign] = useState(false);
  const [openBlockersSection, setOpenBlockersSection] = useState(false);
  const [openForUpdate, setOpenForUpdate] = useState(false);
  const [openToChangeTheUserStories, setOpenToChangeTheUserStories] =
    useState(false);
  //Dummy Data to prevent errors for Blockers and notes

  //popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEstimate, setAnchorElEstimate] = useState(null);
  const [anchorElTimeSheet, setAnchorElTimeSheet] = useState(null);
  const [anchorForAssignee, setAnchorForAssignee] = useState(null);

  //create aceptance criteria
  const [openTextFieldToAcceptance, setOpenTextFieldToAcceptance] =
    useState(true);
  const [openAcceptanceCriteria, setOpenAcceptanceCriteria] = useState(false);
  const [
    singleToUpdateAcceptanceCriteria,
    setSingleToUpdateAcceptanceCriteria,
  ] = useState({});
  const [createAceptanceCriteria, setcreateAceptanceCriteria] = useState([]);
  const [updateSingleAcceptanceCriteria, setUpdateSingleAcceptanceCriteria] =
    useState("");
  //update aceptance criteria
  const [aceptanceCriteria, setAceptanceCriteria] = useState([]);
  const [singleAcceptanceCriteria, setSingleAcceptanceCriteria] = useState(
    `Given:<Context>
When:<Trigger>
Then:<Action>`
  );
  const [openAcceptanceForUpdate, setOpenAcceptanceForUpdate] = useState(false);
  const [singleAcceptanceToUpdateCard, setSingleAcceptanceToUpdateCard] =
    useState({});
  //alert to delete
  const [openAlert, setOpenAlert] = useState(false);
  const [alertToDelete, setAlertToDelete] = useState({});
  const [openToChangeTheBugsLabel, setOpenToChangeTheBugsLabel] =
    useState(false);
  const [openToChangeTheChoresLabel, setOpenToChangeTheChoresLabel] =
    useState(false);
  /* data related states */
  //file related states
  const [filesToSave, setFilesToSave] = useState([]);
  const [updateFilesToSave, setUpdateFilesToSave] = useState([]);

  const [cardData, setCardData] = useState("");
  //AutoComplete filter to show the onChange function
  const filter = createFilterOptions();
  const [toEmails, setToEmails] = useState([]);
  const [epicId, setEpicId] = useState("");
  const [assignee, setAssignee] = useState("");
  const [cardId, setCardId] = useState("");
  //update related States
  const [updateEpicId, setUpdateEpicId] = useState("");
  const [updateAssignee, setUpdateAssignee] = useState("");
  // comment related states
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [openToComment, setOpenToComment] = React.useState(false);
  const [savedDescription, SetSavedDescription] = useState([]);
  const [updatenotes, setUpdatenotes] = useState(false);
  const [updateToNotes, setUpdateToNotes] = useState(null);
  const [prevcomments, setPrevSavedComment] = useState([]);
  const [prevdescription, setPredescription] = useState(null);

  const [editorUpdateState, setEditorUpdateState] = React.useState(
    EditorState.createWithContent(convertFromRaw(boarsdData.rawContent))
  );
  useEffect(() => {
    if (updateToNotes) {
      setEditorUpdateState(
        EditorState.createWithContent(convertFromRaw(updateToNotes.comment))
      );
    }
  }, [updateToNotes]);
  useEffect(() => {
    const parse = querystring.parse(search);
    if (parse.card !== undefined) {
      setCardId(parse.card);
    }
  }, []);
  //update blockers
  const [updateBlockers, setUpdateBlockers] = useState([]);
  const [transientBlockers, setTransientBlockers] = useState([]);
  /* icons related states */
  const [sizeOfProblem, setSizeOfProblem] = useState(0);
  const [updateSizeOfProblem, setUpdateSizeOfProblem] = useState(0);
  const [cardType, setCardType] = useState(2);
  /* date related states */
  const [selectedDate, setSelectedDate] = useState(null);
  const [anotherselectedDate, setAnotherSelectedDate] = useState(new Date());
  const [updateSelectedDate, setUpdateSelectedDate] = useState(null);
  /* global(redux) related states */
  const dispatch = useDispatch();
  const { kanbanData, kanban } = useSelector((state) => state.kanban);
  const { user } = useSelector((state) => state.auth);
  const { valueStream, persona } = useSelector((state) => state.diagram);
  const { organizationProfile } = useSelector((state) => state.orgprofile);

  /* Estimate related states */
  const [estimateShow, setEstimateShow] = useState(false);
  const [timesheet, setTimeSheet] = useState(false);

  const loghours = useSelector((state) => state.loghour.data);
  const [dateinclude, setDateInclude] = useState([]);
  const [loading, setLoading] = useState(false);
  const [estimateHour, setEstimateHour] = useState(0);
  const [kanbanName, setkanbanName] = useState("Work Board");
  const [background, setBackground] = useState("");

  // for descriptions
  const [description, setDescription] = useState(null);
  const [opendescription, setOpenDescription] = useState(false);

  // update card close
  const [openalertnew, setOpenAlertnew] = useState(false);
  /* Lifecycle method of this file*/

  useEffect(() => {
    if (kanban && kanban.length) {
      setBackground(kanban.find((item) => item.id === searchId));
    }
  }, [searchId, kanban]);
  useEffect(() => {
    if (user?.projectId) {
      dispatch(getAllLaneListForKanban(user?.projectId, searchId));
      sessionStorage.removeItem("selectedusers");
      dispatch(getAllForKanban(user?.projectId));
    }
  }, [dispatch, user?.projectId, searchId, cardId]);
  useEffect(() => {
    dispatch(getPersona(user?.projectId));
  }, [dispatch, user?.projectId]);

  useEffect(() => {
    if (user?.projectId) {
      dispatch(getOrganizationProfile(user?.projectId));
      dispatch(getNewValueStream(user?.projectId));
    }
  }, [user?.projectId, dispatch]);
  const {
    formState: { errors, isDirty },
    handleSubmit: handleUpdateSubmit,
    reset,
    control,
    getValues: getUpdateValues,
  } = useForm({
    mode: "all",
    defaultValues: cardData,
  });

  const {
    formState: { errors: createError },
    handleSubmit: handleCreateSubmit,
    reset: resetForCreate,
    control: controlForCreate,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    mode: "all",
  });
  const label = getValues("label");
  const title = getValues("title");
  const bugsLabel = getValues("bugsLabel");
  const choresLabel = getValues("choresLabel");
  const updateLabel = getUpdateValues("label");
  const updateTitle = getUpdateValues("title");
  const updateBugsLabel = getUpdateValues("bugsLabel");
  const updateChoresLabel = getUpdateValues("choresLabel");
  //the componentDidmount and componentWillUpdate for create Card
  useEffect(() => {
    // watch
    if (cardType === 0) {
      setValue("label", label);
      setValue("title", title);
      setValue("bugsLabel", "");
      setValue("choresLabel", "");
      clearErrors(["choresLabel", "bugsLabel"]);
    }
    if (cardType === 1) {
      setValue("label", "");
      setValue("title", "");
      setValue("bugsLabel", bugsLabel);
      setValue("choresLabel", "");
      clearErrors(["label", "bugsLabel", "title"]);
    }
    if (cardType === 2) {
      setValue("label", "");
      setValue("title", "");
      setValue("bugsLabel", "");
      setValue("choresLabel", choresLabel);
      clearErrors(["label", "choresLabel", "title"]);
    }
  }, [bugsLabel, label, title, setValue, cardType, choresLabel, clearErrors]);

  //Kanban Data for Board
  const kanbanDataForCreate = [...kanbanData];
  const newKanban = kanbanDataForCreate.map((item) => {
    const newItem = { ...item };
    if (item.notDeletable) {
      return newItem;
    } else {
      newItem.disallowAddingCard = true;
      return newItem;
    }
  });
  const data = {
    lanes: newKanban ? newKanban : [],
  };

  const handleEditCard = (e) => {
    setModalOpen(true);

    const particularCard = kanbanData.map((item) => item?.cards);
    const particularName = kanbanData.map((item) => {
      const { title, id } = item;
      const newItem = { title, id };
      return newItem;
    });

    const releaseCard = particularCard.flat(2);

    if (releaseCard) {
      const newCardModalUser = releaseCard.find((item) => item?.issueCode === e);
      const getLaneName = particularName.find(
        (item) => item.id === newCardModalUser?.laneId
      );
      // newCardModalUser.laneName = getLaneName && getLaneName?.title;
      setCardData(newCardModalUser);
      setUpdateEpicId(newCardModalUser?.epicId);
      setUpdateAssignee(newCardModalUser?.userId);
      setAceptanceCriteria(newCardModalUser?.acceptanceCriteria);
      setUpdateBlockers(
        newCardModalUser?.blockers.map((item) => JSON.parse(item))
      );
      setTransientBlockers(
        newCardModalUser?.blockers.map((item) => item && JSON.parse(item))
      );

      setUpdateSelectedDate(
        newCardModalUser?.dueDate ? newCardModalUser?.dueDate : null
      );
      SetSavedDescription(
        newCardModalUser?.metadata ? JSON.parse(newCardModalUser?.metadata) : []
      );
      setPrevSavedComment(
        newCardModalUser?.metadata ? JSON.parse(newCardModalUser?.metadata) : []
      );
      setUpdateSizeOfProblem(Number(newCardModalUser?.size));
      setOpenAcceptanceForUpdate(false);
      setDescription(newCardModalUser?.description);
      setPredescription(newCardModalUser?.description);
    }
  };

  useEffect(() => {
    // console.log(cardId)
    if (cardId !== "") {
      const parse = querystring.parse(search);
      window.history.pushState(
        null,
        "",
        window.location.origin +
          window.location.pathname +
          `?boardId=${parse.boardId}&card=${cardId}`
      );
      handleEditCard(cardId);
    }
  }, [cardId, kanbanData]);

  const handleDeleteFromBackend = (id) => {
    dispatch(deleteCardFromKanban(user?.projectId, id, searchId));
    setOpenAlert(false);
  };

  const handleCardToAnotherLane = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    const targetedIndexData = particularCards.find(
      (item) => item.id === targetLaneId
    );
    const makedFunCards = [...targetedIndexData.cards];
    const fes = makedFunCards.findIndex((item) => item.id === cardId);
    if (fes === position) {
      return;
    }

    const cardMovedDetailsForLane = { ...cardDetails };
    cardMovedDetailsForLane.laneId = targetLaneId;
    cardMovedDetailsForLane.projectId = user?.projectId;

    if (sourceLaneId === targetLaneId) {
      const respondedArray = changeArrayPositionByIndexNumber(
        fes,
        position,
        cardMovedDetailsForLane,
        makedFunCards
      );
      const INdexedResponse = respondedArray.map((item, index) => {
        const singleItem = { ...item };
        singleItem.indexNumber = index;
        return singleItem;
      });
      dispatch(
        cardUpdateWithCardsFromKanban(
          user?.projectId,
          INdexedResponse,
          searchId
        )
      );
    } else {
      makedFunCards.splice(position, 0, cardMovedDetailsForLane);
      const uniqueCards = Array.from(
        new Set(makedFunCards.map((a) => a.id))
      ).map((id) => {
        return makedFunCards.find((a) => a.id === id);
      });
      const INdexedResponse = uniqueCards.map((item, index) => {
        const singleItem = { ...item };
        singleItem.indexNumber = index;
        return singleItem;
      });
      dispatch(
        cardUpdateWithCardsFromKanban(
          user?.projectId,
          INdexedResponse,
          searchId
        )
      );
    }
  };
  const handleLaneDragEnd = (removedIndex, addedIndex, payload) => {
    const newUpdatedLanes = [...particularCards];
    const updatedAe = changeArrayPositionByIndexNumber(
      removedIndex,
      addedIndex,
      payload,
      newUpdatedLanes
    );
    const INdexedResponse = updatedAe.map((item, index) => {
      const singleItem = { ...item };
      singleItem.serial = index;
      return singleItem;
    });
    dispatch(
      updateCustomLaneForKanban(user?.projectId, INdexedResponse, searchId)
    );
  };

  const renderStyle = { height: "24px", width: "24px" };
  const open = Boolean(anchorEl);
  const openAssignee = Boolean(anchorForAssignee);
  const id = open ? "simple-popover" : undefined;
  const openAssigneeid = openAssignee ? "simple-popover" : undefined;
  const estimateShowid = estimateShow ? "simple-popover" : undefined;
  const popoverRef = useRef();
  const PopoverForNewEpicName = () => {
    return (
      <MPopover
        id={id}
        ref={popoverRef}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        className={classes.popoverStyle}
      >
        <Autocomplete
          options={toEpics}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => {
            return option.value === value.value;
          }}
          style={{ width: "100%", padding: "20px" }}
          id="blur-on-select"
          blurOnSelect
          onChange={(e, f) => {
            setUpdateEpicId(f.value);
            setAnchorEl(null);
          }}
          defaultValue={
            toEpics.find((item) => item?.value === updateEpicId)
              ? toEpics.find((item) => item?.value === updateEpicId)
              : ""
          }
          renderOption={(option, { selected }) => (
            <div
              style={{
                width: "100%",
                background: option.color ? option.color : "#67c6c0",
              }}
            >
              <p
                style={{
                  color: "black",
                  marginLeft: "10px",
                }}
              >
                {option.label}
              </p>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Epic link"
              variant="outlined"
            />
          )}
        />
      </MPopover>
    );
  };
  const PopoverForNewAssignee = () => {
    return (
      <div>
        <MPopover
          id={openAssigneeid}
          open={openAssignee}
          ref={popoverRef}
          anchorEl={anchorForAssignee}
          onClose={() => setAnchorForAssignee(null)}
          className={classes.popoverStyle}
        >
          <Autocomplete
            options={toUsers}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => {
              return option.value === value.value;
            }}
            style={{ width: "100%", padding: "20px" }}
            id="blur-on-select"
            blurOnSelect
            onChange={(e, f) => {
              setUpdateAssignee(f?.value);
              setAnchorForAssignee(null);
            }}
            defaultValue={
              toUsers.find((item) => item?.value === updateAssignee)
                ? toUsers.find((item) => item?.value === updateAssignee)
                : ""
            }
            renderOption={(option, { selected }) => (
              <div className={classes.flexBetweenCenter}>
                <UserAvatar
                  className={classes.typesIconStyle}
                  getAvatar={getAvatar}
                  getName={getName}
                  userId={option?.value}
                />
                <p
                  style={{
                    color: "black",
                    display: "block",
                    marginLeft: "10px",
                  }}
                >
                  {option?.label}
                </p>
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Assignee"
                variant="outlined"
              />
            )}
          />
        </MPopover>
      </div>
    );
  };

  // pop over for estimate
  const PopOverForEstimate = () => {
    return (
      <MPopover
        id={estimateShowid}
        open={estimateShow}
        ref={popoverRef}
        anchorEl={anchorElEstimate}
        onClose={() => {
          setAnchorElEstimate(null);
          setEstimateShow(false);
        }}
        className={classes.estimateStyle}
      >
        <Box padding={2}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="0h"
            value={estimateHour}
            onChange={(event) => setEstimateHour(event.target.value)}
          />
        </Box>
      </MPopover>
    );
  };

  // pop over for timesheet
  // const PopOverForTimeSheet = () => {
  //   return (
  //     <MPopover
  //       id={id}
  //       open={timesheet}
  //       anchorEl={anchorElTimeSheet}
  //       onClose={() => {
  //         handleTimeSheetClose();
  //         setAnchorElTimeSheet(null);
  //         setTimeSheet(false);
  //       }}
  //       className={classes.timeSheetStyle}
  //     >
  //       <Box padding={2}>
  //         <MuiPickersUtilsProvider utils={DateFnsUtils}>
  //           <KeyboardDatePicker
  //             className={classes.muiDateWrapper}
  //             disableToolbar
  //             format="dd-MM-yyyy"
  //             margin="normal"
  //             variant="outlined"
  //             id="date-picker-inline"
  //             label="This week"
  //             value={anotherselectedDate}
  //             size="small"
  //             onChange={(date) => handleChangeDate(date)}
  //             KeyboardButtonProps={{
  //               "aria-label": "change date",
  //             }}
  //           />
  //         </MuiPickersUtilsProvider>
  //         {!loading ? (
  //           <Box display={"flex"} paddingTop={2}>
  //             {new Array(7).fill().map((_, index) => {
  //               const date = new Date(anotherselectedDate);
  //               date.setDate(date.getDate() + (index - 3));

  //               const matched =
  //                 loghours &&
  //                 loghours.find(
  //                   (item) =>
  //                     new Date(item.date).getDate() === date.getDate() &&
  //                     cardData.id === item.cardId &&
  //                     new Date(item.date).getFullYear() ===
  //                       date.getFullYear() &&
  //                     new Date(item.date).getMonth() === date.getMonth()
  //                 );
  //               let weekday = date.toLocaleString("en-us", {
  //                 weekday: "long",
  //               });
  //               return (
  //                 <div style={index !== 0 ? { marginLeft: "15px" } : null}>
  //                   <div>
  //                     {weekday.substring(0, 3)}
  //                     {`(${date.getDate()})`}
  //                   </div>
  //                   <TextField
  //                     defaultValue={
  //                       matched && matched.hours ? matched.hours : 0
  //                     }
  //                     variant="outlined"
  //                     size="small"
  //                     type="number"
  //                     style={{ width: "80px" }}
  //                     onBlur={(event) =>
  //                       event.target.value !== ""
  //                         ? setDateInclude([
  //                             ...dateinclude,
  //                             {
  //                               date: date,
  //                               hours: event.target.value,
  //                               id:
  //                                 matched && matched.id
  //                                   ? matched.id
  //                                   : nanoid(15),
  //                             },
  //                           ])
  //                         : null
  //                     }
  //                     placeholder="0h"
  //                     disabled={date.getTime() > new Date().getTime()}
  //                   />
  //                 </div>
  //               );
  //             })}
  //           </Box>
  //         ) : null}
  //       </Box>
  //     </MPopover>
  //   );
  // };

  const renderUpdateNotes = () => {
    return (
      <div>
        <Grid
          style={{
            maxWidth: "770px",
            borderRadius: "8px",
          }}
        >
          {/* <MUIEditor
            editorState={editorUpdateState}
            onChange={onCommentDataUpdateChange}
            config={config}
          /> */}
          <Editor
            editorState={editorUpdateState}
            onEditorStateChange={onCommentDataUpdateChange}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "link",
                "image",
                "history",
              ],
            }}
            mention={{
              separator: " ",
              trigger: "@",
              suggestions: suggestions,
            }}
          />
        </Grid>
        <Box mt={1} align="left">
          <Button variant="contained" onClick={updateNotesData}>
            Update
          </Button>
          <Button style={{ marginLeft: "8px" }} onClick={cancelupdateNotesData}>
            cancel
          </Button>
        </Box>
      </div>
    );
  };

  const handleUpdateCardClose = (cancelBtn) => {
    if(cancelBtn === false){
      const newclickbtn = window.document.getElementById("custom-submit-button")
      // console.log(newclickbtn)
      newclickbtn?.click()
    }
    
    // if (
    //   updateFilesToSave.length > 0 ||
    //   isDirty === true ||
    //   !isMatch(description, prevdescription) ||
    //   !isMatch(transientBlockers, updateBlockers) ||
    //   !isMatch(savedDescription, prevcomments)
    // ) {
    //   setOpenAlertnew(true);
    // } else {
      handleUpdateCardCloseConfirm();
    // }
  };

  const handleUpdateCardCloseConfirm = () => {
    setModalOpen(false);
    setOnEditLinkToDesign(false);
    setOpenBlockersSection(false);
    setOpenToChangeTheBugsLabel(false);
    setOpenToChangeTheChoresLabel(false);
    setOpenToChangeTheUserStories(false);
    setOpenForUpdate(false);
    setOpenAcceptanceForUpdate(false);
    setUpdateFilesToSave([]);
    setAceptanceCriteria([]);
    SetSavedDescription([]);
    setSingleAcceptanceToUpdateCard({});
    setOpenToComment(false);
    setEstimateHour(0);
    setDescription(null);
    setOpenAlertnew(false);
    setCardId("");
    reset();
    const parse = querystring.parse(search);
    window.history.pushState(
      null,
      "",
      window.location.origin +
        window.location.pathname +
        `?boardId=${parse.boardId}`
    );
  };
  const handleCardSection = () => {
    return (
      <Modal open={modalOpen} board handleClose={() => handleUpdateCardClose(false)}>
        {newRenderUpdateModal()}
      </Modal>
    );
  };
  // handleAddCardToTheTrelloBoard
  const handleCreateModalClose = () => {
    setModalOpenForAddCard(false);
    setOpenTextFieldToAcceptance(true);
    setOpenAcceptanceCriteria(false);
    setSingleToUpdateAcceptanceCriteria({});
    setcreateAceptanceCriteria([]);
    setSingleAcceptanceCriteria("");
    setSelectedDate(null);
    setToEmails([]);
    setEpicId("");
    setAssignee("");
    resetForCreate();
  };
  //handleDeleteCard
  const handleDeleteCard = (erase) => {
    setOpenAlert(true);
    setAlertToDelete(erase);
  };

  const onUpdateSubmit = (data) => {
    const updatedCardData = { ...cardData };
    const formUpdateData = new FormData();
    const getExist =
      organizationProfile &&
      organizationProfile.findIndex((value) => value.id === updateAssignee);
    formUpdateData.append("metadata", JSON.stringify(savedDescription));
    formUpdateData.append("id", updatedCardData?.id);
    formUpdateData.append("attachmentList", updatedCardData?.attachmentList);
    let finalUpdatedBlockers;
    if (updateBlockers?.length === 0 || updateBlockers?.length <= 1) {
      finalUpdatedBlockers = [
        ...boarsdData.appendedBlockers,
        ...updateBlockers,
      ];
      for (let value of finalUpdatedBlockers) {
        formUpdateData.append("blockers", JSON.stringify(value));
      }
    } else {
      for (let value of updateBlockers) {
        formUpdateData.append("blockers", JSON.stringify(value));
      }
    }
    formUpdateData.append("epicId", updateEpicId ? updateEpicId : "");
    formUpdateData.append("issueCode", updatedCardData?.issueCode);
    formUpdateData.append(
      "dueDate",
      updateSelectedDate ? updateSelectedDate : updatedCardData?.dueDate
    );
    formUpdateData.append(
      "bugsLabel",
      data.bugsLabel ? data.bugsLabel : updatedCardData.bugsLabel
    );
    formUpdateData.append(
      "linkToDesign",
      data.linkToDesign ? data.linkToDesign : updatedCardData.linkToDesign
    );
    formUpdateData.append(
      "choresLabel",
      data.choresLabel ? data.choresLabel : updatedCardData.choresLabel
    );
    formUpdateData.append("description", description);
    formUpdateData.append("draggable", true);
    formUpdateData.append(
      "label",
      data.label ? data.label : updatedCardData.label
    );
    formUpdateData.append("laneId", updatedCardData?.laneId);
    formUpdateData.append(
      "title",
      data.title ? data.title : updatedCardData.title
    );
    if (getExist !== -1) {
      formUpdateData.append(
        "userId",
        updateAssignee ? updateAssignee : updatedCardData?.userId
      );
    } else {
      formUpdateData.append("userId", "");
    }
    formUpdateData.append("type", updatedCardData?.type);
    if (updateSizeOfProblem === 0) {
      formUpdateData.append("size", 0);
    }
    if (updateSizeOfProblem === 1) {
      formUpdateData.append("size", 1);
    }
    if (updateSizeOfProblem === 2) {
      formUpdateData.append("size", 2);
    }
    if (updateSizeOfProblem === 3) {
      formUpdateData.append("size", 3);
    }
    for (let pic of updateFilesToSave) {
      formUpdateData.append("attachments", pic);
    }
    // console.log(updatedCardData, "updated Data");
    // for estimate hour
    formUpdateData.append(
      "estimateHour",
      estimateHour ? estimateHour : updatedCardData?.estimateHour
    );
    const newShallowArray = [...aceptanceCriteria];
    newShallowArray.map((item) => {
      if (item.id.length === 9) {
        delete item.id;
        return item;
      }
      return item;
    });
    dispatch(
      cardUpdateByFromKanban(
        user?.projectId,
        updatedCardData?.id,
        formUpdateData,
        aceptanceCriteria,
        searchId
      )
    );
    if (updateAssignee) {
      const message = `You have assigned a card, please check the assigned card`;
      const anotherurl = domLocation.replace("&", "%26");
      // console.log(anotherurl);
      if (getExist !== -1 && updatedCardData.userId !== updateAssignee) {
        dispatch(
          notificationToTheNewUser(
            updatedCardData?.id,
            anotherurl.toString(),
            updateAssignee,
            message
          )
        );
      }
    }
    handleUpdateCardCloseConfirm();
  };

  const onCreateSubmit = (data) => {
    const formData = new FormData();
    const dueDate = selectedDate ? selectedDate : "";
    const writingComment = {
      id: `notes_${nanoid(12)}`,
      comment: <p>Created</p>,
      projectId: user?.projectId,
      cardId: data.cardId,
      userId: user?.id,
      cretedDate: new Date(),
      deletable: true,
    };
    const metadata = [writingComment];
    formData.append("attachmentList", []);
    const finalBlockers = [...boarsdData.appendedBlockers, ...toEmails];
    if (finalBlockers) {
      for (const blocker of finalBlockers) {
        formData.append("blockers", JSON.stringify(blocker));
      }
    }
    formData.append("epicId", epicId);
    formData.append("dueDate", dueDate);
    formData.append("id", data.cardId);
    //working
    formData.append("bugsLabel", data.bugsLabel);
    formData.append("choresLabel", data.choresLabel);
    formData.append("linkToDesign", data.linkToDesign ? data.linkToDesign : "");
    // formData.append("description", description);
    formData.append("description", data.description ?? "");
    formData.append("draggable", true);
    formData.append("label", data?.label);
    formData.append("laneId", kanbanData.find((item) => item.notDeletable).id);
    formData.append("title", data.title);
    formData.append("userId", assignee);
    formData.append("metadata", JSON.stringify(metadata));
    formData.append("createDate", new Date());
    if (cardType === 0) {
      formData.append("type", "feature");
    }
    if (cardType === 1) {
      formData.append("type", "bug");
    }
    if (cardType === 2) {
      formData.append("type", "chore");
    }
    if (sizeOfProblem === 0) {
      formData.append("size", 0);
    }
    if (sizeOfProblem === 1) {
      formData.append("size", 1);
    }
    if (sizeOfProblem === 2) {
      formData.append("size", 2);
    }
    if (sizeOfProblem === 3) {
      formData.append("size", 3);
    }
    for (let pic of filesToSave) {
      formData.append("attachments", pic);
    }
    dispatch(
      addNewCardToKanban(
        user?.projectId,
        formData,
        JSON.stringify(data.updateCreateAceptanceCriteria),
        data.cardId,
        searchId
      )
    );
    if (assignee) {
      const anotherurl = domLocation + "%26card%3D" + data.cardId;
      const message = `You have assigned a card, please check the assigned card`;
      dispatch(
        notificationToTheNewUser(
          data.cardId,
          anotherurl.toString(),
          assignee,
          message
        )
      );
    }
    handleCreateModalClose();
  };
  //description, id, projectId, cardId, update
  const handleCheckAcceptanceCriteria = () => {
    if (singleAcceptanceCriteria !== null && singleAcceptanceCriteria !== "") {
      const writingDescription = {
        description: singleAcceptanceCriteria,
        id: nanoid(9),
        projectId: user?.projectId,
        cardId: cardData?.id,
      };
      setAceptanceCriteria([...aceptanceCriteria, writingDescription]);
      setOpenForUpdate(false);
      setSingleAcceptanceCriteria("");
    }
  };

  const handleUpdateSingleAcceptanceForCard = useCallback(() => {
    if (
      updateSingleAcceptanceCriteria !== null &&
      updateSingleAcceptanceCriteria !== ""
    ) {
      const itemIndex = aceptanceCriteria.findIndex(
        (item) => item.id === singleAcceptanceToUpdateCard.id
      );
      if (itemIndex !== -1) {
        aceptanceCriteria[itemIndex].description =
          updateSingleAcceptanceCriteria;
      }
      setOpenAcceptanceForUpdate(false);
      setUpdateSingleAcceptanceCriteria("");
      setSingleAcceptanceToUpdateCard({});
    } else {
      setOpenAcceptanceForUpdate(false);
      setUpdateSingleAcceptanceCriteria("");
      setSingleAcceptanceToUpdateCard({});
    }
  }, [updateSingleAcceptanceCriteria]);
  //create----create
  const handleCreateAcceptanceCriteria = useCallback(() => {
    if (singleAcceptanceCriteria !== null && singleAcceptanceCriteria !== "") {
      const writingDescription = {
        description: singleAcceptanceCriteria,
        id: nanoid(9),
        projectId: user?.projectId,
      };
      setcreateAceptanceCriteria((t) => [...t, writingDescription]);
      setOpenTextFieldToAcceptance(false);
      setSingleAcceptanceCriteria("");
    }
  }, [singleAcceptanceCriteria]);

  //create----update
  const handleCreateUpdateAcceptanceCriteria = () => {
    if (
      updateSingleAcceptanceCriteria !== null &&
      updateSingleAcceptanceCriteria !== ""
    ) {
      const itemIndex = createAceptanceCriteria.findIndex(
        (item) => item.id === singleToUpdateAcceptanceCriteria.id
      );
      if (itemIndex !== -1) {
        createAceptanceCriteria[itemIndex].description =
          updateSingleAcceptanceCriteria;
      }
      setOpenAcceptanceCriteria(false);
      setOpenTextFieldToAcceptance(false);
      setSingleAcceptanceCriteria("");
      setUpdateSingleAcceptanceCriteria("");
      setSingleToUpdateAcceptanceCriteria({});
    } else {
      setOpenAcceptanceCriteria(false);
      setOpenTextFieldToAcceptance(false);
      setSingleAcceptanceCriteria("");
      setUpdateSingleAcceptanceCriteria("");
      setSingleToUpdateAcceptanceCriteria({});
    }
  };

  const onCommentDataChange = (data) => {
    setEditorState(data);
  };
  const onCommentDataUpdateChange = (data) => {
    setEditorUpdateState(data);
  };
  const saveComment = () => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    const writingComment = {
      id: `notes_${nanoid(12)}`,
      comment: rawContent,
      projectId: user?.projectId,
      cardId: cardData?.id,
      userId: user?.id,
      cretedDate: new Date(),
    };
    SetSavedDescription((prev) => [...prev, writingComment]);
    setEditorState(EditorState.createEmpty());
    setOpenToComment(false);
  };
  const removeComment = () => {
    setEditorState(EditorState.createEmpty());
    setOpenToComment(false);
  };
  const updateNotesData = () => {
    const updatedrawContent = convertToRaw(
      editorUpdateState.getCurrentContent()
    );
    const itemIndex = savedDescription.findIndex(
      (item) => item.id === updateToNotes.id
    );
    if (itemIndex !== -1) {
      savedDescription[itemIndex].comment = updatedrawContent;
      savedDescription[itemIndex].cretedDate = new Date();
    }
    setEditorState(EditorState.createEmpty());
    setUpdatenotes(false);
  };
  const cancelupdateNotesData = () => {
    setEditorState(EditorState.createEmpty());
    setUpdatenotes(false);
  };

  // for time sheet handling
  const handleTimeSheet = () => {
    setTimeSheet(!timesheet);
  };

  // close data send handle
  // const handleTimeSheetClose = () => {
  //   if (dateinclude.length > 0) {
  //     const parse = querystring.parse(search);
  //     const newData = dateinclude.map((item) => {
  //       return {
  //         cardId: cardData.id,
  //         date: item.date,
  //         hours: item.hours,
  //         laneId: cardData.laneId,
  //         projectId: cardData.projectId,
  //         userId: cardData.userId,
  //         id: item.id,
  //       };
  //     });

  //     dispatch(storeLogHour(cardData.projectId, newData));
  //     setDateInclude([]);
  //   }
  // };

  // fetch loghour data
  useEffect(() => {
    cardData &&
      cardData.projectId &&
      dispatch(fetchLogbyCard(cardData.projectId, cardData.id));
  }, [cardData]);

  // handle change date
  const handleChangeDate = (date) => {
    setLoading(true);
    setTimeout(() => {
      setAnotherSelectedDate(date);
      setLoading(false);
    }, 100);
  };

  const logEnable = useSelector((state) => state.logenable.enable);

  // dispatch logenable

  const project = useSelector((state) => state?.auth?.user?.projectId);

  useEffect(() => {
    dispatch(fetchEnableLoghour(project));
  }, [project, dispatch, fetchEnableLoghour]);

  const newRenderUpdateModal = () => {
    const allget =
      loghours &&
      loghours.map((item) => (cardData.id === item.cardId ? item.hours : null));
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const sum = allget && allget.length && allget.reduce(reducer);

    return (
      <Box minWidth="700px" mt="21px">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleUpdateSubmit(onUpdateSubmit)}
        >
          {PopoverForNewEpicName()}
          {PopoverForNewAssignee()}
          <div className={classes.flexBasisBetweenForHeaderCard}>
            <div className={classes.flexBasisBetween}>
              <Tooltip
                title={
                  getEpicName(updateEpicId)
                    ? getEpicName(updateEpicId)
                    : "Epic Link"
                }
                arrow
              >
                <Button
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  className={classes.updateCardEpicLink}
                  style={{
                    background: updateEpicId
                      ? getEpicColor(updateEpicId) || "#DEDEDE"
                      : "#DEDEDE",
                  }}
                >
                  {updateEpicId ? getEpicName(updateEpicId) : "Epic link"}
                </Button>
              </Tooltip>
              <div className="dateStyle">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    title={`Click to change date`}
                    disableToolbar
                    className={classes.muiDatePicker}
                    format="dd-MM-yyyy"
                    placeholder={`Due date`}
                    value={updateSelectedDate}
                    onChange={(date) => setUpdateSelectedDate(date)}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {logEnable ? (
                <div>
                  <Box
                    sx={{ backgroundColor: "#dedede", cursor: "pointer" }}
                    paddingLeft={1.2}
                    paddingRight={1.2}
                    paddingTop={0.3}
                    paddingBottom={0.3}
                    borderRadius={"5px"}
                    fontWeight={cardData?.estimateHour > 0 ? "bold" : "normal"}
                    onClick={(event) => {
                      setEstimateShow(!estimateShow);
                      setAnchorElEstimate(event.currentTarget);
                    }}
                  >
                    {cardData?.estimateHour > 0
                      ? `Estimate: ${cardData?.estimateHour}h`
                      : `Estimate`}
                  </Box>
                  {PopOverForEstimate()}
                </div>
              ) : null}
            </div>

            {/* #TODO */}
            <div className={classes.flexBetweenCenter}>
              <IconButton
                onClick={(event) => setAnchorForAssignee(event.currentTarget)}
              >
                {updateAssignee ? (
                  <UserAvatar
                    className={classes.updateCardAvatar}
                    getAvatar={getAvatar}
                    getName={getName}
                    userId={updateAssignee}
                  />
                ) : (
                  <Avatar className={classes.updateCardAvatar} />
                )}
              </IconButton>

              <IconButton onClick={handleUpdateTypeOfSize}>
                {updateSizeOfProblem === 0 && (
                  <SVGRender
                    style={renderStyle}
                    img={images.tShirt}
                    alt={"small Icon"}
                  />
                )}
                {updateSizeOfProblem === 1 && (
                  <SVGRender
                    style={renderStyle}
                    img={images.smallIconForSize}
                    alt={"small Icon"}
                  />
                )}
                {updateSizeOfProblem === 2 && (
                  <SVGRender
                    style={renderStyle}
                    img={images.mediumIconForSize}
                    alt={"medium Icon"}
                  />
                )}
                {updateSizeOfProblem === 3 && (
                  <SVGRender
                    style={renderStyle}
                    img={images.largeIconForSize}
                    alt={"large Icon"}
                  />
                )}
              </IconButton>

              {cardData?.type === "bug" && <AdbIcon style={{ color: "red" }} />}
              {cardData?.type === "feature" && (
                <StarIcon style={{ color: " #F0DD2E" }} />
              )}
              {cardData?.type === "chore" && <AssignmentTurnedInOutlinedIcon />}
            </div>
          </div>
          {/* User Stories #TODO */}
          {cardData?.estimateHour > 0 ? (
            <Typography
              align="left"
              title="click to change"
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                lineHeight: " 27px",
                color: " #000000",
                maxWidth: "700px",
                marginBottom: "1rem",
              }}
              onClick={() => setOpenToChangeTheUserStories(true)}
            >
              Reported Time
              <Box display={"flex"}>
                <UserAvatar
                  getAvatar={getAvatar}
                  className={classes.updateCardAvatar}
                  getName={getName}
                  userId={user?.id}
                />
                <Typography
                  style={{
                    fontSize: "13px",
                    fontWeight: "normal",
                    marginLeft: "10px",
                  }}
                >
                  <div>{getName(user?.id)}</div>
                  <div>
                    {sum} of {cardData?.estimateHour}h
                  </div>
                </Typography>
              </Box>
            </Typography>
          ) : null}
          {cardData?.label && cardData?.title && (
            <>
              <div>
                {!openToChangeTheUserStories ? (
                  <Typography
                    align="left"
                    title="click to change"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      lineHeight: " 27px",
                      color: " #000000",
                    }}
                    onClick={() => setOpenToChangeTheUserStories(true)}
                  >
                    <span>{`As ${persona?.name}`}</span>
                    <br />
                    <span>{` I want to do the ${
                      updateTitle ? updateTitle : cardData?.title
                    } process `}</span>
                    <br />
                    <span>{` so that I can ${
                      updateLabel ? updateLabel : cardData?.label
                    }`}</span>
                    <br />
                  </Typography>
                ) : (
                  <div
                    className={classes.flexBetweenCenter}
                    style={{
                      textAlign: "left",
                      margin: "43px 4px",
                    }}
                  >
                    {`As ${persona?.name} I want to do the `}
                    <div style={{ display: "inline" }}>
                      <Controller
                        name="title"
                        control={control}
                        defaultValue={cardData?.title}
                        rules={{
                          required: {
                            value: true,
                            message: "This is required",
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            style={{ maxWidth: "146px" }}
                            variant="outlined"
                            type="text"
                          />
                        )}
                      />
                      <ErrorMessages errors={errors} name="title" />
                    </div>
                    {` process so that I can `}
                    <div style={{ display: "inline" }}>
                      <Controller
                        name="label"
                        control={control}
                        defaultValue={cardData?.label}
                        rules={{
                          required: {
                            value: true,
                            message: "This is required",
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            style={{ maxWidth: "146px" }}
                            // label="processWhy*"
                            variant="outlined"
                            type="text"
                          />
                        )}
                      />
                      <ErrorMessages errors={errors} name="label" />
                    </div>
                    <div className={classes.shortIconForAcceptance}>
                      <Button
                        variant="contained"
                        onClick={() => setOpenToChangeTheUserStories(false)}
                      >
                        <CheckIcon />
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {/* Acceptance Criteria */}
              <div className={classes.flexBetweenCenter}>
                <div>
                  <Typography
                    style={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    Acceptance Criteria
                  </Typography>
                </div>
                <IconButton onClick={() => setOpenForUpdate(true)}>
                  <AddIcon />
                </IconButton>
              </div>
              <div>
                {openForUpdate && (
                  <div style={{ display: "inline" }}>
                    <QuillText
                      value={singleAcceptanceCriteria}
                      onChange={setSingleAcceptanceCriteria}
                    />

                    {/* acceptance criteria buttons */}
                    <div className={classes.shortIconForAcceptance}>
                      <Button
                        variant="contained"
                        onClick={handleCheckAcceptanceCriteria}
                      >
                        <CheckIcon />
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setSingleAcceptanceCriteria("");
                          setOpenForUpdate(false);
                        }}
                      >
                        <ClearIcon />
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {openAcceptanceForUpdate && (
                <div style={{ display: "inline" }}>
                  <QuillText
                    value={updateSingleAcceptanceCriteria}
                    onChange={setUpdateSingleAcceptanceCriteria}
                  />
                  {/* <TextField
                    size="large"
                    fullWidth
                    label="Acceptance Criteria *"
                    variant="outlined"
                    id="outlined-multiline-static"
                    placeholder="given&#10;when&#10;Then"
                    multiline
                    defaultValue={singleAcceptanceToUpdateCard.description}
                    onChange={(e) =>
                      setUpdateSingleAcceptanceCriteria(e.target.value)
                    }
                    rows={3}
                  />
                  <ErrorMessages
                    style={{
                      display: "inline-block",
                    }}
                    errors={errors}
                    name="title"
                  /> */}
                  {/* acceptance criteria buttons */}
                  <div className={classes.shortIconForAcceptance}>
                    <Button
                      variant="contained"
                      onClick={handleUpdateSingleAcceptanceForCard}
                    >
                      <CheckIcon />
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpenAcceptanceForUpdate(false);
                        setSingleAcceptanceToUpdateCard({});
                      }}
                    >
                      <ClearIcon />
                    </Button>
                  </div>
                </div>
              )}
              <div>
                {aceptanceCriteria &&
                  aceptanceCriteria.map((item, index) => (
                    <div
                      style={{
                        display:
                          singleAcceptanceToUpdateCard.id === item.id
                            ? "none"
                            : "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        maxWidth: "700px",
                        margin: "5px 0px",
                      }}
                      key={index}
                    >
                      <Tooltip placement="top" title={`Click to Update`} arrow>
                        <div
                          className={classes.acceptanceCriteriaStyle}
                          dangerouslySetInnerHTML={{
                            __html: getSingleAcceptanceCriteria(
                              item?.description
                            ),
                          }}
                          onClick={() => handleUpdateSingleForUpdateCard(item)}
                        ></div>
                      </Tooltip>
                      <IconButton
                        onClick={() => {
                          if (item.id.length !== 9) {
                            dispatch(
                              deleteSingleAcceptanceCriteria(
                                user.projectId,
                                item.id
                              )
                            );
                          }
                          setAceptanceCriteria(
                            aceptanceCriteria.filter(
                              (items) => items.id !== item.id
                            )
                          );
                        }}
                      >
                        <DeleteOutlineIcon style={{ color: "#FF0000" }} />
                      </IconButton>
                    </div>
                  ))}
              </div>
            </>
          )}
          {/* bugsLabel */}

          {cardData?.bugsLabel && (
            <div>
              {!openToChangeTheBugsLabel ? (
                <Typography
                  align="left"
                  title="click to change"
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    lineHeight: " 27px",
                    color: " #000000",
                    maxWidth: "1040px",
                  }}
                  onClick={() => setOpenToChangeTheBugsLabel(true)}
                >
                  {updateBugsLabel ? updateBugsLabel : cardData?.bugsLabel}
                </Typography>
              ) : (
                <div className={classes.flexStartCenter}>
                  <Controller
                    name="bugsLabel"
                    control={control}
                    defaultValue={cardData?.bugsLabel}
                    rules={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="medium"
                        fullWidth
                        label="Bugs Label*"
                        variant="outlined"
                        type="text"
                      />
                    )}
                  />
                  <ErrorMessages errors={errors} name="bugsLabel" />
                  <div
                    style={{ marginLeft: "10px" }}
                    className={classes.shortIconForAcceptance}
                  >
                    <Button
                      variant="contained"
                      onClick={() => setOpenToChangeTheBugsLabel(false)}
                    >
                      <CheckIcon />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
          {cardData?.choresLabel && (
            <div>
              {!openToChangeTheChoresLabel ? (
                <Typography
                  align="left"
                  title="click to change"
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    lineHeight: " 27px",
                    color: " #000000",
                    maxWidth: "1040px",
                  }}
                  onClick={() => setOpenToChangeTheChoresLabel(true)}
                >
                  {updateChoresLabel
                    ? updateChoresLabel
                    : cardData?.choresLabel}
                </Typography>
              ) : (
                <div className={classes.flexStartCenter}>
                  <Controller
                    name="choresLabel"
                    control={control}
                    defaultValue={cardData?.choresLabel}
                    rules={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="medium"
                        fullWidth
                        label="Task Label*"
                        variant="outlined"
                        type="text"
                      />
                    )}
                  />
                  <ErrorMessages errors={errors} name="choresLabel" />
                </div>
              )}
            </div>
          )}

          {/* for description */}
          {cardData?.type === "chore" || cardData?.type === "bug" ? (
            <div>
              <Box display={"flex"} justifyContent="space-between">
                <Typography
                  align="left"
                  title="click to change"
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    lineHeight: " 27px",
                    color: " #000000",
                    maxWidth: "1040px",
                  }}
                  onClick={() => {
                    setOpenDescription(true);
                  }}
                >
                  Description
                </Typography>
                {opendescription ? (
                  <div style={{ cursor: "pointer" }}>
                    <ClearIcon onClick={() => setOpenDescription(false)} />
                  </div>
                ) : null}
              </Box>
              <Box className={classes.flexStartCenter}>
                {!opendescription ? (
                  <div
                    style={{ textAlign: "left", maxWidth: "1040px" }}
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                    onClick={() => setOpenDescription(true)}
                  ></div>
                ) : null}
                {opendescription ? (
                  <>
                    <QuillText
                      custom={true}
                      value={description}
                      onChange={setDescription}
                    />
                    <ErrorMessages errors={errors} name="description" />
                  </>
                ) : null}
              </Box>
            </div>
          ) : null}

          {/*TODO Link to Design */}
          <div className={classes.flexBetweenCenter}>
            <Typography
              style={{
                color: "black",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Add link
            </Typography>
            {cardData?.linkToDesign ? (
              <IconButton onClick={() => setOnEditLinkToDesign(true)}>
                <EditIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => setOnEditLinkToDesign(true)}>
                <AddIcon />
              </IconButton>
            )}
          </div>
          {onEditLinkToDesign ? (
            <div style={{ display: "inline" }}>
              <Controller
                name="linkToDesign"
                control={control}
                defaultValue={cardData?.linkToDesign}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="medium"
                    fullWidth
                    variant="outlined"
                    label="Add link"
                    type="text"
                  />
                )}
              />

              <ErrorMessages
                style={{
                  display: "inline-block",
                }}
                errors={errors}
                name="linkToDesign"
              />
            </div>
          ) : (
            <Typography
              title="click to change the url"
              style={{
                color: "blue",
                fontWeight: "300",
                fontSize: "14px",
                textDecoration: "underline",
                wordBreak: "break-all",
                maxWidth: "770px",
                textAlign: "left",
              }}
            >
              <Link
                target="_blank"
                rel="noreferrer"
                href={withHttps(cardData?.linkToDesign)}
              >
                {cardData?.linkToDesign}
              </Link>
            </Typography>
          )}
          {/* Blockers */}
          <div className={classes.flexBetweenCenter}>
            <div>
              <Typography
                style={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                Blockers
              </Typography>
            </div>
            <IconButton onClick={() => setOpenBlockersSection(true)}>
              <AddIcon />
            </IconButton>
          </div>
          <div className={classes.toBlockersUpdateStyle}>
            {openBlockersSection && (
              <Autocomplete
                multiple
                limitTags={5}
                defaultValue={updateBlockers.filter(
                  (o1) =>
                    !boarsdData.appendedBlockers.some(
                      (o2) => o1.value === o2.value
                    )
                )}
                getOptionSelected={(option, value) => {
                  const optionTitle =
                    typeof option === "string" ? option : option.value;
                  const valueTitle =
                    typeof value === "string" ? value : value.value;
                  return optionTitle === valueTitle;
                }}
                onChange={(event, newValue) => {
                  if (Array.isArray(newValue)) {
                    const updatedArrayValue = newValue.filter((e) =>
                      typeof e === "string" ? e.trim() : e
                    );
                    const newArrayValue = [...updatedArrayValue];
                    const updatedArray = newArrayValue.map((item) => {
                      if (typeof item === "string") {
                        const newItem = {};
                        newItem.label = item;
                        newItem.value = nanoid(12);
                        newItem.type = "created";
                        return newItem;
                      }
                      return item;
                    });
                    setUpdateBlockers(updatedArray);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      label: `Add "${params.inputValue}"`,
                      value: nanoid(12),
                      type: "created",
                    });
                  }
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={toBlockers}
                filterSelectedOptions
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.label;
                }}
                renderOption={(option) => option.label}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Blockers"
                    variant="outlined"
                  />
                )}
              />
            )}
          </div>
          <div style={{ marginBottom: "4px" }}>
            {cardData?.blockers && cardData?.blockers.length > 0 && (
              <BlockersTable
                renderStyle={renderStyle}
                getName={getName}
                getAvatar={getAvatar}
                particularCard={particularCard}
                persona={persona?.name}
                blockers={cardData?.blockers}
              />
            )}
          </div>
          {/* Attachments */}
          <div className={classes.flexBetweenCenter}>
            <div>
              <Typography
                style={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                Attachments
              </Typography>
            </div>
            <div className={classes.updateDropzone}>
              <DropzoneArea
                showPreviewsInDropzone={false}
                showPreviews={false}
                maxFileSize={105000000}
                onChange={(files) => {
                  setUpdateFilesToSave(files);
                }}
                files={updateFilesToSave}
                onDelete={(file) => {}} // console.log(file)}
                filesLimit={20}
                dropzoneText={
                  <IconButton>
                    <AddIcon />
                  </IconButton>
                }
              />
            </div>
          </div>
          <Attachments
            updateFilesToSave={updateFilesToSave}
            setUpdateFilesToSave={setUpdateFilesToSave}
            projectId={user?.projectId}
            cardId={cardData?.id}
            organizationId={user?.projectId}
            AttachmentList={
              cardData?.attachmentList ? cardData?.attachmentList : []
            }
          />
          <div>
            <Typography
              style={{
                color: "black",
                fontWeight: "500",
                fontSize: "14px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              Notes
            </Typography>

            <Box display="flex" justifyContent="flex-start" mb="10px">
              <div>
                <UserAvatar
                  getAvatar={getAvatar}
                  style={{ marginRight: "8px" }}
                  getName={getName}
                  userId={user?.id}
                />
              </div>
              {openToComment ? (
                <div>
                  <Grid
                    style={{
                      maxWidth: "1014px",
                      borderRadius: "8px",
                    }}
                  >
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={onCommentDataChange}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      toolbar={{
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "link",
                          "image",
                          "history",
                        ],
                      }}
                      mention={{
                        separator: " ",
                        trigger: "@",
                        suggestions: suggestions,
                      }}
                    />
                  </Grid>
                  <div style={{ textAlign: "left" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={saveComment}
                    >
                      Save
                    </Button>
                    <Button
                      size="small"
                      style={{ textAlign: "left", marginLeft: "10px" }}
                      variant="contained"
                      onClick={removeComment}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              ) : (
                <TextField
                  onClick={() => setOpenToComment(true)}
                  style={{
                    maxWidth: "1014px",
                    cursor: "pointer",
                  }}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Add a comment..."
                />
              )}
            </Box>
          </div>
          {savedDescription && (
            <ShowNotes
              savedDescription={savedDescription}
              SetSavedDescription={SetSavedDescription}
              getName={getName}
              setUpdatenotes={setUpdatenotes}
              setUpdateToNotes={setUpdateToNotes}
              getAvatar={getAvatar}
            />
          )}
          {updatenotes && renderUpdateNotes()}
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              aligntItems: "center",
              marginTop: "20px",
            }}
          >
            <Button
              style={{
                marginRight: "6px",
              }}
              onClick={() => handleUpdateCardClose(true)}
              variant="contained"
              color="primary"
              type="button"
             
            >
              Close
            </Button>
            <Button color="primary" variant="contained" id="custom-submit-button" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Box>
    );
  };
  const handleChangeTypeOfSize = useCallback(() => {
    setSizeOfProblem((prev) => {
      if (sizeOfProblem >= 3) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }, [sizeOfProblem]);
  const handleUpdateTypeOfSize = useCallback(() => {
    setUpdateSizeOfProblem((prev) => {
      if (updateSizeOfProblem >= 3) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }, [updateSizeOfProblem]);

  // will work in next release
  const handleCardType = useCallback(() => {
    setCardType((prev) => {
      if (cardType >= 2) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }, [cardType]);

  const classes = useCustomStyles(filesToSave);
  const toUsers = organizationProfile?.map((user) => ({
    label: user.name,
    value: user.id,
  }));
  const suggestions = organizationProfile?.map((user) => ({
    text: user.name,
    url: user.id,
    value: user.name,
  }));
  const particularCard = kanbanData.map((item) => item.cards).flat(2);
  const particularCards = kanbanData;
  //css-2613qy-menu
  const toBlockers = particularCard?.map((block) => ({
    label: block?.title
      ? block?.title + " " + block.label
      : block?.bugsLabel
      ? block?.bugsLabel
      : block?.choresLabel,
    value: block.id,
  }));
  const toEpics = valueStream?.map((item) => ({
    label: item.valueStreamName,
    color: item.color,
    value: item.id,
  }));
  //created single acceptance criteria update function
  const handleUpdateSingleAcceptanceCriteria = (singleAccept) => {
    setSingleToUpdateAcceptanceCriteria(singleAccept);
    setOpenAcceptanceCriteria(true);
  };
  //  #TODO
  const handleUpdateSingleForUpdateCard = (singleAccept) => {
    setOpenAcceptanceForUpdate(true);
    setOpenForUpdate(false);
    setUpdateSingleAcceptanceCriteria(singleAccept.description);
    setSingleAcceptanceToUpdateCard(singleAccept);
  };
  //helpers--- getAvatar
  const getAvatar = (value) => {
    const newAvatar = organizationProfile.find(
      (item) => item.id === value && item
    );
    let newData;
    Object.keys(Avatars).find((key, index) => {
      if (key === newAvatar?.avatar) {
        newData = `${Avatars[newAvatar?.avatar]}`;
        return newData;
      }
      return newData;
    });
    return newData;
  };
  //helpers--- getName
  const getName = (value) => {
    const showingName = organizationProfile.find(
      (item) => item.id === value && item
    );
    return showingName?.name;
  };
  //helpers--- getEpicName
  const getEpicName = (value) => {
    const showingName = valueStream.find((item) => item.id === value && item);
    return showingName?.valueStreamName;
  };
  //helpers--- getEpicColor
  const getEpicColor = (value) => {
    const showingName = valueStream.find((item) => item.id === value && item);
    return showingName?.color;
  };
  const CreatedCard = (props) => {
    return (
      <NewCustomCard
        handleDeleteCard={handleDeleteCard}
        handleEditCard={handleEditCard}
        getAvatar={getAvatar}
        getName={getName}
        getEpicColor={getEpicColor}
        getEpicName={getEpicName}
        setCardId={setCardId}
        partData={props}
        persona={persona}
        logenable={logEnable}
      />
    );
  };
  const CreatedHeader = (props) => {
    return (
      <CustomLaneHeader
        kanbanId={searchId}
        props={props}
        kanbanData={kanbanData}
      />
    );
  };
  const NewButtonArise = () => {
    return <AddButton onClick={() => setModalOpenForAddCard(true)} />;
  };
  const components = {
    AddCardLink: NewButtonArise,
    LaneHeader: CreatedHeader,
    Card: CreatedCard,
  };

  // canban data

  const kanbans = useSelector((state) => state.kanban?.kanban);
  useEffect(() => {
    if (kanbans.length > 0) {
      const kanban = kanbans.find((item) => item.id === searchId);

      setkanbanName(
        kanban?.name?.replace(/([a-z])/i, (str, firstLetter) =>
          firstLetter.toUpperCase()
        )
      );
    }
  }, [kanbans]);
  const handleChangeBackground = (key) => {
    dispatch(
      updateSingleBoardBckground(user?.projectId, searchId, {
        backgroundImage: key.actualImage,
      })
    );
  };

  return (
    <Layout
      pageName={kanbanName}
      type={"board"}
      handleChangeBackground={handleChangeBackground}
      board
      custom={true}
      searchId={searchId}
      search={search}
    >
      {openAlert && (
        <CustomSnacbar
          opened={openAlert}
          alertToDelete={alertToDelete}
          handleDeleteFromBackend={handleDeleteFromBackend}
          setOpenAlert={setOpenAlert}
          type="card"
        />
      )}
      <Box className="BoardClass">
        {kanbanData ? (
          <Board
            editable
            style={{
              ...globalStyle.style,
              backgroundImage: `linear-gradient(
              -220deg,
              rgba(0, 36, 51, 1) 0%,
              rgba(98, 218, 255, 0.164) 100%
              ),url(${
                background?.backgroundImage
                  ? background?.backgroundImage
                  : images.updatedHomeLogo
              })`,
              padding: "10px",
              backgroundPosition: "center",
              backgroundSize: background?.backgroundImage ? "cover" : "auto",
              backgroundRepeat: "no-repeat",
            }}
            laneStyle={globalStyle.laneStyle}
            collapsibleLanes={true}
            hideCardDeleteIcon={true}
            cardDraggable={true}
            draggable
            data={data}
            handleLaneDragEnd={(removedIndex, addedIndex, payload) => {
              handleLaneDragEnd(removedIndex, addedIndex, payload);
            }}
            components={components}
            handleDragEnd={(
              cardId,
              sourceLaneId,
              targetLaneId,
              position,
              cardDetails
            ) =>
              handleCardToAnotherLane(
                cardId,
                sourceLaneId,
                targetLaneId,
                position,
                cardDetails
              )
            }
          />
        ) : (
          <Loader />
        )}

        {openalertnew && (
          <ModalCloseSnackBar
            opened={openalertnew}
            setOpenAlert={setOpenAlertnew}
            handleCloseCardModal={handleUpdateCardCloseConfirm}
          />
        )}
        {modalOpen && handleCardSection()}
        {/* {modalOpenForAddCard && handleAddCardToTheTrelloBoard()} */}
        {modalOpenForAddCard && (
          <AddCardForCustom
            user={user}
            modalOpenForAddCard={modalOpenForAddCard}
            handleCreateSubmit={handleCreateSubmit}
            onCreateSubmit={onCreateSubmit}
            classes={classes}
            persona={persona}
            handleChangeTypeOfSize={handleChangeTypeOfSize}
            sizeOfProblem={sizeOfProblem}
            controlForCreate={controlForCreate}
            createError={createError}
            handleCreateModalClose={handleCreateModalClose}
            handleCardType={handleCardType}
            setFilesToSave={setFilesToSave}
            logEnable={logEnable}
            renderStyle={renderStyle}
            cardType={cardType}
            setOpenTextFieldToAcceptance={setOpenTextFieldToAcceptance}
            openTextFieldToAcceptance={openTextFieldToAcceptance}
            singleAcceptanceCriteria={singleAcceptanceCriteria}
            setSingleAcceptanceCriteria={setSingleAcceptanceCriteria}
            openAcceptanceCriteria={openAcceptanceCriteria}
            singleToUpdateAcceptanceCriteria={singleToUpdateAcceptanceCriteria}
            setUpdateSingleAcceptanceCriteria={
              setUpdateSingleAcceptanceCriteria
            }
            handleCreateUpdateAcceptanceCriteria={
              handleCreateUpdateAcceptanceCriteria
            }
            setOpenAcceptanceCriteria={setOpenAcceptanceCriteria}
            setSingleToUpdateAcceptanceCriteria={
              setSingleToUpdateAcceptanceCriteria
            }
            createAceptanceCriteria={createAceptanceCriteria}
            getSingleAcceptanceCriteria={getSingleAcceptanceCriteria}
            setcreateAceptanceCriteria={setcreateAceptanceCriteria}
            toUsers={toUsers}
            setAssignee={setAssignee}
            getAvatar={getAvatar}
            getName={getName}
            toEpics={toEpics}
            setEpicId={setEpicId}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setToEmails={setToEmails}
            toBlockers={toBlockers}
          />
        )}
      </Box>
    </Layout>
  );
};

export default CustomBoard;
