import axios from "../../api/axios";
import { errorAlert, successAlert } from "Utils";
import { cardconfig } from "./constant";

export const getAllCardConfiguration = (userId, projectIds) => {
    return async (dispatch) => {
        dispatch({ type: cardconfig.GET_ALL_PROFILE_CONFIG_REQUEST_CARD });
        try {
            const res = await axios.get(
                `/v1/projects/cardconfig/getAllConfigs/${userId}/${projectIds}`
            );
            if (res.status === 200) {
                dispatch({
                    type: cardconfig.GET_ALL_PROFILE_CONFIG_SUCCESS_CARD,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: cardconfig.GET_ALL_PROFILE_CONFIG_ERROR_CARD,
                payload: error.message,
            });
        }
    };
};

export const getSingleCardConfiguration = (userId) => {
    return async (dispatch) => {
        dispatch({ type: cardconfig.GET_SINGLE_PROFILE_CONFIG_REQUEST_CARD });
        try {
            const res = await axios.get(`/v1/projects/cardconfig/${userId}`);
            dispatch({
                type: cardconfig.GET_SINGLE_PROFILE_CONFIG_SUCCESS_CARD,
                payload: res.data,
            });
        } catch (error) {
            // console.log({ error });
        }
    };
};
export const getSingleCardConfig = (projectId) => {
    return async (dispatch) => {
        dispatch({ type: cardconfig.GET_ALL_HOME_REQUEST_CARD });
        try {
            const res = await axios.get(
                `/v1/projects/cardconfig/getSingle/${projectId}`
            );
            if (res.data === "") {
                const newitem = {
                    feature: true,
                    bug: true,
                    task: true,
                    issue: true,
                    risk: true,
                    projectId,
                };
                dispatch({
                    type: cardconfig.GET_ALL_HOME_SUCCESS_CARD,
                    payload: newitem,
                });
            } else {
                dispatch({
                    type: cardconfig.GET_ALL_HOME_SUCCESS_CARD,
                    payload: res.data,
                });
            }
        } catch (error) {
            // console.log({ error });
        }
    };
};


export const saveCardCofiguration = (requestBody) => {
    return async (dispatch) => {
        try {
            const responseBody = await axios.post(
                `/v1/projects/cardconfig/saveConfig`,
                requestBody
            );
            // console.log(responseBody);
            if (responseBody.status === 200) {
                dispatch({ type: cardconfig.SAVE_CONFIG_CARD });
                successAlert("Configuration Saved Successfully");
            }
        } catch (error) {
            errorAlert(error.status);
        }
    };
};
