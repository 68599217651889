const initialAssesment = [
  {
    id: 1,
    placeholder: "Impact on time",
    name: "impactOnTime",
    severity: "Negligible",
    impact: 1,
    likelihood: "Very unlikely",
    value: "",
  },
  {
    id: 2,
    placeholder: "Impact on cost",
    name: "impactOnCost",
    severity: "Negligible",
    impact: 1,
    likelihood: "Very unlikely",
    value: "",
  },
  {
    id: 3,
    placeholder: "Impact on customer satisfaction",
    name: "impactOnSatisfaction",
    severity: "Negligible",
    impact: 1,
    likelihood: "Very unlikely",
    value: "",
  },
  {
    id: 4,
    placeholder: "Impact on reputation",
    name: "impactOnReputation",
    severity: "Negligible",
    impact: 1,
    likelihood: "Very unlikely",
    value: "",
  },
];
const severityTable = [
  {
    id: 1,
    title: "Negligible",
    color: "black",
    likelihood: [
      {
        title: "Very Likely",
        color: "black",
        impactKey: 2,
      },
      {
        title: "Likely",
        color: "#4285F4",
        impactKey: 1,
      },
      {
        title: "Possible",
        color: "#FFAB40",
        impactKey: 1,
      },
      {
        title: "Unlikely",
        color: "#E06666",
        impactKey: 1,
      },
      {
        title: "Very unlikely",
        impactKey: 1,
        color: "red",
      },
    ],
  },
  {
    id: 2,
    title: "Minor",
    color: "#4285F4",
    likelihood: [
      {
        title: "Very Likely",
        color: "black",
        impactKey: 3,
      },
      {
        title: "Likely",
        color: "#4285F4",
        impactKey: 2,
      },
      {
        title: "Possible",
        color: "#FFAB40",
        impactKey: 2,
      },
      {
        title: "Unlikely",
        impactKey: 2,
        color: "#E06666",
      },
      {
        title: "Very unlikely",
        impactKey: 1,
        color: "red",
      },
    ],
  },

  {
    id: 3,
    title: "Moderate",
    color: "#FFAB40",
    likelihood: [
      {
        title: "Very Likely",
        color: "black",
        impactKey: 4,
      },
      {
        title: "Likely",
        color: "#4285F4",
        impactKey: 3,
      },
      {
        title: "Possible",
        color: "#FFAB40",
        impactKey: 3,
      },
      {
        title: "Unlikely",
        color: "#E06666",
        impactKey: 2,
      },
      {
        title: "Very unlikely",
        impactKey: 2,
        color: "red",
      },
    ],
  },

  {
    id: 4,
    title: "Significant",
    color: "#E06666",
    likelihood: [
      {
        title: "Very Likely",
        color: "black",
        impactKey: 5,
      },
      {
        title: "Likely",
        color: "#4285F4",
        impactKey: 4,
      },
      {
        title: "Possible",
        color: "#FFAB40",
        impactKey: 4,
      },
      {
        title: "Unlikely",
        color: "#E06666",
        impactKey: 3,
      },
      {
        title: "Very unlikely",
        impactKey: 3,
        color: "red",
      },
    ],
  },
  //  color: "black",
  // color: "#4285F4",
  // color: "#FFAB40",
  // color: "#E06666",
  // color: "red",
  {
    id: 5,
    title: "Severe",
    color: "red",
    likelihood: [
      {
        title: "Very Likely",
        color: "black",
        impactKey: 5,
      },
      {
        title: "Likely",
        color: "#4285F4",
        impactKey: 5,
      },
      {
        title: "Possible",
        color: "#FFAB40",
        impactKey: 4,
      },
      {
        title: "Unlikely",
        color: "#E06666",
        impactKey: 4,
      },
      {
        title: "Very unlikely",
        impactKey: 3,
        color: "red",
      },
    ],
  },
];

let impactTable = [
  {
    key: 1,
    title: "Low",
    color: "#00b53e",
  },
  {
    key: 2,
    title: "Low Medium",
    color: "#7ed232",
  },
  {
    key: 3,
    title: "Medium",
    color: "#fdfe00",
  },
  {
    key: 4,
    title: "Medium High",
    color: "#ffbe04",
  },
  {
    key: 5,
    title: "High",
    color: "#fe0000",
  },
];

export const appendedBlockers = [
  { label: "creative", value: "node_process_Lh1L8RsafJbUas" },
  { label: "Azim__bro", value: "node_process_LhL1s8RaafJbUas" },
];
export const rawContentForIceBox = (data) => {
  const rawContent = {
    blocks: [
      {
        key: "63m5k",
        text: "Created by risk and issue square and this is the ticket number  ",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 48,
            style: "color-rgba(0,0,0,0.87)",
          },
          {
            offset: 0,
            length: 48,
            style: "bgcolor-rgb(255,255,255)",
          },
          {
            offset: 49,
            length: 13,
            style: "bgcolor-rgb(255,255,255)",
          },
          {
            offset: 0,
            length: 48,
            style: "fontsize-14",
          },
          {
            offset: 49,
            length: 13,
            style: "fontsize-14",
          },
          {
            offset: 0,
            length: 48,
            style: "fontfamily-Asap",
          },
          {
            offset: 49,
            length: 13,
            style: "fontfamily-Asap",
          },
        ],
        entityRanges: [
          {
            offset: 49,
            length: 13,
            key: 0,
          },
        ],
        data: {},
      },
    ],
    entityMap: {
      0: {
        type: "LINK",
        mutability: "MUTABLE",
        data: {
          url: `${data}`,
          title: "ticket number",
          targetOption: "_blank",
          _map: {
            type: "LINK",
            mutability: "MUTABLE",
            data: {
              url: `${data}`,
              title: "ticket number",
              targetOption: "_blank",
            },
          },
        },
      },
    },
  };
  return rawContent;
};

export const rawContent = {
  blocks: [
    {
      key: "5giiu",
      text: "m",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
};
const getColors = (assesments) => {
  const getColorNumber = Math.max(...assesments.map((item) => item.impact));
  const getColor = impactTable.find((item) => item.key === getColorNumber);
  return getColor;
};
const style = {
  height: "100%",
  backgroundColor: "transparent",
  padding: "10px",
};
const laneStyle = {
  borderRadius: "6px",
  background: "#F3F3F3",
  boxShadow: "1px 1px 4px gray",
  paddingTop: "15px",
};
export const riskAndIssuesData = {
  severityTable,
  style,
  laneStyle,
  initialAssesment,
  appendedBlockers,
  getColors,
  rawContent,
  impactTable,
  rawContentForIceBox,
};
