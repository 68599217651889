import { FormControlLabel, IconButton, makeStyles } from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
const MatEdit = () => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.root}
      control={
        <IconButton
          title="delete  this workflow"
          className={classes.addButton}
          color="secondary"
          aria-label="add an alarm"
        >
          <RemoveCircleIcon style={{ color: "red" }} />
        </IconButton>
      }
    />
  );
};
const MatAdd = () => {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <IconButton
          title="add a new workflow"
          color="secondary"
          aria-label="add an alarm"
          className={classes.addButton}
        >
          <AddCircleOutlineIcon style={{ color: "green" }} />
        </IconButton>
      }
    />
  );
};

const deleteAndAddIcons = [
  {
    field: "Delete",
    headerName: "",
    sortable: false,
    width: 125,
    renderCell: (params) => {
      return (
        <div>
          <MatEdit index={params.row.id} />
        </div>
      );
    },
  },
  {
    field: "Add",
    headerName: "Add",
    sortable: false,
    width: 123,
    renderCell: (params) => {
      return (
        <div>
          <MatAdd index={params.row.id} />
        </div>
      );
    },
  },
];
const deleteAndAddIconsForBoard = [
  {
    field: "Delete",
    headerName: "",
    sortable: false,
    width: 195,
    renderCell: (params) => {
      return (
        <div>
          <MatEdit index={params.row.id} />
        </div>
      );
    },
  },
  {
    field: "Add",
    headerName: "Add",
    sortable: false,
    width: 193,
    renderCell: (params) => {
      return (
        <div>
          <MatAdd index={params.row.id} />
        </div>
      );
    },
  },
];
const tasksColumns = [
  {
    field: "choresLabel",
    headerName: "Task",
    width: 650,
    sortable: false,
    description: "Source",
    editable: true,
  },
  ...deleteAndAddIconsForBoard,
];
const bugsColumns = [
  {
    field: "bugsLabel",
    headerName: "Bug",
    width: 650,
    sortable: false,
    description: "Source",
    editable: true,
  },
  ...deleteAndAddIconsForBoard,
];
const riskColumns = [
  {
    field: "title",
    headerName: "Risk",
    width: 450,
    sortable: false,
    description: "Source",
    editable: true,
  },
  ...deleteAndAddIcons,
];
const issueColumns = [
  {
    field: "title",
    headerName: "Issue",
    width: 450,
    sortable: false,
    description: "Source",
    editable: true,
  },
  ...deleteAndAddIcons,
];
export { tasksColumns, bugsColumns, riskColumns, issueColumns };

const useStyles = makeStyles(() => ({
  addButton: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
  },
}));
