import { getSingleCardConfig } from 'redux/actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useRenderCardConfig = () => {
    const dispatch = useDispatch();
    const { cardRenderer, loading } = useSelector(state => state.cardConfig);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (user) {
            dispatch(getSingleCardConfig(user?.projectId))
        }
    }, [user?.projectId])
    return { cardRenderer, loading }
}

export default useRenderCardConfig;