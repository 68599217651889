import { Autocomplete } from "@material-ui/lab";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import { ErrorMessages } from "..";

import { forwardRef } from 'react';
/**
 *@function MUIAutoComplete.jsx
 *@author Azim
 *
 **/
const  MUIAutoComplete = forwardRef((props, ref) => {
 const {
  options,
  getOptionLabel,
  renderOption,
  renderInput,
  name,
  control,
  size,
  value,
  style,
  close,
  errors,
  rules,
  ...arg
} = props;
  return (
    <Fragment>
      <Controller
        rules={
          rules
            ? rules
            : {
                required: {
                  value: true,
                  message: "This is required",
                },
              }
        }
        render={({ field: { onChange, ..._field } }) => (
          <Autocomplete
            fullWidth={true}
            ref={ref}
            id="blur-on-select"
            blurOnSelect
            style={style}
            size={size}
            options={options}
            {...arg}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            renderInput={renderInput}
            onChange={
              close
                ? (e, data) => {
                    onChange(data);
                    close();
                  }
                : (e, data) => onChange(data)
            }
            {..._field}
          />
        )}
        defaultValue={value}
        name={name}
        control={control}
      />
      {errors && <ErrorMessages errors={errors} name={name} />}
    </Fragment>
  );
})

export default MUIAutoComplete;
