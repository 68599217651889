import { Box, Button, Typography } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const FirstCard = (props) => {
    const history = useHistory()
    const {
        titleVariant,
        subtitle,
        titleText,
        description,
        isButton,
        buttonText,
        linkTo,
        linkData,
        children,
        workmanage,
        handleDif,
        dif,
    } = props

    const { velocity } = useSelector(state => state.kanban)

    return (
        <div style={{
            boxShadow: '-9px 0px 9px -4px rgba(229, 229, 229, 0.7), 9px 9px 9px -4px rgba(229, 229, 229, 0.7)',
            borderRadius: '10px',
            marginBottom: '20px',
            marginTop: '20px',
            height: '285px',
            padding: '20px 25px 137px 20px',
            backgroundColor: '#fff',
        }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                <Box>
                    <Typography
                        style={{
                            letterSpacing: '0.18px',
                            fontSize: '24px',
                            color: '#000',
                            fontWeight: 'bold',
                            cursor: "pointer",
                            zIndex: "9"
                        }}
                        variant={titleVariant}
                        color={"secondary"}
                        onClick={() => history.push("/board")}
                    >{titleText}
                    </Typography>
                    <Typography
                        style={{
                            letterSpacing: "0.18px",
                            fontSize: "10px",
                            color: "#acacac",
                            fontWeight: "bold",
                            cursor: "pointer",
                            fontStyle: "italic",

                        }}>
                        {subtitle ?? ""}
                    </Typography>
                </Box>
                
                {workmanage === true ? <>
                    <Box marginRight={"2rem"}>
                        <Box display={"flex"} justifyContent="start">
                            <Box marginLeft={"1rem"}>Days</Box>
                            {
                                [1,7,14,30].map((item, index) => {
                                    return <Box key={index} marginLeft={"1rem"} onClick={() => velocity?.length > item && handleDif(item)} style={velocity?.length > item ? { textDecoration: item === dif ? "underline":"none", cursor:"pointer" } : {cursor:"not-allowed"}}>{item}</Box>
                                })
                            }
                            
                        </Box>
                    </Box>
                </>:null}
                {
                    linkTo && <Link to={`/${linkTo}`}><Typography>{linkData}</Typography></Link>
                }
            </div>
            <Typography style={{ margin: '30px 0px 0px 0px' }} variant="body2">{description}</Typography>
            {
                isButton && <Button
                    color="primary"
                    style={{ textAlign: 'center' }}
                    startIcon={
                        <AddCircle />
                    }
                    variant="outlined"
                >{buttonText}</Button>
            }
            {children}
        </div>
    )
}
export default FirstCard;