import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Tooltip } from '@material-ui/core';
/**
 *@function MuiDatePicker.jsx
 *@author Azim
 *
 **/

const MuiDatePicker = ({
  keyboard,
  className,
  value,
  onChange,
  label,
  title,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {!keyboard ? (
            <Tooltip title="click to change date" arrow placement='top'>
              <DatePicker
                disableToolbar
                className={className}
                format="dd-MM-yyyy"
                placeholder={`Due date`}
                value={value}
                onChange={onChange}
              />
            </Tooltip>
      ) : (
        <Tooltip title="click to change date" arrow placement='top'>
          <KeyboardDatePicker
            className={className}
            title={title}
            disableToolbar
            format="dd-MM-yyyy"
            margin="normal"
            variant="outlined"
            id="date-picker-inline"
            label={label ? label : "Due date"}
            value={value}
            fullWidth
            onChange={onChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Tooltip>
      )}
    </MuiPickersUtilsProvider>
  );
};

export default MuiDatePicker;
