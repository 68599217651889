import { Modal } from "Components/Reusable";
import { Avatar, Box, Button, Paper, Tooltip } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { ErrorMessages } from "Components/Reusable";
import { useForm } from "react-hook-form";
import { useToggle } from "hooks/useToggle";
import { useState } from "react";
import { Person } from "@material-ui/icons";
import { ImageOrAvatarSelector } from "..";
import Avatars from "assets/Avatars";
import { useDispatch, useSelector } from "react-redux";
import { createPersonas } from "redux/actions";
export const AddPersona = ({
  classes,
  openModalPersona,
  setOpenModalPersona,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [isTextChanged, setIsTextChanged] = useToggle();
  const randomAvatar =
    Object.keys(Avatars)[
      Math.floor(Math.random() * Object.keys(Avatars).length)
    ];
  const [avatarNew, setAvatarNew] = useState(randomAvatar);
  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    reset,
    register,
  } = useForm();
  const onPersonaSubmit = (data) => {
    const newData = {
      ...data,
      avatar: avatarNew,
      projectId: user?.projectId,
    };
    if (user?.projectId) {
      dispatch(createPersonas(user?.projectId, newData, []));
    }
    setOpenModalPersona(false);
  };
  const handleChangeAvatar = (avatar) => {
    setAvatarNew(avatar);
  };
  const handleAvatarChange = () => {
    return (
      <Modal
        open={isTextChanged}
        handleClose={() => setIsTextChanged(false)}
        settings
      >
        <ImageOrAvatarSelector
          handleChangeBackground={handleChangeAvatar}
          wording={"Select Your Persona Avatar"}
          setOpenSetting={setIsTextChanged}
          avatar
        />
      </Modal>
    );
  };
  const getAvatar = () => {
    let newData;
    Object.keys(Avatars).find((key) => {
      if (key === avatarNew) {
        newData = `${Avatars[avatarNew]}`;
        return newData;
      }
      return newData;
    });
    return newData;
  };
  return (
    <Modal
      open={openModalPersona}
      handleClose={() => {
        setOpenModalPersona(false);
        reset();
      }}
      overflowAuto
    >
      {isTextChanged === true ? handleAvatarChange() : null}
      <Box maxHeight={800} style={{ overflowY: "auto" }}>
        <form
          style={{ padding: "20px", maxWidth: "780px" }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onPersonaSubmit)}
        >
          <h1 style={{ textAlign: "left" }}>Add new Persona</h1>
          <TextField
            style={{ margin: "10px" }}
            variant="outlined"
            fullWidth
            label="Persona name*"
            {...register("name", {
              required: {
                value: true,
                message: "This is field required",
              },
              maxLength: {
                value: 60,
                message: "Can't be more then 60 characters",
              },
              minLength: {
                value: 3,
                message: "min character is 3",
              },
              pattern: {
                value: /^[^\s].*$/,
                message:
                  "Entered value can't start/end or contain only white spacing",
              },
            })}
          />
          <ErrorMessages errors={errors} name="name" />

          <Box ml={2} mt={2}>
            <Paper
              style={{
                height: "30vh",
                width: "350px",
                cursor: "pointer",
              }}
              elevation={2}
            >
              <Tooltip title="Click to change avatar" placement="top" arrow>
                <Avatar
                  style={{
                    position: "relative",
                    backgroundColor: "#e5e5e5",
                    color: "#9a9a9a",
                    top: "21%",
                    height: "120px",
                    width: "120px",
                  }}
                  className={classes.firstpaper}
                  src={getAvatar()}
                  onClick={() => setIsTextChanged(true)}
                >
                  <Person style={{ fontSize: "46px" }} />
                </Avatar>
              </Tooltip>
            </Paper>
          </Box>
          <br />
          <Button
            style={{ margin: "10px" }}
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
          >
            Add Persona
          </Button>
        </form>
      </Box>
    </Modal>
  );
};
