import {
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Modal, MUIAutoComplete } from "Components/Reusable";
import { Delete as DeleteIcon } from "@material-ui/icons";
/**
 *@function NewModalForEdge.jsx
 *@author Azim
 *
 **/

const NewModalForEdge = ({
  edgeEditModal,
  handleCloseModalData,
  handleSubmitted,
  defaultValue,
  setEdgeLabel,
  handleDeleteElement,
  deletedData,
}) => {
  return (
    <Modal settings open={edgeEditModal} handleClose={handleCloseModalData}>
      <Box>
        <Typography
          style={{
            padding: "10px 48px",
            marginTop: "0px",
            fontWeight: "500",
            background: "#7478E8",
            fontSize: "24px",
            color: "#F8F8F8",
            borderBottom: "2px solid black",
          }}
          align="left"
          variant="h5"
          component="h6"
          gutterBottom
        >
          Edit Edge Text
        </Typography>
        <Box mt="20px" p="30px">
          <form onSubmit={handleSubmitted}>
            <Grid container>
              <Grid item md={12}>
                <TextField
                  type="text"
                  label="Line Name"
                  placeholder="Enter your Edge Line"
                  defaultValue={defaultValue}
                  fullWidth
                  onChange={(e) => setEdgeLabel(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box display="flex" justifyContent="flex-end" mr="6px">
          <Tooltip arrow title="click to delete this edge" placement="top">
            <IconButton onClick={() => handleDeleteElement(deletedData)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewModalForEdge;
