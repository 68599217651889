import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { Box } from "@material-ui/core";

const ModalCloseSnackBar = ({
    opened,
    setOpenAlert,
    handleCloseCardModal,
}) => {
    const handleClose = () => {
        setOpenAlert(false);
    };
    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={opened}
                onClose={handleClose}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        background: "#ffffff",
                        width: "421px",
                        height: "177px",
                        boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.25)",
                        borderRadius: "5px",
                    }}
                >
                    <div>
                        <Box style={{textAlign:"center"}}>
                            <p style={{ color: "black", fontWeight: "600", fontSize: "15px" }}>
                                Are you sure you want to close?
                            </p>
                            <p style={{ color: "gray", margin: '0' }}>
                                You have some unsaved changes.
                            </p>
                            <p style={{ color: "gray", margin: 0 }}>
                                Please click the submit button
                            </p>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="row"
                            marginTop="10px"
                        >
                            <Button
                                style={{
                                    marginLeft: "5px",
                                }}
                                onClick={handleClose}
                                variant="contained"
                                fullWidth
                                size="medium"
                            >
                                No
                            </Button>
                            <Button
                                onClick={() => {
                                    handleCloseCardModal()
                                }}
                                style={{
                                    marginLeft: "10px",
                                }}
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="medium"
                            >
                                Yes
                            </Button>
                        </Box>

                    </div>
                </Box>
            </Snackbar>
        </div>
    );
};
export default ModalCloseSnackBar;
