import Layout from "Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  TextField,
  TextareaAutosize,
  Button,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessages } from "Components/Reusable";
import { createStatement, getPersonas, updateStatement } from "redux/actions";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { PersonaLayout } from "Components/UtilComponents";
import { useState } from "react";
/**
 *@function ProblemStatement.jsx
 *@author Shahed
 *
 **/

const ProblemStatement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { statement, loading } = useSelector((state) => state.problemstatement);
  const [menuOpen, setMenuOpen] = useState(true);
  const history = useHistory();
  const params = useParams();
  const { personas } = useSelector((state) => state.personas);
  const auth = useSelector((state) => state.auth);
  const { projectId } = auth?.user;
  const thispersonas = personas.find((value) => value.id === params.personasid);

  useEffect(() => {
    if (projectId) {
      dispatch(getPersonas(projectId));
    }
  }, [projectId, params.personasid]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    watch
  } = useForm();

  watch(['userNeeds', 'userProblem','rootCause', 'userEmotion'])

  const { user } = useSelector((state) => state.auth);

  const onSubmit = (data) => {
    const formData = {
      projectId: user?.projectId,
      personasId: params.personasid,
      ...data,
    };
    if (statement.length) {
      dispatch(updateStatement(statement, user?.projectId, formData));
    } else {
      dispatch(createStatement(user?.projectId, formData));
    }
    history.push("/");
  };

  return (
    <Layout pageName={"Problem Statement"}>
      <PersonaLayout
        menuOpen={menuOpen}
        classes={classes}
        setMenuOpen={setMenuOpen}
        personas={personas}
        personasId={params.personasid}
        user={user}
        pushWord={"problemstatement"}
        projectId={user?.projectId}
      >
        <Grid
          container
          style={{
            padding: "0px 10px 10px 10px",
            marginLeft: "7px",
            marginTop: "5px",
          }}
        >
          {!loading && thispersonas !== undefined ? (
            <>
              <Box className={classes.addUserStyle}>
                <Box className={classes.formpanel}>
                  <Box padding={"3em"} className={classes.formSection}>
                    <form
                      className={classes.root}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <p className={classes.textStyle}>I am</p>
                        <Controller
                          name="userName"
                          control={control}
                          defaultValue={
                            thispersonas !== undefined &&
                              Object.keys(thispersonas)?.length
                              ? thispersonas && thispersonas.name
                              : ""
                          }
                          rules={{
                            required: {
                              value: true,
                              message: "Name is required",
                            },
                          }}
                          render={({ field }) => (
                            <>
                              <TextField
                                disabled
                                {...field}
                                variant="outlined"
                                size="small"
                                placeholder="User Name *"
                                fullWidth
                              />
                            </>
                          )}
                        />
                      </Box>
                      <Box textAlign={"right"} marginBottom={"1rem"}>
                        <ErrorMessages errors={errors} name="name" />
                      </Box>

                      <Box display={"flex"} justifyContent={"space-between"}>
                        <p className={classes.textStyle}>I am trying to</p>
                        <Controller
                          name="userNeeds"
                          control={control}
                          defaultValue={
                            statement?.length ? statement[0]?.userNeeds : ""
                          }
                          rules={{
                            required: {
                              value: true,
                              message: "User Needs are required",
                            },
                          }}
                          render={({ field }) => (
                            <TextareaAutosize
                              {...field}
                              minRows={3}
                              className={classes.textareaStyle}
                              placeholder="User Needs *"
                              style={{ width: "100%" }}
                            />
                          )}
                        />
                      </Box>
                      <Box textAlign={"right"} marginBottom={"1rem"}>
                        <ErrorMessages errors={errors} name="userNeeds" />
                      </Box>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <p className={classes.textStyle}>But</p>
                        <Controller
                          name="userProblem"
                          control={control}
                          defaultValue={
                            statement?.length ? statement[0]?.userProblem : ""
                          }
                          rules={{
                            required: {
                              value: true,
                              message: "User problems are required",
                            },
                          }}
                          render={({ field }) => (
                            <TextareaAutosize
                              {...field}
                              minRows={3}
                              className={classes.textareaStyle}
                              placeholder="User Problem *"
                              style={{ width: "100%" }}
                            />
                          )}
                        />
                      </Box>
                      <Box textAlign={"right"} marginBottom={"1rem"}>
                        <ErrorMessages errors={errors} name="userProblem" />
                      </Box>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <p className={classes.textStyle}>Because</p>
                        <Controller
                          name="rootCause"
                          control={control}
                          defaultValue={
                            statement?.length ? statement[0]?.rootCause : ""
                          }
                          rules={{
                            required: {
                              value: true,
                              message: "Please specify root cause",
                            },
                          }}
                          render={({ field }) => (
                            <TextareaAutosize
                              {...field}
                              minRows={3}
                              className={classes.textareaStyle}
                              placeholder="Root Cause *"
                              style={{ width: "100%" }}
                            />
                          )}
                        />
                      </Box>
                      <Box textAlign={"right"} marginBottom={"1rem"}>
                        <ErrorMessages errors={errors} name="rootCause" />
                      </Box>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <p className={classes.textStyle}>Which makes me feel</p>
                        <Controller
                          name="userEmotion"
                          control={control}
                          defaultValue={
                            statement?.length ? statement[0]?.userEmotion : ""
                          }
                          rules={{
                            required: {
                              value: true,
                              message: "User emotion is required",
                            },
                          }}
                          render={({ field }) => (
                            <TextareaAutosize
                              {...field}
                              minRows={3}
                              className={classes.textareaStyle}
                              placeholder="User Emotion *"
                              style={{ width: "100%" }}
                            />
                          )}
                        />
                      </Box>
                      <Box textAlign={"right"} marginBottom={"1rem"}>
                        <ErrorMessages errors={errors} name="userEmotion" />
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        marginBottom={"1rem"}
                      >
                        <p className={classes.textStyle}></p>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          {statement?.length ? "Update" : "Create"}
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
              <Box ml="3rem" width="500px">
                <div>
                  <p style={{ fontSize: "18px", wordBreak: "break-word" }}>
                    I am 
                    {getValues("userName") ? (
                      <strong style={{marginLeft: "5px"}}>{getValues("userName")}</strong>
                    ) : (
                        <span style={{ fontStyle: "italic", color: "gray", marginLeft: "5px" }}>{"No input"}</span>
                    )}
                    . I am trying to 
                    {getValues("userNeeds") ? (
                      <strong style={{ marginLeft: "5px" }}>{getValues("userNeeds")}</strong>
                    ) : (
                        <span style={{ fontStyle: "italic", color: "gray", marginLeft: "5px" }}>{"No input"}</span>
                    )}
                    . But
                    {getValues("userProblem") ? (
                      <strong style={{ marginLeft: "5px" }}>{getValues("userProblem")}</strong>
                    ) : (
                        <span style={{ fontStyle: "italic", color: "gray", marginLeft: "5px" }}>{"No input"}</span>
                    )}
                    . Because
                    {getValues("rootCause") ? (
                      <strong style={{ marginLeft: "5px", marginRight:"5px" }}>{getValues("rootCause")}</strong>
                    ) : (
                        <span style={{ fontStyle: "italic", color: "gray", marginLeft: "5px", marginRight: "5px" }}>{"No input"}</span>
                    )}
                    which makes me feel
                    {getValues("userEmotion") ? (
                      <strong style={{ marginLeft: "5px" }}>{getValues("userEmotion")}</strong>
                    ) : (
                        <span style={{ fontStyle: "italic", color: "gray", marginLeft: "5px" }}>{"No input"}</span>
                    )}.
                  </p>
                </div>

              </Box>
            </>
          ) : null}
        </Grid>
      </PersonaLayout>
    </Layout>
  );
};

export default ProblemStatement;

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "20px",
  },
  removeUnderScoreMenuItem: {
    "&.MuiInput-underline:before": {
      content: "unset",
    },
  },
  addUserStyle: {
    borderRadius: "10px",
    alignItems: "center",

    width: "50%",
    minHeight: "524px",
    "& p": {
      color: "white",
      paddingLeft: "13px",
      fontWeight: 700,
      fontSize: "18px",
    },
  },
  formpanel: {
    backgroundColor: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    boxShadow: "-1px 1px 15px -8px grey",
  },
  textStyle: {
    color: "black !important",
    margin: 0,
    width: "50%",
    fontSize: "18px !important",
    fontWeight: "normal !important",
    fontFamily: "Asap, sans-serif",
  },
  textareaStyle: {
    paddingTop: "1rem !important",
    paddingLeft: "1rem!important",
    fontSize: "16px !important",
    fontFamily: "Asap, sans-serif",
  },
}));
