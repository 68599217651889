const colors = [
    "#67c6c0",
    "#f5f6f8",
    "#fff9b1",
    "#f5d131",
    "#f49b46",
    "#d5f692",
    "#c9df56",
    "#93d275",
    "#67c6c0",
    "#f7cde0",
    "#ea94bb",
    "#be88c7",
    " #ee6c7f",
    "#a6ccf5",
    "#6cd8fb",
    " #7b92fc",
];
export default colors;