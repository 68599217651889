import {
  Box,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { HookFormTextField, MUIAutoComplete } from "Components/Reusable";
import { DropzoneSection } from "..";
import Attachments from "./Attachments";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { nanoid } from "nanoid";
import { Clear, Delete } from "@material-ui/icons";
/**
 *@function UpdateNodeData.jsx
 *@author Azim
 *
 **/

const UpdateNodeData = ({
  updatedData,
  control,
  errors,
  persona,
  toEpics,
  setFilesToSave,
  loading,
  setLinks,
  links,
}) => {

  const handleUpdateEvidenceLink = (e, item) => {
    const getEvidenceValue = e.target.value;
    const getData = links.find((items) => items.id === item.id);
    getData.link = getEvidenceValue;
    const indexedEvidence = links.findIndex(
      (info) => info.id === getData.id
    );
    links[indexedEvidence] = getData;
    setLinks(links);
  };
  return (
    <Grid spacing={3} container>
      {updatedData.type === "link" ? (
        <Box>
          <Box>
            <Tooltip title={"Cick to add ink"} arrow>
              <Box
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <Fab
                  onClick={() =>
                    setLinks((prev) => [
                      ...prev,
                      {
                        id: nanoid(10),
                        link: "",
                      },
                    ])
                  }
                  color="primary"
                  aria-label="add"
                >
                  <AddCircleOutlineIcon fontSize="large" />
                </Fab>
              </Box>
            </Tooltip>
          </Box>
          <HookFormTextField
            name="name"
            errors={errors}
            style={{ display: "none" }}
            control={control}
            defaultValue={"none"}
            label={
              updatedData.type === "decision"
                ? "Decision Name *"
                : "This process is called...*"
            }
          />
          <HookFormTextField
            name="processWhy"
            errors={errors}
            style={{ display: "none" }}
            control={control}
            defaultValue={"none"}
            label={
              updatedData.type === "decision"
                ? "Decision Name *"
                : "This process is called...*"
            }
          />


{links.map((item, index) => (
              <FormControl
                style={{ marginTop: "16px", maxWidth: "770px" }}
                key={index}
                fullWidth
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Evidence (Links)
                </InputLabel>
                <OutlinedInput
                  onChange={(e) => handleUpdateEvidenceLink(e, item)}
                  id="outlined-adornment-password"
                  type={"text"}
                  defaultValue={item.link}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          const newData = links.filter((items) => item.id !== items.id);
                          setLinks(newData);
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={113}
                />
              </FormControl>
            ))}
            <Grid item md={12}>
                    <MUIAutoComplete
                      control={control}
                      errors={errors}
                      name="valuestreamItem"
                      options={toEpics}
                      value={
                        toEpics.find(
                          (item) =>
                            item?.value === updatedData.data.valueStreamId
                        )
                          ? toEpics.find(
                              (item) =>
                                item?.value === updatedData.data.valueStreamId
                            )
                          : ""
                      }
                      renderOption={(option, { selected }) => (
                        <div
                          style={{
                            width: "100%",
                            background: option.color ? option.color : "#67c6c0",
                          }}
                        >
                          <p
                            style={{
                              color: "black",
                              marginLeft: "10px",
                            }}
                          >
                            {option.label}
                          </p>
                        </div>
                      )}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Change epic link"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
        </Box>
      ) : (
        <>
          {updatedData.type === "file" ? (
            <>
              {loading ? (
                <p>loading...</p>
              ) : (
                <>
                  <HookFormTextField
                    name="name"
                    errors={errors}
                    style={{ display: "none" }}
                    control={control}
                    defaultValue={"none"}
                    label={
                      updatedData.type === "decision"
                        ? "Decision Name *"
                        : "This process is called...*"
                    }
                  />

                  <HookFormTextField
                    name="processWhy"
                    errors={errors}
                    style={{ display: "none" }}
                    control={control}
                    defaultValue={"none"}
                    label={
                      updatedData.type === "decision"
                        ? "Decision Name *"
                        : "This process is called...*"
                    }
                  />

                  {/* {// console.log("updatedData?.data?.files", updatedData)} */}
                  <Grid item md={12}>
                    <MUIAutoComplete
                      control={control}
                      errors={errors}
                      name="valuestreamItem"
                      options={toEpics}
                      value={
                        toEpics.find(
                          (item) =>
                            item?.value === updatedData.data.valueStreamId
                        )
                          ? toEpics.find(
                              (item) =>
                                item?.value === updatedData.data.valueStreamId
                            )
                          : ""
                      }
                      renderOption={(option, { selected }) => (
                        <div
                          style={{
                            width: "100%",
                            background: option.color ? option.color : "#67c6c0",
                          }}
                        >
                          <p
                            style={{
                              color: "black",
                              marginLeft: "10px",
                            }}
                          >
                            {option.label}
                          </p>
                        </div>
                      )}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Change epic link"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  {updatedData?.data?.files && (
                    <Attachments AttachmentList={updatedData?.data?.files} />
                  )}
                  <Grid item md={12}>
                    <Box>
                      <DropzoneSection
                        showFileNames={true}
                        showPreviewsInDropzone={true}
                        setUpdateFilesToSave={setFilesToSave}
                        dropzoneText
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <>
              <Grid item md={updatedData.type === "decision" ? 6 : 4}>
                <HookFormTextField
                  name="name"
                  errors={errors}
                  control={control}
                  defaultValue={updatedData?.data?.name}
                  label={
                    updatedData.type === "decision"
                      ? "Decision Name *"
                      : "This process is called...*"
                  }
                />
              </Grid>
              {updatedData.type === "decision" ? (
                <>
                  <Grid style={{ display: "none" }}>
                    <HookFormTextField
                      name="processWhy"
                      control={control}
                      errors={errors}
                      defaultValue={`processWhy of Decision`}
                      label={`When this is done, persona will have...*`}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <MUIAutoComplete
                      control={control}
                      errors={errors}
                      name="valuestreamItem"
                      options={toEpics}
                      value={
                        toEpics.find(
                          (item) =>
                            item?.value === updatedData.data.valueStreamId
                        )
                          ? toEpics.find(
                              (item) =>
                                item?.value === updatedData.data.valueStreamId
                            )
                          : ""
                      }
                      renderOption={(option, { selected }) => (
                        <div
                          style={{
                            width: "100%",
                            background: option.color ? option.color : "#67c6c0",
                          }}
                        >
                          <p
                            style={{
                              color: "black",
                              marginLeft: "10px",
                            }}
                          >
                            {option.label}
                          </p>
                        </div>
                      )}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Change epic link"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item md={4}>
                    <HookFormTextField
                      name="processWhy"
                      control={control}
                      defaultValue={updatedData?.data?.processWhy}
                      errors={errors}
                      label={`When this is done, ${persona?.personasName} will...*`}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <MUIAutoComplete
                      errors={errors}
                      control={control}
                      name="valuestreamItem"
                      options={toEpics}
                      value={
                        toEpics.find(
                          (item) =>
                            item?.value === updatedData.data.valueStreamId
                        )
                          ? toEpics.find(
                              (item) =>
                                item?.value === updatedData.data.valueStreamId
                            )
                          : ""
                      }
                      renderOption={(option, { selected }) => (
                        <div
                          style={{
                            width: "100%",
                            background: option.color ? option.color : "#67c6c0",
                          }}
                        >
                          <p
                            style={{
                              color: "black",
                              marginLeft: "10px",
                            }}
                          >
                            {option.label}
                          </p>
                        </div>
                      )}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Change epic link"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default UpdateNodeData;
