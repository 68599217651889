import Layout from "Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  getProjectUserByProjectId,
  assginedTheProjectAdminFromUser,
  getProjectByUserId,
  deleteUserFromTheProject
} from "redux/actions";
import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { errorAlert, WordCount, WordCountLength } from "Utils";
import { useEffect } from "react";
import { CustomSnacbar, RIconButton, RButton } from "Components/Reusable";
import { DeleteOutline as DeleteOutlineIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

/**
 *@function ProjectUsers.jsx
 *@author Shahed
 *
 **/

const ProjectUsers = () => {
  const history = useHistory()
  const classes = useStyles();
  const dispatch = useDispatch();
  const { projectid } = useParams();
  const { projectuser, project } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);
  const [filteruser, setFilterUser] = useState("");
  const [projectdetail, setProjectDetail] = useState({});
  const [newAdmin, setNewAdmin] = useState({});
  const [adminAlert, setAdminAlert] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertToDelete, setAlertToDelete] = useState({});
  const [beforeDeleteAlert, setBeforeDeleteAlert] = useState(false);

  useEffect(() => {
    dispatch(getProjectUserByProjectId(projectid));
  }, [projectid, dispatch, openAlert]);


  useEffect(() => {
    if (user?.roleType === "USER") {
      errorAlert("You can't aceess this project")
      history.push("/")
    }
  })

  useEffect(() => {
    const main_project = project.find((value) => value.projectId === projectid);
    setProjectDetail(main_project);
  }, [projectid, project]);
  const domHostname = window?.location?.origin;


  const changeAdmin = () => {
    const newData = {
      adminUserId: user.id,
      newAdminUserId: newAdmin,
      projectId: projectid,
      ownershipRedirectUrl: domHostname + "/project",
    };
    dispatch(assginedTheProjectAdminFromUser(newData));
    dispatch(getProjectByUserId(user?.id))
    setOpenAlert(false)
    setTimeout(() => {
      history.push("/project")
    },2000)
  };

  const handleDeleteUser = (event) => {
    // // console.log(event)
    if (event.role === "USER") {
      setOpenAlert(true);
      setAlertToDelete(event);
    } else if (event.role === "ADMIN") {
      setBeforeDeleteAlert(true);
    }
  };


  const handleDeleteFromBackend = (id) => {
    dispatch(deleteUserFromTheProject(projectid, id, user?.id));
    setOpenAlert(false);
  };


  const projectUserSection = (item) => {
    return (
      <Box display={"flex"} justifyContent={"space-between"} marginTop={"1rem"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Avatar style={{ color: "black" }}>
            {item?.userList[0]?.name &&
              (WordCountLength(item?.userList[0]?.name) > 1
                ? `${WordCount(item?.userList[0]?.name)[0].charAt(0)}${WordCount(
                  item?.userList[0]?.name
                )[1].charAt(0)}`
                : `${WordCount(item?.userList[0]?.name)[0].charAt(0)}`)}
          </Avatar>
          <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
            <span style={{ fontSize: "16px", textTransform: "capitalize" }}>{item?.userList[0]?.name}</span><span>({item?.userList[0]?.username})</span>
          </div>
          {/* <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
            <span style={{ fontSize: "16px" }}>{item?.username}</span>
          </div> */}
        </Box>

        <Box style={{ fontWeight: "bold" }}>
          {item.role === "USER" ? (
            <>
              <Box display={"flex"}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={item.role === "USER" ? "user" : "admin"}
                    label="Age"
                    // style={{ padding: "20px" }}
                    className={classes.removeUnderScoreMenuItem}
                  >
                    <MenuItem value={"user"}>User</MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAdminAlert(true);
                        setNewAdmin(item?.userId);
                      }}
                      value={"admin"}
                    >
                      Admin
                    </MenuItem>
                  </Select>
                </FormControl>
                {/* <MatDelete index={item.id} /> */}
                <FormControlLabel
                  // className={classes.root}
                  style={{
                    marginTop: "-10px",
                    marginLeft: "5px",
                  }}
                  onClick={() => handleDeleteUser(item)}
                  control={
                    // <RIconButton title="Delete this user" className={classes.addButton} onClick={() => handleDeleteUser()}>
                    <RIconButton title="Delete this user" className={classes.addButton} >
                      <DeleteOutlineIcon />
                    </RIconButton>
                  }
                />
              </Box>
            </>
          ) : (
            <Box>
              <Typography
                style={{
                  marginLeft: "-75px",
                }}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                admin
              </Typography>
            </Box>
          )}
          {/*  */}
        </Box>
      </Box>
    );
  };

  return (
    <Layout pageName={"Project Users"}>
      {adminAlert && (
        <CustomSnacbar
          opened={adminAlert}
          DeleteText="Are you sure you want to assign this user as admin?"
          undone
          handleDeleteFromBackend={changeAdmin}
          setOpenAlert={setAdminAlert}
          type="adminUser"
        />
      )}
      {openAlert && (
        <CustomSnacbar
          opened={openAlert}
          alertToDelete={alertToDelete}
          delid={alertToDelete.userId}
          handleDeleteFromBackend={handleDeleteFromBackend}
          setOpenAlert={setOpenAlert}
          type="user"
        />
      )}
      {beforeDeleteAlert && (
        <CustomSnacbar
          opened={beforeDeleteAlert}
          DeleteText="This is an admin account you cannot delete this user"
          setOpenAlert={setBeforeDeleteAlert}
        />
      )}
      <Grid
        container
        style={{
          padding: "0px 10px 10px 10px",
          marginLeft: "7px",
          marginTop: "22px",
        }}
      >
        <Box
          style={{
            width: "40%",
          }}
        >
          <div>
            <Typography variant="h4" style={{ fontWeight: "bold" , textTransform:"capitalize"}}>
              {projectdetail &&
                projectdetail.name &&
                projectdetail?.name}
            </Typography>
          </div>
          <div>
            <Typography variant="span" style={{ fontWeight: "normal" }}>
              To change user role of a project or remove from existing
            </Typography>
          </div>
          {/* <Box display={"flex"}>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              size="small"
              disableClearable
              options={projectuser?.map((option) => option)}
              onChange={(event, newval) => setFilterUser(newval.id)}
              onInputChange={(event) => setFilterUser("")}
              getOptionLabel={(option) => {
                return option.name;
              }}
              // fullWidth
              style={{
                width: "78%",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search input"
                  margin="normal"
                  variant="outlined"
                  InputProps={{ ...params.InputProps, type: "search" }}
                />
              )}
            />
            <Box>
              <RButton
                size="medium"
                color="primary"
                variant="contained"
                wording={"AddUser"}
                style={{ marginTop: "1rem", marginLeft: "0.8rem" }}
              />
            </Box>
          </Box> */}
          {filteruser
            ? projectuser
              ?.filter((value) => value.id === filteruser)
              ?.map((item, index) => {
                return projectUserSection(item);
              })
            : projectuser?.map((item, index) => {
              return projectUserSection(item);
            })}
        </Box>
      </Grid>
    </Layout>
  );
};

export default ProjectUsers;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
  },
  removeUnderScoreMenuItem: {
    // background: "red",
    "&.MuiInput-underline:before": {
      content: "unset",
    },
  },
}));
