import { Box, TextField, Tooltip } from "@material-ui/core";
import { Button, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { Link, useParams } from "react-router-dom";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { copyWorkFlowById, deleteWorkFlowById } from "redux/actions";
import {
  FileCopyOutlined as FileCopyOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from "@material-ui/icons";
import {
  CustomSnacbar,
  Modal,
  MUIAutoComplete,
  RIconButton,
} from "Components/Reusable";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
/**
 *@function StreamCard.jsx
 *@author Azim
 *
 **/
const StreamCard = (props) => {
  const {
    titleVariant,
    subtitle,
    titleColor,
    titleText,
    description,
    isButton,
    buttonText,
    linkTo,
    linkData,
    children,
    workFlowStream,
    setOpenModal,
    workflowitem,
    spaceflow,
    setOpenTitle,
    journeymap,
    createflow,
  } = props;
  const [deleteWorkflow, setDeleteWorkflow] = useState({
    workflowitem,
    isDelete: false,
  });
  const [cloneSnacbar, setCloneSnacbar] = useState({
    workflowitem,
    isClone: false,
  });

  const dispatch = useDispatch();
  const { workflowId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { personas } = useSelector((state) => state.personas);
  const { singlePersona } = useSelector((state) => state.personas);
  const handleDeleteFromBackend = () => {
    dispatch(deleteWorkFlowById(workflowitem.id, user?.projectId));
    setDeleteWorkflow((prev) => ({ ...prev, isDelete: false }));
  };
  const { workflows } = useSelector((state) => state.workflow);
  const {
    formState: { errors: errorsUpdateState },
    handleSubmit: handleUpdateSubmit,
    reset: updateReset,
    control: updateControl,
  } = useForm({
    mode: "all",
  });
  const onUpdateSubmit = (data) => {
    dispatch(
      copyWorkFlowById(workflowitem.id, user?.projectId, data.personas.value)
    );
    setCloneSnacbar((prev) => ({ ...prev, isClone: false }));
    updateReset();
  };
  const toPersonas = personas?.map((item) => ({
    label: item.name,
    workflows: item.workflows,
    value: item.id,
  }));
  const handleUpdateModalOpen = () => {
    return (
      <Modal
        settings
        open={cloneSnacbar.isClone}
        handleClose={() =>
          setCloneSnacbar((prev) => ({ ...prev, isClone: false }))
        }
      >
        <Typography
          style={{
            padding: "10px 48px",
            marginTop: "0px",
            fontWeight: "500",
            background: "#7478E8",
            fontSize: "32px",
            color: "#F8F8F8",
            borderBottom: "1px solid black",
          }}
          align="left"
          variant="h5"
          component="h6"
          gutterBottom
        >
          Clone Workflow
        </Typography>
        <Box
          maxWidth="950px"
          maxHeight="600px"
          padding="45px"
          sx={{
            overflowY: " auto",
            overflowX: "hidden",
          }}
        >
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleUpdateSubmit(onUpdateSubmit)}
          >
            <MUIAutoComplete
              control={updateControl}
              name="personas"
              options={toPersonas}
              style={{
                width: "100%",
              }}
              value={toPersonas.find((item) => item.value === workflowId)}
              renderOption={(option, { selected }) => {
                return (
                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        color: "black",
                        marginLeft: "10px",
                      }}
                    >
                      {option.label}
                    </p>
                  </div>
                );
              }}
              getOptionLabel={(option) => {
                return option.label;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Change Persona"
                  variant="outlined"
                />
              )}
            />
            <Button
              style={{ marginTop: "20px" }}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              clone work flow
            </Button>
          </form>
        </Box>
      </Modal>
    );
  };

  const handleOpeningStreamCard = () => {
    createflow &&
      setOpenTitle((prev) => ({
        ...prev,
        isOpen: true,
        item: workflowitem,
      }));
  };
  const handleDeleteStreamCard = () => {
    setDeleteWorkflow((prev) => ({
      ...prev,
      isDelete: true,
      workflowitem,
    }));
  };
  return (
    <Box
      style={{
        padding: "20px 30px 83px 20px",
        margin: spaceflow ? "0px 16px 0px 2px" : "0px 16px 0px 5px",
        height: "315px",
        backgroundColor: "#fff",
        boxShadow:
          "-9px 0px 9px -4px rgba(229, 229, 229, 0.7), 9px 9px 9px -4px rgba(229, 229, 229, 0.7)",
        borderRadius: "10px",
        width: "100%",
      }}
    >
      {deleteWorkflow.isDelete && !workflowitem.valueStreamList.length > 0 && (
        <CustomSnacbar
          opened={deleteWorkflow.isDelete}
          setOpenAlert={setDeleteWorkflow}
          type
          delid={workflowitem.id}
          handleDeleteFromBackend={handleDeleteFromBackend}
          DeleteText="Are you sure you want to delete this workflow?"
        />
      )}
      {deleteWorkflow.isDelete && workflowitem.valueStreamList.length > 0 && (
        <CustomSnacbar
          opened={deleteWorkflow.isDelete}
          setOpenAlert={setDeleteWorkflow}
          workflowitem={workflowitem}
          DeleteText="Cannot delete non-empty workflow"
        />
      )}
      {cloneSnacbar.isClone && handleUpdateModalOpen()}
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Tooltip
            arrow
            title={titleText ? titleText : "value stream"}
            placement="top"
          >
            <Typography
              style={{
                letterSpacing: "0.18px",
                fontSize: "24px",
                color: "#000",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              variant={titleVariant}
              onClick={() => handleOpeningStreamCard()}
            >
              {titleText}
            </Typography>
          </Tooltip>
          <Typography
            style={{
              letterSpacing: "0.18px",
              fontSize: "10px",
              color: "#acacac",
              fontWeight: "bold",
              cursor: "pointer",
              fontStyle: "italic",

            }}>
            {subtitle ?? ""}
          </Typography>
        </Box>

        <Link to={`/${linkTo}`}>
          <Typography>{linkData}</Typography>
        </Link>
        {workFlowStream && (
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <RIconButton
                title={"Create"}
                placement="top"
                onClick={() => setOpenModal(true)}
              >
                <AddOutlinedIcon />
              </RIconButton>
            </Box>
            <Box>
              <RIconButton
                title={"Clone"}
                placement="top"
                onClick={() =>
                  setCloneSnacbar((prev) => ({
                    ...prev,
                    isClone: true,
                    workflowitem,
                  }))
                }
              >
                <FileCopyOutlinedIcon />
              </RIconButton>
            </Box>
            {workflows &&
            workflows.length &&
            workflows.filter((value) => value.personasId === singlePersona.id)
              .length > 1 ? (
              <Box>
                <RIconButton
                  title={"Delete"}
                  placement="top"
                  onClick={() => handleDeleteStreamCard()}
                >
                  <DeleteOutlinedIcon />
                </RIconButton>
              </Box>
            ) : null}
          </Box>
        )}
        {journeymap != undefined && journeymap !== null ? (
          <Box>{journeymap}</Box>
        ) : null}
      </Box>
      {description && (
        <Typography
          style={{
            margin: "30px 0px 70px 0px",
            fontSize: "13px",
          }}
          variant="body2"
        >
          {description}
        </Typography>
      )}

      {isButton && (
        <Button
          color="primary"
          style={{ textAlign: "center" }}
          startIcon={<AddCircle />}
          variant="outlined"
        >
          {buttonText}
        </Button>
      )}
      {children}
    </Box>
  );
};
export default StreamCard;
