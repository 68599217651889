import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import SignBanner from "../../Components/Reusable/SignBanner";
import { useDispatch, useSelector } from "react-redux";
import { activationUser } from "redux/actions";
import { useLocation , useHistory} from "react-router-dom";
/**
 *@function Login.jsx
 *@author Azim
 *
 **/

const Activation = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { activation } = useSelector((state) => state.user);
  const history = useHistory();
  const classes = useStyles();
  const searchId = new URLSearchParams(search).get("secretKey");
  useEffect(() => {
    dispatch(activationUser(searchId));
  }, [dispatch, searchId]);
  useEffect(() => {
    // return;
    if (activation) {
      const redirect = setTimeout(() => history.push("/login"), 6000);
      return () => clearTimeout(redirect);
    }
  }, [activation, history]);
  return (
    <section className={classes.loginContainer}>
      <Grid container>
        <SignBanner />
        <Grid className={classes.loginFormStyle} item md={6}>
          <Box>
            <Typography
              className={classes.welcomeMessage}
              variant="h3"
              component="p"
            >
              Thank you! <br /> Email verified Successfully
            </Typography>
            <Typography className={classes.welcomeShortMessage} component="p">
              You're redirecting to login...
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default Activation;

//Material UI
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      width: "100%",
    },
  },
  loginContainer: {
    maxWidth: "100%",
    overflow: "hidden",
    position: "relative",
  },
  loginFormStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  welcomeMessage: {
    color: theme.palette.common.black,
    fontWeight: 700,
    fontSize: "34px",
    margin: "3rem 0rem 2rem 0rem",
    textAlign: "center",
  },
  welcomeShortMessage: {
    color: theme.palette.common.black,
    fontWeight: 400,
    fontSize: "20px",
    margin: "3rem 0rem 2rem 0rem",
    textAlign: "center",
  },
}));
