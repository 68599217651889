import { Box, Button } from "@material-ui/core";
import { HookFormTextField, QuillText } from "Components/Reusable";
import { useState } from "react";
/**
 *@function AddNewCard.jsx
 *@author Azim
 *
 **/
const AddNewCard = ({
  handleCreateSubmit,
  onCreateSubmit,
  classes,
  controlForCreate,
  createError,
  handleCreateModalClose,
}) => {
  const [description, setDescription] = useState("");
  const handleSubmit = (createdData) => {
    const data = {
      ...createdData,
      description,
    };
    onCreateSubmit(data);
  };
  return (
    <Box minWidth="700px" maxWidth="700px" maxHeight="650px">
      <form
        onSubmit={handleCreateSubmit(handleSubmit)}
        className={classes.rootOfForm}
      >
        <Box mb="20px" className={classes.flexBetweenCenter}>
          <h1>Add New Row</h1>
        </Box>
        <Box>
          <Box display="inline">
            <HookFormTextField
              name="title"
              control={controlForCreate}
              label="Short description *"
              errors={createError}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
          marginBottom={"3rem"}
          mt="20px"
        >
          <QuillText value={description} onChange={setDescription} />
        </Box>
        <Box
          display="flex"
          justifyContent="right"
          marginTop="60px"
          paddingBottom="20px"
        >
          <Button
            className={classes.submitAndCloseButton}
            style={{
              marginRight: "10px",
              background: "#F5F5F5",
            }}
            onClick={handleCreateModalClose}
            variant="outlined"
            type="button"
            color="secondary"
          >
            Close
          </Button>
          <Button
            className={classes.submitAndCloseButton}
            color="primary"
            variant="contained"
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddNewCard;
