import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
    getAllCardConfiguration,
    saveCardCofiguration,
} from "redux/actions/cardconfig.actions";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SingleSquareBoxs } from "Utils/cardconfig";
import { useEffect } from "react";
import { useCallback } from "react";
import { areObjectsMatching } from "Utils/cardconfig";


const CardConfig = ({ selectedProjects }) => {
    // redux states and dispatches
    const { user } = useSelector((state) => state.auth);

    const { configuration } = useSelector((state) => state.cardConfig);
    const dispatch = useDispatch();

    // for single and multi items
    const [visible, setVisible] = useState();

    // for getting project data
    const fetchProjectDetails = useCallback(
        async (projects) => {
            // console.log({ selectedProjects });
            const projectIds = projects
                .map(function (item) {
                    return item.value;
                })
                .join(",");
            dispatch(getAllCardConfiguration(user?.id, projectIds));
        },
        [configuration]
    );

    // upper callback function
    useEffect(() => {
        if (selectedProjects.length > 0) {
            fetchProjectDetails(selectedProjects);
        }
    }, [selectedProjects]);

    // for getting status of projects
    const getProjectConfigurationStatus = useCallback((config) => {
        // console.log({config})
        const matchOrNot = areObjectsMatching(config);
        if (matchOrNot === null) {
            setVisible(SingleSquareBoxs());
        } else if (matchOrNot === true) {
            setVisible(SingleSquareBoxs(config));
        } else if (matchOrNot === false) {
            setVisible(SingleSquareBoxs());
        }
    }, []);

    // upper callback function
    useEffect(() => {
        if (configuration.length == selectedProjects.length) {
            getProjectConfigurationStatus(configuration);
        }
    }, [configuration]);

    const handleSetVisible = (data, id) => {
        setVisible((visi) =>
            visi.map((item) => {
                if (id === item.id) {
                    item.visible = data;
                }
                return item;
            })
        );
    };

    const handleApplyConfig = () => {
        const newconfig = {};
        visible.forEach((item) => {
            newconfig[item.name] = item.visible;
        });

        const projects = selectedProjects?.map((item, index) => {
            const newitem = {
                feature: newconfig.feature,
                task: newconfig.task,
                bug: newconfig.bug,
                latestOne: true,
                risk: newconfig.risk,
                issue: newconfig.issue,
                projectId: item.value,
                userId: user.id,
            };
            return newitem;
        });

        dispatch(saveCardCofiguration(projects));
    };

    return (
        <Paper elevation={1}>
            <Box padding={3}>
                <Typography align="center" variant="h5">
                    Card Configuration
                </Typography>
                <Grid container>
                    {visible?.map((item) => (
                        <Grid
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                            xs={6}
                            key={item.id}
                            item
                        >
                            <Button
                                variant="contained"
                                color={item.visible ? "primary" : "default"}
                                size="large"
                                onClick={() => handleSetVisible(!item.visible, item.id)}
                                style={{ margin: "8px 0px", minWidth: "200px" }}
                                endIcon={item.visible ? <Visibility /> : <VisibilityOff />}
                            >
                                {item.title}
                            </Button>
                        </Grid>
                    ))}
                </Grid>

                <Box display={"flex"} justifyContent={`center`}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ margin: "8px 0px", minWidth: "200px" }}
                        onClick={() => handleApplyConfig()}
                    >
                        Apply
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default CardConfig;
