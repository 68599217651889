export const SingleSquareBoxs = (item) => {
    const newItem = item !== undefined && item !== null ? item[0] : null
    
    return [
        {
            title: "Persona​",
            name: 'persona',
            id: 1,
            visible: newItem != null ? newItem.persona : true,
        },
        {
            title: "Problem statement​",
            name: 'problemStatement',
            id: 2,
            visible: newItem != null ? newItem.problemStatement : true,
        },
        {
            title: "Journey Map​",
            name: 'journeyMap',
            id: 3,
            visible: newItem != null ? newItem.journeyMap : true,
        },
        {
            title: "Workflow​",
            name: 'workflow',
            id: 4,
            visible: newItem != null ? newItem.workflow : true,
        },
        {
            title: "Kanban​",
            name: 'kanban',
            id: 5,
            visible: newItem != null ? newItem.kanban : true,
        },
        {
            title: "Risks and issues​",
            name: 'riskIssues',
            id: 6,
            visible: newItem != null ? newItem.riskIssues :  true,
        },
        {
            title: "Custom Board​",
            name: 'customBoard',
            id: 7,
            visible: newItem != null ? newItem.customBoard : true,
        },
    ]
};


export const MultiSquareBox = (item) => {

    const configurationItems = []
    item.map(value => {

        const config = [
            {
                title: "Persona​",
                name: 'persona',
                id: 1,
                visible: value != null ? value.persona : true,
            },
            {
                title: "Problem statement​",
                name: 'problemStatement',
                id: 2,
                visible: value != null ? value.problemStatement : true,
            },
            {
                title: "Journey Map​",
                name: 'journeyMap',
                id: 3,
                visible: value != null ? value.journeyMap : true,
            },
            {
                title: "Workflow​",
                name: 'workflow',
                id: 4,
                visible: value != null ? value.workflow : true,
            },
            {
                title: "Kanban​",
                name: 'kanban',
                id: 5,
                visible: value != null ? value.kanban : true,
            },
            {
                title: "Risks and issues​",
                name: 'riskIssues',
                id: 6,
                visible: value != null ? value.riskIssues : true,
            },
            {
                title: "Custom Board​",
                name: 'customBoard',
                id: 7,
                visible: value != null ? value.customBoard : true,
            },
        ]


        configurationItems.push(config)
    })
    return configurationItems;
};


export const areObjectsMatching = (data) => {

    // checking if any objects are null or not
    if(data.length > 1){
        const hasNull = data.some(object =>
            object === null || Object.values(object).some(value => value === null)
        );

        if (hasNull) {
            return null; // If there's a 'null', we don't even need to compare objects.
        } else {
            // making new array to compare easily
            const newData = data.map(item => {
                return {
                    "customBoard": item.customBoard,
                    "journeyMap": item.journeyMap,
                    "kanban": item.kanban,
                    "persona": item.persona,
                    "problemStatement": item.problemStatement,
                    "riskIssues": item.riskIssues,
                    "workflow": item.workflow
                }
            })

            // setting a base object with which we can compare
            const baseObject = newData[0];

            // making an array o keys of base object
            const keysToCompare = Object.keys(newData[0]);




            // checking every value of every keys from every object
            return newData.every(object =>
                keysToCompare.every(key => object[key] === baseObject[key])
            );
        }
    }else{
        return true
    }
    


}