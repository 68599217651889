import { Avatar, Box, Button, Grid, Tooltip } from "@material-ui/core";
import { AddCircle, PersonOutline } from "@material-ui/icons";
import StreamCard from "Components/Reusable/Card/StreamCard";
import { getJourneyMapChart } from "Utils/chartconfig";
import ReactECharts from "echarts-for-react";

/**
 *@function WorkFlow.jsx
 *@author Azim
 *
 **/
export const JourneymapControl = ({
  classes,
  valueStream,
  history,
  workflow,
  journeyMap,
  getAvatar,
  titleText,
  workFlowStream,
  setOpenModal,
  workflowitem,
  setOpenTitle,
  setRecentPersonas,
  journeyDetails,
  recentPersonas,
  openModalPersona,
   setOpenModalPersona
}) => {
  const handlePushState = (item) => {
    setRecentPersonas(item.id);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <StreamCard
      titleVariant="h5"
      setOpenModal={setOpenModal}
      setOpenTitle={setOpenTitle}
      titleText={titleText}
      workFlowStream={workFlowStream}
      workflowitem={workflowitem}
      spaceflow={workflow}
      journeymap={"% discovered"}
      subtitle="Discovery"
    >
      {valueStream.length > 0 ? (
        <Box onClick={() => history.push(`/journeyMap/${recentPersonas}`)}>
          <ReactECharts
            style={{ height: "150px" }}
            option={getJourneyMapChart(journeyDetails)}
          />
        </Box>
      ) : // <Tooltip title="click to go to the Journey map" arrow placement="top">

      //   <Box
      //     style={{ maxWidth: "1400px", minHeight: "90px" }}
      //     className={`${classes.streamCardBoxforjourney} ${classes.streamCardHoverBoxforjourney}`}
      //     onClick={() => history.push(`/journeyMap/${recentPersonas}`)}
      //   >

      //     {/* <Grid className={classes.scrollUpStyle} container>
      //       {journeyDetails &&
      //         journeyDetails.length > 0 &&
      //         journeyDetails
      //           .filter((value) => value.title !== "Questions")
      //           .map((item, index) => (
      //             <div key={index}>
      //               <Tooltip title={item?.title} placement="bottom" arrow>
      //                 <Box
      //                   style={{
      //                     cursor: "pointer",
      //                     width: "120px",
      //                     textAlign: "center",
      //                   }}
      //                 >
      //                   {item.title}
      //                 </Box>
      //               </Tooltip>
      //               <Box style={{ textAlign: "center" }}>
      //                 {item.laneComplete}%
      //               </Box>
      //               <Box style={{ fontSize: "34px", textAlign: "center" }}>
      //                 {item.emojiLabel !== "" && item.emojiLabel !== null ? (
      //                   item.emojiLabel
      //                 ) : (
      //                   <div className={classes.circleBox}>?</div>
      //                 )}
      //               </Box>
      //             </div>
      //           ))}
      //     </Grid> */}
      //   </Box>
      // </Tooltip>
      null}
      <Box
        style={{ maxWidth: "1400px", marginTop: "1rem" }}
        className={classes.streamCardBoxforjourney}
      >
        <Grid className={classes.scrollUpStyle} container>
          {valueStream.map((item, index) => (
            <div
              key={index}
              style={{ backgroundColor: "#ffffff00", cursor: "move" }}
            >
              <Tooltip title={item?.name} placement="bottom" arrow>
                <Box
                  style={{
                    background: "#E5E5E5",
                    cursor: "pointer",
                    marginRight: "20px",
                    border: recentPersonas === item.id && "2px solid #7478E8",
                  }}
                  className={classes.AddboxCircleForjourney}
                >
                  {item?.avatar ? (
                    <div>
                      <Avatar
                        style={{
                          border:
                            recentPersonas === item.id && "2px solid #7478E8",
                        }}
                        className={classes.firstpaperjourney}
                        src={getAvatar(item?.avatar)}
                        onClick={() => handlePushState(item)}
                      ></Avatar>
                    </div>
                  ) : (
                    <p
                      style={{
                        position: "relative",
                        lineHeight: 1,
                        color: "black",
                        top: "0",
                        fontSize: ".5em",
                        textAlign: "center",
                        margin: "0px",
                      }}
                      onClick={() => handlePushState(item)}
                    >
                      <PersonOutline
                        style={{
                          height: "25px",
                          fontSize: "15.5em",
                          color: "#BDBDBD",
                          position: "relative",
                        }}
                      />
                      <span
                        style={{
                          position: "relative",
                          top: "-10px",
                          fontSize: "1.7em",
                        }}
                      >
                        {item?.name.length > 7
                          ? item?.name.slice(0, 7) + "..."
                          : item?.name}
                      </span>
                    </p>
                  )}
                </Box>
              </Tooltip>
            </div>
          ))}

          <Box
            style={{ background: "transparent" }}
            // onClick={() => history.push("/personas")}
            onClick={() => setOpenModalPersona(true)}
            className={classes.AddboxCircleForProblemStatement}
          >
            <Button
              className={classes.buttonStyle2}
              disableRipple
              endIcon={
                <AddCircle className={classes.buttonInnerStyle2problem} />
              }
            />
          </Box>
        </Grid>
      </Box>
    </StreamCard>
  );
};
