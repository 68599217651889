import { Box, Button } from "@material-ui/core";
import { ErrorMessages, TableModal } from "Components/Reusable";
import {
  DataGridOfProcessNode,
  UpdateNodeData,
} from "Components/FlowComponents";
/**
 *@function UpdateTableModal.jsx
 *@author Azim
 *
 **/

const UpdateTableModal = ({
  openDoubleClick,
  handleCloseModal,
  updatedData,
  classes,
  handleSubmit,
  onUpdateSubmit,
  control,
  clearErrors,
  errors,
  loading,
  persona,
  toEpics,
  decisionNodeInitialData,
  setFilesToSave,
  setLinks,
  links,
}) => {
  return (
    <TableModal
      modalStyle={{
        display: "flex",
        alignItems: "center",
        flexDirection: updatedData?.type === "process" ? "column" : "row",
      }}
      minHeight={400}
      open={openDoubleClick}
      handleClose={handleCloseModal}
    >
      {updatedData?.type === "process" && (
        <Box className={classes.table_header}>
          <h1>{updatedData?.data?.name} </h1>
        </Box>
      )}

      <form onSubmit={handleSubmit(onUpdateSubmit)} autoComplete="off">
        <Box className={classes.form_Style}>
          <UpdateNodeData
            toEpics={toEpics}
            loading={loading}
            updatedData={updatedData}
            control={control}
            errors={errors}
            persona={persona}
            setFilesToSave={setFilesToSave}
            setLinks={setLinks}
            links={links}
          />
        </Box>
        {errors.dataExist && (
          <Box
            mt="4px"
            mb="4px"
            style={{
              border: "1px solid gray",
              display: "inline-block",
              padding: "8px",
              borderRadius: "6px",
            }}
          >
            <ErrorMessages errors={errors} name={"dataExist"} />
          </Box>
        )}

        {updatedData.type === "process" && (
          <Box mt="20px">
            <DataGridOfProcessNode rows={decisionNodeInitialData} />
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="right"
          marginTop="30px"
          marginRight="17px"
          paddingBottom="20px"
        >
          <Button
            className={classes.submitAndCloseButton}
            style={{
              marginRight: "10px",
              background: "#F5F5F5",
            }}
            onClick={handleCloseModal}
            variant="outlined"
            type="button"
            color="secondary"
          >
            Close
          </Button>
          <Button
            className={classes.submitAndCloseButton}
            color="primary"
            type="submit"
            onClick={() => clearErrors()}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </form>
    </TableModal>
  );
};

export default UpdateTableModal;
