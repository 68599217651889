import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";
import { MPopover, CustomSnacbar, QuillText } from "Components/Reusable";
import { Modal } from "Components/Reusable";
import { useRef } from "react";

export const RPersona = (props) => {
  const classes = useStyles();
  const descriptionconvert =
    props.item && props.item.description !== ""
      ? JSON.parse(props.item.description)
      : null;
  const [editorUpdateState, setEditorUpdateState] = useState(
    descriptionconvert !== null ? descriptionconvert : ""
  );
  const [open, setOpen] = useState(false);
  const [personasShow, setpersonasShow] = useState(false);
  const [anchorElPersonas, setAnchorElPersonas] = useState(null);
  const [personaName, setPersonaName] = useState(props?.item?.name);
  const [deleteItems, setDeleteItems] = useState(false);
  const handleClose = () => {
    const cleanedHTML = editorUpdateState.replace(/<p><br><\/p>/g, '');
    props.item.description = JSON.stringify(cleanedHTML);
    
    props.getItemData(props.item);
    setOpen(false);
  };


  const handleChangeName = () => {
    props.item.name = personaName;
    props.getItemData(props.item);
  };


  const popoverRef = useRef();
  const PopOverForPersonas = () => {
    return (
      <MPopover
        id={props.item.id}
        ref={popoverRef}
        open={personasShow}
        anchorEl={anchorElPersonas}
        onClose={() => {
          setAnchorElPersonas(null);
          setpersonasShow(false);
          handleChangeName();
        }}
        className={classes.personasStyle}
      >
        <Box padding={2}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="name"
            value={personaName}
            onChange={(event) => setPersonaName(event.target.value)}
          />
        </Box>
      </MPopover>
    );
  };

  const DeleteItems = () => {
    return (
      <CustomSnacbar
        opened={deleteItems}
        DeleteText="Are you sure you want to delete this card?"
        undone
        delid={props?.item?.id}
        handleDeleteFromBackend={props.handleDelete}
        setOpenAlert={setDeleteItems}
        type="adminUser"
      />
    );
  };

  const renderUpdateNotes = () => {
    return (
      <div>
        <Grid
          style={{
            maxWidth: "1200px",
            borderRadius: "8px",
            minHeight: "300px",
            height:"450px",
            width: "1190px",
            overflow: "auto",
          }}
        >
          <QuillText
            value={editorUpdateState}
            onChange={setEditorUpdateState}
            className={classes.qleditornew}
          />
        </Grid>
      </div>
    );
  };

  return (
    <Box
      className={classes.mainCard}
      style={{ backgroundColor: props?.item?.backColor }}
    >
      <Box
        className={classes.commentsCard}
        style={
          props?.menuOpen
            ? {
                width: "39.5vh",
                transition: ".5s",
                borderLeft: `5px solid ${props?.item?.sideColor}`,
              }
            : {
                width: "45.2vh",
                transition: ".5s",
                borderLeft: `5px solid ${props?.item?.sideColor}`,
              }
        }
      >
        <Box
          className={classes.insideitem}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <div
            onClick={(event) => {
              setpersonasShow(!personasShow);
              setAnchorElPersonas(event.currentTarget);
            }}
          >
            {props.item.name}
          </div>

          <div
            style={{ cursor: "pointer" }}
            onClick={() => setDeleteItems(true)}
          >
            <Delete style={{ color: props?.item?.sideColor }} />
          </div>
        </Box>
        {deleteItems && DeleteItems()}
        {PopOverForPersonas()}
        {!open ? (
          <Box
            dangerouslySetInnerHTML={{
              __html: descriptionconvert,
            }}
            style={{
              paddingLeft: "1rem",
              width: "20px !important",
              height: "185px",
              overflow: "hidden",
              overflowY: "scroll",
            }}
            className={classes.editorbox}
            onClick={() => setOpen(true)}
          ></Box>
        ) : null}

        <Modal
          overflowAuto
          riskAndIssue
          open={open}
          // board
          handleClose={handleClose}
        >
          <Typography
            style={{
              padding: "10px 12px",
              marginTop: "0px",
              fontWeight: "500",
              background: "#7478E8",
              fontSize: "24px",
              color: "#F8F8F8",
              borderBottom: "2px solid black",
            }}
            align="left"
            variant="h5"
            component="h6"
            gutterBottom
          >
            {`Write your notes on "${props?.item?.name}"`}
          </Typography>
          <div style={{ padding: "10px" }}>{renderUpdateNotes()}</div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "right",
              aligntItems: "center",
              marginTop: "20px",
              marginRight: "30px",
            }}
          >
            <Tooltip title="Press (Ese)">
              <Button
                style={{
                  marginRight: "6px",
                }}
                variant="contained"
                color="primary"
                type="button"
                onClick={() => handleClose()}
              >
                Close
              </Button>
            </Tooltip>
            <Tooltip title="Press (Ctrl + s)">
              <Button
                className={classes.submitAndCloseButton}
                color="primary"
                variant="contained"
                onClick={() => handleClose()}
                id="submit-button"
              >
                Submit
              </Button>
            </Tooltip>
          </div> */}
        </Modal>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  mainCard: {
    height: "248px",
  },
  commentsCard: {
    margin: ".5rem",
    height: "235px",
  },
  insideitem: {
    fontWeight: "bold",
    marginLeft: "1rem",
    paddingTop: "0.7rem",
  },
  forGetText: {
    position: "relative",
    height: "119px",
  },
  personasStyle: {
    marginLeft: "65px !important",
  },
  editorbox: {
    "&::-webkit-scrollbar": {
      width: "0 !important",
    },
  },
  qleditornew: {
    height: "250px",
  },
}));
