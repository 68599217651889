import { logHour } from './constant';
import axios from '../../api/axios';
import { errorAlert, handleErrorMessage, successAlert } from 'Utils';


// for fetching all log hours
export const fetchLoghour = (proejctId) => {
    return async (dispatch) => {
        dispatch({
            type: logHour.FETCH_LOG_HOURS_REQUEST
        })

        try {
            const resposne = await axios.get(`/v1/projects/${proejctId}/logHours`)
            dispatch({
                type: logHour.FETCH_LOG_HOURS_SUCCESS,
                payload: resposne.data.data
            })
        } catch (error) {
            dispatch({
                type: logHour.FETCH_LOG_HOURS_FAILED,
                payload: error.response
            })
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}


// for storing log hour according to individual project id's
export const storeLogHour = (projectId, data) => {
    return async (dispatch) => {
        if (data) {
            const response = await axios.post(`/v1/projects/${projectId}/logHours`, data)
            dispatch({
                type: logHour.ADD_LOG_HOUR,
            })
            response && response.status === 201 && successAlert("Log Hour Created Successfully")
            dispatch(fetchLoghour(projectId))
        }
    }
}


// for fetching log hours individually according to ids
export const fetchSingleLogHour = (projectId, logId) => {
    return async (dispatch) => {
        dispatch({
            type: logHour.FETCH_LOG_HOUR_REQUEST
        })
        try {
            if (logId) {
                const response = await axios.get(`/v1/projects/${projectId}/logHours/${logId}`)
                dispatch({
                    type: logHour.FETCH_LOG_HOUR_SUCCESS,
                    payload: response.data.data
                })
            }
        } catch (error) {
            dispatch({
                type: logHour.FETCH_LOG_HOUR_FAILED,
                payload: error.response
            })
        }

    }
}


// for updating individual log hours
export const updateLogHour = (projectId, logId, data) => {
    return async (dispatch) => {
        try {
            if (logId) {
                const response = await axios.put(`/v1/projects/${projectId}/logHours/${logId}`, data)
                dispatch({
                    type: logHour.UPDATE_LOG_HOUR_SUCCESS,
                    payload: response.data.data
                })
                response && response.status === 202 && successAlert("Log Hour Updated Successfully")
            }
        } catch (error) {
            dispatch({
                type: logHour.UPDATE_LOG_HOUR_FAILED
            })
        }
    }
}


// for deleting individual log hours
export const deleteLogHour = (projectId, logId) => {
    return async (dispatch) => {
        try {
            if (logId) {
                const response = await axios.delete(`/v1/projects/${projectId}/logHours/${logId}`)
                response && response.status === 204 && successAlert("Log Hour deleted successfully")
                dispatch({
                    type: logHour.DELETE_LOG_HOUR_SUCCESS
                })
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: logHour.DELETE_LOG_HOUR_FAILED
                })
            }
        }
    }
}


// for fetching log hours by card id
export const fetchLogbyCard = (projectId, cardId) => {
    return async (dispatch) => {
        dispatch({
            type: logHour.FIND_LOG_HOUR_BYCARD_REQUEST
        })
        try {
            if (cardId) {
                const response = await axios.get(`/v1/projects/${projectId}/logHours/card/${cardId}`)
                dispatch({
                    type: logHour.FIND_LOG_HOUR_BYCARD_SUCCESS,
                    payload: response.data.data
                })
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: logHour.FIND_LOG_HOUR_BYCARD_FAILED,
                    payload: error.response
                })
            }
        }
    }
}


