import { heartBeat } from "../actions/constant";

const initState = {
    data: [],
    error: null
}


const heartBearReducer = (state = initState, action) => {
    switch (action.type) {
        case heartBeat.GET_HEART_BEAT_API_REQUEST:
            return {
                ...state,
                data: []
            }
        case heartBeat.GET_HEART_BEAT_API_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
        case heartBeat.GET_HEART_BEAT_API_FAILED:
            return {
                ...state,
                error: action.payload,
                data: []
            }
        default:
            return state;
    }
}

export default heartBearReducer