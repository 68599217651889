import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import columns from "./Columns";
import { RIconButton } from "..";
import {
  updateUserDataToTheTable,
  deleteUserToTable,
  deleteUserFromTheProject,
  getAllSubscription,
  getProjectByUserId,
  postUserByAssignedProjectByAdminId,
  patchUserByAssignedProjectByAdminId,
  resetPassword,
  notifyUserAfterAdding,
} from "redux/actions";
import { CustomSnacbar } from "Components/Reusable";
import { makeStyles } from "@material-ui/core";
import { Box, Button, createTheme } from "@material-ui/core";
import { checkduplicity, redirectUrl, validateEmail } from "Utils";
import { uniqBy } from "lodash";
import { useEffect } from "react";

export default function Users({ rows, addNewUserToTheColumn }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { loading, project } = useSelector((state) => state.projects);
  const { organizationId, projectId } = user;

  useEffect(() => {
    if (user?.id) {
      dispatch(getAllSubscription(user?.id));
    }
  }, [user?.id, dispatch]);
  useEffect(() => {
    if (user?.id) {
      dispatch(getProjectByUserId(user.id));
    }
  }, [user?.id, dispatch]);
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const [openAlert, setOpenAlert] = React.useState(false);
  const [beforeDeleteAlert, setBeforeDeleteAlert] = React.useState(false);
  const [alertToDelete, setAlertToDelete] = React.useState({});
  const domHostname = window?.location?.host;
  const [error, setError] = React.useState(null);
  const [updateAble, setUpdateAble] = React.useState(false);
  const [updatedUserData, setUpdatedUserData] = React.useState([]);

  const handleEditRowsModelChange = React.useCallback((newModel) => {
    const updatedModel = { ...newModel };
    Object.keys(updatedModel).forEach((id) => {
      if (updatedModel[id].username) {
        const isValid = validateEmail(updatedModel[id].username.value);
        updatedModel[id].username = {
          ...updatedModel[id].username,
          error: !isValid,
        };
      }
    });
    setEditRowsModel(updatedModel);
  }, []);

  //edit method to edit the work items
  const handleEditItem = (id, value, name) => {
    const data = {
      id,
      value,
      name,
    };
    dispatch(updateUserDataToTheTable(data));
  };
  const handleDeleteUser = (event) => {
    setOpenAlert(true);
    setAlertToDelete(event);
    // if (event.roleType === "USER") {

    // } else if (event.roleType === "ADMIN") {
    //   setBeforeDeleteAlert(true);
    // }
  };
  const handleDeleteFromBackend = (id) => {
    dispatch(deleteUserFromTheProject(projectId, id, user?.id));
    setOpenAlert(false);
  };
  const defaultTheme = createTheme();
  const useStyles = makeStyles(
    () => {
      return {
        root: {
          "& .Mui-error": {
            backgroundColor: `rgb(126,10,15,0.1)`,
            color: "#750f0f",
          },
        },
        rootOfProcessNode: {
          // minHeight: 520,
          minWidth: "780px",
          "& .MuiDataGrid-cell": {
            border: "1px solid #d4cfcf",
            borderRadius: "8px",
            color: "#ADADAD",
          },
          "& .MuiDataGrid-cell--editable": {
            backgroundColor: "white",
            color: "#1a3e72",
          },
          "& .MuiDataGrid-cell--textCenter": {
            backgroundColor: "white",
          },
        },
        addUserStyle: {
          background: "#5D5FEF",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          "& p": {
            color: "white",
            paddingLeft: "13px",
            fontWeight: 700,
            fontSize: "18px",
          },
          ".custompar": {
            marginRight: "10px",
          },
        },
        buttonInDataGrid: {
          position: "relative",
        },
        addButton: {
          color: "white",
          width: "32px",
          height: "32px",
        },
      };
    },
    { defaultTheme }
  );
  const classes = useStyles();
  const ifNewUserExists = checkduplicity(rows).filter(
    (item) => item?.id.length < 20
  );

  React.useEffect(() => {
    const new_rows = [];
    rows.map((item) => {
      if (
        item.projects?.length > -1
        // && item.projectList.length < item.projects?.length
      ) {
        new_rows.push(item);
      }
    });
    setUpdatedUserData(new_rows);

    setUpdateAble(new_rows.length ? true : false);
  }, [rows]);

  React.useEffect(() => {
    if (!ifNewUserExists.length) {
      setError("");
    }
  }, [ifNewUserExists]);

  const getProjectIdList = (arr) => {
    return arr !== undefined && arr.length > 0 ? arr.map((item) => item.value) : [];
  };

  const getremoveIdList = (arr) => {
    const finalfind = arr.projectList.filter(item => !arr.projects.find(item2 => item2.value === item.id))
    return finalfind.map(item => item.id)
  }

  const handleAddAllUsers = () => {
    const name = ifNewUserExists.every((item) => Boolean(item?.name));
    const username = ifNewUserExists.every((item) => Boolean(item?.username));
    const projectsList = ifNewUserExists.every((item) => Boolean(item?.projects));
    if (name && username && projectsList) {
      const userList = ifNewUserExists.map((item) => {
          setError("");
          const { name, username } = item;
          let newData = { name };
          newData.username = username.toLowerCase();
          newData.roleType = "USER";
          newData.projectIdList = getProjectIdList(item?.projects);
          newData.organizationId = organizationId;
          newData.redirectUrl = redirectUrl(domHostname, "user-activation");
          newData.projectId = projectId;
          newData.isAdmin = false;
          return newData;
        
        
      });

      const checkFalse = userList.map(value => getProjectIdList(value?.projectIdList)?.length > 0)

      if (checkFalse.find(value => value === false) !== false){
        dispatch(
          postUserByAssignedProjectByAdminId(
            { userList },
            user?.id,
            user?.projectId,
            ifNewUserExists
          )
        );
      }else{
        setError("Project not selected for someuser");
      }
    } else if (!name && !username) {
      setError("Please input the name & email");
      return 0;
    } else if (!name) {
      setError("Please input the name");
      return 0;
    }
      else if (!projectsList) {
        setError("Please select at least one project");
        return 0;
    } else if (!username) {
      setError("Please input the email");
      return 0;
    }
  };

  const handleUpdateUser = () => {
    updatedUserData.map(item => {
      dispatch(
        notifyUserAfterAdding(item.username, redirectUrl(domHostname, "user-activation"))
      );
    })
    const userList = updatedUserData.map((item) => {
      setError("");
      const { name, username } = item;
      let newData = { name };
      newData.id = item.id;
      newData.username = username.toLowerCase();
      newData.roleType = "USER";
      newData.projectIdList = getProjectIdList(item?.projects);
      newData.removeProjectIds = getremoveIdList(item)
      newData.organizationId = organizationId;
      newData.redirectUrl = redirectUrl(domHostname, "user-activation");
      newData.projectId = projectId;
      newData.isAdmin = false;
      return newData;
    });

    dispatch(
      patchUserByAssignedProjectByAdminId(
        { userList },
        user?.id,
        user?.projectId
      )
    );
  };

  return (
    <div className={classes.rootOfProcessNode}>
      {openAlert && (
        <CustomSnacbar
          opened={openAlert}
          alertToDelete={alertToDelete}
          handleDeleteFromBackend={handleDeleteFromBackend}
          setOpenAlert={setOpenAlert}
          type="user"
        />
      )}
      {beforeDeleteAlert && (
        <CustomSnacbar
          opened={beforeDeleteAlert}
          DeleteText="This is an admin account you cannot delete this user"
          setOpenAlert={setBeforeDeleteAlert}
        />
      )}
      <Box
        className={classes.addUserStyle}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <p>Add User(s)</p>
        <RIconButton
          onClick={() => addNewUserToTheColumn()}
          title="Add new user"
        >
          <AddCircleOutlineIcon className={classes.addButton} />
        </RIconButton>
      </Box>

      <p style={{ color: "red" }}>{error}</p>
      <DataGrid
        rows={uniqBy(rows, "id")}
        autoHeight={true}
        columns={columns}
        className={classes.root}
        onCellEditCommit={(row) => {
          if (row.id && row.field) {
            handleEditItem(row.id, row.field, row.value);
          }
        }}
        isCellEditable={(params) =>
          params?.row?.id.length > 20 ? false : true
        }
        hideFooterSelectedRowCount={true}
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
        disableColumnMenu
        pageSize={10}
        onCellClick={(row) => {
          if (row.id && row.field === "Delete") {
            if (row?.row?.id.length > 20) {
              handleDeleteUser(row.row);
            } else {
              dispatch(deleteUserToTable(row.id));
            }
          }
        }}
      />
      {ifNewUserExists.length > 0 && (
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading ? true : false}
          onClick={!loading && handleAddAllUsers}
        >
          {loading ? "Creating..." : "Create"}
        </Button>
      )}

      {!ifNewUserExists.length > 0 && updateAble && (
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading ? true : false}
          onClick={!loading && handleUpdateUser}
        >
          {loading ? "Updating..." : "Update"}
        </Button>
      )}
    </div>
  );
}
