/* eslint-disable no-unused-expressions */
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FirstCard } from "..";
import { images } from "assets";
import { useCallback, useEffect, useState } from "react";
import { multidates } from "Utils";
import { useSelector } from "react-redux";
import { getReleaseCardCounting, getVelocityReport } from "redux/actions";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import ReactECharts from "echarts-for-react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getOptionsForKanban, getOptionsForRelease } from "Utils/chartconfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./Workmanagement.css"


export const WorkManagement = ({
  persona,
  classes,
  setOpenPersonaModal,
  layoutSec,
}) => {
  const [dif, setDif] = useState(7);
  const { user } = useSelector((state) => state.auth);
  const { velocity } = useSelector((state) => state.kanban);
  const dispatch = useDispatch();
  const [velocitydays, setVelocityDays] = useState([]);
  const { releasecardcounts } = useSelector((state) => state.kanban);
  const history = useHistory();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const fetchDateChange = useCallback((value) => {
    const data = {
      startDate: multidates[3].date,
      endDate: format(new Date(), "dd-MM-yyyy"),
    };
    dispatch(getVelocityReport(user?.projectId, data));
  }, []);

  useEffect(() => {
    fetchDateChange(dif);
  }, [fetchDateChange]);

  useEffect(() => {
    dispatch(getReleaseCardCounting(user?.projectId));
  }, [user]);

  useEffect(() => {
    if (velocity.length > 0) {
      const findval = velocity.find(
        (value) =>
          value.date === multidates.find((value) => value.number === dif).date
      );
      const anothernew = velocity.filter((value) => {
        const itemdate = new Date(value.date);
        itemdate <= new Date(multidates[0].date) &&
          itemdate >=
          new Date(multidates.find((value) => value.number === dif).date);
      });
      // console.log({anothernew})
      setVelocityDays(velocity);
    }
  }, [velocity, dif]);

  const handleCarouselButtonClick = (e) => {
    // // react-multiple-carousel__arrow react-multiple-carousel__arrow--left
    // // console.log(e.target.classList.contains('react-multiple-carousel__arrow'))
    const isCarouselButton = e.target.classList.contains(
      "react-multiple-carousel__arrow"
    );

    if (isCarouselButton) {
      e.stopPropagation();
    } else {
      history.push("/board");
    }
  };

  return (
    <Grid
      onClick={() => !persona?.name && setOpenPersonaModal(false)}
      className={classes.cardSection}
      item
      sm={layoutSec ? 6 : 12}
    >
      {persona?.name ? (
        <div >
          <Carousel responsive={responsive}>
            <FirstCard titleVariant="h5" titleText="Work Management" subtitle="Delivery: Dynamic user stories, Tasks and Bugs">
              <div
                style={{ position: "relative", top: "-35px" }}
                onClick={(e) => handleCarouselButtonClick(e)}
              >
                <div>
                  {velocitydays.length > 0 ? 
                  <ReactECharts
                    style={{ height: "210px" }}
                    option={getOptionsForKanban(velocitydays, "line")}
                    /> : <div>
                        <img src={images.emptyImage} alt="" style={{height:"150px", width: "150px", position: "relative", left: "35%"}}/>
                      </div>}
                </div>

              </div>
            </FirstCard>

            {releasecardcounts &&
              releasecardcounts.length > 0 ?
              releasecardcounts.map((item, index) => {
                return (
                  <FirstCard key={index} titleVariant="h5" titleText={`Release ${index + 1}`} subtitle="Delivery: Dynamic user stories, Tasks and Bugs">
                    <div
                      style={{ position: "relative", top: "-35px" }}
                      onClick={(e) => handleCarouselButtonClick(e)}
                    >
                      <ReactECharts
                        style={{ height: "200px" }}
                        option={getOptionsForRelease(item, index)}
                      />
                    </div>
                  </FirstCard>
                );
              }) : <FirstCard titleVariant="h5" titleText="No Release Card">
                  <img src={images.emptyImage} alt="" style={{ height: "150px", width: "150px", position: "relative", left: "35%" }} />
                </FirstCard>}
          </Carousel>
        </div>
      ) : (
        <FirstCard titleVariant="h5" titleText="Work Management" />
      )}
    </Grid>
  );
};
