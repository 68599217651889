import { addNewCardToRiskAndIssueKanban } from 'redux/actions';
import { riskAndIssuesData } from 'assets/Data/RiskAndIssue';
import store from 'redux/store';
import { writingComment } from 'helpers/Board';
import { nanoid } from 'nanoid';

export const createRiskSubmit = (data, user, notDeletableItem) => {
  const riskAndIssueData = new FormData();
  riskAndIssueData.append("id", data.id);
  riskAndIssueData.append("title", data.inputValue);
  riskAndIssueData.append(
    "assessments",
    JSON.stringify(riskAndIssuesData.initialAssesment)
  );
  const metadata = [writingComment(user, data)];
  riskAndIssueData.append("createDate", new Date());
  riskAndIssueData.append("description", "");
  riskAndIssueData.append("evidenceLinks", JSON.stringify([]));
  riskAndIssueData.append("notes", JSON.stringify(metadata));
  riskAndIssueData.append(
    "riskLaneId",
    notDeletableItem.id
  );
  riskAndIssueData.append("type", "issue");
  riskAndIssueData.append("projectId", user?.projectId);
  riskAndIssueData.append("treatment", []);
  for (let pic of []) {
    riskAndIssueData.append("attachments", pic);
  }
  store.dispatch(addNewCardToRiskAndIssueKanban(user?.projectId, riskAndIssueData));
};

const riskFormData = (user, data, evidenceLinks, riskKanbanData, sizeOfProblem, filesToSave) => {
  const cardId = `sqbear_risk_and_issue_${nanoid(15)}`;
  const formDataa = new FormData();
  formDataa.append(
    "assessments",
    JSON.stringify(riskAndIssuesData.initialAssesment)
  );
  const metadata = [writingComment(user, data)];
  formDataa.append("createDate", new Date());
  formDataa.append("description", data?.description ? data.description : "");
  formDataa.append("evidenceLinks", JSON.stringify(evidenceLinks));
  formDataa.append("id", cardId);
  formDataa.append("notes", JSON.stringify(metadata));
  formDataa.append(
    "riskLaneId",
    riskKanbanData.find((item) => item.notDeletable).id
  );
  if (sizeOfProblem === 0) {
    formDataa.append("type", "risk");
  }
  if (sizeOfProblem === 1) {
    formDataa.append("type", "issue");
  }
  formDataa.append("title", data?.title);
  formDataa.append("projectId", user?.projectId);
  formDataa.append("treatment", []);
  for (let pic of filesToSave) {
    formDataa.append("attachments", pic);
  }
  return formDataa;
}

const riskMultipleFormData = (user, data, evidenceLinks, riskKanbanData, sizeOfProblem, filesToSave) => {
  const formDataa = new FormData();
  formDataa.append(
    "assessments",
    JSON.stringify(riskAndIssuesData.initialAssesment)
  );
  const metadata = [writingComment(user, data)];
  formDataa.append("createDate", new Date());
  formDataa.append("description", data?.description ? data.description : "");
  formDataa.append("evidenceLinks", JSON.stringify(evidenceLinks));
  formDataa.append("id", data.id);
  formDataa.append("notes", JSON.stringify(metadata));
  formDataa.append(
    "riskLaneId",
    riskKanbanData.find((item) => item.notDeletable).id
  );
  if (sizeOfProblem === 0) {
    formDataa.append("type", "risk");
  }
  if (sizeOfProblem === 1) {
    formDataa.append("type", "issue");
  }
  formDataa.append("title", data?.title);
  formDataa.append("projectId", user?.projectId);
  formDataa.append("treatment", []);
  for (let pic of filesToSave) {
    formDataa.append("attachments", pic);
  }
  return formDataa;
}
export { riskFormData, riskMultipleFormData }