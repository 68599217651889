import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { images } from "assets";
const SideBar = ({ personaDataToTheNode, children }) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };
  const classes = useStyles();
  return (
    <aside className={classes.asideArea}>
      <div className="description">
        <Tooltip
          title={
            personaDataToTheNode?.valueStreamName
              ? personaDataToTheNode?.valueStreamName
              : ""
          }
          placement="left"
          arrow
        >
          <Typography
            variant="subtitle2"
            style={{
              fontWeight: "600",
              whiteSpace: "nowrap",
              width: "55px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            component="h3"
            color="primary"
          >
            {personaDataToTheNode?.valueStreamName}
          </Typography>
        </Tooltip>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tooltip title="Process Node" placement="left" arrow>
          <img
            style={{
              height: "40px",
              width: "50px",
              cursor: "grab",
              margin: "10px 0px",
            }}
            onDragStart={(event) => onDragStart(event, "process")}
            draggable
            src={images.processDesign}
            alt="Process Node"
          />
        </Tooltip>
        <Tooltip title="Decision Node" placement="left" arrow>
          <img
            style={{
              height: "40px",
              width: "50px",
              cursor: "grab",
              margin: "10px 0px",
            }}
            onDragStart={(event) => onDragStart(event, "decision")}
            draggable
            src={images.decision}
            alt="Decision Node"
          />
        </Tooltip>
        <Tooltip title="File Node" placement="left" arrow>
          <img
            style={{
              height: "40px",
              width: "40px",
              cursor: "grab",
              margin: "10px 0px",
              color: "#7478E8",
            }}
            onDragStart={(event) => onDragStart(event, "file")}
            draggable
            src={images.upload}
            alt="file Node"
          />
        </Tooltip>
        {/* <Tooltip title="Link Node" placement="left" arrow>
          <img
          className={classes.linkNode}

            onDragStart={(event) => onDragStart(event, "link")}
            draggable
            src={images.linkImage}
            alt="file Node"
          />
        </Tooltip> */}

        {children}
      </div>
    </aside>
  );
};
export default SideBar;

const useStyles = makeStyles(() => ({
  asideArea: {
    padding: " 15px 10px",
    fontSize: " 12px",
    background: "#F3EBFF",
    height: "376px",
    borderRadius: "40% 0px 0px 40%",
    border: "1px solid #dedede",
    boxSizing: " border-box",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    width: " 77px",
    zIndex: "1000",
    maxWidth: "250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    clipPath:
      "polygon(125% 0%, 100% 25%, 100% 75%, 125% 100%, 0% 80%, -10% 15%)",
  },
  linkNode:{
    height: "40px",
    width: "40px",
    cursor: "grab",
    margin: "10px 0px",
    fill: "#7478E8",
  }
}));
