import { paymentConstants } from "../actions/constant";

const initState = {
    users: [],
    loading: false,
    error: '',
    getSinglePayData: {},
    plans: [],
    successStripe: false,
    successStripeRequest: false,
};

const paymentReducer = (state = initState, action) => {
    switch (action.type) {
        case paymentConstants.USER_ADD:
            const checkDuplicityUser = [...state.users, ...action.payload];
            return {
                ...state,
                loading: false,
                users: checkDuplicityUser,
            };
        case paymentConstants.USER_EMPTY:
            return {
                ...state,
                loading: false,
                users: [],
            };
        case paymentConstants.DISABLE_FALSE:
            return {
                ...state,
                successStripeRequest: false,
            };
        case paymentConstants.USER_DELETE:
            const userData = [...state.users];
            const filteredData = userData.filter(item => item.id !== action.payload);
            return {
                ...state,
                users: filteredData,
            };
        case paymentConstants.GET_ALL_SUBSCRIPTIONS:
            return {
                ...state,
                plans: action.payload
            };
        case paymentConstants.SAVE_SUBSCRIPTIONS_STRIPE_REQUEST:
            return {
                ...state,
                successStripeRequest: true,
                successStripe: false
            };
        case paymentConstants.GET_SINGLE_SUBSCRIPTION:
            return {
                ...state,
                getSinglePayData: action.payload,
            };
        case paymentConstants.USER_UPDATE:
            // let allTableDataa = [...state.users];
            let allTableDataa = [...state.users];
            const index = allTableDataa.findIndex((info) => (info.id === action.payload.id));
            allTableDataa[index][action.payload.value] = action.payload.name;
            return {
                ...state,
                users: allTableDataa
                // users: allTableDataa
            };
        case paymentConstants.SAVE_SUBSCRIPTIONS_STRIPE_SUCCESS:
            return {
                ...state,
                successStripeRequest: false,
                successStripe: true
            };
        case paymentConstants.SAVE_SUBSCRIPTIONS_STRIPE_FAILURE:
            return {
                ...state,
                successStripe: true
            };
        case paymentConstants.STRIPE_SUCCESS_MODAL_CLOSE:
            return {
                ...state,
                successStripe: false
            };
        default:
            return state;
    }
};
export default paymentReducer;
