import { logEnable } from './constant';
import axios from '../../api/axios';
import { errorAlert, successAlert } from 'Utils';


// for enabling loghour according to projectid
export const enableLoghour = (projectId, data) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const response = await axios.put(`/v1/projects/${projectId}?enableLog=${data}`)
                if (response && response.status === 202) {
                    dispatch({
                        type: logEnable.UPDATE_LOGHOUR_ENABLE_SUCCESS,
                        payload: response.data.data.logEnabled
                    })
                    successAlert(`Loghour ${data ? 'enable' : 'disable'} success`)
                    fetchEnableLoghour(projectId)
                }
            }
        } catch (error) {
            if (error) {
                dispatch({
                    type: logEnable.UPDATE_LOGHOUR_ENABLE_FAILED,
                    payload: error.response
                })
                errorAlert("Error occured")
            }
        }
    }
}


// fetching log hour enable
export const fetchEnableLoghour = (projectId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: logEnable.FETCH_ENABLE_LOGHOUR_REQUEST
            })
            if (projectId) {
                const response = await axios.get(`/v1/projects/${projectId}`)
                if (response && response.status === 200) {
                    dispatch({
                        type: logEnable.FETCH_ENABLE_LOGHOUR_SUCCESS,
                        payload: response.data.data.logEnabled
                    })
                }
            }
        } catch (error) {
            dispatch({
                type: logEnable.FETCH_ENABLE_LOGHOUR_FAILED,
            })
        }
    }
}