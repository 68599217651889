/**
 *@function Columns.jsx
 *@author Azim
 *
 **/
const columns = [
  {
    field: "question",
    headerName: "Questions",
    minWidth: 240,
    sortable: false,
    description: "Name",
    editable: false,
  },
  {
    field: "awareness",
    headerName: "Awareness",
    minWidth: 250,
    editable: true,
    description: "Date Added",
    sortable: false,
  },

  {
    field: "consideration",
    headerName: "Consideration",
    sortable: false,
    editable: true,
    minWidth: 200,
  },
  {
    field: "decision",
    headerName: "Decision",
    sortable: false,
    minWidth: 200,
    editable: true,
  },
  {
    field: "use",
    headerName: "Use",
    sortable: false,
    minWidth: 200,
    editable: true,
  },
  {
    field: "support",
    headerName: "Support",
    sortable: false,
    editable: true,
    minWidth: 200,
  },
  {
    field: "loyalty",
    headerName: "Loyalty & Advocacy",
    sortable: false,
    minWidth: 200,
    editable: true,
  },
];

export default columns;
