import React from 'react';
// import axios from 'axios';
// import { useCallback } from 'react';
// import { useEffect } from 'react';
// import { auth } from 'api/Auth';
// import { stringify, parse } from "zipson";
// import { useState } from 'react';


const CallBack = () => {
    // const [contentData, setContentData] = useState([])

    // const fetchData = useCallback(async () => {
    //     try {
    //         const config = {
    //             headers: {
    //                 Authorization: "Bearer " + auth.getToken()
    //             }
    //         }
    //         const response = await axios.get("http://localhost:8080/api/v1/projects/{projectId}/contents/content/get-all", config)
    //         if(response.status){config
    //             const data = response.data
    //             const newData = data?.map(item => {
    //                 try {
    //                     const mainData = item && item.messageContent && parse(item.messageContent);
    //                     item.messageContent = JSON.stringify(mainData);

    //                 } catch (parseError) {
    //                     console.error("Parsing error:", parseError);
    //                     item.messageContent = "";
    //                 }
    //                 return item;
    //             })
    //             setContentData(newData)

                
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // },[])


    // useEffect(() => {
    //     fetchData()
    // },[])
    


    // const handleMigrate = async () => {
    //     console.log({contentData})

    //     const config = {
    //         headers: {
    //             Authorization: "Bearer " + auth.getToken()
    //         }
    //     }
    //     const response = await axios.post("http://localhost:8080/api/v1/projects/{projectId}/contents/alter-existing",contentData, config)
    //     if(response.status){
    //         console.log("Successfully migrated")
    //     }
    // }


    return (
        <div>
            this is call back page
            {/* <button onClick={() => handleMigrate()}>Click to Migrate</button> */}
        </div>
    );
};

export default CallBack;