import { getSingleConfiguration, getSingleCardConfiguration } from 'redux/actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useLeftSideBar = (applyToNew, userId) => {
    const dispatch = useDispatch();
    const { singleCog } = useSelector(state => state.profileConfig);
    const { cardconfig } = useSelector(state => state.cardConfig)
    // console.log({applyToNew}, {userId})
    useEffect(() => {
        if (applyToNew && userId) {
            // console.log("calling it now")
            dispatch(getSingleConfiguration(userId))
            dispatch(getSingleCardConfiguration(userId))
        }
    }, [])
    return { singleCog, cardconfig }
}

export default useLeftSideBar;