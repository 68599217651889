const local = {
  backend: "http://localhost:8080/api",
  socket: "http://localhost:8080",
  callback: "http://localhost:3000/callback",
  stripe:
    "pk_test_51K9GQRBm7Y5m3i8tJIDgwXFGMUij4tIY1dZCf11csAjFSIg2lC7NOevqaeUBMSxs6HcRTfDIAoHn2FUfhEwVv7Jg00Z59dLEi4",
  googleSignInClientId:
    "146837668659-hfldecsi9fa0dkhfd7a74k2vjtbjk8ke.apps.googleusercontent.com",
};

const dev = {
  backend: "http://localhost:8080/api",
  socket: "http://localhost:8080",
  callback: "http://localhost:3000",
  stripe:
    "pk_test_51K9GQRBm7Y5m3i8tJIDgwXFGMUij4tIY1dZCf11csAjFSIg2lC7NOevqaeUBMSxs6HcRTfDIAoHn2FUfhEwVv7Jg00Z59dLEi4",
  googleSignInClientId:
    "146837668659-hfldecsi9fa0dkhfd7a74k2vjtbjk8ke.apps.googleusercontent.com",
};

const prod = {
  backend: "https://backend.squarebear.com.au/api",
  socket: "https://backend.squarebear.com.au",
  callback: "https://app.squarebear.com.au/callback",
  stripe:
    "pk_live_51K9GQRBm7Y5m3i8thkK8GcYjnBbrnVgI2q9mZqBzXXS4cAIECJKVRbz9PXKrWx5RcBo2J1zh5ppOa6XNfmW7VKvk00yTmXP4fM",
  googleSignInClientId:
    "146837668659-hfldecsi9fa0dkhfd7a74k2vjtbjk8ke.apps.googleusercontent.com",
};

const dev2 = {
  backend: "https://backendtest.squarebear.com.au/api",
  socket: "https://backendtest.squarebear.com.au",
  callback: "https://test.squarebear.com.au/callback",
  stripe:
    "pk_live_51K9GQRBm7Y5m3i8thkK8GcYjnBbrnVgI2q9mZqBzXXS4cAIECJKVRbz9PXKrWx5RcBo2J1zh5ppOa6XNfmW7VKvk00yTmXP4fM",
  googleSignInClientId:
    "146837668659-hfldecsi9fa0dkhfd7a74k2vjtbjk8ke.apps.googleusercontent.com",
};

const local2 = {
  backend: "http://localhost:8080/api",
  socket: "http://localhost:8080",
  callback: "http://localhost:3000",
  stripe:
    "pk_live_51K9GQRBm7Y5m3i8thkK8GcYjnBbrnVgI2q9mZqBzXXS4cAIECJKVRbz9PXKrWx5RcBo2J1zh5ppOa6XNfmW7VKvk00yTmXP4fM",
  googleSignInClientId:
    "146837668659-hfldecsi9fa0dkhfd7a74k2vjtbjk8ke.apps.googleusercontent.com",
};

export const AppMode = prod;
