import React from 'react';
import { getBezierPath } from 'reactflow';

const CustomEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    markerEnd,
    markerStart,
}) => {
    const [edgePath] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <path
                id={id}
                style={{ strokeWidth: 2, stroke: "black" }}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
                markerStart={markerStart}
            />
            <text>
                {
                    data.text && <textPath
                        href={`#${id}`}
                        style={{
                            fontSize: '12px',
                            cursor: 'pointer',

                        }}
                        startOffset="50%"
                        textAnchor="middle"
                    >
                        {data.text}
                    </textPath>
                }

            </text>
        </>
    );
}
export default CustomEdge;