import { logHour } from "../actions/constant";

const initState = {
    error: "",
    success: false,
    data: [],
    enable: false,
}


const logHourReducer = (state = initState, action) => {
    switch(action.type){
        case logHour.FETCH_LOG_HOURS_REQUEST:
            return{
                ...state,
                success: false,
                data: []
            }
        case logHour.FETCH_LOG_HOURS_SUCCESS:
            return{
                ...state,
                success: true,
                data: action.payload
            }
        case logHour.FETCH_LOG_HOURS_FAILED:
            return{
                ...state,
                success: false,
                error: action.payload,
                data:[]
            }
        case logHour.ADD_LOG_HOUR:
            return{
                ...state,
                success: true,
                data: action.payload
            }
        case logHour.FETCH_LOG_HOUR_REQUEST:
            return{
                ...state,
                success: false,
                data:[]
            }
        case logHour.FETCH_LOG_HOUR_SUCCESS:
            return {
                ...state,
                success: true,
                data: action.payload
            }
        case logHour.FETCH_LOG_HOUR_FAILED:
            return {
                ...state,
                success: false,
                error: action.payload,
                data: []
            }
        case logHour.UPDATE_LOG_HOUR_SUCCESS:
            return {
                ...state,
                success: true,
                data: action.payload
            }
        case logHour.UPDATE_LOG_HOUR_FAILED:
            return {
                ...state,
                error:  action.payload,
                data:[]
            }
        case logHour.DELETE_LOG_HOUR_SUCCESS:
            return {
                ...state,
                success: true
            }
        case logHour.DELETE_LOG_HOUR_FAILED:
            return {
                ...state,
                success: false
            }
        case logHour.FIND_LOG_HOUR_BYCARD_REQUEST:
            return {
                ...state,
                success: false,
                data: []
            }
        case logHour.FIND_LOG_HOUR_BYCARD_SUCCESS:
            return {
                ...state,
                success: true,
                data: action.payload
            }
        case logHour.FIND_LOG_HOUR_BYCARD_FAILED:
            return {
                ...state,
                success: false,
                error: action.payload,
                data: []
            }
        default:
            return state;
    }
}

export default logHourReducer