import { cardconfig } from "../actions/constant";

const initialState = {
    loading: false,
    configuration: [],
    cardconfig: {},
    error: "",
    cardRenderer: {},
};

const cardConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case cardconfig.GET_ALL_PROFILE_CONFIG_REQUEST_CARD:
            return {
                ...state,
                loading: true,
                configuration: [],
                error: "",
            };
        case cardconfig.GET_ALL_PROFILE_CONFIG_SUCCESS_CARD:
            return {
                ...state,
                loading: false,
                configuration: action.payload,
                error: "",
            };
        case cardconfig.GET_ALL_PROFILE_CONFIG_ERROR_CARD:
            return {
                ...state,
                loading: false,
                configuration: [],
                error: action.payload,
            };

        case cardconfig.GET_SINGLE_PROFILE_CONFIG_REQUEST_CARD:
            return {
                ...state,
                loading: true,
                cardconfig: {},
                error: "",
            };
        case cardconfig.GET_SINGLE_PROFILE_CONFIG_SUCCESS_CARD:
            return {
                ...state,
                loading: false,
                cardconfig: action.payload,
                error: "",
            };
        case cardconfig.GET_SINGLE_PROFILE_CONFIG_FAILED_CARD:
            return {
                ...state,
                loading: false,
                cardconfig: {},
                error: action.payload,
            };
        case cardconfig.SAVE_CONFIG_CARD:
            return {
                ...state,
                loading: false,
            };
        case cardconfig.UPDATE_CONFIG_CARD:
            return {
                ...state,
                loading: false,
            };
        case cardconfig.GET_CHECKED_SUCCESS_CARD:
            return {
                ...state,
            };
        case cardconfig.GET_ALL_HOME_REQUEST_CARD:
            return {
                ...state,
                cardRenderer: "",
                loading: true,
            };
        case cardconfig.GET_ALL_HOME_SUCCESS_CARD:
            return {
                ...state,
                loading: false,
                cardRenderer: action.payload,
            };
        default:
            return state;
    }
};

export default cardConfigReducer;
