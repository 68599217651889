import { FormControlLabel, makeStyles } from "@material-ui/core";
import {
  DeleteOutline as DeleteOutlineIcon,
  CloudDownloadOutlined as CloudDownloadOutlinedIcon,
  Visibility,
} from "@material-ui/icons";
import { RIconButton } from "..";

/**
 *@function Columns.jsx
 *@author Azim
 *
 **/
const MatDownload = () => {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <RIconButton
          title="Download this file"
          placement="top"
          color="secondary"
          aria-label="add an alarm"
          className={classes.addButton}
        >
          <CloudDownloadOutlinedIcon />
        </RIconButton>
      }
    />
  );
};
// const MatVisible = () => {
//   const classes = useStyles();

//   return (
//     <FormControlLabel
//       control={
//         <RIconButton
//           title="preview this file"
//           placement="top"
//           color="secondary"
//           aria-label="add an alarm"
//           className={classes.addButton}
//         >
//           <Visibility />
//         </RIconButton>
//       }
//     />
//   );
// };
const handleDownload = (fileUrl, fileName) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileName;
  document?.body?.appendChild(link);
  link.click();
  document?.body?.removeChild(link);
};
const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 280,
    sortable: false,
    description: "Name",
    editable: false,
  },
  {
    field: "type",
    headerName: "Type",
    minWidth: 180,
    editable: true,
    description: "Date Added",
    sortable: false,
  },
  {
    field: "download",
    headerName: "Download",
    sortable: false,
    minWidth: 180,
    editable: true,
    renderCell: (params) => {
      return (
        <div
          onClick={() => handleDownload(params.row.download, params.row.name)}
        >
          <MatDownload index={params.row.id} />
        </div>
      );
    },
  }
  // {
  //   field: "preview",
  //   headerName: "Preview",
  //   sortable: false,
  //   minWidth: 180,
  //   editable: true,
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         <MatVisible index={params.row.id} />
  //       </div>
  //     );
  //   },
  // },
];

export default columns;
const useStyles = makeStyles((theme) => ({
  addButton: {
    cursor: "pointer",
    marginLeft: "10px",
    "& .MuiSvgIcon-root": {
      color: "#999999",
    },
  },
}));
