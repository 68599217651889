import { images } from "assets";

const chartArray = (values, type) => {
    const result = [];
    const lastFiveValues = values.slice(-5);

    const hasNonZeroSizeZero = lastFiveValues.some(item => item.sizeZero !== 0);
    if (hasNonZeroSizeZero) {
        result.push({
            name: 'N',
            type: type,
            data: values.map((item) => item.sizeZero !== 0 ? item.sizeZero : 1)
        });
    }

    const hasNonZeroSizeOne = lastFiveValues.some(item => item?.sizeOne !== 0);
    if (hasNonZeroSizeOne) {
        result.push({
            name: 'S',
            type: type,
            data: values.map((item) => item?.sizeOne !== 0 ? item.sizeOne : 1)
        });
    }

    const hasNonZeroSizeTwo = lastFiveValues.some(item => item?.sizeTwo !== 0);
    if (hasNonZeroSizeTwo) {
        result.push({
            name: 'M',
            type: type,
            data: values.map((item) => item?.sizeTwo !== 0 ? item.sizeTwo : 1)
        });
    }

    const hasNonZeroSizeThree = lastFiveValues.some(item => item?.sizeThree !== 0);
    if (hasNonZeroSizeThree) {
        result.push({
            name: 'L',
            type: type,
            data: values.map((item) => item?.sizeThree !== 0 ? item.sizeThree : 1)
        });
    }

    return result;
}

export const getOptionsForKanban = (values, type) => {
    let options;


    options = {}

    if(values.length > 0) {
        options.tooltip= {
            trigger: 'axis'
        };
        options.legend= {
            data: ['N', 'S', 'M', 'L']
        }
        options.grid= {
            left: '3%',
                right: '4%',
                    bottom: '3%',
                        containLabel: true
        }
        options.toolbox= {
            feature: {
                saveAsImage: { }
            }
        }
        options.xAxis={
            type: 'category',
                boundaryGap: false,
                    data: values.map((item) => item?.date)
        }
        options.yAxis= {
            type: 'value'
        }
        options.series= chartArray(values, type)
    }
    return options ?? [];
}



export const getJourneyMapChart = (values) => {

    const newvalues = values.map(item => {
        if (item.title !== "Questions")
            return {
                value: parseFloat(item.laneComplete) == 0 ? 0 : parseFloat(item.laneComplete),
                name: item.label
            }
    })
    const options = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            data: []
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: { show: true }
            }
        },
        series: [
            {
                name: 'Journey map',
                type: 'pie',
                radius: [30, 70],
                center: ['50%', '50%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 8
                },
                data: [
                    ...newvalues,
                ]
            }
        ]
    }
    return options
}

export const getWorkFlowCharts = (values) => {

    const newvalues = values.map(item => {
        return {
            value: parseFloat(item.totalComplete) == 0 ? 0 : parseFloat(item.totalComplete),
            name: item.valueStreamName
        }
    })
    const options = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            data: []
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: { show: true }
            }
        },
        series: [
            {
                name: 'Work Flow',
                type: 'pie',
                radius: [30, 50],
                center: ['50%', '50%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 8
                },
                data: [
                    ...newvalues,
                ]
            }
        ]
    }
    return options
}




export const getOptionsForRelease = (datas, index) => {

    const dateitems = [...new Set(datas.map(item => item.date))];

    const labels = [...new Set(datas.map(item => item.laneName))]


    const mainvalue = [];
    labels.map(item => {
        const datanew = datas.filter(item2 => item2.laneName === item)
        const madedata = {
            name: item,
            type: 'line',
            areaStyle: {},
            emphasis: {
                'focus': 'series'
            },
            data: datanew.map(itemnew => itemnew.count)
        }
        mainvalue.push(madedata);
    })


    const options = {
        tooltip: {
            trigger: 'axis',
            position: function (point, params, dom, rect, size) {
                // Adjust the vertical position by adding a small offset
                var offsetY = -100;
                var rectTop = point[1] + offsetY;
                return [point[0], rectTop];
            }
        },
        legend: {
            top: 30,
            data: labels.filter(value => value !== "Ice Box")
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top: '60px',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: dateitems.reverse()
        },
        yAxis: {
            type: 'value'
        },
        series: [
            ...mainvalue.filter(value => value.name !== 'Ice Box')
        ]
    }
    return options;
}




export const getRiskAndIssueItems = (values) => {
    const newvalues = values.map(item => {
        return {
            value: item.cardCount,
            name: item.laneTitle
        }
    })


    const options = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            data: []
        },
        toolbox: {
            show: true,
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        label: {
            show: false
        },
        emphasis: {
            label: {
                show: true
            }
        },
        series: [
            {
                name: 'Risk and Issues',
                type: 'pie',
                radius: [30, 70],
                center: ['50%', '35%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 8
                },
                data: [
                    ...newvalues
                ],
                // emphasis: {
                //     itemStyle: {
                //         shadowBlur: 10,
                //         shadowOffsetX: 0,
                //         shadowColor: 'rgba(0, 0, 0, 0.5)'
                //     }
                // }
            }
        ]
    };


    return options;
}