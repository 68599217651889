import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import { emojis } from "assets/Data/emojis";
import React from "react";
import { Controller } from "react-hook-form";

const EmojiCombobox = ({ control, name, defaultValue }) => {
  const useStyles = makeStyles({
    muiRadio: {
      "& .MuiRadio-root": {
        display: "none",
      },
      "& .MuiTypography-body1": {
        fontSize: "3rem",
      },
    },
  });
  const classes = useStyles();
  // console.log({ defaultValue });
  return (
    <Controller
      rules={{ required: true }}
      control={control}
      name={name}
      render={({ field }) => (
        <RadioGroup
          className={classes.muiRadio}
          style={{ display: "block" }}
          {...field}
        >
          {emojis.map((item) => (
            <Tooltip title={item?.text} arrow>
              <FormControlLabel
                className={"NewEmojiIcon"}
                value={item.emoji}
                control={<Radio />}
                label={item?.emoji}
              />
            </Tooltip>
          ))}
        </RadioGroup>
      )}
    />
  );
};

export default EmojiCombobox;
