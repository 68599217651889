import { useCallback, useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import Layout from "Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "Components/Reusable";
import { useHistory, Redirect } from "react-router-dom";
import {
  getProjectByUserId,
  addUserToTable,
  getUserSubscriptionDetails,
  getAllSubscription,
  getOrganizationProfile,
  getUserAndProjectByAdminId,
} from "redux/actions";
import { nanoid } from "nanoid";
import { uniqBy } from "lodash";
import Users from "./Users";
/**
 *@function ProAddUsers.jsx
 *@author Azim
 *
 **/

const ProAddUsers = ({ offSeat }) => {
  const [error, setError] = useState("");
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { getSinglePayData, users, plans } = useSelector(
    (state) => state.payment
  );
  const { usersUnderAdmin, project } = useSelector((state) => state.projects);
  const history = useHistory();
  const [selfProject, setSelfProject] = useState({});

  useEffect(() => {
    const proj = project?.find((value) => value.projectId === user?.projectId);
    setSelfProject(proj);
  }, [project, user]);
  const styles = {
    heads: {
      backgroundColor: "#DFEBFA",
      padding: "0.5rem 4rem 0.5rem 4rem",
      borderRadius: "15px",
      fontWeight: "bolder",
      fontSize: "15px",
    },
  };
  useEffect(() => {
    if (user?.subscriptionPlanId) {
      dispatch(getUserSubscriptionDetails(user?.id));
      dispatch(getProjectByUserId(user?.id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user?.id) {
      dispatch(getAllSubscription(user?.id));
      dispatch(getOrganizationProfile(user?.projectId));
      dispatch(getUserAndProjectByAdminId(user?.id));
    }
  }, [user, dispatch]);

  // getting existing users
  const getExistingUser = useCallback(() => {
    const newplans = getSinglePayData?.items?.map((item) => {
      const newItem = plans.find((value) => value.priceId === item.priceId);
      return {
        quantity: item?.quantity,
        users: newItem?.noOfUser,
      };
    });
    const numberOfSeats = newplans?.reduce(
      (item, value) => item + value.quantity * value.users,
      0
    );
    const alluser = uniqBy(users, "id");
    const existingUser = numberOfSeats - alluser.length;
    return existingUser;
  }, [getSinglePayData, users]);

  useEffect(() => {
    if (getExistingUser() >= 0) {
      setError("");
    }

    if (getExistingUser() <= 0) {
      setError("No more seats are available");
    }
  }, [getExistingUser]);
  const addNewUserToTheColumn = () => {
    let id = nanoid(15);
    const data = {
      id,
      name: "",
      username: "",
      projectList: [],
    };
    if (getExistingUser() <= 0) {
      return;
    }
    dispatch(addUserToTable([data]));
  };

  if (user.roleType === "USER") {
    return <Redirect to="/" />;
  }

  return (

    <Grid container>
      <Grid item md={12} xl={12}>
        <Users
          addNewUserToTheColumn={addNewUserToTheColumn}
          rows={users.filter((value) => value.id !== selfProject?.userId)}
        />
      </Grid>
    </Grid>
  );
};

export default ProAddUsers;
