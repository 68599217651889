import React, { useCallback, useEffect, useState } from "react";
import SockJsClient from "react-stomp";
import { useSelector } from "react-redux";
import { AppMode } from "../../urlConfig";
import { stringify, parse } from "zipson";
import { checkduplicity } from "Utils";

const WebSocks = (props) => {
  const user = useSelector((state) => state.auth);
  const [visible, setVisible] = useState(true);
  const [refData, setRefData] = useState(null);
  const { id, projectId } = user?.user;
  const {
    elements,
    setPendingRequest,
    pendingRequest,
    initialElements,
    edges,
    workflowId,
  } = props;
  const newSendMessage = useCallback(() => {
    if (pendingRequest) {
      let getAllTypeData = [];
      if (edges !== undefined) {
        getAllTypeData = checkduplicity([...elements, ...edges]);
      } else {
        getAllTypeData = checkduplicity([...elements]);
      }
      const firstCompressed = JSON.stringify(getAllTypeData);
      try {
        refData.sendMessage(
          "/app/organization",
          JSON.stringify({
            id: initialElements.id,
            userId: id,
            projectId,
            workflowId,
            messageContent: firstCompressed,
          })
        );
      } catch (error) {
        // console.log(error);
      }
    }
  }, [pendingRequest, workflowId]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      newSendMessage();
      setPendingRequest(false);
    }, 10);
    return () => clearTimeout(timeOut);
  }, [pendingRequest, setPendingRequest, newSendMessage]);

  const handleMessage = (msg) => {
    const parsedContent = JSON.parse(msg.messageContent);
    const edgesForCanvas = parsedContent.filter(
      (item) => item?.type === "custom" && item
    );
    const nodesForCanvas = parsedContent.filter(
      (item) => !(item?.type === "custom")
    );
    if (props.setNodes && props.setEdges) {
      props?.setNodes(nodesForCanvas);
      props?.setEdges(edgesForCanvas);
    }

    // props.setElements(parsedContent);
    // setPendingRequest(false);
  };

  return (
    <SockJsClient
      url={AppMode.socket + "/push-message-mapping/"}
      topics={[`/topic/content/${initialElements.id}`]}
      onConnect={(msg) => {
        setVisible(false);
      }}
      onDisconnect={(msg) => {
        setVisible(false);
      }}
      onMessage={(msg) => {
        handleMessage(msg);
      }}
      // debug={true}
      ref={(client) => {
        setRefData(client);
      }}
      // onConnectFailure={(error) => // console.log(error)}
      autoReconnect={true}
    />
  );
};

export default WebSocks;
