import axios from "api/axios";
import { nanoid } from "nanoid";
import { MarkerType } from "reactflow";

export const edgeArrowId = (source, target) => {
  return `${source}>${target}>${nanoid(5)}`;
};
export const getValueStreamForCSV = (valueStream, elements, persona) => {
  const newValueStream = valueStream.map((item) => {
    return elements.filter(
      (item1) => item1.data.valueStreamId === item.value && item1
    );
  });
  const newValueStreamFilter = valueStream.map((item, index) => {
    const newObj = {
      valueStreamId: item.value,
      elementsDtoList: newValueStream[index],
      valueStreamName: item.valueStreamName,
      valueStreamWhy: item.valueStreamWhy,
      persona: persona?.personasName,
    };
    return newObj;
  });
  return newValueStreamFilter;
};
export function isImage(icon) {
  const ext = [".jpg", ".jpeg", ".bmp", ".gif", ".png", ".svg"];
  return ext.some((el) => icon.endsWith(el));
}
export function isLocal(dataUrl) {
  return dataUrl && dataUrl.includes("localhost");
}
export const isLocalhost = () => {
  return Boolean(
    window.location.hostname === "localhost" ||
      window.location.hostname === "[::1]" ||
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
};
export const mapedWithId = (data) =>
  data.map((item) => {
    const newRow = { ...item };
    newRow.id = nanoid(10);
    return newRow;
  });

export const newNode = (type, position, personaDataToTheNode) => {
  let newNodes = {
    id: `node_${type}_${nanoid(15)}`,
    type,
    position,
    data: {
      line: false,
      node: true,
      label: `${type} node`,
      decision: type === "decision",
      previousDecision: false,
      start: false,
      process: type === "process",
      file: type === "file",
      previousProcess: false,
      end: false,
      text: "",
      source: [],
      target: [],
      files: [],
      name: "",
      url: "",
      processWhy: "",
      previousProcessName: "",
      previousProcessWhy: "",
      decisionLineName: "",
      valueStreamId: personaDataToTheNode?.id,
      persona: personaDataToTheNode?.persona,
      valueStreamName: personaDataToTheNode?.valueStreamName,
      valueStreamWhy: personaDataToTheNode?.valueStreamWhy,
      color: personaDataToTheNode?.color,
      nodeDataList: [],
    },
  };
  return newNodes;
};

export const addEdged = (
  connection,
  newEdgeId,
  newSource,
  newTarget,
  newDecisionLineEdge,
  personaDataToTheNode
) => {
  let Edge = {
    type: "custom",
    // type: "smoothstep",
    style: { stroke: "black", strokeWidth: "1.5" },
    selectable: true,
    ...connection,
    id: newEdgeId,
    arrowHeadType: "arrow",
    labelBgBorderRadius: 4,
    markerStart: { type: MarkerType.Arrow },
    markerEnd: { type: MarkerType.ArrowClosed, color: "black" },
    labelBgStyle: { fill: "white", stroke: "black" },
    data: {
      line: true,
      node: false,
      source: newSource,
      target: newTarget,
      text: newDecisionLineEdge ? "Line name" : "",
      label: "",
      decision: false,
      file: false,
      previousDecision: false,
      start: false,
      process: false,
      previousProcess: false,
      end: false,
      name: "",
      valueStreamId: personaDataToTheNode?.id,
      previousProcessName: "",
      previousProcessWhy: "",
      decisionLineName: "",
      persona: personaDataToTheNode?.persona,
      valueStreamName: personaDataToTheNode?.valueStreamName,
      valueStreamWhy: personaDataToTheNode?.valueStreamWhy,
      color: personaDataToTheNode?.color,
      processWhy: "",
      nodeDataList: [],
    },
  };
  return Edge;
};

export const getgeneratedText = async (body) => {
  const res = await axios.post(`/v1/acceptance-criteria/`, body);
  if (res.status === 200) {
    return res;
  }
};
