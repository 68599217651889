import axios from "../../api/axios";
import newAxios from "axios";
import { errorAlert, handleErrorMessage, successAlert } from "Utils";
import { journeyMapConstant } from "./constant";
import { AppMode } from "../../urlConfig";
import { auth } from "api/Auth";

// export const getAllFilesFromBackendBasedOnCard = (projectId, cardId) => {
//     return async (dispatch) => {
//         try {
//             if (projectId && cardId) {
//                 const res = await axios.get(`v1/projects/${projectId}/documents/card/${cardId}`)
//                 dispatch({
//                     type: journeyMapConstant.GET_SINGLE_ATTACHMENTS_DATA_BY_CARD,
//                     payload: res.data.data
//                 })
//             }
//         } catch (error) {
//             if (error && error.response && error.response.status === 404) {
//                 errorAlert("File has beed deleted")
//             }
//             if (error && error.response && error.response.status === 401) {
//                 return
//             } else {
//                 errorAlert(handleErrorMessage(error));
//             }
//         }
//     }
// }
export const removeAllAttachmentsFromSingleCard = () => {
  return (dispatch) => {
    dispatch({
      type: journeyMapConstant.REMOVE_SINGLE_ATTACHMENTS_DATA_BY_CARD,
      payload: { empty: true },
    });
  };
};
export const getAllLaneForJourney = (projectId, id) => {
  return async (dispatch) => {
    try {
      if (projectId) {
        const res = await axios.get(
          `/v1/projects/${projectId}/personasId/${id}/journeyLanes`
        );
        dispatch({
          type: journeyMapConstant.GET_ALL_RISK_AND_LANE_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return;
      } else {
        errorAlert(handleErrorMessage(error));
      }
    }
  };
};
// export const getSingleCardForRiskAndIssues = (projectId, id) => {
//     return async (dispatch) => {
//         try {
//             if (projectId && id) {
//                 const res = await axios.get(`/v1/projects/${projectId}/riskCards/${id}`);
//                 dispatch({
//                     type: journeyMapConstant.GET_SINGLE_CARD_SUCCESS,
//                     payload: res.data.data
//                 })
//             }
//         } catch (error) {
//             if (error && error.response && error.response.status === 401) {
//                 return
//             } else {
//                 errorAlert(handleErrorMessage(error));
//             }
//         }
//     }
// }

export const deleteCustomJourneyMap = (projectId, laneId, id) => {
  return async (dispatch) => {
    try {
      if (projectId && laneId) {
        //
        const res = await axios.delete(
          `/v1/projects/${projectId}/personasId/${id}/journeyLanes/${laneId}`
        );
        res.status === 204 && successAlert("Lane deleted successfully");
        dispatch({
          type: journeyMapConstant.DELETE_KANBAN_LANE,
          payload: laneId,
        });
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return;
      } else {
        errorAlert(handleErrorMessage(error));
      }
    }
  };
};
export const addNewLaneForJourney = (projectId, laneData, id) => {
  return async (dispatch) => {
    try {
      if (projectId && laneData) {
        const res = await axios.post(
          `/v1/projects/${projectId}/personasId/${id}/journeyLanes`,
          laneData
        );
        // console.log("res.data", res.data)
        res.data && successAlert("Lane added successfully");
        dispatch({
          type: journeyMapConstant.ADD_KANBAN_LANE,
          payload: res.data.data,
        });
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return;
      } else {
        errorAlert(handleErrorMessage(error));
      }
    }
  };
};
export const updateLaneForJounrey = (
  projectId,
  updateLaneData,
  laneId,
  personasId
) => {
  return async (dispatch) => {
    try {
      if (projectId && updateLaneData) {
        const res = await axios.patch(
          `/v1/projects/${projectId}/personasId/${personasId}/journeyLanes/${laneId}`,
          updateLaneData
        );
        res.data && successAlert("Lane updated successfully");
        dispatch(getAllLaneForJourney(projectId, personasId));
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return;
      } else {
        errorAlert(handleErrorMessage(error));
      }
    }
  };
};
export const updateLanePositionForJourneyLane = (
  projectId,
  personasId,
  laneData
) => {
  return async (dispatch) => {
    try {
      if (projectId && laneData) {
        // console.log({laneData})
        const res = await axios.post(
          `/v1/projects/${projectId}/personasId/${personasId}/journeyLanes/saveLanes`,
          laneData
        );
        res.data && successAlert("Lane dragged successfully");
        dispatch({
          type: journeyMapConstant.GET_ALL_RISK_AND_LANE_SUCCESS,
          payload: res.data.data,
        });
        // dispatch(getAllLaneForJourney(projectId, personasId))
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return;
      } else {
        errorAlert(handleErrorMessage(error));
      }
    }
  };
};
export const deleteCardFromJourney = (projectId, cardId, personasId) => {
  return async (dispatch) => {
    try {
      if (projectId && cardId) {
        const res = await axios.delete(
          `/v1/projects/${projectId}/journeyCards/${cardId}`
        );
        res.status === 204 && successAlert("Card deleted successfully");
        if (res.status === 204) {
          await dispatch(getAllLaneForJourney(projectId, personasId));
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return;
      } else {
        errorAlert(handleErrorMessage(error));
      }
    }
  };
};
export const journeyCardUpdate = (projectId, cardId, cardData, personsId) => {
  return async (dispatch) => {
    try {
      if (projectId && cardId) {
        const token = auth.getToken();
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token ? `Bearer ${token}` : "",
          },
        };
        const res = await newAxios.post(
          `${AppMode.backend}/v1/projects/${projectId}/journeyCards/${cardId}`,
          cardData,
          config
        );
        res.data && successAlert("Card updated successfully");
        dispatch({
          type: journeyMapConstant.UPDATE_KANBAN_CARD,
          payload: res.data,
        });
        await dispatch(getAllLaneForJourney(projectId, personsId));
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return;
      } else {
        errorAlert(handleErrorMessage(error));
      }
    }
  };
};

export const addNewCardToJourney = (projectId, formDataOfKard, personasId) => {
  return async (dispatch) => {
    try {
      if (projectId) {
        const token = auth.getToken();
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token ? `Bearer ${token}` : "",
          },
        };
        const res = await newAxios.post(
          `${AppMode.backend}/v1/projects/${projectId}/journeyCards`,
          formDataOfKard,
          config
        );
        res.data && successAlert("Card added successfully");
        await dispatch(getAllLaneForJourney(projectId, personasId));
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return;
      } else {
        errorAlert(handleErrorMessage(error));
      }
    }
  };
};

export const downloadjourneymap = (projectId, personaId) => {
  return async (dispatch) => {
    try {
      if ((projectId, personaId)) {
        const response = await axios.get(
          `v1/projects/{projectId}/personasId/{personasId}/journeyLanes/download-journeymap-persona/${personaId}`,
          {
            responseType: "blob",
          }
        );
        if (response && response.status) {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", `journeymap.xls`);
          document.body.appendChild(link);
          link.click();
          link.remove();

          dispatch({
            type: journeyMapConstant.JOURNEYMAP_DOWNLOAD,
          });
          successAlert("Journey Map Downloaded Successfully");
        }
      }
    } catch (error) {
      if (error) {
        dispatch({
          type: journeyMapConstant.JOURNEYMAP_DOWNLOAD_FAILED,
        });
      }
    }
  };
};

export const getAllLaneDetailsAboutJourney = (id) => {
  return async (dispatch) => {
    try {
      if (id) {
        const res = await axios.get(
          `/v1/projects/{projectId}/personasId/{personasId}/journeyLanes/getPersonasDetails/${id}`
        );
        dispatch({
          type: journeyMapConstant.GET_ALL_JOURNEY_DETAILS,
          payload: res.data,
        });
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return;
      } else {
        errorAlert(handleErrorMessage(error));
      }
    }
  };
};
