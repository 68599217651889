import {
  FormsGrouped as AddValueStreamFields,
  Modal,
} from "Components/Reusable";
import { Button } from "@material-ui/core";
import { mapedFormForPersona } from "assets/Data/FormsData";
/**
 *@function StreamCard.jsx
 *@author Azim
 *
 **/
export const AddValueStreamModal = ({
  openValueStremModal,
  setOpenValueStremModal,
  reset,
  handleSubmit,
  onSubmit,
  errors,
  realControl,
  persona,
}) => {
  return (
    <Modal
      open={openValueStremModal}
      handleClose={() => {
        setOpenValueStremModal(false);
        reset();
      }}
    >
      <h1
        style={{
          textAlign: "left",
          marginLeft: "32px",
          marginBottom: "-27px",
        }}
      >
        Add new value stream
      </h1>
      <form
        style={{ padding: "20px" }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <AddValueStreamFields
          mapedForm={mapedFormForPersona(persona)}
          control={realControl}
          errors={errors}
        />
        <br />
        <Button
          style={{ margin: "10px" }}
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
        >
          Add Stream
        </Button>
      </form>
    </Modal>
  );
};
