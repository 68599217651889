import CircleBox from "Components/FlowComponents/CircleBox";
import { Avatar, Box, Button, Grid, Tooltip } from "@material-ui/core";
import { AddCircle, PersonOutline } from "@material-ui/icons";
import StreamCard from "Components/Reusable/Card/StreamCard";
import ReactECharts from "echarts-for-react";
import { getWorkFlowCharts } from "Utils/chartconfig";
/**
 *@function WorkFlow.jsx
 *@author Azim
 *
 **/
export const WorkFlows = ({
  classes,
  valueStream,
  history,
  workflow,
  getAvatar,
  titleText,
  workFlowStream,
  setOpenModal,
  workflowitem,
  setOpenTitle,
  setRecentFlowPersonas,
  journeyDetails,
  recentFlowPersonas,
  setOpenModalPersona,
}) => {
  const handlePushState = () => {
    recentFlowPersonas
      ? history.push(`/workflow/${recentFlowPersonas}`)
      : history.push("/personas");
  };
  const handleChangeFLow = (item) => {
    setRecentFlowPersonas(item.id);
  };

  return (
    <StreamCard
      titleVariant="h5"
      setOpenModal={setOpenModal}
      setOpenTitle={setOpenTitle}
      titleText={titleText}
      workFlowStream={workFlowStream}
      workflowitem={workflowitem}
      spaceflow={workflow}
      journeymap={"% build"}
      subtitle="Discovery"
    >
      {journeyDetails && journeyDetails.length > 0 ? (
        <Box onClick={() => handlePushState()}>
          <ReactECharts
            style={{ height: "150px" }}
            option={getWorkFlowCharts(journeyDetails)}
          />
        </Box>
      ) : (
        <Box onClick={() => handlePushState()} style={{ height: "150px" }}>
          <span style={{ color: "orange", position: "relative", top: "5rem" }}>
            {recentFlowPersonas
              ? "click to add workflow"
              : "No Personas? Create one here"}
          </span>
        </Box>
      )}
      <Box
        style={{ maxWidth: "1400px", marginTop: "1rem" }}
        className={classes.streamCardBoxforjourney}
      >
        <Grid className={classes.scrollUpStyle} container>
          {valueStream.map((item, index) => (
            <div
              key={index}
              style={{ backgroundColor: "#ffffff00", cursor: "move" }}
            >
              <Tooltip title={item?.name} placement="bottom" arrow>
                <Box
                  style={{
                    background: "#E5E5E5",
                    cursor: "pointer",
                    marginRight: "20px",
                    border:
                      recentFlowPersonas === item.id && "2px solid #7478E8",
                  }}
                  className={classes.AddboxCircleForjourney}
                >
                  {item?.avatar ? (
                    <div>
                      <Avatar
                        style={{
                          border:
                            recentFlowPersonas === item.id &&
                            "2px solid #7478E8",
                        }}
                        className={classes.firstpaperjourney}
                        src={getAvatar(item?.avatar)}
                        onClick={() => handleChangeFLow(item)}
                      ></Avatar>
                    </div>
                  ) : (
                    <p
                      style={{
                        position: "relative",
                        lineHeight: 1,
                        color: "black",
                        top: "0",
                        fontSize: ".5em",
                        textAlign: "center",
                        margin: "0px",
                      }}
                      onClick={() => handleChangeFLow(item)}
                    >
                      <PersonOutline
                        style={{
                          height: "25px",
                          fontSize: "15.5em",
                          color: "#BDBDBD",
                          position: "relative",
                        }}
                      />
                      <span
                        style={{
                          position: "relative",
                          top: "-10px",
                          fontSize: "1.7em",
                        }}
                      >
                        {item?.name.length > 7
                          ? item?.name.slice(0, 7) + "..."
                          : item?.name}
                      </span>
                    </p>
                  )}
                </Box>
              </Tooltip>
            </div>
          ))}
          {/* create persona */}
          <Box
            style={{ background: "transparent" }}
            onClick={() =>setOpenModalPersona(true)}
            className={classes.AddboxCircleForProblemStatement}
          >
            <Button
              className={classes.buttonStyle2}
              disableRipple
              endIcon={
                <AddCircle className={classes.buttonInnerStyle2problem} />
              }
            />
          </Box>
        </Grid>
      </Box>
    </StreamCard>
  );
};
