import { CustomSnacbar } from "Components/Reusable";
import { Box, Paper, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";

const Projects = ({
  user,
  selfproject,
  history,
  project,
  setFilterProject,
  filterproject,
  projectData,
  ProjectItem,
  deleteSingleProject,
  handledeleteSingleProject,
  setdeleteSingleProject,
}) => {
  return (
    <div>
      {user.roleType === "ADMIN" && selfproject?.role !== "USER" ? (
        <Paper>
          <Box p="30px">
            <Box justifyContent="space-between" display="flex">
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Projects
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontWeight: "bold",
                  color: "#7478E8",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/project")}
              >
                Add
              </Typography>
            </Box>
            <Box
              style={{
                height: "400px",
              }}
            >
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={project?.project}
                onChange={(event, newval) => setFilterProject(newval.projectId)}
                onInputChange={(event) => setFilterProject("")}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search input"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: "search" }}
                  />
                )}
              />
              <Box
                style={{
                  height: "325px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                {filterproject
                  ? projectData?.project
                      ?.filter(
                        (value) =>
                          value.projectId === filterproject &&
                          value.role !== "USER"
                      )
                      .map((item, index) => {
                        return ProjectItem(item, index);
                      })
                  : projectData?.project
                      ?.filter((value) => value.role !== "USER")
                      .map((item, index) => {
                        return ProjectItem(item, index);
                      })}
                {deleteSingleProject.open ? (
                  <CustomSnacbar
                    opened={deleteSingleProject.open}
                    alertToDelete={deleteSingleProject}
                    handleDeleteFromBackend={handledeleteSingleProject}
                    setOpenAlert={setdeleteSingleProject}
                    delid={deleteSingleProject.id}
                    type="adminUser"
                    generalText={
                      "Are you sure you want to delete this project?"
                    }
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
        </Paper>
      ) : null}
    </div>
  );
};

export default Projects;
