// for male genders
import male1 from './Male - Persona 1.svg';
import male2 from './Male - Persona 2.svg';
import male3 from './Male - Persona 3.svg';
import male4 from './Male - Persona 4.svg';
import male5 from './Male - Persona 5.svg';
import male6 from './Male - Persona 6.svg';
import male7 from './Male - Persona 7.svg';
import male8 from './Male - Persona 8.svg';
import male9 from './Male - Persona 9.svg';
import male10 from './Male - Persona 10.svg';
import male11 from './Male - Persona 11.svg';
import male12 from './Male - Persona 12.svg';
import male13 from './Male - Persona 13.svg';
import male14 from './Male - Persona 14.svg';
import male15 from './Male - Persona 15.svg';
import male16 from './Male - Persona 16.svg';
import male17 from './Male - Persona 17.svg';
import male18 from './Male - Persona 18.svg';
import male19 from './Male - Persona 19.svg';
import male20 from './Male - Persona 20.svg';
import male21 from './Male - Persona 21.svg';
import male22 from './Male - Persona 22.svg';
import male23 from './Male - Persona 23.svg';
import male24 from './Male - Persona 24.svg';
import male25 from './Male - Persona 25.svg';
import male26 from './Male - Persona 26.svg';
import male27 from './Male - Persona 27.svg';
import male28 from './Male - Persona 28.svg';
import male29 from './Male - Persona 29.svg';
import male30 from './Male - Persona 30.svg';
import male31 from './Male - Persona 31.svg';
import male32 from './Male - Persona 32.svg';
import male33 from './Male - Persona 33.svg';
import male34 from './Male - Persona 34.svg';
import male35 from './Male - Persona 35.svg';
import male36 from './Male - Persona 36.svg';
import male37 from './Male - Persona 37.svg';
import male38 from './Male - Persona 38.svg';
import male39 from './Male - Persona 39.svg';
import male40 from './Male - Persona 40.svg';
import male41 from './Male - Persona 41.svg';
import male42 from './Male - Persona 42.svg';
import male43 from './Male - Persona 43.svg';
import male44 from './Male - Persona 44.svg';
import male45 from './Male - Persona 45.svg';
import male46 from './Male - Persona 46.svg';
import male47 from './Male - Persona 47.svg';
import male48 from './Male - Persona 48.svg';
import male49 from './Male - Persona 49.svg';
import male50 from './Male - Persona 50.svg';
import male51 from './Male - Persona 51.svg';
import male52 from './Male - Persona 52.svg';
import male53 from './Male - Persona 53.svg';
import male54 from './Male - Persona 54.svg';
import male55 from './Male - Persona 55.svg';
import male56 from './Male - Persona 56.svg';
import male57 from './Male - Persona 57.svg';
import male58 from './Male - Persona 58.svg';
// for neural genders
import nutral1 from './Neutral - Persona 1.svg';
import nutral2 from './Neutral - Persona 2.svg';
// import nutral3 from './Neutral - Persona 3.svg';
import nutral4 from './Neutral - Persona 4.svg';
import nutral5 from './Neutral - Persona 5.svg';
import nutral6 from './Neutral - Persona 6.svg';
import nutral8 from './Neutral - Persona 7.svg';
import nutral7 from './Neutral - Persona 8.svg';
// import nutral9 from './Neutral - Persona 9.svg';
import nutral10 from './Neutral - Persona 10.svg';
import nutral11 from './Neutral - Persona 11.svg';
import nutral12 from './Neutral - Persona 12.svg';
import nutral13 from './Neutral - Persona 13.svg';
import nutral14 from './Neutral - Persona 14.svg';
import nutral15 from './Neutral - Persona 15.svg';
import nutral16 from './Neutral - Persona 16.svg';
import nutral17 from './Neutral - Persona 17.svg';
import nutral18 from './Neutral - Persona 18.svg';
import nutral19 from './Neutral - Persona 19.svg';
import nutral20 from './Neutral - Persona 20.svg';
// for female genders
import female1 from './Female - Persona 1.svg';
import female2 from './Female - Persona 2.svg';
import female3 from './Female - Persona 3.svg';
import female4 from './Female - Persona 4.svg';
import female5 from './Female - Persona 5.svg';
import female6 from './Female - Persona 6.svg';
import female7 from './Female - Persona 7.svg';
import female8 from './Female - Persona 8.svg';
import female9 from './Female - Persona 9.svg';
import female10 from './Female - Persona 10.svg';
import female11 from './Female - Persona 11.svg';
import female12 from './Female - Persona 12.svg';
import female13 from './Female - Persona 13.svg';
import female14 from './Female - Persona 14.svg';
import female15 from './Female - Persona 15.svg';
import female16 from './Female - Persona 16.svg';
import female17 from './Female - Persona 17.svg';
import female18 from './Female - Persona 18.svg';
import female19 from './Female - Persona 19.svg';
import female20 from './Female - Persona 20.svg';
import female21 from './Female - Persona 21.svg';
import female22 from './Female - Persona 22.svg';
import female23 from './Female - Persona 23.svg';
import female24 from './Female - Persona 24.svg';
import female25 from './Female - Persona 25.svg';
import female26 from './Female - Persona 26.svg';
import female27 from './Female - Persona 27.svg';
import female28 from './Female - Persona 28.svg';
import female29 from './Female - Persona 29.svg';
import female30 from './Female - Persona 30.svg';
import female31 from './Female - Persona 31.svg';
import female32 from './Female - Persona 32.svg';
import female33 from './Female - Persona 33.svg';
import female34 from './Female - Persona 34.svg';
import female35 from './Female - Persona 35.svg';
import female36 from './Female - Persona 36.svg';
import female37 from './Female - Persona 37.svg';
import female38 from './Female - Persona 38.svg';
import female39 from './Female - Persona 39.svg';
import female40 from './Female - Persona 40.svg';
import female41 from './Female - Persona 41.svg';
import female42 from './Female - Persona 42.svg';
import female43 from './Female - Persona 43.svg';
import female44 from './Female - Persona 44.svg';
import female45 from './Female - Persona 45.svg';
import female46 from './Female - Persona 46.svg';
import female47 from './Female - Persona 47.svg';
import female48 from './Female - Persona 48.svg';
import female49 from './Female - Persona 49.svg';
import female50 from './Female - Persona 50.svg';
import female51 from './Female - Persona 51.svg';
import female52 from './Female - Persona 52.svg';
import female53 from './Female - Persona 53.svg';
import female54 from './Female - Persona 54.svg';
import female55 from './Female - Persona 55.svg';
import female56 from './Female - Persona 56.svg';
import female57 from './Female - Persona 57.svg';
import female58 from './Female - Persona 58.svg';
import female59 from './Female - Persona 59.svg';
import female60 from './Female - Persona 60.svg';
import female61 from './Female - Persona 61.svg';

const Avatars = {
    // for male
    male1,
    male2,
    male3,
    male4,
    male5,
    male6,
    male7,
    male8,
    male9,
    male10,
    male11,
    male12,
    male13,
    male14,
    male15,
    male16,
    male17,
    male18,
    male19,
    male20,
    male21,
    male22,
    male23,
    male24,
    male25,
    male26,
    male27,
    male28,
    male29,
    male30,
    male31,
    male32,
    male33,
    male34,
    male35,
    male36,
    male37,
    male38,
    male39,
    male40,
    male41,
    male42,
    male43,
    male44,
    male45,
    male46,
    male47,
    male48,
    male49,
    male50,
    male51,
    male52,
    male53,
    male54,
    male55,
    male57,
    male56,
    male58,
    // for female
    female1,
    female2,
    female3,
    female4,
    female5,
    female6,
    female7,
    female8,
    female9,
    female10,
    female11,
    female12,
    female13,
    female14,
    female15,
    female16,
    female17,
    female18,
    female19,
    female20,
    female21,
    female22,
    female23,
    female24,
    female25,
    female26,
    female27,
    female28,
    female29,
    female30,
    female31,
    female32,
    female33,
    female34,
    female35,
    female36,
    female37,
    female38,
    female39,
    female40,
    female41,
    female42,
    female43,
    female44,
    female45,
    female46,
    female47,
    female48,
    female49,
    female50,
    female51,
    female52,
    female53,
    female54,
    female55,
    female56,
    female57,
    female58,
    female59,
    female60,
    female61,
    // for other
    nutral1,
    nutral2,
    // nutral3,
    nutral4,
    nutral5,
    nutral6,
    nutral7,
    nutral8,
    // nutral9,
    nutral10,
    nutral11,
    nutral12,
    nutral13,
    nutral14,
    nutral15,
    nutral16,
    nutral17,
    nutral18,
    nutral19,
    nutral20,
}
export default Avatars;