import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";

/**
 *@function CircleBox.jsx
 *@author Azim
 *
 **/

const CircleBox = (props) => {
  const classes = useStyles(props);
  const { item, layout, boardItem } = props;
  return (
    <Tooltip
      title={
        item?.valueStreamName || boardItem?.name
          ? item?.valueStreamName || boardItem?.name
          : ""
      }
      placement="bottom"
      arrow
    >
      <Box className={layout ? classes.layBoxCircle : classes.boxCircle}>
        <Typography
          className={layout ? classes.layBoxWording : classes.boxWording}
        >
          {item?.valueStreamName}
          {boardItem?.name}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default CircleBox;

const useStyles = makeStyles(() => {
  return {
    boxCircle: {
      borderRadius: "50%",
      background: (props) => (props.background ? props?.background : "#93d275"),
      height: "140px",
      width: "140px",
      display: "flex",
      padding: "1.5rem",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    layBoxCircle: {
      borderRadius: "50%",
      background: (props) => (props.background ? props?.background : "#93d275"),
      height: "50px",
      width: "50px",
      display: "flex",
      padding: ".5rem",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },

    boxWording: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      color: "#00000",
      wordBreak: "break-all",
      textAlign: "center",
      fontSize: "12px",
      fontWeight: "600",
    },
    layBoxWording: {
      color: "black",
      wordBreak: "break-all",
      textAlign: "center",
      fontSize: "12px",
      fontWeight: "600",
      whiteSpace: "nowrap",
      width: "58px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };
});
