import React, { useState } from "react";
import {
  Clear as ClearIcon,
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  Settings,
} from "@material-ui/icons";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  addNewLaneForJourney,
  deleteCustomLaneForKanban,
  deleteCustomJourneyMap,
  updateLaneForJounrey,
} from "redux/actions";
import {
  CustomSnacbar,
  Modal,
  MPopover,
  RIconButton,
} from "Components/Reusable";
import store from "redux/store";
import { HookFormTextField } from "Components/Reusable";
import { useEffect } from "react";
import { useRef } from "react";

function LaneForJourney({ props, riskKanbanData, kanbanId, journeyMap }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [beforeDeleteAlert, setBeforeDeleteAlert] = useState(false);
  const [openToUpdate, setOpenToUpdate] = useState(false);
  const [defValues, setDefValues] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverRef = useRef();

  //Hook form state
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    mode: "all",
    defaultValues: {
      label: "",
      imRequired: [],
    },
  });
  const requiredOptions = [
    {
      label: "Is this part of technical solution?",
      value: "checked",
    },
  ];
  useEffect(() => {
    setDefValues({
      title: props.title,
      imRequired: props.imRequired === true ? [...requiredOptions] : [],
    });
  }, [props.imRequired, props.title]);
  const {
    formState: { errors: errorsUpdate },
    handleSubmit: handleUpdateSubmit,
    reset: resetUpdate,
    control: controlUpdate,
  } = useForm({
    mode: "all",
    defaultValues: defValues,
  });
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSubmit = (data) => {
    const newLaneData = { ...data };
    // console.log({ data });
    newLaneData.title = data.label;
    newLaneData.projectId = props.projectId;
    newLaneData.personasId = props.personasId;
    newLaneData.defaultKanban = props.defaultKanban;
    newLaneData.imRequired = data.imRequired.length ? true : false;
    newLaneData.notDeletable = false;
    newLaneData.serial = riskKanbanData.length + 1;
    journeyMap &&
      store.dispatch(
        addNewLaneForJourney(props.projectId, newLaneData, props.personasId)
      );
    setModalOpen(false);
    handleClose();
    reset();
  };
  const onUpdateSubmit = (data) => {
    const updateLaneData = { ...data };
    updateLaneData.title = data.label;
    updateLaneData.projectId = props.projectId;
    updateLaneData.cards = props.cards;
    updateLaneData.personasId = props.personasId;
    updateLaneData.imRequired = data.imRequired.length
      ? true
      : data.imRequired.length === 0
      ? false
      : props.imRequired;
    updateLaneData.serial = props.serial;
    updateLaneData.notDeletable = props.notDeletable;
    if (!journeyMap) {
      updateLaneData.kanbanId = props.kanbanId;
    }
    updateLaneData.defaultKanban = props.defaultKanban;
    updateLaneData.id = props.id;

    journeyMap &&
      store.dispatch(
        updateLaneForJounrey(
          props.projectId,
          updateLaneData,
          props.id,
          props?.personasId
        )
      );
    setOpenToUpdate(false);
    handleClose();
    resetUpdate();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDeleteClick = () => {
    setOpenAlert(true);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles();
  const handleDeleteFromBackend = (id) => {
    !journeyMap &&
      store.dispatch(
        deleteCustomLaneForKanban(props?.projectId, props?.id, kanbanId)
      );
    journeyMap &&
      store.dispatch(
        deleteCustomJourneyMap(props?.projectId, props?.id, props?.personasId)
      );
    setOpenAlert(false);
  };

  const renderModalForAddingNewColumn = () => {
    return (
      <Modal
        open={modalOpen}
        handleClose={() => {
          setModalOpen(false);
          handleClose();
          reset();
        }}
      >
        <div style={{ padding: "30px" }}>
          <Typography
            style={{ marginBottom: "20px" }}
            variant="h4"
            component="h1"
            align="left"
          >
            Add column
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} action="">
            <Box
              minWidth="505px"
              justifyContent="center"
              flexDirection="column"
              display="flex"
              alignItems="center"
            >
              <Box mb="12px" mr="230px">
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    <HookFormTextField
                      name="imRequired"
                      checkBox={true}
                      checkBoxData={requiredOptions}
                      control={control}
                      errors={errors}
                      className={classes.textStyle}
                      rules={{
                        required: {
                          value: false,
                          message: "",
                        },
                      }}
                    />
                  </FormGroup>
                </FormControl>
              </Box>

              <HookFormTextField
                name="label"
                control={control}
                errors={errors}
                rules={{

                  required: {
                    value: true,
                    message: "This is required",
                  },
                }}
                label="Column's label *"
                className={classes.textStyle}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: "7px" }}
              >
                Add
              </Button>
            </Box>
          </form>
        </div>
      </Modal>
    );
  };

  const renderModalForUpdatingColumn = () => {
    return (
      <Modal
        open={openToUpdate}
        handleClose={() => {
          setOpenToUpdate(false);
          resetUpdate();
        }}
      >
        <Box p="30px">
          <Box mb="22px">
            <Typography variant="h4" component="h1" align="left">
              Update column
            </Typography>
          </Box>
          <form onSubmit={handleUpdateSubmit(onUpdateSubmit)} action="">
            <Box
              minWidth="505px"
              justifyContent="center"
              flexDirection="column"
              display="flex"
              alignItems="center"
            >
              <Box mb="12px" mr="230px">
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    <HookFormTextField
                      name="imRequired"
                      checkBox={true}
                      defaultValue={props.imRequired ? ["checked"] : []}
                      checkBoxData={requiredOptions}
                      control={controlUpdate}
                      errors={errors}
                      className={classes.textStyle}
                      rules={{
                        required: {
                          value: false,
                          message: "",
                        },
                      }}
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <HookFormTextField
                defaultValue={props.title}
                name="label"
                errors={errorsUpdate}
                control={controlUpdate}
                rules={{

                  required: {
                    value: true,
                    message: "This is required",
                  },
                }}
                label="Column's label *"
                className={classes.textStyle}
              />

              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: "7px" }}
              >
                Update
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    );
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box>
          <Box display={`flex`} alignItems="center">
            <Tooltip title={"Click to Update"} placement="top" arrow>
              <div
                style={{ color: props?.notDeletable && "white" }}
                className={classes.newStyle}
                onClick={() => {
                  !props.defaultKanban && setOpenToUpdate(true);
                }}
              >
                {props && props.title}
              </div>
            </Tooltip>
            {props && props.imRequired && (
              <RIconButton
                title={"This is part of technical solution"}
                type="button"
                size="small"
                style={{ marginLeft: "4px" }}
                color="primary"
              >
                <Settings />
              </RIconButton>
            )}
          </Box>
          <MPopover
            open={open}
            ref={popoverRef}
            anchorEl={anchorEl}
            onClose={handleClose}
            id={id}
          >
            <Typography className={classes.typography}>
              <Button
                onClick={() => setModalOpen(true)}
                startIcon={<AddIcon />}
              >
                Add column
              </Button>
            </Typography>
          </MPopover>
        </Box>
        <div>
          <RIconButton
            style={{ color: props?.notDeletable && "white" }}
            title={"click to add new column"}
            placement="top"
            onClick={handleClick}
            type="button"
            id={id}
          >
            <MoreVertIcon />
          </RIconButton>

          {props.defaultKanban || props?.notDeletable ? (
            ""
          ) : (
            <RIconButton
              title={"click to remove lane"}
              placement="top"
              onClick={() => handleDeleteClick(props)}
            >
              <ClearIcon />
            </RIconButton>
          )}
        </div>
      </Box>
      {modalOpen && renderModalForAddingNewColumn()}
      {openToUpdate && renderModalForUpdatingColumn()}
      {openAlert && (
        <CustomSnacbar
          opened={openAlert}
          handleDeleteFromBackend={handleDeleteFromBackend}
          setOpenAlert={setOpenAlert}
          type="Lane"
        />
      )}
      {beforeDeleteAlert && (
        <CustomSnacbar
          opened={beforeDeleteAlert}
          setOpenAlert={setBeforeDeleteAlert}
          DeleteText="You have to delete or drag all cards from this lane"
        />
      )}
    </div>
  );
}
export default React.memo(LaneForJourney);

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  newStyle: {
    float: "left",
    width: "auto",
    height: "auto",
    margin: "5px 0px 2px 0",
    fontSize: "20px",
    fontWeight: "700",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#1b1d21",
  },
  textStyle: {
    marginBottom: "15px",
  },
}));
