import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Switch,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
  TextField,
  ListItemIcon,
  Button,
} from "@material-ui/core";
import Layout from "Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { useToggle } from "hooks/useToggle";
import {
  Modal,
  Loader,
  ReUseDialog,
  RIconButton,
  CustomSnacbar,
} from "Components/Reusable";
import Avatars from "assets/Avatars";
import {
  avatarChange,
  getProjectByUserId,
  getProfileByUserId,
  enableLoghour,
  fetchEnableLoghour,
  getProjectById,
  deleteProject,
  sendValueStreamAndDiagramForCSV,
  nullifiedCSV,
  getPersona,
  getOrganizationProfile,
  getNewValueStream,
  loadAllDiagramFromBackend,
  downloadTimeSheet,
  downloadjourneymap,
  downloadJasperReports,
} from "redux/actions";
import { errorAlert, WordCount, WordCountLength } from "Utils";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { Delete } from "@material-ui/icons";
import { nanoid } from "nanoid";
import { ImageOrAvatarSelector } from "Components/BoardComponents";
import { parse } from "zipson";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useProfileStyles } from "assets/Styles/Profile";
import TabProfile from "Components/Profile/TabProfile";

/**
 *@function Profile.jsx
 *@author Shahed
 *
 **/

const Profile = () => {
  const { user } = useSelector((state) => state.auth);
  const projectData = useSelector((state) => state.projects);
  const enableLog = useSelector((state) => state.logenable.enable);
  const { profileItem, loading } = useSelector((state) => state.orgprofile);
  const { allinitials, valueStream, persona } = useSelector(
    (state) => state.diagram
  );
  const dispatch = useDispatch();
  const classes = useProfileStyles();
  const [isTextChanged, setIsTextChanged] = useToggle();
  const [openNameChange, setOpenNameChange] = useToggle();
  const [openChangePassword, setOpenChangePassword] = useToggle();
  const [selfproject, setSelfProject] = useState();
  const history = useHistory();
  const [filterproject, setFilterProject] = useState();
  const [deleteSingleProject, setdeleteSingleProject] = useState({});
  // const [ageLabels, setAgeLabels] = useState([
  //   { id: nanoid(10), color: "black", name: "", time: "" },
  // ]);

  // for downloading timesheet
  const [downloadModal, setDownloadModal] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`)
  );
  const [endDate, setEndDate] = useState(new Date());

  const handleChangeAvatar = (avatar) => {
    dispatch(avatarChange(user?.projectId, profileItem?.id, user?.id, avatar));
  };
  useEffect(() => {
    dispatch(getProfileByUserId(user?.id));
  }, [dispatch, user]);
  useEffect(() => {
    dispatch(getProjectById(user?.projectId));
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(getPersona(user?.projectId));
  }, [dispatch, user?.projectId]);

  useEffect(() => {
    if (user?.projectId) {
      dispatch(getOrganizationProfile(user?.projectId));
      dispatch(getNewValueStream(user?.projectId));
      dispatch(loadAllDiagramFromBackend(user?.projectId));
    }
  }, [user?.projectId, dispatch]);

  // for getting self project id

  const getAvatar = () => {
    let newData;
    Object.keys(Avatars).find((key) => {
      if (key === profileItem?.avatar) {
        newData = `${Avatars[profileItem.avatar]}`;
        return newData;
      }
      return newData;
    });
    return newData;
  };
  const handleAvatarChange = () => {
    return (
      <Modal
        settings
        open={isTextChanged}
        handleClose={() => setIsTextChanged(false)}
        maxWidth={"50%"}
      >
        <ImageOrAvatarSelector
          handleChangeBackground={handleChangeAvatar}
          wording={"Select your Avatar"}
          setOpenSetting={setIsTextChanged}
          avatar
        />
      </Modal>
    );
  };
  const handleTextChange = () => {
    return (
      <ReUseDialog
        open={openNameChange}
        handleClose={() => setOpenNameChange(false)}
        title="Edit Name"
        form
        name
        fieldName="name"
        profileId={profileItem?.id}
        defaultValue={profileItem?.name}
        label="Name"
      />
    );
  };
  const handlePasswordChange = () => {
    return (
      <ReUseDialog
        open={openChangePassword}
        handleClose={() => setOpenChangePassword(false)}
        title="Change your password"
        form
        password
        fieldName="oldPassword"
        profileId={profileItem?.id}
        label="Old password"
        type="password"
      />
    );
  };

  useEffect(() => {
    if (user?.id) {
      dispatch(getProjectByUserId(user?.id));
    }
  }, [user?.id, dispatch]);

  const ProjectItem = (item, index) => {
    return (
      <Box
        key={index}
        display={"flex"}
        justifyContent={"space-between"}
        style={{ cursor: "pointer" }}
      >
        <Box
          className={classes.projectdiv}
          display={"flex"}
          onClick={() => history.push(`projectuser/${item.projectId}`)}
        >
          <ListItemIcon>
            <div className={classes.projectIcon}>
              <span className={classes.innderword}>
                {selfproject && item.name && item.name !== "undefined"
                  ? item?.name.slice(0, 1).toUpperCase()
                  : null}
              </span>
            </div>
          </ListItemIcon>
          <div style={{ marginTop: ".5rem", textTransform: "capitalize" }}>
            {item?.name}
          </div>
        </Box>
        <Box className={classes.projectdiv} display={"flex"}>
          <Typography variant="body2" style={{ color: "gray" }}>
            Total User: {item?.userList.length}
          </Typography>
          {item.projectId === projectData?.singleProject?.id ? (
            <div>
              <Delete
                style={{
                  color: "gray",
                  marginTop: "-0.1rem",
                  marginLeft: "1rem",
                }}
                onClick={() => errorAlert("You are in the same project ")}
              ></Delete>
            </div>
          ) : (
            <div>
              <Delete
                style={{
                  color: "gray",
                  marginTop: "-0.1rem",
                  marginLeft: "1rem",
                }}
                onClick={() =>
                  setdeleteSingleProject({ id: item.projectId, open: true })
                }
              ></Delete>
            </div>
          )}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    const project = projectData?.project.find(
      (value) => value.projectId === user?.projectId
    );
    setSelfProject(project);
  }, [projectData, user]);

  // fetching data
  useEffect(() => {
    dispatch(fetchEnableLoghour(user?.projectId));
  }, [projectData, dispatch]);

  const handledeleteSingleProject = (id) => {
    dispatch(deleteProject(id));
    setdeleteSingleProject(false);
    setTimeout(() => {
      if (user?.id) {
        dispatch(getProjectByUserId(user?.id));
      }
    }, 100);
  };
  const generateCSV = () => {
    let parsedCanvasData = [];

    allinitials.map((item) => {
      const alldata = JSON.parse(item?.messageContent);
      alldata &&
        alldata.length &&
        alldata.map((item2) => {
          parsedCanvasData.push(item2);
        });
    });
    if (parsedCanvasData?.length > 0) {
      const newValueStream = valueStream.map((item) => {
        return parsedCanvasData.filter(
          (item1) => item1.data.valueStreamId === item.id && item1
        );
      });
      const newValueStreamFilter = valueStream.map((item, index) => {
        const newObj = {
          valueStreamId: item.id,
          elementsDtoList: newValueStream[index],
          valueStreamName: item.valueStreamName,
          valueStreamWhy: item.valueStreamWhy,
          persona: persona?.name,
        };
        return newObj;
      });
      dispatch(
        sendValueStreamAndDiagramForCSV(
          user?.projectId,
          newValueStreamFilter,
          user?.name + nanoid(2)
        )
      );
      dispatch(nullifiedCSV());
    }
  };



  return (
    <Layout pageName={"Profile"}>
      <Grid
        container
        style={{
          padding: "0px 10px 10px 10px",
          marginLeft: "7px",
          marginTop: "22px",
        }}
      >
        <Grid item xs={12} md={12}>
          <Typography
            align="center"
            style={{ margin: "14px 0px" }}
            variant="h4"
          >
            SquareBear Admin
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} style={{ marginRight: "1rem" }}>
          {loading ? (
            <Loader />
          ) : (
            <Paper>
              <Box p="30px">
                <Box justifyContent="space-start" gridGap={100} display="flex">
                  <Box ml="7px">
                    {openNameChange && handleTextChange()}
                    {openChangePassword && handlePasswordChange()}
                    {isTextChanged && handleAvatarChange()}
                    <Tooltip
                      title={"change your avatar"}
                      placement="right"
                      arrow
                    >
                      <Avatar
                        size={"large"}
                        src={getAvatar()}
                        className={classes.purple}
                        onClick={() => {
                          (user.roleType !== "USER" || (user && user.id)) &&
                            setIsTextChanged(true);
                        }}
                      >
                        {profileItem?.name &&
                          (WordCountLength(profileItem?.name) > 1
                            ? `${WordCount(profileItem?.name)[0].charAt(
                                0
                              )}${WordCount(profileItem?.name)[1].charAt(0)}`
                            : `${WordCount(profileItem?.name)[0].charAt(0)}`)}
                      </Avatar>
                    </Tooltip>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    ml="20px"
                    mt="12px"
                  >
                    <Typography>
                      {`Username`}
                      <span style={{ marginLeft: "7px" }}>
                        {user && user.id && (
                          <RIconButton
                            title={"edit name"}
                            onClick={() => setOpenNameChange(true)}
                          />
                        )}
                      </span>
                    </Typography>

                    <Typography variant="h5">{profileItem?.name}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    ml="20px"
                    mt="12px"
                  >
                    <Typography>
                      Change your password
                      <span style={{ marginLeft: "7px" }}>
                        {user && user.id && (
                          <RIconButton
                            title={"update password"}
                            onClick={() => setOpenChangePassword(true)}
                          />
                        )}
                      </span>
                    </Typography>
                  </Box>
                </Box>
                {/* Role Email*/}
                <Box display="flex" justifyContent="space-start" gridGap={50}>
                  <Box display="flex" flexDirection="column" m="17px">
                    <h3 style={{ fontWeight: "normal", margin: 0 }}>Email </h3>
                    <Typography
                      component="p"
                      style={{ fontWeight: "700", fontSize: "17px" }}
                      variant="h6"
                    >
                      {profileItem.username}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          )}
          {user.roleType === "ADMIN" && selfproject?.role !== "USER" ? (
            <Paper>
              <Box marginTop={"20px"}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  padding={"3rem"}
                  paddingBottom={"0"}
                >
                  <Typography variant="h6">Add Time Sheet</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={enableLog}
                        onChange={() =>
                          dispatch(enableLoghour(user?.projectId, !enableLog))
                        }
                      />
                    }
                  />
                </Box>
                <Box padding={"3rem"} paddingTop={"0rem"}>
                  {enableLog ? (
                    <span style={{ color: "#999999" }}>
                      Toggling this will let you and your teammates use <br />
                      store points against kanban cards instead of timesheet
                    </span>
                  ) : (
                    <span style={{ color: "#999999" }}>
                      Toggling this will let you and your teammates use <br />
                      timesheet against kanban cards instead of story points
                    </span>
                  )}
                </Box>
              </Box>
            </Paper>
          ) : null}

          <TabProfile
            {...{
              selfproject,
              history,
              setFilterProject,
              filterproject,
              projectData,
              ProjectItem,
              deleteSingleProject,
              handledeleteSingleProject,
              setdeleteSingleProject,
              user,
            }}
            generateCSV={generateCSV}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Profile;
