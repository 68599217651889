export const SingleSquareBoxs = (item) => {
    const newItem = item !== undefined && item !== null ? item[0] : null

    console.log({ newItem })

    return [
        {
            title: "User Stories",
            name: 'feature',
            id: 1,
            visible: newItem != null ? newItem.feature : true,
        },
        {
            title: "Task​",
            name: 'task',
            id: 2,
            visible: newItem != null ? newItem.task : true,
        },
        {
            title: "Bug​",
            name: 'bug',
            id: 3,
            visible: newItem != null ? newItem.bug : true,
        },
        {
            title: "Risk",
            name: 'risk',
            id: 4,
            visible: newItem != null ? newItem.risk : true,
        },
        {
            title: "Issue",
            name: 'issue',
            id: 5,
            visible: newItem != null ? newItem.issue : true,
        },
    ]
};




export const areObjectsMatching = (data) => {

    // checking if any objects are null or not
    if (data.length > 1) {
        const hasNull = data.some(object =>
            object === null || Object.values(object).some(value => value === null)
        );

        if (hasNull) {
            return null; // If there's a 'null', we don't even need to compare objects.
        } else {
            // making new array to compare easily
            const newData = data.map(item => {
                return {
                    "feature": item.feature,
                    "task": item.task,
                    "bug": item.bug,
                    "risk": item.risk,
                    "issue": item.issue,
                }
            })

            // setting a base object with which we can compare
            const baseObject = newData[0];

            // making an array o keys of base object
            const keysToCompare = Object.keys(newData[0]);




            // checking every value of every keys from every object
            return newData.every(object =>
                keysToCompare.every(key => object[key] === baseObject[key])
            );
        }
    } else {
        return true
    }



}