import { createRiskSubmit } from "helpers/RiskAndIssue";
import { nanoid } from "nanoid";
const { boarsdData } = require("assets/Data/BoardsData");
const writingComment = (user, data, comment) => ({
  id: `notes_${nanoid(12)}`,
  comment: comment ? comment : <p>Created</p>,
  projectId: user?.projectId,
  cardId: data?.cardId ? data?.cardId : data?.id,
  userId: user?.id,
  cretedDate: new Date(),
  deletable: comment ? false : true,
});

const blockers = (user, data, itemData) => ({
  id: `notes_${nanoid(12)}`,
  comment: rawContentForRiskAndIssue(
    window?.location?.origin + "/riskandissues/?card=" + itemData.id,
    itemData.inputValue
  ),
  cretedDate: new Date(),
  projectId: user?.projectId,
  cardId: data?.cardId ? data?.cardId : data?.id,
  userId: user?.id,
  deletable: true,
});

const filteredItemForBlockers = (params, filtered) => {
  filtered.push({
    inputValue: params.inputValue,
    label: `Add "${params.inputValue}"`,
    value: nanoid(12),
    type: "created",
  });
  filtered.push({
    inputValue: params.inputValue,
    label: `Add ${params.inputValue} to "risk and issue Board"`,
    value: nanoid(12),
    type: "riskAndIssue",
  });
};

const getEpicName = (value, valueStream) => {
  const showingName = valueStream.find((item) => item.id === value && item);
  return showingName?.valueStreamName;
};
const getPersonaName = (value, personas) => {
  const showingName = personas.find((item) => item.id === value && item);
  return showingName?.name;
};
const getPersona = (value, personas) => {
  const showingName = personas.find((item) => item.value === value && item);
  return showingName;
};

const getEpicColor = (value, valueStream) => {
  const showingName = valueStream.find((item) => item.id === value && item);
  return showingName?.color;
};

export const rawContentForRiskAndIssue = (data, itemData) => {
  const rawContent = {
    blocks: [
      {
        key: "637gr",
        text: `Added "${itemData}" as an  issue to the "Risk and Issue" board.  `,
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 7,
            length: itemData && itemData.length ? itemData.length : 10,
            style: "color-rgb(44,130,201); cursor:pointer",
          },
        ],
        entityRanges: [
          {
            offset: 7,
            length: 8,
            key: 0,
          },
        ],
        data: {},
      },
    ],
    entityMap: {
      0: {
        type: "LINK",
        mutability: "MUTABLE",
        data: {
          url: data,
          title: itemData,
          targetOption: "_blank",
        },
      },
    },
  };
  return rawContent;
};

const taskFormData = (data, kanbanData, user) => {
  // console.log("taskFormData", { data })
  const formData = new FormData();
  formData.append("attachmentList", []);
  const finalBlockers = [...boarsdData.appendedBlockers];
  if (finalBlockers) {
    for (const blocker of finalBlockers) {
      formData.append("blockers", JSON.stringify(blocker));
    }
  }
  formData.append("related", JSON.stringify([]));
  formData.append("dueDate", "");
  formData.append("epicId", "");
  formData.append("id", data?.id);
  formData.append("personaId", "");
  formData.append("bugsLabel", data.bugsLabel ? data.bugsLabel : "");
  formData.append("assessments", JSON.stringify([]));
  formData.append("choresLabel", data.choresLabel ? data.choresLabel : "");
  formData.append("linkToDesign", "");
  formData.append("description", "");
  formData.append("draggable", true);
  formData.append("label", "");
  formData.append("title", "");
  formData.append("userId", "");
  formData.append("estimateHour", 0);
  formData.append("createDate", new Date());
  formData.append("releaseRef", "");
  formData.append("size", 0);
  for (let pic of []) {
    formData.append("attachments", pic);
  }
  // for lane wise kanban cards
  formData.append(
    "laneId",
    kanbanData.find((item) => item.title === "Ice Box").id
  );
  formData.append("type", data?.bugsLabel ? "bug" : "chore");

  const findlastIndex = kanbanData.find((value) => value.title === "Ice Box");

  formData.append("indexNumber", findlastIndex?.cards?.length);

  const metadata = [writingComment(user, data)];
  formData.append("metadata", JSON.stringify(metadata));
  return formData;
};
const allTypeFormData = (
  selectedDate,
  data,
  toEmails,
  related,
  cardType,
  epicId,
  assignee,
  user,
  sizeOfProblem,
  filesToSave,
  kanbanData,
  notDeletableItem
) => {
  const formData = new FormData();
  const dueDate = selectedDate ? selectedDate : "";
  formData.append("attachmentList", []);
  const finalBlockers = [...boarsdData.appendedBlockers, ...toEmails];
  if (finalBlockers) {
    for (const blocker of finalBlockers) {
      formData.append("blockers", JSON.stringify(blocker));
    }
  }
  const findRelates = [...boarsdData.appendedBlockers, ...related];
  if (findRelates) {
    for (const rel of findRelates) {
      formData.append("related", JSON.stringify(rel));
    }
  }
  formData.append("dueDate", dueDate);
  if (cardType !== 3) {
    formData.append("epicId", epicId);
    formData.append("id", data.cardId);
    formData.append("personaId", data.personas?.value);
    localStorage.setItem("currentPersona", JSON.stringify(data.personas));
    //working
    if (data.bugsLabel) {
      formData.append("bugsLabel", data.bugsLabel);
      formData.append("title", "");
      formData.append("choresLabel", "");
    }
    if (data.choresLabel) {
      formData.append("bugsLabel", "");
      formData.append("title", "");
      formData.append("choresLabel", data.choresLabel);
    }
    if (!data.choresLabel && !data.bugsLabel && data.title) {
      formData.append("bugsLabel", "");
      formData.append("title", data.title);
      formData.append("choresLabel", "");
    }
    // formData.append("bugsLabel", data.bugsLabel);
    formData.append("assessments", JSON.stringify([]));
    // formData.append("choresLabel", data.choresLabel);
    formData.append(
      "linkToDesign",
      data?.linkToDesign ? data.linkToDesign : ""
    );
    formData.append("description", data.description ?? "");
    formData.append("draggable", true);
    // formData.append("label", data.label);
    // formData.append("title", data.title);
    formData.append("userId", assignee !== "" ? assignee : user?.id);

    formData.append("estimateHour", 0);
    formData.append("createDate", new Date());
    formData.append("releaseRef", "");
    if (sizeOfProblem === 0) {
      formData.append("size", 0);
    }
    if (sizeOfProblem === 1) {
      formData.append("size", 1);
    }
    if (sizeOfProblem === 2) {
      formData.append("size", 2);
    }
    if (sizeOfProblem === 3) {
      formData.append("size", 3);
    }
    for (let pic of filesToSave) {
      formData.append("attachments", pic);
    }
  }
  // for lane wise kanban cards
  formData.append(
    "laneId",
    kanbanData.find((item) =>
      cardType !== 3 ? item.title === "Ice Box" : item.title === "To Do"
    ).id
  );

  // based on cards
  const findlastIndex = kanbanData.find((value) => value.title === "Ice Box");
  const finalIndex =
    findlastIndex?.cards?.length > 0
      ? Math.max(...findlastIndex?.cards.map((value) => value.indexNumber))
      : 0;
  if (cardType === 0) {
    formData.append("type", "feature");

    formData.append("indexNumber", finalIndex + 1);
  }
  if (cardType === 1) {
    formData.append("type", "bug");

    formData.append("indexNumber", +1);
  }
  if (cardType === 2) {
    formData.append("type", "chore");

    formData.append("indexNumber", finalIndex + 1);
  }
  if (cardType === 3) {
    formData.append("type", "release");
    formData.append("releaseName", data?.releaseName);
    formData.append("goal", data?.goal ?? "");
    formData.append("draggable", true);
    const findlastIndex = kanbanData.find((value) => value.title === "To Do");
    formData.append("indexNumber", findlastIndex?.cards?.length + 2);
  }
  const metadata = [writingComment(user, data)];
  const filteredIssueItems = toEmails.filter(
    (item) => item.type === "riskAndIssue"
  );

  if (filteredIssueItems && filteredIssueItems.length) {
    const idAddedIssue = filteredIssueItems.map((item) => ({
      ...item,
      id: `sqbear_risk_and_issue_${nanoid(15)}`,
    }));
    const getAllComments = idAddedIssue.map((item) =>
      blockers(user, data, item)
    );
    idAddedIssue.forEach((item) =>
      createRiskSubmit(item, user, notDeletableItem)
    );
    const metadataWithBlockers = [
      ...getAllComments,
      writingComment(user, data),
    ];
    formData.append("metadata", JSON.stringify(metadataWithBlockers));
  } else {
    formData.append("metadata", JSON.stringify(metadata));
  }
  return formData;
};
export {
  writingComment,
  blockers,
  filteredItemForBlockers,
  getEpicName,
  getEpicColor,
  getPersonaName,
  getPersona,
  taskFormData,
  allTypeFormData,
};
