// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.decisionNode {
  background: #d5f692;
  min-width: 82.81px;
  min-height: 82.81px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: rotate(45deg);
}
.filesNode {
  background: white;
  min-width: 120px;
  min-height: 120px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
`, "",{"version":3,"sources":["webpack://./src/Components/FlowComponents/index.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,2CAA2C;EAC3C,wBAAwB;AAC1B;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,2CAA2C;AAC7C","sourcesContent":[".decisionNode {\n  background: #d5f692;\n  min-width: 82.81px;\n  min-height: 82.81px;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n  transform: rotate(45deg);\n}\n.filesNode {\n  background: white;\n  min-width: 120px;\n  min-height: 120px;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
