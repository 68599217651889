import { Box, Fab, Tooltip } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
/**
 *@function AddIconForGrid.jsx
 *@author Azim
 *
 **/
const AddIconForGrid = ({ classes, renderOpenClickWayListener, title }) => {
  return (
    <Box className={classes.buttonInDataGrid}>
      <Tooltip title={title} arrow>
        <Box className={classes.addButton}>
          <Fab
            onClick={renderOpenClickWayListener}
            color="primary"
            aria-label="add"
          >
            <AddCircleOutlineIcon fontSize="large" />
          </Fab>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default AddIconForGrid;
