export const emojis = [
  {
    emoji: "😊",
    id: 1,
    text: "Happy",
  },
  {
    emoji: "😕",
    text: "Sad",
    id: 7,
  },
  {
    // emoji: "😵‍💫",
    emoji: "😤",
    text: "Frustrated",
    id: 8,
  },
  {
    emoji: "😡",
    text: "Angry",
    id: 4,
  },
  {
    emoji: "😯",
    id: 2,
    text: "Surprised",
  },
  {
    emoji: "😬",
    text: "Confused",
    id: 3,
  },

  {
    emoji: "👍",
    text: "Positive",
    id: 5,
  },
  {
    text: "Negative",
    emoji: "👎",
    id: 6,
  },
];