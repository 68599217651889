export { default as Home } from "./Home";
export { default as Login } from "./Login";
export { default as Signup } from "./Signup";
export { default as FinalDiagram } from "./FinalDiagram";
export { default as RiskIssues } from "./RiskIssues";
export { default as Profile } from "./Profile";
export { default as JourneyMap } from "./JourneyMap";
export { default as SplashScreen } from "./SplashScreen/index.jsx";
export { default as ForgotPassword } from "./ForgotPassword";
export { default as Pricing } from "./Pricing";
export { default as Activation } from "./Activation";
export { default as ResetPassword } from "./ResetPassword";
export { default as Boards } from "./Board";
export { default as AddUsers } from "./AddUsers";
export { default as CustomBoard } from "./CustomBoard";
export { default as UpdatePricing } from "./UpdatePricing";
export { default as NotFound } from "./NotFound";
export { default as ProjectUsers } from './ProjectUsers';
export { default as ProblemStatement } from './ProblemStatement'
export { default as Personas } from './Personas'
export { default as Callback} from "./Callback"