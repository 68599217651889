import { auth } from 'api/Auth';
import store from 'redux/store';
import axios from "../../api/axios";
import { authConstants, paymentConstants } from "./constant";


export const addUserToTable = (data) => {
    return (dispatch) => {

        dispatch({ type: paymentConstants.USER_ADD, payload: data });
    };
};

export const deleteUserToTable = (id) => {
    return (dispatch) => {
        dispatch({ type: paymentConstants.USER_DELETE, payload: id });
    };
};
export const updateUserDataToTheTable = (data) => {
    return async (dispatch) => {
        try {
            if (data) {

                dispatch({
                    type: paymentConstants.USER_UPDATE,
                    payload: data
                })
            }
        } catch (error) {
            // console.log(error);
        }
    }
}

export const saveSubscription = (data) => {
    return async (dispatch) => {
        dispatch({
            type: paymentConstants.SAVE_SUBSCRIPTIONS_REQUEST,
        });
        try {
            if (data) {
                await axios.post(`/v1/subscriptionPlans`, data);
                dispatch({
                    type: paymentConstants.SAVE_SUBSCRIPTIONS_SUCCESS,
                });
            }
        } catch (error) {
            dispatch({
                type: paymentConstants.SAVE_SUBSCRIPTIONS_FAILURE,
            });
        }
    };
};
export const getUserSubscriptionDetails = (id) => {
    return async (dispatch) => {
        dispatch({
            type: paymentConstants.SAVE_SUBSCRIPTIONS_REQUEST,
        });
        try {
            if (id) {
                const res = await axios.get(`/v1/users/${id}/stripe`);
                dispatch({
                    type: paymentConstants.GET_SINGLE_SUBSCRIPTION,
                    payload: res?.data?.data
                });
            }
        } catch (error) {
            dispatch({
                type: paymentConstants.SAVE_SUBSCRIPTIONS_FAILURE,
            });
        }
    };
};
export const getAllSubscription = (id) => {
    return async (dispatch) => {
        try {
            if (id) {
                const res = await axios.get(`/v1/subscriptionPlans`,);
                dispatch({
                    type: paymentConstants.GET_ALL_SUBSCRIPTIONS,
                    payload: res.data?.data,
                });
            }
        } catch (error) {
            dispatch({
                type: paymentConstants.SAVE_SUBSCRIPTIONS_FAILURE,
            });
        }
    };
};
export const stripeSubscriptionCharge = (userId, requestBody) => {
    return async (dispatch) => {
        dispatch({
            type: paymentConstants.SAVE_SUBSCRIPTIONS_STRIPE_REQUEST,
        });
        try {
            if (userId && requestBody) {
                const res = await axios.post(`/v1/users/${userId}/stripe`, requestBody);
                const token = auth.getToken()
                const { kanbanId, projectId } = JSON.parse(window.localStorage.getItem("squser"));
                window.localStorage.removeItem("squser");
                const user = { ...res?.data?.data }
                user.kanbanId = kanbanId;
                user.projectId = projectId;
                window.localStorage.setItem("squser", JSON.stringify(user));
                dispatch({
                    type: paymentConstants.SAVE_SUBSCRIPTIONS_STRIPE_SUCCESS,
                    payload: res.data
                })
                if (user) {
                    store.dispatch({
                        type: authConstants.LOGIN_SUCCESS,
                        payload: {
                            token,
                            user,
                        },
                    });
                }
                if (user?.subscriptionPlanId) {
                    dispatch(getUserSubscriptionDetails(user?.id))
                }
            }
        } catch (error) {
            dispatch({
                type: paymentConstants.SAVE_SUBSCRIPTIONS_FAILURE,
            });
        }
    };
};
export const stripeUpdateSubscriptionCharge = (userId, requestBody) => {
    return async (dispatch) => {
        dispatch({
            type: paymentConstants.SAVE_SUBSCRIPTIONS_STRIPE_REQUEST,
        });
        try {
            if (userId && requestBody) {
                const res = await axios.post(`/v1/users/${userId}/stripe/update-subscription`, requestBody);
                const { kanbanId, projectId } = JSON.parse(window.localStorage.getItem("squser"));
                const token = auth.getToken()
                // window.localStorage.removeItem("squser");
                const user = { ...res?.data?.data }
                user.kanbanId = kanbanId;
                user.projectId = projectId;
                window.localStorage.setItem("squser", JSON.stringify(user));
                dispatch({
                    type: paymentConstants.SAVE_SUBSCRIPTIONS_STRIPE_SUCCESS,
                    payload: res.data
                })
                if (user) {
                    store.dispatch({
                        type: authConstants.LOGIN_SUCCESS,
                        payload: {
                            token,
                            user,
                        },
                    });
                }
                if (user?.subscriptionPlanId) {
                    dispatch(getUserSubscriptionDetails(user?.id))
                }
            }
        } catch (error) {
            dispatch({
                type: paymentConstants.SAVE_SUBSCRIPTIONS_FAILURE,
            });
        }
    };
};
export const handleChangeRequest = (data) => {
    return (dispatch) => {
        dispatch({ type: paymentConstants.DISABLE_FALSE });
    };
};
export const handleStripeSuccess = (data) => {
    return (dispatch) => {
        dispatch({ type: paymentConstants.STRIPE_SUCCESS_MODAL_CLOSE });
    };
};

