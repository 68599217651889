import { Modal } from "Components/Reusable";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { ErrorMessages } from "Components/Reusable";
import { Controller } from "react-hook-form";
import { Delete as DeleteIcon } from "@material-ui/icons";

export const UpdateValueStreamModal = ({
  updateModal,
  handleUpdateModal,
  handleUpdateSubmit,
  onUpdateSubmit,
  control,
  modalData,
  formData,
  persona,
  attempToDeleteValueStream,
}) => {
  // console.log({ modalData });
  return (
    <Modal open={updateModal} handleClose={handleUpdateModal}>
      <h1 style={{ textAlign: "left" }}>Update Value Stream</h1>
      <div>
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleUpdateSubmit(onUpdateSubmit)}
        >
          <Controller
            name="valueStreamName"
            control={control}
            defaultValue={modalData?.valueStreamName}
            rules={{
              required: {
                value: true,
                message: "This is required",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Value stream name*"
                variant="outlined"
                fullWidth
                type="text"
                style={{ marginTop: "12px" }}
              />
            )}
          />
          <ErrorMessages errors={formData} name="valueStreamName" />
          <br />
          <Controller
            name="valueStreamWhy"
            control={control}
            defaultValue={modalData?.valueStreamWhy}
            rules={{
              required: {
                value: true,
                message: "This is required",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Value stream why*"
                variant="outlined"
                style={{ marginTop: "12px" }}
                type="text"
                fullWidth
              />
            )}
          />
          <br />
          <Controller
            name="persona"
            control={control}
            defaultValue={modalData?.persona}
            rules={{
              required: {
                value: true,
                message: "This is required",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                style={{ margin: "12px 0px" }}
                label="Persona*"
                variant="outlined"
                type="text"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
          />
        </form>
      </div>
      <Button
        style={{
          width: "137px",
          fontWeight: "700",
          height: "42px",
        }}
        color="primary"
        variant="contained"
        onClick={() => handleUpdateModal()}
      >
        Update
      </Button>
      <Tooltip arrow title="Delete value Stream" placement="top">
        <IconButton
          onClick={() => attempToDeleteValueStream(modalData?.id)}
          style={{ marginTop: "10px" }}
          color="secondary"
          aria-label="add an alarm"
        >
          <DeleteIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    </Modal>
  );
};
