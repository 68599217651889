import { Box, Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Modal } from "Components/Reusable";
import { nanoid } from "nanoid";
import { useForm } from "react-hook-form";
/**
 *@function RenderWorkflow.jsx
 *@author Azim
 *
 **/

const RenderWorkflow = ({
  autoCompleteData,
  openWorkflowModal,
  setOpenWorkflowModal,
}) => {
  const {
    formState: { errors: errorsState },
    handleSubmit,
    reset,
    control: realControl,
  } = useForm({
    mode: "all",
  });
  const options = autoCompleteData.map((option) => {
    return {
      firstLetter: option.imRequired ? "Technical" : "Non-Technical",
      ...option,
    };
  });

  const onSubmit = (data) => {
    // console.log({ data });
    reset();
  };
  return (
    <Modal
      open={openWorkflowModal}
      handleClose={() => {
        setOpenWorkflowModal(false);
        // reset();
      }}
    >
      <h1
        style={{
          textAlign: "left",
          marginLeft: "32px",
          marginBottom: "-27px",
        }}
      >
        Generate Features
      </h1>
      <form
        style={{ padding: "20px" }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box marginTop={10}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={options.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            onChange={(event, newValue) => {
              if (Array.isArray(newValue)) {
                const updatedArrayValue = newValue.filter((e) =>
                  typeof e === "string" ? e.trim() : e
                );
                // #TODO
                const newArrayValue = [...updatedArrayValue];
                const updatedArray = newArrayValue.map((item) => {
                  if (typeof item === "string") {
                    const newItem = {};
                    newItem.label = item;
                    newItem.value = nanoid(12);
                    newItem.type = "created";
                    return newItem;
                  }
                  return item;
                });
                // setAllRelated(updatedArray);
              }
            }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Journeymap Columns"
                placeholder="Columns"
              />
            )}
          />
        </Box>
        <Button
          style={{ margin: "10px" }}
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
        >
          Generate
        </Button>
      </form>
    </Modal>
  );
};

export default RenderWorkflow;
