import { Box, Paper } from "@material-ui/core";
import { Add as AddIcon } from '@material-ui/icons'
import { Remove as RemoveIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { uniqBy } from "lodash";
import { errorAlert } from "Utils";
/**
 *@function PricingBox.jsx
 *@author Azim
 *
 **/

const SingleBox = ({ click, item, active, allplans ,existing, deactivateItem, users }) => {
  const isDeactivateItem =
    deactivateItem?.id === item?.id ||
    deactivateItem?.noOfUser >= item?.noOfUser || existing > item?.noOfUser;
  const classes = useStyles()

  const [quantity, setQuantity] = useState(0)

  const totalqnt = allplans?.reduce((item, value) => item + (value?.noOfUser * value?.quantity), 0)
  const totalusers = uniqBy(users, "id")?.length

  const handleItems = (data, point) => {
    if(point === "add"){
      setQuantity(quantity + 1)
      const newdata = {
        ...data,
        quantity: quantity + 1
      }

      click(newdata)
    }
    else if(point === "sub"){
      if( allplans !== undefined &&  totalqnt - data.noOfUser >= totalusers){
        if (quantity > 0) {

          setQuantity(quantity - 1)
          const newdata = {
            ...data,
            quantity: quantity - 1
          }
          click(newdata)
        } else {
          const newdata = {
            ...data,
            quantity: quantity
          }
          click(newdata)
        }
      }else if(allplans === undefined){
        if (quantity > 0) {

          setQuantity(quantity - 1)
          const newdata = {
            ...data,
            quantity: quantity - 1
          }
          click(newdata)
        } else {
          const newdata = {
            ...data,
            quantity: quantity
          }
          click(newdata)
        }
      }
      else{
        errorAlert(`You already have ${totalusers} seats occupied`)
      }
      
    }
    
  }

  const getExistingQuantity = useCallback(() => {
    const getquantity = allplans?.find(value => value.priceId === item.priceId)?.quantity
    setQuantity(getquantity > 0 ? getquantity : 0)
  },[item, allplans])

  useEffect(() => {
    getExistingQuantity()
  },[getExistingQuantity])

  return (
    <Box
      // style={{
      //   cursor: isDeactivateItem ? "not-allowed" : "pointer",
      // }}
      // onClick={() => !isDeactivateItem && click(item.name)}
      marginBottom={"1rem"}
    >
      <Paper
        elevation={3}
        // style={
        //   item.name === active
        //     ? { backgroundColor: "#A5A6F6", borderRadius: "15px" }
        //     : {
        //       borderRadius: "15px",
        //       background: isDeactivateItem ? "#DEDEDE" : "transparent",
        //     }
        // }
        style={{borderRadius:"15px"}}
      >
        <Box
          paddingTop={1}
          paddingLeft={3}
          paddingRight={3}
          paddingBottom={0.5}
        >
          <Box
            display={`flex`}
            justifyContent="space-between"
            alignItems={`center`}
          >
            <Box>
              <h1
                style={{ margin: "0", fontSize: "15px", fontWeight: "bolder" }}
              >
                {item.name}
              </h1>
              <h2 style={{ fontWeight: "bold", marginTop:"0px", marginBottom:"14px" }}>{item.noOfUser} seats </h2>
              <Box display={"flex"}>
                <Box onClick={() => handleItems(item, "sub")}> <RemoveIcon className={classes.crementIcons} /> </Box>
                <Box> <p className={classes.seatnumbers}> {quantity} </p> </Box>
                <Box onClick={() => handleItems(item, "add")}><AddIcon className={classes.crementIcons} /> </Box>
                <Box marginLeft={"1rem"}>Please Select desired Seat</Box>
              </Box>
            </Box>

            <Box>
              <h1>{`$${(item.amount / item.noOfUser).toFixed(2)}`} / seat</h1>
              <p style={{ color: "#373A4D", fontSize: "14px" }}>
                ${item.amount}.00 per month
              </p>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SingleBox;


const useStyles = makeStyles(theme => ({
  crementIcons: {
    fontSize: "11px",
    fontWeight: "normal",

    marginTop: "0px",
    marginBottom:"0px",
    border:"1px solid #d5d5d5",
    height:"23px",
    width:"20px",
    textAlign:"center",
    background:"#f8f9fb",
    color:"#7478e8",
    cursor:"pointer"
  },
  seatnumbers:{
    fontSize: "11px",
    fontWeight: "normal",

    marginTop: "0px",
    marginBottom: "0px",
    border: "1px solid #d5d5d5",
    height: "23px",
    width: "40px",
    textAlign: "center",
    paddingTop:"3px",
    backgroundColor:"white"
  }
}))