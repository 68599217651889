// import React, { Fragment } from "react";
// import {
//   Star as StarIcon,
//   Adb as AdbIcon,
//   AssignmentTurnedInOutlined as AssignmentTurnedInOutlinedIcon,
// } from "@material-ui/icons";
// import { UserAvatar, SVGRender } from "Components/Reusable";
// import {
//   Box,
//   makeStyles,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableRow,
// } from "@material-ui/core";
// import { images } from "assets";
// import MUIEditor, {
//   MUIEditorState,
//   toolbarControlTypes,
//   LANG_PREFIX,
//   fileToBase64,
//   toHTML,
// } from "react-mui-draft-wysiwyg";
// import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
// function BlockersTable({
//   particularCard,
//   blockers,
//   persona,
//   getAvatar,
//   getName,
//   renderStyle,
// }) {
//   const classes = useStyles();
//   return <Box></Box>;
// }
// export default React.memo(BlockersTable);

// const useStyles = makeStyles(() => ({
//   updateCardAvatar: {
//     height: "24px",
//     width: "24px",
//     fontSize: "13px",
//   },
// }));
