import axios from "../../api/axios";
import { errorAlert, successAlert } from "Utils";
import { authConstants, profileConfig } from "./constant";
import store from "redux/store";
import { auth } from "api/Auth";

export const getAllProfileConfiguration = (userId, projectIds) => {
  return async (dispatch) => {
    dispatch({ type: profileConfig.GET_ALL_PROFILE_CONFIG_REQUEST });
    try {
      const res = await axios.get(
        `/v1/projects/profileSquare/getAllConfigs/${userId}/${projectIds}`
      );
      if (res.status === 200) { 
        dispatch({
          type: profileConfig.GET_ALL_PROFILE_CONFIG_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: profileConfig.GET_ALL_PROFILE_CONFIG_ERROR,
        payload: error.message,
      });
    }
  };
};

export const getSingleConfiguration = (userId) => {
  return async (dispatch) => {
    dispatch({ type: profileConfig.GET_SINGLE_PROFILE_CONFIG_REQUEST });
    try {
      const res = await axios.get(`/v1/projects/profileSquare/${userId}`);
      // console.log({ res }, "response");
      dispatch({
        type: profileConfig.GET_SINGLE_PROFILE_CONFIG_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      // console.log({ error });
    }
  };
};
export const getSingleHomeConfig = (projectId) => {
  return async (dispatch) => {
    dispatch({ type: profileConfig.GET_ALL_HOME_REQUEST });
    try {
      const res = await axios.get(
        `/v1/projects/profileSquare/getSingle/${projectId}`
      );
      if (res.data === "") {
        const newitem = {
          customBoard: true,
          journeyMap: true,
          kanban: true,
          latestOne: true,
          persona: true,
          problemStatement: true,
          riskIssues: true,
          workflow: true,
          projectId,
        };
        dispatch({
          type: profileConfig.GET_ALL_HOME_SUCCESS,
          payload: newitem,
        });
      } else {
        dispatch({
          type: profileConfig.GET_ALL_HOME_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      // console.log({ error });
    }
  };
};
export const getSingleCheck = (userId, status) => {
  return async (dispatch) => {
    dispatch({ type: profileConfig.GET_CHECKED_REQUEST });
    try {
      const res = await axios.get(
        `/v1/projects/profileSquare/applyToNew/${userId}/${status}`
      );
      //SHAHED_NEW
      const getUser = JSON.parse(window.localStorage.getItem("squser"));
      // window.localStorage.removeItem("squser");
      getUser.applyToNew = res.data.applyToNew;
      window.localStorage.setItem("squser", JSON.stringify(getUser));
      const token = auth.getToken();
      const user = JSON.parse(window.localStorage.getItem("squser"));
      if (user) {
        store.dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: {
            token,
            user,
          },
        });
      }
      dispatch({
        type: profileConfig.GET_CHECKED_SUCCESS,
      });
      // successAlert("Configuration changed successfully")
    } catch (error) {
      // console.log({ error });
    }
  };
};

export const saveCofiguration = (requestBody) => {
  return async (dispatch) => {
    try {
      const responseBody = await axios.post(
        `/v1/projects/profileSquare/saveConfig`,
        requestBody
      );
      if (responseBody.status === 200) {
        dispatch({ type: profileConfig.SAVE_CONFIG });
        successAlert("Configuration Saved Successfully");
      }
    } catch (error) {
      errorAlert(error.status);
    }
  };
};
