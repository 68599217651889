//to centralise the api , so we can change the data in every place by changing one place.
import axios from 'axios';

import store from '../redux/store';
import { authConstants } from '../redux/actions/constant';
import { auth } from './Auth';
import { AppMode } from 'urlConfig';
import { errorAlert } from 'Utils';


const axiosInstance = axios.create({
  baseURL: AppMode.backend,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
    // Authorization: `Bearer ${localStorage.getItem("sqtoken")}`,
  },
});


axiosInstance.interceptors.request.use((req) => {
  const token = auth.getToken()
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});
axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const status = error.response ? error && error.response && error.response.status : 500;
    if (status === 500 || status === 401) {
      window.localStorage.removeItem("sqtoken");
      // if(status === 401){
      //   errorAlert("Please Login Again")
      // }
      auth.removeToken()
      window.localStorage.removeItem("squser");

      store.dispatch({ type: authConstants.LOGOUT_SUCCESS });
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
