import { useEffect, useState } from "react";
import { Button, Grid, Box, Tooltip } from "@material-ui/core";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
import Layout from "Components/Layout";
import { CustomSnacbar } from "Components/Reusable";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addNewPersona,
  addNewValueStream,
  deleteValueStream,
  // getPersona,
  // updateValueStream,
  getNewValueStream,
  // updatePersona,
  createNewBoardKanban,
  deleteSingleBoardKanban,
  updateSingleBoardKanban,
  getPersonas,
  deletePersona,
  getLaneCountsForHomePage,
  makingEmptyTheKanban,
  gettingEmptyDiagram,
  getproblemStatement,
  getAllLaneDetailsAboutJourney,
  getAllworkflowDetails,
} from "redux/actions";
import StreamCard from "Components/Reusable/Card/StreamCard";
import { useToggle } from "hooks/useToggle";
import { images } from "assets";
import CircleBox from "../../Components/FlowComponents/CircleBox";
import colors from "assets/Colors";
import Avatars from "assets/Avatars";
import {
  AddBoardModal,
  AddNewPersona,
  AddValueStreamModal,
  JourneymapControl,
  PersonaSection,
  ProblemStatement,
  UpdateBoardModal,
  // UpdatePersonaModal,
  // WorkFlowControl,
  WorkManagement,
  AddPersona,
} from "Components/HomeComponents";
import { useHomeStyles } from "assets/Styles/Home";
import Spinner from "Components/Reusable/Spinner";
import { PersonasForProblemStatement } from "Components/HomeComponents/PersonasForProblemStatement";
import { WorkFlows } from "Components/HomeComponents/WorkFlows";
import RiskAndIssueCard from "Components/HomeComponents/RiskAndIssueCard";
import useRenderhome from "hooks/useRenderhome";

const Home = (props) => {
  //open Modal states
  const [modalData, setModalData] = useState({});
  const [openValueStremModal, setOpenValueStremModal] = useState(false);
  const [openModalForBoard, setOpenModalForBoard] = useState(false);
  const [openPersonaModal, setOpenPersonaModal] = useToggle();
  const [changePersona, setChangePersona] = useToggle();
  const [openModalPersona, setOpenModalPersona] = useToggle();
  const [updateModal, setUpdateModal] = useToggle();
  const [alertToDelete, setAlertToDelete] = useState({});
  const [updateBoardModal, setUpdateBoardModal] = useToggle();
  const [openAlert, setOpenAlert] = useToggle();
  const [openValueStream, setOpenValueStream] = useToggle();
  const [deleteItem, setDeleteItem] = useState({});
  const [selectedBackground, setSelectedBackground] = useState({});
  //redux
  const dispatch = useDispatch();
  //TODO_HOME
  const { homeRenderer, loading: homeLoading } = useRenderhome();
  const auth = useSelector((state) => state.auth);
  const { kanban, loading } = useSelector((state) => state.kanban);
  const { laneCount } = useSelector((state) => state.riskandissue);
  const { id: userId, projectId } = auth?.user;
  const { valueStream, persona } = useSelector((state) => state.diagram);
  const { statement } = useSelector((state) => state.problemstatement);
  const { personas } = useSelector((state) => state.personas);
  const [personaproblemstatement, setPersonaProblemstatement] = useState(
    personas[0]?.id
  );
  const [recentPersonas, setRecentPersonas] = useState(null);
  const [recentFlowPersonas, setRecentFlowPersonas] = useState(null);

  const { flowOverview } = useSelector((state) => state.workflow);
  const { journeyDetails } = useSelector((state) => state.journey);


  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    control: realControl,
  } = useForm({
    mode: "all",
  });
  const {
    formState: { errors: boardError },
    register: boardRegister,
    handleSubmit: handleBoardSubmit,
    reset: boardReset,
  } = useForm({
    mode: "all",
  });
  const history = useHistory();
  const onSubmit = (data) => {
    const findColour = colors.filter(
      (val) => !valueStream.map((item) => item?.color).includes(val)
    );
    const color = findColour[Math.floor(Math.random() * findColour.length)];
    const updatedColor = color ? color : "#93d275";
    const newvalueStream = {
      valueStreamName: data.valueStreamName,
      valueStreamWhy: data.valueStreamWhy,
      persona: data.persona,
      projectId,
      userId,
      color: updatedColor,
    };
    dispatch(addNewValueStream(newvalueStream, projectId));
    setOpenValueStremModal(false);
    reset();
  };
  const onBoardSubmit = (data) => {
    const newKanbanData = { ...data };
    newKanbanData.projectId = projectId;
    newKanbanData.backgroundImage = selectedBackground.actualImage;
    newKanbanData.defaultKanban = false;
    setOpenModalForBoard(false);
    dispatch(createNewBoardKanban(projectId, newKanbanData));
    boardReset();
  };

  const {
    formState: { errors: formData },
    handleSubmit: handleUpdateSubmit,
    reset: resetUpdate,
    control,
  } = useForm({
    mode: "all",
    defaultValues: {
      valueStreamName: modalData?.valueStreamName,
      valueStreamWhy: modalData?.valueStreamWhy,
      persona: modalData?.persona,
    },
  });

  const onUpdateBoardSubmit = (data) => {
    const updateKanban = { ...modalData };
    updateKanban.name = data.name;
    updateKanban.boardColor = data?.boardColor;
    dispatch(
      updateSingleBoardKanban(projectId, updateKanban?.id, updateKanban)
    );
    setUpdateBoardModal(false);
    resetUpdate();
    setModalData({});
  };
  // for deleting epic value stream board
  const handleDeleteEpic = () => {
    dispatch(deleteValueStream(modalData));
    setOpenValueStream(false);
    setUpdateModal(false);
    resetUpdate();
    setModalData({});
  };
  // for deleting custom board
  const handleDeleteBoard = () => {
    dispatch(deleteSingleBoardKanban(projectId, alertToDelete?.id));
    setOpenAlert(false);
    setUpdateBoardModal(false);
    resetUpdate();
    setModalData({});
  };

  // for deleting custom board
  const attemptToDeleteBoard = (board) => {
    setOpenAlert(true);
    setAlertToDelete(board);
  };

  useEffect(() => {
    dispatch(getPersonas(projectId));
    dispatch(getNewValueStream(projectId));
    dispatch(getLaneCountsForHomePage(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(makingEmptyTheKanban(projectId));
    dispatch(gettingEmptyDiagram(projectId));
  }, [dispatch, projectId]);

  //getting Persona Data
  // useEffect(() => {
  //   dispatch(getPersona(projectId));
  // }, [dispatch, projectId]);

  useEffect(() => {
    if (personas.length > 0) {
      setRecentFlowPersonas(personas[0]?.id);
      setPersonaProblemstatement(personas[0]?.id);
      setRecentPersonas(personas[0]?.id);
    } else {
      setRecentFlowPersonas(null);
    }
  }, [personas]);

  // useEffect(() => {
  //   if (recentFlowPersonas && personas.length) {
  //     dispatch(getAllworkflowDetails(recentFlowPersonas));
  //   }
  // }, [recentFlowPersonas]);
  // useEffect(() => {
  //   if (recentPersonas !== null) {
  //     dispatch(getAllLaneDetailsAboutJourney(recentPersonas));
  //   }
  // }, [recentPersonas]);

  // for problem statement
  useEffect(() => {
    if (personaproblemstatement) {
      dispatch(
        getproblemStatement(auth?.user?.projectId, personaproblemstatement)
        
      );
      dispatch(getAllworkflowDetails(personaproblemstatement));
      dispatch(getAllLaneDetailsAboutJourney(personaproblemstatement));
    }
  }, [personaproblemstatement, personas, dispatch, auth.user.projectId]);

  const {
    formState: { errors: errorState },
    handleSubmit: handlePersonaSubmit,
    reset: resetPersona,
    control: controlled,
  } = useForm({
    mode: "all",
    defaultValues: {
      name: "",
    },
  });
  const onCreateSubmit = (data) => {
    const personaSubmit = { ...data, projectId };
    dispatch(addNewPersona(personaSubmit, projectId));
    setOpenPersonaModal(false);
    resetPersona();
  };
  const handleOpenModalForNewBoard = () => {
    setOpenModalForBoard(true);
  };
  const handleOpenModalForUpdateBoard = (item) => {
    setModalData(item);
    setUpdateBoardModal(true);
  };
  const classes = useHomeStyles(props);

  const getAvatar = (data) => {
    let newData;
    Object.keys(Avatars).find((key) => {
      if (key === data) {
        newData = `${Avatars[data]}`;
        return newData;
      }
      return newData;
    });
    return newData;
  };

  const handleDeletePersona = (id) => {
    dispatch(deletePersona(projectId, id));
    setDeleteItem(false);
  };

  return (
    <Layout pageName={"Home"}>
      {loading ? (
        <Spinner />
      ) : (
        <section>
          {openValueStremModal && (
            <AddValueStreamModal
              openValueStremModal={openValueStremModal}
              setOpenValueStremModal={setOpenValueStremModal}
              reset={reset}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
              errors={errors}
              realControl={realControl}
              persona={persona}
            />
          )}
          {openModalForBoard && (
            <AddBoardModal
              openModalForBoard={openModalForBoard}
              setOpenModalForBoard={setOpenModalForBoard}
              boardReset={boardReset}
              handleBoardSubmit={handleBoardSubmit}
              onBoardSubmit={onBoardSubmit}
              boardRegister={boardRegister}
              boardError={boardError}
              classes={classes}
              setSelectedBackground={setSelectedBackground}
              selectedBackground={selectedBackground}
            />
          )}
          {openModalPersona && (
            <AddPersona
              openModalPersona={openModalPersona}
              setOpenModalPersona={setOpenModalPersona}
              boardReset={boardReset}
              boardRegister={boardRegister}
              boardError={boardError}
              classes={classes}
              setSelectedBackground={setSelectedBackground}
              selectedBackground={selectedBackground}
            />
          )}
          {updateBoardModal && (
            <UpdateBoardModal
              updateBoardModal={updateBoardModal}
              setUpdateBoardModal={setUpdateBoardModal}
              resetUpdate={resetUpdate}
              setModalData={setModalData}
              handleUpdateSubmit={handleUpdateSubmit}
              onUpdateBoardSubmit={onUpdateBoardSubmit}
              control={control}
              modalData={modalData}
              classes={classes}
              formData={formData}
              alertToDelete={alertToDelete}
              attemptToDeleteBoard={attemptToDeleteBoard}
            />
          )}
          {openPersonaModal && (
            <AddNewPersona
              openPersonaModal={openPersonaModal}
              setOpenPersonaModal={setOpenPersonaModal}
              resetPersona={resetPersona}
              onCreateSubmit={onCreateSubmit}
              handlePersonaSubmit={handlePersonaSubmit}
              controlled={controlled}
              errorState={errorState}
            />
          )}

          {openAlert && (
            <CustomSnacbar
              opened={openAlert}
              handleDeleteFromBackend={handleDeleteBoard}
              setOpenAlert={setOpenAlert}
              type="Lane"
            />
          )}

          {openValueStream && (
            <CustomSnacbar
              opened={openValueStream}
              handleDeleteFromBackend={handleDeleteEpic}
              setOpenAlert={setOpenValueStream}
              type="Kanban"
            />
          )}

          <Grid spacing={2} container justifyContent="center">
            <Grid item sm={12}>
              <Grid
                spacing={2}
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                {homeRenderer?.persona && (
                  <Grid
                    className={classes.cardSection}
                    item
                    sm={
                      homeRenderer?.problemStatement && homeRenderer?.persona
                        ? 6
                        : 12
                    }
                  >
                    <PersonaSection
                      classes={classes}
                      personas={personas}
                      getAvatar={getAvatar}
                      history={history}
                      deleteItem={deleteItem}
                      alertToDelete={alertToDelete}
                      setDeleteItem={setDeleteItem}
                      handleDeletePersona={handleDeletePersona}
                      persona={persona}
                    />
                  </Grid>
                )}

                {homeRenderer?.problemStatement && (
                  <Grid
                    className={classes.cardSection}
                    item
                    sm={
                      homeRenderer?.problemStatement && homeRenderer?.persona
                        ? 6
                        : 12
                    }
                  >
                      <StreamCard titleVariant="h5" titleText="Problem Statement" subtitle="Discovery">
                      {personas.length && statement.length ? (
                        <Tooltip
                          title="click to update problem statement"
                          arrow
                          placement="top"
                        >
                          <Box
                            style={{
                              height: "80%",
                              overflow: "hidden",
                              overflowY: "auto",
                            }}
                            onClick={() =>
                              history.push(
                                `/problemstatement/${personaproblemstatement}`
                              )
                            }
                          >
                            <ProblemStatement statement={statement} />
                          </Box>
                        </Tooltip>
                      ) : (
                        <Box
                          style={{
                            height: "90%",
                            overflow: "hidden",
                            overflowY: "auto",
                            color: "orange",
                            position: "relative",
                            top: "6rem",
                          }}
                          onClick={() => {
                            if (personas.length > 0) {
                              history.push(
                                `/problemstatement/${personaproblemstatement}`
                              );
                            } else {
                              history.push(`/personas`);
                            }
                          }}
                        >
                          {personas.length > 0
                            ? "Click To Add Problem Statement"
                            : "No Personas? Create one here"}
                        </Box>
                      )}

                      <Box height={"20px"}>
                        <PersonasForProblemStatement
                          classes={classes}
                          personas={personas}
                          getAvatar={getAvatar}
                          changePersonas={setPersonaProblemstatement}
                          personaproblemstatement={personaproblemstatement}
                          setOpenModalPersona={setOpenModalPersona}
                          persona={persona}
                        />
                      </Box>
                    </StreamCard>
                  </Grid>
                )}
              </Grid>
              {/* container for journey map */}
              <Grid container spacing={2}>
                {homeRenderer?.journeyMap && (
                  <Grid
                    className={classes.cardSection}
                    item
                    sm={
                      homeRenderer?.journeyMap && homeRenderer?.workflow
                        ? 6
                        : 12
                    }
                  >
                    <JourneymapControl
                      classes={classes}
                      journeyMap
                      titleText="Journey Map"
                      getAvatar={getAvatar}
                      openModalPersona={openModalPersona}
                      setOpenModalPersona={setOpenModalPersona}
                      setChangePersona={setChangePersona}
                        setRecentPersonas={setPersonaProblemstatement}
                      recentPersonas={personaproblemstatement}
                      valueStream={personas}
                      history={history}
                      journeyDetails={journeyDetails}
                    />
                  </Grid>
                )}

                {/* for workflow section */}
                {homeRenderer?.workflow && (
                  <Grid
                    className={classes.cardSection}
                    item
                    sm={
                      homeRenderer?.journeyMap && homeRenderer?.workflow
                        ? 6
                        : 12
                    }
                  >
                    <WorkFlows
                      classes={classes}
                      titleText="Feature Design"
                      journeyMap
                      workflow
                      getAvatar={getAvatar}
                      setChangePersona={setChangePersona}
                      setOpenModalPersona={setOpenModalPersona}
                      valueStream={personas}
                      history={history}
                        setRecentFlowPersonas={setPersonaProblemstatement}
                        recentFlowPersonas={personaproblemstatement}
                      journeyDetails={flowOverview}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid
                style={{ marginLeft: "1px" }}
                spacing={2}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {homeRenderer?.kanban && (
                  <WorkManagement
                    persona={persona}
                    classes={classes}
                    layoutSec={homeRenderer?.kanban && homeRenderer?.riskIssues}
                    setOpenPersonaModal={setOpenPersonaModal}
                  />
                )}
                {homeRenderer?.riskIssues && (
                  <RiskAndIssueCard
                    layoutSec={homeRenderer?.kanban && homeRenderer?.riskIssues}
                    laneCount={laneCount}
                    classes={classes}
                  />
                )}
                {/* this portion is for risk and issue */}
              </Grid>

              {/* Boards */}
              {homeRenderer.customBoard && (
                <Grid style={{ marginTop: "10px" }}>
                  <StreamCard titleVariant="h5" titleText="Boards">
                    <Box className={classes.streamCardBox}>
                      <Grid className={classes.scrollUpStyle} container>
                        {kanban.length > 0
                          ? kanban.map((item, index) => {
                              return (
                                item?.defaultKanban === false && (
                                  <Grid
                                    className={classes.beforeSectionCard}
                                    key={index}
                                    item
                                    md={2}
                                  >
                                    <img
                                      onClick={() =>
                                        handleOpenModalForUpdateBoard(item)
                                      }
                                      src={images.edit}
                                      className={classes.iconStyle}
                                      alt="Edit Button"
                                    />
                                    <Box
                                      className={classes.particularBox}
                                      onClick={() => {
                                        history.push(
                                          `/unhookboard?boardId=${item.id}`
                                        );
                                      }}
                                    >
                                      <CircleBox
                                        background={item?.boardColor}
                                        boardItem={item}
                                      />
                                    </Box>
                                  </Grid>
                                )
                              );
                            })
                          : null}

                        <Box
                          onClick={handleOpenModalForNewBoard}
                          className={classes.AddboxCircle}
                        >
                          <Tooltip
                            placement="bottom"
                            arrow
                            title={"Click to create Board"}
                          >
                            <Button
                              className={classes.buttonStyle}
                              disableRipple
                              endIcon={
                                <AddCircleIcon
                                  className={classes.buttonInnerStyle}
                                />
                              }
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Box>
                  </StreamCard>
                </Grid>
              )}
            </Grid>
          </Grid>
        </section>
      )}
    </Layout>
  );
};

export default Home;
