import { Avatar, Box, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { MPopover } from "Components/Reusable";
import { getSingleAvatar } from "Utils";
import { useRef } from "react";

/**
 *@function PopoverForNewPersona.jsx
 *@author Azim
 *
 **/

const PopoverForNewPersona = ({
  openAssigneeid,
  openAssignee,
  anchorForAssignee,
  setAnchorForAssignee,
  classes,
  toUsers,
  addCardsToIceBoxInTheBoard,
  singleAssigneeForBoard,
  setLocalValue,
  getAvatar,
  getName,
  storedValue,
}) => {
  const popoverRef = useRef();
  return (
    <Box>
      <MPopover
        ref={popoverRef}
        id={openAssigneeid}
        open={openAssignee}
        anchorEl={anchorForAssignee}
        onClose={() => setAnchorForAssignee(null)}
        className={classes.popoverStyle}
      >
        <Autocomplete
          options={toUsers}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => {
            return option.value === value.value;
          }}
          style={{ width: "100%", padding: "20px" }}
          id="blur-on-select"
          size="small"
          blurOnSelect
          // SHAHED
          onChange={(e, f) => {
            // console.log({ f });
            setLocalValue(f);
            setAnchorForAssignee(null);
          }}
          value={storedValue}
          renderOption={(option, { selected }) => (
            <Box className={classes.flexBetweenCenter}>
              <Avatar
                style={{
                  height: "30px",
                  width: "30px",
                }}
                src={option.avatar ? getSingleAvatar(option.avatar) : "Azim"}
              ></Avatar>
              <p
                style={{
                  color: "black",
                  display: "block",
                  marginLeft: "10px",
                }}
              >
                {option?.label}
              </p>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Persona"
              variant="outlined"
            />
          )}
        />
      </MPopover>
    </Box>
  );
};

export default PopoverForNewPersona;
