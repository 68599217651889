import { Box, Button, Tooltip } from '@material-ui/core';
import { memo } from 'react';

 const ContextMenu = memo(({ isOpen, position, actions = [], onMouseLeave }) => isOpen ? <Box
     padding="5px"
     width="240px"
     sx={{background:"white"}}
     borderRadius={3}
     border="1px solid #ccc"
     zIndex={1000}
     left={position.x + 10}
     top={position.y - 120}
     position="absolute"
     display="flex"
     flexDirection="column"
    onMouseLeave={onMouseLeave}
>
    {actions.map((item, i) =>
        <Tooltip arrow placement='right' title={item.label}  key={i}>
             <Button
            style={{display:"flex",alignItems:"center", paddingLeft:"10px", margin:"4px 0px"}}
            className='flex items-center text-[black] pl-2 my-2'
            variant="outlined"
            onClick={item.effect}
        >
      <item.icon style={{marginRight:"8px", marginTop:"4px"}}/>
            {item.label}
        </Button>
       </Tooltip>
    )}

 </Box > : null)
export default ContextMenu;