import {
  Box,
  ClickAwayListener,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
const DeleteElement = ({
  handleClickAway,
  deletedData,
  open,
  handleDeleteElement,
}) => {
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: "relative" }}>
        {open ? (
          <Box style={{ textAlign: "center" }}>
            <Tooltip
              title={deletedData?.data?.name ? deletedData?.data?.name : ""}
              placement="left"
              arrow
            >
              <Typography
                color="primary"
                style={{ marginTop: "0px", fontWeight: "600" }}
              >
                {deletedData?.data?.name.substr(0, 6)}
              </Typography>
            </Tooltip>

            <Tooltip title="delete  this workflow" placement="left" arrow>
              <IconButton
                onClick={() => handleDeleteElement(deletedData)}
                color="primary"
                aria-label="add an alarm"
              >
                <DeleteIcon style={{ cursor: "pointer" }} />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};
export default DeleteElement;
