import axios from '../../api/axios';
import newAxios from 'axios';
import { errorAlert, getItemLabel, handleErrorMessage, successAlert } from 'Utils';
import { kanbanConstant, riskAndIssueConstant } from './constant';
import { AppMode } from '../../urlConfig';
import fileDownload from 'js-file-download';
import { auth } from 'api/Auth';


// export const downloadFileFromBackend = (projectId, documentId, filename) => {
//     return async (dispatch) => {
//         try {
//             if (documentId) {
//                 await axios.get(`v1/projects/${projectId}/documents/download/file/${documentId}`, {
//                     responseType: 'blob',
//                 }).then(res => {
//                     fileDownload(res.data, filename);
//                 })
//             }
//         } catch (error) {
//             if (error && error.response && error.response.status === 404) {
//                 errorAlert("File has beed deleted")
//             }
//             if (error && error.response && error.response.status === 401) {
//                 return
//             } else {
//                 // errorAlert(handleErrorMessage(error));
//             }
//         }
//     }
// }
// export const deleteFileFromBackend = (projectId, documentId) => {
//     return async (dispatch) => {
//         dispatch({
//             type: kanbanConstant.DOCUMENT_DELETE_REQUEST,
//         })
//         try {
//             if (documentId) {
//                 const res = await axios.delete(`v1/projects/${projectId}/documents/delete/file/${documentId}`)
//                 res && res.status === 204 && successAlert("document deleted successfully")
//                 dispatch({
//                     type: kanbanConstant.DOCUMENT_DELETE_SUCCESS,
//                     payload: { deleted: true, documentId }
//                 })
//             }
//         } catch (error) {
//             if (error && error.response && error.response.status === 404) {
//                 errorAlert("File has beed deleted")
//             }
//             if (error && error.response && error.response.status === 401) {
//                 return
//             } else {
//                 // errorAlert(handleErrorMessage(error));
//             }
//         }
//     }
// }
export const getRiskLaneNotDeletableBoard = (projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get(`/v1/projects/${projectId}/riskLanes/project/${projectId}`);
                dispatch({
                    type: riskAndIssueConstant.GET_SINGLE_NOT_DELETABLE_ID,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getAllFilesFromBackendBasedOnCard = (projectId, cardId) => {
    return async (dispatch) => {
        try {
            if (projectId && cardId) {
                const res = await axios.get(`v1/projects/${projectId}/documents/card/${cardId}`)
                dispatch({
                    type: riskAndIssueConstant.GET_SINGLE_ATTACHMENTS_DATA_BY_CARD,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 404) {
                errorAlert("File has beed deleted")
            }
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                // errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const removeAllAttachmentsFromSingleCardRisk = () => {
    return (dispatch) => {
        dispatch({
            type: riskAndIssueConstant.REMOVE_SINGLE_ATTACHMENTS_DATA_BY_CARD,
            payload: { empty: true }
        })
    }
}
export const getAllLaneForRisksAndIssue = (projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get(`/v1/projects/${projectId}/riskLanes`);
                dispatch({
                    type: riskAndIssueConstant.GET_ALL_RISK_AND_LANE_SUCCESS,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getSingleLaneForBoardCards = (laneId, getAllData) => {
    return async (dispatch) => {
        try {
            if (laneId) {
                const res = await axios.get(`/v1/projects/{projectId}/{kanbanId}/lanes/${laneId}`);
                dispatch({
                    type: riskAndIssueConstant.GET_SINGLE_TREATMENTS_CARD,
                    payload: getItemLabel(getAllData, res.data)
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getLaneCountsForHomePage = (projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get(`/v1/projects/${projectId}/riskLanes/count/kanban`);
                dispatch({
                    type: riskAndIssueConstant.GET_LANE_COUNT,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getAllCardsForRisksAndIssue = (projectId) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                const res = await axios.get(`/v1/projects/${projectId}/riskCards`);
                dispatch({
                    type: riskAndIssueConstant.GET_ALL__RISK_AND_ISSUE_CARD_SUCCESS,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

// for specific users
// export const getUsersLaneListForKanban = (projectId, kanbanId, users) => {
//     return async (dispatch) => {
//         try {
//             if (projectId) {
//                 const res = await axios.get(`/v1/projects/${projectId}/${kanbanId}/lanes/users/${users}`);
//                 // console.log({ res })
//                 dispatch({
//                     type: kanbanConstant.GET_ALL_RISK_AND_LANE_SUCCESS,
//                     payload: res.data
//                 })
//             }
//         } catch (error) {
//             if (error && error.response && error.response.status === 401) {
//                 return
//             } else {
//                 errorAlert(handleErrorMessage(error));
//             }
//         }
//     }
// }
export const getSingleCardForRiskAndIssues = (projectId, id) => {
    return async (dispatch) => {
        try {
            if (projectId && id) {
                const res = await axios.get(`/v1/projects/${projectId}/riskCards/${id}`);
                dispatch({
                    type: riskAndIssueConstant.GET_SINGLE_CARD_SUCCESS,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const addNewLaneForRiskAndIssueKanban = (projectId, laneData) => {
    return async (dispatch) => {
        try {
            if (projectId && laneData) {
                const res = await axios.post(`/v1/projects/${projectId}/riskLanes`, laneData);
                res.data && successAlert("Lane added successfully")
                dispatch({
                    type: riskAndIssueConstant.ADD_KANBAN_LANE,
                    payload: res.data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const addNewLaneForDefaultKanban = (projectId, kanbanId, laneData, riskCardId) => {
    return async (dispatch) => {
        try {
            if (projectId && laneData) {
                const res = await axios.post(`/v1/projects/${projectId}/${kanbanId}/lanes/riskCard/${riskCardId}`, laneData);
                res.data && successAlert("card added successfully")
                // dispatch({
                //     type: riskAndIssueConstant.ADD_KANBAN_LANE,
                //     payload: res.data.data
                // })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const getTreatmentCards = (riskCardId) => {
    return async (dispatch) => {
        try {
            if (riskCardId) {
                const { data } = await axios.get(`/v1/projects/{projectId}/{kanbanId}/lanes/riskCard/${riskCardId}`);
                //TODO
                const getAllData = [...data.data];
                getAllData.forEach(item => {
                    dispatch(getSingleLaneForBoardCards(item?.laneId, getAllData))
                })
                dispatch({
                    type: riskAndIssueConstant.GET_SINGLE_TREATMENTS_CARD,
                    payload: data.data
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const updateRiskAndIssueLaneForKanban = (projectId, updateLaneData, laneId) => {
    return async (dispatch) => {
        try {
            if (projectId && updateLaneData) {
                const { data } = await axios.patch(`/v1/projects/${projectId}/riskLanes/${laneId}`, updateLaneData);
                data && successAlert("Lane updated successfully")
                // dispatch({
                //     type: kanbanConstant.UPDATE_KANBAN_LANE,
                //     payload: res.data
                // })
                await dispatch(getAllLaneForRisksAndIssue(projectId))
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const deleteCustomRiskLaneForKanban = (projectId, laneId) => {
    return async (dispatch) => {
        try {
            if (projectId && laneId) {
                const res = await axios.delete(`/v1/projects/${projectId}/riskLanes/${laneId}`);
                res.status === 204 && successAlert("Lane deleted successfully")
                dispatch({
                    type: riskAndIssueConstant.DELETE_KANBAN_LANE,
                    payload: laneId
                })
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const deleteCardFromRiskAndIssue = (projectId, cardId) => {
    return async (dispatch) => {
        try {
            if (projectId && cardId) {
                const res = await axios.delete(`/v1/projects/${projectId}/riskCards/${cardId}`);
                res.status === 204 && successAlert("Card deleted successfully")
                if (res.status === 204) {
                    await dispatch(getAllLaneForRisksAndIssue(projectId))
                }
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

export const updateLanePositionForRiskKanban = (projectId, laneData) => {
    return async (dispatch) => {
        try {
            if (projectId && laneData) {
                const res = await axios.post(`/v1/projects/${projectId}/riskLanes/saveLanes`, laneData);
                res.data && successAlert("Lane dragged successfully")
                dispatch({
                    type: riskAndIssueConstant.UPDATE_LANE_DRAG,
                    payload: res.data.data
                })
                dispatch(getAllLaneForRisksAndIssue(projectId))
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}

// export const findByLaneId = (projectId, valueStreamDtoList) => {
//     return async (dispatch) => {
//         try {
//             if (valueStreamDtoList) {
//                 const res = await axios.post(`/v1/projects/${projectId}/lanes/generate-kanban`, valueStreamDtoList);
//                 dispatch({
//                     type: riskAndIssueConstant.FOR_CSV_WITH_VALUE_STREAM,
//                     payload: res.data
//                 })
//             }
//         } catch (error) {
//             if (error && error.response && error.response.status === 401) {
//                 return
//             } else {
//                 errorAlert(handleErrorMessage(error));
//             }
//         }
//     }
// }
export const issuecardUpdate = (projectId, cardId, cardData) => {
    return async (dispatch) => {
        try {
            if (projectId && cardId) {
                const token = auth.getToken()
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                };
                const res = await newAxios.post(`${AppMode.backend}/v1/projects/${projectId}/riskCards/${cardId}`, cardData, config);
                (res.data) && successAlert("Card updated successfully")
                dispatch({
                    type: riskAndIssueConstant.UPDATE_KANBAN_CARD,
                    payload: res.data
                })
                await dispatch(getAllLaneForRisksAndIssue(projectId));
            }
        }
        catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
export const cardPositionFromRiskKanban = (projectId, cardData) => {
    return async (dispatch) => {
        try {
            if (projectId && cardData) {
                const res = await axios.patch(`/v1/projects/${projectId}/riskLanes/cards`, cardData);
                (res.data) && successAlert("Card Changed successfully")
                dispatch({
                    type: riskAndIssueConstant.UPDATE_KANBAN_CARD,
                    payload: res.data
                })
                // const users = JSON.parse(localStorage.getItem("searchedUser"))
                // // // console.log(users, "searched")
                // if (users.length > 0) {
                //     dispatch(getUsersLaneListForKanban(projectId, users))
                // } else {
                //     dispatch(getAllLaneForRisksAndIssue(projectId))
                // }
            }
        }
        catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}


// #TODO no need
// export const forFeatureLane = (projectId, featureName) => {
//     return async (dispatch) => {
//         try {
//             if (projectId && featureName) {
//                 const res = await axios.put(`/v1/projects/${projectId}/types`, featureName);
//                 res.data && successAlert("Card Changed Successfully")
//                 dispatch({
//                     type: riskAndIssueConstant.UPDATE_KANBAN_CARD,
//                     payload: res.data
//                 })
//                 dispatch(getAllLaneForRisksAndIssue(projectId))
//             }
//         } catch (error) {
//             if (error && error.response && error.response.status === 401) {
//                 return
//             } else {
//                 errorAlert(handleErrorMessage(error));
//             }
//         }
//     }
// }
export const addNewCardToRiskAndIssueKanban = (projectId, formDataOfKard) => {
    return async (dispatch) => {
        try {
            if (projectId) {
                // const config = {
                //     headers: {
                //         "Content-Type": "multipart/form-data",
                //         Authorization: token ? `Bearer ${token}` : '',
                //     },
                // };
                const res = await axios.post(`${AppMode.backend}/v1/projects/${projectId}/riskCards`, formDataOfKard);
                res.data && successAlert("Card added successfully")
                // dispatch({
                //     type: kanbanConstant.UPDATE_KANBAN_CARD,
                //     payload: res.data
                // })
                await dispatch(getAllLaneForRisksAndIssue(projectId))
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                return
            } else {
                errorAlert(handleErrorMessage(error));
            }
        }
    }
}
// export const notificationToTheNewUser = (cardId, redirectUrl, userId, message) => {
//     return async (dispatch) => {
//         try {
//             if (cardId) {
//                 await axios.patch(`v1/projects/{projectId}/users/send/notification/email/${cardId}/${userId}?message=${message}&redirectUrl=${redirectUrl}`);
//             }
//         } catch (error) {
//             if (error && error.response && error.response.status === 401) {
//                 return
//             } else {
//                 errorAlert(handleErrorMessage(error));
//             }
//         }
//     }
// }
