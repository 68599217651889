import { Modal } from "Components/Reusable";
import { Box, FormControl, InputLabel, MenuItem, Select, } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { ErrorMessages } from "Components/Reusable";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


const UpdatePersona = ({
    handlePersonaUpdateSubmit,
    onPersonaUpdateSubmit,
    controlledPersona,
    persona,
    personaError,
}) => {
    const history = useHistory()
    const { personas } = useSelector((state) => state.personas);
    return (
        <form
            noValidate
            autoComplete="off"
            onSubmit={handlePersonaUpdateSubmit(onPersonaUpdateSubmit)}
        >
            <h1 style={{ textAlign: "left", marginLeft: "30px" }}>
                Select Persona
            </h1>

            <Box style={{ padding: "30px" }}>
                <Controller
                    name="name"
                    control={controlledPersona}
                    defaultValue={persona?.name}
                    rules={{
                        required: {
                            value: true,
                            message: "This is required",
                        },
                    }}
                    
                    render={({ field }) => (

                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label">Personas</InputLabel>
                            <Select
                                {...field}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                disabled={personas.length < 1}
                                label="Age"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {personas && personas.map((item, index) => <MenuItem key={index} value={item?.name}>{item?.name}</MenuItem>)}

                            </Select>
                        </FormControl>
                    )}
                />
                <ErrorMessages errors={personaError} name="name" />
                {personas.length > 0 ?
                    <Button
                        style={{ marginTop: "20px" }}
                        fullWidth
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        Select
                    </Button> : <Button
                        style={{ marginTop: "20px" }}
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={() => history.push("/personas")}
                    >
                        Create Persona
                    </Button>}
            </Box>
        </form>
    );
};



export const UpdatePersonaModal = ({
    changePersona,
    setChangePersona,
    resetPersonaUpdate,
    handlePersonaUpdateSubmit,
    onPersonaUpdateSubmit,
    controlledPersona,
    persona,
    personaError,

}) => {
    return (
        <Modal
            open={changePersona}
            handleClose={() => {
                setChangePersona(false);
                resetPersonaUpdate();
            }}
        >
            <UpdatePersona
                handlePersonaUpdateSubmit={handlePersonaUpdateSubmit}
                onPersonaUpdateSubmit={onPersonaUpdateSubmit}
                controlledPersona={controlledPersona}
                persona={persona}
                personaError={personaError}
            />
        </Modal>
    );
};