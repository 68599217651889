import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import { ErrorMessages } from "Components/Reusable";
/**
 *@function HookFormTextField.jsx
 *@author Azim
 *
 **/
const HookFormTextField = ({
  control,
  defaultValue,
  label,
  name,
  style,
  className,
  rules,
  size,
  variant,
  type,
  errors,
  InputProps,
  checkBox,
  checkBoxData,
}) => {
  const pattern = {
    value: /^[^\s].*$/,
    message: "Entered value can't start/end or contain only white spacing",
  };
  return (
    <Fragment>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ? defaultValue : ""}
        rules={
          rules
            ? { ...rules, pattern: rules.pattern ? rules.pattern : pattern }
            : {
                required: {
                  value: false,
                  message: "This is required",
                },
                pattern: pattern,
              }
        }
        render={({ field }) => (
          <>
            {checkBox ? (
              //must pass an array with label and value and need to create defaultValues in useForm hook
              checkBoxData.map((item, i) => (
                <FormControlLabel
                  key={i}
                  label={item?.label}
                  control={
                    <Checkbox
                      value={item.value}
                      checked={field?.value.some(
                        (existingValue) => existingValue === item.value
                      )}
                      onChange={(event, checked) => {
                        if (checked) {
                          field.onChange([...field.value, event.target.value]);
                        } else {
                          field.onChange(
                            field?.value.filter(
                              (value) => value !== event.target.value
                            )
                          );
                        }
                      }}
                    />
                  }
                />
              ))
            ) : (
              <TextField
                {...field}
                size={size ? size : "medium"}
                fullWidth={type === "checkbox" ? false : true}
                style={style}
                autoComplete={type === "password" ? "true" : "false"}
                className={className}
                label={label}
                variant={variant ? variant : "outlined"}
                type={type ? type : "text"}
                InputProps={InputProps}
              />
            )}
          </>
        )}
      />
      {errors && <ErrorMessages errors={errors} name={name} />}
    </Fragment>
  );
};

export default HookFormTextField;
