import { getgeneratedText } from "Utils/flowUtils";
import SettingsIcon from "@material-ui/icons/Settings";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Tooltip } from "@material-ui/core";
import { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
/**
 *@function QuillText.jsx
 *@author Azim
 *
 **/

const QuillText = ({
  value,
  onChange,
  placeholder,
  defaultValue,
  className,
  custom,
  cardData,
  currentGPT,
  setCurrentGPT,
}) => {
  const [loading, setLoading] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  function RemoveHTMLTags(html) {
    var regX = /(<([^>]+)>)/gi;
    return html.replace(regX, "");
  }
  const newgenText = async (newval) => {
    const anothernewval =
      newval.length > 50 ? newval : cardData?.description.split(".")[0];
    setLoading(true);
    const newText =
      "Generate acceptance criteria for this user story" + "\"" + 
      RemoveHTMLTags(anothernewval) + "\"" + " " +
      "only using the given, when and then format , not so long and after every new line use <br/> and use single * before and after for given, when and then and obviously do a grammer check while generating";
    const body = {
      aibody: newText.replaceAll("\\", "").replaceAll('"', ""),
    };

    const res = await getgeneratedText(body);

    if (res.status === 200) {
      setLoading(false);
      setCurrentGPT(true);
      onChange(res.data);
    }
  };
  return (
    <>
      {custom ? (
        <Tooltip title="Generate Acceptance" arrow placement="left-end">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => !loading && newgenText(value)}
          >
            {!loading ? (
              <>
                {!currentGPT && (
                  <SettingsIcon
                    style={{
                      position: "absolute",
                      left: "40px",
                      height: "30px",
                      width: "30px",
                      marginTop: "5px",
                    }}
                  />
                )}
              </>
            ) : (
              <CircularProgress
                style={{
                  position: "absolute",
                  left: "40px",
                  height: "30px",
                  width: "30px",
                  marginTop: "5px",
                }}
              />
            )}
          </div>
        </Tooltip>
      ) : (
        false
      )}

      <ReactQuill
        style={{ width: "100%", maxWidth: custom && "1040px" , height:"90%"}}
        className={className ?? null}
        theme="snow"
        value={value}
        defaultValue={defaultValue ? defaultValue : ""}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder ? placeholder : "Description"}
      />
    </>
  );
};

export default QuillText;
