import { makeStyles } from '@material-ui/core';

const useCustomStyles = (filesToSave) => {
    let stylesData;
    stylesData = makeStyles((theme) => ({
        button: {
            display: "block",
            marginTop: theme.spacing(2),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            "& .MuiInputLabel-formControl": {
                background: "white",
                borderRadius: "2px",
                padding: "2px",
            },
        },
        popoverStyle: {
            "& .MuiPopover-paper": {
                width: "300px",
            },
        },
        updateCardAvatar: {
            height: "24px",
            width: "24px",
            fontSize: "13px",
        },
        linkToDesignStyle: {
            display: "inline",
            "& .MuiTextField-root": {
                margin: "6px 0px 2px 0px",
            },
        },
        toBlockersStyle: {
            display: "inline",
            "& .MuiTextField-root": {
                maxWidth: "1018px",
                marginTop: "10px",
            },
            "& .MuiChip-label": {
                maxWidth: "90px",
            },
        },
        toBlockersUpdateStyle: {
            display: "inline",
            "& .MuiTextField-root": {
                maxWidth: "1040px",
                margin: "10px 0px",
            },
            "& .MuiChip-label": {
                maxWidth: "90px",
            },
        },
        updateCardEpicLink: {
            weight: "400",
            height: "24px",
            fontSize: "1rem",
            marginRight: "4px",
            padding: "0px 6px",
            display: "block",
            "&>span": {
                textOverflow: "ellipsis",
                maxWidth: "150px",
                display: "block",
                lineHeight: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
            },
        },
        submitAndCloseButton: {
            width: "137px",
            fontWeight: "700",
            height: "42px"
        },
        muiDateWrapper: {
            border: "1px solid #7b7e80",
            padding: "5px",
            color: "black",
            borderRadius: "4px",
            "& .MuiInputLabel-root": {
                marginLeft: "9px",
                marginTop: "-10px",
                background: "white",
                padding: "5px",
            },
            "& .MuiInput-fullWidth": {
                marginTop: "10px",
            },
            "& .MuiIconButton-root": {
                marginBottom: "9px",
            },
            "& .MuiInput-underline:before": {
                content: "unset",
            },
        },
        muiDatePicker: {
            weight: "400",
            height: "24px",
            fontSize: "12px",
            marginRight: "4px",
            background: "white",
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .MuiInput-input": {
                textAlign: "center",
                cursor: "pointer",
                height: "24px",
                diplay: "block",
                background: "#DEDEDE",
                padding: "0px",
                width: "110px",
                borderRadius: "4px",
                "&::-webkit-input-placeholder": {
                    color: "black",
                    opacity: 1,
                },
            },
        },

        flexBasisBetween: {
            display: "flex",
            justifyContent: "space-between",
            flexBasis: "row",
            alignItems: "center",
        },
        flexBasisBetweenForHeaderCard: {
            display: "flex",
            justifyContent: "space-between",
            flexBasis: "row",
            alignItems: "center",
            margin: "30px 0px 20px 0px",
        },
        flexBetweenCenter: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        acceptanceCriteriaStyle: {
            fontSize: "14px",
            width: "100%",
            color: "#000000",
            fontWeight: "400",
            textAlign: "left",
            marginLeft: "10px",
        },
        flexCenterCenter: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        flexBetween: {
            display: "flex",
            justifyContent: "space-between",
        },
        typesIconStyle: {
            height: "24px",
            width: "24px",
            fontSize: "14px",
            marginRight: "3px",
            marginBottom: "3px",
        },
        updateDropzone: {
            "& .MuiDropzoneArea-icon": {
                display: "none",
            },
            "& .MuiDropzoneArea-text": {
                margin: 0,
            },
            "& .MuiDropzoneArea-root": {
                border: "none",
                borderRadius: "0",
                minHeight: "20px",
            },
            "& .MuiPaper-root": {
                background: "green",
            },
        },
        shortIconForAcceptance: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            "& .MuiButton-root": {
                height: "19px",
                minWidth: "19px",
                marginRight: "7px",
                padding: 0,
                paddingTop: "4px",
                paddingBottom: "4px",
            },
        },
        flexStartCenter: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        avatar: {
            marginRight: theme.spacing(2),
        },

        rootOfForm: {
            "& .MuiDropzoneArea-root": {
                marginTop: "10px",
                background: "white",
                minHeight: "38px",
            },
            "& .MuiDropzoneArea-text": {
                marginTop: "10px",
                marginBottom: "76px",
            },
            "& .MuiDropzoneArea-icon": {
                height: "30px",
                display: "none",
            },
            "& .MuiGrid-item": {
                margin: filesToSave.length > 3 ? "10px 0px 10px 10px" : 0,
                borderBottom: filesToSave.length > 3 ? "1px solid gray" : "none",
            },
            "& .MuiGrid-spacing-xs-8": {
                margin: filesToSave.length > 3 ? 0 : "-32px",
            },
            dateStyle: {
                "& .MuiInput-underline::before": {
                    content: "unset",
                },
            },
            "& .MuiDropzoneSnackbar-successAlert": {
                background: "green",
                color: "white",
            },
            "& .MuiDropzoneArea-textContainer": {
                height: "50px",
            },
            "& .MuiDropzonePreviewList-removeButton": {
                top: filesToSave.length > 3 ? "-9px" : "40px",
                right: filesToSave.length > 3 ? "-5px" : "40px",
                zIndex: "10000000000",
            },
            "& .MuiTypography-body1": {
                color: "black",
                textOverflow: "ellipsis",
                width: filesToSave.length > 3 ? "400px" : "180px",
                padding: filesToSave.length > 3 ? "5px" : 0,
                textAlign: filesToSave.length > 3 ? "left" : "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
            },
            "& .MuiDropzonePreviewList-image": {
                //conditon
                display: filesToSave.length > 3 && "none",
            },
            "& .MuiDropzonePreviewList-root": {
                //conditon
                display: filesToSave.length > 3 ? "flex" : "",
                flexDirection: filesToSave.length > 3 && "column",
                overflowY: filesToSave.length > 3 && "scroll",
                marginTop: filesToSave.length > 3 && "10px",
                "& .MuiGrid-grid-xs-4": {
                    maxWidth: filesToSave.length > 3 ? "80%" : "33.333333%",
                },
                "& .MuiGrid-spacing-xs-8": {
                    width: filesToSave.length > 3 ? "80%" : `calc(100% + 64px)`,
                },
            },
            "& .MuiDropzonePreviewList-imageContainer": {
                //conditon
                padding: filesToSave.length > 3 && 0,
            },
        },
    }));
    return stylesData();
}
export { useCustomStyles }