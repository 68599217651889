import { problemStatement } from "../actions/constant";

const initState = {
    error: "",
    success: false,
    loading: false,
    statement: [],
}


const problemStatementReducer = (state = initState, action) => {
    switch (action.type) {
        case problemStatement.GET_PROBLEM_STATEMENT_REQUEST:
            return {
                ...state,
                success: false,
                loading: true,
                statement: []
            }
        case problemStatement.GET_PROBLEM_STATEMENT_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                statement: action.payload
            }
        case problemStatement.GET_PROBLEM_STATEMENT_FAILED:
            return {
                ...state,
                success: false,
                loading: false,
                error: action.payload,
                statement: []
            }
        case problemStatement.CREATE_PROBLEM_STATEMENT_REQUEST:
            return {
                ...state,
                success: false,
                loading: true,
                statement: []
            }
        case problemStatement.CREATE_PROBLEM_STATEMENT_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                statement: []
            }
        case problemStatement.CREATE_PROBLEM_STATEMENT_FAILED:
            return {
                ...state,
                success: false,
                loading: false,
                error: action.payload,
                statement: []
            }
        case problemStatement.UPDATE_PROBLEM_STATEMENT_REQUEST:
            return {
                ...state,
                success: false,
                loading: true,
                statement: []
            }
        case problemStatement.UPDATE_PROBLEM_STATEMENT_SUCCESS:
            return {
                ...state,
                success: false,
                loading: false,
                statement: []
            }
        case problemStatement.UPDATE_PROBLEM_STATEMENT_FAILED:
            return {
                ...state,
                success: false,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}

export default problemStatementReducer