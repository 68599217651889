import {
  AddNewCard,
  EmojiCombobox,
  LaneForJourney,
} from "Components/JourneyMap";
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Link,
  TextField,
  Typography,
  Tooltip,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import {
  Clear as ClearIcon,
  Add as AddIcon,
  DeleteOutline as DeleteOutlineIcon,
  VisibilityRounded as VisibilityRoundedIcon,
} from "@material-ui/icons";
import Layout from "Components/Layout";
import Board from "react-trello";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLaneForJourney,
  journeyCardUpdate,
  getPersona,
  getOrganizationProfile,
  addNewCardToJourney,
  deleteCardFromJourney,
  removeAllAttachmentsFromSingleCard,
  updateLanePositionForJourneyLane,
  getPersonas,
} from "redux/actions";
import { Controller, useForm } from "react-hook-form";
import {
  AddButton,
  Attachments,
  CustomCardForRiskAndJourney,
  DropzoneSection,
} from "Components/BoardComponents";
import { nanoid } from "nanoid";
import {
  CustomSnacbar,
  ErrorMessages,
  Modal,
  RIconButton,
  QuillText,
} from "Components/Reusable";
import { useLocation, useParams } from "react-router-dom";
import querystring from "query-string";
import { riskAndIssuesData } from "assets/Data/RiskAndIssue";
import { useStyles } from "assets/Styles/RiskAndIssue";
import { changeArrayPositionByIndexNumber } from "Utils";
import ModalCloseSnackBar from "Components/Reusable/CustomSnacbar/modalclose";
import { PersonaLayout } from "Components/UtilComponents";
/**
 *@function JourneyMap.jsx
 *@author Azim
 *
 **/

const JourneyMap = () => {
  //state of this file
  const { personasId } = useParams();
  /* Modal related states */
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForAddCard, setModalOpenForAddCard] = useState(false);
  //popover

  //create evidence link
  const [evidenceLinks, setEvidenceLinks] = useState([]);

  const [updateEvidenceLinks, setUpdateEvidenceLinks] = useState([]);
  const [updateEvidences, setUpdateEvidences] = useState([]);
  const [defaultValuesForCard, setDefaultValuesForCard] = useState({});
  //alert to delete
  const [openAlert, setOpenAlert] = useState(false);
  const [alertToDelete, setAlertToDelete] = useState({});
  const [openToChangeTheTitle, setOpenToChangeTheTitle] = useState(false);
  //file related states
  const [updateFilesToSave, setUpdateFilesToSave] = useState([]);

  const [cardData, setCardData] = useState({});
  const [openWorkflowModal, setOpenWorkflowModal] = useState(false);
  const [cardId, setCardId] = useState("");
  //AutoComplete filter to show the onChange function
  // comment related states
  const [updateDescription, setUpdateDescription] = useState(false);
  const [updateDescriptionState, setUpdateDescriptionState] = useState("");
  const { search } = useLocation();
  useEffect(() => {
    const parse = querystring.parse(search);
    if (parse.card !== undefined) {
      setCardId(parse.card);
    }
  }, []);
  /* global(redux) related states */
  const dispatch = useDispatch();
  const { riskKanbanData } = useSelector((state) => state.journey);
  const { personas } = useSelector((state) => state.personas);
  const { attachments } = useSelector((state) => state.riskandissue);
  const { user } = useSelector((state) => state.auth);
  const { persona } = useSelector((state) => state.diagram);
  const [openalertnew, setOpenAlertnew] = useState(false);
  useEffect(() => {
    if (user?.projectId) {
      dispatch(getAllLaneForJourney(user?.projectId, personasId));
    }
  }, [dispatch, user?.projectId, personasId]);
  useEffect(() => {
    dispatch(getPersona(user?.projectId));
  }, [dispatch, user?.projectId]);
  useEffect(() => {
    dispatch(getPersonas(user?.projectId));
  }, [dispatch, user?.projectId]);

  useEffect(() => {
    if (user?.projectId) {
      dispatch(getOrganizationProfile(user?.projectId));
    }
  }, [user?.projectId, dispatch]);

  const {
    formState: { errors: createError },
    handleSubmit: handleCreateSubmit,
    reset: resetForCreate,
    control: controlForCreate,
  } = useForm({
    mode: "all",
  });
  const {
    formState: { errors, isDirty },
    handleSubmit: handleUpdateSubmit,
    reset,
    control,
    getValues,
    // setValue,
  } = useForm({
    mode: "all",
    defaultValues: defaultValuesForCard,
  });
  useEffect(() => {
    if (cardData) {
      setDefaultValuesForCard({ ...cardData });
      reset(cardData);
    }
  }, [cardData, reset]);

  const updateTitle = getValues("title");
  //Kanban Data for Board
  const newKanbanData = riskKanbanData.map((item) => {
    const newItem = { ...item };
    if (item.notDeletable) {
      newItem.style = {
        backgroundColor: "#6748A4",
        boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
        color: "#fff",
        width: 280,
      };
      return newItem;
    } else {
      newItem.disallowAddingCard = true;

      return newItem;
    }
  });
  const data = {
    lanes: newKanbanData ? newKanbanData : [],
  };
  const handleEditCard = useCallback(
    (e) => {
      setModalOpen(true);
      const particularCard = riskKanbanData.map((item) => item?.cards);
      const releaseCard = particularCard.flat(2);
      if (releaseCard && riskKanbanData.length) {
        const { cards } = riskKanbanData && riskKanbanData[0];
        const newCardModalUser = releaseCard.find((item) => item?.id === e);
        const getCardsFromTheSelectedCard = riskKanbanData.find(
          (item) => item.id === newCardModalUser.laneId
        )?.cards;
        const getIndex = getCardsFromTheSelectedCard.findIndex(
          (item) => item?.id === e
        );
        const getFirstQuestions = cards.find((_, index) => index === getIndex);
        const getUpdatedModal = { ...newCardModalUser };
        getUpdatedModal.rowTitle = getFirstQuestions.title;
        setCardData(getUpdatedModal);
        if (newCardModalUser?.evidenceLinks) {
          setUpdateEvidences(JSON.parse(newCardModalUser?.evidenceLinks));
        }
      }
    },
    [riskKanbanData]
  );

  useEffect(() => {
    if (cardId !== "") {
      window.history.pushState(
        null,
        "",
        window.location.origin + window.location.pathname + `?card=${cardId}`
      );
      handleEditCard(cardId);
    }
  }, [cardId, riskKanbanData, dispatch]);

  const handleDeleteFromBackend = (id) => {
    dispatch(deleteCardFromJourney(user?.projectId, id, personasId));
    setOpenAlert(false);
  };

  const handleAddUpdateEvidenceLink = () => {
    setUpdateEvidenceLinks([
      ...updateEvidenceLinks,
      {
        id: nanoid(15),
        link: "",
      },
    ]);
  };
  const handleUpdateEvidenceLink = (e, item) => {
    const getEvidenceValue = e.target.value;
    const getData = updateEvidenceLinks.find((items) => items.id === item.id);
    getData.link = getEvidenceValue;
    const indexedEvidence = updateEvidenceLinks.findIndex(
      (info) => info.id === getData.id
    );
    updateEvidenceLinks[indexedEvidence] = getData;
    setUpdateEvidenceLinks(updateEvidenceLinks);
  };
  const handleRemoveCreatedUpdateEvidence = (item) => {
    setUpdateEvidenceLinks(
      updateEvidenceLinks.filter((val) => val.id !== item.id)
    );
  };

  const handleUpdateCardClose = (cancelBtn) => {
    if (cancelBtn === false) {
      const newclickbtn = window.document.getElementById(
        "custom-journey-button"
      );
      newclickbtn?.click();
    }
    if (updateFilesToSave.length > 0 || isDirty === true) {
      setOpenAlertnew(true);
    } else {
      handleUpdateCardCloseConfirm();
    }
  };

  const handleUpdateCardCloseConfirm = () => {
    window.history.pushState(
      null,
      "",
      window.location.origin + window.location.pathname
    );
    setModalOpen(false);
    setUpdateDescription(false);
    setUpdateFilesToSave([]);
    setOpenToChangeTheTitle(false);
    setCardId("");
    reset(cardData);
    setUpdateEvidences([]);
    setUpdateEvidenceLinks([]);
    setUpdateDescription(false);
    setUpdateDescriptionState("");
    setOpenAlertnew(false);
    dispatch(removeAllAttachmentsFromSingleCard());
  };
  const handleCardSection = () => {
    return (
      <Modal
        riskAndIssue
        open={modalOpen}
        handleClose={() => handleUpdateCardClose(false)}
      >
        {newRenderUpdateModal()}
      </Modal>
    );
  };
  // handleAddCardToTheTrelloBoard
  const handleCreateModalClose = () => {
    setModalOpenForAddCard(false);
    setEvidenceLinks([]);
    resetForCreate();
  };
  const handleAddCardToTheTrelloBoard = () => {
    const getPropsForAddCard = {
      handleCreateSubmit: handleCreateSubmit,
      onCreateSubmit: onCreateSubmit,
      classes: classes,
      controlForCreate: controlForCreate,
      createError: createError,
      handleCreateModalClose: handleCreateModalClose,
    };
    return (
      <Modal
        open={modalOpenForAddCard}
        overflowAuto
        riskAndIssue
        handleClose={handleCreateModalClose}
      >
        <Box p="10px">
          <AddNewCard {...getPropsForAddCard} />
        </Box>
      </Modal>
    );
  };
  //handleDeleteCard
  const handleDeleteCard = (erase) => {
    setOpenAlert(true);
    setAlertToDelete(erase);
  };

  const onUpdateSubmit = (data) => {
    const UpdateformData = new FormData();
    UpdateformData.append("createDate", new Date());
    UpdateformData.append("indexNumber", cardData?.indexNumber);
    UpdateformData.append("deletable", cardData?.deletable);
    UpdateformData.append(
      "description",
      updateDescriptionState ? updateDescriptionState : cardData?.description
    );
    UpdateformData.append(
      "evidenceLinks",
      updateEvidenceLinks
        ? JSON.stringify([...updateEvidences, ...updateEvidenceLinks])
        : JSON.stringify(updateEvidences)
    );
    UpdateformData.append("id", cardData?.id);
    UpdateformData.append("personasId", cardData?.personasId);
    UpdateformData.append("journeyLaneId", cardData?.journeyLaneId);
    UpdateformData.append(
      "title",
      data.title ? data.title : cardData?.title || data.title
    );
    // if (cardData.indexNumber === 3) {
    // }
    // UpdateformData.append(
    //   "title",
    //   cardData.indexNumber === 3
    //     ? data.title
    //     : updateTitle
    //     ? updateTitle
    //     : cardData?.title || data.title
    // );
    UpdateformData.append("projectId", cardData?.projectId);
    UpdateformData.append("attachmentList", cardData?.attachmentList);
    for (let pic of updateFilesToSave) {
      UpdateformData.append("attachments", pic);
    }
    dispatch(
      journeyCardUpdate(
        user?.projectId,
        cardData?.id,
        UpdateformData,
        personasId
      )
    );
    handleUpdateCardCloseConfirm();
  };
  const onCreateSubmit = (data) => {
    const cardId = `sqbear_risk_and_issue_${nanoid(15)}`;
    const formDataa = new FormData();

    formDataa.append("createDate", new Date());
    // TODO
    formDataa.append(
      "indexNumber",
      riskKanbanData
        .find((item) => item.notDeletable)
        .cards.map((item) => item.indexNumber)
        .reduce((a, b) => Math.max(a, b)) + 1
    );
    formDataa.append("description", data?.description ? data.description : "");
    formDataa.append("evidenceLinks", JSON.stringify(evidenceLinks));
    formDataa.append("deletable", true);
    formDataa.append("id", cardId);
    formDataa.append("personasId ", personasId);
    formDataa.append(
      "journeyLaneId",
      riskKanbanData.find((item) => item.notDeletable).id
    );
    formDataa.append("title", data?.title);
    formDataa.append("projectId", user?.projectId);
    for (let pic of []) {
      formDataa.append("attachments", pic);
    }
    dispatch(addNewCardToJourney(user?.projectId, formDataa, personasId));
    handleCreateModalClose();
  };

  const newRenderUpdateModal = () => {
    return (
      <Box minWidth="700px" maxWidth="700px" mt="10px">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleUpdateSubmit(onUpdateSubmit)}
        >
          <Box className={classes.flexBetweenCenter} mb="20px">
            <h1 style={{ textAlign: "left" }}>{cardData.rowTitle}</h1>
            <Box className={classes.flexBetweenCenter}>
              <Box>
                <Tooltip placement="top" title={"Observe"} arrow>
                  <IconButton>
                    <VisibilityRoundedIcon
                      style={{ fill: " black", fontSize: "40px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          {/* {console.log({ cardData })} */}
          {cardData?.indexNumber === 3 &&
          !(
            cardData?.title.includes("?") || cardData?.title.includes("emotion")
          ) ? (
            <EmojiCombobox
              name="title"
              control={control}
              defaultValue={cardData?.title}
            />
          ) : cardData?.title ? (
            <Box style={{ textAlign: "left" }}>
              {!openToChangeTheTitle ? (
                <Tooltip
                  arrow
                  title="click to change the short description"
                  placement="top"
                >
                  <Typography
                    align="left"
                    className={classes.updateTitle}
                    onClick={() => setOpenToChangeTheTitle(true)}
                  >
                    {updateTitle ? updateTitle : cardData?.title}
                  </Typography>
                </Tooltip>
              ) : (
                <Box className={classes.flexStartCenter}>
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="medium"
                        fullWidth
                        label="Short description *"
                        variant="outlined"
                        type="text"
                      />
                    )}
                  />
                  <ErrorMessages errors={errors} name="title" />
                </Box>
              )}
            </Box>
          ) : (
            <Box className={classes.flexStartCenter}>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "This is required",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="medium"
                    fullWidth
                    label="Short description *"
                    variant="outlined"
                    type="text"
                  />
                )}
              />
              <ErrorMessages errors={errors} name="title" />
            </Box>
          )}

          <Box maxWidth="770px" textAlign="left" marginTop="30px">
            <Typography className={classes.textForBoldEveryUpdateCardSection}>
              Description
            </Typography>
            {updateDescription || !cardData?.description ? (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                marginTop={"1rem"}
                marginBottom={"3rem"}
                mt="20px"
              >
                <QuillText
                  value={updateDescriptionState}
                  onChange={setUpdateDescriptionState}
                />
              </Box>
            ) : (
              <Tooltip
                arrow
                title="click to change description"
                placement="top"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: cardData?.description,
                  }}
                  onClick={() => {
                    setUpdateDescription(true);
                    setUpdateDescriptionState(cardData?.description);
                  }}
                ></div>
              </Tooltip>
            )}
          </Box>
          <Box maxWidth="770px" textAlign="left" marginTop="30px">
            <Box
              display={`flex`}
              alignItems="center"
              justifyContent={`space-between`}
              style={{ marginTop: "8px", maxWidth: "760px" }}
            >
              <p style={{ fontWeight: "600" }}>Evidence (Links)</p>
              <Tooltip
                placement="top"
                arrow
                title="click to add new evidence link"
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleAddUpdateEvidenceLink}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>

            {updateEvidenceLinks.map((item, index) => (
              <FormControl
                style={{ marginTop: "16px", maxWidth: "770px" }}
                key={index}
                fullWidth
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Evidence (Links)
                </InputLabel>
                <OutlinedInput
                  onChange={(e) => handleUpdateEvidenceLink(e, item)}
                  id="outlined-adornment-password"
                  type={"text"}
                  defaultValue={item.link}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleRemoveCreatedUpdateEvidence(item)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={113}
                />
              </FormControl>
            ))}
            {updateEvidences.length > 0 &&
              updateEvidences.map((item, index) => (
                <Box
                  display={`flex`}
                  justifyContent="space-between"
                  alignItems={`center`}
                  mb="8px"
                >
                  <p key={index}>
                    <Link target="_blank" rel="noreferrer" href={item?.link}>
                      {item?.link}
                    </Link>
                  </p>
                  <RIconButton
                    onClick={() => {
                      setUpdateEvidences(
                        updateEvidences.filter((items) => items.id !== item.id)
                      );
                    }}
                    title="delete this evidence link"
                  >
                    <DeleteOutlineIcon style={{ color: "#FF0000" }} />
                  </RIconButton>
                </Box>
              ))}
          </Box>
          <Box mt="30px" className={classes.flexBetweenCenter}>
            <Box>
              <Typography className={classes.textForBoldEveryUpdateCardSection}>
                Evidence
                {attachments.length === 0 ? (
                  ""
                ) : (
                  <span>{`(${attachments.length})`}</span>
                )}
              </Typography>
            </Box>
            <Box className={classes.updateDropzone}>
              <DropzoneSection
                showFileNames={true}
                showPreviewsInDropzone={false}
                setUpdateFilesToSave={setUpdateFilesToSave}
                updateFilesToSave={updateFilesToSave}
              />
            </Box>
          </Box>
          {(attachments.length > 0 || updateFilesToSave.length > 0) && (
            <Attachments
              updateFilesToSave={updateFilesToSave}
              setUpdateFilesToSave={setUpdateFilesToSave}
              projectId={user?.projectId}
              cardId={cardData?.id}
              organizationId={user?.projectId}
              AttachmentList={attachments}
            />
          )}
          <Box
            display="flex"
            justifyContent="right"
            marginTop="30px"
            paddingBottom="20px"
          >
            <Button
              className={classes.submitAndCloseButton}
              style={{
                marginRight: "10px",
                background: "#F5F5F5",
              }}
              onClick={() => handleUpdateCardClose(true)}
              variant="outlined"
              type="button"
              color="secondary"
            >
              Close
            </Button>
            <Button
              className={classes.submitAndCloseButton}
              color="primary"
              variant="contained"
              type="submit"
              id="custom-journey-button"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    );
  };
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(true);
  const CreatedCard = (props) => {
    return (
      <CustomCardForRiskAndJourney
        handleDeleteCard={handleDeleteCard}
        journeyMap
        handleEditCard={handleEditCard}
        setCardId={setCardId}
        partData={props}
        persona={persona}
        riskAndIssue
      />
    );
  };
  const CreatedHeader = (props) => {
    return (
      <LaneForJourney
        journeyMap
        kanbanId={user?.kanbanId}
        props={props}
        riskKanbanData={riskKanbanData}
      />
    );
  };
  const NewButtonArise = () => {
    return <AddButton onClick={() => setModalOpenForAddCard(true)} journey />;
  };
  const components = {
    AddCardLink: NewButtonArise,
    LaneHeader: CreatedHeader,
    Card: CreatedCard,
  };
  const handleLaneDragEnd = (removedIndex, addedIndex, payload) => {
    const newUpdatedLanes = [...riskKanbanData];
    const updatedAe = changeArrayPositionByIndexNumber(
      removedIndex,
      addedIndex,
      payload,
      newUpdatedLanes
    );
    const INdexedResponse = updatedAe.map((item, index) => {
      const singleItem = { ...item };
      singleItem.serial = index;
      return singleItem;
    });
    dispatch(
      updateLanePositionForJourneyLane(
        user?.projectId,
        personasId,
        INdexedResponse
      )
    );
  };
  const autoCompleteData = data?.lanes
    .filter((item) => !item.notDeletable)
    .map((item) => {
      const newItems = {};
      newItems.label = item.label;
      newItems.value = item.id;
      newItems.imRequired = item.imRequired;
      return newItems;
    });
  return (
    <Layout
      pageName={"Journey map"}
      type={"journeyMap"}
      personasId={personasId}
      autoCompleteData={autoCompleteData}
      setOpenWorkflowModal={setOpenWorkflowModal}
      openWorkflowModal={openWorkflowModal}
    >
      <PersonaLayout
        menuOpen={menuOpen}
        classes={classes}
        setMenuOpen={setMenuOpen}
        personas={personas}
        personasId={personasId}
        setOpenWorkflowModal={setOpenWorkflowModal}
        user={user}
        pushWord={"journeyMap"}
        journeyMap
        projectId={user?.projectId}
      >
        <Box
          maxWidth={1200}
          style={{
            maxWidth: menuOpen ? "calc(100vw - 280px)" : "calc(100vw - 125px)",
          }}
          className={`${classes.sampleJourney} Nested__journeyMap`}
        >
          {riskKanbanData.length && (
            <Board
              editable
              style={riskAndIssuesData.style}
              laneStyle={riskAndIssuesData.laneStyle}
              collapsibleLanes={true}
              hideCardDeleteIcon={true}
              cardDraggable={false}
              draggable
              data={data}
              handleLaneDragEnd={(removedIndex, addedIndex, payload) => {
                handleLaneDragEnd(removedIndex, addedIndex, payload);
              }}
              // handleLaneDragStart={(a, b, c) => console.log({ a, b, c })}
              components={components}
            />
          )}
        </Box>
      </PersonaLayout>

      {openAlert && (
        <CustomSnacbar
          opened={openAlert}
          alertToDelete={alertToDelete}
          handleDeleteFromBackend={handleDeleteFromBackend}
          setOpenAlert={setOpenAlert}
          type="card"
        />
      )}
      {modalOpen && handleCardSection()}
      {modalOpenForAddCard && handleAddCardToTheTrelloBoard()}
      {openalertnew && (
        <ModalCloseSnackBar
          opened={openalertnew}
          setOpenAlert={setOpenAlertnew}
          handleCloseCardModal={handleUpdateCardCloseConfirm}
        />
      )}
    </Layout>
  );
};
export default JourneyMap;
